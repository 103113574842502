import React from 'react';
import Landing from '../../components/Landing';
import ArrowToTop from '../../components/UI/ArrowToTop';

const LandingPage = (): JSX.Element => {
  return (
    <div className="len-wr" style={{position: 'relative'}}>
      <Landing />
      <ArrowToTop className="arrowToTop--for-landing" />
    </div>
  );
};

export default LandingPage;
