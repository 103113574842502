import React, {useEffect, useState} from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import {MenuItem} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import CustomCheckbox from '../CustomCheckbox';
import Chip from '@material-ui/core/Chip';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export interface IItem {
  id: string;
  name: string;
  period?: string;
}

interface IProps {
  items: IItem[];
  setSelectedItems: (items: IItem[] | ((i: IItem[]) => IItem[])) => void;
  selectedItems: IItem[];
  defaultItemText: string;
  placeholder?: string;
  value?: string;
  className?: string;
  showNumberOfSelected?: boolean;
  label?: string;
  withIcon?: boolean;
  chipView?: boolean;
  isAllByDefault?: boolean;
}

const CustomAutocomplete = (props: IProps): JSX.Element => {
  const {
    setSelectedItems,
    items,
    selectedItems,
    placeholder,
    label,
    className,
    showNumberOfSelected = false,
    defaultItemText,
    isAllByDefault = false,
    withIcon = false,
  } = props;
  const [allChecked, setAllChecked] = useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: IItem[]) => {
    setSelectedItems(newValue);
  };
  useEffect(() => {
    setAllChecked(Boolean(selectedItems.length === items.length && selectedItems.length !== 0));
  }, [selectedItems, items]);

  const handleAllChecked = () => {
    if (!isAllByDefault) {
      if (!allChecked) {
        setSelectedItems(items);
      } else {
        setSelectedItems([]);
      }
      setAllChecked(!allChecked);
    } else {
      setSelectedItems([]);
    }
  };
  const handleDelete = (chipToDelete: string) => {
    setSelectedItems((chips: IItem[]) => chips.filter((chip: IItem) => chip.name !== chipToDelete));
  };
  useEffect(() => {
    if (isAllByDefault) {
      selectedItems.length === items.length && setSelectedItems([]);
    } else {
      setAllChecked(Boolean(selectedItems.length === items.length));
    }
  }, [selectedItems]);

  const getOptionSelected = (option: IItem, anotherOption: IItem) =>
    option.name === anotherOption.name;
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
    popupOpen,
  } = useAutocomplete({
    id: 'customized-autocomplete',
    onChange: handleChange,
    multiple: true,
    disableCloseOnSelect: true,
    options: items,
    value: selectedItems,
    getOptionSelected: getOptionSelected,
    getOptionLabel: (option) => option.name,
  });
  return (
    <div className={`custom-autocomplete ${popupOpen ? 'custom-autocomplete--opened' : ''}`}>
      <div {...getRootProps()} className="custom-block">
        <label htmlFor="multi-input">{label}</label>
        <div
          ref={setAnchorEl}
          className={`input-wrapper ${focused ? 'focused' : ''} ${className ? className : ''}`}
        >
          {showNumberOfSelected
            ? value.length
              ? ''
              : ''
            : value.map((option: IItem, index: number) => (
                <Chip
                  label={option.name}
                  deleteIcon={<CloseIcon />}
                  key={index}
                  onDelete={() => handleDelete(option.name)}
                />
              ))}
          <input
            placeholder={value.length ? `${value.length} selected` : placeholder}
            {...getInputProps()}
            id="multi-input"
          />
          {withIcon ? (
            <span className={`custom-autocomplete__arrow-icon-wr`}>
              {/*<SelectAll />*/}
              <KeyboardArrowDownIcon />
            </span>
          ) : null}
        </div>
      </div>
      {groupedOptions.length > 0 ? (
        <div className="list-box-wrap">
          <ul className="list-box" {...getListboxProps()}>
            <MenuItem onClick={handleAllChecked}>
              <CustomCheckbox checked={isAllByDefault ? !selectedItems.length : allChecked} />
              {defaultItemText}
            </MenuItem>
            {groupedOptions.map((option, index) => {
              const arrayOfIds = value.map((item) => item.id); //because in selected items Array url is not correct id is betted to check only by ids
              return (
                <MenuItem {...getOptionProps({option, index})} key={index}>
                  <CustomCheckbox checked={arrayOfIds.indexOf(option.id) > -1} />
                  {!option.period
                    ? option.name
                    : `${option.name} (${option.period?.toLowerCase()})`}
                </MenuItem>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default CustomAutocomplete;
