import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import './style.scss';

const BreadcrumbsComponent = (): JSX.Element => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const defaultLinks = [
    {title: 'Dashboard', url: '/'},
    {title: 'My Content', url: '/main/my-Content'},
  ];

  const crumbName = (i: string): string => {
    switch (i) {
      case 'text-model-create':
        return 'Choose Template';
      default:
        return i;
    }
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" className="custom-breadcrumbs">
      {defaultLinks.map((link) => (
        <Link key={link.title} to={link.url}>
          {link.title}
        </Link>
      ))}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography key={name}>{crumbName(name)}</Typography>
        ) : (
          <Link key={name} to={routeTo}>
            {crumbName(name)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
