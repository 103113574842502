import {createArrayEndpoint} from '../helpers/helper';
import {GET, handleBody, handleExceptions, PATCH, POST, PUT, DELETE} from './request';
import {
  ICreateTextModelRequestPayload,
  IEntryCreate,
  IEntryPublishBody,
  IEntryRequest,
  IEntryUpdate,
  IGenerateTextModel,
  IGetTextGenerationResultPayload,
  ILikeModelPayload,
  IModelIdFeedbackPayload,
  IModelNextStepRequestData,
  INNFormattingRequest,
  IPublicContentPayload,
  ISetArchiveModelRequest,
  ITextModelAutosavePayload,
  ITextModelsPayload,
} from '../interfaces';

export const getTextModelTemplate = (data?: {
  pageNumber: number;
  pageSize: number;
}): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModelTemplate${
    data ? `?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}` : ''
  }`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const createTextModel = (
  payload: ICreateTextModelRequestPayload,
): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/Create`;
  const options = {
    body: payload,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getTextModelDetails = (data: {
  id: string;
}): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/${data.id}`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getModelTemplateDetails = (data: {
  textModelTemplateName: string;
}): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModelTemplate/${data.textModelTemplateName}`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const postModelNextStep = (
  data: IModelNextStepRequestData,
): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/NextStep`;
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const cancelModel = (data: {
  textModelId: string;
}): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/CancelEngineRequest`;
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const likeModel = (data: ILikeModelPayload): Promise<void | Response | Object | null> => {
  const endpoint: string = `/Favorites/AddTM?textModelId=${data.textModelId}&isFavorite=${
    data.isFavorite ? 'true' : 'false'
  }`;

  return PUT(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getTextModels = (
  payload: ITextModelsPayload,
): Promise<void | Response | Object | null> => {
  const endpoint: string = createArrayEndpoint('TextModels', payload);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const addModelFeedback = (
  data: IModelIdFeedbackPayload,
): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/Feedback?TextModelId=${data.textModelId}&Feedback=${data.feedback}`;

  return PATCH(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getPublicTextModel = (
  payload: IPublicContentPayload,
): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/Public/${payload.publicHashId}`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const shareTextModel = (payload: {
  textModelId: string;
}): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/Public/Share`;
  const options = {
    body: payload,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const unshareTextModel = (payload: {
  textModelId: string;
}): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/Public/Unshare`;
  const options = {
    body: payload,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const uploadFile = (payload: {file: string}): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/ReadFile`;
  const options = {
    body: payload,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const nnFormattingModel = (
  payload: INNFormattingRequest,
): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/Formating`;
  const options = {
    body: payload,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const setArchiveModel = (
  data: ISetArchiveModelRequest,
): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/TextModel/Archive`;
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getTextModelsForLanding = (): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/LandingPageData`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getTextGenerationResult = (
  payload: IGetTextGenerationResultPayload,
): Promise<void | Response | Object | null> => {
  const endpoint: string = createArrayEndpoint('TextModels/GenerationHistory', payload);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const createEntry = (data: IEntryCreate): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModelEntries`;
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getTextModelEntry = (
  payload: IEntryRequest,
): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModelEntries/${payload.id}`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const updateTextModelEntry = (
  payload: IEntryUpdate,
): Promise<void | Response | Object | null> => {
  const endpoint: string = '/TextModelEntries/Update';
  const options = {
    body: payload,
  };

  return PUT(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const publishTextModelEntry = (
  payload: IEntryPublishBody,
): Promise<void | Response | Object | null> => {
  const endpoint: string = '/TextModelEntries/ChangeState';
  const options = {
    body: payload,
  };

  return PATCH(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getTextModelEntries = (payload: string): Promise<void | Response | Object | null> => {
  const endpoint: string = createArrayEndpoint('TextModelEntries', payload);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getTextModelCategories = (data?: {
  pageNumber: number;
  pageSize: number;
}): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModelCategories${
    data ? `?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}` : ''
  }`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const generateTextModel = (
  data: IGenerateTextModel,
): Promise<void | Response | Object | null> => {
  const endpoint: string = `/TextModels/GenerateContent`;
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const deleteTextModelEntry = (
  payload: IEntryRequest,
): Promise<void | Response | Object | null> => {
  const endpoint: string = createArrayEndpoint(`TextModelEntries/${payload.id}`);

  return DELETE(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getTextModelEntriesImages = (): Promise<void | Response | Object | null> => {
  const endpoint: string = '/TextModelEntries/Images';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const autoSaveTextModel = (
  payload: ITextModelAutosavePayload,
): Promise<void | Response | Object | null> => {
  const endpoint: string = '/TextModels/Autosave';
  const options = {
    body: payload,
  };

  return PATCH(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getTextModelStatistic = (): Promise<void | Response | Object | null> => {
  const endpoint: string = '/TextModels/Statistic';

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
