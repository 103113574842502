import {
  call,
  put,
  takeLatest,
  CallEffect,
  PutEffect,
  ForkEffect,
  SelectEffect,
} from 'redux-saga/effects';

// API
import * as textModel from '../api/textModel';

// REDUX
import * as textModelAction from '../actions/textModel';
import * as textModelActionType from '../actions/types/types';
import {
  IAddModelFeedbackRequestAction,
  ICancelModelRequestAction,
  ICreateTextModelRequestAction,
  IGetModelDetailsRequestAction,
  IGetModelTemplateDetailsRequestAction,
  IGetTextModelTemplateRequestAction,
  IModelNextStepRequestAction,
  ITextModelDetails,
  IGetTextModelsAction,
  IPutPublicTextModelsAction,
  IUploadFileRequestAction,
  ISetArchiveModelRequestAction,
  IState,
  ITextModelsPayload,
  IGetTextGenerationResultRequestAction,
  IShareModelRequestAction,
  IPublicHashResponse,
  ISetFavoriteModelRequestAction,
  IEntryCreateRequestAction,
  IGetEntryAction,
  IUpdateEntryActionRequest,
  IPublishEntryRequestAction,
  IGetTextModelEntriesRequestAction,
  IDeleteEntryAction,
  // IEntriesImagesAction,
  IGenerateTextModelRequestAction,
  ITextModelAutosaveAction,
} from '../interfaces';
import {Action} from 'redux-actions';
import * as alerts from '../actions/alert';
import {select} from '@redux-saga/core/effects';

export function* getTextModelTemplate(
  action: IGetTextModelTemplateRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.getTextModelTemplate, action.payload);
    yield put(textModelAction.getTextModelTemplateSuccess(payload));
  } catch (error) {
    yield put(textModelAction.getTextModelTemplateFailure(error));
  }
}

export function* createTextModel(
  action: ICreateTextModelRequestAction,
): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  {id: string}
> {
  try {
    const payload = yield call(textModel.createTextModel, action.payload);
    yield put(textModelAction.postTextModelCreateSuccess(payload));
  } catch (error) {
    yield put(textModelAction.postTextModelCreateFailure(error));
  }
}

export function* getTextModelDetails(
  action: IGetModelDetailsRequestAction,
): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>> | SelectEffect,
  void,
  ITextModelDetails
> {
  try {
    const payload = yield call(textModel.getTextModelDetails, action.payload);
    yield put(textModelAction.getTextModelDetailsSuccess(payload));
    // yield put(textModelAction.getModelTemplateDetailsRequest());
    // const modelTemplateName = payload.textModelTemplateName;
    // const templatePayload = yield call(textModel.getModelTemplateDetails, {
    //   textModelTemplateName: modelTemplateName,
    // });
    // yield put(textModelAction.getModelTemplateDetailsSuccess(templatePayload));
  } catch (error) {
    yield put(textModelAction.getTextModelDetailsFailure(error));
    yield put(textModelAction.getModelTemplateDetailsFailure(error));
  }
}

export function* getModelTemplateDetails(
  action: IGetModelTemplateDetailsRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.getModelTemplateDetails, action.payload);
    yield put(textModelAction.getModelTemplateDetailsSuccess(payload));
  } catch (error) {
    yield put(textModelAction.getModelTemplateDetailsFailure(error));
  }
}

export function* postModelNextStep(
  action: IModelNextStepRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.postModelNextStep, action.payload);
    yield put(textModelAction.postModelNextStepSuccess(payload));
  } catch (error) {
    yield put(textModelAction.postModelNextStepFailure(error));
  }
}

export function* cancelModel(
  action: ICancelModelRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.cancelModel, action.payload);
    yield put(textModelAction.postCancelModelSuccess(payload));
    const detailsPayload = yield call(textModel.getTextModelDetails, {
      id: action.payload.textModelId,
    });
    yield put(textModelAction.getTextModelDetailsSuccess(detailsPayload));
  } catch (error) {
    yield put(textModelAction.postCancelModelFailure(error));
  }
}

export function* likeModel(
  action: ISetFavoriteModelRequestAction,
): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>> | SelectEffect,
  void
> {
  try {
    const payload = yield call(textModel.likeModel, action.payload);
    yield put(textModelAction.putLikeModelSuccess(payload));
    if (!action.payload.isDetails) {
      const filtersPayload = yield select((state: IState) => state.textModel.lastFilterParams);
      yield put(
        textModelAction.getTextModelsRequest({
          ...(filtersPayload as ITextModelsPayload),
          inBackground: action.payload.inBackground,
        }),
      );
      yield put(
        alerts.showAlert({
          type: 'success',
          msg: action.payload.successMsg,
        }),
      );
    }
    if (action.payload.isDetails) {
      const detailsPayload = yield call(textModel.getTextModelDetails, {
        id: action.payload.textModelId,
      });
      yield put(textModelAction.getTextModelDetailsSuccess(detailsPayload));
    }
  } catch (error) {
    yield put(textModelAction.putLikeModelFailure(error));
    yield put(textModelAction.getTextModelDetailsFailure(error));
  }
}

export function* addModelFeedback(
  action: IAddModelFeedbackRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.addModelFeedback, action.payload);
    yield put(textModelAction.patchModelFeedbackSuccess(payload));
    const detailsPayload = yield call(textModel.getTextModelDetails, {
      id: action.payload.textModelId,
    });
    yield put(textModelAction.getTextModelDetailsSuccess(detailsPayload));
  } catch (error) {
    yield put(textModelAction.patchModelFeedbackFailure(error));
    yield put(textModelAction.getTextModelDetailsFailure(error));
  }
}

export function* getTextModels(
  action: IGetTextModelsAction,
): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>> | SelectEffect,
  void
> {
  try {
    const payload = yield call(textModel.getTextModels, action.payload);
    yield put(textModelAction.getTextModelsSuccess(payload));
  } catch (error) {
    yield put(textModelAction.getTextModelsFailure(error));
  }
}

export function* getPublicTextModel(
  action: IPutPublicTextModelsAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.getPublicTextModel, action.payload);
    yield put(textModelAction.getPublicTextModelSuccess(payload));
  } catch (error) {
    yield put(textModelAction.getPublicTextModelFailure(error));
  }
}

export function* shareTextModel(
  action: IShareModelRequestAction,
): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  IPublicHashResponse
> {
  try {
    const payload = yield call(textModel.shareTextModel, action.payload);
    yield put(textModelAction.postShareTextModelSuccess(payload));
    if (action.payload.isDetails) {
      const detailsPayload = yield call(textModel.getTextModelDetails, {
        id: action.payload.textModelId,
      });
      yield put(textModelAction.getTextModelDetailsSuccess(detailsPayload));
    }
  } catch (error) {
    yield put(textModelAction.postShareTextModelFailure(error));
  }
}

export function* unshareTextModel(
  action: ICancelModelRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.unshareTextModel, action.payload);
    yield put(textModelAction.postUnshareTextModelSuccess(payload));
    if (action.payload.isDetails) {
      const detailsPayload = yield call(textModel.getTextModelDetails, {
        id: action.payload.textModelId,
      });
      yield put(textModelAction.getTextModelDetailsSuccess(detailsPayload));
    }
  } catch (error) {
    yield put(textModelAction.postUnshareTextModelFailure(error));
  }
}

export function* uploadFile(
  action: IUploadFileRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.uploadFile, action.payload);
    yield put(textModelAction.postUploadFileSuccess(payload));
  } catch (error) {
    yield put(textModelAction.postUploadFileFailure(error));
  }
}

export function* setArchiveModel(
  action: ISetArchiveModelRequestAction,
): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>> | SelectEffect,
  void
> {
  try {
    yield put(textModelAction.removeTextModelManually({id: action.payload.textModelId}));
    const payload = yield call(textModel.setArchiveModel, {
      textModelId: action.payload.textModelId,
      isArchived: action.payload.isArchived,
    });
    yield put(textModelAction.setArchiveStatusModelSuccess(payload));
    yield put(
      alerts.showAlert({
        type: 'success',
        msg: action.payload.successMsg,
      }),
    );

    const filtersPayload = yield select((state: IState) => state.textModel.lastFilterParams);
    yield put(
      textModelAction.getTextModelsRequest({
        ...(filtersPayload as ITextModelsPayload),
        inBackground: action.payload.inBackground,
      }),
    );
  } catch (error) {
    yield put(textModelAction.setArchiveStatusModelFailure(error));
  }
}

export function* getTextGenerationResult(
  action: IGetTextGenerationResultRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.getTextGenerationResult, action.payload);
    yield put(textModelAction.getTextGenerationResultSuccess(payload));
    if (action.payload.textModelId) {
      yield put(textModelAction.updateTextModelDetailsHistory(payload));
    }
  } catch (error) {
    yield put(textModelAction.getTextGenerationResultFailure(error));
  }
}

export function* getTextModelsForLanding(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void
> {
  try {
    const payload = yield call(textModel.getTextModelsForLanding);
    yield put(textModelAction.getTextModelsForLandingSuccess(payload as ITextModelDetails[]));
  } catch (error) {
    yield put(textModelAction.getTextModelsForLandingFailure(error));
  }
}

export function* getTextModelEntries(
  action: IGetTextModelEntriesRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.getTextModelEntries, action.payload);
    yield put(textModelAction.getTextModelEntriesSuccess(payload));
  } catch (error) {
    yield put(textModelAction.getTextModelEntriesFailure(error));
  }
}

export function* getTextModelCategories(
  action: IGetTextModelTemplateRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.getTextModelCategories, action.payload);
    yield put(textModelAction.getTextModelCategoriesSuccess(payload));
  } catch (error) {
    yield put(textModelAction.getTextModelCategoriesFailure(error));
  }
}

export function* createTextModelEntry(
  action: IEntryCreateRequestAction,
): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  {id: string}
> {
  try {
    const payload = yield call(textModel.createEntry, action.payload);
    yield put(textModelAction.postCreateModelEntrySuccess(payload));
    const id = payload.id;
    const getPayload = yield call(textModel.getTextModelEntry, {id});
    yield put(textModelAction.getTextModelEntrySuccess(getPayload));
  } catch (error) {
    yield put(textModelAction.postCreateModelEntryFailure(error));
    yield put(textModelAction.getTextModelEntryFailure(error));
  }
}

export function* getTextModelEntry(
  action: IGetEntryAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.getTextModelEntry, action.payload);
    yield put(textModelAction.getTextModelEntrySuccess(payload));
  } catch (error) {
    yield put(textModelAction.getTextModelEntryFailure(error));
  }
}

export function* updateTextModelEntry(
  action: IUpdateEntryActionRequest,
): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  {id: string}
> {
  try {
    const payload = yield call(textModel.updateTextModelEntry, action.payload);
    yield put(textModelAction.updateTextModelEntrySuccess(payload));
    const id = payload.id;
    const getPayload = yield call(textModel.getTextModelEntry, {id});
    yield put(textModelAction.getTextModelEntrySuccess(getPayload));
  } catch (error) {
    yield put(textModelAction.updateTextModelEntryFailure(error));
    yield put(textModelAction.getTextModelEntryFailure(error));
  }
}

export function* publishTextModelEntry(
  action: IPublishEntryRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.publishTextModelEntry, action.payload);
    yield put(textModelAction.publishTextModelEntrySuccess(payload));
    if (action.payload.isPublished) {
      action.payload.navigate('/admin/template-management');
    }
  } catch (error) {
    yield put(textModelAction.publishTextModelEntryFailure(error));
  }
}

export function* generateTextModel(
  action: IGenerateTextModelRequestAction,
): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  {id: string}
> {
  try {
    const payload = yield call(textModel.generateTextModel, action.payload);
    yield put(textModelAction.generateTextModelSuccess(payload));
    if (payload.id) {
      const detailsPayload = yield call(textModel.getTextModelDetails, {
        id: payload.id,
      });
      yield put(textModelAction.getTextModelDetailsSuccess(detailsPayload));
      localStorage.removeItem('properties');
    }
  } catch (error) {
    yield put(textModelAction.generateTextModelFailure(error));
  }
}

export function* deleteTextModelEntry(
  action: IDeleteEntryAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.deleteTextModelEntry, action.payload);
    yield put(textModelAction.deleteTextModelEntrySuccess(payload));
  } catch (error) {
    yield put(textModelAction.deleteTextModelEntryFailure(error));
  }
}

export function* getEntriesImages(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void
> {
  try {
    const payload = yield call(textModel.getTextModelEntriesImages);
    yield put(textModelAction.getTextModelEntriesImagesSuccess(payload));
  } catch (error) {
    yield put(textModelAction.getTextModelEntriesImagesFailure(error));
  }
}
export function* autosaveTextModel(
  action: ITextModelAutosaveAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    const payload = yield call(textModel.autoSaveTextModel, action.payload);
    yield put(textModelAction.patchTextModelAutosaveSuccess(payload));
  } catch (error) {
    yield put(textModelAction.patchTextModelAutosaveFailure(error));
  }
}
export function* getTextModelStatistic(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void
> {
  try {
    const payload = yield call(textModel.getTextModelStatistic);
    yield put(textModelAction.getTextModelStatisticSuccess(payload));
  } catch (error) {
    yield put(textModelAction.getTextModelStatisticFailure(error));
  }
}

// WATCHERS
export function* watchTextModelTemplate(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.GET_TEXT_MODEL_TEMPLATE_REQUEST, getTextModelTemplate);
}

export function* watchCreateTextModel(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.POST_TEXT_MODEL_CREATE_REQUEST, createTextModel);
}

export function* watchTextModelDetails(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.GET_TEXT_MODEL_DETAILS_REQUEST, getTextModelDetails);
}

export function* watchModelTemplateDetails(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.GET_MODEL_TEMPLATE_DETAILS_REQUEST, getModelTemplateDetails);
}

export function* watchModelNextStep(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.POST_MODEL_NEXT_STEP_REQUEST, postModelNextStep);
}

export function* watchCancelModel(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.POST_CANCEL_MODEL_REQUEST, cancelModel);
}

export function* watchLikeModel(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.PUT_LIKE_MODEL_REQUEST, likeModel);
}

export function* watchAddModelFeedback(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.PATCH_MODEL_FEEDBACK_REQUEST, addModelFeedback);
}

export function* watchTextModels(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.GET_TEXT_MODELS_REQUEST, getTextModels);
}

export function* watchPublicTextModel(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.GET_PUBLIC_TEXT_MODEL_REQUEST, getPublicTextModel);
}

export function* watchSharePublicTextModel(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.POST_SHARE_TEXT_MODEL_REQUEST, shareTextModel);
}

export function* watchUnsharePublicTextModel(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.POST_UNSHARE_TEXT_MODEL_REQUEST, unshareTextModel);
}

export function* watchUploadFile(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.POST_UPLOAD_FILE_REQUEST, uploadFile);
}

export function* watchSetArchiveModel(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.SET_ARCHIVE_STATUS_MODEL_REQUEST, setArchiveModel);
}

export function* watchGtTextModelsForLanding(): Generator<ForkEffect<never>, void> {
  yield takeLatest(
    textModelActionType.GET_TEXT_MODELS_FOR_LANDING_REQUEST,
    getTextModelsForLanding,
  );
}
export function* watchTextGenerationResult(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.GET_TEXT_GENERATION_RESULT_REQUEST, getTextGenerationResult);
}

export function* watchTextModelEntryCreate(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.POST_CREATE_MODEL_ENTRY_REQUEST, createTextModelEntry);
}

export function* watchGetTextModelEntry(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.GET_TEXT_MODEL_ENTRY_REQUEST, getTextModelEntry);
}

export function* watchUpdateTextModelEntry(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.UPDATE_MODEL_ENTRY_REQUEST, updateTextModelEntry);
}

export function* watchPublishTextModelEntry(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.PUBLISH_MODEL_ENTRY_REQUEST, publishTextModelEntry);
}

export function* watchTextModelEntriesResult(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.GET_TEXT_MODEL_ENTRIES_REQUEST, getTextModelEntries);
}

export function* watchTextModelCategoriesResult(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.GET_TEXT_MODEL_CATEGORIES_REQUEST, getTextModelCategories);
}

export function* watchGenerateTextModel(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.GENERATE_TEXT_MODEL_REQUEST, generateTextModel);
}

export function* watchDeleteTextModelEntry(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.DELETE_TEXT_MODEL_ENTRY_REQUEST, deleteTextModelEntry);
}

export function* watchEntriesImages(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.GET_TEXT_MODEL_ENTRIES_IMAGES_REQUEST, getEntriesImages);
}

export function* watchAutosaveTextModel(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.PATCH_TEXT_MODEL_AUTOSAVE_REQUEST, autosaveTextModel);
}

export function* watchTextModelStatistic(): Generator<ForkEffect<never>, void> {
  yield takeLatest(textModelActionType.GET_TEXT_MODEL_STATISTIC_REQUEST, getTextModelStatistic);
}
