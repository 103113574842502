import {useState} from 'react';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {IState} from '../interfaces';
import {SubscriptionStatus} from '../enums/SubscriptionStatuses';

interface IDates {
  startDate: Date | null;
  endDate: Date | null;
}
interface DatePicker {
  dates: IDates;
  dateDeb: IDates;
  currentDate: Date;
  nextDayDate: Date;
  handleChange: (date: Date | null, name: string) => void;
}

export function useDatePicker(): DatePicker {
  const [dates, setDates] = useState<IDates>({
    startDate: null,
    endDate: null,
  });
  const [dateDeb, setDateDeb] = useState<IDates>({
    startDate: null,
    endDate: null,
  });

  const currentDate = new Date();
  const nextDayDate = new Date();
  nextDayDate.setDate(nextDayDate.getDate() + 1);

  const dateDebounce = _.debounce((date: Date | null, name: string) => {
    setDateDeb({
      ...dateDeb,
      [name]: date,
    });
  }, 1500);

  const handleChange = (date: Date | null, name: string) => {
    setDates({
      ...dates,
      [name]: date,
    });
    dateDebounce(date, name);
  };

  return {dates, dateDeb, currentDate, nextDayDate, handleChange};
}

export const useActiveUser = (): {
  isExpired: boolean;
  isSubscribed: boolean;
  isActiveUser: boolean;
  isInActiveUser: boolean;
  currentStatus: SubscriptionStatus;
} => {
  const {userSubscriptionStatus} = useSelector((state: IState) => state.userProfile.userProfile);

  const isSubscribed =
    userSubscriptionStatus === SubscriptionStatus.PaidActive ||
    userSubscriptionStatus === SubscriptionStatus.Endless ||
    userSubscriptionStatus === SubscriptionStatus.TrialActive;

  const isExpired =
    userSubscriptionStatus === SubscriptionStatus.PaidExpired ||
    userSubscriptionStatus === SubscriptionStatus.TrialExpired;
  const isInActiveUser = userSubscriptionStatus === SubscriptionStatus.None;
  const isActiveUser = !isExpired && isSubscribed; // not needed any more should be refactored
  const currentStatus = userSubscriptionStatus;

  return {isExpired, isSubscribed, isActiveUser, currentStatus, isInActiveUser};
};
