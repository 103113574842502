import React from 'react';
import {Grid} from '@material-ui/core';

const Title = (): JSX.Element => {
  return (
    <Grid className="contact-us__title-wr" container item xs={12}>
      <Grid
        className="contact-us__title-grid"
        container
        item
        style={{
          margin: 'auto',
          alignItems: 'center',
        }}
        xs={11}
        sm={10}
        lg={8}
      >
        <h1 className="contact-us__title">Marquètte</h1>
        <p>
          Got a billing or technical issue? Want to send feedback about a product feature? Need
          details about our future roadmap? Let us know.
        </p>
      </Grid>
    </Grid>
  );
};

export default Title;
