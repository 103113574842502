import React from 'react';
import 'date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {IconType} from '../../../enums';
import Icons from '../Icons';
import './style.scss';
import {ParsableDate} from '@material-ui/pickers/constants/prop-types';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';

interface IDatePicker {
  label: string;
  name?: string;
  className?: string;
  disabled?: boolean;
  onChangeFunction: (date: MaterialUiPickersDate, name?: string) => void;
  value?: ParsableDate;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
  helperText?: string;
}

const DatePicker = (props: IDatePicker): JSX.Element => {
  const {label, className, disabled, onChangeFunction, value, minDate, maxDate, name, helperText} =
    props;

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          placeholder="mm/dd/yyyy"
          label={label}
          helperText={helperText}
          name={name}
          keyboardIcon={
            disabled ? null : <Icons type={IconType.CALENDAR} wrapper={false} color="#03004F" />
          }
          value={value ? value : null}
          className={`datePicker-root ${className ? className : ''}`}
          onChange={(data) => onChangeFunction(data, name)}
          disabled={disabled}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          minDate={minDate}
          maxDate={maxDate}
          autoOk
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePicker;
