import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {getPaymentSystemIcon} from '../../../helpers/helper';
import StripeFooter from '../StripeFooter';
import LoadingSpinner from '../../UI/LoadingSpinner';
import {ICreditsDetails, IPaymentMethods, IState} from '../../../interfaces';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EditIcon from '@material-ui/icons/Edit';
import {postBuyCreditsRequest} from '../../../actions/billing';
import {ICreditsOpen} from './index';

interface IProps {
  card: ICreditsDetails;
  defaultCard: IPaymentMethods;
  setModalSuccessOpen: (data: ICreditsOpen) => void;
  isActiveUser: boolean;
  isReader: boolean;
}

const CreditsItem = (props: IProps): JSX.Element => {
  const {card, defaultCard, setModalSuccessOpen, isActiveUser, isReader} = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<string>('');

  const creditsState = useSelector((state: IState) => state.credits);
  const CreditsBought = creditsState.CreditsBought;
  const CreditsBoughtErr = creditsState.CreditsBoughtError;
  const CreditsLoad = creditsState.loading;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useDispatch();

  const handleClick = () => {
    setModalOpen(true);
  };
  const confirmPurchase = () => {
    dispatch(postBuyCreditsRequest({creditsProductId: card.creditsId}));
    setModalSuccessOpen({isOpen: false, credits: card.creditsValue});
  };

  useEffect(() => {
    if (CreditsBoughtErr) {
      setPaymentError('Payment submission failed. Please check your payment details or try again.');
    }
  }, [CreditsBoughtErr]);

  useEffect(() => {
    if (CreditsBought) {
      setModalOpen(false);
    }
  }, [CreditsBought]);

  return (
    <Card className="subscription-credits__card">
      <CardContent className="subscription-credits">
        <img alt="" src={card.img} className="subscription-credits__img" />
        <h3 className="card__title card__title--lowercase">{card.name}</h3>
      </CardContent>
      <CardActions>
        <Button
          onClick={handleClick}
          className="blue-btn blue-btn--filled subscription-credits__btn"
          disabled={!isActiveUser || !isReader}
        >
          <span className="subscription-credits__currency">$</span>
          {card.price}
        </Button>
      </CardActions>
      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        aria-labelledby="dialog-before-close"
        className="credits__modal"
        onClose={() => setModalOpen(false)}
      >
        <div className="payment-modal-wr">
          <div className="modal__window modal__window--credits">
            <h5 className="modal__title">Checkout</h5>
            <div className="plan-details">
              <div className="card-info-wr">
                <span className="card-info">
                  <p>Payment method:</p>
                  <span>
                    <img src={getPaymentSystemIcon(defaultCard && defaultCard.brand)} alt="" />
                    **** **** **** {defaultCard && defaultCard.last4}
                  </span>
                </span>
                <p className="modal__edit">
                  <a href="/account/profile">
                    <EditIcon />
                    Payment settings
                  </a>
                </p>
                <div className="plan-details__total-wr">
                  <img className="plan-details__icon" src={card.img} alt="coin" />
                  <p className="modal__credit-title">{card.name}</p>
                  <p className="modal__credit-price">
                    {card.price % 1 === 0 ? `$${card.price}.00` : `$${card.price}`}
                  </p>
                </div>
              </div>
            </div>
            {paymentError.length > 0 && (
              <div className="error error--credits">
                <p>{paymentError}</p>
              </div>
            )}
            <DialogActions className="modal__buttons payment-modal-actions">
              <div className="payment-form__buttons">
                <Button
                  onClick={confirmPurchase}
                  className="orange-btn orange-btn-hover"
                  disabled={paymentError.length > 0}
                >
                  Confirm purchase
                  <ArrowForwardIcon />
                </Button>
                <Button className="white-btn white-btn-hover" onClick={() => setModalOpen(false)}>
                  Cancel
                </Button>
              </div>
              <p className="plan-details__add-info">
                By confirming your subscription, you allow Marquette to charge your card for this
                payment and future payments in accordance with their terms.
              </p>
            </DialogActions>
            <StripeFooter />
            {CreditsLoad ? (
              <div className="modal__window--loading">
                <LoadingSpinner />
              </div>
            ) : null}
          </div>
        </div>
      </Dialog>
    </Card>
  );
};

export default CreditsItem;
