import React, {useEffect, useState} from 'react';
import {Header} from '../../components/Header';
import TextModelEditComponent from '../../components/TextModelComponent/TextModelEdit';
import {useNavigate, useParams} from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {getTextModelDetailsRequest} from '../../actions/textModel';
import {useDispatch, useSelector} from 'react-redux';
import {IState} from '../../interfaces';

const TextModelPage = (): JSX.Element => {
  const {modelId} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modelDetails = useSelector((state: IState) => state.textModel.textModelDetails);
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    if (modelId) {
      dispatch(getTextModelDetailsRequest({id: modelId}));
    }
  }, [modelId]);

  useEffect(() => {
    if (modelDetails) {
      setTitle(modelDetails.name);
    }
  }, [modelDetails]);

  return (
    <div className="client-page client-page--light text-model-page">
      <Header
        showLogo={false}
        adminSide={false}
        backButton={
          <>
            <span
              onClick={() => navigate('/main/my-Content')}
              className="text-model-page__back-btn"
            >
              <ArrowBackIosIcon />
              <p>Templates</p>
            </span>
            <p className="text-model-page__header-title">
              {title.length > 80 ? title.substring(0, 80) + '... ' : title}
            </p>
          </>
        }
      />
      <TextModelEditComponent modelId={modelId} />
    </div>
  );
};

export default TextModelPage;
