import React, {MouseEvent, useState} from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import IconButton from '@material-ui/core/IconButton';
import {Menu, MenuItem} from '@material-ui/core';
import {
  handleDownloadHTML,
  handleDownloadMarkdown,
  handleDownloadPdf,
} from '../../../helpers/helper';
import {LightTooltip} from '../StatusLabel';

interface IProps {
  refElement: HTMLDivElement;
  title: string;
  isPreviewStep?: boolean;
  contentAsHtml: string;
}

const DownloadFileBtn = (props: IProps): JSX.Element => {
  const {refElement, title, isPreviewStep, contentAsHtml} = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(!openMenu);
  };
  const menuId = 'content-menu';

  const renderMenu = (
    <>
      <Menu
        anchorEl={anchorEl}
        id={menuId}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => handleDownloadPdf(refElement, title, isPreviewStep)}>PDF</MenuItem>
        <MenuItem onClick={() => handleDownloadHTML(contentAsHtml, title)}>HTML</MenuItem>
        <MenuItem onClick={() => handleDownloadMarkdown(refElement, title)}>MD</MenuItem>
      </Menu>
    </>
  );
  return (
    <>
      <IconButton
        className="orange-icon-btn download-icon"
        onClick={(e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) =>
          handleMenuOpen(e)
        }
      >
        <LightTooltip title={'Download'} placement={'top'}>
          <FileDownloadOutlinedIcon />
        </LightTooltip>
      </IconButton>
      {renderMenu}
    </>
  );
};

export default DownloadFileBtn;
