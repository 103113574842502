import React, {useCallback, useEffect, useState} from 'react';
import './style.scss';
import {useDispatch, useSelector} from 'react-redux';
import {getPublicTextModelRequest} from '../../../actions/textModel';
import MyContentDetailsTitle from '../TextModelEdit/Title/MyContentDetailsTitle';
import LoadingSpinner from '../../UI/LoadingSpinner';
import {IState} from '../../../interfaces';
import {Editor} from '../TextModelEdit/Editor/Editor';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';
import {DeltaStatic} from 'quill';

const PublicPageComponent = (): JSX.Element => {
  const params = useParams();
  const publicHashId = params.publicHashId;
  const dispatch = useDispatch();
  const [contentWords, setContentWords] = useState<number>(0);
  const contentDetails = useSelector((state: IState) => state.textModel);
  const result = contentDetails?.textPublicModel?.finalResult;

  useEffect(() => {
    dispatch(getPublicTextModelRequest({publicHashId}));
  }, []);

  const setContentDataAndWords = useCallback((_: DeltaStatic, words: number) => {
    setContentWords(words);
  }, []);

  return (
    <>
      <Helmet
        title={
          contentDetails.textPublicModel?.name
            ? contentDetails.textPublicModel?.name
            : contentDetails.textPublicModel?.alias
        }
        meta={[
          {name: 'description', content: contentDetails.textPublicModel?.alias},
          {property: 'og:description', content: contentDetails.textPublicModel?.alias},
          {
            property: 'og:title',
            content: contentDetails.textPublicModel?.name
              ? contentDetails.textPublicModel?.name
              : contentDetails.textPublicModel?.alias,
          },
          {
            name: 'twitter:title',
            content: contentDetails.textPublicModel?.name
              ? contentDetails.textPublicModel?.name
              : contentDetails.textPublicModel?.alias,
          },
          {name: 'twitter:description', content: contentDetails.textPublicModel?.alias},
        ]}
      />
      {!contentDetails.loading ? (
        <div className="shared__container">
          <div className="shared__content">
            {contentDetails.textPublicModel && (
              <MyContentDetailsTitle
                id={contentDetails.textPublicModel.id}
                title={
                  contentDetails.textPublicModel.name
                    ? contentDetails.textPublicModel.name
                    : contentDetails.textPublicModel.alias
                }
                date={contentDetails.textPublicModel.createdDate}
                industry={contentDetails.textPublicModel.industryName}
                description={contentDetails.textPublicModel.alias}
                isActionBtnDisplayed={false}
                image={contentDetails.textPublicModel.entryImg}
                contentWords={contentWords}
                isPublic={true}
              />
            )}
            <div data-color-mode="light">
              <div className="wmde-markdown-var"> </div>
              {result && (
                <Editor
                  modelId={contentDetails.textPublicModel?.id || ''}
                  disabled={true}
                  value={result}
                  hideToolbar={true}
                  onUpdateContentWords={setContentDataAndWords}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
export default PublicPageComponent;
