import React, {useEffect, useState} from 'react';
import {useMsal} from '@azure/msal-react';
import {useDispatch, useSelector} from 'react-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {useNavigate} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Switcher from './Switcher';
import PlanCard from './PlanCard';
import {Header} from '../Header';
import LandingHeader from '../Landing/Header';
import Footer from '../Footer';
import AddCredits from './AddCredits';
import {
  getRegisteredUserSubscriptionRequest,
  getUserSubscriptionRequest,
} from '../../actions/subscription';
import {getPaymentMethodsRequest, postInitializePaymentCardRequest} from '../../actions/payments';
import {ICard, IState, ISubscriptionItem} from '../../interfaces';
import {getEnabledSubscriptionDetails, logInHandle, setPlanImage} from '../../helpers/helper';
import {useActiveUser} from '../../helpers/customHooks';
import './style.scss';
import LoadingSpinner from '../UI/LoadingSpinner';
import AlertDialog from '../UI/AlertDialog';
import StandardModal from '../UI/StandardModal';
import {DialogActions} from '@material-ui/core';
import Payment from './Payment';
import PaymentForm from './Payment/PaymentForm';
import StripeFooter from './StripeFooter';

const SubscriptionPlan = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {instance} = useMsal();
  const [isMonthly, setIsMonthly] = useState<boolean>(true);
  const isAuthorized = useSelector((state: IState) => !!state.authMsal.aadResponse);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSubscribeError, setSubscribeError] = useState<boolean>(false);
  const [isOpenCardAdding, setOpenCardAdding] = useState<boolean>(false);
  const [isCardSavingSucceeded, setCardSavingSucceeded] = useState<boolean>(false);
  const userRole = useSelector((state: IState) => state.userProfile.userProfile.userRole);
  const initializedCardResponse = useSelector(
    (state: IState) => state.initializeCard.initializeCardResponse,
  );
  const paymentMethodsState = useSelector((state: IState) => state.paymentMethods);
  const boughtSubscriptionState = useSelector((state: IState) => state.boughtSubscription);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(postInitializePaymentCardRequest()); //get clientSecret and setupIntentId
      dispatch(getPaymentMethodsRequest());
    }
  }, []);

  useEffect(() => {
    isAuthorized
      ? dispatch(getRegisteredUserSubscriptionRequest())
      : dispatch(getUserSubscriptionRequest());
  }, [isAuthorized]);

  useEffect(() => {
    if (boughtSubscriptionState.error) {
      setSubscribeError(!isSubscribeError);
    }
  }, [boughtSubscriptionState.error]);

  useEffect(() => {
    isCardSavingSucceeded && dispatch(getPaymentMethodsRequest());
  }, [isCardSavingSucceeded]);

  const setPrice = () => {
    setIsMonthly(!isMonthly);
  };
  const subscriptionPlans = useSelector(
    (state: IState) =>
      state[isAuthorized ? 'subscriptionRegistered' : 'subscription']['subscription'] &&
      state[isAuthorized ? 'subscriptionRegistered' : 'subscription']['subscription'],
  );
  const subscriptionMonthPlans =
    subscriptionPlans && subscriptionPlans.items.filter((item) => item.period === 'Month');
  const subscriptionYearPlans =
    subscriptionPlans && subscriptionPlans.items.filter((item) => item.period === 'Year');

  function changeArrayOrder(array: ICard[]): ICard[] {
    const activeArr = array.filter((item) => !item.isFuturePlan);
    const inactiveArr = array.filter((item) => item.isFuturePlan);
    return [...activeArr, ...inactiveArr];
  }

  function modifyArray(array: ISubscriptionItem[]): ICard[] {
    const expandedArray = array.map((item: ISubscriptionItem, index: number) => ({
      id: item.id ? item.id : index.toString(),
      img: setPlanImage(item.name),
      isAvailableForUser: item.isAvailableForUser,
      price: item.price,
      period: item.period,
      name: item.name,
      oldPrice: item.oldPrice,
      details: getEnabledSubscriptionDetails(item.availableFeatures),
      description: 'Access to all must-have features with no limits!',
      level: item.level,
      credits: item.credits,
      isFuturePlan: item.isFuturePlan,
    }));
    return changeArrayOrder(expandedArray);
  }

  const {isActiveUser, isExpired} = useActiveUser();

  const btnClickHandle = () => {
    void logInHandle(instance);
  };
  function handleAdd() {
    setSubscribeError(false);
    setOpenCardAdding(true);
  }
  return (
    <>
      {isAuthorized ? (
        <Header showLogo={true} adminSide={false} />
      ) : (
        <Grid className="header-wr" container item lg={12}>
          <LandingHeader positionFixed={true} redirectHandle={btnClickHandle} />
        </Grid>
      )}
      <Grid
        container
        className={`subscription-page grid-wr ${
          !isAuthorized ? 'subscription-page--fixed-header' : ''
        }`}
      >
        <Grid item xs={12} md={10} lg={11}>
          <div className="st__wr">
            <div className="st__title">
              {(isExpired || isActiveUser) && (
                <div className="back-btn" onClick={() => navigate(-1)}>
                  <ArrowBack className="st__img" />
                  <p className="st__img-caption">Back</p>
                </div>
              )}
              <div className="st__title-item">
                <h1 className="subscription-page__title">
                  {isAuthorized ? 'Choose Your Subscription Plan' : 'See Our Subscription Plans'}
                </h1>
                <p className="st__subtitle">Find the right solution for your needs.</p>
                {!isAuthorized ? (
                  <button className="orange-btn orange-btn-hover" onClick={btnClickHandle}>
                    Sign Up Now
                  </button>
                ) : (
                  <></>
                )}
                <Switcher
                  className={`${isAuthorized ? 'switcher' : 'switcher switcher--dark'}`}
                  onSwitch={setPrice}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container item className="subscription-page__plan grid-wr" sm={11} lg={12}>
        {subscriptionMonthPlans && subscriptionYearPlans ? (
          modifyArray(isMonthly ? subscriptionMonthPlans : subscriptionYearPlans).map((item) => {
            return (
              <Grid
                key={item.id}
                className="subscription-page__card-wr"
                container
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
              >
                <PlanCard
                  card={item}
                  monthly={isMonthly}
                  isAuthorized={isAuthorized}
                  userRole={userRole}
                  initializeCardResponse={initializedCardResponse}
                  usersPaymentMethods={paymentMethodsState}
                  setPageLoading={setLoading}
                />
              </Grid>
            );
          })
        ) : (
          <div className="subscription-page--loading">
            <LoadingSpinner />
          </div>
        )}
      </Grid>
      {isAuthorized && <AddCredits isActiveUser={isActiveUser} isReader={userRole === 'Reader'} />}
      <div className="subsription-footer">
        <h2 className="subscription-page__title">Ready to Rock?</h2>
        <p>We are passionate about unleashing the potential of your marketing.</p>
        <p> Reach out to learn more about how you can get the most out of Marquètte.</p>
        <a href={`/contact-us`} className="orange-btn orange-btn-hover">
          Contact Us
        </a>
      </div>
      <Footer />
      {isLoading && (
        <div className="subscription-page--loading">
          <LoadingSpinner />
        </div>
      )}
      <AlertDialog
        isDialogOpen={isSubscribeError}
        setDialogOpen={() => setSubscribeError(!isSubscribeError)}
        text={{
          question: 'Your card expiration date is in the past',
          action: 'Add new card',
        }}
        confirmAction={handleAdd}
      />
      <StandardModal
        isOpen={isOpenCardAdding}
        title={'Add credit card'}
        subtitle={'Please enter your payment details.'}
        specificClass={'add-card-modal'}
        onClose={() => setOpenCardAdding(false)}
      >
        <DialogActions className="modal__buttons payment-modal-actions">
          {initializedCardResponse && (
            <Payment initializeCardResponse={initializedCardResponse}>
              <PaymentForm
                clientSecret={initializedCardResponse.clientSecret}
                setSavingCardSucceeded={setCardSavingSucceeded}
                btnText={'Add card'}
                isAccountPage={true}
                setOpenPopup={setOpenCardAdding}
                setDefault={false}
                setPaymentModalOpen={setOpenCardAdding}
              />
            </Payment>
          )}
          <StripeFooter />
        </DialogActions>
        {paymentMethodsState.loading && (
          <div className="modal__window--loading">
            <LoadingSpinner />
          </div>
        )}
      </StandardModal>
    </>
  );
};

export default SubscriptionPlan;
