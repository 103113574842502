// AREAS - ACTION TYPES
// =============================================================================
export const GET_AREAS_REQUEST = '[AREAS] GET_AREAS_REQUEST';
export const GET_AREAS_SUCCESS = '[AREAS] GET_AREAS_SUCCESS';
export const GET_AREAS_FAILURE = '[AREAS] GET_AREAS_FAILURE';

// INDUSTRIES - ACTION TYPES
// =============================================================================
export const GET_INDUSTRIES_REQUEST = '[INDUSTRIES] GET_INDUSTRIES_REQUEST';
export const GET_INDUSTRIES_SUCCESS = '[INDUSTRIES] GET_INDUSTRIES_SUCCESS';
export const GET_INDUSTRIES_FAILURE = '[INDUSTRIES] GET_INDUSTRIES_FAILURE';

// FAVORITES - ACTION TYPES
// =============================================================================
export const GET_FAVORITES_REQUEST = '[FAVORITES] GET_FAVORITES_REQUEST';
export const GET_FAVORITES_SUCCESS = '[FAVORITES] GET_FAVORITES_SUCCESS';
export const GET_FAVORITES_FAILURE = '[FAVORITES] GET_FAVORITES_FAILURE';

export const PUT_FAVORITES_REQUEST = '[FAVORITES] PUT_FAVORITES_REQUEST';
export const PUT_FAVORITES_SUCCESS = '[FAVORITES] PUT_FAVORITES_SUCCESS';
export const PUT_FAVORITES_FAILURE = '[FAVORITES] PUT_FAVORITES_FAILURE';

export const DELETE_FAVORITES_REQUEST = '[FAVORITES] DELETE_FAVORITES_REQUEST';
export const DELETE_FAVORITES_SUCCESS = '[FAVORITES] DELETE_FAVORITES_SUCCESS';
export const DELETE_FAVORITES_FAILURE = '[FAVORITES] DELETE_FAVORITES_FAILURE';

// AUTH-MSAL - ACTION TYPES
//=============================================================================
export const AAD_LOGOUT_SUCCESS = 'AAD_LOGOUT_SUCCESS';
export const AAD_LOGIN_SUCCESS = 'AAD_LOGIN_SUCCESS';

// USERPROFILE -ACTION TYPES
//=============================================================================
export const GET_USERPROFILE_REQUEST = '[USER] GET_USERPROFILE_REQUEST';
export const GET_USERPROFILE_SUCCESS = '[USER] GET_USERPROFILE_SUCCESS';
export const GET_USERPROFILE_FAILURE = '[USER] GET_USERPROFILE_FAILURE';

export const POST_UPDATEPROFILE_NAME_REQUEST = '[USER] POST_UPDATEPROFILE_NAME_REQUEST';
export const POST_UPDATEPROFILE_NAME_SUCCESS = '[USER] POST_UPDATEPROFILE_NAME_SUCCESS';
export const POST_UPDATEPROFILE_NAME_FAILURE = '[USER] POST_UPDATEPROFILE_NAME_FAILURE';

export const GET_USER_PHOTO_REQUEST = '[USER] GET_USER_PHOTO_REQUEST';
export const GET_USER_PHOTO_SUCCESS = '[USER] GET_USER_PHOTO_SUCCESS';
export const GET_USER_PHOTO_FAILURE = '[USER] GET_USER_PHOTO_FAILURE';

export const POST_UPDATEPROFILE_PHOTO_REQUEST = '[USER] POST_UPDATEPROFILE_PHOTO_REQUEST';
export const POST_UPDATEPROFILE_PHOTO_SUCCESS = '[USER] POST_UPDATEPROFILE_PHOTO_SUCCESS';
export const POST_UPDATEPROFILE_PHOTO_FAILURE = '[USER] POST_UPDATEPROFILE_PHOTO_FAILURE';

export const POST_INIT_USER_REQUEST = '[USER] POST_INIT_USER_REQUEST';
export const POST_INIT_USER_SUCCESS = '[USER] POST_INIT_USER_SUCCESS';
export const POST_INIT_USER_FAILURE = '[USER] POST_INIT_USER_FAILURE';

export const DELETE_USER_ACCOUNT_REQUEST = '[USER] DELETE_USER_ACCOUNT_REQUEST';
export const DELETE_USER_ACCOUNT_SUCCESS = '[USER] DELETE_USER_ACCOUNT_SUCCESS';
export const DELETE_USER_ACCOUNT_FAILURE = '[USER] DELETE_USER_ACCOUNT_FAILURE';

export const POST_USER_LASTLOGINDATE_REQUEST = '[USER] POST_USER_LASTLOGINDATE_REQUEST';
export const POST_USER_LASTLOGINDATE_SUCCESS = '[USER] POST_USER_LASTLOGINDATE_SUCCESS';
export const POST_USER_LASTLOGINDATE_FAILURE = '[USER] POST_USER_LASTLOGINDATE_FAILURE';

export const DELETE_USER_PHOTO_REQUEST = '[USER] DELETE_USER_PHOTO_REQUEST';
export const DELETE_USER_PHOTO_SUCCESS = '[USER] DELETE_USER_PHOTO_SUCCESS';
export const DELETE_USER_PHOTO_FAILURE = '[USER] DELETE_USER_PHOTO_FAILURE';

export const UPDATE_USER_CREDITS_REQUEST = '[USER] UPDATE_USER_CREDITS_REQUEST';

//NOTIFICATIONS
//=============================================================================
export const POST_PASSWORD_CHANGED_NOTIFICATION_REQUEST =
  '[NOTIFICATIONS] POST_PASSWORD_CHANGED_NOTIFICATION_REQUEST';
export const POST_PASSWORD_CHANGED_NOTIFICATION_SUCCESS =
  '[NOTIFICATIONS] POST_PASSWORD_CHANGED_NOTIFICATION_SUCCESS';
export const POST_PASSWORD_CHANGED_NOTIFICATION_FAILURE =
  '[NOTIFICATIONS] POST_PASSWORD_CHANGED_NOTIFICATION_FAILURE';

//USER_SETTINGS - ACTION TYPES
//=============================================================================
export const GET_USER_SETTINGS_REQUEST = '[USER_SETTINGS] GET_USER_SETTINGS_REQUEST';
export const GET_USER_SETTINGS_SUCCESS = '[USER_SETTINGS] GET_USER_SETTINGS_SUCCESS';
export const GET_USER_SETTINGS_FAILURE = '[USER_SETTINGS] GET_USER_SETTINGS_FAILURE';

export const PUT_USER_SETTINGS_REQUEST = '[USER_SETTINGS] PUT_USER_SETTINGS_REQUEST';
export const PUT_USER_SETTINGS_SUCCESS = '[USER_SETTINGS] PUT_USER_SETTINGS_SUCCESS';
export const PUT_USER_SETTINGS_FAILURE = '[USER_SETTINGS] PUT_USER_SETTINGS_FAILURE';

export const PATCH_USER_SETTINGS_REQUEST = '[USER_SETTINGS] PATCH_USER_SETTINGS_REQUEST';
export const PATCH_USER_SETTINGS_SUCCESS = '[USER_SETTINGS] PATCH_USER_SETTINGS_SUCCESS';
export const PATCH_USER_SETTINGS_FAILURE = '[USER_SETTINGS] PATCH_USER_SETTINGS_FAILURE';

//PAYMENT METHODS - ACTION TYPES
//=============================================================================
export const GET_PAYMENT_METHODS_REQUEST = '[PAYMENT_METHODS] GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = '[PAYMENT_METHODS] GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILURE = '[PAYMENT_METHODS] GET_PAYMENT_METHODS_FAILURE';

export const POST_INITIALIZE_PAYMENT_CARD_REQUEST =
  '[PAYMENT_METHODS] POST_INITIALIZE_PAYMENT_CARD_REQUEST';
export const POST_INITIALIZE_PAYMENT_CARD_SUCCESS =
  '[PAYMENT_METHODS] POST_INITIALIZE_PAYMENT_CARD_SUCCESS';
export const POST_INITIALIZE_PAYMENT_CARD_FAILURE =
  '[PAYMENT_METHODS] POST_INITIALIZE_PAYMENT_CARD_FAILURE';

export const POST_INITIALIZE_DEFAULT_PAYMENT_CARD_REQUEST =
  '[PAYMENT_METHODS] POST_INITIALIZE_DEFAULT_PAYMENT_CARD_REQUEST';
export const POST_INITIALIZE_DEFAULT_PAYMENT_CARD_SUCCESS =
  '[PAYMENT_METHODS] POST_INITIALIZE_DEFAULT_PAYMENT_CARD_SUCCESS';
export const POST_INITIALIZE_DEFAULT_PAYMENT_CARD_FAILURE =
  '[PAYMENT_METHODS] POST_INITIALIZE_DEFAULT_PAYMENT_CARD_FAILURE';

export const DELETE_PAYMENT_CARD_REQUEST = '[PAYMENT_METHODS] DELETE_PAYMENT_CARD_REQUEST';
export const DELETE_PAYMENT_CARD_SUCCESS = '[PAYMENT_METHODS] DELETE_PAYMENT_CARD_SUCCESS';
export const DELETE_PAYMENT_CARD_FAILURE = '[PAYMENT_METHODS] DELETE_PAYMENT_CARD_FAILURE';

//BILLING - ACTION TYPES
//=============================================================================
export const GET_CREDIT_ITEMS_REQUEST = '[BILLING] GET_CREDIT_ITEMS_REQUEST';
export const GET_CREDIT_ITEMS_SUCCESS = '[BILLING] GET_CREDIT_ITEMS_SUCCESS';
export const GET_CREDIT_ITEMS_FAILURE = '[BILLING] GET_CREDIT_ITEMS_FAILURE';

export const POST_SUBSCRIPTIONS_REQUEST = '[BILLING] POST_SUBSCRIPTIONS_REQUEST';
export const POST_SUBSCRIPTIONS_SUCCESS = '[BILLING] POST_SUBSCRIPTIONS_SUCCESS';
export const POST_SUBSCRIPTIONS_FAILURE = '[BILLING] POST_SUBSCRIPTIONS_FAILURE';

//BILLING HISTORY - ACTION TYPES
//=============================================================================
export const GET_BILLING_HISTORY_REQUEST = '[BILLING] GET_BILLING_HISTORY_REQUEST';
export const GET_BILLING_HISTORY_SUCCESS = '[BILLING] GET_BILLING_HISTORY_SUCCESS';
export const GET_BILLING_HISTORY_FAILURE = '[BILLING] GET_BILLING_HISTORY_FAILURE';

//BILLING BUYING CREDITS - ACTION TYPES
//=============================================================================
export const GET_CREDITS_DETAILS_REQUEST = '[BILLING] GET_CREDITS_DETAILS_REQUEST';
export const GET_CREDITS_DETAILS_SUCCESS = '[BILLING] GET_CREDITS_DETAILS_SUCCESS';
export const GET_CREDITS_DETAILS_FAILURE = '[BILLING] GET_CREDITS_DETAILS_FAILURE';

export const POST_BUY_CREDITS_REQUEST = '[BILLING] POST_BUY_CREDITS_REQUEST';
export const POST_BUY_CREDITS_SUCCESS = '[BILLING] POST_BUY_CREDITS_SUCCESS';
export const POST_BUY_CREDITS_FAILURE = '[BILLING] POST_BUY_CREDITS_FAILURE';

// SUBSCRIPTIONS - ACTION TYPES
//=============================================================================
export const GET_USER_SUBSCRIPTION_REQUEST = '[USER_SUBSCRIPTION] GET_USER_SUBSCRIPTION_REQUEST';
export const GET_USER_SUBSCRIPTION_SUCCESS = '[USER_SUBSCRIPTION] GET_USER_SUBSCRIPTION_SUCCESS';
export const GET_USER_SUBSCRIPTION_FAILURE = '[USER_SUBSCRIPTION] GET_USER_SUBSCRIPTION_FAILURE';

// SUBSCRIPTIONS FOR REGISTERED USERS - ACTION TYPES
//=============================================================================
export const GET_REGISTERED_USER_SUBSCRIPTION_REQUEST =
  '[REGISTERED_USER_SUBSCRIPTION] GET_REGISTERED_USER_SUBSCRIPTION_REQUEST';
export const GET_REGISTERED_USER_SUBSCRIPTION_SUCCESS =
  '[REGISTERED_USER_SUBSCRIPTION] GET_REGISTERED_USER_SUBSCRIPTION_SUCCESS';
export const GET_REGISTERED_USER_SUBSCRIPTION_FAILURE =
  '[REGISTERED_USER_SUBSCRIPTION] GET_REGISTERED_USER_SUBSCRIPTION_FAILURE';

export const POST_ADD_CREDITS_ADMIN_REQUEST = '[POST_ADD_CREDITS_ADMIN_REQUEST';
export const POST_ADD_CREDITS_ADMIN_SUCCESS = '[POST_ADD_CREDITS_ADMIN_SUCCESS';
export const POST_ADD_CREDITS_ADMIN_FAILURE = '[POST_ADD_CREDITS_ADMIN_FAILURE';

// USER MANAGEMENT - ACTION TYPES
//=============================================================================
export const GET_USER_MANAGEMENT_REQUEST = '[USER_MANAGEMENT] GET_USER_MANAGEMENT_REQUEST';
export const GET_USER_MANAGEMENT_SUCCESS = '[USER_MANAGEMENT] GET_USER_MANAGEMENT_SUCCESS';
export const GET_USER_MANAGEMENT_FAILURE = '[USER_MANAGEMENT] GET_USER_MANAGEMENT_FAILURE';

export const GET_ALL_USER_MANAGEMENT_REQUEST = '[USER_MANAGEMENT] GET_ALL_USER_MANAGEMENT_REQUEST';
export const GET_ALL_USER_MANAGEMENT_SUCCESS = '[USER_MANAGEMENT] GET_ALL_USER_MANAGEMENT_SUCCESS';
export const GET_ALL_USER_MANAGEMENT_FAILURE = '[USER_MANAGEMENT] GET_ALL_USER_MANAGEMENT_FAILURE';

export const GET_COMPANIES_REQUEST = '[USER_MANAGEMENT] GET_COMPANIES_REQUEST';
export const GET_COMPANIES_SUCCESS = '[USER_MANAGEMENT] GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURE = '[USER_MANAGEMENT] GET_COMPANIES_FAILURE';

export const GET_SINGLE_USER_REQUEST = '[USER_MANAGEMENT] GET_SINGLE_USER_REQUEST';
export const GET_SINGLE_USER_SUCCESS = '[USER_MANAGEMENT] GET_SINGLE_USER_SUCCESS';
export const GET_SINGLE_USER_FAILURE = '[USER_MANAGEMENT] GET_SINGLE_USER_FAILURE';

// USER MANAGEMENT ADDING SUBSCRIPTION - ACTION TYPES
//=============================================================================
export const GET_SUBSCRIPTIONS_ADMIN_REQUEST = '[USER_MANAGEMENT] GET_SUBSCRIPTIONS_ADMIN_REQUEST';
export const GET_SUBSCRIPTIONS_ADMIN_SUCCESS = '[USER_MANAGEMENT] GET_SUBSCRIPTIONS_ADMIN_SUCCESS';
export const GET_SUBSCRIPTIONS_ADMIN_FAILURE = '[USER_MANAGEMENT] GET_SUBSCRIPTIONS_ADMIN_FAILURE';

export const POST_ADD_SUBSCRIPTION_ADMIN_REQUEST =
  '[USER_MANAGEMENT] POST_ADD_SUBSCRIPTION_ADMIN_REQUEST';
export const POST_ADD_SUBSCRIPTION_ADMIN_SUCCESS =
  '[USER_MANAGEMENT] POST_ADD_SUBSCRIPTION_ADMIN_SUCCESS';
export const POST_ADD_SUBSCRIPTION_ADMIN_FAILURE =
  '[USER_MANAGEMENT] POST_ADD_SUBSCRIPTION_ADMIN_FAILURE';

// SUBSCRIPTIONS RENEWAL- ACTION TYPES
//=============================================================================
export const POST_USER_SUBSCRIPTION_RENEWAL_REQUEST =
  '[USER_SUBSCRIPTION] POST_USER_SUBSCRIPTION_RENEWAL_REQUEST';
export const POST_USER_SUBSCRIPTION_RENEWAL_SUCCESS =
  '[USER_SUBSCRIPTION] POST_USER_SUBSCRIPTION_RENEWAL_SUCCESS';
export const POST_USER_SUBSCRIPTION_RENEWAL_FAILURE =
  '[USER_SUBSCRIPTION] POST_USER_SUBSCRIPTION_RENEWAL_FAILURE';

// CONTACT US - ACTION TYPES
//=============================================================================
export const POST_SEND_CONTACT_US_FORM_REQUEST = '[CONTACT_US] POST_SEND_CONTACT_US_FORM_REQUEST';
export const POST_SEND_CONTACT_US_FORM_SUCCESS = '[CONTACT_US] POST_SEND_CONTACT_US_FORM_SUCCESS';
export const POST_SEND_CONTACT_US_FORM_FAILURE = '[CONTACT_US] POST_SEND_CONTACT_US_FORM_FAILURE';

// COUPONS - ACTION TYPES
//=============================================================================
export const POST_CREATE_COUPON_REQUEST = '[COUPON] POST_CREATE_COUPON_REQUEST';
export const POST_CREATE_COUPON_SUCCESS = '[COUPON] POST_CREATE_COUPON_SUCCESS';
export const POST_CREATE_COUPON_FAILURE = '[COUPON] POST_CREATE_COUPON_FAILURE';

export const GET_COUPONS_MANAGEMENT_REQUEST = '[COUPON] GET_COUPONS_MANAGEMENT_REQUEST';
export const GET_COUPONS_MANAGEMENT_SUCCESS = '[COUPON] GET_COUPONS_MANAGEMENT_SUCCESS';
export const GET_COUPONS_MANAGEMENT_FAILURE = '[COUPON] GET_COUPONS_MANAGEMENT_FAILURE';

export const POST_APPLY_COUPON_REQUEST = '[COUPON] POST_APPLY_COUPON_REQUEST';
export const POST_APPLY_COUPON_SUCCESS = '[COUPON] POST_APPLY_COUPON_SUCCESS';
export const POST_APPLY_COUPON_FAILURE = '[COUPON] POST_APPLY_COUPON_FAILURE';

export const GET_COUPONS_HISTORY_REQUEST = '[COUPON] GET_COUPONS_HISTORY_REQUEST';
export const GET_COUPONS_HISTORY_SUCCESS = '[COUPON] GET_COUPONS_HISTORY_SUCCESS';
export const GET_COUPONS_HISTORY_FAILURE = '[COUPON] GET_COUPONS_HISTORY_FAILURE';

export const GET_COUPON_BY_ID_REQUEST = '[COUPON] GET_COUPON_BY_ID_REQUEST';
export const GET_COUPON_BY_ID_SUCCESS = '[COUPON] GET_COUPON_BY_ID_SUCCESS';
export const GET_COUPON_BY_ID_FAILURE = '[COUPON] GET_COUPON_BY_ID_FAILURE';

export const POST_CHECK_COUPON_REQUEST = '[COUPON] POST_CHECK_COUPON_REQUEST';
export const POST_CHECK_COUPON_SUCCESS = '[COUPON] POST_CHECK_COUPON_SUCCESS';
export const POST_CHECK_COUPON_FAILURE = '[COUPON] POST_CHECK_COUPON_FAILURE';

export const POST_CHECK_COUPON_WITHOUT_APPLY_REQUEST =
  '[COUPON] POST_CHECK_COUPON_WITHOUT_APPLY_REQUEST';
export const POST_CHECK_COUPON_WITHOUT_APPLY_SUCCESS =
  '[COUPON] POST_CHECK_COUPON_WITHOUT_APPLY_SUCCESS';
export const POST_CHECK_COUPON_WITHOUT_APPLY_FAILURE =
  '[COUPON] POST_CHECK_COUPON_WITHOUT_APPLY_FAILURE';

export const PUT_UPDATE_COUPON_REQUEST = '[COUPON] PUT_UPDATE_COUPON_REQUEST';
export const PUT_UPDATE_COUPON_SUCCESS = '[COUPON] PUT_UPDATE_COUPON_SUCCESS';
export const PUT_UPDATE_COUPON_FAILURE = '[COUPON] PUT_UPDATE_COUPON_FAILURE';

// SignalR Step Status

export const SET_SIGNALR_STEP_REQUEST = '[SIGNALR] SET_SIGNALR_STEP_REQUEST';
export const SET_SIGNALR_STEP_SUCCESS = '[SIGNALR] SET_SIGNALR_STEP_SUCCESS';
export const SET_SIGNALR_STEP_FAILURE = '[SIGNALR] SET_SIGNALR_STEP_FAILURE';

// SignalR Cell Edit Status

export const SET_SIGNALR_CELL_EDIT_REQUEST = '[SIGNALR] SET_SIGNALR_CELL_EDIT_REQUEST';
export const SET_SIGNALR_CELL_EDIT_SUCCESS = '[SIGNALR] SET_SIGNALR_CELL_EDIT_SUCCESS';
export const SET_SIGNALR_CELL_EDIT_FAILURE = '[SIGNALR] SET_SIGNALR_CELL_EDIT_FAILURE';

// Text Model Template

export const GET_TEXT_MODEL_TEMPLATE_REQUEST = '[TEXT_MODELS] GET_TEXT_MODEL_TEMPLATE_REQUEST';
export const GET_TEXT_MODEL_TEMPLATE_SUCCESS = '[TEXT_MODELS] GET_TEXT_MODEL_TEMPLATE_SUCCESS';
export const GET_TEXT_MODEL_TEMPLATE_FAILURE = '[TEXT_MODELS] GET_TEXT_MODEL_TEMPLATE_FAILURE';

export const POST_TEXT_MODEL_CREATE_REQUEST = '[TEXT_MODELS] POST_TEXT_MODEL_CREATE_REQUEST';
export const POST_TEXT_MODEL_CREATE_SUCCESS = '[TEXT_MODELS] POST_TEXT_MODEL_CREATE_SUCCESS';
export const POST_TEXT_MODEL_CREATE_FAILURE = '[TEXT_MODELS] POST_TEXT_MODEL_CREATE_FAILURE';

export const GET_TEXT_MODEL_DETAILS_REQUEST = '[TEXT_MODELS] GET_TEXT_MODEL_DETAILS_REQUEST';
export const GET_TEXT_MODEL_DETAILS_SUCCESS = '[TEXT_MODELS] GET_TEXT_MODEL_DETAILS_SUCCESS';
export const GET_TEXT_MODEL_DETAILS_FAILURE = '[TEXT_MODELS] GET_TEXT_MODEL_DETAILS_FAILURE';

export const SET_TEXT_MODEL_DETAILS_CLEAR = '[TEXT_MODELS] SET_TEXT_MODEL_DETAILS_CLEAR';

export const GET_MODEL_TEMPLATE_DETAILS_REQUEST =
  '[TEXT_MODELS] GET_MODEL_TEMPLATE_DETAILS_REQUEST';
export const GET_MODEL_TEMPLATE_DETAILS_SUCCESS =
  '[TEXT_MODELS] GET_MODEL_TEMPLATE_DETAILS_SUCCESS';
export const GET_MODEL_TEMPLATE_DETAILS_FAILURE =
  '[TEXT_MODELS] GET_MODEL_TEMPLATE_DETAILS_FAILURE';

export const POST_MODEL_NEXT_STEP_REQUEST = '[TEXT_MODELS] POST_MODEL_NEXT_STEP_REQUEST';
export const POST_MODEL_NEXT_STEP_SUCCESS = '[TEXT_MODELS] POST_MODEL_NEXT_STEP_SUCCESS';
export const POST_MODEL_NEXT_STEP_FAILURE = '[TEXT_MODELS] POST_MODEL_NEXT_STEP_FAILURE';

export const CLEAR_CREATED_MODEL_REQUEST = '[TEXT_MODELS] CLEAR_CREATED_MODEL_REQUEST';

export const POST_CANCEL_MODEL_REQUEST = '[TEXT_MODELS] POST_CANCEL_MODEL_REQUEST';
export const POST_CANCEL_MODEL_SUCCESS = '[TEXT_MODELS] POST_CANCEL_MODEL_SUCCESS';
export const POST_CANCEL_MODEL_FAILURE = '[TEXT_MODELS] POST_CANCEL_MODEL_FAILURE';

export const GET_TEXT_MODELS_REQUEST = '[TEXT_MODELS] GET_TEXT_MODELS_REQUEST';
export const GET_TEXT_MODELS_SUCCESS = '[TEXT_MODELS] GET_TEXT_MODELS_SUCCESS';
export const GET_TEXT_MODELS_FAILURE = '[TEXT_MODELS] GET_TEXT_MODELS_FAILURE';

export const CLEAR_MODEL_DETAILS_REQUEST = '[TEXT_MODELS] CLEAR_MODEL_DETAILS_REQUEST';

export const PATCH_MODEL_FEEDBACK_REQUEST = '[TEXT_MODELS] PATCH_MODEL_FEEDBACK_REQUEST';
export const PATCH_MODEL_FEEDBACK_SUCCESS = '[TEXT_MODELS] PATCH_MODEL_FEEDBACK_SUCCESS';
export const PATCH_MODEL_FEEDBACK_FAILURE = '[TEXT_MODELS] PATCH_MODEL_FEEDBACK_FAILURE';

export const PUT_LIKE_MODEL_REQUEST = '[TEXT_MODELS] PUT_LIKE_MODEL_REQUEST';
export const PUT_LIKE_MODEL_SUCCESS = '[TEXT_MODELS] PUT_LIKE_MODEL_SUCCESS';
export const PUT_LIKE_MODEL_FAILURE = '[TEXT_MODELS] PUT_LIKE_MODEL_FAILURE';

export const GET_PUBLIC_TEXT_MODEL_REQUEST = '[TEXT_MODELS] GET_PUBLIC_TEXT_MODEL_REQUEST';
export const GET_PUBLIC_TEXT_MODEL_SUCCESS = '[TEXT_MODELS] GET_PUBLIC_TEXT_MODEL_SUCCESS';
export const GET_PUBLIC_TEXT_MODEL_FAILURE = '[TEXT_MODELS] GET_PUBLIC_TEXT_MODEL_FAILURE';

export const POST_SHARE_TEXT_MODEL_REQUEST = '[TEXT_MODELS] POST_SHARE_TEXT_MODEL_REQUEST';
export const POST_SHARE_TEXT_MODEL_SUCCESS = '[TEXT_MODELS] POST_SHARE_TEXT_MODEL_SUCCESS';
export const POST_SHARE_TEXT_MODEL_FAILURE = '[TEXT_MODELS] POST_SHARE_TEXT_MODEL_FAILURE';

export const POST_UNSHARE_TEXT_MODEL_REQUEST = '[TEXT_MODELS] POST_UNSHARE_TEXT_MODEL_REQUEST';
export const POST_UNSHARE_TEXT_MODEL_SUCCESS = '[TEXT_MODELS] POST_UNSHARE_TEXT_MODEL_SUCCESS';
export const POST_UNSHARE_TEXT_MODEL_FAILURE = '[TEXT_MODELS] POST_UNSHARE_TEXT_MODEL_FAILURE';

export const POST_UPLOAD_FILE_REQUEST = '[TEXT_MODELS] POST_UPLOAD_FILE_REQUEST';
export const POST_UPLOAD_FILE_SUCCESS = '[TEXT_MODELS] POST_UPLOAD_FILE_SUCCESS';
export const POST_UPLOAD_FILE_FAILURE = '[TEXT_MODELS] POST_UPLOAD_FILE_FAILURE';

export const SET_ARCHIVE_STATUS_MODEL_REQUEST = '[TEXT_MODELS] SET_ARCHIVE_STATUS_MODEL_REQUEST';
export const SET_ARCHIVE_STATUS_MODEL_SUCCESS = '[TEXT_MODELS] SET_ARCHIVE_STATUS_MODEL_SUCCESS';
export const SET_ARCHIVE_STATUS_MODEL_FAILURE = '[TEXT_MODELS] SET_ARCHIVE_STATUS_MODEL_FAILURE';

export const SHOW_ALERT = '[ALERT] SHOW_ALERT';
export const ADD_ALERT = '[ALERT] ADD_ALERT';
export const REMOVE_ALERT = '[ALERT] REMOVE_ALERT';

export const REMOVE_TEXT_MODEL_MANUALLY = '[TEXT_MODELS] REMOVE_TEXT_MODEL_MANUALLY';

export const GET_TEXT_MODELS_FOR_LANDING_REQUEST =
  '[TEXT_MODELS] GET_TEXT_MODELS_FOR_LANDING_REQUEST';
export const GET_TEXT_MODELS_FOR_LANDING_SUCCESS =
  '[TEXT_MODELS] GET_TEXT_MODELS_FOR_LANDING_SUCCESS';
export const GET_TEXT_MODELS_FOR_LANDING_FAILURE =
  '[TEXT_MODELS] GET_TEXT_MODELS_FOR_LANDING_FAILURE';

export const GET_TEXT_GENERATION_RESULT_REQUEST =
  '[TEXT_MODELS] GET_TEXT_GENERATION_RESULT_REQUEST';
export const GET_TEXT_GENERATION_RESULT_SUCCESS =
  '[TEXT_MODELS] GET_TEXT_GENERATION_RESULT_SUCCESS';
export const GET_TEXT_GENERATION_RESULT_FAILURE =
  '[TEXT_MODELS] GET_TEXT_GENERATION_RESULT_FAILURE';

export const GET_TEXT_MODEL_ENTRIES_REQUEST = '[TEXT_MODELS] GET_TEXT_MODEL_ENTRIES_REQUEST';
export const GET_TEXT_MODEL_ENTRIES_SUCCESS = '[TEXT_MODELS] GET_TEXT_MODEL_ENTRIES_SUCCESS';
export const GET_TEXT_MODEL_ENTRIES_FAILURE = '[TEXT_MODELS] GET_TEXT_MODEL_ENTRIES_FAILURE';

export const GET_TEXT_MODEL_CATEGORIES_REQUEST = '[TEXT_MODELS] GET_TEXT_MODEL_CATEGORIES_REQUEST';
export const GET_TEXT_MODEL_CATEGORIES_SUCCESS = '[TEXT_MODELS] GET_TEXT_MODEL_CATEGORIES_SUCCESS';
export const GET_TEXT_MODEL_CATEGORIES_FAILURE = '[TEXT_MODELS] GET_TEXT_MODEL_CATEGORIES_FAILURE';

export const GET_TEXT_MODEL_STATISTIC_REQUEST = '[TEXT_MODELS] GET_TEXT_MODEL_STATISTIC_REQUEST';
export const GET_TEXT_MODEL_STATISTIC_SUCCESS = '[TEXT_MODELS] GET_TEXT_MODEL_STATISTIC_SUCCESS';
export const GET_TEXT_MODEL_STATISTIC_FAILURE = '[TEXT_MODELS] GET_TEXT_MODEL_STATISTIC_FAILURE';

// SUBSCRIPTIONS REFUND- ACTION TYPES
//=============================================================================
export const POST_USER_SUBSCRIPTION_REFUND_REQUEST =
  '[USER_SUBSCRIPTION] POST_USER_SUBSCRIPTION_REFUND_REQUEST';
export const POST_USER_SUBSCRIPTION_REFUND_SUCCESS =
  '[USER_SUBSCRIPTION] POST_USER_SUBSCRIPTION_REFUND_SUCCESS';
export const POST_USER_SUBSCRIPTION_REFUND_FAILURE =
  '[USER_SUBSCRIPTION] POST_USER_SUBSCRIPTION_REFUND_FAILURE';

export const UPDATE_TEXT_MODEL_DETAILS_HISTORY = '[TEXT_MODELS] UPDATE_TEXT_MODEL_DETAILS_HISTORY';

//  ENTRY - ACTION TYPES
//=============================================================================
export const POST_CREATE_MODEL_ENTRY_REQUEST = '[ENTRY] POST_CREATE_MODEL_ENTRY_REQUEST';
export const POST_CREATE_MODEL_ENTRY_SUCCESS = '[ENTRY] POST_CREATE_MODEL_ENTRY_SUCCESS';
export const POST_CREATE_MODEL_ENTRY_FAILURE = '[ENTRY] POST_CREATE_MODEL_ENTRY_FAILURE';
export const SET_CREATE_MODEL_ENTRY_NAVIGATE = '[ENTRY] SET_CREATE_MODEL_ENTRY_NAVIGATE';

export const GET_TEXT_MODEL_ENTRY_REQUEST = '[ENTRY] GET_TEXT_MODEL_ENTRY_REQUEST';
export const GET_TEXT_MODEL_ENTRY_SUCCESS = '[ENTRY] GET_TEXT_MODEL_ENTRY_SUCCESS';
export const GET_TEXT_MODEL_ENTRY_FAILURE = '[ENTRY] GET_TEXT_MODEL_ENTRY_FAILURE';

export const UPDATE_MODEL_ENTRY_REQUEST = '[ENTRY] UPDATE_MODEL_ENTRY_REQUEST';
export const UPDATE_MODEL_ENTRY_SUCCESS = '[ENTRY] UPDATE_MODEL_ENTRY_SUCCESS';
export const UPDATE_MODEL_ENTRY_FAILURE = '[ENTRY] UPDATE_MODEL_ENTRY_FAILURE';

export const PUBLISH_MODEL_ENTRY_REQUEST = '[ENTRY] PUBLISH_MODEL_ENTRY_REQUEST';
export const PUBLISH_MODEL_ENTRY_SUCCESS = '[ENTRY] PUBLISH_MODEL_ENTRY_SUCCESS';
export const PUBLISH_MODEL_ENTRY_FAILURE = '[ENTRY] PUBLISH_MODEL_ENTRY_FAILURE';

export const SET_MODEL_ENTRY_CLEAR = '[ENTRY] SET_MODEL_ENTRY_CLEAR';
export const SET_NAVIGATE_TO_ENTRY = '[ENTRY] SET_NAVIGATE_TO_ENTRY';
export const SET_CLEAR_NAVIGATE_TO_ENTRY = '[ENTRY] SET_CLEAR_NAVIGATE_TO_ENTRY';

export const DELETE_TEXT_MODEL_ENTRY_REQUEST = '[ENTRY] DELETE_TEXT_MODEL_ENTRY_REQUEST';
export const DELETE_TEXT_MODEL_ENTRY_SUCCESS = '[ENTRY] DELETE_TEXT_MODEL_ENTRY_SUCCESS';
export const DELETE_TEXT_MODEL_ENTRY_FAILURE = '[ENTRY] DELETE_TEXT_MODEL_ENTRY_FAILURE';

export const GENERATE_TEXT_MODEL_REQUEST = '[ENTRY] GENERATE_TEXT_MODEL_REQUEST';
export const GENERATE_TEXT_MODEL_SUCCESS = '[ENTRY] GENERATE_TEXT_MODEL_SUCCESS';
export const GENERATE_TEXT_MODEL_FAILURE = '[ENTRY] GENERATE_TEXT_MODEL_FAILURE';

export const GET_TEXT_MODEL_ENTRIES_IMAGES_REQUEST =
  '[ENTRY] GET_TEXT_MODEL_ENTRIES_IMAGES_REQUEST';
export const GET_TEXT_MODEL_ENTRIES_IMAGES_SUCCESS =
  '[ENTRY] GET_TEXT_MODEL_ENTRIES_IMAGES_SUCCESS';
export const GET_TEXT_MODEL_ENTRIES_IMAGES_FAILURE =
  '[ENTRY] GET_TEXT_MODEL_ENTRIES_IMAGES_FAILURE';

export const PATCH_TEXT_MODEL_AUTOSAVE_REQUEST = '[TEXT_MODELS] PATCH_TEXT_MODEL_AUTOSAVE_REQUEST';
export const PATCH_TEXT_MODEL_AUTOSAVE_SUCCESS = '[TEXT_MODELS] PATCH_TEXT_MODEL_AUTOSAVE_SUCCESS';
export const PATCH_TEXT_MODEL_AUTOSAVE_FAILURE = '[TEXT_MODELS] PATCH_TEXT_MODEL_AUTOSAVE_FAILURE';

//  COMMANDS - ACTION TYPES
//=============================================================================
export const GET_COMMANDS_LIST_REQUEST = '[COMMANDS] GET_COMMANDS_LIST_REQUEST';
export const GET_COMMANDS_LIST_SUCCESS = '[COMMANDS] GET_COMMANDS_LIST_SUCCESS';
export const GET_COMMANDS_LIST_FAILURE = '[COMMANDS] GET_COMMANDS_LIST_FAILURE';

export const GET_COMMAND_ITEM_REQUEST = '[COMMANDS] GET_COMMAND_ITEM_REQUEST';
export const GET_COMMAND_ITEM_SUCCESS = '[COMMANDS] GET_COMMAND_ITEM_SUCCESS';
export const GET_COMMAND_ITEM_FAILURE = '[COMMANDS] GET_COMMAND_ITEM_FAILURE';

export const POST_COMMAND_CREATE_REQUEST = '[COMMANDS] POST_COMMAND_CREATE_REQUEST';
export const POST_COMMAND_CREATE_SUCCESS = '[COMMANDS] POST_COMMAND_CREATE_SUCCESS';
export const POST_COMMAND_CREATE_FAILURE = '[COMMANDS] POST_COMMAND_CREATE_FAILURE';

export const PUT_COMMAND_UPDATE_REQUEST = '[COMMANDS] PUT_COMMAND_UPDATE_REQUEST';
export const PUT_COMMAND_UPDATE_SUCCESS = '[COMMANDS] PUT_COMMAND_UPDATE_SUCCESS';
export const PUT_COMMAND_UPDATE_FAILURE = '[COMMANDS] PUT_COMMAND_UPDATE_FAILURE';

export const PATCH_PUBLISH_COMMAND_REQUEST = '[COMMANDS] PATCH_PUBLISH_COMMAND_REQUEST';
export const PATCH_PUBLISH_COMMAND_SUCCESS = '[COMMANDS] PATCH_PUBLISH_COMMAND_SUCCESS';
export const PATCH_PUBLISH_COMMAND_FAILURE = '[COMMANDS] PATCH_PUBLISH_COMMAND_FAILURE';

export const DELETE_COMMAND_REQUEST = '[COMMANDS] DELETE_COMMAND_REQUEST';
export const DELETE_COMMAND_SUCCESS = '[COMMANDS] DELETE_COMMAND_SUCCESS';
export const DELETE_COMMAND_FAILURE = '[COMMANDS] DELETE_COMMAND_FAILURE';

export const SET_CREATE_COMMAND_NAVIGATE = '[COMMANDS] SET_CREATE_COMMAND_NAVIGATE';

export const CLEAR_COMMAND_ITEM = '[COMMANDS] CLEAR_COMMAND_ITEM';
