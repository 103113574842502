import React from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {Grid, Hidden} from '@material-ui/core';
import {Header} from '../../components/Header';
import SideBar from '../../components/SideBar';
import Dashboard from '../../components/Dashboard';
import ExpiredPlan from '../../components/ExpiredPlan';
import {useActiveUser} from '../../helpers/customHooks';
import {MyContent} from '../../components/MyContent';
import Footer from '../../components/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import OutOfCredits from '../../components/OutOfCredits';

export const Main = (): JSX.Element => {
  const location = useLocation();
  const url = location.pathname;
  const sidebarSettings = {openOnPage: ['areas']};
  const {isExpired} = useActiveUser();

  return (
    <div className="main-page client-page">
      <Header showLogo={true} adminSide={false} />
      <Grid container className="gridContainer main-page__content">
        <Grid item xs={12} md={11} lg={10}>
          <Grid container direction="row" className="mainContainer">
            <Hidden only={['xs', 'sm']}>
              <Grid item md={3} lg={2}>
                {location && (
                  <SideBar url={url} location={location} sidebarSettings={sidebarSettings} />
                )}
              </Grid>
            </Hidden>
            <Grid
              item
              xs={12}
              sm={11}
              md={9}
              lg={10}
              className={`component-white-wr ${url === '/main/dashboard' ? 'transparent' : ''}`}
            >
              <Routes>
                <Route path={`out-of-credits`} element={<OutOfCredits />} />
                <Route path={`expired-subscription`} element={<ExpiredPlan />} />
                <Route path={`dashboard`} element={<Dashboard />} />
                <Route
                  path={`my-Content`}
                  element={<MyContent isFavorite={false} isArchived={false} />}
                />
                <Route
                  path={`archive`}
                  element={<MyContent isFavorite={false} isArchived={true} />}
                />
                <Route
                  path={`favorites`}
                  element={<MyContent isFavorite={true} isArchived={false} />}
                />
                {isExpired && (
                  <>
                    <Route path="/archive" element={<Navigate to="/main/dashboard" replace />} />
                    <Route path="/favorites" element={<Navigate to="/main/dashboard" replace />} />
                    <Route path="/history" element={<Navigate to="/main/dashboard" replace />} />
                    <Route
                      path="/help-center"
                      element={<Navigate to="/main/dashboard" replace />}
                    />
                    {/*<Redirect from="/archive" to="/main/home" />*/}
                    {/*<Redirect from="/favorites" to="/main/home" />*/}
                    {/*<Redirect from="/history" to="/main/home" />*/}
                    {/*<Redirect from="/help-center" to="/main/home" />*/}
                  </>
                )}
              </Routes>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};
export default Main;
