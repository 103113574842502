import './style.scss';
import NoContentImg from '../../../images/empty-content.png';

interface IProps {
  text: string;
}

const EmptyContent = (props: IProps): JSX.Element => {
  const {text} = props;

  return (
    <div className="no-content">
      <div className="no-content__img">
        <img src={NoContentImg} alt="Empty Content" />
      </div>
      <div className="no-content__text" dangerouslySetInnerHTML={{__html: text}} />
    </div>
  );
};

export default EmptyContent;
