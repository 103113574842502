import React, {useState, useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {AppBar, Tabs, Tab, Typography, Button, Box} from '@material-ui/core';
import EmailNotification from './EmailNotification';
import DesktopNotification from './DesktopNotification';
import DataPrivacy from './DataPrivacy';
import DeleteAccount from './DeleteAccount';
import NotificationCheckboxes from './NotificationCheckboxes/ActionableNotification';
import NewsLettersNotification from './NotificationCheckboxes/NewsLettersNotification';
import LiveNotification from './NotificationCheckboxes/LiveNotification';
import {IInitNotification, IState} from '../../../../interfaces';
import {initNotification} from '../../../Settings';

import './style.scss';
import {LightTooltip} from '../../../UI/StatusLabel';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ISettingSideBarProps {
  className?: string;
  dataPrivacy?: boolean;
  adminManagment?: boolean;
  handleAdminManagement?: () => void;
}

function TabPanel(props: TabPanelProps) {
  const {children, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>
        <Typography variant="inherit">{children}</Typography>
      </Box>
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SettingSideBar = (props: ISettingSideBarProps): JSX.Element => {
  const {className, dataPrivacy, adminManagment, handleAdminManagement} = props;
  const userRole = useSelector((state: IState) => state.userProfile.userProfile.userRole);
  const userSettings = useSelector((state: IState) => state.userSettings.userSettings);

  const [value, setValue] = useState(0);
  const emailRef = useRef<HTMLDivElement | null>(null);
  const desctopRef = useRef<HTMLDivElement | null>(null);
  const dataPrivacyRef = useRef<HTMLDivElement | null>(null);
  const deleteRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [checkboxNotification, setCheckboxNotification] =
    useState<IInitNotification[]>(initNotification);
  const [checkboxNotificationMarquette, setCheckboxNotificationMarquette] =
    useState<IInitNotification[]>(initNotification);

  const handleChangeSummary = (text: string) => {
    const newCheckboxNotification = checkboxNotification.map((item: IInitNotification) => ({
      title: item.title,
      checkboxes: item.checkboxes.map((el) =>
        el.text === text ? {text: el.text, checked: !el.checked} : el,
      ),
    }));

    setCheckboxNotification(newCheckboxNotification);
  };

  const handleChangeMarquette = (text: string) => {
    const newCheckboxNotification = checkboxNotificationMarquette.map(
      (item: IInitNotification) => ({
        title: item.title,
        checkboxes: item.checkboxes.map((el) =>
          el.text === text ? {text: el.text, checked: !el.checked} : el,
        ),
      }),
    );

    setCheckboxNotificationMarquette(newCheckboxNotification);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => handleScrollPage());
    return window.removeEventListener('scroll', () => handleScrollPage());
  }, []);

  const emailDiv = emailRef.current || 0;
  const desctopDiv = desctopRef.current || 0;
  const dataPrivacyDiv = dataPrivacyRef.current || 0;
  const deleteDiv = deleteRef.current || 0;
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (emailDiv && desctopDiv && deleteDiv) {
      switch (newValue) {
        case 0:
          window.scrollTo(0, emailDiv.offsetTop - 200);
          break;
        case 1:
          window.scrollTo(0, desctopDiv.offsetTop - 150);
          break;
        case 2:
          window.scrollTo(
            0,
            dataPrivacyDiv ? dataPrivacyDiv.offsetTop - 200 : deleteDiv.offsetTop - 200,
          );
          break;
        case 3:
          window.scrollTo(0, deleteDiv.offsetTop - 200);
          break;
        default:
          return;
      }
    }
  };

  let headerTabElements = [];
  dataPrivacy
    ? (headerTabElements = [
        'Email Notifications',
        'Marquètte Notifications',
        'Data privacy',
        'Delete Account',
      ])
    : (headerTabElements = ['Email Notifications', 'Marquètte Notifications', 'Delete Account']);

  const handleScrollPage = () => {
    const topOffSet = window.pageYOffset;

    if (emailRef.current && desctopRef.current && dataPrivacyRef.current) {
      if (200 >= topOffSet && topOffSet >= 0) {
        setValue(0);
      } else if (
        emailRef.current.clientHeight + 100 >= topOffSet &&
        topOffSet >= emailRef.current.clientHeight - 100
      ) {
        setValue(1);
      } else if (
        emailRef.current.clientHeight + desctopRef.current.clientHeight * 0.7 >= topOffSet &&
        topOffSet >=
          document.body.scrollHeight -
            emailRef.current.clientHeight +
            desctopRef.current.clientHeight * 0.7 +
            100
      ) {
        setValue(2);
      } else if (
        document.body.scrollHeight >= document.body.clientHeight + window.pageYOffset &&
        document.body.clientHeight + window.pageYOffset >= document.body.scrollHeight - 20
      ) {
        setValue(headerTabElements.length - 1);
      }
    }
  };

  return (
    <div className={classNames('SettingSideBar', className)}>
      <div className="SettingSideBar__container">
        <div className="SettingSideBar__wrapper">
          <AppBar position="static" className="SettingSideBar__header">
            <Tabs
              value={value}
              onChange={handleChange}
              orientation="vertical"
              aria-label="simple tabs example"
            >
              {headerTabElements.map((element, index) => (
                <Tab
                  label={element}
                  key={index + '-tab'}
                  className="SettingSideBar__header-element"
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </AppBar>
          {adminManagment && (
            <LightTooltip title="coming soon">
              <Button
                className="white-btn SettingSideBar__header--admin-button"
                onClick={() => handleAdminManagement}
              >
                Admin management
              </Button>
            </LightTooltip>
          )}
        </div>

        <div className="SettingSideBar__content-block-wr" ref={wrapperRef}>
          <div className="SettingSideBar__content-wr" ref={emailRef}>
            <TabPanel value={value} index={0}>
              <EmailNotification emailNotification={userSettings[0]}>
                <NotificationCheckboxes
                  checkboxNotification={checkboxNotification}
                  handleChange={handleChangeSummary}
                  dropdown={true}
                />
              </EmailNotification>
            </TabPanel>
          </div>
          <div className="SettingSideBar__content-wr" ref={desctopRef}>
            <TabPanel value={value} index={1}>
              <DesktopNotification>
                <NotificationCheckboxes
                  checkboxNotification={checkboxNotificationMarquette}
                  handleChange={handleChangeMarquette}
                  dropdown={true}
                />

                {userRole !== 'Admin' && userRole !== 'Consultant' && (
                  <>
                    <div className="SettingSideBar__common-bottom-line" />
                    <NewsLettersNotification italic={false} />
                    <LiveNotification italic={false} />
                  </>
                )}
              </DesktopNotification>
            </TabPanel>
          </div>
          {dataPrivacy && (
            <div className="SettingSideBar__content-wr" ref={dataPrivacyRef}>
              <TabPanel value={value} index={2}>
                <DataPrivacy />
              </TabPanel>
            </div>
          )}
          <div className="SettingSideBar__content-wr" ref={deleteRef}>
            <TabPanel value={value} index={3}>
              <DeleteAccount />
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SettingSideBar;
