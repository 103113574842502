import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as credits from '../api/billing';

// REDUX
import * as creditsAction from '../actions/billing';
import * as creditsActionType from '../actions/types/types';
import {Action} from 'redux-actions';
import {IBuyCreditsAction} from '../interfaces';
import * as userProfile from '../api/userProfile';
import * as userProfileAction from '../actions/userProfile';

export function* getCredits(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  unknown
> {
  try {
    const payload = yield call(credits.getCreditDetails, {});
    yield put(creditsAction.getCreditsDetailsSuccess(payload));
  } catch (error) {
    yield put(creditsAction.getCreditsDetailsFailure(error));
  }
}

export function* buyCredits(
  action: IBuyCreditsAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(credits.postBuyCredits, action.payload);
    yield put(creditsAction.postBuyCreditsSuccess(payload));
    const userPayload = yield call(userProfile.getUserProfile);
    yield put(userProfileAction.getUserProfileSuccess(userPayload));
  } catch (error) {
    yield put(creditsAction.postBuyCreditsFailure(error));
  }
}

// WATCHERS
export function* watchGetCredits(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(creditsActionType.GET_CREDITS_DETAILS_REQUEST, getCredits);
}

export function* watchBuyCredits(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(creditsActionType.POST_BUY_CREDITS_REQUEST, buyCredits);
}
