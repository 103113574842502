// Onboarding.tsx

import React, {useEffect} from 'react';
import './style.scss';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

interface OnboardingProps {
  steps: any[];
  isOpen: boolean;
  onExit?: () => void;
}

const Onboarding: React.FC<OnboardingProps> = ({steps, isOpen, onExit}) => {
  useEffect(() => {
    if (isOpen) {
      const intro = introJs();

      intro.setOptions({
        nextLabel: 'Next',
        prevLabel: 'Back',
        showBullets: true,
        stepNumbersOfLabel: '/',
        steps: steps,
        nextToDone: false,
      });
      intro.setOption('scrollToElement', false);
      intro.setOption('autoPosition', false);

      intro.onexit(() => {
        if (onExit) {
          onExit();
        }
      });

      void intro.start();
    }
  }, [steps]);

  return null;
};

export default Onboarding;
