import {NavLink, useLocation} from 'react-router-dom';
import {Grid, InputBase, IconButton} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import HelpCenterLinksComponent from './HelpCenterLinks';
import helpCenterIcon from '../../../images/help-center-icon.svg';
import {LightTooltip} from '../../UI/StatusLabel';
import React, {useEffect} from 'react';

interface IRecommendations {
  text: string;
  url: string;
}

export const MainPage = (): JSX.Element => {
  // const url = props.match.path;
  const {pathname} = useLocation();
  const recommendations: IRecommendations[] = [
    {text: 'What is a template, and how to work with it?', url: `${pathname}/TemplateWork`},
    {text: 'Why there may be no result?', url: `${pathname}/Rules`},
    {text: 'See how it works', url: `${pathname}/how-it-works`},
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="help-center-search">
        <div className="help-center__content">
          <h1 className="help-center__title">Help Center</h1>
          <LightTooltip title="coming soon">
            <div className="help-center-search__search">
              <InputBase
                placeholder="What do you need help with?"
                inputProps={{'aria-label': 'search'}}
                disabled={true}
              />
              <IconButton className="help-center-search__searchIcon" aria-label="search">
                <SearchIcon />
              </IconButton>
            </div>
          </LightTooltip>
        </div>
        <div className="help-center__recommend">
          <Grid container className="gridContainer">
            <Grid item xs={12} lg={10}>
              <h2 className="help-center__subtitle">Recommended for you</h2>
              <div className="help-center__recommend-list">
                {recommendations.map((item: IRecommendations, index: number) => {
                  return (
                    <NavLink className="help-center__recommend-item" to={item.url} key={index}>
                      <img
                        src={helpCenterIcon}
                        alt="Help Center icon"
                        className="help-center__recommend-icon"
                      />
                      <span className="help-center__recommend-label">{item.text}</span>
                    </NavLink>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <HelpCenterLinksComponent />
    </>
  );
};
export default MainPage;
