import React from 'react';
import {Grid} from '@material-ui/core';
import BackButton from './../../UI/BackButton';
import NotUpdateImg from '../../../images/not-update-question.png';

const NotUpdateQuestion = (): JSX.Element => {
  return (
    <>
      <Grid container className="info-page no-updates">
        <Grid item xs={11} md={11} lg={10}>
          <div className="info-page__wr">
            <BackButton prevPage />

            <Grid container>
              <Grid item xs={12} md={9} lg={8}>
                <h1 className="info-page__title">Why are there no updates for my Answers?</h1>

                <div className="info-page__content">
                  <img src={NotUpdateImg} alt="No updates illustration" />
                  <div className="content-item">
                    <p>
                      In order to answer your questions, our AI processes a massive number of
                      articles, reports, and other data sources.
                    </p>
                    <p>
                      The data is filtered by source and by date to ensure that you get the most
                      relevant insights. We run the check regularly to keep the information updated.
                    </p>
                    <p>
                      However, sometimes there is no new data available on the topic. Therefore, you
                      can stay tuned and your AI assistant will provide you with relevant updates as
                      soon as more information is released.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default NotUpdateQuestion;
