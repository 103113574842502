import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  POST_USER_SUBSCRIPTION_REFUND_REQUEST,
  POST_USER_SUBSCRIPTION_REFUND_SUCCESS,
  POST_USER_SUBSCRIPTION_REFUND_FAILURE,
} from '../actions/types/types';
import {IRefundState} from '../interfaces';

export const initialState = {
  refundResult: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [POST_USER_SUBSCRIPTION_REFUND_REQUEST]: (state: IRefundState) => ({
    ...state,
    loading: true,
    error: '',
  }),

  [POST_USER_SUBSCRIPTION_REFUND_SUCCESS]: (state: IRefundState, action: {payload: boolean}) => ({
    ...state,
    refundResult: action.payload,
    loading: false,
    error: '',
  }),

  [POST_USER_SUBSCRIPTION_REFUND_FAILURE]: (state: IRefundState, action: {error: boolean}) => ({
    ...state,
    error: action.error,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
