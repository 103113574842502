// PAYMENTS - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as billingActionType from './types/types';

export const getCreditItemsRequest = createAction(billingActionType.GET_CREDIT_ITEMS_REQUEST);
export const getCreditItemsSuccess = createAction(billingActionType.GET_CREDIT_ITEMS_SUCCESS);
export const getCreditItemsFailure = createAction(billingActionType.GET_CREDIT_ITEMS_FAILURE);

export const postSubscriptionRequest = createAction(billingActionType.POST_SUBSCRIPTIONS_REQUEST);
export const postSubscriptionSuccess = createAction(billingActionType.POST_SUBSCRIPTIONS_SUCCESS);
export const postSubscriptionFailure = createAction(billingActionType.POST_SUBSCRIPTIONS_FAILURE);

export const getCreditsDetailsRequest = createAction(billingActionType.GET_CREDITS_DETAILS_REQUEST);
export const getCreditsDetailsSuccess = createAction(billingActionType.GET_CREDITS_DETAILS_SUCCESS);
export const getCreditsDetailsFailure = createAction(billingActionType.GET_CREDITS_DETAILS_FAILURE);

export const postBuyCreditsRequest = createAction(billingActionType.POST_BUY_CREDITS_REQUEST);
export const postBuyCreditsSuccess = createAction(billingActionType.POST_BUY_CREDITS_SUCCESS);
export const postBuyCreditsFailure = createAction(billingActionType.POST_BUY_CREDITS_FAILURE);

export const getBillingHistoryRequest = createAction(billingActionType.GET_BILLING_HISTORY_REQUEST);
export const getBillingHistorySuccess = createAction(billingActionType.GET_BILLING_HISTORY_SUCCESS);
export const getBillingHistoryFailure = createAction(billingActionType.GET_BILLING_HISTORY_FAILURE);
