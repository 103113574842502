import React, {useState} from 'react';
import './style.scss';
import {Box, IconButton, InputAdornment, InputBase} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import {LightTooltip} from '../UI/StatusLabel';
import {StyledTextField} from '../UI/TestTextField/StyledTextField';

interface ISearchProps {
  disabled?: boolean;
  searchText?: string | null;
  setSearchText?: (text: string) => void;
  searchFunction?: () => void;
  clearFilter?: () => void;
}

export const Search = (props: ISearchProps): JSX.Element => {
  const {disabled, searchText, setSearchText, searchFunction, clearFilter} = props;
  const [isFocused, setFocused] = useState<boolean>(false);
  const inputProps = {
    'aria-label': 'naked',
  };

  function handleChange(value: string) {
    setSearchText && setSearchText(value);
  }
  function clearField() {
    setSearchText && setSearchText('');
    clearFilter && clearFilter();
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      searchFunction && searchFunction();
    }
  };

  return (
    <>
      {!disabled ? (
        <Box className={`search-textfield__wr ${isFocused || searchText ? 'active' : ''}`}>
          <StyledTextField
            id="search-textfield"
            className={`search-textfield ${isFocused || searchText ? 'focused' : ''}`}
            placeholder="Search ..."
            variant="outlined"
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={(event) => handleChange(event.target.value)}
            onKeyDown={onKeyDown}
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className="search-icon">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={`search-close-icon ${searchText ? 'focused' : ''}`}
                >
                  <CloseIcon onClick={clearField} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ) : (
        <LightTooltip title="Coming soon">
          <InputBase
            disabled={disabled}
            name="search"
            className="search-input"
            placeholder="Search"
            startAdornment={
              <IconButton>
                <SearchIcon />
              </IconButton>
            }
            // onFocus={() => console.log('input focused')}
            inputProps={inputProps}
          />
        </LightTooltip>
      )}
    </>
  );
};

export default Search;
