import {Grid} from '@material-ui/core';
import {Link} from 'react-router-dom';
import './style.scss';
import {LightTooltip} from '../../../UI/StatusLabel';
import {useSelector} from 'react-redux';
import {IState, IUserProfile} from '../../../../interfaces';
import {CHANGE_PASSWORD_LINK} from '../../../../constants';
import React from 'react';
import {ReactComponent as ManageAcc} from '../../../../images/illustration-manage-acc.svg';
import {ReactComponent as BillingInfo} from '../../../../images/illustration-billing.svg';
import {ReactComponent as TipsTricks} from '../../../../images/illustration-tips-tricks.svg';

export const HelpCenterLinksComponent = (): JSX.Element => {
  const user: IUserProfile = useSelector((state: IState) => state.userProfile.userProfile);
  const manageMyAccountLinks = [
    {name: 'Plans and Pricing', link: '/help-center/plans-and-pricing', isDisabled: false},
    {name: 'Profile details', link: '/account/profile', isDisabled: false},
    {name: 'Update password', link: CHANGE_PASSWORD_LINK, isDisabled: false},
    {name: 'Update email', link: '/', isDisabled: true},
    {name: 'Deactivate account', link: '/', isDisabled: true},
  ];
  const billingQuestionsLinks = [
    {
      name: 'Billing and payments',
      link: '/help-center/billing-and-payments',
      isDisabled: false,
    },
    {name: 'How to use coupon codes', link: '/help-center/coupon-codes', isDisabled: false},
    {
      name: 'Review payment history',
      link:
        user.userSubscriptionStatus === 'TrialActive'
          ? '/account/profile'
          : '/account/billing-details',
      isDisabled: false,
    },
    {name: 'Update payment method', link: '/account/profile#payment', isDisabled: false},
    {
      name: 'What to do if I want to get my money back?',
      link: '/help-center/money-back',
      isDisabled: false,
    },
    {
      name: 'Credits Use History',
      link: '/help-center/credits-use-history',
      isDisabled: false,
    },
  ];
  const tipsTricks = [
    {name: 'How to ask a good question', link: '/help-center/GoodQuestion', isDisabled: false},
    {
      name: 'How to use the question and answer template',
      link: '/help-center/use-q-and-a',
      isDisabled: false,
    },
    {
      name: 'How to use the short essay template',
      link: '/help-center/use-short-essay',
      isDisabled: false,
    },
    {name: 'Why there may be no result?', link: '/help-center/Rules', isDisabled: false},
    {
      name: 'Why are there no updates for my answers?',
      link: '/help-center/not-update',
      isDisabled: false,
    },
    {name: 'Cancel account', link: '/account/profile#delete', isDisabled: false},
  ];
  return (
    <Grid container className="help-center__links" justifyContent="center">
      <Grid item xs={12} lg={10}>
        <h2 className="help-center__subtitle">Help by category</h2>
        <div className="help-center__wrap">
          <Grid item xs={12} md={4}>
            <div className="help-center__group">
              <ManageAcc />
              <p className="help-center__title-links">Manage My Account</p>
              <ul className="help-center__list">
                {manageMyAccountLinks.map((item, index) =>
                  item.isDisabled ? (
                    <li className="help-center__item" key={index}>
                      <LightTooltip title="coming soon" placement="right">
                        <span>
                          <Link to={'/'} className="help-center__link disabled">
                            {item.name}
                          </Link>
                        </span>
                      </LightTooltip>
                    </li>
                  ) : (
                    <li className="help-center__item" key={index}>
                      {item.name === 'Update password' ? (
                        <a href={item.link} className="help-center__link">
                          {item.name}
                        </a>
                      ) : (
                        <Link to={item.link} className="help-center__link">
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ),
                )}
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="help-center__group">
              <BillingInfo />
              <p className="help-center__title-links">Billing Questions</p>
              <ul className="help-center__list">
                {billingQuestionsLinks.map((item, index) =>
                  item.isDisabled ? (
                    <li className="help-center__item" key={index}>
                      <LightTooltip
                        title={
                          item.name === 'Billing and Payments'
                            ? 'not available for your subscription plan'
                            : 'coming soon'
                        }
                        placement="right"
                      >
                        <span>
                          <Link to={'/'} className="help-center__link disabled">
                            {item.name}
                          </Link>
                        </span>
                      </LightTooltip>
                    </li>
                  ) : (
                    <li className="help-center__item" key={index}>
                      <Link to={item.link} className="help-center__link">
                        {item.name}
                      </Link>
                    </li>
                  ),
                )}
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="help-center__group">
              <TipsTricks />
              <p className="help-center__title-links">Tips & Tricks</p>
              <ul className="help-center__list">
                {tipsTricks.map((item, index) =>
                  item.isDisabled ? (
                    <li className="help-center__item" key={index}>
                      <LightTooltip title="coming soon" placement="right">
                        <span>
                          <Link to={'/'} className="help-center__link disabled">
                            {item.name}
                          </Link>
                        </span>
                      </LightTooltip>
                    </li>
                  ) : (
                    <li className="help-center__item" key={index}>
                      <Link to={item.link} className="help-center__link">
                        {item.name}
                      </Link>
                    </li>
                  ),
                )}
              </ul>
            </div>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default HelpCenterLinksComponent;
