export enum SubscriptionStatus {
  None = 'None',
  FreeActive = 'FreeActive',
  FreeExpired = 'FreeExpired',
  PaidActive = 'PaidActive',
  PaidExpired = 'PaidExpired',
  Endless = 'Endless',
  TrialActive = 'TrialActive',
  TrialExpired = 'TrialExpired',
}
