import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as userProfile from '../api/userProfile';

// REDUX
import * as userProfileAction from '../actions/userProfile';
import * as userProfileActionType from '../actions/types/types';
import {Action} from 'redux-actions';
import {IUserUpdatePhotoRequestAction} from '../interfaces';

export function* postUserUpdatePhoto(
  action: IUserUpdatePhotoRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(userProfile.postUpdateProfilePhoto, action.payload);
    yield put(userProfileAction.postUserProfilePhotoSuccess(payload));
    const profilePayload = yield call(userProfile.getUserProfile);
    yield put(userProfileAction.getUserProfileSuccess(profilePayload));
  } catch (error) {
    yield put(userProfileAction.postUserProfilePhotoFailure(error));
    yield put(userProfileAction.getUserProfileFailure(error));
  }
}

// WATCHERS
export function* watchUserUpdatePhoto(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(userProfileActionType.POST_UPDATEPROFILE_PHOTO_REQUEST, postUserUpdatePhoto);
}
