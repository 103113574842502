import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as favorites from '../api/favorites';

// REDUX
import * as favoritesAction from '../actions/favorites';
import * as favoritesActionType from '../actions/types/types';
import {IGetFavoriteAction, IFavoriteItemAction, IDeleteItemAction} from '../interfaces';
import {Action} from 'redux-actions';

export function* getFavorites(
  action: IGetFavoriteAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(favorites.getFavorites, action.payload);
    yield put(favoritesAction.getStatusSuccess(payload));
  } catch (error) {
    yield put(favoritesAction.getStatusFailure(error));
  }
}

export function* saveItem(
  action: IFavoriteItemAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(favorites.saveItem, action.payload);
    yield put(favoritesAction.putStatusSuccess(payload));
  } catch (error) {
    yield put(favoritesAction.putStatusFailure(error));
  }
}

export function* deleteItem(
  action: IDeleteItemAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(favorites.deleteItem, action.payload);
    yield put(favoritesAction.deleteStatusSuccess(payload));
    yield put(favoritesAction.getStatusRequest({pageNumber: 1}));
  } catch (error) {
    yield put(favoritesAction.deleteStatusFailure(error));
  }
}

// WATCHERS
export function* watchFavorites(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(favoritesActionType.GET_FAVORITES_REQUEST, getFavorites);
  yield takeLatest(favoritesActionType.PUT_FAVORITES_REQUEST, saveItem);
  yield takeLatest(favoritesActionType.DELETE_FAVORITES_REQUEST, deleteItem);
}
