import {useDispatch} from 'react-redux';
import {ReactComponent as FeedbackBadImg} from '../../../../images/feedback-bad-grey.svg';
import {ReactComponent as FeedbackMediumImg} from '../../../../images/feedback-medium-grey.svg';
import {ReactComponent as FeedbackGoodImg} from '../../../../images/feedback-good-grey.svg';
import IconButton from '@material-ui/core/IconButton';
import {patchModelFeedbackRequest} from '../../../../actions/textModel';
import './style.scss';
import {ETextModelFeedback} from '../../../../interfaces';

interface IProps {
  feedback?: ETextModelFeedback;
  modelId: string;
}

const QuestionFeedback = (props: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const {feedback, modelId} = props;

  const handleClick = (rate: ETextModelFeedback) => {
    if (feedback === rate) {
      rate = ETextModelFeedback.None;
    }

    dispatch(
      patchModelFeedbackRequest({
        textModelId: modelId,
        feedback: rate,
      }),
    );
  };

  return (
    <div className="feedback">
      <p className="feedback__title">Please, rate this content</p>
      <div className="feedback__content">
        <IconButton onClick={() => handleClick(ETextModelFeedback.Bad)}>
          <FeedbackBadImg
            className={`${feedback === ETextModelFeedback.Bad ? 'active' : 'unrated'} feedback-bad`}
          />
        </IconButton>
        <IconButton onClick={() => handleClick(ETextModelFeedback.Medium)}>
          <FeedbackMediumImg
            className={`${
              feedback === ETextModelFeedback.Medium ? 'active' : 'unrated'
            } feedback-medium`}
          />
        </IconButton>
        <IconButton onClick={() => handleClick(ETextModelFeedback.Good)}>
          <FeedbackGoodImg
            className={`${
              feedback === ETextModelFeedback.Good ? 'active' : 'unrated'
            } feedback-good`}
          />
        </IconButton>
      </div>
    </div>
  );
};

export default QuestionFeedback;
