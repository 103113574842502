// import {useState, useEffect} from 'react';
import './style.scss';

interface IProps {
  specialClass?: string;
}

export default function LoadingSpinner(props: IProps): JSX.Element {
  const {specialClass} = props;
  // const [loading, setLoading] = useState<boolean>(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // });

  return (
    <>
      {/* {loading ? ( */}
      <div className="LoadingSpinner">
        <div className={`lds-roller ${specialClass ? specialClass : ''}`}>
          <div className="lds-roller-center"></div>
          <div className="lds-roller-dot"></div>
          <div className="lds-roller-dot"></div>
          <div className="lds-roller-dot"></div>
          <div className="lds-roller-dot"></div>
          <div className="lds-roller-dot"></div>
        </div>
      </div>
      {/* ) : null} */}
    </>
  );
}
