import React, {MouseEvent, useState} from 'react';
import {LightTooltip} from '../StatusLabel';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import {IconButton, Menu, MenuItem} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import './style.scss';
import * as textModels from '../../../actions/textModel';
import {useDispatch, useSelector} from 'react-redux';
import {ReactComponent as UnshareIcon} from '../../../images/unshare.svg';
import {IState} from '../../../interfaces';
import {TwitterShareButton, FacebookShareButton, LinkedinShareButton} from 'react-share';
import {ReactComponent as TwitterIcon} from '../../../images/twitter-icon.svg';
import {ReactComponent as FacebookIcon} from '../../../images/facebook-icon.svg';
import {ReactComponent as LinkedinIcon} from '../../../images/linkedin-icon.svg';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';

interface IProps {
  publicHash?: string;
  contentId: string;
  snackBarAction: (
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error' | undefined,
    open: boolean,
  ) => void;
  isDetailsPage?: boolean;
  title?: string;
}

const ShareContentBtn = (props: IProps): JSX.Element => {
  const {publicHash, contentId, snackBarAction, isDetailsPage, title} = props;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const isMenuOpen = Boolean(anchorEl);
  const url = `${window.location.origin}/public/`;
  const templateDetailsState = useSelector((state: IState) => state.textModel);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(!openMenu);
  };

  function shareContent(modelId: string) {
    if (!publicHash) {
      dispatch(
        textModels.postShareTextModelRequest({textModelId: modelId, isDetails: isDetailsPage}),
      );
      snackBarAction(
        'Please note that the content you share on social media will be available to everyone using the link.',
        'info',
        true,
      );
    }
  }

  function copyToClipboard(publicHashId: string) {
    void navigator.clipboard.writeText(`${url}${publicHashId}`);
    snackBarAction('Link copied to clipboard', 'success', true);
    handleMenuClose();
  }

  function unshareContent(modelId: string) {
    dispatch(
      textModels.postUnshareTextModelRequest({textModelId: modelId, isDetails: isDetailsPage}),
    );
    snackBarAction('You stopped sharing this result', 'info', true);
    handleMenuClose();
  }

  const menuId = 'content-menu';
  const renderMenu = (
    <>
      <Menu
        anchorEl={anchorEl}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem>
          <FacebookShareButton
            title={title}
            url={`${url}${publicHash as string}`}
            className="share-btn__social"
          >
            <FacebookIcon />
            <span>Facebook</span>
          </FacebookShareButton>
        </MenuItem>
        <MenuItem>
          <TwitterShareButton
            title={title}
            url={`${url}${publicHash as string}`}
            //via={'Marquètte'}
            //hashtags={['']}
            className="share-btn__social"
          >
            <TwitterIcon />
            <span>Twitter</span>
          </TwitterShareButton>
        </MenuItem>
        <MenuItem>
          <LinkedinShareButton
            title={title}
            url={`${url}${publicHash as string}`}
            className="share-btn__social"
          >
            <LinkedinIcon />
            <span>Linkedin</span>
          </LinkedinShareButton>
        </MenuItem>

        <MenuItem onClick={() => publicHash && copyToClipboard(publicHash)}>
          <div className="content__menu-items">
            <InsertLinkOutlinedIcon />
            <span>Copy Link</span>
          </div>
        </MenuItem>

        {publicHash && (
          <MenuItem onClick={() => unshareContent(contentId)}>
            <div className="content__menu-items">
              <UnshareIcon />
              <span>Stop Sharing</span>
            </div>
          </MenuItem>
        )}
      </Menu>
    </>
  );

  return (
    <>
      <IconButton
        className="orange-icon-btn share-icon"
        onClick={(e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
          handleMenuOpen(e);
          shareContent(contentId);
        }}
        disabled={templateDetailsState.loading}
      >
        <LightTooltip title={'Share content'} placement={'top'}>
          {!publicHash ? <ShareOutlinedIcon /> : <ShareIcon />}
        </LightTooltip>
      </IconButton>
      {!templateDetailsState.loading && <>{renderMenu}</>}
    </>
  );
};

export default ShareContentBtn;
