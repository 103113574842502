import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as billing from '../api/billing';

// REDUX
import {Action} from 'redux-actions';
import * as billingAction from '../actions/billing';
import * as billingActionType from '../actions/types/types';
import {IBuySubscriptionAction} from '../interfaces';
import * as userProfile from '../api/userProfile';
import * as userProfileAction from '../actions/userProfile';

export function* buySubscription(
  action: IBuySubscriptionAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(billing.postBuySubscription, action.payload);
    yield put(billingAction.postSubscriptionSuccess(payload));
    const userPayload = yield call(userProfile.getUserProfile);
    yield put(userProfileAction.getUserProfileSuccess(userPayload));
  } catch (error) {
    yield put(billingAction.postSubscriptionFailure(error));
  }
}

// WATCHERS
export function* watchBuySubscription(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(billingActionType.POST_SUBSCRIPTIONS_REQUEST, buySubscription);
}
