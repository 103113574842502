import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as commandsManagement from '../api/commandsManagement';

// REDUX
import * as commandsManagementAction from '../actions/commandsManagement';
import * as commandsManagementActionType from '../actions/types/types';

import {
  ICommandResponseId,
  ICreateCommandRequestAction,
  IDeleteCommandsRequestAction,
  IGetCommandRequestAction,
  IGetCommandsListRequestAction,
  IPublishCommandsRequestAction,
  IUpdateCommandsRequestAction,
} from '../interfaces';
import {Action} from 'redux-actions';

export function* createCommand(
  action: ICreateCommandRequestAction,
): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  ICommandResponseId
> {
  try {
    const payload = yield call(commandsManagement.createCommand, action.payload);
    yield put(commandsManagementAction.postCommandCreateSuccess(payload));
    const getCommandPayload = yield call(commandsManagement.getCommandItem, {id: payload.id});
    yield put(commandsManagementAction.getCommandItemSuccess(getCommandPayload));
  } catch (error) {
    yield put(commandsManagementAction.postCommandCreateFailure(error));
    yield put(commandsManagementAction.getCommandItemFailure(error));
  }
}

export function* getCommandItem(
  action: IGetCommandRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(commandsManagement.getCommandItem, action.payload);
    yield put(commandsManagementAction.getCommandItemSuccess(payload));
  } catch (error) {
    yield put(commandsManagementAction.getCommandItemFailure(error));
  }
}

export function* getCommandsList(
  action: IGetCommandsListRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(commandsManagement.getCommandsList, action.payload);
    yield put(commandsManagementAction.getCommandsListSuccess(payload));
  } catch (error) {
    yield put(commandsManagementAction.getCommandsListFailure(error));
  }
}

export function* publishCommand(
  action: IPublishCommandsRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(commandsManagement.publishCommand, action.payload);
    yield put(commandsManagementAction.patchPublishCommandSuccess(payload));
    const commandPayload = yield call(commandsManagement.getCommandItem, action.payload);
    yield put(commandsManagementAction.getCommandItemSuccess(commandPayload));
  } catch (error) {
    yield put(commandsManagementAction.patchPublishCommandFailure(error));
    yield put(commandsManagementAction.getCommandItemFailure(error));
  }
}

export function* updateCommand(
  action: IUpdateCommandsRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(commandsManagement.updateCommand, action.payload);
    yield put(commandsManagementAction.putCommandUpdateSuccess(payload));
    const commandPayload = yield call(commandsManagement.getCommandItem, {id: action.payload.id});
    yield put(commandsManagementAction.getCommandItemSuccess(commandPayload));
  } catch (error) {
    yield put(commandsManagementAction.putCommandUpdateFailure(error));
    yield put(commandsManagementAction.getCommandItemFailure(error));
  }
}

export function* deleteCommand(
  action: IDeleteCommandsRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(commandsManagement.deleteCommand, action.payload);
    yield put(commandsManagementAction.deleteCommandSuccess(payload));
  } catch (error) {
    yield put(commandsManagementAction.deleteCommandFailure(error));
  }
}

// WATCHERS
export function* watchCreateCommand(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(commandsManagementActionType.POST_COMMAND_CREATE_REQUEST, createCommand);
}

export function* watchGetCommandItem(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(commandsManagementActionType.GET_COMMAND_ITEM_REQUEST, getCommandItem);
}

export function* watchGetCommandsList(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(commandsManagementActionType.GET_COMMANDS_LIST_REQUEST, getCommandsList);
}

export function* watchPublishCommands(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(commandsManagementActionType.PATCH_PUBLISH_COMMAND_REQUEST, publishCommand);
}

export function* watchUpdateCommand(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(commandsManagementActionType.PUT_COMMAND_UPDATE_REQUEST, updateCommand);
}

export function* watchDeleteCommand(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(commandsManagementActionType.DELETE_COMMAND_REQUEST, deleteCommand);
}
