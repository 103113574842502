import {createAction} from 'redux-actions';
import * as modelActionType from './types/types';
import {
  IModelIdFeedbackPayload,
  ISetArchiveModelRequestActionPayload,
  ITextModelAutosavePayload,
  ITextModelDetails,
} from '../interfaces';
import {IInBackground} from '../interfaces/UI';

export const getTextModelTemplateRequest = createAction(
  modelActionType.GET_TEXT_MODEL_TEMPLATE_REQUEST,
);
export const getTextModelTemplateSuccess = createAction(
  modelActionType.GET_TEXT_MODEL_TEMPLATE_SUCCESS,
);
export const getTextModelTemplateFailure = createAction(
  modelActionType.GET_TEXT_MODEL_TEMPLATE_FAILURE,
);

export const postTextModelCreateRequest = createAction(
  modelActionType.POST_TEXT_MODEL_CREATE_REQUEST,
);
export const postTextModelCreateSuccess = createAction(
  modelActionType.POST_TEXT_MODEL_CREATE_SUCCESS,
);
export const postTextModelCreateFailure = createAction(
  modelActionType.POST_TEXT_MODEL_CREATE_FAILURE,
);

export const getTextModelDetailsRequest = createAction(
  modelActionType.GET_TEXT_MODEL_DETAILS_REQUEST,
);
export const getTextModelDetailsSuccess = createAction(
  modelActionType.GET_TEXT_MODEL_DETAILS_SUCCESS,
);
export const getTextModelDetailsFailure = createAction(
  modelActionType.GET_TEXT_MODEL_DETAILS_FAILURE,
);

export const getModelTemplateDetailsRequest = createAction(
  modelActionType.GET_MODEL_TEMPLATE_DETAILS_REQUEST,
);
export const getModelTemplateDetailsSuccess = createAction(
  modelActionType.GET_MODEL_TEMPLATE_DETAILS_SUCCESS,
);
export const getModelTemplateDetailsFailure = createAction(
  modelActionType.GET_MODEL_TEMPLATE_DETAILS_FAILURE,
);

export const setModelTemplateDetailsClear = createAction(
  modelActionType.SET_TEXT_MODEL_DETAILS_CLEAR,
);

export const postModelNextStepRequest = createAction(modelActionType.POST_MODEL_NEXT_STEP_REQUEST);
export const postModelNextStepSuccess = createAction(modelActionType.POST_MODEL_NEXT_STEP_SUCCESS);
export const postModelNextStepFailure = createAction(modelActionType.POST_MODEL_NEXT_STEP_FAILURE);

export const clearCreatedModel = createAction(modelActionType.CLEAR_CREATED_MODEL_REQUEST);

export const getTextModelsRequest = createAction(modelActionType.GET_TEXT_MODELS_REQUEST);
export const getTextModelsSuccess = createAction(modelActionType.GET_TEXT_MODELS_SUCCESS);
export const getTextModelsFailure = createAction(modelActionType.GET_TEXT_MODELS_FAILURE);

export const postCancelModelRequest = createAction(modelActionType.POST_CANCEL_MODEL_REQUEST);
export const postCancelModelSuccess = createAction(modelActionType.POST_CANCEL_MODEL_SUCCESS);
export const postCancelModelFailure = createAction(modelActionType.POST_CANCEL_MODEL_FAILURE);

export const clearModelDetails = createAction(modelActionType.CLEAR_MODEL_DETAILS_REQUEST);

export const patchModelFeedbackRequest = createAction<IModelIdFeedbackPayload>(
  modelActionType.PATCH_MODEL_FEEDBACK_REQUEST,
);
export const patchModelFeedbackSuccess = createAction(modelActionType.PATCH_MODEL_FEEDBACK_SUCCESS);
export const patchModelFeedbackFailure = createAction(modelActionType.PATCH_MODEL_FEEDBACK_FAILURE);

export const putLikeModelRequest = createAction(modelActionType.PUT_LIKE_MODEL_REQUEST);
export const putLikeModelSuccess = createAction(modelActionType.PUT_LIKE_MODEL_SUCCESS);
export const putLikeModelFailure = createAction(modelActionType.PUT_LIKE_MODEL_FAILURE);

export const getPublicTextModelRequest = createAction(
  modelActionType.GET_PUBLIC_TEXT_MODEL_REQUEST,
);
export const getPublicTextModelSuccess = createAction(
  modelActionType.GET_PUBLIC_TEXT_MODEL_SUCCESS,
);
export const getPublicTextModelFailure = createAction(
  modelActionType.GET_PUBLIC_TEXT_MODEL_FAILURE,
);

export const postShareTextModelRequest = createAction(
  modelActionType.POST_SHARE_TEXT_MODEL_REQUEST,
);
export const postShareTextModelSuccess = createAction(
  modelActionType.POST_SHARE_TEXT_MODEL_SUCCESS,
);
export const postShareTextModelFailure = createAction(
  modelActionType.POST_SHARE_TEXT_MODEL_FAILURE,
);

export const postUnshareTextModelRequest = createAction(
  modelActionType.POST_UNSHARE_TEXT_MODEL_REQUEST,
);
export const postUnshareTextModelSuccess = createAction(
  modelActionType.POST_UNSHARE_TEXT_MODEL_SUCCESS,
);
export const postUnshareTextModelFailure = createAction(
  modelActionType.POST_UNSHARE_TEXT_MODEL_FAILURE,
);

export const postUploadFileRequest = createAction(modelActionType.POST_UPLOAD_FILE_REQUEST);
export const postUploadFileSuccess = createAction(modelActionType.POST_UPLOAD_FILE_SUCCESS);
export const postUploadFileFailure = createAction(modelActionType.POST_UPLOAD_FILE_FAILURE);

export const setArchiveStatusModelRequest = createAction<
  ISetArchiveModelRequestActionPayload & IInBackground
>(modelActionType.SET_ARCHIVE_STATUS_MODEL_REQUEST);

export const setArchiveStatusModelSuccess = createAction(
  modelActionType.SET_ARCHIVE_STATUS_MODEL_SUCCESS,
);
export const setArchiveStatusModelFailure = createAction(
  modelActionType.SET_ARCHIVE_STATUS_MODEL_FAILURE,
);

export const removeTextModelManually = createAction<{id: string}>(
  modelActionType.REMOVE_TEXT_MODEL_MANUALLY,
);

export const getTextModelsForLandingRequest = createAction(
  modelActionType.GET_TEXT_MODELS_FOR_LANDING_REQUEST,
);
export const getTextModelsForLandingSuccess = createAction<ITextModelDetails[]>(
  modelActionType.GET_TEXT_MODELS_FOR_LANDING_SUCCESS,
);
export const getTextModelsForLandingFailure = createAction(
  modelActionType.GET_TEXT_MODELS_FOR_LANDING_FAILURE,
);

export const getTextGenerationResultRequest = createAction(
  modelActionType.GET_TEXT_GENERATION_RESULT_REQUEST,
);
export const getTextGenerationResultSuccess = createAction(
  modelActionType.GET_TEXT_GENERATION_RESULT_SUCCESS,
);
export const getTextGenerationResultFailure = createAction(
  modelActionType.GET_TEXT_GENERATION_RESULT_FAILURE,
);
export const updateTextModelDetailsHistory = createAction(
  modelActionType.UPDATE_TEXT_MODEL_DETAILS_HISTORY,
);

export const postCreateModelEntryRequest = createAction(
  modelActionType.POST_CREATE_MODEL_ENTRY_REQUEST,
);
export const postCreateModelEntrySuccess = createAction(
  modelActionType.POST_CREATE_MODEL_ENTRY_SUCCESS,
);
export const postCreateModelEntryFailure = createAction(
  modelActionType.POST_CREATE_MODEL_ENTRY_FAILURE,
);
export const setCreateModelEntryNavigate = createAction(
  modelActionType.SET_CREATE_MODEL_ENTRY_NAVIGATE,
);

export const getTextModelEntryRequest = createAction(modelActionType.GET_TEXT_MODEL_ENTRY_REQUEST);
export const getTextModelEntrySuccess = createAction(modelActionType.GET_TEXT_MODEL_ENTRY_SUCCESS);
export const getTextModelEntryFailure = createAction(modelActionType.GET_TEXT_MODEL_ENTRY_FAILURE);

export const updateTextModelEntryRequest = createAction(modelActionType.UPDATE_MODEL_ENTRY_REQUEST);
export const updateTextModelEntrySuccess = createAction(modelActionType.UPDATE_MODEL_ENTRY_SUCCESS);
export const updateTextModelEntryFailure = createAction(modelActionType.UPDATE_MODEL_ENTRY_FAILURE);

export const publishTextModelEntryRequest = createAction(
  modelActionType.PUBLISH_MODEL_ENTRY_REQUEST,
);
export const publishTextModelEntrySuccess = createAction(
  modelActionType.PUBLISH_MODEL_ENTRY_SUCCESS,
);
export const publishTextModelEntryFailure = createAction(
  modelActionType.PUBLISH_MODEL_ENTRY_FAILURE,
);

export const generateTextModelRequest = createAction(modelActionType.GENERATE_TEXT_MODEL_REQUEST);
export const generateTextModelSuccess = createAction(modelActionType.GENERATE_TEXT_MODEL_SUCCESS);
export const generateTextModelFailure = createAction(modelActionType.GENERATE_TEXT_MODEL_FAILURE);

export const getTextModelEntriesRequest = createAction(
  modelActionType.GET_TEXT_MODEL_ENTRIES_REQUEST,
);
export const getTextModelEntriesFailure = createAction(
  modelActionType.GET_TEXT_MODEL_ENTRIES_FAILURE,
);
export const getTextModelEntriesSuccess = createAction(
  modelActionType.GET_TEXT_MODEL_ENTRIES_SUCCESS,
);

export const getTextModelCategoriesRequest = createAction(
  modelActionType.GET_TEXT_MODEL_CATEGORIES_REQUEST,
);
export const getTextModelCategoriesFailure = createAction(
  modelActionType.GET_TEXT_MODEL_CATEGORIES_FAILURE,
);
export const getTextModelCategoriesSuccess = createAction(
  modelActionType.GET_TEXT_MODEL_CATEGORIES_SUCCESS,
);

export const setModelEntryClear = createAction(modelActionType.SET_MODEL_ENTRY_CLEAR);
export const setNavigateToEntry = createAction(modelActionType.SET_NAVIGATE_TO_ENTRY);
export const setClearNavigateToEntry = createAction(modelActionType.SET_CLEAR_NAVIGATE_TO_ENTRY);

export const deleteTextModelEntryRequest = createAction(
  modelActionType.DELETE_TEXT_MODEL_ENTRY_REQUEST,
);
export const deleteTextModelEntryFailure = createAction(
  modelActionType.DELETE_TEXT_MODEL_ENTRY_FAILURE,
);
export const deleteTextModelEntrySuccess = createAction(
  modelActionType.DELETE_TEXT_MODEL_ENTRY_SUCCESS,
);

export const getTextModelEntriesImagesRequest = createAction(
  modelActionType.GET_TEXT_MODEL_ENTRIES_IMAGES_REQUEST,
);
export const getTextModelEntriesImagesFailure = createAction(
  modelActionType.GET_TEXT_MODEL_ENTRIES_IMAGES_FAILURE,
);
export const getTextModelEntriesImagesSuccess = createAction(
  modelActionType.GET_TEXT_MODEL_ENTRIES_IMAGES_SUCCESS,
);

export const patchTextModelAutosaveRequest = createAction<ITextModelAutosavePayload>(
  modelActionType.PATCH_TEXT_MODEL_AUTOSAVE_REQUEST,
);
export const patchTextModelAutosaveSuccess = createAction(
  modelActionType.PATCH_TEXT_MODEL_AUTOSAVE_SUCCESS,
);
export const patchTextModelAutosaveFailure = createAction(
  modelActionType.PATCH_TEXT_MODEL_AUTOSAVE_FAILURE,
);

export const getTextModelStatisticRequest = createAction(
  modelActionType.GET_TEXT_MODEL_STATISTIC_REQUEST,
);
export const getTextModelStatisticFailure = createAction(
  modelActionType.GET_TEXT_MODEL_STATISTIC_FAILURE,
);
export const getTextModelStatisticSuccess = createAction(
  modelActionType.GET_TEXT_MODEL_STATISTIC_SUCCESS,
);
