import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  SET_SIGNALR_CELL_EDIT_REQUEST,
  SET_SIGNALR_CELL_EDIT_SUCCESS,
  SET_SIGNALR_CELL_EDIT_FAILURE,
} from '../actions/types/types';
import {ISignalRAction, ISignalRCellEditState} from '../interfaces/SignalR';

export const initialState: ISignalRCellEditState = {
  status: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [SET_SIGNALR_CELL_EDIT_REQUEST]: (state: ISignalRCellEditState, action: ISignalRAction) => ({
    ...state,
    status: action.payload,
    loading: false,
    error: '',
  }),
  [SET_SIGNALR_CELL_EDIT_SUCCESS]: (state: ISignalRCellEditState, action: ISignalRAction) => ({
    ...state,
    status: action.payload,
    loading: false,
    error: '',
  }),
  [SET_SIGNALR_CELL_EDIT_FAILURE]: (state: ISignalRCellEditState) => ({
    ...state,
    loading: false,
    error: '',
  }),
};

export default handleActions(handlerMap, initialState);
