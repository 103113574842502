import React, {useState} from 'react';
import {ICouponsManagementItem} from '../../../../interfaces';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import AllInclusive from '@material-ui/icons/AllInclusive';
import {ReactComponent as TooltipIcon} from '../../../../images/coupon-tooltip.svg';
import moment from 'moment';
import {IconButton, Menu, MenuItem} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import {ReactComponent as OpenMenuIcon} from '../../../../images/openMenu.svg';
import {ReactComponent as CloneIcon} from '../../../../images/clone-icon.svg';
import {ReactComponent as DisableIcon} from '../../../../images/disable-icon.svg';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import * as coupon from '../../../../actions/coupon';
import {useDispatch} from 'react-redux';
import AlertDialog from '../../../UI/AlertDialog';

interface IProps {
  item: ICouponsManagementItem;
  index: number;
}

const CouponsTable = (props: IProps): JSX.Element => {
  const {item, index} = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);
  const isMenuOpen = Boolean(anchorEl);
  const dispatch = useDispatch();
  const today = new Date();
  const isScheduled = today < new Date(item.startDate);
  const [isDisableDialogOpen, setDisableDialogOpen] = useState<boolean>(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(!openMenu);
  };

  function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }

  function handleOpenDisableDialog() {
    setDisableDialogOpen(true);
  }

  function handleDisableCoupon() {
    setDisableDialogOpen(false);
    const selectedStartDate = isScheduled ? getPreviousDay() : item.startDate;
    const createData = {
      id: item.id,
      code: item.code,
      stripeProductId: item.stripeProductId,
      name: item.name,
      description: item.description,
      isFixedAmount: item.isFixedAmount,
      discount: item.discount,
      maxUsage: item.maxUsage,
      startDate: moment(selectedStartDate).utcOffset(0, true).format(),
      endDate: moment(getPreviousDay()).utcOffset(0, true).format(),
    };
    dispatch(coupon.putUpdateCouponRequest(createData));
    setDisableDialogOpen(false);
  }

  const menuId = 'product-menu';
  const renderMenu = (
    <>
      <Menu
        anchorEl={anchorEl}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem>
          <NavLink to={`/admin/edit-coupon/${item.id}`}>
            <div className="coupon__menu-items">
              <EditOutlinedIcon />
              Update
            </div>
          </NavLink>
        </MenuItem>

        <MenuItem>
          <NavLink to={`/admin/clone-coupon/${item.id}`}>
            <div className="coupon__menu-items">
              <CloneIcon />
              Clone
            </div>
          </NavLink>
        </MenuItem>

        <MenuItem onClick={handleOpenDisableDialog}>
          <div className="coupon__menu-items">
            <DisableIcon />
            <span>Disable</span>
          </div>
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <TableRow hover key={index} className="table-row">
        <TableCell className="coupon__item coupon__name">
          <span className={item.couponStatus}></span>
          {item.name}
        </TableCell>
        <TableCell>
          <span className="coupon__item coupon__product-code">{item.code}</span>
        </TableCell>
        <TableCell className="coupon__item">
          {item.isFixedAmount && <span>$</span>}
          {item.discount}
          {!item.isFixedAmount && <span>%</span>}
        </TableCell>
        <TableCell className="coupon__item">
          ${Math.round(item.productAmountWithDiscount)}
        </TableCell>
        <TableCell className="coupon__item coupon__usage">
          {item.totalUsageCount}/{item.maxUsage}
          {item.maxUsage === null && <AllInclusive />}
        </TableCell>
        <TableCell className="coupon__item">
          {item.stripeProductName} ({item.stripeProductPeriod.toLowerCase()})
        </TableCell>
        <TableCell className="coupon__item">{moment(item.startDate).format('MM/DD/YY')}</TableCell>
        <TableCell className="coupon__item">-</TableCell>
        <TableCell className="coupon__item coupon__endDate">
          {item.endDate && <span>{moment(item.endDate).format('MM/DD/YY')}</span>}
          {item.endDate === null && <AllInclusive />}
        </TableCell>
        <TableCell className="coupon__item">
          {item.description && (
            <Tooltip title={item.description}>
              <TooltipIcon />
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          <IconButton onClick={(e) => handleMenuOpen(e)}>
            <OpenMenuIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {renderMenu}
      <AlertDialog
        isDialogOpen={isDisableDialogOpen}
        setDialogOpen={setDisableDialogOpen}
        text={{
          question: 'Are you sure want to disable the coupon?',
          warning:
            'You set the Expiration Date as Yesterday date. It will automatically disable the coupon.',
          action: 'Yes, disable',
        }}
        confirmAction={handleDisableCoupon}
      />
    </>
  );
};

export default CouponsTable;
