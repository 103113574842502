import {handleBody, handleExceptions, POST} from './request';

export const postAddCreditsAdmin = (payload: {
  userId: string;
  creditsValue: number;
}): Promise<Object | null> => {
  const endpoint: string = `/Admin/AddCredits`;
  const options = {
    body: {...payload},
  };
  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
