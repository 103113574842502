import {Link} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import BackButton from './../../UI/BackButton';
import CreditsUseHistoryImg from '../../../images/credits-use-history.png';
import OpenContentWideImg from '../../../images/open-content-wide-screenshot.png';
import OpenContentTabletImg from '../../../images/open-content-tablet-screenshot.png';
import OpenContentMobileImg from '../../../images/open-content-mobile-screenshot.png';
import OpenDetailWideImg from '../../../images/open-detail-wide-screenshot.png';
import OpenDetailTabletImg from '../../../images/open-detail-tablet-screenshot.png';
import OpenDetailMobileImg from '../../../images/open-detail-mobile-screenshot.png';
import {useEffect} from 'react';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';

export const CreditsUseHistory = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container className="info-page">
        <Grid item xs={11} md={11} lg={10}>
          <div className="info-page__wr">
            {/*{history.length > 1 && <BackButton match={history} prevPage />}*/}
            <BackButton prevPage />
            <Grid container>
              <Grid item xs={12} md={9} lg={8}>
                <h1 className="info-page__title">Credits Use History</h1>

                <div className="info-page__content">
                  <img src={CreditsUseHistoryImg} alt="help-center_credits-use-history" />
                  <div className="content-item">
                    <p>
                      After subscribing to Marquètte, you have a set number of credits added to your
                      account. Credits are in-app Marquètte currency.
                    </p>
                    <p>
                      Each word generated by AI <b>costs 1 credit</b>.
                    </p>
                    <p>
                      Please note that <b>AI charges per generated word</b>, not for the final
                      version of your chosen content. Therefore, every content option offered by
                      Marquètte will deduct some credits from your account.
                    </p>
                    <p>
                      <Link className="use-qa__link" to={`/account/credit-history`}>
                        Click here
                      </Link>{' '}
                      to see your payment details and
                    </p>
                    <p>On this page, you’ll see a table detailing your credits use history.</p>
                    <p>You’ll be able to review the following:</p>
                    <ul>
                      <li>DATE - date and time when credits were used</li>
                      <li>TEMPLATE - name of the Template you used</li>
                      <li>TITLE - title of the content piece you generated</li>
                      <li>
                        FIELD NAME - name of the step where you stopped the generation process
                      </li>
                      <li>CREDITS - total number of credits used</li>
                    </ul>
                    <div className="help-center__block">
                      You can see a detailed breakdown of credits use by clicking{' '}
                      <span className="help-center__icon help-center__icon--color">
                        <ExpandMoreRoundedIcon />
                      </span>
                    </div>
                    <picture>
                      <source media="(min-width:1025px)" srcSet={OpenDetailWideImg} />
                      <source media="(min-width:600px)" srcSet={OpenDetailTabletImg} />
                      <source media="(min-width: 320px)" srcSet={OpenDetailMobileImg} />
                      <img
                        className="help-center__img help-center__img--mb"
                        src={OpenDetailMobileImg}
                        alt="Open details"
                      />
                    </picture>
                    <div className="help-center__block">
                      To see the whole content piece, click on{' '}
                      <span className="help-center__icon">
                        <OpenInNewRoundedIcon />
                      </span>
                    </div>
                    <picture>
                      <source media="(min-width:1025px)" srcSet={OpenContentWideImg} />
                      <source media="(min-width:600px)" srcSet={OpenContentTabletImg} />
                      <source media="(min-width: 320px)" srcSet={OpenContentMobileImg} />
                      <img
                        className="help-center__img"
                        src={OpenContentMobileImg}
                        alt="Open content"
                      />
                    </picture>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default CreditsUseHistory;
