import {IEngineOptions} from '../interfaces/TextModel';

export const BASE_URL: string = process.env.REACT_APP_BACKEND || '';
export const MSAL_SCOPE: string = process.env.REACT_APP_MSAL_SCOPE || '';
export const API_CLIENT_ID: string = process.env.REACT_APP_API_CLIENT_ID || '';
export const AUTHORITY: string = process.env.REACT_APP_AUTHORITY || '';
export const KNOWN_AUTHORITY: string = process.env.REACT_APP_KNOWN_AUTHORITY || '';
export const STRIPE_PUBLISHABLE_KEY: string = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
export const CURRENT_YEAR = new Date().getFullYear();
export const TRACKING_ID: string = process.env.REACT_APP_GOOGLE_ANALYTICS || '';
export const CAPTCHA_SITE_KEY: string = process.env.REACT_APP_CAPTCHA_SITE_KEY || '';
export const CHANGE_PASSWORD_LINK: string = process.env.REACT_APP_CHANGE_PASSWORD_LINK || '';
export const GRAMMARLY_CLIENT_ID: string = process.env.REACT_APP_GRAMMARLY_CLIENT_ID || '';
export const BLOB_LINK = process.env.REACT_APP_BLOB_COLLECTION_LINK || '';
export const NOTIFICATION_HUB_DEVELOPMENT = `${BASE_URL.substring(
  0,
  BASE_URL.length - 4,
)}/notifications-event-hub`;
export const CREATIVITY_LEVEL_DATA: IEngineOptions[] = [
  {name: 'None', value: 1},
  {name: 'Low', value: 25},
  {name: 'Medium', value: 50},
  {name: 'High', value: 75},
  {name: 'Max', value: 100},
];

export const QUALITY_TYPE_DATA: IEngineOptions[] = [
  {name: 'Superior (GPT 4)', value: 'GPT 4'},
  {name: 'Premium (GPT 3.5)', value: 'GPT 3.5'},
];
