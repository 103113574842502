import React from 'react';
import './style.scss';
import {Header} from '../../components/Header';
import ContactUs from '../../components/ContactUs';
import {Footer} from '../../components/Footer';

const ContactUsPage = (): JSX.Element => {
  return (
    <div className="contact-us-page">
      <Header showLogo={true} adminSide={false} />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default ContactUsPage;
