import React, {useState} from 'react';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import IconButton from '@material-ui/core/IconButton';
import SnackbarMessage from '../../../UI/Snackbar';
import ShareContentBtn from '../../../UI/ShareContentBtn';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import {handleDownloadPdf} from '../../../../helpers/helper';
import DownloadFileBtn from '../../../UI/DownloadFileBtn';
import {LightTooltip} from '../../../UI/StatusLabel';
import {ETextModelFeedback} from '../../../../interfaces';
import QuestionFeedback from '../QuestionFeedback';
import {BLOB_LINK} from '../../../../constants';
import DateIcon from '../../../../images/model-details-date-icon.svg';
import moment from 'moment';
import useWindowDimensions from '../../../../helpers/windowResize';
import WordsIcon from '../../../../images/model-details-words-icon.svg';
import {ReactComponent as HintIcon} from '../../../../images/hint-icon.svg';
import Onboarding from '../../../UI/Onboarding';
import {useLocation} from 'react-router-dom';

interface IProps {
  id: string;
  title: string;
  date?: string;
  publicHashId?: string;
  industry?: string;
  areas?: string[];
  isFavorite?: boolean;
  contentWords?: number;
  description?: string;
  likeFunction?: (id: string, like: boolean) => void;
  isActionBtnDisplayed?: boolean;
  isPreviewStep?: boolean;
  refContent?: HTMLDivElement | null;
  contentAsHtml?: string;
  feedback?: ETextModelFeedback | undefined;
  image: string | undefined;
  isPublic?: boolean;
}

const imageLink = `${BLOB_LINK}/assets-for-templates/`;

const MyContentDetailsTitle = (props: IProps): JSX.Element => {
  const {
    title,
    isFavorite,
    id,
    likeFunction,
    isActionBtnDisplayed = true,
    isPreviewStep,
    publicHashId,
    refContent,
    contentAsHtml = '',
    feedback,
    date,
    contentWords,
    description,
    image,
    isPublic = false,
  } = props;
  const [isOpenSnack, setOpenSnack] = React.useState<boolean>(false);
  const [snackMessage, setSnackMessage] = React.useState<string>('');
  const [severity, setSeverity] = useState<'success' | 'info' | 'warning' | 'error' | undefined>(
    undefined,
  );
  const screenWidth = useWindowDimensions().width;
  const [isOnboardingOpen, setIsOnboardingOpen] = React.useState<boolean>(false);
  const location = useLocation();

  const steps = [
    {
      element: screenWidth > 767 ? '.text-model-edit' : '.text-model',
      position: screenWidth > 767 ? 'bottom' : 'floating',
      intro:
        'Set template parameters based on your preferences and click Generate. Let Marquètte write content for you!',
      tooltipClass: 'onboarding-tooltip__setup onboarding-tooltip__height',
      highlightClass: 'onboarding-highlight__setup',
      disableInteraction: true,
    },
  ];

  function snackBarAction(
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error' | undefined,
    open: boolean,
  ) {
    setSnackMessage(message);
    setSeverity(severity);
    setOpenSnack(open);
  }

  const handleOnboardingExit = () => {
    setIsOnboardingOpen(false);
  };

  return (
    <>
      <div className="content-title">
        {location.pathname === '/text-model' && (
          <IconButton onClick={() => setIsOnboardingOpen(true)} className="button-hint">
            <HintIcon />
          </IconButton>
        )}
        <div className="content-title__main">
          <h1>
            {isPublic === false && <img src={`${imageLink}${image as string}`} alt="Words Icon" />}
            {title}
          </h1>
          <div className="content-title__info">
            {description && !location.pathname.includes('text-model-details') && (
              <span className="content-title__info-item">
                {isPublic === true && (
                  <img src={`${imageLink}${image as string}`} alt="Words Icon" />
                )}
                {description}
              </span>
            )}
            {date && (
              <span className="content-title__info-item">
                <img src={DateIcon} alt="Date Icon" />
                {moment(date).format(screenWidth > 767 ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY')}
              </span>
            )}
            {!!contentWords && contentWords > 0 && (
              <span className="content-title__info-item">
                <img src={WordsIcon} alt="Words Icon" />
                {contentWords} words
              </span>
            )}
          </div>
        </div>
        <div className="content-title__info-wr">
          <div className="content-title__buttons">
            {isActionBtnDisplayed && (
              <>
                <div className="content-title__buttons-share">
                  <IconButton
                    aria-label="like"
                    className="orange-icon-btn"
                    onClick={() => likeFunction && id && likeFunction(id, !isFavorite)}
                  >
                    <LightTooltip
                      title={
                        isFavorite ? 'Remove content from favorites' : 'Add content to favorites'
                      }
                      placement={'top'}
                    >
                      {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                    </LightTooltip>
                  </IconButton>
                  <ShareContentBtn
                    publicHash={publicHashId}
                    contentId={id}
                    snackBarAction={snackBarAction}
                    isDetailsPage={true}
                  />
                  <DownloadFileBtn
                    refElement={refContent as HTMLDivElement}
                    title={title}
                    isPreviewStep={isPreviewStep}
                    contentAsHtml={contentAsHtml}
                  />
                </div>
                <QuestionFeedback modelId={id} feedback={feedback} />
              </>
            )}
            {isPreviewStep && (
              <IconButton
                className="orange-icon-btn"
                onClick={() =>
                  handleDownloadPdf(refContent as HTMLDivElement, title, isPreviewStep)
                }
              >
                <PictureAsPdfOutlinedIcon />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      <SnackbarMessage
        open={isOpenSnack}
        message={snackMessage}
        severity={severity}
        setOpen={setOpenSnack}
      />
      <Onboarding steps={steps} onExit={handleOnboardingExit} isOpen={isOnboardingOpen} />
    </>
  );
};

export default MyContentDetailsTitle;
