// CATEGORIES - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as industriesActionType from './types/types';

export const getStatusRequest = createAction(industriesActionType.GET_INDUSTRIES_REQUEST);
export const getStatusSuccess = createAction(industriesActionType.GET_INDUSTRIES_SUCCESS);
export const getStatusFailure = createAction(industriesActionType.GET_INDUSTRIES_FAILURE);
