import {call, fork, spawn, put, StrictEffect} from 'redux-saga/effects';

type Saga = (...args: any[]) => Generator<StrictEffect, any, any>;
type Effect = any;
type SupportedEffects = 'call' | 'fork' | 'spawn';
type FluxStandardAction = {
  type: string;
  error?: true;
  payload?: any;
  meta?: any;
};
interface Strategy {
  safe: boolean;
  effect: string;
  logErrors: boolean;
  logRestarts: boolean;
  errorMessage: string;
  restart: boolean | number;
  errorAction?: (error: Error) => FluxStandardAction;
  errorTransform: (error: Error) => any;
  [key: string]: string | Object | undefined;
}
const defaultRetries = Infinity;

export function autoRestart(
  saga: Saga,
  maximum: number = defaultRetries,
  logRestarts?: boolean,
): Saga {
  return function* sagaRestarter(...args: Array<any>) {
    let syncException: boolean = false;
    let retries: number = 0;

    while (!syncException && retries < maximum) {
      syncException = true;

      try {
        const showRestartMessage = logRestarts && retries > 0;

        syncException = false;
        retries += 1;

        if (showRestartMessage) {
          console.info(`Saga '${saga.name}' restarted. (${retries} of ${maximum} times allowed)`);
        }

        yield call(saga, ...args);
      } catch (error) {
        if (syncException) {
          // This error has to be highly visible, since this is a fail-fast scenario.
          console.error(
            `Saga '${saga.name}' was terminated because it encountered an exception on startup.`,
          );
        } else if (logRestarts) {
          console.info(`Saga '${saga.name}' encountered an error and must restart.`);
        }

        throw error;
      }
    }
  };
}

export function reportErrors(saga: Saga, message?: string): Saga {
  return function* sagaErrorReporter(...args: Array<StrictEffect>) {
    try {
      yield* saga(...args);
    } catch (error) {
      console.error(message || `Uncaught exception in saga '${saga.name}':\n`, error);
      throw error;
    }
  };
}

export function safeEffect(saga: Saga) {
  return function* sagaErrorSupression(...args: Array<any>): Effect {
    try {
      yield* saga(...args);
    } catch (error) {
      // Do nothing: the error should be caught and not propagated to the parent
    }
  };
}

export function safeErrorDispatchEffect(
  saga: Saga,
  errorTransform: (error: Error, ...args: any[]) => Error,
  errorAction?: (error: Error) => FluxStandardAction,
) {
  return function* sagaErrorDispatch(...args: Array<any>): Effect {
    try {
      yield* saga(...args);
    } catch (error) {
      if (errorAction) {
        let payload = error as Error;
        if (errorTransform) {
          payload = errorTransform(payload, ...args);
        }
        yield put(errorAction(payload));
      }
    }
  };
}

export function* effectWrapper(effect: SupportedEffects, saga: Saga, ...args: Array<any>): Effect {
  try {
    switch (effect) {
      case 'fork':
        yield fork(saga, ...args);
        break;

      case 'spawn':
        yield spawn(saga, ...args);
        break;

      case 'call':
      default:
        yield call(saga, ...args);
        break;
    }
  } catch (error) {
    // console.log(error);
  }
}

export function* supervise(strategyOptions: Strategy, saga: Saga, ...args: Array<any>): Effect {
  const activeStrategy: Strategy = {
    safe: true,
    effect: 'spawn',
    logErrors: false,
    logRestarts: false,
    errorMessage: '',
    restart: true,
    errorAction: undefined,
    errorTransform: (error: Error) => error,
  };

  Object.keys(activeStrategy).forEach((key: keyof Strategy) => {
    if (Object.prototype.hasOwnProperty.call(strategyOptions, key)) {
      activeStrategy[key] = strategyOptions[key];
    }
  });

  let wrappedSaga = saga;

  if (activeStrategy.restart) {
    const retries =
      typeof activeStrategy.restart === 'boolean' ? defaultRetries : activeStrategy.restart;
    wrappedSaga = autoRestart(wrappedSaga, retries, activeStrategy.logRestarts);
  }

  if (activeStrategy.logErrors) {
    wrappedSaga = reportErrors(wrappedSaga);
  }

  if (activeStrategy.safe) {
    wrappedSaga = safeEffect(wrappedSaga);
  }
  if (activeStrategy.errorAction) {
    wrappedSaga = safeErrorDispatchEffect(
      wrappedSaga,
      activeStrategy.errorTransform,
      activeStrategy.errorAction,
    );
  }

  yield effectWrapper(activeStrategy.effect as SupportedEffects, wrappedSaga, ...args);
}
