import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {
  ICard,
  IInitializeCardResponse,
  IPaymentMethods,
  IPaymentMethodsState,
  IState,
} from '../../../interfaces';
import './style.scss';
import React, {useEffect, useState} from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Payment from '../Payment';
import {useDispatch, useSelector} from 'react-redux';
import {getPaymentMethodsRequest} from '../../../actions/payments';
import LoadingSpinner from '../../UI/LoadingSpinner';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {postSubscriptionRequest} from '../../../actions/billing';
import {Link, useNavigate} from 'react-router-dom';
import {ReactComponent as NiceToMeetIcon} from '../../../images/nice-to-meet-img.svg';
import moment from 'moment';
import {getPaymentSystemIcon, getPlanIconByName} from '../../../helpers/helper';
import StripeFooter from '../StripeFooter';
import PaymentForm from '../Payment/PaymentForm';
import ModalWithImage from '../../UI/ModalWithImage';
import {useActiveUser} from '../../../helpers/customHooks';
import {IconButton, TextField} from '@material-ui/core';
import * as coupons from '../../../actions/coupon';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

interface IProps {
  card: ICard;
  monthly: boolean;
  isAuthorized: boolean;
  userRole: string;
  initializeCardResponse?: IInitializeCardResponse | null;
  usersPaymentMethods: IPaymentMethodsState;
  setPageLoading: (loading: boolean) => void;
}

const PlanCard = (props: IProps): JSX.Element => {
  const {card, monthly, isAuthorized, initializeCardResponse, usersPaymentMethods, setPageLoading} =
    props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const enabledBenefits = card.details.filter((item) => item.enabled);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const [isCardSavingSucceeded, setCardSavingSucceeded] = useState<boolean>(false);
  const [paymentMethodsArray, setPaymentMethodsArray] = useState<IPaymentMethods[]>([]);
  const [isPaidSubscriptionSelected, setPaidSubscriptionSelected] = useState<boolean>(false);
  const [isPaymentSucceedModalOpen, setPaymentSucceedModalOpen] = useState<boolean>(false);
  const [isPurchaseSucceeded, setPurchaseSucceeded] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>('');
  const [isPromocodeChecked, setPromocodeChecked] = useState<boolean>(false);
  const [percentOfDiscount, setPercentOfDiscount] = useState<number | null>(null);
  const [valueOfDiscount, setValueOfDiscount] = useState<number | null>(null);
  const [isCouponError, setCouponError] = useState<boolean>(false);

  const paymentMethodsState = useSelector((state: IState) => state.paymentMethods);
  const boughtSubscriptionState = useSelector((state: IState) => state.boughtSubscription);
  const userProfileState = useSelector((state: IState) => state.userProfile);
  const couponState = useSelector((state: IState) => state.coupon);

  const defaultCard = paymentMethodsArray.filter((item) => item.isDefaultPaymentCard)[0];
  const userSubscription = userProfileState.userProfile.currentUserSubscription;
  const userStatus = useActiveUser();
  const iconSrc = getPlanIconByName(card.name);
  const isTrialAvailable = userStatus.currentStatus === 'None';
  const isButtonDisabled = card.isFuturePlan || !card.isAvailableForUser;
  const buttonText = isTrialAvailable ? 'Start 7-Day Free Trial' : 'Start now';
  const isTrialActive = userStatus.currentStatus === 'TrialActive';

  useEffect(() => {
    usersPaymentMethods.paymentMethods &&
      setPaymentMethodsArray(usersPaymentMethods.paymentMethods.items);
  }, [usersPaymentMethods]);

  useEffect(() => {
    isCardSavingSucceeded && dispatch(getPaymentMethodsRequest());
  }, [isCardSavingSucceeded]);
  useEffect(() => {
    if (isPaidSubscriptionSelected) {
      if (!boughtSubscriptionState.loading) {
        setPurchaseSucceeded(true);
      }
    }
  }, [boughtSubscriptionState]);
  useEffect(() => {
    if (!userProfileState.loading && isTrialAvailable) {
      setPageLoading(false);
    }
    if (!userProfileState.loading && isPaidSubscriptionSelected && isPurchaseSucceeded) {
      setPaymentModalOpen(false);
      setPaymentSucceedModalOpen(true);
    }
  }, [userProfileState]);

  useEffect(() => {
    if (!couponState.loading && couponState.checkCouponResponse && couponCode.length) {
      const discountPercent =
        100 - (couponState.checkCouponResponse.priceWithDiscount * 100) / +card.price;
      const discountSum = +card.price - couponState.checkCouponResponse.priceWithDiscount;
      setPercentOfDiscount(
        Number.isInteger(discountPercent) ? discountPercent : +discountPercent.toFixed(2),
      );
      setValueOfDiscount(Number.isInteger(discountSum) ? discountSum : +discountSum.toFixed(2));
      setPromocodeChecked(true);
    } else if (!couponState.loading && couponState.error.length && couponCode.length) {
      setCouponError(true);
    }
  }, [couponState.loading]);

  function handleClick() {
    if (!usersPaymentMethods.loading) {
      setPaymentModalOpen(true);
      setPaidSubscriptionSelected(true);
    }
  }

  const confirmPurchase = () => {
    const data = {
      subscriptionId: card.id,
      promocode: couponCode,
    };
    dispatch(postSubscriptionRequest(data));
  };

  const isInUseNow = () => {
    return userSubscription && userSubscription.subscriptionId === card.id;
  };

  const planCardStatusClass = () => {
    if (isAuthorized) {
      if (!card.isFuturePlan && card.isAvailableForUser) {
        return 'active';
      } else {
        return 'disabled';
      }
    } else {
      if (!card.isFuturePlan) {
        return 'active';
      } else {
        return 'disabled';
      }
    }
  };

  function checkCoupon() {
    const dataForCheck = {
      subscriptionId: card.id,
      promocode: couponCode,
    };
    dispatch(coupons.postCheckCouponWithoutApplyRequest(dataForCheck));
  }
  function onCheckoutDialogClose() {
    setPaymentModalOpen(false);
    setPromocodeChecked(false);
    setCouponCode('');
    setCouponError(false);
  }

  function onPromocodeDelete() {
    setPromocodeChecked(false);
    setCouponCode('');
  }

  function onCancel() {
    onPromocodeDelete();
    setPaymentModalOpen(false);
  }

  const CheckedPromocode = (): JSX.Element => {
    return (
      <div className="plan-details__checked-promocode">
        <div className="checked-promocode-wr">
          <div className="checked-promocode">
            <LocalOfferIcon />
            <p>{couponCode}</p>
            <IconButton onClick={onPromocodeDelete}>
              <CloseIcon />
            </IconButton>
          </div>
          <p className="discount-percent">{percentOfDiscount}% off for this purchase</p>
        </div>
        <p className="discount-value">-${valueOfDiscount}</p>
      </div>
    );
  };

  return (
    <Card
      className={`plan__card ${planCardStatusClass()}
        ${card.name === 'Silver Plan' ? 'bordered silver' : ''}
        ${isInUseNow() && !userStatus.isExpired ? 'active-now' : ''}
        ${card.oldPrice ? 'sale' : ''}
      `}
    >
      <img alt="" src={card.img} className="plan-img" />
      <CardContent
        className="plan__card-content"
        id={`${monthly ? card.monthId || 'card' + card.id : card.yearId || 'card' + card.id}`}
      >
        {card.name === 'Silver Plan' && <p className="silver-title">Highly recommended</p>}
        <h3 className="card__title">{card.name}</h3>
        {card.oldPrice && (
          <p className="plan__old-price">
            <s>${card.oldPrice}</s>
            <span className="plan__duration">{monthly ? '/ 30 days' : '/ 1 year'}</span>
          </p>
        )}
        <p className={`plan__price ${card.oldPrice ? 'sale' : ''}`}>
          <span className="plan__currency">$</span>
          <span className="plan__price-value">{parseFloat(card.price).toFixed(2)}</span>
          <span className="plan__duration">{monthly ? '/ 30 days' : '/ 1 year'}</span>
        </p>
        <p className="plan__subtitle">{card.description}</p>
        <ul className="plan__list">
          <li className="plan__list-item active">
            <CheckRoundedIcon className="active" />
            {card.credits} Credits
          </li>
          {card.details.map((item, i) => {
            return (
              <li key={i} className={`plan__list-item ${item.enabled ? 'active' : 'disabled'}`}>
                {item.enabled ? <CheckRoundedIcon className="active" /> : <CloseRoundedIcon />}
                {item.value}
              </li>
            );
          })}
        </ul>
      </CardContent>
      {isAuthorized && (
        <CardActions className="plan__action">
          {isInUseNow() && !userStatus.isExpired ? (
            <span className="plan__active-btn">Active subscription</span>
          ) : (
            <Button
              className={`blue-btn blue-btn-hover ${card.id === '1' ? 'blue-btn--filled' : ''}`}
              disabled={isButtonDisabled}
              onClick={handleClick}
            >
              {card.isFuturePlan ? 'Coming Soon' : buttonText}
            </Button>
          )}
        </CardActions>
      )}
      <Dialog
        open={isPaymentModalOpen && !paymentMethodsArray.length}
        aria-labelledby="dialog-before-close"
        className="NewQuestion__modal"
        onClose={onCheckoutDialogClose}
      >
        <div className="payment-modal-wr">
          <div className="modal__window ">
            <h5 className="modal__title">Add credit card</h5>
            <p className="modal__subtitle">Please enter your payment details.</p>
            <DialogActions className="modal__buttons payment-modal-actions">
              {initializeCardResponse && (
                <Payment initializeCardResponse={initializeCardResponse}>
                  <PaymentForm
                    clientSecret={initializeCardResponse.clientSecret}
                    setSavingCardSucceeded={setCardSavingSucceeded}
                    btnText={'Next step'}
                    isAccountPage={false}
                    setPaymentModalOpen={setPaymentModalOpen}
                    subscriptionId={card.id}
                    isTrial={isTrialAvailable}
                  />
                </Payment>
              )}
              <StripeFooter />
            </DialogActions>
            {paymentMethodsState.loading && (
              <div className="modal__window--loading">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
      </Dialog>
      <Dialog
        open={isPaymentModalOpen && paymentMethodsArray.length > 0}
        aria-labelledby="dialog-before-close"
        className="NewQuestion__modal"
        onClose={onCheckoutDialogClose}
      >
        <div className="payment-modal-wr">
          <div className="modal__window ">
            <h5 className="modal__title">Checkout</h5>
            <div className="plan-details">
              <div className="card-info-wr">
                <span className="card-info">
                  <p>Payment method:</p>
                  <span>
                    <img src={getPaymentSystemIcon(defaultCard && defaultCard.brand)} alt="" />
                    **** **** **** {defaultCard && defaultCard.last4}
                  </span>
                </span>
                <p className="modal__edit">
                  <a href="/account/profile#payment">
                    <EditIcon />
                    Payment settings
                  </a>
                </p>
              </div>
              <div className="plan-details__wr">
                <div className="plan-details__info">
                  <div className="plan-details__name-wr">
                    <img className="plan-details__icon" alt="" src={card.img} />
                    <div>
                      <h4 className="plan-name">{card.name}</h4>
                      <p className="billing-period">Billed {monthly ? ' monthly' : ' yearly'}</p>
                    </div>
                  </div>
                  <p className="plan-details__price">${card.price}</p>
                </div>
                <ul className="plan-details__list">
                  {enabledBenefits.map((item, idx) => (
                    <li key={idx} className="plan__list-item active">
                      <CheckRoundedIcon className="active" />
                      {item.value}
                    </li>
                  ))}
                </ul>
              </div>
              {!couponState.loading ? (
                <div className="plan-details__promocode">
                  {!isPromocodeChecked ? (
                    <>
                      <span className="description-wr">
                        <p>If you have a coupon code, please enter it below.</p>
                        <Link to={'/help-center/coupon-codes'}>Read more</Link>
                      </span>
                      <div className="promocode-input-wr">
                        <TextField
                          className="promocode-input"
                          value={couponCode}
                          label="Add coupon code"
                          type="text"
                          id="coupon-code"
                          onChange={(e) => setCouponCode(e.target.value)}
                          error={isCouponError}
                          helperText={isCouponError && 'Please enter valid coupon code'}
                        />
                        {couponCode.length > 0 && (
                          <Button className="apply-button" onClick={checkCoupon}>
                            Apply
                          </Button>
                        )}
                      </div>
                    </>
                  ) : (
                    <CheckedPromocode />
                  )}
                </div>
              ) : (
                <LoadingSpinner />
              )}
              <div className="plan-details__total-wr">
                <p>Total due today</p>
                <p>
                  $
                  {isPromocodeChecked &&
                  couponState.checkCouponResponse &&
                  couponState.checkCouponResponse.priceWithDiscount
                    ? couponState.checkCouponResponse.priceWithDiscount
                    : card.price}
                </p>
              </div>
            </div>
            <DialogActions className="modal__buttons payment-modal-actions">
              <div className="payment-form__buttons">
                <Button
                  onClick={confirmPurchase}
                  className="orange-btn orange-btn-hover"
                  disabled={paymentMethodsState.loading}
                >
                  Confirm purchase
                  <ArrowForwardIcon />
                </Button>
                <Button className="white-btn white-btn-hover" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
              <p className="plan-details__add-info">
                By confirming your subscription, you allow Marquette to charge your card for this
                payment and future payments in accordance with their terms.
              </p>
            </DialogActions>
            <StripeFooter />
            {boughtSubscriptionState.loading || userProfileState.loading ? (
              <div className="modal__window--loading">
                <LoadingSpinner />
              </div>
            ) : null}
          </div>
        </div>
      </Dialog>

      <ModalWithImage
        isOpen={isPaymentSucceedModalOpen}
        topImage={<NiceToMeetIcon />}
        title={isTrialActive ? '7-Day Free Trial Success!' : 'Payment success'}
        subtitle={
          isTrialActive
            ? 'Congratulations on starting your Free Trial! For the next 7 days you will be able to use all Marquètte features and explore all capabilities included in the Silver Plan .'
            : ''
        }
        specificClass={'payment-success-modal'}
        buttons={
          <Button
            className="orange-btn orange-btn-hover"
            onClick={() => navigate(`/main/dashboard`)}
          >
            Got it!
          </Button>
        }
      >
        <>
          {userProfileState && userProfileState.userProfile && (
            <div className="subscription-info-wr">
              <span>
                Your {isTrialActive ? 'Free Trial' : card.name} will expire on
                <p>
                  {` ${moment(
                    userProfileState.userProfile.currentUserSubscription?.expirationUtc,
                  ).format('DD/MM/YYYY')}`}
                </p>
              </span>
              <span className="subscription-info-wr--bottom">
                You have
                <img src={iconSrc} alt="credits icon" />
                <p>{` ${card.credits}`}</p>
              </span>
            </div>
          )}
          <p className="modal__description">
            You can change your Subscription Plan in {` `}
            <Link to="/account/profile">Account details.</Link>
          </p>
        </>
      </ModalWithImage>
    </Card>
  );
};

export default PlanCard;
