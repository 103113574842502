import {useEffect, useState} from 'react';
import './style.scss';
import {useDispatch, useSelector} from 'react-redux';
import LoadingSpinner from '../UI/LoadingSpinner';
import Search from '../Search';
import SingleSelectTextfield from '../UI/SingleSelectTextfield';
import {
  IArea,
  IIndustry,
  IState,
  ITextModelEntriesItem,
  ITextModelsPayload,
} from '../../interfaces';
import * as textModels from '../../actions/textModel';
import ContentTable from './MyContentList';
import CloseIcon from '@material-ui/icons/Close';
import {IconButton} from '@material-ui/core';
// import {ReactComponent as FilterIcon} from '../../images/filter-icon.svg';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import useWindowDimensions from '../../helpers/windowResize';
import ModalFilter from './ModalFilter';
import EmptyContent from './EmptyContent';
import SingleSelectDropdown from '../UI/SingleSelectDropdown';
import {IItem} from '../UI/MultipleSelectorAll';

export interface IFilterArrayItems extends IItem {
  id: string;
  name: string;
  imageUrl?: string;
}
export interface IFilterArray {
  areas: IArea[];
  industry: IFilterArrayItems[];
  stencil: IFilterArrayItems[];
  status: IFilterArrayItems[];
}
interface IProps {
  isArchived: boolean;
  isFavorite: boolean;
}

// const useStyles = makeStyles((theme: Theme) => ({
//   textField: {
//     margin: theme.spacing(1),
//     width: '100%',
//     '&:hover': {
//       backgroundColor: 'lightgray', // Change background color on hover
//     },
//     '&:focus': {
//       borderColor: 'blue', // Change border color on focus
//     },
//   },
// }));

export const MyContent = (props: IProps): JSX.Element => {
  const {isArchived, isFavorite} = props;
  const dispatch = useDispatch();
  const windowSize = useWindowDimensions();
  const [industryArray, setIndustryArray] = useState<IFilterArrayItems[] | null>([]);
  const [areasArray, setAreasArray] = useState<IArea[] | null>([]);
  const [stencilsArray, setStencilsArray] = useState<IFilterArrayItems[] | null>([]);
  const mockContentStatus: IFilterArrayItems[] = [
    {id: '1', name: 'Pending'},
    {id: '2', name: 'Generating'},
    {id: '3', name: 'Done'},
  ];
  const mockSortStatus = [
    {id: 'Latest', name: 'Latest'},
    {id: 'Oldest', name: 'Oldest'},
  ];
  const [selectedStatus, setSelectedStatus] = useState<IFilterArrayItems[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<IFilterArrayItems[]>([]);
  const [selectedStencil, setSelectedStencil] = useState<IFilterArrayItems[]>([]);
  const [selectedArea, setSelectedArea] = useState<IArea[]>([]);
  const [selectedSort, setSelectedSort] = useState<string>('Descending');
  const [searchText, setSearchText] = useState<string | null>('');
  const [page, setPage] = useState<number>(1);
  const [filterArray, setFilterArray] = useState<IFilterArray>({
    industry: [],
    stencil: [],
    areas: [],
    status: [],
  });
  const [filtersUpdated, setFiltersUpdated] = useState<Date | null>(null);
  const [isFiltersApplied, setFiltersApplied] = useState<boolean>(false);
  const [isModalFilterOpen, setModalFilterOpen] = useState<boolean>(false);
  const [emptyText, setEmptyText] = useState<string>('');

  const content = useSelector((state: IState) => state.textModel);
  const industryIds = useSelector((state: IState) => state.industries.industries.items);
  const areasIds = useSelector((state: IState) => state.areas.areas && state.areas.areas.items);
  const entriesState = useSelector((state: IState) => state.textModel.textModelEntries);
  const industryPayload = selectedIndustry.map((item: IFilterArrayItems) => item.id);
  const stencilPayload = selectedStencil.map((item: IFilterArrayItems) => item.id);
  const areaPayload = selectedArea.map((item: IArea) => item.id);
  const statusPayload = selectedStatus.map((item: IFilterArrayItems) => item.id);
  const contentData: ITextModelsPayload = {
    industryIds: industryPayload,
    templateNames: stencilPayload,
    areaIds: areaPayload,
    statusIds: statusPayload,
    search: searchText,
    sortOrder: selectedSort,
    pageNumber: page,
    onlyArchived: isArchived,
    onlyFavorite: isFavorite,
  };
  // const filtersLength =
  //   filterArray.areas.length +
  //   filterArray.industry.length +
  //   filterArray.stencil.length +
  //   filterArray.status.length;

  useEffect(() => {
    dispatch(textModels.getTextModelTemplateRequest());
    dispatch(textModels.getTextModelEntriesRequest());
  }, []);
  useEffect(() => {
    if (areasIds) {
      setAreasArray(areasIds);
    }
  }, [areasIds]);
  useEffect(() => {
    handleClearFilters();
    setFilterArray({
      industry: [],
      stencil: [],
      areas: [],
      status: [],
    });

    if (isArchived) {
      setEmptyText('No archived content');
    } else if (isFavorite) {
      setEmptyText('No favorite content here yet');
    } else {
      setEmptyText(
        'Start creating the best content with <span class="marquette-label">Marquètte</span>',
      );
    }
  }, [isArchived, isFavorite]);

  useEffect(() => {
    if (industryIds) {
      const updatedArray = industryIds.map((item: IIndustry) => ({
        id: item.id.toString(),
        name: item.name,
      }));
      setIndustryArray(updatedArray);
    }
  }, [industryIds]);

  useEffect(() => {
    if (entriesState && entriesState.items) {
      const stencilsArray = entriesState.items.map((i: ITextModelEntriesItem) => ({
        name: i.name,
        id: i.contentType,
      }));
      setStencilsArray(stencilsArray);
    }
  }, [content.textModelEntries]);

  function loadContentData(page: number) {
    const contentPayload = {...contentData, search: searchText, pageNumber: page};
    dispatch(textModels.getTextModelsRequest(contentPayload));
  }

  useEffect(() => {
    !content.loading && loadContentData(page);
  }, [page, filtersUpdated]);

  useEffect(() => {
    !content.loading && (content.shareResponse || content.unShareResponse) && loadContentData(page);
  }, [content.shareResponse, content.unShareResponse]);

  function handleFiltering(selectedSort: string) {
    selectedSort === 'Latest' ? setSelectedSort('Descending') : setSelectedSort('Ascending');
  }

  const handleSearch = () => {
    if (!content.loading) {
      loadContentData(page);
    }
  };

  useEffect(() => {
    setPage(1);
    setFiltersUpdated(new Date());
  }, [
    selectedIndustry.length,
    selectedStencil.length,
    selectedArea.length,
    selectedStatus.length,
    selectedSort,
    isArchived,
    isFavorite,
  ]);
  useEffect(() => {
    setFilterArray({
      areas: selectedArea,
      industry: selectedIndustry,
      stencil: selectedStencil,
      status: selectedStatus,
    });
  }, [selectedArea, selectedIndustry, selectedStencil, selectedStatus]);

  useEffect(() => {
    if (
      selectedIndustry.length > 0 ||
      selectedStencil.length > 0 ||
      selectedArea.length > 0 ||
      selectedStatus.length > 0
    ) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }
    selectedIndustry.length === industryArray?.length && setSelectedIndustry([]);
    selectedStencil.length === stencilsArray?.length && setSelectedStencil([]);
    selectedArea.length === areasArray?.length && setSelectedArea([]);
    selectedStatus.length === mockContentStatus.length && setSelectedStatus([]);
  }, [selectedIndustry.length, selectedStencil.length, selectedArea.length, selectedStatus.length]);

  function handleDeleteFIlter(index: number, objectIndex: number) {
    const newIndustryArray: IFilterArrayItems[] = filterArray.industry;
    const newStencilArray: IFilterArrayItems[] = filterArray.stencil;
    const newAreaArray: IArea[] = filterArray.areas;
    const newStatusArray: IFilterArrayItems[] = filterArray.status;

    if (index !== -1) {
      if (objectIndex === 0) {
        newAreaArray.splice(index, 1);
        setFilterArray((prev: IFilterArray) => ({
          ...prev,
          areas: newAreaArray,
        }));
      }
      if (objectIndex === 1) {
        newIndustryArray.splice(index, 1);
        setFilterArray((prev: IFilterArray) => ({
          ...prev,
          industry: newIndustryArray,
        }));
      }
      if (objectIndex === 2) {
        newStencilArray.splice(index, 1);
        setFilterArray((prev: IFilterArray) => ({
          ...prev,
          stencil: newStencilArray,
        }));
      }
      if (objectIndex === 3) {
        newStatusArray.splice(index, 1);
        setFilterArray((prev: IFilterArray) => ({
          ...prev,
          status: newStatusArray,
        }));
      }
    }
  }

  function handleClearFilters() {
    setSelectedIndustry([]);
    setSelectedStencil([]);
    setSelectedArea([]);
    setSelectedStatus([]);
    setSearchText('');
    setFiltersApplied(false);
    setFiltersUpdated(new Date());
  }

  // function openMobileFilters() {
  //   setModalFilterOpen(!isModalFilterOpen);
  // }

  function getPageName(): string {
    if (isArchived) {
      return 'Archive';
    } else if (isFavorite) {
      return 'Favorites';
    } else {
      return 'My Content';
    }
  }

  return (
    <>
      <div
        className={`content ${
          content.textModels?.items && content.textModels?.totalCount === 0 ? 'qqqqqq' : ''
        }`}
      >
        <div className="content__top">
          <div className="content__bar">
            <h2 className="content__bar-title">{getPageName()}</h2>
            <div className="content__bar-search">
              <Search
                disabled={false}
                searchFunction={handleSearch}
                searchText={searchText}
                setSearchText={setSearchText}
                clearFilter={handleClearFilters}
              />
            </div>
          </div>
        </div>
        {windowSize.width > 959 ? (
          <div className="content__filter">
            <div className="content__filter-items">
              {/* Hidden Stencil Filter */}
              {/*{stencilsArray && (*/}
              {/*  <div className="single-filter-wr">*/}
              {/*    <p className="single-filter-wr__label">Stencil</p>*/}
              {/*    <MultipleSelectorAll*/}
              {/*      itemsList={stencilsArray}*/}
              {/*      setSelectedItems={setSelectedStencil}*/}
              {/*      selectedItems={selectedStencil}*/}
              {/*      itemName={'stencil'}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
            <div className="content__sortBy-wr">
              <p>Sort by</p>
              <SingleSelectDropdown
                buttonName={selectedSort === 'Descending' ? 'Latest' : 'Oldest'}
                itemsList={mockSortStatus}
                updateFunction={handleFiltering}
              />
            </div>
          </div>
        ) : (
          <div className="content__filters-tablet">
            {/*<IconButton*/}
            {/*  className="content__filters-tablet-button"*/}
            {/*  onClick={() => openMobileFilters()}*/}
            {/*>*/}
            {/*  <span className="content__filters-tablet-name"> Filters</span>*/}
            {/*  {isFiltersApplied ? (*/}
            {/*    <span className="content__filters-tablet-number">{filtersLength}</span>*/}
            {/*  ) : (*/}
            {/*    <FilterIcon className="content__filters-tablet-icon" />*/}
            {/*  )}*/}
            {/*</IconButton>*/}
            <div> </div>
            <SingleSelectTextfield
              inputLabel={'Sort by'}
              showLabel={'Sort by'}
              itemsList={mockSortStatus}
              updateFunction={(val: string) => handleFiltering(val)}
              className="content__select"
              selectedValue={mockSortStatus[0].id}
              shrink={false}
            />
          </div>
        )}
        {isFiltersApplied && windowSize.width > 959 && (
          <div className="content__filters">
            {Object.values(filterArray).map((value: IFilterArrayItems[], objectIndex: number) =>
              value.map((i: IFilterArrayItems, index: number, _: any) => (
                <div className="content__filters-item" key={index}>
                  <span className="content__filters-filter">{i.name}</span>
                  <IconButton onClick={() => handleDeleteFIlter(index, objectIndex)}>
                    <CloseIcon className="content__filters-close-icon" />
                  </IconButton>
                </div>
              )),
            )}
            <IconButton
              className="refresh-btn"
              aria-label="refresh filters"
              component="span"
              onClick={handleClearFilters}
            >
              <AutorenewIcon />
            </IconButton>
          </div>
        )}

        {content.textModels?.items && content.textModels?.totalCount === 0 && (
          <EmptyContent text={isFiltersApplied ? 'No matching content' : emptyText} />
        )}
        {content.textModels?.items && content.textModels?.totalCount !== 0 && (
          <ContentTable
            content={content.textModels.items}
            totalPages={content.textModels.totalPages}
            page={content.textModels.pageIndex}
            setPage={setPage}
            isLoading={content.loading}
          />
        )}
        {!content.textModels?.items && <LoadingSpinner />}
      </div>
      {isModalFilterOpen && (
        <ModalFilter
          industryArray={industryArray}
          stencilArray={stencilsArray}
          areaArray={areasArray}
          statusArray={mockContentStatus}
          setModalOpen={setModalFilterOpen}
          selectedIndustry={selectedIndustry}
          setSelectedIndustry={setSelectedIndustry}
          selectedStencil={selectedStencil}
          setSelectedStencil={setSelectedStencil}
          selectedArea={selectedArea}
          setSelectedArea={setSelectedArea}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
      )}
      {content.loading && content.textModels && <div className="loading-overflow" />}
    </>
  );
};
