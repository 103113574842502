// USER MANAGEMENT SINGLE USER - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as userManagementUserActionType from './types/types';

export const getUserManagementProfileRequest = createAction(
  userManagementUserActionType.GET_SINGLE_USER_REQUEST,
);
export const getUserManagementProfileSuccess = createAction(
  userManagementUserActionType.GET_SINGLE_USER_SUCCESS,
);
export const getUserManagementProfileFailure = createAction(
  userManagementUserActionType.GET_SINGLE_USER_FAILURE,
);
