import {all, Effect} from 'redux-saga/effects';

import * as areas from './areas';
import * as industries from './industries';
import * as favorites from './favorites';
import * as userProfile from './userProfile';
import * as notifications from './notifications';
import * as userSettings from './userSettings';
import * as userUpdateName from './userUpdateName';
import * as userUpdatePhoto from './userUpdatePhoto';
import * as initializeCard from './initializeCard';
import * as paymentMethods from './paymentMethods';
import * as subscription from './subscription';
import * as billing from './billing';
import * as credits from './credits';
import * as billingHistory from './billingHistory';
import * as creditItems from './creditItems';
import * as adminCredits from './adminCredits';
import * as userManagement from './userManagement';
import * as userManagementProfile from './userManagementProfile';
import * as companies from './companies';
import * as adminSubscription from './adminSubscription';
import * as contactUs from './contactUs';
import * as coupon from './coupon';
import * as userLastLogin from './userLastLogin';
import * as userUpdateSubscriptionRenewal from './userUpdateSubscriptionRenewal';
import * as signalRStep from './signalRStep';
import * as signalRCellEdit from './signalRCellEdit';
import * as textModel from './textModel';
import * as alerts from './alerts';
import * as refund from './refundSuscription';
import * as commandsManagement from './commandsManagement';

import {supervise} from '../helpers/saga';

const defaultStrategy = {
  logErrors: typeof process.env.DEV_TOOLS === 'string',
  logRestarts: typeof process.env.DEV_TOOLS === 'string',
  safe: true,
  effect: 'spawn',
  errorMessage: '',
  restart: true,
  errorAction: undefined,
  errorTransform: (error: Error) => error,
};

// ROOT SAGA
export default function* rootSaga(): Generator<Effect<any>, void, unknown> {
  try {
    yield all([supervise({...defaultStrategy}, areas.watchAreas)]);
    yield all([supervise({...defaultStrategy}, industries.watchIndustries)]);
    yield all([supervise({...defaultStrategy}, favorites.watchFavorites)]);
    yield all([supervise({...defaultStrategy}, userProfile.watchUserProfile)]);
    yield all([supervise({...defaultStrategy}, userProfile.watchGetUserPhoto)]);
    yield all([supervise({...defaultStrategy}, userProfile.watchGetUserProfile)]);
    yield all([supervise({...defaultStrategy}, userProfile.watchDeleteUserAccount)]);
    yield all([supervise({...defaultStrategy}, userProfile.watchDeleteUserPhoto)]);
    yield all([supervise({...defaultStrategy}, notifications.watchChangePassword)]);
    yield all([supervise({...defaultStrategy}, userSettings.watchUserSettings)]);
    yield all([supervise({...defaultStrategy}, userUpdateName.watchUserUpdateName)]);
    yield all([supervise({...defaultStrategy}, userUpdatePhoto.watchUserUpdatePhoto)]);
    yield all([supervise({...defaultStrategy}, initializeCard.watchInitializePaymentCard)]);
    yield all([supervise({...defaultStrategy}, paymentMethods.watchPaymentMethods)]);
    yield all([supervise({...defaultStrategy}, subscription.watchUserSubscription)]);
    yield all([supervise({...defaultStrategy}, subscription.watchRegisteredUserSubscription)]);
    yield all([supervise({...defaultStrategy}, billing.watchBuySubscription)]);
    yield all([supervise({...defaultStrategy}, initializeCard.watchInitializeDefaultPaymentCard)]);
    yield all([supervise({...defaultStrategy}, initializeCard.watchRemovePaymentCard)]);
    yield all([supervise({...defaultStrategy}, credits.watchGetCredits)]);
    yield all([supervise({...defaultStrategy}, credits.watchBuyCredits)]);
    yield all([supervise({...defaultStrategy}, billingHistory.watchBillingHistory)]);
    yield all([supervise({...defaultStrategy}, creditItems.watchGetCreditItems)]);
    yield all([supervise({...defaultStrategy}, adminCredits.watchBuyCreditsAdmin)]);
    yield all([supervise({...defaultStrategy}, userManagement.watchUserManagement)]);
    yield all([supervise({...defaultStrategy}, companies.watchCompanies)]);
    yield all([supervise({...defaultStrategy}, userManagementProfile.watchUserManagementProfile)]);
    yield all([supervise({...defaultStrategy}, adminSubscription.watchAdminSubscription)]);
    yield all([supervise({...defaultStrategy}, contactUs.watchSendContactUs)]);
    yield all([supervise({...defaultStrategy}, coupon.watchCreateCoupon)]);
    yield all([supervise({...defaultStrategy}, coupon.watchGetCouponsManagement)]);
    yield all([supervise({...defaultStrategy}, coupon.watchApplyCoupon)]);
    yield all([supervise({...defaultStrategy}, coupon.watchGetCouponsHistory)]);
    yield all([supervise({...defaultStrategy}, coupon.watchGetCouponById)]);
    yield all([supervise({...defaultStrategy}, coupon.watchCheckCoupon)]);
    yield all([supervise({...defaultStrategy}, coupon.watchUpdateCoupon)]);
    yield all([supervise({...defaultStrategy}, coupon.watchCheckCouponWithoutApply)]);
    yield all([supervise({...defaultStrategy}, userLastLogin.watchUserLastLogin)]);
    yield all([supervise({...defaultStrategy}, userManagement.watchAllUserManagement)]);
    yield all([
      supervise(
        {...defaultStrategy},
        userUpdateSubscriptionRenewal.watchUserUpdateSubscriptionRenewal,
      ),
    ]);
    yield all([supervise({...defaultStrategy}, signalRStep.watchSignalRStepTemplate)]);
    yield all([supervise({...defaultStrategy}, signalRCellEdit.watchSignalRCellEdit)]);
    yield all([supervise({...defaultStrategy}, textModel.watchTextModelTemplate)]);
    yield all([supervise({...defaultStrategy}, textModel.watchCreateTextModel)]);
    yield all([supervise({...defaultStrategy}, textModel.watchTextModelDetails)]);
    yield all([supervise({...defaultStrategy}, textModel.watchModelNextStep)]);
    yield all([supervise({...defaultStrategy}, textModel.watchCancelModel)]);
    yield all([supervise({...defaultStrategy}, textModel.watchTextModels)]);
    yield all([supervise({...defaultStrategy}, textModel.watchLikeModel)]);
    yield all([supervise({...defaultStrategy}, textModel.watchAddModelFeedback)]);
    yield all([supervise({...defaultStrategy}, textModel.watchPublicTextModel)]);
    yield all([supervise({...defaultStrategy}, textModel.watchSharePublicTextModel)]);
    yield all([supervise({...defaultStrategy}, textModel.watchUnsharePublicTextModel)]);
    yield all([supervise({...defaultStrategy}, textModel.watchUploadFile)]);
    yield all([supervise({...defaultStrategy}, textModel.watchSetArchiveModel)]);
    yield all([supervise({...defaultStrategy}, textModel.watchTextModelEntryCreate)]);
    yield all([supervise({...defaultStrategy}, textModel.watchGetTextModelEntry)]);
    yield all([supervise({...defaultStrategy}, alerts.watchAddAlert)]);
    yield all([supervise({...defaultStrategy}, textModel.watchTextGenerationResult)]);
    yield all([supervise({...defaultStrategy}, textModel.watchGtTextModelsForLanding)]);
    yield all([supervise({...defaultStrategy}, textModel.watchUpdateTextModelEntry)]);
    yield all([supervise({...defaultStrategy}, textModel.watchPublishTextModelEntry)]);
    yield all([supervise({...defaultStrategy}, refund.watchRefund)]);
    yield all([supervise({...defaultStrategy}, textModel.watchTextModelEntriesResult)]);
    yield all([supervise({...defaultStrategy}, textModel.watchTextModelCategoriesResult)]);
    yield all([supervise({...defaultStrategy}, textModel.watchGenerateTextModel)]);
    yield all([supervise({...defaultStrategy}, textModel.watchDeleteTextModelEntry)]);
    yield all([supervise({...defaultStrategy}, textModel.watchEntriesImages)]);
    yield all([supervise({...defaultStrategy}, textModel.watchAutosaveTextModel)]);
    yield all([supervise({...defaultStrategy}, textModel.watchTextModelStatistic)]);
    yield all([supervise({...defaultStrategy}, commandsManagement.watchCreateCommand)]);
    yield all([supervise({...defaultStrategy}, commandsManagement.watchGetCommandItem)]);
    yield all([supervise({...defaultStrategy}, commandsManagement.watchGetCommandsList)]);
    yield all([supervise({...defaultStrategy}, commandsManagement.watchPublishCommands)]);
    yield all([supervise({...defaultStrategy}, commandsManagement.watchUpdateCommand)]);
    yield all([supervise({...defaultStrategy}, commandsManagement.watchDeleteCommand)]);
  } catch (error) {
    console.log(error);
  }
}
