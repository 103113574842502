import {handleBody, handleExceptions, POST} from './request';
import {IContactUsPayload} from '../interfaces';

export const sendContactUsForm = (
  data: IContactUsPayload,
): Promise<void | Response | Object | null> => {
  const endpoint: string = `/ContactUs`;
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
