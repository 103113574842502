import React, {useEffect, useState} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {Collapse, Hidden, IconButton} from '@material-ui/core';
import moment from 'moment';
import {IHistoryTable} from '..';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import {useNavigate} from 'react-router-dom';
import {getIconSrc} from '../../../../helpers/helper';
import {LightTooltip} from '../../../UI/StatusLabel';
import {IUserCurrentSubscription} from '../../../../interfaces';
import {Editor} from '../../../TextModelComponent/TextModelEdit/Editor/Editor';
import {BLOB_LINK} from '../../../../constants';

interface IProps {
  item: IHistoryTable;
  index: number;
  currentItem: number | null;
  userPlan: IUserCurrentSubscription | undefined | null;
  setCurrentItem: (item: number | null) => void;
}

const TableRowItem = (props: IProps): JSX.Element => {
  const {item, index, currentItem, userPlan, setCurrentItem} = props;
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  function onCollapseOpen() {
    if (!open) {
      setCurrentItem(index);
    } else {
      setCurrentItem(null);
    }
  }

  const imageLink = `${BLOB_LINK}/assets-for-templates/`;

  useEffect(() => {
    currentItem === index ? setOpen(true) : setOpen(false);
  }, [currentItem]);

  const routeToTemplate = (id: string) => {
    navigate(`/text-model-details/${id}`);
  };

  return (
    <>
      <TableRow
        hover
        key={index}
        className="charge-history__table-item-row"
        onClick={onCollapseOpen}
      >
        <TableCell className="charge-history__row charge-history__table-item">
          <div className="charge-history__table-item-date">
            <Hidden smUp>
              <p className="charge-history__title">Date</p>
            </Hidden>
            <span>{moment(item.createdDate).format('MM/DD/YYYY')}</span>
            <span>{moment(item.createdDate).format('h:mm:ss a')}</span>
          </div>
        </TableCell>
        <TableCell className="charge-history__row charge-history__table-item charge-history__table-item-template">
          <Hidden smUp>
            <p className="charge-history__title">Template</p>
          </Hidden>
          <img
            src={`${imageLink}${item.image}`}
            alt="Template"
            style={{
              backgroundColor: '#DBE8FC',
              borderColor: '#F5F9FE',
            }}
            className="charge-history__table-item-icon"
          />
          <span>{item.name}</span>
        </TableCell>
        <TableCell className="charge-history__row charge-history__table-item charge-history__table-content charge-history__table-item-content">
          <Hidden smUp>
            <p className="charge-history__title">Content Title</p>
          </Hidden>
          <span>{item.modelName}</span>{' '}
          <LightTooltip title={'Go to content'} placement={'bottom'}>
            <IconButton onClick={() => routeToTemplate(item.textModelId)}>
              <OpenInNewRoundedIcon />
            </IconButton>
          </LightTooltip>
        </TableCell>
        <TableCell className="charge-history__row charge-history__table-item charge-history__table-item-field">
          <Hidden smUp>
            <p className="charge-history__title">Field Name</p>
          </Hidden>
          <span className="charge-history__table-item-name">
            {item.inputControlAlias ? item.inputControlAlias : item.modelName}
          </span>
        </TableCell>
        <TableCell className="charge-history__row charge-history__table-item charge-history__table-item-total">
          <Hidden smUp>
            <p className="charge-history__title">Credits</p>
          </Hidden>
          <div className="charge-history__table-item-credits">
            <span className="charge-history__table-item-credits-items">
              <img src={getIconSrc(userPlan)} alt="Credit Icon" />
              {item.chargeAmount}
            </span>
            <span>{!open ? <ExpandMoreRoundedIcon /> : <ExpandLessRoundedIcon />}</span>
          </div>
        </TableCell>
      </TableRow>
      <TableRow className={`collapse-row ${!open ? 'close' : ''}`}>
        <TableCell colSpan={8}>
          <Collapse in={currentItem === index} timeout="auto" unmountOnExit>
            {item.value.map((i: string, index: number) => (
              <div key={index} className="charge-history__collapse-row">
                <div className="charge-history__collapse-input">
                  <Editor hideToolbar={true} modelId={''} value={i} disabled={true} />
                </div>{' '}
                <span className="charge-history__collapse-credits">
                  <div>
                    <Hidden mdUp>
                      <p className="charge-history__title">Credits per text:</p>
                    </Hidden>
                    {item.chargeAmount ? item.chargeAmount : 0}
                  </div>
                </span>
              </div>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableRowItem;
