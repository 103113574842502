import React from 'react';
import SubscriptionComponent from './../../components/SubscriptionComponent';

const SubscriptionPlan = (): JSX.Element => {
  return (
    <div className="subscription-wr">
      <SubscriptionComponent />
    </div>
  );
};

export default SubscriptionPlan;
