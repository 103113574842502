import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr';
import {NOTIFICATION_HUB_DEVELOPMENT} from '../constants';
import {Dispatch} from 'react';
import {ISignalRResponse} from '../interfaces';
import {acquireToken} from '../authProvider/auth-provider';
import {SET_SIGNALR_STEP_REQUEST} from '../actions/types/types';
import {setSignalRCellEditRequest} from '../actions/signalRCellEdit';

const setStepResult = (payload: any) => {
  const currentTime = new Date();
  return {
    type: SET_SIGNALR_STEP_REQUEST,
    payload: {...payload, currentTime} as ISignalRResponse,
  };
};

const startSignalRConnection = (
  connection: HubConnection,
  dispatch: Dispatch<{type: string; payload: ISignalRResponse}>,
) =>
  connection
    .start()
    .then(() => {
      connection.on('notification', function (data: ISignalRResponse) {
        connectionSuccess(data, dispatch);
      });
      connection.onclose(() => {
        console.warn('SignalR Connection Lost');
        setTimeout(() => {
          connectSignalR(dispatch, true);
        }, 3000);
      });
      return;
    })
    .catch((err: any) => {
      console.warn('SignalR Connection Error: ', err);
      if (connection.state === 'Disconnected') {
        setTimeout(() => {
          connectSignalR(dispatch, true);
        }, 3000);
      }
    });

function connectionSuccess(
  data: ISignalRResponse,
  dispatch: Dispatch<{type: string; payload: ISignalRResponse}>,
) {
  // TODO: for correct determining backend should send type of message
  const resultValue: string = data.modelId;
  if (resultValue) {
    dispatch(setStepResult(data));
  }
  const operationId: string = data.operationId;
  if (operationId) {
    dispatch(setSignalRCellEditRequest(data));
  }
}

export function connectSignalR(
  dispatch: Dispatch<{type: string; payload: ISignalRResponse}>,
  forceSignalR: boolean,
): () => void {
  let connection: HubConnection | null = null;
  connection = new HubConnectionBuilder()
    .withUrl(NOTIFICATION_HUB_DEVELOPMENT, {
      accessTokenFactory: acquireToken,
      /*skipNegotiation: forceSignalR,
      transport: forceSignalR
        ? HttpTransportType.WebSockets
        : HttpTransportType.WebSockets && HttpTransportType.ServerSentEvents,*/
    })
    .withAutomaticReconnect()
    .build();

  void startSignalRConnection(connection, dispatch);

  return () => {
    if (connection && connection.state === 'Connected') {
      connection
        .stop()
        .then(() => {
          return;
        })
        .catch(() => console.log('error'));
    }
  };
}
