import React from 'react';
import {useSelector} from 'react-redux';
import {Alert} from '@material-ui/lab';
import {Snackbar} from '@material-ui/core';
import {IState} from '../../interfaces';

const Alerts = (): JSX.Element => {
  const alerts = useSelector((state: IState) => state.alerts.alerts || []);

  return (
    <>
      {alerts.map((alert, idx) => {
        return (
          <Snackbar key={idx} open={true}>
            <Alert severity={alert.type}>{alert.msg}</Alert>
          </Snackbar>
        );
      })}
    </>
  );
};
export default Alerts;
