import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import EnhancedTableHead from './EnhancedTableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRowItem from './TableItem';
import LoadingSpinner from '../../UI/LoadingSpinner';
import TablePagination from '@material-ui/core/TablePagination';
import {IGetCommandResponse} from '../../../interfaces';
import './style.scss';

type Order = 'asc' | 'desc';

interface IProps {
  commands: IGetCommandResponse[];
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rows: number) => void;
  totalCount: number;
  isLoading: boolean;
  order: Order;
  setOrder: (order: Order) => void;
  orderBy: keyof ITableData | string;
  setOrderBy: (orderBy: keyof ITableData) => void;
}

export interface ITableData {
  id: string;
  name: string;
  status: string;
  type: string;
  updatedUtc: string;
  createdUtc: string;
}

const CommandsTable = (props: IProps): JSX.Element => {
  const {
    commands,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    isLoading,
    order,
    setOrder,
    orderBy,
    setOrderBy,
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ITableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };
  return (
    <div>
      <div className="users-table">
        <TableContainer className="users-table__container">
          <Table>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={commands.length}
            />
            {!isLoading ? (
              <TableBody>
                {commands.map((row, index) => (
                  <TableRowItem key={index} item={row} index={index} />
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <tr>
                  <td>
                    <LoadingSpinner />
                  </td>
                </tr>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default CommandsTable;
