import {handleActions} from 'redux-actions';

// ACTION TYPES
import {
  POST_INIT_USER_FAILURE,
  POST_INIT_USER_REQUEST,
  POST_INIT_USER_SUCCESS,
} from '../actions/types/types';

export interface IInitNewUserState {
  loading: boolean;
  error: string;
  id: string | null;
}
interface IPayload {
  id: string;
  error: string;
}
interface IAction {
  payload: IPayload;
}

export const initialState = {
  loading: false,
  error: null,
};

const handlerMap: any = {
  [POST_INIT_USER_REQUEST]: (state: IInitNewUserState, action: IAction) => ({
    ...state,
    id: null,
    loading: true,
    error: action.payload,
  }),
  [POST_INIT_USER_SUCCESS]: (state: IInitNewUserState, action: IAction) => ({
    ...state,
    loading: false,
    error: null,
    id: action.payload.id,
  }),
  [POST_INIT_USER_FAILURE]: (state: IInitNewUserState, action: IAction) => ({
    ...state,
    error: action.payload.error,
  }),
};

export default handleActions(handlerMap, initialState);
