import {handleBody, handleExceptions, POST} from './request';

export const postPasswordChangedNotifications = (
  data: object,
): Promise<Object | Response | null> => {
  const endpoint: string = `/Notifications/SendPasswordChanged`;
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
