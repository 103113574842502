import {Grid} from '@material-ui/core';
import BackButton from '../../UI/BackButton';
import UseShortEssayImg from '../../../images/UseShortEssayImg.svg';
import {useEffect} from 'react';

export const UseShortEssayTemplate = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container className="info-page">
        <Grid item xs={11} md={11} lg={10}>
          <div className="info-page__wr">
            {/*{history.length > 1 && <BackButton match={history} prevPage />}*/}
            <BackButton prevPage />
            <Grid container>
              <Grid item xs={12} md={9} lg={8}>
                <h1 className="info-page__title">How to Use the Short Essay Template</h1>

                <div className="info-page__content">
                  <img src={UseShortEssayImg} alt="Short Essay Template illustration" />
                  <div className="content-item">
                    <p>
                      The Short Essay template will allow you to generate a simple expository essay
                      on your chosen topic. The essay created by the AI will have an introduction,
                      conclusion, and 4-6 paragraphs total.
                    </p>
                    <p>
                      To create your essay, go to <b>Create Content → Educational</b>, and choose
                      the <b>Short Essay</b> template.
                    </p>
                    <p>
                      During the next step, you can enter your essay <b>topic</b> and choose the{' '}
                      <b>tone of writing</b>.
                    </p>
                    <p>
                      To get the best result, be precise when entering the topic. For example, a
                      query like “American salmon” will generate an essay covering several most
                      prominent facts about this type of fish, such as its uses, population, and
                      current state of the species. However, a more specified query, like “American
                      salmon extinction” will create a text about the leading causes of the species
                      extinction and its impact on the ecosystem.
                    </p>
                    <p>
                      Moreover, your topic will define the type of essay the system will generate.
                      It means that you can create an expository, argumentative, or descriptive
                      essay just by wording your topic in the right way.
                    </p>
                    <p>Take a look at some examples to understand how this works:</p>
                    <ul>
                      <li>
                        Should college education be free or paid? - <b>argumentative essay</b>
                      </li>
                      <li>
                        Describe the beauty of the Northern lights - <b>descriptive essay</b>
                      </li>
                      <li>
                        Why did the COVID pandemic cause a collapse of supply chains? -{' '}
                        <b>expository essay</b>
                      </li>
                    </ul>
                    <p>
                      Click <b>Generate</b> and the system will provide you with several variations
                      of the essay. Please look through them and select the one that matches your
                      needs best.
                    </p>
                    <p>
                      If none of the options are satisfactory, click <b>Retry</b> and the AI will
                      generate alternatives.
                    </p>
                    <p>Once you have chosen your preferred essay, go to the next step.</p>
                    <p>You can share the content via a link.</p>
                    <p>
                      The Marquètte team would kindly ask you to rate the content generated by the
                      AI by clicking an appropriate smiley face. Your reactions help us improve!
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default UseShortEssayTemplate;
