import React, {useEffect, useState} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {Collapse, Hidden} from '@material-ui/core';
import moment from 'moment';
import {ICreditHistoryTable} from '..';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import {getPlanIconByName} from '../../../../helpers/helper';
import {Editor} from '../../TextModelEdit/Editor/Editor';

interface IProps {
  item: ICreditHistoryTable;
  index: number;
  currentItem: number | null;
  userPlan: string | undefined;
  setCurrentItem: (item: number | null) => void;
}

const TableRowItem = (props: IProps): JSX.Element => {
  const {item, index, currentItem, userPlan, setCurrentItem} = props;
  const [open, setOpen] = useState<boolean>(false);

  function onCollapseOpen() {
    if (!open) {
      setCurrentItem(index);
    } else {
      setCurrentItem(null);
    }
  }

  useEffect(() => {
    currentItem === index ? setOpen(true) : setOpen(false);
  }, [currentItem]);

  return (
    <>
      <TableRow
        hover
        key={index}
        className="charge-history__table-item-row"
        onClick={onCollapseOpen}
      >
        <TableCell className="charge-history__row charge-history__table-item charge-history__table-item-created ">
          <div className="charge-history__table-item-date">
            <Hidden smUp>
              <p className="charge-history__title">Date</p>
            </Hidden>
            <span>{moment(item.createdDate).format('MM/DD/YYYY')}</span>
            <span>{moment(item.createdDate).format('h:mm:ss a')}</span>
          </div>
        </TableCell>
        <TableCell className="charge-history__row charge-history__table-item charge-history__table-item-field">
          <Hidden smUp>
            <p className="charge-history__title">Field Name</p>
          </Hidden>
          <span className="charge-history__table-item-name">
            {item.inputControlAlias ? item.inputControlAlias : item.modelName}
          </span>
        </TableCell>
        <TableCell className="charge-history__row charge-history__table-item charge-history__table-item-model">
          <Hidden smUp>
            <p className="charge-history__title">Model</p>
          </Hidden>
          <span className="charge-history__table-item-name">{item.qualityType}</span>
        </TableCell>
        <TableCell className="charge-history__row charge-history__table-item charge-history__table-item-total">
          <Hidden smUp>
            <p className="charge-history__title">Credits</p>
          </Hidden>
          <div className="charge-history__table-item-credits">
            <span className="charge-history__table-item-credits-items">
              <img src={getPlanIconByName(userPlan)} alt="Credit Icon" />
              {item.chargeAmount}
            </span>
            <Hidden smDown>
              <span>{!open ? <ExpandMoreRoundedIcon /> : <ExpandLessRoundedIcon />}</span>
            </Hidden>
          </div>
        </TableCell>
        <Hidden smUp>
          <td className="charge-history__open-collapse">
            {!open ? <ExpandMoreRoundedIcon /> : <ExpandLessRoundedIcon />}
          </td>
        </Hidden>
      </TableRow>
      <TableRow className={`collapse-row ${!open ? 'close' : ''}`}>
        <TableCell colSpan={8}>
          <Collapse in={currentItem === index} timeout="auto" unmountOnExit>
            {item.value.map((i: string, index: number) => (
              <div key={index} className="charge-history__collapse-row">
                <div className="charge-history__collapse-input">
                  <Editor hideToolbar={true} modelId={''} value={i} disabled={true} />
                </div>{' '}
                <span className="charge-history__collapse-credits">
                  <div>
                    <Hidden smUp>
                      <p className="charge-history__title">Credits per text:</p>
                    </Hidden>
                    {item.chargeAmount ? item.chargeAmount : 0}
                  </div>
                </span>
              </div>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableRowItem;
