import React from 'react';
import './style.scss';
import StripeNameIcon from '../../../images/stripe-name.svg';

const StripeFooter = (): JSX.Element => {
  return (
    <div className="stripe-footer">
      <span>
        Powered by
        <img src={StripeNameIcon} alt="" />
      </span>
      <span>
        <a href="https://stripe.com/privacy">Privacy</a>
        <a href="https://stripe.com/legal">Terms</a>
      </span>
    </div>
  );
};

export default StripeFooter;
