import {useMsal} from '@azure/msal-react';
import HeroBanner from './Hero';
import LandingHeader from './Header';
import WhatGet from './Features';
import WhoCanUse from './WhoCanUse';
import FAQ from './FAQ';
import PopularQuestions from './PopularQuestions';
import LandingFooter from './Footer';
import {logInHandle} from '../../helpers/helper';
import './style.scss';

const Landing = (): JSX.Element => {
  const {instance} = useMsal();

  const btnClickHandle = (): Promise<void> => {
    return logInHandle(instance);
  };

  return (
    <>
      <LandingHeader positionFixed={true} redirectHandle={btnClickHandle} />
      <HeroBanner redirectHandle={btnClickHandle} />
      <WhatGet redirectHandle={btnClickHandle} />
      <PopularQuestions />
      <WhoCanUse />
      <FAQ />
      <LandingFooter redirectHandle={btnClickHandle} />
    </>
  );
};

export default Landing;
