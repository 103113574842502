import React, {useState} from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Button} from '@material-ui/core';
import './style.scss';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {useDispatch} from 'react-redux';
import {postInitializeDefaultPaymentCardRequest} from '../../../../actions/payments';

interface IProps {
  clientSecret: string;
  setSavingCardSucceeded: (succeeded: boolean) => void;
  isPaymentMethodExists?: boolean;
  setOpenPopup?: (open: boolean) => void;
  setDefault?: boolean;
  btnText: string;
  isAccountPage: boolean;
  setPaymentModalOpen: (item: boolean) => void;
  subscriptionId?: string;
  isTrial?: boolean;
}

const PaymentForm = (props: IProps): JSX.Element => {
  const {
    clientSecret,
    setSavingCardSucceeded,
    btnText,
    setDefault,
    setOpenPopup,
    isAccountPage,
    setPaymentModalOpen,
    subscriptionId,
    isTrial = false,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | undefined>();
  const dispatch = useDispatch();
  const inputStyle = {
    iconColor: '#BEABA3',
    fontWeight: '500',
    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then((result) => {
        if (result.error) {
          setError(result.error.message);
        } else {
          const paymentMethodId = result.setupIntent.payment_method;
          if (isAccountPage) {
            !setDefault &&
              dispatch(postInitializeDefaultPaymentCardRequest({paymentMethodId: paymentMethodId}));
          } else {
            isTrial
              ? dispatch(
                  postInitializeDefaultPaymentCardRequest({
                    paymentMethodId: paymentMethodId,
                    subscriptionId: subscriptionId,
                  }),
                )
              : dispatch(
                  postInitializeDefaultPaymentCardRequest({paymentMethodId: paymentMethodId}),
                );
          }
          setSavingCardSucceeded(Boolean(result.setupIntent.status === 'succeeded'));
          isAccountPage && setOpenPopup && setOpenPopup(false);
          if (isTrial) {
            setPaymentModalOpen(false);
          }
        }
        return result;
      })
      .catch((err) => err as string);
  };
  const options = {
    style: {
      base: inputStyle,
    },
  };
  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <CardElement className="card-element" options={options} />
      <p className="error">{error}</p>
      <p className="payment-form__add-info">
        Your information will be saved automatically.You can change the card details anytime in your
        Account.
      </p>
      <div className="payment-form__buttons">
        <Button
          disabled={!stripe || !elements}
          className="btn-pay orange-btn orange-btn-hover"
          type="submit"
        >
          {btnText}
          <ArrowForwardIcon />
        </Button>
        <Button
          className="white-btn white-btn-hover"
          type="button"
          onClick={() => setPaymentModalOpen(false)}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default PaymentForm;
