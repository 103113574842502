import {useMsal} from '@azure/msal-react';
import {useEffect} from 'react';

const Logout = (): JSX.Element => {
  const {instance} = useMsal();

  useEffect(() => {
    void instance.logoutRedirect();
  }, []);

  return <></>;
};

export default Logout;
