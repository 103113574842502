import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  POST_USER_SUBSCRIPTION_RENEWAL_REQUEST,
  POST_USER_SUBSCRIPTION_RENEWAL_SUCCESS,
  POST_USER_SUBSCRIPTION_RENEWAL_FAILURE,
} from '../actions/types/types';
import {
  IUserUpdateAutorenewalAction,
  IUserUpdateAutorenewalState,
  IUserUpdateNameResponseAction,
} from '../interfaces';

export const initialState: IUserUpdateAutorenewalState = {
  userUpdateAutorenewal: null,
  isAutorenewal: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [POST_USER_SUBSCRIPTION_RENEWAL_REQUEST]: (
    state: IUserUpdateAutorenewalState,
    action: IUserUpdateAutorenewalAction,
  ) => ({
    ...state,
    isAutorenewal: action.payload.autoRenew,
    loading: true,
  }),

  [POST_USER_SUBSCRIPTION_RENEWAL_SUCCESS]: (
    state: IUserUpdateAutorenewalState,
    action: IUserUpdateNameResponseAction,
  ) => ({
    ...state,
    userUpdateAutorenewal: action.payload,
    loading: false,
    error: '',
  }),

  [POST_USER_SUBSCRIPTION_RENEWAL_FAILURE]: (
    state: IUserUpdateAutorenewalState,
    action: object,
  ) => ({
    ...state,
    error: action,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
