import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  POST_UPDATEPROFILE_PHOTO_REQUEST,
  POST_UPDATEPROFILE_PHOTO_SUCCESS,
  POST_UPDATEPROFILE_PHOTO_FAILURE,
} from '../actions/types/types';

import {IUserUpdatePhotoResponseAction, IUserUpdatePhotoState} from '../interfaces';

export const initialState: IUserUpdatePhotoState = {
  userUpdatePhoto: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [POST_UPDATEPROFILE_PHOTO_REQUEST]: (state: IUserUpdatePhotoState) => ({
    ...state,
    loading: true,
  }),

  [POST_UPDATEPROFILE_PHOTO_SUCCESS]: (
    state: IUserUpdatePhotoState,
    action: IUserUpdatePhotoResponseAction,
  ) => ({
    ...state,
    userUpdatePhoto: action.payload,
    loading: false,
    error: '',
  }),

  [POST_UPDATEPROFILE_PHOTO_FAILURE]: (state: IUserUpdatePhotoState, action: object) => ({
    ...state,
    error: action,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
