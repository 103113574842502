import {useNavigate} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import BackButton from './../../UI/BackButton';
import ResultContentImg from '../../../images/resultContent.svg';
import './style.scss';
import {useEffect} from 'react';

export const ResultContent = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  return (
    <>
      <Grid container className="info-page result-content">
        <Grid item xs={11} md={11} lg={10}>
          <div className="info-page__wr">
            <BackButton prevPage />

            <Grid container>
              <Grid item xs={12} md={9} lg={8}>
                <h1 className="info-page__title">
                  Why there might be no result/unsatisfactory result when generating content?
                </h1>

                <div className="info-page__content">
                  <img src={ResultContentImg} alt="help-center_result-content" />
                  <div className="content-item">
                    <p>
                      AI technology is still developing, so sometimes you might experience some
                      issues when generating content with Marquètte.
                    </p>
                    <p>Two common types of problems you might encounter:</p>
                    <ul>
                      <li>
                        The content takes too long to appear, and the window seems to be ‘stuck’.
                        It’s likely that the connection with the server was disrupted. In this case,
                        you should cancel your request and go back to the previous step. Then,
                        please, try again.
                      </li>
                      <li>
                        The result provided a very small piece of content, or the content was not
                        relevant to your query. It can happen that GPT3 will generate only a small
                        piece of text, which might be completely unrelated to your topic. The
                        machine is still learning, so please don’t be too hard on it. If this
                        happens, click ‘Retry’, and it will provide you with a different result. In
                        case the result remains unsatisfactory, please consider editing your query.
                        <p>
                          See our tips on{' '}
                          <span
                            className="result-content__link"
                            onClick={() => navigate('/help-center/GoodQuestion')}
                          >
                            how to ask AI a good question.
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ResultContent;
