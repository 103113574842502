import {useEffect, useState} from 'react';
import './style.scss';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {Button, IconButton} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import coming from '../../images/coupon-coming.svg';
import Search from '../Search';
import {ReactComponent as Nothing} from '../../images/nothing.svg';
import {ICouponsManagementPayload, IState, ISubscriptionItem} from '../../interfaces';
import * as couponsManagement from '../../actions/coupon';
import * as userSubscriptionActionTypes from '../../actions/subscription';
import CouponsTable, {ITableData} from './CouponsTable';
import LoadingSpinner from '../UI/LoadingSpinner';
import CustomAutocomplete, {IItem} from '../UI/CustomAutocomplete';
import SingleSelectTextfield from '../UI/SingleSelectTextfield';
import DatePicker from '../UI/DatePicker';
import {useDatePicker} from '../../helpers/customHooks';
import moment from 'moment';
import {getTomorrow} from '../../helpers/dateTime';

type Order = 'asc' | 'desc';

interface IProductArray extends IItem {
  id: string;
  name: string;
}

export const CouponsManagement = (): JSX.Element => {
  const {nextDayDate} = useDatePicker();
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ITableData | string>('');
  const [filtersUpdated, setFiltersUpdated] = useState<Date | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<IProductArray[]>([]);
  const [productsArray, setProductsArray] = useState<IProductArray[] | null>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>('All');
  const [selectedStartDateFromFormat, setSelectedStartDateFromFormat] = useState<Date | null>(null);
  const [selectedStartDateToFormat, setSelectedStartDateToFormat] = useState<Date | null>(null);
  const [selectedExpirationDateFromFormat, setSelectedExpirationDateFromFormat] =
    useState<Date | null>(null);
  const [selectedExpirationDateToFormat, setSelectedExpirationDateToFormat] = useState<Date | null>(
    null,
  );
  const mockCouponsStatus = [
    {id: 'Active', name: 'Active'},
    {id: 'Disabled', name: 'Disabled'},
    {id: 'Scheduled', name: 'Scheduled'},
  ];
  const [searchText, setSearchText] = useState<string | null>('');
  const [isSearchActive, setSearchActive] = useState<boolean>(false);
  const coupons = useSelector((state: IState) => state.coupon);
  const products = useSelector((state: IState) => state.subscriptionRegistered.subscription?.items);
  const sortOrder = order === 'asc' ? 'Ascending' : 'Descending';

  const formatDate = (dt?: Date | null): string => {
    if (dt) {
      const m = dt.getMonth() + 1;
      const d = dt.getDate().toString().padStart(2, '0');
      const y = dt.getFullYear();
      return m + ' ' + d + ' ' + y;
    }
    return '';
  };

  const couponsData: ICouponsManagementPayload = {
    couponStatus: selectedStatus,
    startDateFrom: formatDate(selectedStartDateFromFormat),
    startDateTo: formatDate(selectedStartDateToFormat),
    endDateFrom: formatDate(selectedExpirationDateFromFormat),
    endDateTo: formatDate(selectedExpirationDateToFormat),
    couponSortCategory: orderBy,
    sortOrder: sortOrder,
    pageSize: rowsPerPage,
    search: searchText,
  };

  useEffect(() => {
    dispatch(couponsManagement.getCouponsManagementRequest());
    dispatch(userSubscriptionActionTypes.getRegisteredUserSubscriptionRequest());
  }, [coupons.updateCouponResult]);

  function loadCouponsManagementData(pageNumber: number) {
    const productArray = selectedProduct.map((item: IProductArray) => item.id);
    const couponsPayLoad = {...couponsData, pageNumber: pageNumber, product: productArray};
    dispatch(couponsManagement.getCouponsManagementRequest(couponsPayLoad));
  }

  useEffect(() => {
    if (products) {
      const formatProductsArray = products?.slice(1);
      const updatedArray = formatProductsArray.map((item: ISubscriptionItem) => ({
        id: item.id || '',
        name: `${item.name} (${item.period.toLowerCase()})`,
      }));
      setProductsArray(updatedArray);
    }
  }, [products]);

  useEffect(() => {
    !coupons.loading && loadCouponsManagementData(page + 1);
  }, [page, filtersUpdated]);

  useEffect(() => {
    setPage(0);
    setFiltersUpdated(new Date());
  }, [
    selectedProduct,
    selectedStatus,
    selectedStartDateFromFormat,
    selectedStartDateToFormat,
    selectedExpirationDateFromFormat,
    selectedExpirationDateToFormat,
    rowsPerPage,
    order,
    orderBy,
  ]);

  const dateStartFromHandleChange = (date: Date | null) => {
    const formatDate = date?.toDateString();
    if (moment(formatDate).isValid()) {
      setSelectedStartDateFromFormat(date);
    }
  };

  const dateStartToHandleChange = (date: Date | null) => {
    const formatDate = date?.toDateString();
    if (moment(formatDate).isValid()) {
      setSelectedStartDateToFormat(date);
    }
  };

  const dateExpirationFromHandleChange = (date: Date | null) => {
    const formatDate = date?.toDateString();
    if (moment(formatDate).isValid()) {
      setSelectedExpirationDateFromFormat(date);
    }
  };

  const dateExpirationToHandleChange = (date: Date | null) => {
    const formatDate = date?.toDateString();
    if (moment(formatDate).isValid()) {
      setSelectedExpirationDateToFormat(date);
    }
  };

  function handleFiltering(couponsStatus: string) {
    if (couponsStatus === 'All') {
      setSelectedStatus('Unknown');
    } else {
      setSelectedStatus(couponsStatus);
    }
  }
  function handleClearFilters() {
    setSelectedStatus('All');
    setSelectedProduct([]);
    setSelectedStartDateFromFormat(null);
    setSelectedStartDateToFormat(null);
    setSelectedExpirationDateFromFormat(null);
    setSelectedExpirationDateToFormat(null);
    setSearchText('');
    setSearchActive(false);
  }

  const handleSearch = () => {
    const data = {...couponsData, search: searchText};
    dispatch(couponsManagement.getCouponsManagementRequest(data));
    setSearchActive(true);
  };

  return (
    <div className="coupons-management">
      <div className="coupons-management__top">
        <div className="coupons-management__bar">
          <h2 className="coupons-management__bar-title">Coupons Management</h2>
          {coupons.couponsManagement && (
            <span className="coupons-management__bar-total">
              {coupons.couponsManagement?.totalCount} total
            </span>
          )}
        </div>
        <Button className="coupons-management__button">
          <NavLink to="/admin/create-coupons">Create Coupon Code</NavLink>
        </Button>
      </div>
      {!coupons && (
        <div className="coupons-management__main">
          <div className="coupons-management__pic">
            <img src={coming} alt="coming" />
          </div>
          <p>You have not created any coupons yet.</p>
        </div>
      )}
      <div className="coupons-management__filters-wr">
        <div className="coupons-management__datepickers">
          <h2 className="coupons-management__datepickers-title">Start Date:</h2>
          <div className="coupons-management__datepickers-input">
            <DatePicker
              label={'From'}
              className="coupons-management__date-input"
              onChangeFunction={dateStartFromHandleChange}
              name="startDate"
              value={selectedStartDateFromFormat}
              maxDate={undefined}
            />
            <span className="coupons-management__datepickers-dash">-</span>
            <DatePicker
              label={'To'}
              className="coupons-management__date-input"
              onChangeFunction={dateStartToHandleChange}
              name="endDate"
              value={selectedStartDateToFormat}
              minDate={
                selectedStartDateFromFormat !== null
                  ? getTomorrow(selectedStartDateFromFormat)
                  : undefined
              }
              maxDate={undefined}
            />
          </div>
        </div>
        <div className="coupons-management__datepickers">
          <h2 className="coupons-management__datepickers-title">Expiration Date:</h2>
          <div className="coupons-management__datepickers-input">
            <DatePicker
              label={'From'}
              className="coupons-management__date-input"
              onChangeFunction={dateExpirationFromHandleChange}
              name="startDate"
              value={selectedExpirationDateFromFormat}
              minDate={
                selectedStartDateToFormat !== null
                  ? getTomorrow(selectedStartDateToFormat)
                  : undefined
              }
              maxDate={undefined}
            />
            <span className="coupons-management__datepickers-dash">-</span>
            <DatePicker
              label={'To'}
              className="coupons-management__date-input"
              onChangeFunction={dateExpirationToHandleChange}
              name="endDate"
              value={selectedExpirationDateToFormat}
              minDate={
                selectedExpirationDateFromFormat !== null
                  ? getTomorrow(selectedExpirationDateFromFormat)
                  : nextDayDate
              }
              maxDate={undefined}
            />
          </div>
        </div>
        <SingleSelectTextfield
          inputLabel={'Status'}
          itemsList={mockCouponsStatus}
          updateFunction={(val: string) => handleFiltering(val)}
          isDefaultValue={true}
          defaultValueName={'All'}
          className="coupons-management__filter-item"
          selectedValue={selectedStatus}
        />
        {productsArray && (
          <CustomAutocomplete
            placeholder="Product"
            className={''}
            items={productsArray}
            selectedItems={selectedProduct}
            setSelectedItems={setSelectedProduct}
            showNumberOfSelected={true}
            defaultItemText="All"
            isAllByDefault={true}
          />
        )}
        <IconButton
          className="refresh-btn"
          aria-label="refresh filters"
          component="span"
          disabled={
            selectedProduct.length === 0 &&
            selectedStatus === 'All' &&
            selectedStartDateFromFormat === null &&
            selectedStartDateToFormat === null &&
            selectedExpirationDateFromFormat === null &&
            selectedExpirationDateToFormat === null &&
            searchText === '' &&
            !isSearchActive
          }
          onClick={handleClearFilters}
        >
          <AutorenewIcon />
        </IconButton>
        <div className="coupons-management__search">
          <Search
            disabled={false}
            searchFunction={handleSearch}
            searchText={searchText}
            setSearchText={setSearchText}
            clearFilter={handleClearFilters}
          />
        </div>
      </div>
      {coupons.couponsManagement ? (
        <CouponsTable
          coupons={coupons.couponsManagement?.items}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={coupons.couponsManagement.totalCount}
          isLoading={coupons.loading}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
        />
      ) : (
        <span className="coupons-management__spinner-wr">
          <LoadingSpinner />
        </span>
      )}
      {coupons.couponsManagement?.totalCount === 0 && (
        <div className="coupons-management__nothing">
          <span>
            <Nothing />
          </span>
          <div className="coupons-management__nothing-text">
            There is no search result based on your search query.
          </div>
          <div className="coupons-management__nothing-text">
            Use another keyword or go back to all questions
          </div>
        </div>
      )}
    </div>
  );
};

export default CouponsManagement;
