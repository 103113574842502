import {Link} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import BackButton from './../../UI/BackButton';
import PlansAndPricingImg from '../../../images/plans-and-pricing.png';
import {useEffect} from 'react';

export const PlansAndPricing = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container className="info-page money-back">
        <Grid item xs={11} md={11} lg={10}>
          <div className="info-page__wr">
            {/*{history.length > 1 && <BackButton match={history} prevPage />}*/}
            <BackButton prevPage />
            <Grid container>
              <Grid item xs={12} md={9} lg={8}>
                <h1 className="info-page__title">Plans and Pricing</h1>

                <div className="info-page__content">
                  <img src={PlansAndPricingImg} alt="help-center_money-back" />
                  <div className="content-item">
                    <p>
                      To use Marquètte, you need to choose a subscription plan, enter your
                      credit/debit card information, and subscribe.
                    </p>

                    <p>
                      Please{' '}
                      <Link className="use-qa__link" to={`/subscription-plan`}>
                        click here
                      </Link>{' '}
                      to subscribe.
                    </p>
                    <p>With the Silver subscription plan you get:</p>
                    <ul>
                      <li>20,000 credits</li>
                      <li>Access to Popular Questions</li>
                      <li>Access to Special Questions</li>
                      <li>Ability to buy unlimited credits</li>
                    </ul>
                    <h2 className="info-page__subtitle info-page__subtitle--left">
                      7-Day Free Trial
                    </h2>
                    <p>
                      You can enjoy using the 7-Day Free Trial for the first seven days after
                      subscribing.
                    </p>
                    <p>
                      During this time, there will be no charges to your credit/debit card, and you
                      will immediately have full access to all Marquètte features.
                    </p>
                    <p>
                      Please note that <b>it is mandatory to enter card details</b> to register your
                      account, regardless of whether you choose to use the Free Trial option.
                    </p>
                    <p>
                      If you only have one card added for payment details, it cannot be deleted.
                    </p>
                    <p>
                      In order to change the card, add details of a new one and then choose which
                      option to delete.
                    </p>
                    <p>
                      If your <b>auto-renewal feature is disabled</b>, your account will become
                      inactive once the plan expires. It means you won’t be able to generate new
                      content or see any of the Popular and Special Questions. However, you will
                      retain access to all content generated previously.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default PlansAndPricing;
