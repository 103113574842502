import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CreditsItem from './CreditsItem';
import {Button} from '@material-ui/core';
import {ICreditsDetails, IState} from '../../../interfaces';
import imgCredit10 from '../../../images/credits10.svg';
import imgCredit25 from '../../../images/credits25.svg';
import imgCredit50 from '../../../images/credits50.svg';
import imgCredit75 from '../../../images/credits75.svg';
import imgCredit100 from '../../../images/credits100.svg';
import {ReactComponent as CreditsImg} from '../../../images/credits-large.svg';
import CreditCountIconSilver from '../../../images/credit-icon-silver.svg';
import {getCreditsDetailsRequest} from '../../../actions/billing';
import './style.scss';
import ModalWithImage from '../../UI/ModalWithImage';

interface IProps {
  isActiveUser: boolean;
  isReader: boolean;
}

export interface ICreditsOpen {
  isOpen: boolean;
  credits: number | null;
}

const AddCredits = (props: IProps): JSX.Element => {
  const {isActiveUser, isReader} = props;
  const hashParts = window.location.hash.split('#');
  const hash = hashParts.slice(-1)[0];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const credits = useSelector(
    (state: IState) =>
      state?.credits?.CreditsAwailable?.items.sort((a, b) => (a.price > b.price ? 1 : -1)) ||
      undefined,
  );
  const defaultCard = useSelector(
    (state: IState) =>
      state?.paymentMethods?.paymentMethods?.items?.filter((item) => item.isDefaultPaymentCard)[0],
  );
  const CreditsBought = useSelector((state: IState) => state?.credits?.CreditsBought);

  const [modalSuccessOpen, setModalSuccessOpen] = useState<ICreditsOpen>({
    isOpen: false,
    credits: null,
  });

  const imgArr = [imgCredit10, imgCredit25, imgCredit50, imgCredit75, imgCredit100];
  const creditsArr: ICreditsDetails[] | undefined = credits?.map((item, i) => {
    item.img = imgArr[i] || imgArr[imgArr.length];
    return item;
  });

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`${hash}`)?.scrollIntoView();
    }, 1000);
  }, [hash]);
  useEffect(() => {
    dispatch(getCreditsDetailsRequest());
  }, []);

  useEffect(() => {
    if (CreditsBought) {
      setModalSuccessOpen({...modalSuccessOpen, isOpen: true});
      dispatch(getCreditsDetailsRequest());
    }
  }, [CreditsBought]);

  return (
    <div className="subscription-credits" id="add-credits">
      <h2 className="subscription-page__title">Add more credits</h2>
      <p className="st__subtitle">Use credits to unlock additional features.</p>
      <Grid container className="grid-wr">
        <Grid container item md={10} spacing={1}>
          {creditsArr &&
            creditsArr?.map((item) => {
              return (
                <Grid key={item.creditsId} item xs={12} sm={6} md={3}>
                  <CreditsItem
                    card={item}
                    isActiveUser={isActiveUser}
                    isReader={isReader}
                    defaultCard={defaultCard}
                    setModalSuccessOpen={setModalSuccessOpen}
                  />
                </Grid>
              );
            })}
          <ModalWithImage
            isOpen={modalSuccessOpen.isOpen}
            topImage={<CreditsImg />}
            buttons={
              <Button
                className="orange-btn orange-btn-hover"
                onClick={() => navigate(`/main/dashboard`)}
              >
                Got it!
              </Button>
            }
            specificClass={'credits-success'}
          >
            <>
              <p className="credits-success__coin-value">
                <img src={CreditCountIconSilver} className="credits-success__coin-img" alt="" />
                {modalSuccessOpen.credits}
              </p>
              <h5 className="credits-success__title">Your coins were added!</h5>
              <p className="credits-success__description">
                You can change your Subscription Plan in{` `}
                <a href="/account/profile">Account details.</a>
              </p>
            </>
          </ModalWithImage>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddCredits;
