import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as billing from '../api/billing';

// REDUX
import {Action} from 'redux-actions';
import * as billingAction from '../actions/billing';
import * as billingActionType from '../actions/types/types';
import {IBillingHistoryAction} from '../interfaces';

export function* billingHistory(
  action: IBillingHistoryAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(billing.getBillingHistory);

    yield put(billingAction.getBillingHistorySuccess(payload));
  } catch (error) {
    yield put(billingAction.getBillingHistoryFailure(error));
  }
}

// WATCHERS
export function* watchBillingHistory(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(billingActionType.GET_BILLING_HISTORY_REQUEST, billingHistory);
}
