import SettingSideBar from './SettingSideBar';
import './style.scss';

const AccountSettings = (): JSX.Element => {
  return (
    <SettingSideBar
      className="account-settings__content"
      adminManagment={false}
      dataPrivacy={true}
    />
  );
};

export default AccountSettings;
