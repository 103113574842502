import {useState, MouseEventHandler, useEffect} from 'react';
import {Grid, Accordion, AccordionSummary, AccordionDetails, Collapse} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GenerationSmallImg from '../../../images/generation_img.svg';
import GenerationBigImg from '../../../images/feature_01_content_generation.png';
import InsightsSmallImg from '../../../images/insights_img.svg';
import InsightsBigImg from '../../../images/market-insights.png';
import AnswerSmallImg from '../../../images/ai_answer_img.svg';
import AnswerBigImg from '../../../images/feature_03_ai_answers.png';
import SupportSmallImg from '../../../images/support-img.svg';
import SupportBigImg from '../../../images/feature_04_supreme_support.png';
import DevicesImg from '../../../images/feature_05.png';

import benefitsImg1 from '../../../images/benefits_01.png';
import benefitsImg2 from '../../../images/benefits_02.png';
import benefitsImg3 from '../../../images/benefits_03.png';
import benefitsImg4 from '../../../images/benefits_04.png';
import benefitsImg5 from '../../../images/benefits_05.png';
import './style.scss';
interface IProps {
  redirectHandle: MouseEventHandler<HTMLButtonElement>;
}

const Features = ({redirectHandle}: IProps): JSX.Element => {
  const [expanded, setExpanded] = useState<string | boolean>('panel1');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : !isExpanded);
    if (window.screen.width < 960 && panel) {
      setTimeout(() => {
        const top = document.getElementById(panel)?.getBoundingClientRect().top || 0;
        window.scrollTo({
          top: top + window.pageYOffset - 90,
          behavior: 'smooth',
        });
      }, 350);
    }
  };
  return (
    <div id="features" className="features-container">
      <div className="features-wr">
        <Grid
          className="len-section whatGet-section"
          container
          item
          style={{
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          xs={12}
          sm={11}
          md={10}
          lg={8}
        >
          <h2 className="len-section-title">What You Get</h2>
          <p className="len-section-subtitle">
            Marquètte is an AI-powered platform capable of processing Big Data to deliver content in
            many forms. See how it works to generate content and answer user questions.
          </p>
          <p className="features-button-wr">
            <button onClick={redirectHandle} className="orange-btn orange-btn-hover">
              Try for Free
              <ArrowForwardIcon />
            </button>
          </p>
          <Grid
            className="len-feature"
            container
            item
            style={{alignItems: 'center', justifyContent: 'space-between'}}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid
              className="len-feature__description"
              item
              style={{alignItems: 'center', justifyContent: 'space-between'}}
              md={5}
            >
              <img src={GenerationSmallImg} alt="" />
              <h3 className="len-subtitle">AI Content Generator</h3>
              <p>
                Never again will your content creation process stop because of writer’s block.
                Marquètte is an AI content generation tool that will provide fresh posts your target
                audience is most interested in. Boost your conversions by offering valuable content!
              </p>
            </Grid>
            <Grid
              className="len-feature__img content"
              item
              style={{alignItems: 'center', justifyContent: 'space-between'}}
              md={5}
            >
              <img src={GenerationBigImg} alt="Content Generation" />
            </Grid>
          </Grid>
          <Grid
            className="len-feature"
            container
            item
            style={{alignItems: 'center', justifyContent: 'space-between'}}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid
              className="len-feature__img"
              item
              style={{alignItems: 'center', justifyContent: 'space-between'}}
              md={5}
            >
              <img src={InsightsBigImg} alt="Market Insights" />
            </Grid>
            <Grid
              className="len-feature__description"
              item
              style={{alignItems: 'center', justifyContent: 'space-between'}}
              md={5}
            >
              <img src={InsightsSmallImg} alt="" />
              <h3 className="len-subtitle">Content templates</h3>
              <p>
                With your Marquètte subscription, you’ll be able to generate content easily using
                our collection of templates. Do you need to create a blog post, basic business
                analysis, SMM post, or an email? Marquètte will do it in moments!
              </p>
            </Grid>
          </Grid>
          <Grid
            className="len-feature"
            container
            item
            style={{alignItems: 'center', justifyContent: 'space-between'}}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid className="len-feature__description" item md={5}>
              <img src={AnswerSmallImg} alt="" />
              <h3 className="len-subtitle">AI-Powered Intelligence</h3>
              <p>
                Marquètte uses AI and Big Data to generate an opinion on a variety of subjects. Use
                immense AI processing power to see a matter from multiple angles fast!
              </p>
            </Grid>
            <Grid
              className="len-feature__img answers"
              item
              style={{alignItems: 'center', justifyContent: 'space-between'}}
              md={5}
            >
              <img src={AnswerBigImg} alt="AI-Generated Answers" />
            </Grid>
          </Grid>
          <Grid
            className="len-feature"
            container
            item
            style={{alignItems: 'center', justifyContent: 'space-between'}}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid
              className="len-feature__img"
              item
              style={{alignItems: 'center', justifyContent: 'space-between'}}
              md={5}
            >
              <img src={SupportBigImg} alt="Supreme Support" />
            </Grid>
            <Grid
              className="len-feature__description"
              item
              style={{alignItems: 'center', justifyContent: 'space-between'}}
              md={5}
            >
              <img src={SupportSmallImg} alt="" />
              <h3 className="len-subtitle">Supreme Support</h3>
              <p>
                The Marquètte AI-powered platform team is always within easy reach. Email and chat
                support are available to all subscribers. And you can get access to a personal
                consultant to help with any questions.
              </p>
            </Grid>
          </Grid>
          <Grid container item style={{justifyContent: 'center', alignItems: 'flex-start'}}>
            <img src={DevicesImg} alt="Mobile devices" className="devices-img" />
            <div className="devices-description">
              <h3 className="len-subtitle">Mobile-Friendly, Fast and Responsive</h3>
              <p className="len-section-subtitle len-section-subtitle--grey">
                Data and insights are with you everywhere thanks to our mobile-first design.
                Marquètte is an AI-powered platform optimized for all devices. Don’t let any
                critical trend changes and industry developments go unnoticed!
              </p>
              <button onClick={redirectHandle} className="orange-btn orange-btn-hover">
                Get a 7-DAY FREE trial now
                <ArrowForwardIcon />
              </button>
            </div>
          </Grid>
          <div className="canDo-section-wr" id="benefits">
            <Grid
              className="canDo-section"
              container
              item
              style={{justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto'}}
              xs={12}
            >
              <h3 className="len-section-title">
                What Marqu<span className="orange-text">è</span>tte
                <span className="orange-text"> can do</span> for you
              </h3>
              <p className="len-section-subtitle">
                Marquètte is an AI-powered platform that will provide you with actionable insights
                that can help transform your business
              </p>
              <Grid
                className="canDo-feature-wr"
                container
                item
                style={{justifyContent: 'space-between'}}
              >
                <Grid container item style={{position: 'relative'}} md={6}>
                  <Collapse className="canDo-img" in={expanded === 'panel1'}>
                    <img src={benefitsImg1} alt="" />
                  </Collapse>
                  <Collapse className="canDo-img" in={expanded === 'panel2'}>
                    <img src={benefitsImg2} alt="" />
                  </Collapse>
                  <Collapse className="canDo-img" in={expanded === 'panel3'}>
                    <img src={benefitsImg3} alt="" />
                  </Collapse>
                  <Collapse className="canDo-img" in={expanded === 'panel4'}>
                    <img src={benefitsImg4} alt="" />
                  </Collapse>
                  <Collapse className="canDo-img" in={expanded === 'panel5'}>
                    <img src={benefitsImg5} alt="" />
                  </Collapse>
                </Grid>
                <Grid container item md={6} style={{flexFlow: 'column'}}>
                  <Accordion
                    id="panel1"
                    className="canDo-feature"
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="canDo-title">
                      <p>AI Content Generation Tool</p>
                    </AccordionSummary>
                    <AccordionDetails
                      className="canDo-description"
                      style={{flexDirection: 'column'}}
                    >
                      <p>
                        Use the Marquètte AI content generator to create posts from templates and
                        ensure your copywriters are supplied with ideas and research materials.
                        Marquètte can be used as an AI content writer for any industry. Boost your
                        marketing strategy and get a significant advantage over your competitors.
                      </p>
                      <img src={benefitsImg1} alt="" />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    id="panel2"
                    className="canDo-feature"
                    expanded={expanded === 'panel2'}
                    onChange={handleChange('panel2')}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="canDo-title">
                      <p>AI-Powered Opinions</p>
                    </AccordionSummary>
                    <AccordionDetails
                      className="canDo-description"
                      style={{flexDirection: 'column'}}
                    >
                      <p>
                        Basic research becomes easy and affordable with Marquètte. Our AI-powered
                        platform gets information from multiple digital sources. It uses AI and Big
                        Data to provide you with opinions about the market, industry trends, and
                        your competitors. And all of it is affordable and fast!
                      </p>
                      <img src={benefitsImg2} alt="" />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    id="panel3"
                    className="canDo-feature"
                    expanded={expanded === 'panel3'}
                    onChange={handleChange('panel3')}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="canDo-title">
                      <p>Research Trends</p>
                    </AccordionSummary>
                    <AccordionDetails
                      className="canDo-description"
                      style={{flexDirection: 'column'}}
                    >
                      <p>
                        Want to know how to change your UI/UX design for max efficiency? Or what the
                        latest trends in your industry are? Marquètte will help you answer all these
                        questions and more! Use it to make sure your business is always a step
                        ahead.
                      </p>
                      <img src={benefitsImg3} alt="" />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    id="panel4"
                    className="canDo-feature"
                    expanded={expanded === 'panel4'}
                    onChange={handleChange('panel4')}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="canDo-title">
                      <p>Minimize Business Risks</p>
                    </AccordionSummary>
                    <AccordionDetails
                      className="canDo-description"
                      style={{flexDirection: 'column'}}
                    >
                      <p>
                        Risks are inherent in business. But it’s within your power to minimize them.
                        The AI-powered platform Marquètte will be your ally in reducing risks by
                        valuable information on many topics.
                      </p>
                      <img src={benefitsImg4} alt="" />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    id="panel5"
                    className="canDo-feature"
                    expanded={expanded === 'panel5'}
                    onChange={handleChange('panel5')}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className="canDo-title">
                      <p>Look for Opportunities</p>
                    </AccordionSummary>
                    <AccordionDetails
                      className="canDo-description"
                      style={{flexDirection: 'column'}}
                    >
                      <p>
                        Marquètte can use AI-powered business intelligence to help you find detailed
                        information about any industry. Use its power to identify new opportunities
                        for your business. Act on the insights to gain an immediate advantage!
                      </p>
                      <img src={benefitsImg5} alt="" />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default Features;
