import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Quill, {DeltaStatic} from 'quill';
import katex from 'katex';
import './quill.scss';
import './style.scss';
import {GrammarlyEditorPlugin} from '@grammarly/editor-sdk-react';
import {QuillDeltaToHtmlConverter} from 'quill-delta-to-html';
import {updateContentWords, prepareContainerForQuill, AIModule, getDelta} from './EditorHelpers';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@material-ui/core';
import {ListItemButton} from '@mui/material';
import {INNFormattingResponse, ISignalRResponse, IState} from '../../../../interfaces';
import {useDispatch, useSelector} from 'react-redux';
import {getTextGenerationResultRequest} from '../../../../actions/textModel';
import {setSignalRCellEditRequest} from '../../../../actions/signalRCellEdit';
import {INNFValueError, NNFormattingError} from '../../NNFormatControl';
import {v4 as uuidv4} from 'uuid';
import {nnFormattingModel} from '../../../../api/textModel';
import SnackbarMessage from '../../../UI/Snackbar';
import {generateTextualRequest, ITemplateBlock} from '../../../../helpers/textModel';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import OptionsPreview from '../../../TemplateManagement/EntryDetails/OptionsPreview';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useWindowDimensions from '../../../../helpers/windowResize';

//@ts-ignore
window.katex = katex;

interface IEditorProps {
  modelId: string;
  inputName?: string;
  idx?: number;
  //onChange?: (value: string, inputName?: string, idx?: number) => void;
  value: string;
  disabled?: boolean;
  hideToolbar?: boolean;
  onUpdateContentWords?: (data: DeltaStatic, words: number) => void;
  onUpdateContentAsHtml?: (content: string) => void;
  setAiEditingLoading?: (loading: boolean) => void;
}

const signalRCellEdit = (state: IState) => state.signalRCellEdit.status as ISignalRResponse;

export const Editor = (props: IEditorProps): JSX.Element => {
  const {
    onUpdateContentAsHtml,
    //onChange,
    onUpdateContentWords,
    modelId,
    disabled,
    value,
    hideToolbar,
    setAiEditingLoading,
  } = props;

  const cellEditData = useSelector(signalRCellEdit);
  const operationId = useMemo(() => uuidv4(), []);
  const [aIEditorResponse, setAIEditorResponse] = useState<{
    data?: INNFormattingResponse;
    error?: INNFValueError;
  }>({});
  const [selection, setSelection] = useState<{start: number; end: number}>({start: 0, end: 0});
  const [cancelAIEditorRequest, setCancelAIEditorRequest] = useState<boolean>(false);
  const [valueErrors, setValueErrors] = useState<INNFValueError[]>([]);
  const [aIEditorData, setAIEditorData] = useState<INNFormattingResponse | null>(null);
  const dispatch = useDispatch();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [optionModalOpen, setOptionModalOpen] = useState<boolean>(false);
  const [dropdownDisplay, setDropdownDisplay] = useState<boolean>(false);
  const [blocked, setBlocked] = useState<boolean>(false);
  const [isOpenSnack, setOpenSnack] = useState<boolean>(false);
  const [commandPopUpOpen, setCommandPopUpOpen] = useState<boolean>(false);
  const [selectedCommand, setSelectedCommand] = useState<{
    name: string;
    data: ITemplateBlock[];
    insertion: 'InsertBefore' | 'InsertAfter' | 'Replace';
  }>({
    name: '',
    data: [],
    insertion: 'Replace',
  });
  const commandList = useSelector(
    (state: IState) => state.commandsManagement.commandsListResponse?.items,
  );
  const editor = useRef<Quill>();
  const screenWidth = useWindowDimensions().width;

  useEffect(() => {
    const aiButton = document.querySelector('.ql-ai');
    if (aiButton) {
      aiButton.innerHTML = 'AI Assistant';
    }
  });

  useEffect(() => {
    if (cancelAIEditorRequest) {
      setCancelAIEditorRequest(false);
      setAIEditorData(null);
      return;
    }

    if (aIEditorResponse.error) {
      setValueErrors([aIEditorResponse.error]);
      setAIEditorData(null);
      return;
    }

    if (aIEditorResponse.data) {
      setValueErrors([]);
      setAIEditorData(aIEditorResponse.data);
      return;
    }
  }, [aIEditorResponse]);

  useEffect(() => {
    if (cellEditData?.operationId !== operationId || cellEditData?.operationId === '') {
      return;
    }

    if (cellEditData) {
      if (cellEditData.error) {
        setAIEditorResponse({
          error: {
            code: NNFormattingError.EmptyText,
            text: cellEditData.error,
            type: 'warning',
          },
        });
      } else {
        setAIEditorResponse({
          data: {
            wordCount: cellEditData.wordCount,
            userCredits: cellEditData.userCredits,
            text: cellEditData.text,
          },
        });

        dispatch(
          getTextGenerationResultRequest({
            textModelId: modelId,
            pageSize: 999,
          }),
        );
      }
    } else {
      setAIEditorResponse({
        error: {
          code: NNFormattingError.EmptyText,
          text: `We're sorry, AI couldn't complete this task. Please try again.`,
          type: 'warning',
        },
      });
    }

    dispatch(
      setSignalRCellEditRequest({
        operationId: '',
        text: '',
        wordCount: 0,
      }),
    );
  }, [cellEditData?.operationId]);

  const setData = useCallback((editor: Quill, value: string) => {
    const data: DeltaStatic = getDelta(value);
    editor.setContents(data);
    updateContentData(editor);
  }, []);

  useEffect(() => {
    if (aIEditorData?.text) {
      if (!editor.current) {
        return;
      }
      const {start, end} = selection;
      start !== end && editor.current.deleteText(start, end, 'user');
      const generateText = aIEditorData?.text;
      const delta = getDelta(generateText);
      const previousBlock = editor.current.getContents(0, start);
      if (previousBlock?.ops) {
        // add extra line if previous block has size attribute
        const ops = previousBlock.ops;
        if (ops.length > 0 && ops[ops.length - 1]?.attributes?.size) {
          previousBlock?.ops?.push({insert: '\n'});
        }
      }
      if (delta && delta.ops && delta.ops.length > 0) {
        // remove extra line if last op is empty
        const lastOp = delta.ops[delta.ops.length - 1];
        if (lastOp.insert === '\n' && !lastOp.attributes) {
          delta.ops.pop();
        }
        previousBlock?.ops?.push(...delta.ops);
      }
      editor.current?.setSelection(0, 0, 'user');
      editor.current.deleteText(0, start, 'user');
      editor.current.updateContents(previousBlock, 'user');
      setBlocked(false);
      setAiEditingLoading && setAiEditingLoading(false);
    }
  }, [aIEditorData]);

  const aiEditingCommand = useCallback(
    (
      inputValue: string,
      selectedCommand: string,
      start: number,
      end: number,
      resultInsertion: 'InsertBefore' | 'InsertAfter' | 'Replace',
    ) => {
      if (resultInsertion === 'InsertBefore') {
        setSelection({
          start: start - 1,
          end: start - 1,
        });
      } else if (resultInsertion === 'InsertAfter') {
        setSelection({
          start: start + end + 1,
          end: start + end + 1,
        });
      } else {
        setSelection({
          start: start,
          end: end,
        });
      }
      nnFormattingModel({
        text: inputValue,
        command: selectedCommand,
        context: null,
        textModelId: modelId,
        modelName: '',
        templateName: '',
        inputControlAlias: '',
        operationId: operationId,
      })
        .then((res) => {
          return;
        })
        .catch((err) => {
          console.error('nnFormattingRequest failed', err);
          setAIEditorResponse({
            error: {
              code: NNFormattingError.UnknownError,
              text: `We're sorry, AI couldn't complete this task. Please try again.`,
              type: 'warning',
            },
          });
        });
    },
    [modelId, operationId],
  );

  const updateContentData = useCallback(
    (editor: Quill) => {
      const data = editor?.getContents();
      const deltaOps = data.ops ? data.ops : [];
      const converter = new QuillDeltaToHtmlConverter(deltaOps, {});
      const html = converter.convert();

      // update HTML data and count of words
      onUpdateContentAsHtml && onUpdateContentAsHtml(html);
      onUpdateContentWords && editor && updateContentWords(editor, data, onUpdateContentWords);
    },
    [onUpdateContentAsHtml, onUpdateContentWords, onUpdateContentWords],
  );

  useEffect(() => {
    if (container) {
      const subContainer = prepareContainerForQuill(container);

      editor.current = new Quill(subContainer, {
        readOnly: disabled,
        modules: {
          toolbar: hideToolbar
            ? []
            : [
                ['ai'],
                [{size: ['small', false, 'large', 'huge']}],
                ['bold', 'italic', 'underline', 'strike'],
                [{list: 'ordered'}, {list: 'bullet'}],
                [{indent: '-1'}, {indent: '+1'}],
                [{align: []}],
                ['link', 'formula', 'code-block'],
              ],
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true,
          },
          aiModule: true,
        },
        theme: 'snow',
      });

      // Handlers can also be added post initialization
      editor.current.on('text-change', () => {
        editor.current && !disabled && updateContentData(editor.current);
      });

      setData(editor.current, value);

      const aiModule = editor.current.getModule('aiModule') as AIModule;
      const aiButton = document.querySelector('.ql-ai');
      aiModule.onShowDropdown(() => {
        if (aiButton) {
          aiButton.classList.add('active');
        }
        if (screenWidth < 768) {
          window.getSelection()?.removeAllRanges();
        }
        setDropdownDisplay(true);
      });
    }
  }, [container, setDropdownDisplay, updateContentData, disabled]);

  useEffect(() => {
    editor.current && setData(editor.current, value);
  }, [value, editor.current, updateContentData]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const aiButton = document.querySelector('.ql-ai');
      if (!dropdownDisplay || target.classList.contains('ql-ai')) {
        return;
      }
      aiButton && aiButton.classList.remove('active');
      setDropdownDisplay(false);
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownDisplay]);

  function returnSelection() {
    if (editor.current) {
      editor.current.focus();

      return editor.current.getSelection();
    }
  }

  const handleOptionSelect = useCallback(
    (
      option: ITemplateBlock[],
      isSilent: boolean,
      name: string,
      insertion: 'InsertBefore' | 'InsertAfter' | 'Replace',
    ) => {
      if (!editor.current) {
        console.log('!editor.current');
        return;
      }
      setSelectedCommand({name: name, data: option, insertion: insertion});

      const selection = returnSelection();

      if (selection) {
        if (selection.length == 0) {
          setOpenSnack(true);
        } else if (!isSilent) {
          setCommandPopUpOpen(true);
        } else {
          applyCommandEdit(option, insertion);
          // temporary action: reverse text
          // setBlocked(true);
          // const optionForEdit = generateTextualRequest(option);
          // const text = editor.current.getText(selection.index, selection.length);
          // aiEditingCommand(text, optionForEdit, selection.index, selection.length, insertion);
        }
      }
    },
    [],
  );

  const CancelAIEditing = useCallback(() => {
    setCancelAIEditorRequest(true);
    setBlocked(false);
    setAiEditingLoading && setAiEditingLoading(false);
  }, []);

  const applyCommandEdit = useCallback(
    (option: ITemplateBlock[], insertion: 'InsertBefore' | 'InsertAfter' | 'Replace') => {
      if (!editor.current) {
        return;
      }
      const selection = returnSelection();
      setBlocked(true);
      setAiEditingLoading && setAiEditingLoading(true);
      const optionForEdit = generateTextualRequest(option);
      if (selection) {
        const text = editor.current.getText(selection.index, selection.length);
        aiEditingCommand(text, optionForEdit, selection.index, selection.length, insertion);
        setCommandPopUpOpen(false);
      }
    },
    [],
  );
  const setOptions = (arr: ITemplateBlock[]) => {
    setSelectedCommand({...selectedCommand, data: arr});
  };

  return (
    <>
      {valueErrors.length > 0 && (
        <div className={'editor-errors'}>
          {valueErrors.map((error, idx) => (
            <div key={idx}>{error}</div>
          ))}
        </div>
      )}
      <div
        className={`rich-text-editor ${hideToolbar ? 'disabled' : ''} ${blocked ? 'blocked' : ''}`}
      >
        <GrammarlyEditorPlugin>
          <div>
            <div key={'quill'} ref={setContainer}></div>
          </div>
        </GrammarlyEditorPlugin>

        <div id="aiDropdown" style={{display: !dropdownDisplay ? 'none' : 'block'}}>
          <List>
            {commandList &&
              commandList.map((btn, idx) => (
                <ListItemButton
                  key={idx + btn.name}
                  onClick={() =>
                    handleOptionSelect(btn.data, btn.isSilent, btn.name, btn.resultInsertion)
                  }
                >
                  <ListItemText primary={btn.name} />
                  {!btn.isSilent && (
                    <ListItemIcon>
                      <EditOutlinedIcon />
                    </ListItemIcon>
                  )}
                </ListItemButton>
              ))}
            {/*Button for future version*/}
            {/*<ListItemButton onClick={onOptionAdd}>Add</ListItemButton>*/}
          </List>
        </div>
        <div id="ai-blocker" style={{display: !blocked ? 'none' : 'flex'}}>
          <Button
            onClick={() => CancelAIEditing()}
            // startIcon={}
            className="orange-btn orange-btn-hover"
          >
            <div className="loader-ai-icon"></div>
            Cancel AI editing
          </Button>
        </div>
      </div>
      <Dialog open={optionModalOpen} onClose={() => setOptionModalOpen(false)}>
        <DialogTitle>Add custom option</DialogTitle>
        <DialogContent>
          <DialogContentText>You can customize your option here</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Command name"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="data"
            label="Command data"
            fullWidth
            variant="standard"
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOptionModalOpen(false)}>Cancel</Button>
          <Button onClick={() => setOptionModalOpen(false)}>Create</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={commandPopUpOpen}
        onClose={() => setCommandPopUpOpen(false)}
        className="command-edit-modal"
      >
        <DialogTitle>
          <span className="title-icon-wr">
            <AutoFixHighIcon />
          </span>
          {selectedCommand.name}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <OptionsPreview options={selectedCommand.data} setDataOutside={setOptions} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => applyCommandEdit(selectedCommand.data, selectedCommand.insertion)}
            className="orange-btn orange-btn-hover"
          >
            Yes, confirm
          </Button>
          <Button onClick={() => setCommandPopUpOpen(false)} className="white-btn white-btn-hover">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarMessage
        open={isOpenSnack}
        message={'Please select the text you want to change'}
        severity={'warning'}
        setOpen={setOpenSnack}
      />
    </>
  );
};
