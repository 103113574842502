import React from 'react';
import './style.scss';
import HeroBannerDesktop from '../../../images/hero-video-baner-desktop.png';
import HeroBannerLaptop from '../../../images/hero-video-baner-laptop.png';
import HeroBannerTablet from '../../../images/hero-video-baner-tablet.png';
import HeroBannerMobile from '../../../images/hero-video-baner-mobile.png';
import HeroBannerTabletVertical from '../../../images/hero-video-baner-tablet-vertical.png';

export const Banner = (): JSX.Element => {
  return (
    <div className="dashboard__video">
      <picture className="hero-video-banner">
        <source media="(min-width:1281px)" srcSet={HeroBannerDesktop} />
        <source media="(min-width:1025px)" srcSet={HeroBannerLaptop} />
        <source media="(min-width: 600px)" srcSet={HeroBannerTablet} />
        <source
          media="(min-width: 600px) and (orientation: portrait)"
          srcSet={HeroBannerTabletVertical}
        />
        <source media="(min-width: 320px)" srcSet={HeroBannerMobile} />
        <img src={HeroBannerMobile} alt="" />
      </picture>
      <div className="banner-wr__text">
        <p className="banner-wr__text-title">
          Marqu<span>è</span>tte
        </p>
        <p>Content writing on steroids</p>
      </div>
    </div>
  );
};

export default Banner;
