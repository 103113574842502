// USER_SETTINGS - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as userSettingsActionTypes from './types/types';

export const getUserSettingsRequest = createAction(
  userSettingsActionTypes.GET_USER_SETTINGS_REQUEST,
);
export const getUserSettingsSuccess = createAction(
  userSettingsActionTypes.GET_USER_SETTINGS_SUCCESS,
);
export const getUserSettingsFailure = createAction(
  userSettingsActionTypes.GET_USER_SETTINGS_FAILURE,
);

export const saveUserSettingsRequest = createAction(
  userSettingsActionTypes.PUT_USER_SETTINGS_REQUEST,
);
export const saveUserSettingsSuccess = createAction(
  userSettingsActionTypes.PUT_USER_SETTINGS_SUCCESS,
);
export const saveUserSettingsFailure = createAction(
  userSettingsActionTypes.PUT_USER_SETTINGS_FAILURE,
);

export const patchUserSettingsRequest = createAction(
  userSettingsActionTypes.PATCH_USER_SETTINGS_REQUEST,
);
export const patchUserSettingsSuccess = createAction(
  userSettingsActionTypes.PATCH_USER_SETTINGS_SUCCESS,
);
export const patchUserSettingsFailure = createAction(
  userSettingsActionTypes.PATCH_USER_SETTINGS_FAILURE,
);
