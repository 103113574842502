import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  GET_CREDIT_ITEMS_REQUEST,
  GET_CREDIT_ITEMS_SUCCESS,
  GET_CREDIT_ITEMS_FAILURE,
} from '../actions/types/types';
import {ICreditItemAction, ICreditItemsState} from '../interfaces/CreditItems';

export const initialState: ICreditItemsState = {
  creditItems: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_CREDIT_ITEMS_REQUEST]: (state: ICreditItemsState) => ({
    ...state,
    loading: true,
  }),

  [GET_CREDIT_ITEMS_SUCCESS]: (state: ICreditItemsState, action: ICreditItemAction) => ({
    ...state,
    creditItems: action.payload,
    loading: false,
    error: '',
  }),

  [GET_CREDIT_ITEMS_FAILURE]: (state: ICreditItemsState, action: ICreditItemAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
