import React, {useCallback, useEffect, useState, useRef, MutableRefObject} from 'react';
import MyContentDetailsTitle from './Title/MyContentDetailsTitle';
import OptionsPreview from '../../TemplateManagement/EntryDetails/OptionsPreview';
import {Button, FormControl, IconButton, InputLabel, MenuItem, Select} from '@material-ui/core';
import {IShortenEntry} from './index';
import {useDispatch, useSelector} from 'react-redux';
import {IGenerateTextModel, IState} from '../../../interfaces';
import {generateTextualRequest, ITemplateBlock} from '../../../helpers/textModel';
import {generateTextModelRequest, postCancelModelRequest} from '../../../actions/textModel';
import useWindowDimensions from '../../../helpers/windowResize';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {QUALITY_TYPE_DATA, CREATIVITY_LEVEL_DATA} from '../../../constants';
import {SubscriptionStatus} from '../../../enums/SubscriptionStatuses';
import {ReactComponent as HintIcon} from '../../../images/hint-icon.svg';
import {useLocation} from 'react-router-dom';
import cn from 'classnames';

interface IProps {
  entryItem: IShortenEntry;
  urlModeId: string | undefined;
  generationStatus: string;
  setGenerationStatus: (status: string) => void;
  content: string;
  title: string;
  setContent: (s: string) => void;
  contentAsHtml?: string;
  isEditorMode?: boolean;
  setEntryPreviewOpen?: (open: boolean) => void;
  setHintButtonEnabled: (enabled: boolean) => void;
  hintButtonEnabled: boolean;
  status?: boolean;
  aiEditingLoading?: boolean;
  onTriggerHint?: () => void;
}

const PreviewEditor = (props: IProps): JSX.Element => {
  const {
    entryItem,
    urlModeId,
    generationStatus,
    setGenerationStatus,
    title,
    contentAsHtml,
    content,
    setContent,
    isEditorMode = false,
    setEntryPreviewOpen,
    hintButtonEnabled,
    setHintButtonEnabled,
    status = false,
    aiEditingLoading,
    onTriggerHint,
  } = props;
  const dispatch = useDispatch();

  const textModelDetails = useSelector((state: IState) => state.textModel.textModelDetails);
  const textModelEntry = useSelector((state: IState) => state.textModel.singleTextModelEntry);
  const userProfile = useSelector((state: IState) => state.userProfile.userProfile);

  const [optionsObject, setOptionsObject] = useState<ITemplateBlock[]>([]);
  const [qualityType, setQualityType] = useState<string>('');
  const [creativityLevel, setCreativityLevel] = useState<number>(0);

  const screenWidth = useWindowDimensions().width;
  const location = useLocation();
  const timeoutRef: MutableRefObject<null | NodeJS.Timeout> = useRef(null);

  useEffect(() => {
    if (entryItem.qualityType) {
      setQualityType(entryItem.qualityType);
    }
    if (entryItem.creativityIndicator) {
      setCreativityLevel(entryItem.creativityIndicator);
    }
  }, [entryItem]);

  const pendingStatus = generationStatus === 'Pending';

  const onGenerate = useCallback(() => {
    setGenerationStatus('Generating');
    setContent('');
    const request = generateTextualRequest(optionsObject);
    const data: IGenerateTextModel = {
      name: entryItem.name,
      entryName: entryItem.name,
      entryImg: entryItem.entryImg as string,
      contentType: entryItem.contentType,
      textualTemplate: optionsObject || entryItem.data,
      textualRequest: request || '',
      categoryId: textModelEntry ? textModelEntry.categoryId : entryItem.categoryId,
      qualityType: qualityType,
      creativityIndicator: creativityLevel,
    };
    if (generationStatus === 'Generating') {
      dispatch(postCancelModelRequest({textModelId: urlModeId}));
    } else {
      urlModeId
        ? dispatch(generateTextModelRequest({id: urlModeId, ...data}))
        : dispatch(generateTextModelRequest(data));
    }
  }, [optionsObject, generationStatus, qualityType, creativityLevel, urlModeId, entryItem]);

  function buttonMode() {
    const enableHint = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setHintButtonEnabled(true);
      }, 5000);
    };

    if (content && pendingStatus) {
      enableHint();
      return 'Regenerate';
    } else if (pendingStatus) {
      enableHint();
      return 'Generate';
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      setHintButtonEnabled(false);
      return 'Stop Generation';
    }
  }

  const hintClassNames = cn({
    'button-hint': true,
    'button-hint-disabled': !hintButtonEnabled,
  });

  return (
    <>
      <div>
        <div className="preview-editor__title-wr">
          <MyContentDetailsTitle
            id={entryItem.id ? entryItem.id : (urlModeId as string)}
            title={title}
            description={entryItem.description}
            isActionBtnDisplayed={false}
            isPreviewStep={false}
            // isActionBtnDisplayed={textModelDetails?.status.name === 'Done'}
            // isPreviewStep={textModelDetails?.status.name === 'Pending'}
            image={entryItem.entryImg}
            contentAsHtml={contentAsHtml}
          />
          {screenWidth < 1180 && isEditorMode && (
            <div className="close-icon-wr">
              <CloseIcon onClick={() => setEntryPreviewOpen && setEntryPreviewOpen(false)} />
            </div>
          )}
        </div>
        <OptionsPreview
          options={entryItem.data}
          setDataOutside={setOptionsObject}
          status={status}
          generationStatus={generationStatus}
        />
        <div className="text-model__settings">
          <div className="entry-create__quality text-model__settings-item">
            <p className="entry-create__input-title">Quality</p>
            <FormControl className="entry-create__input" variant="outlined">
              <InputLabel id="quality-select">Choose Quality level</InputLabel>
              <Select
                id="quality-select"
                IconComponent={ExpandMoreIcon}
                className="entry-create__category-select"
                value={qualityType}
                onChange={(e: React.ChangeEvent<{value: unknown}>) =>
                  setQualityType(e.target.value as string)
                }
                required
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                disabled={textModelDetails?.status.name === 'Generating'}
              >
                {QUALITY_TYPE_DATA.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    className="entry-create__category-items"
                  >
                    <span className="entry-create__category-item">{item.name}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="entry-create__creativity text-model__settings-item">
            <p className="entry-create__input-title">Creativity</p>
            <FormControl className="entry-create__input" variant="outlined">
              <InputLabel id="category-select">Choose Creativity level</InputLabel>
              <Select
                id="category-select"
                IconComponent={ExpandMoreIcon}
                className="entry-create__category-select"
                value={creativityLevel}
                onChange={(e: React.ChangeEvent<{value: unknown}>) =>
                  setCreativityLevel(e.target.value as number)
                }
                required
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                disabled={textModelDetails?.status.name === 'Generating'}
              >
                {CREATIVITY_LEVEL_DATA.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    className="entry-create__category-items"
                  >
                    <span className="entry-create__category-item">{item.name}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="text-model__button-wr">
        {textModelDetails?.status.name !== 'Done' && (
          <Button
            className={isEditorMode ? 'white-btn white-btn-hover' : 'orange-btn orange-btn-hover'}
            onClick={onGenerate}
            disabled={
              aiEditingLoading ||
              userProfile.userSubscriptionStatus === SubscriptionStatus.PaidExpired
            }
          >
            {buttonMode()}
          </Button>
        )}
        {location.pathname.includes('text-model-details') && screenWidth > 1024 && (
          <IconButton onClick={onTriggerHint} className={hintClassNames}>
            <HintIcon />
          </IconButton>
        )}
      </div>
    </>
  );
};

export default PreviewEditor;
