import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './../style.scss';
import {useActiveUser} from '../../../helpers/customHooks';
import {useNavigate} from 'react-router-dom';
import {
  getTextModelCategoriesRequest,
  getTextModelEntriesRequest,
} from '../../../actions/textModel';
import {IState, ITextModelEntry, IUserProfile} from '../../../interfaces';
import ChooseTemplate from './ChooseTemplate';
import LoadingSpinner from '../../UI/LoadingSpinner';
import UiAlert from '../../UI/UiAlert';
import BreadcrumbsComponent from '../../UI/Breadcrumbs';

interface IDataToDisplay {
  id: string;
  externalId: string;
  name: string;
  description: string;
  imageUrl: string;
  backgroundColor: string;
  entries: ITextModelEntry[];
}

const TextModelCreateComponent = (): JSX.Element => {
  const userStatus = useActiveUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [dataToDisplay, setDataToDisplay] = useState<IDataToDisplay[]>([]);

  const user: IUserProfile = useSelector((state: IState) => state.userProfile.userProfile);
  const createResponse = useSelector((state: IState) => state.textModel.textModelCreate);
  const textModelCategories = useSelector((state: IState) => state.textModel.textModelCategories);
  const textModelEntries = useSelector((state: IState) => state.textModel.textModelEntries);

  useEffect(() => {
    dispatch(getTextModelEntriesRequest({pageSize: 1000}));
    dispatch(getTextModelCategoriesRequest());
    localStorage.removeItem('properties');
  }, []);

  useEffect(() => {
    if (dataToDisplay.length) {
      setSelectedCategory(dataToDisplay[0].id);
    }
  }, [dataToDisplay]);

  useEffect(() => {
    if (
      textModelCategories &&
      textModelCategories.items.length &&
      textModelEntries &&
      textModelEntries.items.length
    ) {
      const modifiedArr = textModelCategories.items
        .map((item) => {
          const entries = textModelEntries.items.filter((entry) => entry.categoryId === item.id);
          return {
            ...item,
            entries: entries,
          };
        })
        .filter((obj) => obj.entries.length > 0);
      setDataToDisplay(modifiedArr);
    }
  }, [textModelCategories?.items, textModelEntries?.items]);

  useEffect(() => {
    if (createResponse && createResponse.id) {
      navigate(`/text-model/${createResponse.id}`);
    }
  }, [createResponse]);

  return (
    <>
      <div className="text-model">
        <div className="text-model__container" style={{margin: 'auto'}}>
          <BreadcrumbsComponent />
        </div>
        <div className="text-model__container">
          <div className="component-white-wr">
            {dataToDisplay.length ? (
              <>
                {user.creditCount === 0 && !userStatus.isExpired && (
                  <UiAlert
                    type={'warning'}
                    title={'You have used all your credits!'}
                    details={'To continue asking questions buy additional credits.'}
                    withButton={true}
                    buttonText={'Buy credits'}
                    buttonAction={() => navigate('/subscription-plan')}
                  />
                )}
                {dataToDisplay && dataToDisplay.length && (
                  <ChooseTemplate
                    templates={dataToDisplay}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                  />
                )}
              </>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TextModelCreateComponent;
