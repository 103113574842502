import React, {ReactNode, useState} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {LightTooltip} from '../../../../UI/StatusLabel';
import '../style.scss';
import {IPayloadAction} from '../../../../../interfaces';

interface IEmailNotificationProps {
  children?: ReactNode;
  emailNotification?: IPayloadAction;
}

const EmailNotification = (props: IEmailNotificationProps): JSX.Element => {
  const {children} = props;
  const [value, setValue] = useState('off');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <div>
      <div className="SettingContent">
        <h2 className="SettingContent__title SettingSideBar__common-tit">Email Notifications</h2>
        <h3 className="SettingContent__sub-title SettingSideBar__common-tit">
          Notification Summary
        </h3>
        <FormControl
          component="fieldset"
          className="SettingContent__radios SettingSideBar__common-bottom-line"
        >
          <FormLabel component="legend">
            Receive all notifications in one email instead of getting a single email for each new
            notification.
          </FormLabel>
          <LightTooltip title="coming soon" placement="bottom-start">
            <RadioGroup value={value} onChange={handleChange} className="SettingContent__radios-wr">
              <FormControlLabel disabled value="on" control={<Radio />} label="On" />
              <FormControlLabel disabled value="off" control={<Radio />} label="Off" />
            </RadioGroup>
          </LightTooltip>
        </FormControl>
        <div className="SettingContent__actionable-wr">
          <h3 className="SettingContent__actionable-tit">
            Email Notifications{' '}
            <span className="SettingContent__actionable-tit--italic">
              Email me immediately when:
            </span>
          </h3>
        </div>
        {children}
      </div>
    </div>
  );
};

export default EmailNotification;
