import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as userManagementProfile from '../api/userManagementProfile';

// REDUX
import * as userManagementProfileAction from '../actions/userManagementProfile';
import * as userManagementProfileActionType from '../actions/types/types';

import {IUserManagementProfileAction} from '../interfaces';
import {Action} from 'redux-actions';

export function* getUserManagementProfile(
  action: IUserManagementProfileAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(userManagementProfile.getUserManagementProfile, action.payload);
    yield put(userManagementProfileAction.getUserManagementProfileSuccess(payload));
  } catch (error) {
    yield put(userManagementProfileAction.getUserManagementProfileFailure(error));
  }
}

// WATCHERS
export function* watchUserManagementProfile(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    userManagementProfileActionType.GET_SINGLE_USER_REQUEST,
    getUserManagementProfile,
  );
}
