import {handleActions} from 'redux-actions';

// ACTION TYPES
import {
  POST_INITIALIZE_PAYMENT_CARD_REQUEST,
  POST_INITIALIZE_PAYMENT_CARD_SUCCESS,
  POST_INITIALIZE_PAYMENT_CARD_FAILURE,
  POST_INITIALIZE_DEFAULT_PAYMENT_CARD_REQUEST,
  POST_INITIALIZE_DEFAULT_PAYMENT_CARD_SUCCESS,
  POST_INITIALIZE_DEFAULT_PAYMENT_CARD_FAILURE,
  DELETE_PAYMENT_CARD_REQUEST,
  DELETE_PAYMENT_CARD_SUCCESS,
  DELETE_PAYMENT_CARD_FAILURE,
} from '../actions/types/types';
import {IInitializeCardAction, IInitializeCardState} from '../interfaces';

export const initialState: IInitializeCardState = {
  initializeCardResponse: null,
  defaultCardResponse: null,
  removedCard: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [POST_INITIALIZE_PAYMENT_CARD_REQUEST]: (state: IInitializeCardState) => ({
    ...state,
    loading: true,
  }),

  [POST_INITIALIZE_PAYMENT_CARD_SUCCESS]: (
    state: IInitializeCardState,
    action: IInitializeCardAction,
  ) => ({
    ...state,
    initializeCardResponse: action.payload,
    loading: false,
    error: '',
  }),

  [POST_INITIALIZE_PAYMENT_CARD_FAILURE]: (
    state: IInitializeCardState,
    action: IInitializeCardAction,
  ) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [POST_INITIALIZE_DEFAULT_PAYMENT_CARD_REQUEST]: (state: IInitializeCardState) => ({
    ...state,
    loading: true,
  }),

  [POST_INITIALIZE_DEFAULT_PAYMENT_CARD_SUCCESS]: (
    state: IInitializeCardState,
    action: IInitializeCardAction,
  ) => ({
    ...state,
    defaultCardResponse: action.payload,
    loading: false,
    error: '',
  }),

  [POST_INITIALIZE_DEFAULT_PAYMENT_CARD_FAILURE]: (
    state: IInitializeCardState,
    action: IInitializeCardAction,
  ) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [DELETE_PAYMENT_CARD_REQUEST]: (state: IInitializeCardState) => ({
    ...state,
    loading: true,
  }),

  [DELETE_PAYMENT_CARD_SUCCESS]: (state: IInitializeCardState, action: IInitializeCardAction) => ({
    ...state,
    removedCard: action.payload,
    loading: false,
    error: '',
  }),

  [DELETE_PAYMENT_CARD_FAILURE]: (state: IInitializeCardState, action: IInitializeCardAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
