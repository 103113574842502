// USER MANAGEMENT - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as companiesActionType from './types/types';

export const getCompaniesStatusRequest = createAction(companiesActionType.GET_COMPANIES_REQUEST);
export const getCompaniesStatusSuccess = createAction(companiesActionType.GET_COMPANIES_SUCCESS);
export const getCompaniesStatusFailure = createAction(companiesActionType.GET_COMPANIES_FAILURE);
