import React, {useRef, MouseEventHandler, useState, useEffect} from 'react';
import BackButton from './../../components/UI/BackButton';
import Grid from '@material-ui/core/Grid';
import './style.scss';

const PrivacyPolicy = (): JSX.Element => {
  const [active, setActive] = useState('personal');

  const personalRef = useRef<HTMLDivElement | null>(null);
  const nonPersonalRef = useRef<HTMLDivElement | null>(null);
  const informationUseRef = useRef<HTMLDivElement | null>(null);
  const informationShareRef = useRef<HTMLDivElement | null>(null);
  const linksRef = useRef<HTMLDivElement | null>(null);
  const cookiesUseRef = useRef<HTMLDivElement | null>(null);
  const changesRef = useRef<HTMLDivElement | null>(null);
  const acceptanceRef = useRef<HTMLDivElement | null>(null);

  const [personal, setPersonal] = useState<HTMLDivElement | null>(null);
  const [nonPersonal, setNonPersonal] = useState<HTMLDivElement | null>(null);
  const [informationUse, setInformationUse] = useState<HTMLDivElement | null>(null);
  const [informationShare, setInformationShare] = useState<HTMLDivElement | null>(null);
  const [links, setLinks] = useState<HTMLDivElement | null>(null);
  const [cookiesUse, setCookiesUse] = useState<HTMLDivElement | null>(null);
  const [changes, setChanges] = useState<HTMLDivElement | null>(null);
  const [acceptance, setAcceptance] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setPersonal(personalRef.current);
    setNonPersonal(nonPersonalRef.current);
    setInformationUse(informationUseRef.current);
    setInformationShare(informationShareRef.current);
    setLinks(linksRef.current);
    setCookiesUse(cookiesUseRef.current);
    setChanges(changesRef.current);
    setAcceptance(acceptanceRef.current);
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const topOffSet = window.pageYOffset;
      const scrolledHeight = document.body.scrollHeight - document.body.clientHeight;
      if (
        personal &&
        nonPersonal &&
        informationUse &&
        informationShare &&
        links &&
        cookiesUse &&
        changes &&
        acceptance
      ) {
        if (topOffSet < personal?.offsetTop - 20) {
          setActive('personal');
        } else if (topOffSet > scrolledHeight) {
          setActive('acceptance');
        } else if (
          topOffSet <= informationUse?.offsetTop - 20 &&
          topOffSet >= nonPersonal?.offsetTop - 20
        ) {
          setActive('nonPersonal');
        } else if (
          topOffSet <= informationShare?.offsetTop - 20 &&
          topOffSet >= informationUse?.offsetTop - 20
        ) {
          setActive('informationUse');
        } else if (
          topOffSet <= links?.offsetTop - 20 &&
          topOffSet >= informationShare?.offsetTop - 20
        ) {
          setActive('informationShare');
        } else if (topOffSet <= cookiesUse?.offsetTop - 20 && topOffSet >= links?.offsetTop - 20) {
          setActive('links');
        } else if (
          topOffSet <= changes?.offsetTop - 20 &&
          topOffSet >= cookiesUse?.offsetTop - 20
        ) {
          setActive('cookiesUse');
        } else if (
          topOffSet <= acceptance?.offsetTop - 20 &&
          topOffSet >= changes?.offsetTop - 20
        ) {
          setActive('changes');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [acceptance]);

  const handleChange = (newValue: string): MouseEventHandler<HTMLLIElement> | undefined => {
    if (
      personal &&
      nonPersonal &&
      informationUse &&
      informationShare &&
      links &&
      cookiesUse &&
      changes &&
      acceptance
    ) {
      switch (newValue) {
        case 'personal':
          window.scrollTo(0, personal.offsetTop - 50);
          break;
        case 'nonPersonal':
          window.scrollTo(0, nonPersonal.offsetTop - 20);
          break;
        case 'informationUse':
          window.scrollTo(0, informationUse.offsetTop - 20);
          break;
        case 'informationShare':
          window.scrollTo(0, informationShare.offsetTop - 20);
          break;
        case 'links':
          window.scrollTo(0, links.offsetTop - 20);
          break;
        case 'cookiesUse':
          window.scrollTo(0, cookiesUse.offsetTop - 20);
          break;
        case 'changes':
          window.scrollTo(0, changes.offsetTop - 20);
          break;
        case 'acceptance':
          window.scrollTo(0, acceptance.offsetTop - 20);
          break;
        default:
          return;
      }
    }
  };

  return (
    <>
      <div className="policy-privacy__title">
        <BackButton prevPage isTextBack={false} />
        <span className="policy-privacy__back">Privacy Policy</span>
      </div>
      <div className="policy-privacy__content">
        <Grid container>
          <Grid item xs={12} md={3} className="policy-privacy__menu-container">
            <ul className="policy-privacy__menu">
              <li
                onClick={() => handleChange('personal')}
                className={active === 'personal' ? 'active' : ''}
              >
                Collection of Personal Information
              </li>
              <li
                onClick={() => handleChange('nonPersonal')}
                className={active === 'nonPersonal' ? 'active' : ''}
              >
                Collection of Non-Personal Information
              </li>
              <li
                onClick={() => handleChange('informationUse')}
                className={active === 'informationUse' ? 'active' : ''}
              >
                Use of Collected Information
              </li>
              <li
                onClick={() => handleChange('informationShare')}
                className={active === 'informationShare' ? 'active' : ''}
              >
                Sharing Personal Information – When Do We Do It
              </li>
              <li
                onClick={() => handleChange('links')}
                className={active === 'links' ? 'active' : ''}
              >
                Links to Other Websites
              </li>
              <li
                onClick={() => handleChange('cookiesUse')}
                className={active === 'cookiesUse' ? 'active' : ''}
              >
                Usage of Cookies
              </li>
              <li
                onClick={() => handleChange('changes')}
                className={active === 'changes' ? 'active' : ''}
              >
                Changes To The Privacy Policy Statement
              </li>
              <li
                onClick={() => handleChange('acceptance')}
                className={active === 'acceptance' ? 'active' : ''}
              >
                Acceptance of Terms of Policy
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={9}>
            <p className="policy-privacy__paragraph">
              We take every possible care to keep your private information protected at all times.
              Our Privacy Policy statement provides details of the policies and practices we follow
              to manage our business. By accessing this policy, you give your implicit consent to
              the procedures we have adopted and followed.
            </p>
            <p className="policy-privacy__paragraph">
              This Privacy Policy Statement provides details about the process of collecting,
              maintaining, and using the information we receive from users of our services/website.
            </p>
            <h3 className="policy-privacy__sub-title" ref={personalRef}>
              Collection of Personal Information
            </h3>
            <p className="policy-privacy__paragraph">
              When you visit our website, we may collect your personal identification in many ways.
              We do so even when you or any of our visitors carry out specific actions such as
              subscribing to our newsletter, placing an order, filling out an online form, or
              performing any other activity by using our website's various tools and resources.
            </p>
            <p className="policy-privacy__paragraph">
              Based on our privacy policy, we may you for your personal details, including your
              name, contact number, and personal email address. You have the freedom to refuse to
              provide such information. However, you may not be allowed access to our website in
              such cases. You will also not be able to perform any activities on our website.
            </p>
            <h3 className="policy-privacy__sub-title" ref={nonPersonalRef}>
              Collection of Non-Personal Information
            </h3>
            <p className="policy-privacy__paragraph">
              As a business entity, we may have to collect information structured as non-personal
              from our visitors. We may ask for technical information pertaining to the type of
              browser you use, web connectivity details, and ISP-related details.
            </p>
            <h3 className="policy-privacy__sub-title" ref={informationUseRef}>
              Use of Collected Information
            </h3>
            <p className="policy-privacy__paragraph">
              We make use of the information collected from you and other users to carry out
              important tasks like the processing of transactions. The information you share while
              placing an order may be used only to provide service for that specific order.
            </p>
            <p className="policy-privacy__paragraph">
              We will not use this information for sharing with others except for delivering the
              exceptional quality of service we are known for. We will use the email address
              provided by you for processing your orders and also to send you any information about
              the specific order or general updates. We may also use your email address to respond
              to your queries and address your problems satisfactorily.
            </p>
            <p className="policy-privacy__paragraph">
              If you have registered your email address on your website, you may receive emails
              covering data related to company news and our products and services. Our only
              intention is to provide quick, accurate, and updated information about our products
              and services. If you don't want to receive this information and updates, you can
              unsubscribe from receiving new emails in the future. We provide step-by-step guidance
              on unsubscribing from our email mailing list at the end of every email. You can also
              access the information by visiting our website.
            </p>
            <p className="policy-privacy__paragraph">
              We have ensured complete protection against any attempt to access your personal
              information in an unauthorized manner by designing our data collection and processing
              methods suitably. We have put the right systems in place to prevent any such attempts
              that can result in the alteration, destruction, or illegal disclosure of your personal
              information and data we have in our possession. They are stored securely.
            </p>
            <p className="policy-privacy__paragraph">
              We use SSL secured communication systems for exchanging sensitive and private data
              with visitors. We wish to convey that all such communication content is suitably
              encrypted and further protected through digital signatures.
            </p>
            <h3 className="policy-privacy__sub-title" ref={informationShareRef}>
              Sharing Personal Information – When Do We Do It
            </h3>
            <p className="policy-privacy__paragraph">
              We have already stated very clearly that we will not misuse any personal information
              you have shared with us. However, we may share some generic information with our
              partners and associates. These may be in the form of generic cumulative
              demographic-specific information unrelated to your personal information.
            </p>
            <h3 className="policy-privacy__sub-title" ref={linksRef}>
              Links to Other Websites
            </h3>
            <p className="policy-privacy__paragraph">
              Our website may contain links to other websites operated by third parties and over
              which we have no control. If you click on any such external link, you will be taken to
              a third-party site. As we are not responsible for the content on any such sites and
              have no control over them, we strongly advise that you visit and review the Privacy
              Policy of every such site you visit. We are not responsible for any adverse experience
              you may have because of visiting such sites.
            </p>
            <h3 className="policy-privacy__sub-title" ref={cookiesUseRef}>
              Usage of Cookies
            </h3>
            <p className="policy-privacy__paragraph">
              We use tracking technologies like cookies to track your activities on our website. We
              may hold some information gained from these cookies, which are files with small sums
              of data.
            </p>
            <p className="policy-privacy__paragraph">
              You are at liberty to set your browser to refuse all such cookies. You can also ask
              for an indicator of when a cookie is being sent. If you are not comfortable using
              cookies, you may not be able to access some important areas of service. Some of the
              cookies we use are session cookies, preference cookies, security cookies, and ad
              cookies.
            </p>
            <h3 className="policy-privacy__sub-title" ref={changesRef}>
              Changes To The Privacy Policy Statement
            </h3>
            <p className="policy-privacy__paragraph">
              We have the right to update this privacy policy statement at any time. We will inform
              you of the changes we have carried as and when we do it. We will also provide dates of
              the changes we have incorporated on this page.
            </p>
            <p className="policy-privacy__paragraph">
              You agree that you will be responsible for noticing such changes and agree to keep a
              close watch on our privacy policy. By continuing to use our website even after
              noticing the changes in our privacy policy, you indicate your acceptance of the
              changed Privacy Policy document.
            </p>
            <h3 className="policy-privacy__sub-title" ref={acceptanceRef}>
              Acceptance of Terms of Policy
            </h3>
            <p className="policy-privacy__paragraph">
              Your continued use of our website is a clear indication that you have accepted the
              terms of our service. If you do not agree with the changes, please stop using our
              website.
            </p>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PrivacyPolicy;
