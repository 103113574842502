import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as payments from '../api/payments';

// REDUX
import * as paymentsAction from '../actions/payments';
import * as paymentsActionType from '../actions/types/types';
import {Action} from 'redux-actions';

export function* getPaymentMethods(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  unknown
> {
  try {
    const payload = yield call(payments.getPaymentMethods, {});
    yield put(paymentsAction.getPaymentMethodsSuccess(payload));
  } catch (error) {
    yield put(paymentsAction.getPaymentMethodsFailure(error));
  }
}

// WATCHERS
export function* watchPaymentMethods(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(paymentsActionType.GET_PAYMENT_METHODS_REQUEST, getPaymentMethods);
}
