import React from 'react';
import {ReactComponent as AboutImg} from '../../../images/contact-us-about.svg';
import {ReactComponent as FacebookIcon} from '../../../images/contact-us-fb-icon.svg';
import {ReactComponent as InstaIcon} from '../../../images/contact-us-insta-icon.svg';
import {ReactComponent as LinkedinIcon} from '../../../images/contact-us-lin-icon.svg';
import './style.scss';

const AboutUs = (): JSX.Element => {
  return (
    <div className="contact-us__about">
      <AboutImg className="about__image" />
      <h2 className="about__title">About Us</h2>
      <div className="about__desc">
        <p>
          The Marquètte Team is comprised of developers, web designers, and marketers who work daily
          to make this product even better. You can track our progress through social media.
        </p>
        <br />
        <p>Please sign up, comment, and join discussions! Your opinions matter greatly to us.</p>
      </div>
      <div className="about__social">
        <a
          href="https://www.facebook.com/devtoriumsoftwarecompany"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon />
        </a>
        <a href="https://www.instagram.com/marquette.ai/" target="_blank" rel="noopener noreferrer">
          <InstaIcon />
        </a>
        <a
          href="https://www.linkedin.com/company/devtorium/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedinIcon />
        </a>
      </div>
    </div>
  );
};

export default AboutUs;
