import React, {useEffect, useState} from 'react';
import {IEntryCategoryItems, ITextModelEntry} from '../../../interfaces';
import useWindowDimensions from '../../../helpers/windowResize';
import Slider from 'react-slick';
import {useNavigate} from 'react-router-dom';
import {BLOB_LINK} from '../../../constants';
import Onboarding from '../../UI/Onboarding';
import {ReactComponent as HintIcon} from '../../../images/hint-icon.svg';
import {IconButton} from '@material-ui/core';
import introJs from 'intro.js';

interface IProps {
  templates: IEntryCategoryItems[];
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
}

interface ICategoryItemProps {
  item: IEntryCategoryItems;
  isSelected: boolean;
  handleChange: (categoryId: string) => void;
}

interface IColors {
  bg_color: string;
  border_color: string;
  text_color: string;
}
const imageLink = `${BLOB_LINK}/assets-for-templates/`;

function CategoryItem(props: ICategoryItemProps) {
  const {item, isSelected, handleChange} = props;
  const colors = JSON.parse(item.backgroundColor) as IColors;
  const [isHover, setHover] = useState<boolean>(false);

  return (
    <div
      key={item.id}
      className={`text-model__category ${item.name} ${isSelected ? 'selected' : ''}`}
      onClick={() => handleChange(item.id)}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={
        isSelected || isHover
          ? {
              backgroundColor: colors ? colors.bg_color : 'transparent',
              borderColor: colors ? colors.border_color : 'transparent',
              color: colors ? colors.text_color : '#00000',
            }
          : {}
      }
    >
      <img src={`${imageLink}${item.imageUrl}`} alt="category icon" />
      {item.name}
    </div>
  );
}

const ChooseTemplate = (props: IProps): JSX.Element => {
  const navigate = useNavigate();
  const {templates, selectedCategory, setSelectedCategory} = props;
  const [availableTemplates, setAvailableTemplates] = useState<IEntryCategoryItems[]>([]);
  const screenWidth = useWindowDimensions().width;
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
  };
  const [isOnboardingOpen, setIsOnboardingOpen] = React.useState<boolean>(false);
  const steps = [
    {
      element: screenWidth > 761 ? '.text-model__category-wr' : '.text-model__category-mobile',
      position: 'bottom',
      intro: "Now, let's shape your project by selecting a template category.",
      tooltipClass: 'onboarding-tooltip__category onboarding-tooltip__height',
      highlightClass: 'onboarding-highlight__category',
      // disableInteraction: true,
    },
    {
      element: '.text-model__templates-wr',
      intro: 'Next, choose a template that fits your project.',
      position: screenWidth <= 768 ? 'top' : 'bottom',
      tooltipClass: 'onboarding-tooltip__template onboarding-tooltip__height',
      highlightClass: 'onboarding-highlight__template',
      // disableInteraction: true,
    },
  ];
  const intro = introJs();
  const closeTour = () => {
    if (intro) {
      void intro.exit(true);
    }
  };

  useEffect(() => {
    const availableTemplates = templates.filter((item) => {
      return item.id === selectedCategory && item;
    });
    setAvailableTemplates(availableTemplates);
  }, [selectedCategory, templates]);

  const handleChange = (categoryId: string) => {
    setSelectedCategory(categoryId);
  };
  const isSelected = (categoryId: string) => {
    return Boolean(categoryId === selectedCategory);
  };

  function onChooseTemplate(templateId: string) {
    const data = localStorage.getItem('properties');
    if (!data) {
      const properties = {
        selectedEntryId: templateId,
      };
      localStorage.setItem('properties', JSON.stringify(properties));
    } else {
      const parsedData = JSON.parse(data) as {selectedEntryId: string};
      parsedData.selectedEntryId = templateId;
      localStorage.setItem('properties', JSON.stringify(parsedData));
    }
    navigate(`/text-model`);
  }

  const handleOnboardingExit = () => {
    setIsOnboardingOpen(false);
  };

  return (
    <>
      <h2 className="text-model__title">Choose a Template</h2>
      <p className="text-model__desc">Please pick content template</p>
      <div className="text-model__divider"></div>
      {screenWidth > 760 ? (
        <div className="text-model__category-wr">
          {templates.map((item, index) => (
            <CategoryItem
              key={item.id}
              item={item}
              isSelected={isSelected(item.id)}
              handleChange={handleChange}
            />
          ))}
          <IconButton onClick={() => setIsOnboardingOpen(true)} className="button-hint">
            <HintIcon />
          </IconButton>
        </div>
      ) : (
        <div className="text-model__category-mobile">
          <Slider {...sliderSettings}>
            {templates.map((item, index) => (
              <CategoryItem
                key={item.id}
                item={item}
                isSelected={isSelected(item.id)}
                handleChange={handleChange}
              />
            ))}
          </Slider>
          <IconButton onClick={() => setIsOnboardingOpen(true)} className="button-hint">
            <HintIcon />
          </IconButton>
        </div>
      )}
      <div className="text-model__empty"></div>
      <div className="text-model__templates-wr">
        {availableTemplates.length &&
          availableTemplates[0].entries.map((item: ITextModelEntry) => (
            <div
              key={item.id}
              className={`text-model__template`}
              onClick={() => {
                onChooseTemplate(item.id);
                closeTour();
              }}
            >
              <span className="template-title">
                <img src={`${imageLink}${item.image}`} alt="template icon" />
                <span className="template-alias">{item.name}</span>
              </span>
              <span className="template-description">{item.description}</span>
            </div>
          ))}
      </div>
      <Onboarding steps={steps} onExit={handleOnboardingExit} isOpen={isOnboardingOpen} />
    </>
  );
};

export default ChooseTemplate;
