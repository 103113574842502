import {Configuration} from '@azure/msal-browser/dist/config/Configuration';
import {InteractionRequiredAuthError, PublicClientApplication} from '@azure/msal-browser';
import {API_CLIENT_ID, AUTHORITY, KNOWN_AUTHORITY, MSAL_SCOPE} from '../constants';
import {AuthError} from '@azure/msal-common/dist/error/AuthError';

// extension
export interface MsalExtension {
  extension_Industry?: string;
}

// Msal Configurations
const config: Configuration = {
  auth: {
    clientId: API_CLIENT_ID,
    authority: AUTHORITY,
    knownAuthorities: [KNOWN_AUTHORITY],
    navigateToLoginRequestUrl: false,
    redirectUri: `/`,
    postLogoutRedirectUri: `/login`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const pca = new PublicClientApplication(config);
export const accounts = pca.getAllAccounts();

export const acquireToken = async (): Promise<string> => {
  const request = {
    scopes: ['openid', MSAL_SCOPE],
    account: accounts[0],
  };

  return await pca
    .acquireTokenSilent(request)
    .then((response) => {
      return response.accessToken;
    })
    .catch((error: AuthError) => {
      console.log('acquireToken Error: ', error);
      if (error instanceof InteractionRequiredAuthError) {
        void pca.acquireTokenRedirect(request);
        return '';
      } else {
        if (error && error.errorCode === 'multiple_matching_tokens') {
          localStorage.clear();
          window.location.reload();
        }
        return '';
      }
    });
};
