import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  POST_SEND_CONTACT_US_FORM_REQUEST,
  POST_SEND_CONTACT_US_FORM_SUCCESS,
  POST_SEND_CONTACT_US_FORM_FAILURE,
} from '../actions/types/types';
import {IContactUsPostAction, IContactUsState} from '../interfaces';

export const initialState: IContactUsState = {
  sendFormResponse: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [POST_SEND_CONTACT_US_FORM_REQUEST]: (state: IContactUsState) => ({
    ...state,
    loading: true,
  }),

  [POST_SEND_CONTACT_US_FORM_SUCCESS]: (state: IContactUsState, action: IContactUsPostAction) => ({
    ...state,
    sendFormResponse: action.payload,
    loading: false,
    error: '',
  }),

  [POST_SEND_CONTACT_US_FORM_FAILURE]: (state: IContactUsState, action: IContactUsPostAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
