// ADD SUBSCRIPTION BY ADMIN - ACTION
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as addAdminSubscriptionType from './types/types';

export const getAdminSubscriptionsStatusRequest = createAction(
  addAdminSubscriptionType.GET_SUBSCRIPTIONS_ADMIN_REQUEST,
);
export const getAdminSubscriptionsStatusSuccess = createAction(
  addAdminSubscriptionType.GET_SUBSCRIPTIONS_ADMIN_SUCCESS,
);
export const getAdminSubscriptionsStatusFailure = createAction(
  addAdminSubscriptionType.GET_SUBSCRIPTIONS_ADMIN_FAILURE,
);

export const postAddAdminSubscriptionStatusRequest = createAction(
  addAdminSubscriptionType.POST_ADD_SUBSCRIPTION_ADMIN_REQUEST,
);
export const postAddAdminSubscriptionStatusSuccess = createAction(
  addAdminSubscriptionType.POST_ADD_SUBSCRIPTION_ADMIN_SUCCESS,
);
export const postAddAdminSubscriptionStatusFailure = createAction(
  addAdminSubscriptionType.POST_ADD_SUBSCRIPTION_ADMIN_FAILURE,
);

export const postSubscriptionRefundRequest = createAction(
  addAdminSubscriptionType.POST_USER_SUBSCRIPTION_REFUND_REQUEST,
);
export const postSubscriptionRefundSuccess = createAction(
  addAdminSubscriptionType.POST_USER_SUBSCRIPTION_REFUND_SUCCESS,
);
export const postSubscriptionRefundFailure = createAction(
  addAdminSubscriptionType.POST_USER_SUBSCRIPTION_REFUND_FAILURE,
);
