import {GET, handleBody, handleExceptions, POST} from './request';

export const postAddSubscriptionAdmin = (payload: {userId: string}): Promise<Object | null> => {
  const endpoint: string = `/Admin/AddEndlessSubscription`;
  const options = {
    body: {...payload},
  };
  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getSubscriptionsAdmin = (payload: {}): Promise<Object | null> => {
  const endpoint: string = `/Admin/Subscriptions`;
  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const refundSubscription = (payload: {userId: string}): Promise<Object | null> => {
  const endpoint: string = `/Admin/RefundSubscription`;
  const options = {
    body: {...payload},
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
