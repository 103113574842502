import {handleActions} from 'redux-actions';
import {ADD_ALERT, REMOVE_ALERT} from '../actions/types/types';
import {IAlertAction, IAlertsState, IRemoveAlertAction} from '../interfaces/alerts';

export const initialState: IAlertsState = {
  alerts: [],
};

const handlerMap: any = {
  [ADD_ALERT]: (state: IAlertsState, action: IAlertAction) => ({
    alerts: [...state.alerts, action.payload],
  }),
  [REMOVE_ALERT]: (state: IAlertsState, action: IRemoveAlertAction) => ({
    alerts: state.alerts.filter((alert) => alert.id !== action.payload.alertId),
  }),
};

export default handleActions(handlerMap, initialState);
