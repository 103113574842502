import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as coupon from '../../../../actions/coupon';
import * as products from '../../../../actions/subscription';
import {IState} from '../../../../interfaces';
import './style.scss';
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  createStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  InputAdornment,
  ListItemIcon,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DatePicker from '../../../UI/DatePicker';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AlertDialog from '../../../UI/AlertDialog';
import {useNavigate} from 'react-router-dom';
import UiAlert from '../../../UI/UiAlert';
import moment from 'moment';

interface IProps {
  couponName: string;
  setCouponName: (name: string) => void;
  productPlan: string | undefined;
  setProductPlan: (plan: string) => void;
  campaignName: string;
  setCampaignName: (name: string) => void;
  internalNote: string;
  setInternalNote: (note: string) => void;
  discountValue: string;
  setDiscountValue: (value: string) => void;
  discountDetailsIcon: boolean;
  setDiscountDetailsIcon: (icon: boolean) => void;
  couponUsage: string | undefined;
  setCouponUsage: (usage: string) => void;
  startDate: Date | string | undefined;
  setStartDate: (date: Date) => void;
  endDate: Date | string | undefined;
  setEndDate: (date: Date) => void;
}
const useStyles = makeStyles(() =>
  createStyles({
    border: {
      borderRadius: '8px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)',
      padding: '40px 60px',
    },
  }),
);

export const EditCouponsForm = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    couponName,
    setCouponName,
    setProductPlan,
    campaignName,
    setCampaignName,
    internalNote,
    setInternalNote,
    discountValue,
    setDiscountValue,
    setDiscountDetailsIcon,
    couponUsage,
    setCouponUsage,
    startDate,
    endDate,
  } = props;

  const dispatch = useDispatch();
  const allProducts = useSelector((state: IState) =>
    state.subscriptionRegistered.subscription?.items.slice(1),
  );
  const today = new Date();
  const isActive: boolean = startDate !== undefined && today > new Date(startDate);
  const isScheduled: boolean = startDate !== undefined && today < new Date(startDate);
  const isDisabled: boolean = endDate !== undefined && today > new Date(endDate);
  const couponInfo = useSelector((state: IState) => state.coupon.couponById);
  const endDateFromRequest = endDate ? new Date(endDate) : null;

  const [nameError, setNameError] = useState<string | null>(null);
  const [couponError, setCouponError] = useState<string | null>(null);
  const [campaignError, setCampaignError] = useState<string | null>(null);
  const [discountError, setDiscountError] = useState<string | null>(null);
  const [discountLengthError, setDiscountLengthError] = useState<string | null>(null);
  const [currentProduct, setCurrentProduct] = useState<string>(
    couponInfo?.stripeProductId ? couponInfo?.stripeProductId : '',
  );
  const [isEmptyProducts, setIsEmptyProducts] = useState<boolean>(false);
  const [discountDetails, setDiscountDetails] = useState<string>(
    couponInfo && couponInfo.isFixedAmount ? 'fixed' : 'percentage',
  );
  const [isFixed, setIsFixed] = useState<boolean>(couponInfo ? couponInfo.isFixedAmount : false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
    startDate ? new Date(startDate) : new Date(),
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(
    endDate ? new Date(endDate) : null,
  );
  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState<boolean>(false);
  const [isDisableDialogOpen, setDisableDialogOpen] = useState<boolean>(false);
  const nextDate: Date = selectedStartDate ? new Date(selectedStartDate) : new Date();

  const requiredText: string = 'This field is required';
  const requiredLength: string = 'Please enter number from 1 to 100';
  const codeRequirements = [
    'Length: 3 to 15 symbols.',
    'Only Latin characters and numbers.',
    'The code isn’t case-sensitive.',
  ];

  useEffect(() => {
    dispatch(products.getRegisteredUserSubscriptionRequest());
  }, []);

  useEffect(() => {
    setSelectedEndDate(endDateFromRequest);
  }, [isDisableDialogOpen === false]);

  function onCouponNameChange(value: string) {
    const isLatinAndIsNumber = /^[0-9a-zA-Z\s]+$/.test(value);
    if (value.length) {
      if (!isLatinAndIsNumber) {
        setNameError('The name should consist of latin characters + numbers');
      } else {
        setCouponName(value);
        setNameError(null);
        setCouponError(null);
      }
    } else {
      setCouponName(value);
      setNameError(null);
      setCouponError(null);
    }
  }
  function onCampaignNameChange(value: string) {
    setCampaignName(value);
    setCampaignError(null);
  }
  function onInternalNoteChange(value: string) {
    setInternalNote(value);
  }
  function onDiscountValueChange(value: string) {
    if (+value > 100) {
      setDiscountLengthError(requiredLength);
      setDiscountValue(value);
    }
    if (+value < 1) {
      setDiscountLengthError(requiredLength);
      setDiscountValue(value);
    }
    if (+value >= 1 && +value <= 100) {
      setDiscountLengthError(null);
      setDiscountValue(value);
      setDiscountError(null);
    }
  }
  const handleChangeDiscountDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountDetails((event.target as HTMLInputElement).value);
    switch ((event.target as HTMLInputElement).value) {
      case 'fixed':
        setIsFixed(true);
        setDiscountDetailsIcon(true);
        break;
      case 'percentage':
        setIsFixed(false);
        setDiscountDetailsIcon(false);
        break;
      default:
        setIsFixed(false);
        setDiscountDetailsIcon(false);
    }
  };
  const iconAdornment = isFixed
    ? {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }
    : {
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      };

  function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }

  function getStartDateExpirationPicker(date = new Date()) {
    if (isScheduled) {
      if (selectedStartDate !== null) {
        nextDate.setDate(selectedStartDate.getDate() + 1);

        return nextDate;
      }
    } else if (isDisabled) {
      const tomorrowDate = new Date(date.getTime());
      tomorrowDate.setDate(date.getDate() + 1);

      return tomorrowDate;
    } else if (isActive) {
      const previous = new Date(date.getTime());
      previous.setDate(date.getDate() - 1);

      return previous;
    }
  }

  const onMaxUsageChange = (usage: string) => {
    setCouponUsage(usage);
  };
  const dateStartHandleChange = (date: Date | null) => {
    setSelectedStartDate(date);
  };
  const dateEndHandleChange = (date: Date | null) => {
    const previousDate = getPreviousDay();
    const shortedDate = moment(date).format('DD/MM/YYYY');
    const shortedPrevDate = moment(previousDate).format('DD/MM/YYYY');
    if (shortedDate === shortedPrevDate) {
      setSelectedEndDate(date);
      setDisableDialogOpen(true);
    } else {
      setSelectedEndDate(date);
    }
  };

  const sendProductPlan = (productIds: string) => {
    const productPlanName = allProducts?.find((item) => productIds === item.id);
    productPlanName &&
      setProductPlan(`${productPlanName.name}(${productPlanName.period.toLowerCase()})`);
  };
  const handleChangeProducts = (productIds: string) => {
    setCurrentProduct(productIds);
    sendProductPlan(productIds);
    setIsEmptyProducts(false);
  };
  const handleCancelCoupon = () => {
    navigate('/admin/coupons-management');
  };
  function disableCoupon() {
    const previousDate = getPreviousDay();
    isScheduled && setSelectedStartDate(previousDate);
    handleEditCoupon();
    setDisableDialogOpen(false);
  }

  function handleEditCoupon() {
    const createData = {
      id: couponInfo && couponInfo.id,
      code: couponName,
      stripeProductId: currentProduct,
      name: campaignName,
      description: internalNote,
      isFixedAmount: isFixed,
      discount: +discountValue,
      maxUsage: couponUsage && +couponUsage,
      startDate: moment(selectedStartDate).utcOffset(0, true).format(),
      endDate: moment(selectedEndDate).utcOffset(0, true).format(),
    };
    if (!currentProduct) {
      setIsEmptyProducts(true);
    }
    if (!campaignName) {
      setCampaignError(requiredText);
    }
    if (!discountValue) {
      setDiscountError(requiredText);
    }
    if (
      couponName &&
      currentProduct &&
      campaignName &&
      discountValue &&
      +discountValue >= 1 &&
      +discountValue <= 100
    ) {
      dispatch(coupon.putUpdateCouponRequest(createData));
      navigate('/admin/coupons-management');
    }
  }

  function handleCloneCoupon() {
    couponInfo && navigate(`/admin/clone-coupon/${couponInfo.id}`);
  }

  return (
    <div className={classes.border}>
      <div className="coupon-create-form__input-name">
        <h2 className="coupon-create__titles coupon-create__titles-title">Edit Coupon Code</h2>
        {isActive && !isDisabled && (
          <UiAlert
            type={'warning'}
            title={'This coupon code is already active.'}
            detailsList={[
              'Please be careful with your changes to prevent critical mistakes in your campaign.',
              'If you want to disable the Coupon please enter Yesterday`s date into the Expiration date field.',
            ]}
            withButton={true}
            buttonText={'Got it!'}
          />
        )}
        {isScheduled && (
          <UiAlert
            type={'warning'}
            title={'This coupon code is already scheduled.'}
            details={
              'If you want to disable the Coupon please enter Yesterday`s date into the Expiration date field.'
            }
            withButton={true}
            buttonText={'Got it!'}
          />
        )}
        {isDisabled && (
          <UiAlert
            type={'warning'}
            title={'This coupon code is disabled.'}
            details={
              'If you want to reactivate the Coupon please update the Expiration date field.'
            }
            withButton={true}
            buttonText={'Got it!'}
          />
        )}
        <TextField
          value={couponName}
          label="Enter a coupon code, e.g. DEVTORIUM"
          className="coupon-create-form__input-title"
          variant="standard"
          fullWidth
          error={Boolean(nameError) || Boolean(couponError)}
          helperText={(nameError ? nameError : '') || (couponError ? couponError : '')}
          required
          onChange={(event) => onCouponNameChange(event.target.value)}
          inputProps={{
            minLength: 3,
            maxLength: 15,
            style: {textTransform: 'uppercase'},
            readOnly: isActive || isDisabled,
          }}
        />
        <span className="coupon-create__count">{couponName.length} / 15</span>
        <div className="coupon-create__descr">
          <p>
            This is the code a user has to enter to use this coupon. Сhoose something that makes
            sense with the kind of discount you’re offering.
          </p>
          <List>
            {codeRequirements.map((item, idx) => (
              <ListItem key={idx}>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </div>
      </div>
      {allProducts && (
        <FormControl className="coupon-create-form__input-products coupon-create-form__input">
          <InputLabel htmlFor="products-select" className="coupon-create-form__label">
            Product
          </InputLabel>
          <Select
            IconComponent={ExpandMoreIcon}
            className="coupon-create-form__select"
            labelId="products-select"
            value={currentProduct}
            onChange={(e: React.ChangeEvent<{value: unknown}>) =>
              handleChangeProducts(e.target.value as string)
            }
            error={isEmptyProducts}
            required
            disabled={isActive}
          >
            {allProducts.map((item) => (
              <MenuItem key={item.id} value={item.id} className="coupon=create-form__option">
                {item.name} ({item.period.toLowerCase()})
              </MenuItem>
            ))}
          </Select>
          {isEmptyProducts && (
            <p className="coupon-create-form__input-error-text coupon-create-form__input">
              {requiredText}
            </p>
          )}
        </FormControl>
      )}
      <TextField
        value={campaignName}
        label="Name of the Campaign"
        className="coupon-create-form__input-campaign coupon-create-form__input"
        id="campaign-form-input"
        variant="standard"
        fullWidth
        error={Boolean(campaignError)}
        helperText={campaignError ? campaignError : ''}
        required
        onChange={(event) => onCampaignNameChange(event.target.value)}
      />
      <TextField
        value={internalNote}
        label="Internal Note (optional)"
        className="coupon-create-form__input-note coupon-create-form__input"
        id="internal-note-form-input"
        variant="standard"
        fullWidth
        onChange={(event) => onInternalNoteChange(event.target.value)}
      />
      <div className="coupon-create__titles coupon-create__titles-subtitle">Discount details</div>
      <div className="coupon-create-form__radio">
        <RadioGroup value={discountDetails} onChange={handleChangeDiscountDetails}>
          <FormControlLabel
            value="percentage"
            control={<Radio required />}
            label="Percentage (%)"
          />
          <FormControlLabel value="fixed" control={<Radio required />} label="Fixed discount ($)" />
        </RadioGroup>
        <TextField
          type="number"
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          value={discountValue}
          label="Discount value"
          className="coupon-create-form__value"
          id="discount-value-form-input"
          InputProps={iconAdornment}
          inputProps={{inputMode: 'numeric', pattern: '[0-9]*', min: 1, max: 100}}
          variant="standard"
          fullWidth
          error={Boolean(discountError) || Boolean(discountLengthError)}
          helperText={
            (discountError ? discountError : '') || (discountLengthError ? discountLengthError : '')
          }
          required
          onChange={(event) => onDiscountValueChange(event.target.value.toString())}
        />
        <div className="coupon-create__descr">
          Select and enter a fixed discount value. To set a discount based on the percentage, select
          Percentage Discount. The discount will be applied to the product price.
        </div>
      </div>
      <div className="coupon-create__titles coupon-create__titles-subtitle">
        Total number of coupons
      </div>
      <div className="coupon-create-form__usage">
        <TextField
          type="number"
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          value={couponUsage}
          label="Max usage"
          className="coupon-create-form__usage-input"
          id="max-usage-form-input"
          inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
          variant="standard"
          fullWidth
          onChange={(event) => onMaxUsageChange(event.target.value)}
        />
      </div>
      <div className="coupon-create__descr">
        Specify how many times a coupon code can be used, e.g., 10. For unlimited usage, enter "0",
        or leave this field empty. The customer cannot use the same code for future purchases.
      </div>
      <div className="coupon-create__titles coupon-create__titles-subtitle">Usage date range</div>
      <div className="coupon-create__datepicker">
        <DatePicker
          label="Start date (optional)"
          className="coupon-create__datepicker-input"
          onChangeFunction={dateStartHandleChange}
          name="startDate"
          value={selectedStartDate}
          disabled={isActive || isDisabled}
          minDate={isScheduled ? getPreviousDay() : null}
        />
        <span> - </span>
        <DatePicker
          label="Expiration date (optional)"
          className="coupon-create__datepicker-input"
          onChangeFunction={dateEndHandleChange}
          name="endDate"
          value={selectedEndDate}
          minDate={getStartDateExpirationPicker()}
        />
      </div>
      <div className="coupon-create__descr">
        Specify the usage date range for your coupon. The Start date defaults to the current date,
        but you can also set the Start date in the future. For unlimited usage, leave the Expiration
        date field empty.
      </div>

      <div className="coupon-create__buttons">
        <Button className="white-btn white-btn-hover" onClick={() => setIsOpenCancelDialog(true)}>
          Cancel
        </Button>
        <Button className="orange-btn orange-btn-hover dark-blue-btn" onClick={handleCloneCoupon}>
          Clone
        </Button>
        <Button className="orange-btn orange-btn-hover" onClick={handleEditCoupon}>
          Update Coupon
        </Button>
      </div>
      <AlertDialog
        isDialogOpen={isOpenCancelDialog}
        setDialogOpen={setIsOpenCancelDialog}
        text={{
          question: 'Are you sure you want to close the form?',
          warning: 'All changes will be lost.',
          action: 'Yes, close',
        }}
        confirmAction={handleCancelCoupon}
      />
      <AlertDialog
        isDialogOpen={isDisableDialogOpen}
        setDialogOpen={setDisableDialogOpen}
        text={{
          question: 'Are you sure want to disable the coupon?',
          warning:
            'You set the Expiration Date as Yesterday date. It will automatically disable the coupon.',
          action: 'Yes, disable',
        }}
        confirmAction={disableCoupon}
      />
    </div>
  );
};

export default EditCouponsForm;
