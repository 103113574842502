import {handleActions} from 'redux-actions';
// ACTION TYPES
import {
  GET_USER_SETTINGS_REQUEST,
  GET_USER_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_FAILURE,
} from '../actions/types/types';
import {IUserSettingsState, ISettingsAction} from '../interfaces';

export const initialState = {
  userSettings: [{}],
  loading: false,
  error: '',
};
const handlerMap: any = {
  [GET_USER_SETTINGS_REQUEST]: (state: IUserSettingsState) => ({
    ...state,
    loading: true,
  }),

  [GET_USER_SETTINGS_SUCCESS]: (state: IUserSettingsState, action: ISettingsAction) => ({
    ...state,
    userSettings: action.payload,
    loading: false,
    error: '',
  }),

  [GET_USER_SETTINGS_FAILURE]: (state: IUserSettingsState) => ({
    ...state,
    error: '',
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
