import {Button} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import './style.scss';

interface IProps {
  isDialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  text: {
    question: string;
    warning?: string;
    action: string;
  };
  confirmAction: (action?: number) => void;
  itemToDelete?: number;
}

const AlertDialog = (props: IProps): JSX.Element => {
  const {isDialogOpen, setDialogOpen, text, confirmAction, itemToDelete} = props;

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        className="alert-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="alert-dialog__title">
          {text.question}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="alert-dialog__warning">
            {text.warning}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-dialog__actions-btn">
          <Button
            onClick={() => confirmAction(itemToDelete)}
            className="orange-btn orange-btn-hover"
          >
            {text.action}
          </Button>
          <Button onClick={handleClose} className="white-btn white-btn-hover">
            Cancel
          </Button>
        </DialogActions>
        <IconButton aria-label="close" className="close-icon" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
