import {handleActions} from 'redux-actions';
// ACTION TYPES
import {
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
} from '../actions/types/types';
import {ICompaniesAction, ICompaniesState} from '../interfaces';

export const initialState = {
  companies: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_COMPANIES_REQUEST]: (state: ICompaniesState) => ({
    ...state,
    loading: true,
  }),

  [GET_COMPANIES_SUCCESS]: (state: ICompaniesState, action: ICompaniesAction) => ({
    ...state,
    companies: action.payload,
    loading: false,
    error: '',
  }),

  [GET_COMPANIES_FAILURE]: (state: ICompaniesState) => ({
    ...state,
    error: '',
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
