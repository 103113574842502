import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as userSettings from '../api/userSettings';

// REDUX
import * as userSettingsAction from '../actions/userSettings';
import * as userSettingsActionType from '../actions/types/types';
import {ISaveSettingsAction, IPatchSettingsAction} from '../interfaces';
import {Action} from 'redux-actions';

export function* getUserSettings(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  unknown
> {
  try {
    const payload = yield call(userSettings.getUserSettings);
    yield put(userSettingsAction.getUserSettingsSuccess(payload));
  } catch (error) {
    yield put(userSettingsAction.getUserSettingsFailure(error));
  }
}

export function* saveUserSettings(
  action: ISaveSettingsAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(userSettings.saveUserSettings, action.payload);
    yield put(userSettingsAction.saveUserSettingsSuccess(payload));
  } catch (error) {
    yield put(userSettingsAction.saveUserSettingsFailure(error));
  }
}

export function* patchUserSettings(
  action: IPatchSettingsAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(userSettings.patchUserSettings, action.payload);
    yield put(userSettingsAction.patchUserSettingsSuccess(payload));
  } catch (error) {
    yield put(userSettingsAction.patchUserSettingsFailure(error));
  }
}

// WATCHERS
export function* watchUserSettings(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(userSettingsActionType.GET_USER_SETTINGS_REQUEST, getUserSettings);
  yield takeLatest(userSettingsActionType.PUT_USER_SETTINGS_REQUEST, saveUserSettings);
  yield takeLatest(userSettingsActionType.PATCH_USER_SETTINGS_REQUEST, patchUserSettings);
}
