import {useLocation} from 'react-router-dom';
import {FileTypes} from '../enums/FileTypes';
import {MSAL_SCOPE} from '../constants';
import DocIcon from '../images/file-doc-icon.svg';
import PdfIcon from '../images/file-pdf-icon.svg';
import TxtIcon from '../images/file-txt-icon.svg';
import {IPublicClientApplication} from '@azure/msal-browser';
import {Idetails, IPlanDetails, IUserCurrentSubscription} from '../interfaces';
import VisaIcon from '../images/visa-icon.svg';
import MastercardIcon from '../images/mastercard-icon.svg';
import imgPathSilver from '../images/silver.png';
import imgPathGold from '../images/gold.png';
import imgPathDiamond from '../images/diamond.png';
import imgPathStudent from '../images/student.png';
import moment from 'moment';
import CreditCountIconSilver from '../images/credit-icon-silver.svg';
import CreditCountIconSilverPlus from '../images/credit-icon-silver-plus.svg';
import CreditCountIconGold from '../images/credit-icon-gold.svg';
import htmlToPdf from 'jspdf';
import TurndownService from 'turndown';
import {ITemplateBlock} from './textModel';

export function createEndpoint(baseName: string, params?: {}): string {
  if (params) {
    return `/${baseName}?${Object.entries(params)
      .map((item) => item.join('='))
      .join('&')}`;
  } else {
    return `/${baseName}`;
  }
}

export function createSaveItemPutRequest(
  titleName: string,
  itemId: number | string,
  favoriteTitle: string,
  liked: boolean,
): Object | undefined {
  if (!liked) {
    return {
      reference: `${titleName}:${itemId}`,
      notes: `${favoriteTitle}`,
      title: `Title ${titleName} ${itemId}`,
    };
  } else {
    return {reference: `${titleName}:${itemId}`};
  }
}

export function createArrayEndpoint(baseName: string, params?: {}): string {
  if (params) {
    const resultString = Object.entries(params).map((item) => {
      const paramKeyName = item[0];
      const paramArray = item[1];
      if (Array.isArray(paramArray)) {
        return paramArray
          .map((arrayItem: number[]) => {
            return paramKeyName + '=' + arrayItem;
          })
          .join('&');
      } else {
        return item.join('=');
      }
    });
    return `/${baseName}?${resultString.join('&')}`;
  } else {
    return `/${baseName}`;
  }
}

export function getIndustryIdByName(industryName: string): number {
  switch (industryName) {
    case 'All':
      return 1;
    case 'Health & Wellness':
      return 10;
    case 'Retail':
      return 20;
    case 'Beauty':
      return 30;
    case 'Education':
      return 40;
    case 'Media':
      return 50;
    case 'Finance':
      return 60;
    case 'Not Applicable':
      return 900;
    case 'Other':
      return 1000;
    default:
      return 0;
  }
}

export function getIndustryNameById(industryId: number): string {
  switch (industryId) {
    case 1:
      return 'All';
    case 10:
      return 'Health & Wellness';
    case 20:
      return 'Retail';
    case 30:
      return 'Beauty';
    case 40:
      return 'Education';
    case 50:
      return 'Media';
    case 60:
      return 'Finance';
    case 1000:
      return 'Other';
    default:
      return '';
  }
}

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export function validURL(str: string): boolean {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
}

export function getScrollPosition(rect: DOMRect | undefined): boolean {
  const pageOffset = window.pageYOffset;
  const windowHeight = window.innerHeight;

  return !!rect && rect.top < pageOffset + windowHeight;
}

export function setDocumentIcon(fileType: string): string | undefined {
  switch (fileType) {
    case FileTypes.DOC:
      return DocIcon;
    case FileTypes.PDF:
      return PdfIcon;
    case FileTypes.TXT:
      return TxtIcon;
  }
}

export function getStatusByQuestionLastActivity(status: string): string {
  switch (status) {
    case 'Question was created':
      return 'New';
    case 'Question was blocked':
      return 'Rejected';
    case 'Question needs clarification':
      return 'Requires clarification';
    case 'Question is being processed':
      return 'Processing';
    case 'Answer for your question is ready':
      return 'Answer is ready';
    case 'New answer is available for your question':
      return 'Updated answer';
    case 'There are no updates for your question':
      return 'No update';
    default:
      return '';
  }
}

export function getEnabledSubscriptionDetails(arr: string[]): Idetails[] {
  const detailsObj: IPlanDetails = {
    MarketInsights: {value: 'Market insights for your industry', enabled: false},
    PopularQuestion: {value: 'Access to popular questions', enabled: false},
    EmailSupport: {value: 'Premium support', enabled: false},
    UnlimitedCredits: {value: 'Buy unlimited credits', enabled: false},
    PlagiarismChecker: {value: 'Plagiarism checker', enabled: false},
    ContentTone: {value: 'Content tone', enabled: false},
    OnlineEditor: {value: 'Online editor', enabled: false},
  };
  const detailsArr: Idetails[] = [];
  Object.entries(detailsObj).map(([key, val]) => {
    if (arr.includes(key)) detailsObj[key]['enabled'] = true;
    detailsArr.push(val);
  });
  return detailsArr;
}

export function logInHandle(instance: IPublicClientApplication): Promise<void> {
  return instance.loginRedirect({
    scopes: ['openid', MSAL_SCOPE],
    prompt: 'select_account',
  });
}

export function getPaymentSystemIcon(type: string): string {
  switch (type) {
    case 'visa':
      return VisaIcon;
    default:
      return MastercardIcon;
  }
}

export function getPlanIconByName(name?: string): string {
  switch (name) {
    case 'Silver Plan':
      return CreditCountIconSilver;
    case 'Silver Plan Plus':
      return CreditCountIconSilverPlus;
    case 'Gold Plan':
      return CreditCountIconGold;
    default:
      return CreditCountIconSilver;
  }
}

export function getIconSrc(plan: IUserCurrentSubscription | undefined | null): string {
  if (plan) {
    return getPlanIconByName(plan.name);
  } else {
    return CreditCountIconSilver;
  }
}

export function setPlanImage(name: string): string {
  switch (name) {
    case 'Silver Plan':
      return imgPathSilver;
    case 'Gold Plan':
      return imgPathGold;
    case 'Diamond Plan':
      return imgPathDiamond;
    case 'Student Plan':
      return imgPathStudent;
    default:
      return '';
  }
}

export function standardDateFormat(date: string | undefined): string {
  if (!date) return '';
  return moment(date).format('MMM D, YYYY');
}

export function convertBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      if (fileReader && fileReader.result) {
        if (typeof fileReader.result === 'string') {
          resolve(fileReader.result.split(',').splice(1).join());
        }
      }
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function wordsCounter(str: string): number {
  return str ? str.trim().split(/\s+/).length : 0;
}

export function handleDownloadPdf(item: HTMLDivElement, title: string, preview?: boolean): void {
  const container = !preview
    ? (item.querySelector('.rich-text-editor') as HTMLDivElement)
    : item.querySelector('.ql-container');
  const parentContainer = item.querySelector('.rich-text-editor') as HTMLDivElement;
  const cloneContainer = parentContainer.cloneNode(true) as HTMLDivElement;
  const cloneChildContainer = container?.cloneNode(true) as HTMLDivElement;

  cloneChildContainer.style.border = 'none';
  cloneContainer.firstChild?.remove();
  cloneContainer.appendChild(cloneChildContainer);

  const content = !preview
    ? (container?.cloneNode(true) as HTMLDivElement)
    : (cloneContainer?.cloneNode(true) as HTMLDivElement);
  const doc = new htmlToPdf();
  void doc.html(content, {
    callback: function (doc) {
      // !preview ? doc.save(`${title}.pdf`) : window.open(doc.output('bloburl'));
      doc.save(`${title}.pdf`);
      content.remove();
    },
    x: 30,
    width: 150,
    windowWidth: 725,
    autoPaging: 'text',
    html2canvas: {
      removeContainer: true,
    },
    margin: [15, 0, 15, 0],
  });
}

export function handleDownloadHTML(htmlString: string, fileName: string): void {
  const file = new File([htmlString], `${fileName}.html`, {
    type: 'text/plain',
  });
  const link = document.createElement('a');
  const url = URL.createObjectURL(file);

  link.href = url;
  link.download = file.name;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
  return;
}

export function handleDownloadMarkdown(item: HTMLDivElement, fileName: string): void {
  const turndownService = new TurndownService();
  const container = item.querySelector('.rich-text-editor') as HTMLDivElement;
  const content = container?.cloneNode(true) as HTMLDivElement;
  const markdown = turndownService.turndown(content.firstChild as HTMLDivElement);
  const file = new File([markdown], `${fileName}.md`, {
    type: 'text/plain',
  });
  const link = document.createElement('a');
  const url = URL.createObjectURL(file);

  link.href = url;
  link.download = file.name;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
  return;
}

export function containsSpecificStrings(arr: ITemplateBlock[]): boolean {
  const specificStrings = ['number-input', 'single-select', 'multi-select', 'text-input'];
  for (const element of arr) {
    if (specificStrings.includes(element.type)) {
      return true;
    }
  }
  return false;
}
