import {FormControlLabel, Switch} from '@material-ui/core';

interface IProps {
  setShowPreview: (result: boolean) => void;
  showPreview: boolean;
}
const ShowPreviewButton = (props: IProps): JSX.Element => {
  const {setShowPreview, showPreview} = props;

  return (
    <FormControlLabel
      value="start"
      control={
        <Switch
          color="primary"
          checked={showPreview}
          onChange={() => setShowPreview(!showPreview)}
        />
      }
      label="Show Preview"
      labelPlacement="start"
    />
  );
};

export default ShowPreviewButton;
