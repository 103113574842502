import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {IGetCommandResponse, IState} from '../../../../interfaces';
import moment from 'moment';
import {NavLink, useNavigate} from 'react-router-dom';
import {ReactComponent as CloneIcon} from '../../../../images/clone-icon.svg';
import {ReactComponent as Publishcon} from '../../../../images/publish-button-icon.svg';
import {ReactComponent as Unpublishcon} from '../../../../images/unpublish-button-icon.svg';
import {ReactComponent as OpenMenuIcon} from '../../../../images/openMenu.svg';
import {ReactComponent as AskQuestionIcon} from '../../../../images/askQuestion-large.svg';
import {Button, IconButton, Menu, MenuItem} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  deleteCommandRequest,
  patchPublishCommandRequest,
  postCommandCreateRequest,
} from '../../../../actions/commandsManagement';
import {containsSpecificStrings} from '../../../../helpers/helper';
import ModalWithImage from '../../../UI/ModalWithImage';

interface IProps {
  item: IGetCommandResponse;
  index: number;
}

const TableRowItem = (props: IProps): JSX.Element => {
  const {item, index} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const commandCreateState = useSelector(
    (state: IState) => state.commandsManagement.commandCreateResponse,
  );
  const status = item.isPublished ? 'published' : 'draft';
  const type = item.isSilent ? 'silent' : 'not silent';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);

  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (commandCreateState?.id) {
      navigate(`/admin/commands-management/details/${commandCreateState?.id}`);
    }
  }, [commandCreateState]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(!openMenu);
  };

  function onCommandClone(item: IGetCommandResponse) {
    const data = {
      name: `Clone of ${item.name}`,
      data: item.data,
      isSilent: !containsSpecificStrings(item.data),
    };
    item.id && dispatch(postCommandCreateRequest(data));
    //setShowClonePopup(false);
  }

  function handleDeleteDialog() {
    setShowDeletePopup(true);
  }

  function onCommandDelete() {
    item.id && dispatch(deleteCommandRequest({id: item.id}));
    setShowDeletePopup(false);
  }

  function onCommandPublish() {
    if (item.isPublished) {
      item.id && dispatch(patchPublishCommandRequest({id: item.id, isPublished: false}));
    } else {
      item.id && dispatch(patchPublishCommandRequest({id: item.id, isPublished: true}));
    }
  }

  const menuId = 'product-menu';
  const renderMenu = (
    <>
      <Menu
        anchorEl={anchorEl}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem>
          <NavLink to={`/admin/commands-management/details/${item.id}`}>
            <div className="coupon__menu-items">
              <EditOutlinedIcon />
              Edit
            </div>
          </NavLink>
        </MenuItem>

        <MenuItem onClick={() => onCommandClone(item)}>
          <div className="coupon__menu-items">
            <CloneIcon />
            Clone
          </div>
        </MenuItem>

        <MenuItem onClick={handleDeleteDialog}>
          <div className="coupon__menu-items">
            <DeleteIcon />
            <span>Delete</span>
          </div>
        </MenuItem>
        <MenuItem onClick={onCommandPublish}>
          <div className="coupon__menu-items">
            {!item.isPublished ? (
              <>
                <Publishcon />
                <span>Publish</span>
              </>
            ) : (
              <>
                <Unpublishcon />
                <span>Unpublish</span>
              </>
            )}
          </div>
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <TableRow hover key={index} className="table-row">
        <TableCell
          component="th"
          scope="row"
          className="user-name"
          onClick={() => navigate(`/admin/commands-management/details/${item.id}`)}
        >
          {item.name}
        </TableCell>
        <TableCell
          className={`command-table__cell status`}
          onClick={() => navigate(`/admin/commands-management/details/${item.id}`)}
        >
          <span className={`${status}`}>{status}</span>
        </TableCell>
        <TableCell
          className="command-table__cell type"
          onClick={() => navigate(`/admin/commands-management/details/${item.id}`)}
        >
          <span className={item.isSilent ? 'silent' : 'not-silent'}>{type}</span>
        </TableCell>
        <TableCell onClick={() => navigate(`/admin/commands-management/details/${item.id}`)}>
          {item.updatedUtc ? moment(item.updatedUtc).format('MMM DD, YYYY') : '-'}
        </TableCell>
        <TableCell onClick={() => navigate(`/admin/commands-management/details/${item.id}`)}>
          {item.createdUtc ? moment(item.createdUtc).format('MMM DD, YYYY') : '-'}
        </TableCell>
        <TableCell>
          <IconButton onClick={(e) => handleMenuOpen(e)}>
            <OpenMenuIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {renderMenu}
      {/*<AlertDialog
        isDialogOpen={showDeletePopup}
        setDialogOpen={setShowDeletePopup}
        text={{
          question: 'Delete Command?',
          warning: `Are you sure you want to delete ${item.name} Template?`,
          action: 'Yes, delete',
        }}
        confirmAction={onCommandDelete}
      />*/}
      <ModalWithImage
        isOpen={showDeletePopup}
        topImage={<AskQuestionIcon />}
        title={'Delete Command?'}
        subtitle={`Are you sure you want to delete ${item.name} Command?`}
        buttons={
          <>
            <Button className="orange-btn orange-btn-hover" onClick={() => onCommandDelete()}>
              Yes, delete
            </Button>

            <Button
              className="white-btn white-btn-hover"
              onClick={() => setShowDeletePopup(!showDeletePopup)}
            >
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};

export default TableRowItem;
