import React, {useEffect} from 'react';
import {ReactComponent as DeleteAccountSuccessIcon} from '../../images/delete-account-success.svg';
import {Button} from '@material-ui/core';
import './style.scss';

const DeleteSuccess = (): JSX.Element => {
  useEffect(() => {
    localStorage.clear();
  }, []);
  function handleExit() {
    window.history.pushState({}, '', '/home');
    window.location.reload();
  }

  return (
    <div className="deleteSuccess">
      <div className="deleteSuccess__content">
        <DeleteAccountSuccessIcon className="deleteSuccess__top-icon" />
        <h5 className="deleteSuccess__title">Your account was deleted.</h5>
        <p>We are sorry to see you go and would love to know why you decided to leave.</p>
        <p>Please take this very brief survey if you’d like to tell us how we can improve.</p>
        <p>We hope you come back soon.</p>
        <div className="deleteSuccess__btn-wr">
          <Button
            className="modal__action-buttons--white modal__action-buttons white-btn-hover"
            onClick={handleExit}
          >
            Okay
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteSuccess;
