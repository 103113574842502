// USER MANAGEMENT - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as userManagementActionType from './types/types';

export const getUserManagementRequest = createAction(
  userManagementActionType.GET_USER_MANAGEMENT_REQUEST,
);
export const getUserManagementSuccess = createAction(
  userManagementActionType.GET_USER_MANAGEMENT_SUCCESS,
);
export const getUserManagementFailure = createAction(
  userManagementActionType.GET_USER_MANAGEMENT_FAILURE,
);

export const getAllUserManagementRequest = createAction(
  userManagementActionType.GET_ALL_USER_MANAGEMENT_REQUEST,
);
export const getAllUserManagementSuccess = createAction(
  userManagementActionType.GET_ALL_USER_MANAGEMENT_SUCCESS,
);
export const getAllUserManagementFailure = createAction(
  userManagementActionType.GET_ALL_USER_MANAGEMENT_FAILURE,
);
