import {Grid} from '@material-ui/core';
import BackButton from './../../UI/BackButton';
import QaATemplateImg from '../../../images/qAaTemplate.svg';
import './style.scss';
import {useEffect} from 'react';

export const UseQATemplate = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container className="info-page">
        <Grid item xs={11} md={11} lg={10}>
          <div className="info-page__wr">
            {/*{history.length > 1 && <BackButton match={history} prevPage />}*/}
            <BackButton prevPage />
            <Grid container>
              <Grid item xs={12} md={9} lg={8}>
                <h1 className="info-page__title">How to Use the Question and Answer Template</h1>

                <div className="info-page__content">
                  <img src={QaATemplateImg} alt="Question and Answer Template illustration" />
                  <div className="content-item">
                    <p>
                      The Question and Answer template will allow you to generate an answer to any
                      question that interests you. You will get a short answer, which you can post
                      on forums or social media. The AI will also generate a detailed answer that
                      you can use as a blog article or for research. There is also an optional
                      feature to generate extended information. Use it to get more details about a
                      selected topic.
                    </p>
                    <p>
                      To get your answer, go to <b>Create Content → General</b>, and choose the{' '}
                      <b>Question and Answer</b> template.
                    </p>
                    <p>
                      Then, enter your <b>question</b>. Please be specific. Click <b>Generate</b>,
                      and the system will provide you with a Short Answer. If the provided option
                      isn’t satisfactory, click <b>Regenerate</b>, and the AI will create an
                      alternative. Please note that you can choose between using the GPT 3.5 and GPT
                      4 versions and adjust the Creativity level to influence the results AI will
                      provide.
                    </p>
                    <p>
                      In addition, you can use the AI Assistant to give the machine some specific
                      commands, such as shortening or expanding the text in a selected area.
                    </p>
                    <p>
                      Note that the AI will expand your selected answer by adding more detailed
                      information during the next step.
                    </p>
                    <p>
                      Once you are done, click <b>Finish</b>.
                    </p>
                    <p>You can share the content via a link.</p>
                    <p>
                      The Marquètte team would kindly ask you to rate the content generated by the
                      AI by clicking an appropriate smiley face. Your reactions help us improve!
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default UseQATemplate;
