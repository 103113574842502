import {GET, handleBody, handleExceptions, POST, PUT, PATCH, DELETE} from './request';
import {
  ICommandPublishRequest,
  ICreateCommandRequest,
  IGetCommandsRequestPayload,
  IUpdateCommandRequest,
} from '../interfaces';
import {createArrayEndpoint} from '../helpers/helper';

export const getCommandsList = (payload: IGetCommandsRequestPayload): Promise<Object | null> => {
  const endpoint: string = createArrayEndpoint('TextEditor', payload);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getCommandItem = (payload: {id: string}): Promise<Object | null> => {
  const endpoint: string = `/TextEditor/id?id=${payload.id}`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
export const createCommand = (payload: ICreateCommandRequest): Promise<Object | null> => {
  const endpoint: string = `/TextEditor`;
  const options = {
    body: payload,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const updateCommand = (payload: IUpdateCommandRequest): Promise<Object | null> => {
  const endpoint: string = `/TextEditor/id`;
  const options = {
    body: payload,
  };

  return PUT(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const publishCommand = (payload: ICommandPublishRequest): Promise<Object | null> => {
  const endpoint: string = `/TextEditor/ChangeState`;
  const options = {
    body: payload,
  };

  return PATCH(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const deleteCommand = (payload: {id: string}): Promise<Object | null> => {
  const endpoint: string = `/TextEditor/${payload.id}`;

  return DELETE(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
