import React from 'react';
import {NavLink} from 'react-router-dom';
import {Drawer, List, ListItem, ListItemText} from '@material-ui/core';
import './style.scss';
import contentIcon from '../../images/content-icon.svg';
import homeIcon from '../../images/home.svg';
import collectionsIcon from '../../images/collections-icon.svg';
import helpcenter from '../../images/helpcenter.svg';
import archiveIcon from '../../images/archive-icon.svg';
import * as H from 'history';
import {useActiveUser} from '../../helpers/customHooks';
import {SubscriptionStatus} from '../../enums/SubscriptionStatuses';

interface ISidebarProps {
  url: string;
  location: H.Location;
  sidebarSettings?: {openOnPage: string[]};
  handleMenu?: () => void;
}

const SideBar = (props: ISidebarProps): JSX.Element => {
  const {handleMenu} = props;
  const {isExpired, isActiveUser, currentStatus, isSubscribed} = useActiveUser();

  return (
    <Drawer variant="permanent" anchor="left" open={false} className="drawer">
      <List>
        {(isExpired || isActiveUser) && (
          <>
            <ListItem
              button
              key={'Dashboard'}
              className="list-item custom-touch"
              onClick={() => (handleMenu ? handleMenu() : ``)}
            >
              <NavLink className="list-item__link" to={`/main/dashboard`}>
                <div className="list-item__wr">
                  <img src={homeIcon} alt="Item icon" />
                  <ListItemText primary={'Dashboard'} />
                </div>
              </NavLink>
            </ListItem>
            {currentStatus !== 'TrialExpired' && (
              <ListItem
                button
                key={'My Content'}
                className="list-item custom-touch"
                onClick={() => (handleMenu ? handleMenu() : ``)}
              >
                <NavLink className="list-item__link" to={`/main/my-Content`}>
                  <div className="list-item__wr">
                    <img src={contentIcon} alt="Item icon" />
                    <ListItemText primary={'My Content'} />
                  </div>
                </NavLink>
              </ListItem>
            )}
          </>
        )}
        {(isSubscribed || currentStatus === SubscriptionStatus.PaidExpired) && (
          <ListItem
            button
            key={'Favorites'}
            className="list-item custom-touch"
            onClick={() => (handleMenu ? handleMenu() : ``)}
          >
            <NavLink className="list-item__link" to={`/main/favorites`}>
              <div className="list-item__wr">
                <img src={collectionsIcon} alt="Item icon" />
                <ListItemText primary={'Favorites'} />
              </div>
            </NavLink>
          </ListItem>
        )}
        {(isSubscribed || currentStatus === SubscriptionStatus.PaidExpired) && (
          <ListItem
            button
            key={'Archive'}
            className="list-item custom-touch"
            onClick={() => (handleMenu ? handleMenu() : ``)}
          >
            <NavLink className="list-item__link" to={`/main/archive`}>
              <div className="list-item__wr">
                <img src={archiveIcon} alt="Archive icon" />
                <ListItemText primary={'Archive'} />
              </div>
            </NavLink>
          </ListItem>
        )}
        {!isExpired && isActiveUser && (
          <ListItem
            button
            key={'Help center'}
            className="list-item custom-touch"
            onClick={() => (handleMenu ? handleMenu() : ``)}
          >
            <NavLink className="list-item__link" to={`/help-center`}>
              <div className="list-item__wr">
                <img src={helpcenter} alt="Item icon" />
                <ListItemText primary={'Help center'} />
              </div>
            </NavLink>
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default SideBar;
