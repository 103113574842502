import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  SET_SIGNALR_STEP_REQUEST,
  SET_SIGNALR_STEP_SUCCESS,
  SET_SIGNALR_STEP_FAILURE,
} from '../actions/types/types';
import {ISignalRAction, ISignalRStepState} from '../interfaces';

export const initialState: ISignalRStepState = {
  status: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [SET_SIGNALR_STEP_REQUEST]: (state: ISignalRStepState, action: ISignalRAction) => ({
    ...state,
    status: action.payload,
    loading: false,
    error: '',
  }),
  [SET_SIGNALR_STEP_SUCCESS]: (state: ISignalRStepState, action: ISignalRAction) => ({
    ...state,
    status: action.payload,
    loading: false,
    error: '',
  }),
  [SET_SIGNALR_STEP_FAILURE]: (state: ISignalRStepState) => ({
    ...state,
    loading: false,
    error: '',
  }),
};

export default handleActions(handlerMap, initialState);
