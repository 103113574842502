import React, {useEffect, useState} from 'react';
import EntriesTable, {ITableData} from './EntriesTable';
import '../UserManagement/style.scss';
import {useDispatch, useSelector} from 'react-redux';
import LoadingSpinner from '../UI/LoadingSpinner';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import {Button, IconButton} from '@material-ui/core';
import {IState, ITextModelCategoriesItem, ITextModelEntriesPayload} from '../../interfaces';
import {
  getTextModelCategoriesRequest,
  getTextModelEntriesRequest,
  setClearNavigateToEntry,
  setModelEntryClear,
} from '../../actions/textModel';
import {useNavigate, useSearchParams} from 'react-router-dom';
import SingleSelectTextfield from '../UI/SingleSelectTextfield';
import {ReactComponent as Nothing} from '../../images/nothing.svg';
import MultipleSelectorAll, {IItem} from '../UI/MultipleSelectorAll';
import Search from '../Search';

type Order = 'asc' | 'desc';
export interface ICategoryArray extends IItem {
  id: string;
  name: string;
}
export interface IFilterArray {
  categories: ICategoryArray[];
}
type Params = {
  category?: string[];
  status?: string;
};

const TemplateManagement = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const initialCategoryFilters = searchParams.getAll('category');
  const initialStatus: string | null = searchParams.get('status');

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ITableData>('name');
  const [selectedStatus, setSelectedStatus] = useState<string>(initialStatus || 'All');
  const [categoriesArray, setCategoriesArray] = useState<ICategoryArray[] | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<ICategoryArray[]>([]);
  const [filtersUpdated, setFiltersUpdated] = useState<Date | null>(null);
  const [isFiltersApplied, setFiltersApplied] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string | null>('');
  const mockTemplatesStatus = [
    {id: 'Draft', name: 'Draft'},
    {id: 'Published', name: 'Published'},
  ];
  const [filterArray, setFilterArray] = useState<ICategoryArray[]>([]);

  const textModels = useSelector((state: IState) => state.textModel);

  useEffect(() => {
    if (!textModels.textModelCategories) {
      dispatch(getTextModelCategoriesRequest());
    }
  }, []);
  useEffect(() => {
    if (textModels.textModelCategories?.items) {
      const updatedArray = textModels.textModelCategories?.items.map(
        (item: ITextModelCategoriesItem) => ({
          id: item.id,
          name: item.name,
        }),
      );
      setCategoriesArray(updatedArray);
      if (initialCategoryFilters.length) {
        const mappedArray: ICategoryArray[] = [];
        initialCategoryFilters.map((item) => {
          for (const i of updatedArray) {
            i.name === item && mappedArray.push({id: i.id, name: item}); // Example mapping function
          }
        });
        mappedArray.length > 0 && setSelectedCategory(mappedArray);
      }
    }
  }, [textModels.textModelCategories?.items]);

  function loadTemplateEntriesData(pageNumber: number) {
    const categoriesArray = selectedCategory.map((item: ICategoryArray) => item.id.toString());
    const categoriesForUrl = selectedCategory.map((item: ICategoryArray) => item.name);

    const params: Params = {};
    if (categoriesForUrl.length > 0) {
      params.category = categoriesForUrl;
    } else {
      delete params.category;
    }

    if (selectedStatus !== 'All') {
      params.status = selectedStatus;
    } else {
      delete params.status;
    }
    setSearchParams(params);
    const sortOrder = order === 'asc' ? 'Ascending' : 'Descending';
    const templateEntriesPayload: ITextModelEntriesPayload = {
      categoryIds: categoriesArray,
      state: selectedStatus,
      sortOrder: sortOrder,
      pageNumber: pageNumber,
      pageSize: rowsPerPage,
      sortingProperty: orderBy,
      templateName: searchText,
    };
    dispatch(getTextModelEntriesRequest(templateEntriesPayload));
  }

  useEffect(() => {
    if (!textModels.loading && categoriesArray) {
      loadTemplateEntriesData(page + 1);
    }
    setTimeout(() => {
      dispatch(setClearNavigateToEntry());
    }, 200);
  }, [
    page,
    filtersUpdated,
    categoriesArray,
    textModels.isEntryDeleted,
    textModels.publishEntryResult,
    textModels.entryUpdateResult,
  ]);

  useEffect(() => {
    setPage(0);
    setFiltersUpdated(new Date());
  }, [selectedCategory, selectedStatus, rowsPerPage, order, orderBy]);

  function handleFiltering(templateStatus: string | number) {
    if (templateStatus === 0) {
      setSelectedStatus('All');
    } else {
      setSelectedStatus(templateStatus.toString());
    }
  }

  useEffect(() => {
    if (selectedCategory.length > 0) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }
    selectedCategory.length === categoriesArray?.length && setSelectedCategory([]);
  }, [selectedCategory.length]);

  useEffect(() => {
    setFilterArray(selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      handleClearFilters();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  function handleClearFilters() {
    setSelectedCategory([]);
    setSelectedStatus('All');
    setSearchText('');
  }

  function newTemplate() {
    dispatch(setModelEntryClear());
    navigate('/admin/template-management/details');
  }
  const handleSearch = () => {
    if (!textModels.loading && categoriesArray) {
      loadTemplateEntriesData(page + 1);
    }
  };

  function handleDeleteFIlter(index: number) {
    const newCategoriesArray = [...filterArray];
    newCategoriesArray.splice(index, 1);
    setFilterArray(newCategoriesArray);
    setSelectedCategory(newCategoriesArray);
  }

  return (
    <>
      <div className="user-management">
        <div className="user-management__title-wr">
          <h2 className="user-management__title">Template management</h2>
          <Button className="orange-btn orange-btn-hover" onClick={() => newTemplate()}>
            New Template
          </Button>
        </div>
        <div className="user-management__filters-wr template-management__filters-wr">
          <div className="user-management__dropdowns-wr template-management__dropdowns-wr">
            <div className="template-management__selectors">
              {textModels.textModelCategories?.items && categoriesArray && (
                <div className="single-filter-wr template-management__single-filter-wr">
                  <div className="template-management__multiselect">
                    <p className="single-filter-wr__label">Categories</p>
                    <MultipleSelectorAll
                      itemsList={categoriesArray}
                      setSelectedItems={setSelectedCategory}
                      selectedItems={selectedCategory}
                      itemName={'category'}
                    />
                  </div>
                  {isFiltersApplied && (
                    <div className="content__filters">
                      {filterArray.map((value: ICategoryArray, index: number) => (
                        <div className="div" key={index}>
                          <span className="content__filters-filter">{value.name}</span>
                          <IconButton onClick={() => handleDeleteFIlter(index)}>
                            <CloseIcon className="content__filters-close-icon" />
                          </IconButton>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              <SingleSelectTextfield
                inputLabel={'Templates status'}
                itemsList={mockTemplatesStatus}
                updateFunction={(val: string) => handleFiltering(val)}
                isDefaultValue={true}
                defaultValueName={'All'}
                className="user-management__filter-item template-management"
                selectedValue={selectedStatus}
              />
              <IconButton
                className="refresh-btn"
                aria-label="refresh filters"
                component="span"
                disabled={
                  selectedCategory.length === 0 &&
                  selectedStatus === 'All' &&
                  searchText?.length === 0
                }
                onClick={handleClearFilters}
              >
                <AutorenewIcon />
              </IconButton>
            </div>
            <Search
              disabled={false}
              searchFunction={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
              clearFilter={handleClearFilters}
            />
          </div>
        </div>

        {textModels.textModelEntries ? (
          <EntriesTable
            templates={textModels.textModelEntries.items}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={textModels.textModelEntries.totalCount}
            isLoading={textModels.loading}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            categories={textModels.textModelCategories?.items}
          />
        ) : (
          <span className="user-management__spinner-wr">
            <LoadingSpinner />
          </span>
        )}
      </div>
      {textModels.textModelEntries?.totalCount === 0 && (
        <div className="coupons-management__nothing">
          <span>
            <Nothing />
          </span>
          <div className="coupons-management__nothing-text">
            There is no filter result based on your filter query.
          </div>
        </div>
      )}
    </>
  );
};

export default TemplateManagement;
