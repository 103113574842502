// FAVORITES - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as favoritesActionType from './types/types';

export const getStatusRequest = createAction(favoritesActionType.GET_FAVORITES_REQUEST);
export const getStatusSuccess = createAction(favoritesActionType.GET_FAVORITES_SUCCESS);
export const getStatusFailure = createAction(favoritesActionType.GET_FAVORITES_FAILURE);

export const putStatusRequest = createAction(favoritesActionType.PUT_FAVORITES_REQUEST);
export const putStatusSuccess = createAction(favoritesActionType.PUT_FAVORITES_SUCCESS);
export const putStatusFailure = createAction(favoritesActionType.PUT_FAVORITES_FAILURE);

export const deleteStatusRequest = createAction(favoritesActionType.DELETE_FAVORITES_REQUEST);
export const deleteStatusSuccess = createAction(favoritesActionType.DELETE_FAVORITES_SUCCESS);
export const deleteStatusFailure = createAction(favoritesActionType.DELETE_FAVORITES_FAILURE);
