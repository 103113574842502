import {CallEffect, ForkEffect, PutEffect, delay, put, takeEvery} from 'redux-saga/effects';
import {Action} from 'redux-actions';
import {v4 as uuidv4} from 'uuid';
import {SHOW_ALERT} from '../actions/types/types';
import {addAlert, removeAlert} from '../actions/alert';
import {IAlertAction} from '../interfaces/alerts';

export function* showAlertModel(
  action: IAlertAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const id: string = uuidv4();
    yield put(
      addAlert({
        ...action.payload,
        id,
      }),
    );
    yield delay(2000);
    yield put(removeAlert({alertId: id}));
  } catch (error) {
    console.error('Show Alert failed: ', error);
  }
}

// WATCHERS
export function* watchAddAlert(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(SHOW_ALERT, showAlertModel);
}
