export enum IconType {
  HEART_BORDER = 'heart-border',
  MORE_DOTS = 'more-dots',
  HEART_FILLED = 'heart-filled',
  SHARE_ICON = 'share-icon',
  DOWNLOAD_ICON = 'download-icon',
  DOWNLOAD_ICON_SVG = 'download-icon-svg',
  EXPAND_MORE = 'expand-more',
  EXPAND_LESS = 'expand-less',
  PLUS_FILLED = 'plus-filled',
  MINUS_FILLED = 'minus-filled',
  CALENDAR = 'calendar',
  FILTER_LIST = 'filter-list',
  REPLAY = 'replay',
}

export enum SelectorName {
  AREAS = 'Areas',
  INDUSTRIES = 'Industries',
  INDUSTRIES_MOBILE = 'Filter by Industries',
  MEDIA = 'Media',
  TYPES = 'Types',
  FILTER = 'Filter by type',
}

export enum SortOption {
  NEWEST = 'The newest',
  RELEVANT = 'Most relevant',
}

export enum ArticleDateVariant {
  DATE_IN = 'date-in',
  DATE_AFTER = 'date-after',
}

export enum UIStatus {
  New = 'New',
  Declined = 'Rejected',
  RequireDetails = 'Requires clarification',
  ReadyForAnalysis = 'Processing',
  Analysed = 'Processing',
  Summarized = 'Processing',
  Answered = 'Answer is ready',
}

export enum HistoryShortStatus {
  'New' = 100,
  'Rejected' = 200,
  'Requires clarification' = 300,
  'Processing' = 400,
  'Answer is ready' = 500,
  'Updated answer' = 600,
  'No update' = 700,
}

export enum HisoryFullStatuses {
  'Question draft was created' = 100,
  'New question was created' = 200,
  'Question was declined' = 300,
  'Question requires details' = 400,
  'Question is ready for analysis' = 500,
  'Question was analysed' = 600,
  'Question summary was created' = 700,
  'Question answer is ready' = 800,
  'Question was archived' = 900,
}

export enum DeclineCodes {
  card_declined = 'Declined payment',
  expired_card = 'Expired card',
  insufficient_funds = 'Insufficient funds',
  authentication_required = 'Authentication required',
  approve_with_id = 'Payment is not authorized',
  call_issuer = 'Unknown reason',
  card_not_supported = 'Purchase is not supported',
  card_velocity_exceeded = 'Exceeded the balance or credit limit',
  currency_not_supported = 'Currency is not supported',
  do_not_honor = 'Unknown reason',
  do_not_try_again = 'Unknown reason',
  duplicate_transaction = 'Duplicated transaction',
  fraudulent = 'Fraudulent suspected',
  generic_decline = 'Generic decline',
  incorrect_number = 'Incorrect number',
  incorrect_cvc = 'Incorrect CVC',
  incorrect_pin = 'Incorrect PIN',
  incorrect_zip = 'Incorrect ZIP',
  invalid_account = 'Invalid account',
  invalid_amount = 'Invalid amount',
  invalid_cvc = 'Incorrect CVC',
  invalid_expiry_month = 'Invalid expiry month',
  invalid_expiry_year = 'Invalid expiry month',
  invalid_number = 'Incorrect number',
  invalid_pin = 'Incorrect PIN',
  issuer_not_available = 'Issuer is not available',
  lost_card = 'Lost card',
  merchant_blacklist = 'Merchant blacklist',
  new_account_information_available = 'New account information available',
  no_action_taken = 'Unknown reason',
  not_permitted = 'Payment isn’t permitted',
  offline_pin_required = 'PIN required',
  online_or_offline_pin_required = 'PIN required',
  pickup_card = 'Lost card',
  pin_try_exceeded = 'Number of PIN tries exceeded',
  processing_error = 'Processing error',
  reenter_transaction = 'Reenter transaction',
  restricted_card = 'Restricted card',
  revocation_of_all_authorizations = 'Revocation of authorization',
  revocation_of_authorization = 'Revocation of authorization',
  security_violation = 'Security violation',
  service_not_allowed = 'Service not allowed',
  stolen_card = 'Stolen card',
  stop_payment_order = 'Stop payment order',
  testmode_decline = 'Testmode decline',
  transaction_not_allowed = 'Transaction is not allowed',
  try_again_later = 'Try again later',
  withdrawal_count_limit_exceeded = 'Withdrawal count limit exceeded',
}

export enum BackgroundColors {
  VIOLET = 'violet',
  LAVANDER = 'lavander',
  YELLOW = 'yellow',
  ORANGE = 'orange',
}
