import React from 'react';
import TotalWords from '../TotalWords';
import UsedTemplates from '../UsedTemplates';
import GeneratedContent from '../GeneratedContent';
import ContentCategories from '../ContentCategories';
import {ReactComponent as TotalWordsIcon} from '../../../images/total-words-icon.svg';
import {ReactComponent as AverageWordsIcon} from '../../../images/average-words-icon.svg';
import {ReactComponent as TodaysWordsIcon} from '../../../images/todays-words-icon.svg';

const EmptyState = (): JSX.Element => {
  return (
    <>
      <div className="dashboard__grid">
        <div className="dashboard__grid-total dashboard__info">
          <TotalWordsIcon className="dashboard__icon" />
          <div className="dashboard__count">
            <div className="dashboard__count-title">0 words</div>
            <div className="dashboard__count-descr">Total Content Generated</div>
          </div>
        </div>
        <div className="dashboard__grid-average dashboard__info">
          <AverageWordsIcon className="dashboard__icon" />
          <div className="dashboard__count">
            <div className="dashboard__count-title">0 words</div>
            <div className="dashboard__count-descr">Average Content Length</div>
          </div>
        </div>
        <div className="dashboard__grid-day dashboard__info">
          <TodaysWordsIcon className="dashboard__icon" />
          <div className="dashboard__count">
            <div className="dashboard__count-title">0 words</div>
            <div className="dashboard__count-descr">Today's Content Generated</div>
          </div>
        </div>
        <div className="dashboard__grid-words white">
          <TotalWords />
        </div>
        <div className="dashboard__grid-templates white">
          <UsedTemplates />
        </div>
        <div className="dashboard__grid-content white">
          <GeneratedContent />
        </div>
        <div className="dashboard__grid-categories white">
          <ContentCategories />
        </div>
      </div>
    </>
  );
};

export default EmptyState;
