import React, {useState, useEffect} from 'react';
import ChartOfCategories from '../../../images/chart-of-categories.svg';
import {PieChart, Pie, Cell} from 'recharts';
import {ITextModelStatistic} from '../../../interfaces';

interface IData {
  categoryName: string;
  count: number;
  color: string;
}

interface IProps {
  data?: ITextModelStatistic[] | null;
}

const ContentCategories = (props: IProps): JSX.Element => {
  const {data} = props;

  const COLORS = ['#EC7644', '#52E4A7', '#5C96F5', '#FFD84F'];
  const [topCategories, setTopCategories] = useState<IData[]>([]);
  const [total, setTotal] = useState<number>();

  useEffect(() => {
    const groupedData: Record<string, number> = {};
    data?.forEach((item) => {
      if (item.categoryName !== null) {
        const {categoryName} = item;
        if (!groupedData[categoryName]) {
          groupedData[categoryName] = 0;
        }
        groupedData[categoryName] += 1;
      }
    });
    const sortedCategories: IData[] = Object.keys(groupedData)
      .map((categoryName, index) => ({
        categoryName,
        count: groupedData[categoryName],
        color: COLORS[index % COLORS.length],
      }))
      .sort((a, b) => b.count - a.count);

    const topCategories = sortedCategories.slice(0, 4);
    setTopCategories(topCategories);
    let totalCount = 0;
    for (const obj of topCategories) {
      totalCount += obj.count;
    }
    setTotal(totalCount);
  }, [data]);

  function percentOfTotal(i: number) {
    if (total) {
      const percentCount = (i / total) * 100;
      return percentCount.toFixed(0);
    }
  }

  function className(i: string) {
    const className = i.substring(1);
    return `color-${className}`;
  }

  return (
    <>
      <div className="dashboard__subtitle">Content Categories</div>
      {topCategories.length > 0 ? (
        <>
          <PieChart width={158} height={158}>
            <text
              className="chart__label-count"
              x="50%"
              y={75}
              textAnchor="middle"
              dominantBaseline="middle"
            >
              {total}
            </text>
            <text
              className="chart__label-text"
              x="50%"
              y={95}
              textAnchor="middle"
              dominantBaseline="middle"
            >
              templates
            </text>
            <Pie
              data={topCategories}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="count"
            >
              {topCategories.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
          <div className="dashboard__chart-descr">
            {topCategories.map((item: IData, index: number) => (
              <div className="dashboard__categories" key={index}>
                <div className={`dashboard__categories-name ${className(item.color)}`}>
                  {item.categoryName}
                </div>
                <div className="dashboard__categories-count">
                  <span className="dashboard__categories-count-item">{item.count}</span>
                  {percentOfTotal(item.count)}%
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="dashboard__chart">
          <p className="dashboard__categories-count count">
            <span className="chart__label-count disabled">0</span>
            <span className="chart__label-text">templates</span>
          </p>
          <img src={ChartOfCategories} alt="chart of categories" />
          <div className="dashboard__chart-descr">
            <div className="dashboard__categories">
              <div className="dashboard__categories-name">General</div>
              <div className="dashboard__categories-count">
                <span className="dashboard__categories-count-empty">0</span>
                0%
              </div>
            </div>
            <div className="dashboard__categories">
              <div className="dashboard__categories-name">Educational</div>
              <div className="dashboard__categories-count">
                <span className="dashboard__categories-count-empty">0</span>
                0%
              </div>
            </div>
            <div className="dashboard__categories">
              <div className="dashboard__categories-name">Blog Posts</div>
              <div className="dashboard__categories-count">
                <span className="dashboard__categories-count-empty">0</span>
                0%
              </div>
            </div>
            <div className="dashboard__categories">
              <div className="dashboard__categories-name">Marketing</div>
              <div className="dashboard__categories-count">
                <span className="dashboard__categories-count-empty">0</span>
                0%
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContentCategories;
