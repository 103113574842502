import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as userProfile from '../api/userProfile';

// REDUX
import {Action} from 'redux-actions';
import * as userProfileAction from '../actions/userProfile';
import * as userProfileActionType from '../actions/types/types';
import {IUserProfile, IUserUpdateNameRequestAction} from '../interfaces';

export function* postUserUpdateSubscriptionRenewal(
  action: IUserUpdateNameRequestAction,
): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  IUserProfile
> {
  try {
    const payload = yield call(userProfile.setSubscriptionAutoRenewal, action.payload);
    const userPayload: IUserProfile = yield call(userProfile.getUserProfile);
    yield put(userProfileAction.postUserSubscriptionRenewalSuccess(payload));
    yield put(userProfileAction.getUserProfileSuccess(userPayload));
  } catch (error) {
    yield put(userProfileAction.postUserSubscriptionRenewalFailure(error));
    yield put(userProfileAction.getUserPhotoFailure(error));
  }
}

// WATCHERS
export function* watchUserUpdateSubscriptionRenewal(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    userProfileActionType.POST_USER_SUBSCRIPTION_RENEWAL_REQUEST,
    postUserUpdateSubscriptionRenewal,
  );
}
