import React from 'react';
import {LightTooltip} from '../../../../UI/StatusLabel';

import '../style.scss';

const DataPrivacy = (): JSX.Element => {
  return (
    <>
      <div className="DataPrivacy">
        <h2 className="SettingContent__title SettingSideBar__common-tit">Data Privacy</h2>
        <LightTooltip title="Functionality is coming soon">
          <h3 className="SettingContent__sub-title SettingSideBar__common-tit">
            How Devtorium uses your data
          </h3>
        </LightTooltip>
        <p className="SettingSideBar__common-bottom-line DataPrivacy__text">
          Manage how your data is used and download it anytime
        </p>
      </div>
    </>
  );
};

export default DataPrivacy;
