import {Link, useNavigate} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import './style.scss';
import ModalWithImage from '../UI/ModalWithImage';
import {ReactComponent as AskQuestionIcon} from '../../images/askQuestion-large.svg';
import {useState} from 'react';

interface IProps {
  title?: string;
  text?: string;
}

export const ExpiredPlan = (props: IProps): JSX.Element => {
  const {title, text} = props;
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState<boolean>(true);
  const handleCloseBtn = () => {
    navigate(`/subscription-plan`);
  };
  function handleCancel() {
    setOpen(false);
  }
  return (
    <div className="expired-plan">
      <ModalWithImage
        isOpen={isOpen}
        topImage={<AskQuestionIcon />}
        title={title ? title : 'Ouch! Your subscription plan expired!'}
        specificClass={'expired-plan__popup'}
        buttons={
          <>
            <Button
              className="modal__action-buttons--orange modal__action-buttons orange-btn orange-btn-hover"
              onClick={handleCloseBtn}
            >
              Update subscription
            </Button>
            <Button
              className="modal__action-buttons--white modal__action-buttons white-btn white-btn-hover"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </>
        }
      >
        <p>
          {text
            ? text
            : 'You’ve lost the ability to use all the best Marquètte features. But if you have any credits left, you can still ask questions until they run out'}
        </p>
        <p>
          To get full use of Marquètte again, update your subscription through the
          <Link to={'/account/profile'}>Account page</Link>
        </p>
        <p>
          Have any questions? <Link to={'/contact-us'}>Contact us.</Link>
        </p>
      </ModalWithImage>
    </div>
  );
};
export default ExpiredPlan;
