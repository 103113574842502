import moment from 'moment';
import './style.scss';
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  createStyles,
  ListItemIcon,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface IProps {
  couponName: string;
  productPlan: string | undefined;
  campaignName: string;
  internalNote: string;
  discountValue: string;
  discountDetailsIcon: boolean;
  discountPercetage: string;
  discountFixed: string;
  couponUsage: string | undefined;
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  couponStatus?: string;
}
const useStyles = makeStyles(() =>
  createStyles({
    border: {
      position: 'relative',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)',
      padding: '30px 30px',
      marginTop: '6px',
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        left: '0',
        height: '6px',
        width: '100%',
      },
      '&:before': {
        top: '-6px',
        background:
          'radial-gradient(circle, transparent, transparent 50%, #FFFFFF 50%, #FFFFFF 100% ) 4px -7px / 18px 14px repeat-x',
      },
      '&:after': {
        bottom: '-6px',
        background:
          'radial-gradient(circle, transparent, transparent 50%, #FFFFFF 50%, #FFFFFF 100% ) -7px -1px / 20px 14px repeat-x',
      },
    },
  }),
);

export const CreateCouponsSummary = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const {
    couponName,
    productPlan,
    campaignName,
    internalNote,
    discountValue,
    discountDetailsIcon,
    couponUsage,
    startDate,
    endDate,
    couponStatus,
  } = props;
  const visible = () => {
    return !(
      couponName ||
      productPlan ||
      campaignName ||
      internalNote ||
      discountValue ||
      couponUsage ||
      startDate ||
      endDate
    );
  };

  const startDateLook = () => {
    if (moment(startDate).format('MM/DD/YYYY') === moment(new Date()).format('MM/DD/YYYY')) {
      return 'Active from Today';
    } else {
      return `Active from ${moment(startDate).format('MM/DD/YYYY')}`;
    }
  };

  const endDateLook = () => {
    if (endDate === null || endDate === undefined) {
      return 'Never Expires';
    } else {
      return `Expires on ${moment(endDate).format('MM/DD/YYYY')}`;
    }
  };

  return (
    <div className={classes.border}>
      <div className="coupon-create__info">
        <h3 className="coupon-create__summary-title">Summary</h3>
        {visible() && (
          <span className="coupon-create__summary-note">
            Fill in the fileds to see Coupon summary.
          </span>
        )}
        {couponName && <div className="coupon-create__summary-name">{couponName}</div>}
        <div className="coupon-create__summary-campaign">{campaignName}</div>
        <div className="coupon-create__list">
          <List>
            {productPlan && (
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={`Applied to ${productPlan}`} />
              </ListItem>
            )}
            {discountValue && (
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                {discountDetailsIcon && (
                  <ListItemText primary={`$ ${discountValue} off entire order`} />
                )}
                {!discountDetailsIcon && (
                  <ListItemText primary={`${discountValue} % off entire order`} />
                )}
              </ListItem>
            )}
            {couponUsage && (
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                {+couponUsage > 0 && <ListItemText primary={`${couponUsage} uses`} />}
                {+couponUsage === 0 && <ListItemText primary={'Unlimited usage'} />}
              </ListItem>
            )}
            {couponName && (
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={startDateLook()} />
              </ListItem>
            )}
            {couponName && (
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={endDateLook()} />
              </ListItem>
            )}
          </List>
          {internalNote && <div className="coupon-create__summary-note">{internalNote}</div>}
        </div>
      </div>
      <div className="coupon-create__info coupon-create__status">
        <h3 className="coupon-create__summary-title">Status</h3>
        {couponStatus && <span className={couponStatus}>{couponStatus}</span>}
        {!couponStatus && (
          <span className="coupon-create__summary-note">Coupon is not active yet.</span>
        )}
      </div>
    </div>
  );
};

export default CreateCouponsSummary;
