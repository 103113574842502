import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IState, ITextModelStatistic} from '../../interfaces';
import {Banner} from './Banner';
import './style.scss';
import {useActiveUser} from '../../helpers/customHooks';
import ExpiredPlan from '../ExpiredPlan';
import SubscriptionExpired from './SubscriptionExpired';
//import {useNavigate} from 'react-router-dom';
//import useWindowDimensions from '../../helpers/windowResize';
import OutOfCredits from '../OutOfCredits';
import TotalWords from './TotalWords';
import UsedTemplates from './UsedTemplates';
import GeneratedContent from './GeneratedContent';
import ContentCategories from './ContentCategories';
import {ReactComponent as TotalWordsIcon} from '../../images/total-words-icon.svg';
import {ReactComponent as AverageWordsIcon} from '../../images/average-words-icon.svg';
import {ReactComponent as TodaysWordsIcon} from '../../images/todays-words-icon.svg';
import EmptyState from './EmptyState';
import * as textModels from '../../actions/textModel';

export interface ILineChartData {
  name: string;
  words: number;
}

const Dashboard = (): JSX.Element => {
  const dispatch = useDispatch();
  // const {userSubscriptionStatus, currentUserSubscription} = useSelector(
  //   (state: IState) => state.userProfile.userProfile,
  // );
  const user = useSelector((state: IState) => state.userProfile.userProfile);
  const content = useSelector((state: IState) => state.textModel);
  //const navigate = useNavigate();
  //const screenWidth = useWindowDimensions().width;

  const userStatus = useActiveUser();

  const [totalWords, setTotalWords] = useState<number>(0);
  const [averageWords, setAverageWords] = useState<number>(0);
  const [todayWords, setTodayWords] = useState<number>(0);

  useEffect(() => {
    dispatch(textModels.getTextModelsRequest({pageSize: 1000}));
    dispatch(textModels.getTextModelStatisticRequest());
    dispatch(textModels.getTextModelEntriesRequest({pageSize: 1000}));
  }, []);

  useEffect(() => {
    if (content.textModelStatistic) {
      const totalWords = content.textModelStatistic.reduce((total, item) => total + item.words, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayWords = content.textModelStatistic
        .filter((item: ITextModelStatistic) => new Date(item.createdDate) >= today)
        .reduce((total, item) => total + item.words, 0);
      const averageWords =
        content.textModelStatistic.length > 0 ? totalWords / content.textModelStatistic.length : 0;

      setTotalWords(totalWords);
      setAverageWords(averageWords);
      setTodayWords(todayWords);
    }
  }, [content.textModelStatistic]);

  const showContent = () => {
    if (content.textModelStatistic) {
      return content.textModelStatistic?.length > 0 && content.textModelEntries;
    }
  };

  const showOutOfCredits = user.creditCount === 0 && user.hadSubscription;

  return (
    <div className="dashboard">
      <h1 className="dashboard__title">Dashboard</h1>
      {userStatus.isExpired && !user.paymentError && <ExpiredPlan />}
      {user.paymentError && (
        <ExpiredPlan
          title="Ouch! We are unable to renew your subscription plan!"
          text="You’ve lost the ability to use all the best Marquètte features."
        />
      )}
      {showOutOfCredits && <OutOfCredits />}
      {userStatus.isExpired ? (
        <>
          <SubscriptionExpired />
          <div className="dashboard__video">
            <Banner />
          </div>
        </>
      ) : (
        <>
          {showContent() ? (
            <>
              <div className="dashboard__grid">
                <div className="dashboard__grid-total dashboard__info">
                  <TotalWordsIcon className="dashboard__icon" />
                  <div className="dashboard__count">
                    <div className="dashboard__count-title">{totalWords} words </div>
                    <div className="dashboard__count-descr">Total Content Generated</div>
                  </div>
                </div>
                <div className="dashboard__grid-average dashboard__info">
                  <AverageWordsIcon className="dashboard__icon" />
                  <div className="dashboard__count">
                    <div className="dashboard__count-title">{averageWords.toFixed(0)} words </div>
                    <div className="dashboard__count-descr">Average Content Length</div>
                  </div>
                </div>
                <div className="dashboard__grid-day dashboard__info">
                  <TodaysWordsIcon className="dashboard__icon" />
                  <div className="dashboard__count">
                    <div className="dashboard__count-title">{todayWords} words </div>
                    <div className="dashboard__count-descr">Today's Content Generated</div>
                  </div>
                </div>
                <div className="dashboard__grid-words white">
                  <TotalWords totalWords={totalWords} dataS={content.textModelStatistic} />
                </div>
                {content.textModelStatistic && content.textModelEntries && (
                  <div className="dashboard__grid-templates white">
                    <UsedTemplates
                      data={content.textModelStatistic}
                      entries={content.textModelEntries.items}
                    />
                  </div>
                )}
                <div className="dashboard__grid-content white">
                  <GeneratedContent data={content.textModels?.items} />
                </div>
                <div className="dashboard__grid-categories white">
                  <ContentCategories data={content.textModelStatistic} />
                </div>
              </div>
            </>
          ) : (
            <EmptyState />
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
