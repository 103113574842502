import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as credits from '../api/adminCredits';
import * as userManagementProfile from '../api/userManagementProfile';

// REDUX
import {Action} from 'redux-actions';
import * as creditsAction from '../actions/adminCredits';
import * as creditsActionType from '../actions/types/types';
import * as userManagementProfileAction from '../actions/userManagementProfile';
import {IAddAdminCreditsAction} from '../interfaces';

export function* buyCreditsAdmin(
  action: IAddAdminCreditsAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(credits.postAddCreditsAdmin, action.payload);
    yield put(creditsAction.postAddCreditsStatusSuccess(payload));
    const userPayload = yield call(userManagementProfile.getUserManagementProfile, action.payload);
    yield put(userManagementProfileAction.getUserManagementProfileSuccess(userPayload));
  } catch (error) {
    yield put(creditsAction.postAddCreditsStatusFailure(error));
  }
}

// WATCHERS
export function* watchBuyCreditsAdmin(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(creditsActionType.POST_ADD_CREDITS_ADMIN_REQUEST, buyCreditsAdmin);
}
