import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  POST_ADD_CREDITS_ADMIN_REQUEST,
  POST_ADD_CREDITS_ADMIN_SUCCESS,
  POST_ADD_CREDITS_ADMIN_FAILURE,
} from '../actions/types/types';
import {IAddAdminCreditsAction, IAddAdminCreditsState} from '../interfaces';

export const initialState = {
  addAdminCredits: null,
  isAddingSuccess: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [POST_ADD_CREDITS_ADMIN_REQUEST]: (state: IAddAdminCreditsState) => ({
    ...state,
    loading: true,
  }),

  [POST_ADD_CREDITS_ADMIN_SUCCESS]: (
    state: IAddAdminCreditsState,
    action: IAddAdminCreditsAction,
  ) => ({
    ...state,
    isAddingSuccess: action.payload,
    error: '',
    loading: false,
  }),

  [POST_ADD_CREDITS_ADMIN_FAILURE]: (
    state: IAddAdminCreditsState,
    action: IAddAdminCreditsAction,
  ) => ({
    ...state,
    addAdminCredits: null,
    error: action.payload,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
