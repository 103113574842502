import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './style.scss';
import {clearCreatedModel, clearModelDetails} from '../../../actions/textModel';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

interface IProps {
  match?: {
    goBack: () => void;
    replace: (s: string) => void;
  };
  href?: string;
  prevPage?: boolean;
  isTextBack?: boolean;
  mainHref?: string;
  specialClass?: string;
  clearData?: boolean;
}

const BackButton = (props: IProps): JSX.Element => {
  const {isTextBack = true, prevPage, mainHref, specialClass, clearData} = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goBack = () => {
    if (prevPage) {
      if (clearData) {
        dispatch(clearCreatedModel());
        dispatch(clearModelDetails());
      }
      navigate(-1);
      // match.goBack();
    } else if (mainHref) {
      window.location.href = mainHref;
    } else {
      // href && match.replace(href);
    }
  };
  return (
    <span onClick={goBack} className={`back-btn ${specialClass ? specialClass : ''}`}>
      <ArrowBackIcon />
      {isTextBack ? <p>Back</p> : null}
    </span>
  );
};

export default BackButton;
