import React, {MouseEventHandler, useEffect, useState} from 'react';
import Logo from '../../../images/Logo-dark.svg';
import {Grid, Drawer} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import {Link} from 'react-router-dom';

interface IProps {
  redirectHandle: MouseEventHandler<HTMLButtonElement>;
  positionFixed?: boolean;
  navBar?: boolean;
}

const LandingHeader = (props: IProps): JSX.Element => {
  const {positionFixed, redirectHandle, navBar = true} = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [headerClass, setHeaderClass] = useState(
    positionFixed ? 'header--transparent' : 'header--white',
  );

  const wrapperStyle: React.CSSProperties = positionFixed
    ? {
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: 1200,
      }
    : {};

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isWhiteNavbar = () => {
    if (window !== undefined) {
      const windowHeight = window.scrollY;
      windowHeight > 50 ? setHeaderClass('header--white') : setHeaderClass('header--transparent');
    }
  };
  useEffect(() => {
    if (!positionFixed) {
      return;
    }

    window.addEventListener('scroll', isWhiteNavbar);

    return () => {
      window.removeEventListener('scroll', isWhiteNavbar);
    };
  }, []);

  return (
    <>
      <Grid className={`header-land ${headerClass}`} container item xs={12} style={wrapperStyle}>
        <Grid
          className="header-transparent"
          container
          item
          style={{
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          xs={11}
          sm={10}
          lg={navBar !== false ? 8 : 11}
        >
          <Link className="header__logo-link" to="/home">
            <img src={Logo} alt="Logo Devtorium" />
          </Link>
          {navBar !== false && (
            <div className="header-links">
              <a href="/home#features">Features</a>
              <a href="/home#benefits">Benefits</a>
              <a href="/home#whoCanUse">Who can use</a>
              <a href="/subscription-plan">Pricing</a>
              <a href="/home#faq">FAQ</a>
            </div>
          )}
          <div className="header-buttons">
            <button onClick={redirectHandle} className="orange-btn orange-btn-hover">
              Sign in / Sign up
            </button>
          </div>
          <MenuIcon className="header-mobile-btn" onClick={handleDrawerToggle} />
          <Drawer
            className="header-mobile-menu"
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            hideBackdrop={false}
            ModalProps={{
              keepMounted: true,
            }}
            anchor={'top'}
          >
            <div className="header-mobile-top">
              <div className="header__logo-menu">
                <img src={Logo} alt="Logo Devtorium" />
              </div>
              <CloseIcon onClick={handleDrawerToggle} />
            </div>
            <div className="header-mobile-links">
              <a onClick={() => setMobileOpen(false)} href="/home/#features">
                Features
              </a>
              <a onClick={() => setMobileOpen(false)} href="/home/#benefits">
                Benefits
              </a>
              <a onClick={() => setMobileOpen(false)} href="/home/#whoCanUse">
                Who can use
              </a>
              <a onClick={() => setMobileOpen(false)} href="/subscription-plan">
                Pricing
              </a>
              <a onClick={() => setMobileOpen(false)} href="/home/#faq">
                FAQ
              </a>
              <div className="header-mobile-buttons">
                <button onClick={redirectHandle} className="orange-btn orange-btn-hover">
                  Sign in / Sign up
                </button>
              </div>
            </div>
          </Drawer>
        </Grid>
      </Grid>
    </>
  );
};

export default LandingHeader;
