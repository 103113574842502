import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  // withRouter,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import HelpCenter from '../components/HelpCenter';
import Main from '../pages/Main';
import Admin from '../pages/Admin';
import AccountPage from '../pages/Account';
import SubscriptionPlan from '../pages/SubscriptionPlan';
import TrialExpired from '../pages/TrialExpired';
import {getUserProfileRequest} from '../actions/userProfile';
import * as areas from './../actions/areas';
import {IState} from '../interfaces';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import {useActiveUser} from '../helpers/customHooks';
import Logout from '../components/Logout';
import ReactGA from 'react-ga4';
import DeleteSuccess from '../pages/DeleteSuccess';
import ContactUsPage from '../pages/ContactUs';
import AfterChangePass from '../pages/AfterChangePass';
import TermsOfServices from '../pages/TermsOfService';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import * as industriesStatus from '../actions/industries';
import TextModelCreatePage from '../pages/TextModel/TextModelCreatePage';
import {connectSignalR} from '../helpers/signalR';
import PublicPage from '../pages/PublicPage';
import TextModelPage from '../pages/TextModel';
import LandingHeader from '../components/Landing/Header';
import {useMsal} from '@azure/msal-react';
import {logInHandle} from '../helpers/helper';
import UserAgreement from '../pages/UserAgreement';
import {withRouter} from '../helpers/routeHelper';

function RootRouters() {
  const dispatch = useDispatch();
  const userRole = useSelector((state: IState) => state.userProfile.userProfile.userRole);
  const userProfileState = useSelector((state: IState) => state.userProfile);
  const userStatus = useActiveUser();
  const areasState = useSelector((state: IState) => state.areas);
  const navigate = useNavigate();
  const {instance} = useMsal();
  const location = useLocation();

  const availableRoutes =
    location.pathname !== '/subscription-plan' &&
    location.pathname !== '/account/profile' &&
    location.pathname !== '/account/billing-details' &&
    location.pathname !== '/account/settings' &&
    location.pathname !== '/login' &&
    location.pathname !== '/delete-success' &&
    location.pathname !== '/contact-us';

  const availableRoutesForExpired =
    location.pathname !== '/main/dashboard' &&
    location.pathname !== '/ask-a-question' &&
    location.pathname !== '/main/my-Q&A' &&
    location.pathname !== '/help-center/user-agreement' &&
    !location.pathname.includes('/main/summary/');

  useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: window.location.pathname});
  }, []);

  useEffect(() => {
    dispatch(getUserProfileRequest());
    return connectSignalR(dispatch, true);
  }, []);

  useEffect(() => {
    if (userProfileState.userProfile.userId && !areasState.loading && !areasState.areas) {
      dispatch(areas.getStatusRequest());
      dispatch(industriesStatus.getStatusRequest());
    }
  }, [userProfileState.userProfile]);

  useEffect(() => {
    if (!userProfileState.loading && userProfileState.userProfile.userId && userRole === 'Reader') {
      if (
        !userStatus.isSubscribed &&
        availableRoutes &&
        !userProfileState.userProfile.hadSubscription
      ) {
        navigate('/subscription-plan');
      } else if (userStatus.isExpired && availableRoutes && availableRoutesForExpired) {
        navigate('/main/dashboard');
      }
    }
  }, [userProfileState]);

  const btnClickHandle = (): Promise<void> => {
    return logInHandle(instance);
  };

  return userProfileState.userProfile.userId ? (
    <Routes>
      <Route path="/subscription-plan" element={<SubscriptionPlan />} />
      <Route path="/trialExpired" element={<TrialExpired />} />
      <Route path="/terms" element={<TermsOfServices />} />
      <Route path="/policy-privacy" element={<PrivacyPolicy />} />
      <Route path="/user-agreement" element={<UserAgreement />} />
      <Route path="/help-center/*" element={<HelpCenter />} />
      <Route path="/logout" element={<Logout />} />
      <Route
        path="/delete-success"
        element={
          <>
            <div className="main-page unauthenticated__page delete-success">
              <LandingHeader redirectHandle={btnClickHandle} />
              <DeleteSuccess />
            </div>
          </>
        }
      />
      <Route path={`/public/:publicHashId`} element={<PublicPage />} />
      {userRole === 'Reader' && <Route path="/main/*" element={<Main />} />}
      {userRole === 'Reader' && <Route path="/account/*" element={<AccountPage />} />}
      {userRole === 'Reader' && <Route path="/contact-us" element={<ContactUsPage />} />}
      {userRole === 'Reader' && <Route path="/password-changed" element={<AfterChangePass />} />}
      {userRole === 'Reader' && (
        <Route path="/text-model-create" element={<TextModelCreatePage />} />
      )}
      {userRole === 'Reader' && <Route path={`/text-model`} element={<TextModelPage />} />}
      {userRole === 'Reader' && (
        <Route path={`/text-model-details/:modelId`} element={<TextModelPage />} />
      )}
      {(userRole === 'Admin' || userRole === 'Consultant') && (
        <Route path="/admin/*" element={<Admin />} />
      )}
      {userRole &&
        (userRole === 'Admin' || userRole === 'Consultant' ? (
          <Route path="/" element={<Navigate replace to="/admin/user-management" />} />
        ) : (
          <Route path="/" element={<Navigate replace to="/main/dashboard" />} />
        ))}
    </Routes>
  ) : (
    <div className="page-loading">
      <LoadingSpinner />
    </div>
  );
}

export default withRouter(RootRouters);
