import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {IPaymentMethods, IState} from '../../../../interfaces';
import PaymentItem from './PaymentItem';
import AddIcon from '@material-ui/icons/Add';
import CreditCountIcon from '../../../../images/card-icon.svg';
import {DialogActions} from '@material-ui/core';
import Payment from '../../../SubscriptionComponent/Payment';
import StripeFooter from '../../../SubscriptionComponent/StripeFooter';
import LoadingSpinner from '../../../UI/LoadingSpinner';
import './style.scss';
import PaymentForm from '../../../SubscriptionComponent/Payment/PaymentForm';
import StandardModal from '../../../UI/StandardModal';
import {useActiveUser} from '../../../../helpers/customHooks';
import {NavLink, useNavigate} from 'react-router-dom';
import {LightTooltip} from '../../../UI/StatusLabel';
import {ReactComponent as QuestionIcon} from '../../../../images/redeem-coupon-question.svg';

interface IPaymentInfo {
  cards: IPaymentMethods[];
  billingDate: string | undefined;
  plan: string | undefined;
  renewalPrice: number | undefined;
  renewalDate: string | null;
}
interface IProps {
  data: IPaymentInfo;
  setCardSavingSucceeded: (result: boolean) => void;
}

const PaymentInfo = (props: IProps): JSX.Element => {
  const {data, setCardSavingSucceeded} = props;
  const navigate = useNavigate();
  const initializedCardResponse = useSelector(
    (state: IState) => state.initializeCard.initializeCardResponse,
  );
  const hasBillingHistory = useSelector(
    (state: IState) =>
      state.billingHistory.billingHistory &&
      Boolean(state.billingHistory.billingHistory.items.length),
  );
  // const [isCardSavingSucceeded, setCardSavingSucceeded] = useState<boolean>(false);
  const paymentMethodsState = useSelector((state: IState) => state.paymentMethods);
  const {currentStatus} = useActiveUser();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const setDefault = paymentMethodsState?.paymentMethods?.items?.length;
  function handleAdd() {
    setCardSavingSucceeded(false);
    setOpenPopup(true);
  }

  return (
    <div id={'payment'} className={`user-info__payment  ${!data.plan ? 'inactive' : ''}`}>
      <h2 className="profile__subtitle profile__subtitle--flex">
        <span>Payment details</span>
        <LightTooltip
          title="Read more"
          placement="bottom"
          onClick={() => navigate('/help-center/billing-and-payments')}
        >
          <QuestionIcon />
        </LightTooltip>
      </h2>
      <div className="profile__section">
        <div className="user-info__payment--info">
          <div className="user-info__payment--title">
            Your next billing date is
            {data.renewalDate && data.plan ? (
              <span className="user-info__payment--descr user-info__all-info--blue">
                {data.billingDate && data.renewalPrice
                  ? `${data.renewalDate} ($${data.renewalPrice})`
                  : ': none'}
              </span>
            ) : (
              <span>...</span>
            )}
            {hasBillingHistory && (
              <span className="user-info__payment--history">
                <NavLink to="/account/billing-details">Payment history</NavLink>
              </span>
            )}
          </div>
          {(!data.cards || data.cards?.length === 0) && (
            <div className="user-info__payment--title">
              <img src={CreditCountIcon} alt="credit card" />
              You haven't added a credit card yet
            </div>
          )}
        </div>
        {data.cards && data.cards.length > 0 && (
          <div className="user-info__payment--cards">
            {data.cards.map((item) => (
              <PaymentItem
                key={item.id}
                item={item}
                cardLength={data.cards.length}
                subscriptionStatus={currentStatus}
              />
            ))}
          </div>
        )}
        <div className="user-info__payment--add" onClick={handleAdd}>
          <AddIcon />
          <span>Add card</span>
        </div>

        <StandardModal
          isOpen={openPopup}
          title={'Add credit card'}
          subtitle={'Please enter your payment details.'}
          specificClass={'add-card-modal'}
          onClose={() => setOpenPopup(false)}
        >
          <DialogActions className="modal__buttons payment-modal-actions">
            {initializedCardResponse && (
              <Payment initializeCardResponse={initializedCardResponse}>
                <PaymentForm
                  clientSecret={initializedCardResponse.clientSecret}
                  setSavingCardSucceeded={setCardSavingSucceeded}
                  btnText={'Add card'}
                  isAccountPage={true}
                  setOpenPopup={setOpenPopup}
                  setDefault={!!setDefault}
                  setPaymentModalOpen={setOpenPopup}
                />
              </Payment>
            )}
            <StripeFooter />
          </DialogActions>
          {paymentMethodsState.loading && (
            <div className="modal__window--loading">
              <LoadingSpinner />
            </div>
          )}
        </StandardModal>
      </div>
    </div>
  );
};

export default PaymentInfo;
