import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {IconButton} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import {useActiveUser} from '../../../../helpers/customHooks';
import './style.scss';
import {getSubscriptionTitleLabel} from '../../../../helpers/subscriptionHelper';
import {getPlanIconByName} from '../../../../helpers/helper';
import moment from 'moment';
import SubscriptionRenewalSwitch from './SubscriptionRenewalSwitch';
import {IState} from '../../../../interfaces';
import useWindowDimensions from '../../../../helpers/windowResize';

interface ISubscriptionInfoProps {
  credits: {
    plan: string;
    planId: string;
    exDate: string;
    credits: number;
  };
  setAutoRenewalActivated: (item: boolean) => void;
}

const SubscriptionInfo = (props: ISubscriptionInfoProps): JSX.Element => {
  const {credits, setAutoRenewalActivated} = props;
  const navigate = useNavigate();
  const [isAutoRenewalActivatedEnabled, setAutoRenewalActivatedEnabled] = useState<boolean>(false);
  const userData = useSelector((state: IState) => state.userProfile);
  const subscriptionRenewalData = userData.userProfile?.subscriptionRenewal;

  const userStatus = useActiveUser();
  const {currentStatus} = userStatus;
  const formatCurrentDate = Date.parse(moment(new Date()).format('MMM D, YYYY'));
  const formatExDate = Date.parse(credits.exDate);
  const iconSrc = getPlanIconByName(credits.plan);
  const isUserWithTrial =
    currentStatus === 'TrialExpired' || currentStatus === 'TrialActive' || currentStatus === 'None';

  const subscriptionTitle = getSubscriptionTitleLabel(currentStatus, credits.plan);
  const screenWidth = useWindowDimensions().width;

  useEffect(() => {
    if (isAutoRenewalActivatedEnabled) {
      setAutoRenewalActivated(true);
    }
  }, [isAutoRenewalActivatedEnabled]);

  return (
    <div className="subscription profile__section-wr">
      <h2 className="profile__subtitle">Subscription plan</h2>
      <div className="profile__section">
        <div>
          <div className="subscription-section__credits">
            <div className="user-info__all-info--title">Credits: </div>
            {credits.plan || credits.credits > 0 ? (
              <span className="user-info__all-info--descr user-info__all-info--price">
                <img src={iconSrc} alt="credit icon" />
                <span className="orange-text">{credits.credits}</span>
              </span>
            ) : (
              <p className="no-credits">No credits yet</p>
            )}
          </div>
          {!isUserWithTrial && userStatus.isSubscribed && (
            <div
              className="profile__section--edit"
              onClick={() => {
                navigate('/subscription-plan#add-credits');
              }}
            >
              <IconButton aria-label="edit">
                <AddIcon />
              </IconButton>
              <span>Add credits</span>
            </div>
          )}
        </div>
        <div className="subscription-section__plan">
          <div>
            <div className="user-info__all-info--title subscription-title">Subscription plan:</div>
            <span
              className={`user-info__all-info--descr user-info__all-info--blue 
              ${userStatus.isExpired ? 'user-info__expired' : ''}
              ${!credits.plan ? ' user-info__none' : ''}`}
            >
              {subscriptionTitle}
            </span>
          </div>
          <div
            className="profile__section--edit"
            onClick={() => {
              navigate('/subscription-plan');
            }}
          >
            {credits.plan ? (
              <IconButton aria-label="edit">
                <EditOutlinedIcon />
              </IconButton>
            ) : null}
            {credits.plan && formatExDate < formatCurrentDate ? (
              <span>Update subscription</span>
            ) : (
              <span>{screenWidth > 768 ? 'Change subscription' : 'Change'}</span>
            )}
          </div>
        </div>
        {credits.plan && (
          <div className="subscription-title--wr">
            <div className="user-info__all-info--title">Expiration date: </div>
            <span className="user-info__all-info--descr user-info__all-info--blue">
              {credits.plan && credits.exDate}
            </span>
          </div>
        )}

        {subscriptionRenewalData && (
          <div className="user-info__all-info--renew">
            <div className="user-info__all-info--title user-info__all-info--title_width">
              Auto-renew subscription:
            </div>
            <span>
              <SubscriptionRenewalSwitch
                setAutoRenewalActivatedEnabled={setAutoRenewalActivatedEnabled}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionInfo;
