import React, {useEffect} from 'react';
import './style.scss';
import {getCouponsHistoryRequest} from '../../../actions/coupon';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import BackButton from '../../UI/BackButton';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment';
import LoadingSpinner from '../../UI/LoadingSpinner';
import {IState} from '../../../interfaces';

const CouponHistory = (): JSX.Element => {
  const dispatch = useDispatch();
  const couponsHistory = useSelector((state: IState) => state.coupon.couponsHistory);
  useEffect(() => {
    dispatch(getCouponsHistoryRequest());
  }, []);
  return (
    <div className="coupons-history">
      <Grid container style={{justifyContent: 'center'}}>
        <Grid
          item
          xs={12}
          sm={11}
          lg={10}
          className="coupons-history__container component-white-wr"
        >
          {couponsHistory && couponsHistory.items.length ? (
            <>
              <div className="coupons-history__title-wr">
                <BackButton prevPage isTextBack={false} />
                <h1 className="coupons-history__title">Coupon History</h1>
              </div>
              <Hidden xsDown>
                <div className="coupons-history__table-header">
                  <div className="coupons-history__table-row">
                    <p className="table-header-item date">Date of usage</p>
                    <p className="table-header-item name">Coupon Code</p>
                    <p className="table-header-item campaign">Name</p>
                    <p className="table-header-item product">Product</p>
                    <p className="table-header-item discount">Discount</p>
                  </div>
                </div>
              </Hidden>
              <div className="coupons-history__table-details">
                {couponsHistory.items.map((item, i) => (
                  <div key={i} className="coupons-history__table-row">
                    <p className="coupons-history__table-item date">
                      <Hidden smUp>
                        <span className="coupons-history__table-title">Date</span>
                      </Hidden>
                      {moment(item.useDateUtc).format('MM-DD-YYYY')}
                    </p>
                    <p className="coupons-history__table-item name">
                      <Hidden smUp>
                        <span className="coupons-history__table-title">Coupon Code</span>
                      </Hidden>
                      <span className="info-item">{item.code.toUpperCase()}</span>
                    </p>
                    <p className="coupons-history__table-item campaign">
                      <Hidden smUp>
                        <span className="coupons-history__table-title">Name</span>
                      </Hidden>
                      <span className="coupons-history__table-card">{item.description}</span>
                    </p>
                    <p className="coupons-history__table-item product">
                      <Hidden smUp>
                        <span className="coupons-history__table-title">Product</span>
                      </Hidden>
                      {`${item.product} (${item.productPeriod})`}
                    </p>
                    <p className="coupons-history__table-item discount">
                      <Hidden smUp>
                        <span className="coupons-history__table-title">Discount</span>
                      </Hidden>
                      {item.percentOff > 0 ? `${item.percentOff}%` : `$${item.amountOff}`}
                    </p>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CouponHistory;
