import {handleBody, handleExceptions, GET, Params} from './request';

export const getAreas = (params: Params): Promise<Object | null> => {
  const endpoint: string = `/Areas`;
  const options = {
    ...params,
  };

  return GET(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
