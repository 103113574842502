import {BASE_URL} from '../constants';
import {acquireToken} from '../authProvider/auth-provider';

export type HttpMethod = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';

export type ExtraHeaders = {};

export type ApiHeaders = {
  Accept: 'application/json, text/json';
  'Content-Type':
    | 'application/json'
    | 'text/json'
    | 'application/x-www-form-urlencoded'
    | 'multipart/form-data';
  Authorization?: string;
};

export type AllowedContentTypes =
  | 'application/json'
  | 'text/json'
  | 'application/x-www-form-urlencoded'
  | 'multipart/form-data';

export interface Params {
  apiPath?: string;
  contentType?: AllowedContentTypes;
  body?: {} | string;
  accessToken?: string;
  extraHeaders?: ExtraHeaders;
}

interface IFetch {
  mode: 'cors' | 'navigate' | 'no-cors' | 'same-origin';
  headers?: HeadersInit;
  method?: HttpMethod;
  body?: any;
}

export function getHeadersInit(options: Params): ApiHeaders {
  const headersInit: ApiHeaders = {
    Accept: 'application/json, text/json',
    'Content-Type': options.contentType || 'application/json',
    ...options.extraHeaders,
  };

  if (options.accessToken) {
    headersInit.Authorization = `Bearer ${options.accessToken}`;
  }

  return headersInit;
}

export function getFetchInit(
  method: HttpMethod,
  headers: HeadersInit,
  body?: Object | string,
): RequestInit {
  const fetchInit: IFetch = {mode: 'cors'};
  fetchInit.headers = new Headers({...headers});
  fetchInit.method = method;

  if (body) {
    if (typeof body === 'string') {
      fetchInit.body = body;
    } else if (typeof body === 'object') {
      fetchInit.body = JSON.stringify(body);
    }
  }

  return fetchInit;
}

export function handleBody(response: Response): Promise<Object | null> {
  if (!response) {
    return Promise.reject(new Error('No response supplied to handleBody'));
  }

  if (response.status === 204) {
    return response.text().then((text) => ({text}));
  }

  const contentType: string | null = response.headers.get('Content-Type');
  switch (contentType && contentType.toLowerCase()) {
    case 'application/json':
    case 'application/json; charset=utf-8':
    case 'text/json':
      try {
        return response.json().then((data) => data as Object | null);
      } catch (error) {
        return Promise.reject(new Error("Got a JSON response, but it couldn't be parsed"));
      }

    case 'text/plain':
    case 'text/html':
      // These may be returned on a 500 status, or other unexpected outcome.
      try {
        return response.text().then((text) => ({text}));
      } catch (error) {
        return Promise.reject(new Error("Got a text response, but it couldn't be parsed"));
      }

    default:
      return Promise.reject(new Error(`Unrecognized Content-Type: ${contentType || ''}`));
  }
}

export function handleExceptions(response: Response): Response {
  if (response.status >= 400) {
    throw Error('Error! Status code: ' + response.status.toString());
  }

  return response;
}

export async function devtoriumFetch(
  method: HttpMethod,
  endpoint: string,
  params?: Params,
): Promise<Response> {
  let fetchInit: RequestInit;
  try {
    const options = {
      ...params,
      accessToken: await acquireToken(),
    };

    const headersInit: HeadersInit = getHeadersInit(options) as HeadersInit;
    fetchInit = getFetchInit(method, headersInit, options.body);
    const url = BASE_URL ? BASE_URL : '';
    return fetch(`${url}${endpoint}`, fetchInit);
  } catch (error) {
    return Promise.reject(error);
  }
}

export const GET = (endpoint: string, params?: Params): Promise<Response> =>
  devtoriumFetch('GET', endpoint, params);
export const PUT = (endpoint: string, params?: Params): Promise<Response> =>
  devtoriumFetch('PUT', endpoint, params);
export const POST = (endpoint: string, params?: Params): Promise<Response> =>
  devtoriumFetch('POST', endpoint, params);
export const PATCH = (endpoint: string, params?: Params): Promise<Response> =>
  devtoriumFetch('PATCH', endpoint, params);
export const DELETE = (endpoint: string, params?: Params): Promise<Response> =>
  devtoriumFetch('DELETE', endpoint, params);
