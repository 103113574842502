import React, {useEffect, useState} from 'react';
import {getIconSrc} from '../../helpers/helper';
import {IState, ITextGenerationResultItem, IUserProfile} from '../../interfaces';
import {Button, IconButton} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import {useNavigate} from 'react-router-dom';
import UiAlert from '../UI/UiAlert';
import {ReactComponent as PublishIcon} from '../../images/publish-icon.svg';
import {ReactComponent as UnpublishIcon} from '../../images/unpublish-icon.svg';
import useWindowDimensions from '../../helpers/windowResize';
import DownloadFileBtn from '../UI/DownloadFileBtn';
import ShareContentBtn from '../UI/ShareContentBtn';
import SnackbarMessage from '../UI/Snackbar';
import {LightTooltip} from '../UI/StatusLabel';
import {putLikeModelRequest} from '../../actions/textModel';
import {ReactComponent as HintIcon} from '../../images/hint-icon.svg';
import Onboarding from '../UI/Onboarding';

interface IProps {
  onAction?: (isRetry: boolean) => void;
  isButtonDisabled: boolean;
  text: string;
  retryStep: string;
  historyTemplate: ITextGenerationResultItem[];
  open: boolean;
  isContentDone: boolean;
  setOpen: (open: boolean) => void;
  refContent: HTMLDivElement | null;
  contentAsHtml: string;
  status?: string | undefined;
  publicHash?: string;
  contentId?: string;
  title?: string;
  isFavorite?: boolean;
}

const modelDetailsErrorSelector = (state: IState) => {
  return state.textModel.textModelDetails ? state.textModel.textModelDetails.lastError : null;
};

const TemplateFooter = (props: IProps): JSX.Element => {
  const {
    onAction,
    historyTemplate = [],
    open,
    setOpen,
    status,
    refContent,
    contentAsHtml,
    publicHash,
    contentId,
    title,
    isFavorite,
  } = props;
  const dispatch = useDispatch();
  const [creditCount, setCreditCount] = useState<number>(0);
  const [isLiked, setLiked] = useState<boolean>(Boolean(isFavorite));
  const [isOpenSnack, setOpenSnack] = React.useState<boolean>(false);
  const [snackMessage, setSnackMessage] = React.useState<string>('');
  const [severity, setSeverity] = useState<'success' | 'info' | 'warning' | 'error' | undefined>(
    undefined,
  );
  // const modelDetails = useSelector((state: IState) => state.textModel.textModelDetails);
  // const modelTemplateDetails = useSelector((state: IState) => state.textModel.modelTemplateDetails);
  const createResponse = useSelector((state: IState) => state.textModel.textModelCreate);
  const textModelState = useSelector((state: IState) => state.textModel);
  const textModelDetails = useSelector((state: IState) => state.textModel.textModelDetails);
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasOnAction = typeof onAction === 'function';
  const modelDetailsError = useSelector<IState, string | null>(modelDetailsErrorSelector);
  const [isOnboardingOpen, setIsOnboardingOpen] = React.useState<boolean>(false);

  const screenWidth = useWindowDimensions().width;

  const steps = [
    {
      element: '.highlight',
      position: 'top',
      intro: 'Export or download your content anytime.',
      tooltipClass: 'onboarding-tooltip__share onboarding-tooltip__height',
      highlightClass: 'onboarding-highlight__share',
      disableInteraction: true,
    },
    {
      element: '.total-credits',
      intro:
        'Want to know how many credits you used up? See the expanded credit history to learn exactly how many credits were deducted at each step of the generation process.',
      position: 'top',
      tooltipClass: 'onboarding-tooltip__credits onboarding-tooltip__height',
      highlightClass: 'onboarding-highlight__credits',
      disableInteraction: true,
    },
  ];

  useEffect(() => {
    if (historyTemplate) {
      setCreditCount(
        historyTemplate.map((item) => item.chargeAmount).reduce((prev, curr) => prev + curr, 0),
      );
    }
  }, [historyTemplate]);

  useEffect(() => {
    if (createResponse && createResponse.id) {
      navigate(`/text-model/${createResponse.id}`);
    }
  }, [createResponse]);

  useEffect(() => {
    if (textModelDetails) {
      setLiked(textModelDetails.isFavorite);
    }
  }, [textModelDetails]);

  function likeModel(id: string, like: boolean) {
    setLiked(like);
    dispatch(putLikeModelRequest({textModelId: id, isFavorite: like, isDetails: true}));
  }

  function snackBarAction(
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error' | undefined,
    open: boolean,
  ) {
    setSnackMessage(message);
    setSeverity(severity);
    setOpenSnack(open);
  }

  // const createNewTextModel = () => {
  //   if (modelTemplateDetails && modelDetails) {
  //     const createData = {
  //       textModelTemplateId: modelTemplateDetails.id,
  //       industryId: modelDetails.industryId,
  //       areaIds: modelDetails.areaNames.map((areaName) => getAreaIdByName(areaName)),
  //     };
  //     dispatch(postTextModelCreateRequest(createData));
  //   }
  // };

  const user: IUserProfile = useSelector((state: IState) => state.userProfile.userProfile);
  const handleOnboardingExit = () => {
    setIsOnboardingOpen(false);
  };

  return (
    <>
      {modelDetailsError && (
        <div className="text-model__footer-error">
          <UiAlert
            type={'error'}
            title={<div dangerouslySetInnerHTML={{__html: modelDetailsError}} />}
            withButton={false}
          />
        </div>
      )}
      <SnackbarMessage
        open={isOpenSnack}
        message={snackMessage}
        severity={severity}
        setOpen={setOpenSnack}
      />
      <div className="text-model__actions">
        {creditCount > 0 ? (
          <p className="total-credits" onClick={() => (setOpen as (open: boolean) => void)(!open)}>
            <span className="pointer">
              {screenWidth <= 515 ? 'Total:' : 'Total cost:'}
              <span className="price">
                <img src={getIconSrc(user.currentUserSubscription)} alt="credit icon" />
                {creditCount}
              </span>
              {historyTemplate.length > 0 && (
                <span className="total-credits__collapse-icon">
                  {open ? <ExpandMoreRoundedIcon /> : <ExpandLessRoundedIcon />}
                </span>
              )}
            </span>
          </p>
        ) : (
          <></>
        )}
        {hasOnAction && (
          <div className="buttons-wr">
            <>
              <div className="text-model__actions-group">
                {status === 'Done' && (
                  <>
                    <IconButton
                      aria-label="like"
                      className="orange-icon-btn like-button"
                      onClick={() => likeModel(contentId as string, !isFavorite)}
                      disabled={textModelState.loading}
                    >
                      <LightTooltip
                        title={
                          isLiked ? 'Remove content from favorites' : 'Add content to favorites'
                        }
                        placement={'top'}
                      >
                        {isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                      </LightTooltip>
                    </IconButton>

                    {!isOnboardingOpen && (
                      <IconButton onClick={() => setIsOnboardingOpen(true)} className="button-hint">
                        <HintIcon />
                      </IconButton>
                    )}
                  </>
                )}
                <div className="highlight">
                  {status === 'Done' && (
                    <ShareContentBtn
                      publicHash={publicHash}
                      contentId={contentId as string}
                      snackBarAction={snackBarAction}
                      isDetailsPage={true}
                      title={title}
                    />
                  )}
                  <DownloadFileBtn
                    refElement={refContent as HTMLDivElement}
                    title={title ? title : 'Marquette document'}
                    isPreviewStep={true}
                    contentAsHtml={contentAsHtml}
                  />
                </div>
              </div>
            </>
            {screenWidth <= 1181 ? (
              <>
                {status === 'Done' ? (
                  <IconButton
                    onClick={() => onAction(true)}
                    className="orange-btn orange-btn-hover padding text-model__action-button"
                  >
                    <UnpublishIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => onAction(true)}
                    className="orange-btn orange-btn-hover padding text-model__action-button"
                    disabled={status === 'Generating'}
                  >
                    <PublishIcon />
                  </IconButton>
                )}
              </>
            ) : (
              <>
                {status === 'Done' ? (
                  <Button
                    onClick={() => onAction(true)}
                    className="orange-btn orange-btn-hover text-model__action-button"
                    endIcon={<UnpublishIcon />}
                  >
                    Unpublish
                  </Button>
                ) : (
                  <Button
                    onClick={() => onAction(true)}
                    className="orange-btn orange-btn-hover text-model__action-button"
                    endIcon={<PublishIcon />}
                    disabled={status === 'Generating'}
                  >
                    Publish
                  </Button>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <Onboarding steps={steps} onExit={handleOnboardingExit} isOpen={isOnboardingOpen} />
    </>
  );
};

export default TemplateFooter;
