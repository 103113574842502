import {handleBody, handleExceptions, GET, Params, POST, DELETE} from './request';
import {IInitializeDefaultCardPayload, IRemoveCardPayload} from '../interfaces';

export const getPaymentMethods = (params: Params): Promise<Object | null> => {
  const endpoint: string = `/PaymentMethods`;
  const options = {
    ...params,
  };

  return GET(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const initializePaymentCard = (payload: {}): Promise<Object | null> => {
  const endpoint: string = `/PaymentMethods/InitializePaymentCard`;
  const options = {
    body: {payload},
  };
  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const initializeDefaultPaymentCard = (
  payload: IInitializeDefaultCardPayload,
): Promise<Object | null> => {
  const endpoint: string = `/PaymentMethods/Default`;
  const options = {
    body: payload,
  };
  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const removePaymentCard = (payload: IRemoveCardPayload): Promise<Object | null> => {
  const endpoint: string = `/PaymentMethods/${payload.id}`;
  const options = {
    body: payload,
  };
  return DELETE(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
