export interface ITemplateBlock {
  type: string;
  value?: string;
  values?: number[];
  options?: string[];
  width?: number;
}

function getSelectedOptions(options: string[]): string[] {
  return options.filter((el) => el.includes('~'));
}

export function getIndexesOfSelectedOptions(
  options: string[],
  selectedOptions: string[],
): number[] {
  return selectedOptions.map((el) => options.findIndex((option) => option === el));
}

export function getOptionsByIndexes(options: string[], indexes: number[]): string[] {
  return indexes.map((index) => options[index]);
}

export function stringToTemplateBlocks(str: string): ITemplateBlock[] {
  const blocks: ITemplateBlock[] = [];

  const wideTextRegex = /\(\(\((.*?)\)\)\)/;
  const textRegex = /\(\((.*?)\)\)/;
  const numberRegex = /<<(.*?)>>/;
  const newLineRegex = /\n/;
  const hiddenRegex = /<!--([\s\S]*?)-->/;
  const selectRegex = /\[\[(.*?)\]\]/;
  const multiselectRegex = /\{\{(.*?)\}\}/;

  while (str.length > 0) {
    const pos = str.search(
      /<!--[\s\S]*?-->|\{\{.*?\}\}|\[\[.*?\]\]|\(\(\(.*?\)\)\)|\(\(.*?\)\)|<<.*?>>|\n/,
    );
    if (pos === -1) {
      // rest string is text
      const text = str;
      blocks.push({
        type: 'text',
        value: text,
      });
      str = '';
    } else if (pos !== 0) {
      // text before tag
      const text = str.slice(0, pos);
      blocks.push({
        type: 'text',
        value: text,
      });
      str = str.slice(pos);
    } else {
      let mObj = numberRegex.exec(str);
      if (mObj && mObj.index === 0) {
        // number-input
        blocks.push({
          type: 'number-input',
          value: mObj[1],
        });
        str = str.slice(mObj[0].length);
        continue;
      }
      mObj = wideTextRegex.exec(str);
      if (mObj && mObj.index === 0) {
        // wide-text-input
        blocks.push({
          type: 'wide-text-input',
          value: mObj[1],
        });
        str = str.slice(mObj[0].length);
        continue;
      }
      mObj = textRegex.exec(str);
      if (mObj && mObj.index === 0) {
        // text-input
        blocks.push({
          type: 'text-input',
          value: mObj[1],
        });
        str = str.slice(mObj[0].length);
        continue;
      }
      mObj = selectRegex.exec(str);
      if (mObj && mObj.index === 0) {
        // single-select
        const options = mObj[1].split('|');
        const selectedOptions = getSelectedOptions(options);
        const indexes = getIndexesOfSelectedOptions(options, selectedOptions);
        blocks.push({
          type: 'single-select',
          options: options.map((option) => option.replace('~', '')),
          value: indexes.length > 0 ? indexes[0].toString() : '',
        });
        str = str.slice(mObj[0].length);
        continue;
      }
      mObj = multiselectRegex.exec(str);
      if (mObj && mObj.index === 0) {
        // multi-select
        const options = mObj[1].split('|');
        const selectedOptions = getSelectedOptions(options);
        const indexes = getIndexesOfSelectedOptions(options, selectedOptions);
        blocks.push({
          type: 'multi-select',
          options: options.map((option) => option.replace('~', '')),
          values: indexes,
        });
        str = str.slice(mObj[0].length);
        continue;
      }
      mObj = newLineRegex.exec(str);
      if (mObj && mObj.index === 0) {
        // new line
        blocks.push({
          type: 'tag',
          value: '<br>',
        });
        str = str.slice(mObj[0].length);
        continue;
      }
      mObj = hiddenRegex.exec(str);
      if (mObj && mObj.index === 0) {
        // hidden
        blocks.push({
          type: 'hidden',
          value: mObj[1],
        });
        str = str.slice(mObj[0].length);
      }
    }
  }

  return blocks;
}

export function generateTextualRequest(optionsObject: ITemplateBlock[]): string {
  let str = '';

  optionsObject.forEach((block) => {
    if (block.type === 'tag') {
      str += '\n';
    } else if (block.type === 'text') {
      str += block.value || '';
    } else if (block.type === 'number-input') {
      str += `${block.value || ''}`;
    } else if (block.type === 'single-select') {
      const optionIndex = parseInt(block.value || '-1');
      const filteredValue = block.options && optionIndex !== -1 ? block.options[optionIndex] : '';
      str += `${filteredValue}`;
    } else if (block.type === 'wide-text-input') {
      str += `${block.value || ''}`;
    } else if (block.type === 'text-input') {
      str += `${block.value || ''}`;
    } else if (block.type === 'multi-select') {
      const filteredValues =
        block.options && block.values ? getOptionsByIndexes(block.options, block.values) : [];
      str += `${filteredValues.join(',')}`;
    } else if (block.type === 'hidden') {
      str += block.value || '';
    }
  });
  return str;
}

export function getOptionsWithSelection(block: ITemplateBlock, multi: boolean): string[] {
  const options = block.options || [];
  if (multi) {
    const selectedOptions = block.values ? getOptionsByIndexes(options, block.values) : [];
    return options.map((option) => (selectedOptions.includes(option) ? `~${option}` : option));
  } else {
    const index = parseInt(block.value || '-1') || -1;
    return options.map((option, optionIndex) => (optionIndex === index ? `~${option}` : option));
  }
}

export function hasSelectedOption(block: ITemplateBlock, option: string): boolean {
  const options = block.options || [];
  const selectedOptions = block.values ? getOptionsByIndexes(options, block.values) : [];
  return selectedOptions.includes(option);
}

export function templateBlockToString(optionsObject: ITemplateBlock[]): string {
  let str = '';

  optionsObject.forEach((block) => {
    if (block.type === 'tag') {
      str += '\n';
    } else if (block.type === 'text') {
      str += block.value;
    } else if (block.type === 'wide-text-input') {
      str += `(((${block.value || ''})))`;
    } else if (block.type === 'text-input') {
      str += `((${block.value || ''}))`;
    } else if (block.type === 'number-input') {
      str += `<<${block.value || ''}>>`;
    } else if (block.type === 'single-select' && block.options) {
      str += `[[${getOptionsWithSelection(block, false).join('|')}]]`;
    } else if (block.type === 'multi-select' && block.options) {
      str += `{{${getOptionsWithSelection(block, true).join('|')}}}`;
    } else if (block.type === 'hidden') {
      str += `<!--${block.value || ''}-->`;
    }
  });
  return str;
}
