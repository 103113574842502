import './style.scss';
import {Grid} from '@material-ui/core';
import {useState} from 'react';
import CreateCouponsForm from './CreateCouponsForm';
import CreateCouponsSummary from './CreateCouponsSummary';

export const CreateCoupons = (): JSX.Element => {
  const [couponName, setCouponName] = useState<string>('');
  const [campaignName, setCampaignName] = useState<string>('');
  const [internalNote, setInternalNote] = useState<string>('');
  const [discountValue, setDiscountValue] = useState<string>('');
  const [discountDetailsIcon, setDiscountDetailsIcon] = useState<boolean>(false);
  const [discountPercentage] = useState<string>('');
  const [discountFixed] = useState<string>('');
  const [couponUsage, setCouponUsage] = useState<string>();
  const [productPlan, setProductPlan] = useState<string>();
  const [startDate, setStartDate] = useState<Date | null | string>();
  const [endDate, setEndDate] = useState<Date | string | null>();

  return (
    <div className="coupon-create">
      <Grid container spacing={1}>
        <Grid
          className=""
          item
          style={{
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          xs={9}
        >
          <CreateCouponsForm
            couponName={couponName}
            setCouponName={setCouponName}
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            internalNote={internalNote}
            setInternalNote={setInternalNote}
            discountValue={discountValue}
            setDiscountValue={setDiscountValue}
            discountDetailsIcon={discountDetailsIcon}
            setDiscountDetailsIcon={setDiscountDetailsIcon}
            couponUsage={couponUsage}
            setCouponUsage={setCouponUsage}
            productPlan={productPlan}
            setProductPlan={setProductPlan}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Grid>
        <Grid
          className=""
          item
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          xs={3}
        >
          <CreateCouponsSummary
            couponName={couponName}
            campaignName={campaignName}
            internalNote={internalNote}
            discountValue={discountValue}
            discountDetailsIcon={discountDetailsIcon}
            discountPercetage={discountPercentage}
            discountFixed={discountFixed}
            couponUsage={couponUsage}
            productPlan={productPlan}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateCoupons;
