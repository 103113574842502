import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import '../../UserManagement/UsersTable/style.scss';
import TableRowItem from './TableItem';
import EnhancedTableHead from './EnhancedTableHead';
import LoadingSpinner from '../../UI/LoadingSpinner';
import {ITextModelCategoriesItem, ITextModelEntriesItem} from '../../../interfaces';

export interface ITableData {
  id: string;
  name: string;
  description: string;
  category: string;
  status: string;
  updatedUtc: string;
  createdUtc: string;
}

interface IProps {
  templates: ITextModelEntriesItem[];
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rows: number) => void;
  totalCount: number;
  isLoading: boolean;
  order: Order;
  setOrder: (order: Order) => void;
  orderBy: keyof ITableData | string;
  setOrderBy: (orderBy: keyof ITableData) => void;
  categories: ITextModelCategoriesItem[] | undefined;
}

type Order = 'asc' | 'desc';

const EntriesTable = (props: IProps): JSX.Element => {
  const {
    templates,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    isLoading,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    categories,
  } = props;
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ITableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <div className="users-table">
      <TableContainer className="users-table__container">
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={templates.length}
          />
          {!isLoading ? (
            <TableBody>
              {templates.map((row, index) => (
                <TableRowItem key={index} item={row} index={index} categories={categories} />
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <tr>
                <td>
                  <LoadingSpinner />
                </td>
              </tr>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {totalCount > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default EntriesTable;
