import React from 'react';
import {createStyles, TableCell, TableHead, TableRow, TableSortLabel} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ITableData} from '../index';

type Order = 'asc' | 'desc';
const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ITableData) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface HeadCell {
  id: keyof ITableData;
  label: string;
  isFiltered: boolean;
  filters?: string[];
}

const headCells: HeadCell[] = [
  {id: 'name', label: 'Command name', isFiltered: true},
  {id: 'status', label: 'Status', isFiltered: true},
  {id: 'type', label: 'Type', isFiltered: true},
  {id: 'updatedUtc', label: 'Last Updated', isFiltered: true},
  {id: 'createdUtc', label: 'Created', isFiltered: true},
];

const EnhancedTableHead = (props: EnhancedTableProps): JSX.Element => {
  const classes = useStyles();
  const {order, orderBy, onRequestSort} = props;
  const createSortHandler = (property: keyof ITableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="command-table__head">
      <TableRow className="command-table__row">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
            className="command-table__cell table-head-item"
          >
            {headCell.isFiltered ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
