import React, {useEffect, useState} from 'react';
import Title from './Title';
import './style.scss';
import AboutUs from './AboutUs';
import {Grid} from '@material-ui/core';
import ContactForm from './ContactForm';
import FAQ from './FAQ';
import TitleBg from '../../images/contact-us-header-bg.png';
import TitleBgLg from '../../images/contact-us-header-bg-lg.png';
import TitleBgMd from '../../images/contact-us-header-bg-md.png';
import TitleBgS from '../../images/contact-us-header-bg-s.png';
import useWindowDimensions from '../../helpers/windowResize';
import BackButton from '../UI/BackButton';

const ContactUs = (): JSX.Element => {
  const [titleBgImg, setTitleBgImg] = useState<string>(TitleBgLg);
  const screenWidth = useWindowDimensions().width;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (screenWidth >= 1920) {
      setTitleBgImg(TitleBg);
    } else if (screenWidth < 1920 && screenWidth > 1025) {
      setTitleBgImg(TitleBgLg);
    } else if (screenWidth <= 1025 && screenWidth > 768) {
      setTitleBgImg(TitleBgMd);
    } else {
      setTitleBgImg(TitleBgS);
    }
  }, [screenWidth]);
  return (
    <div className="contact-us">
      <BackButton prevPage />
      <img src={titleBgImg} alt="title background" className="contact-us__title-bg" />
      <Title />
      <Grid
        className="contact-us__about-form"
        container
        item
        xs={11}
        md={11}
        lg={8}
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        spacing={2}
      >
        <Grid
          className=""
          item
          style={{
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          xs={12}
          md={5}
        >
          <AboutUs />
        </Grid>
        <Grid
          className=""
          item
          style={{
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          xs={12}
          md={7}
        >
          <ContactForm />
        </Grid>
      </Grid>
      <FAQ />
    </div>
  );
};

export default ContactUs;
