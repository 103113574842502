import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  GET_SUBSCRIPTIONS_ADMIN_REQUEST,
  GET_SUBSCRIPTIONS_ADMIN_SUCCESS,
  GET_SUBSCRIPTIONS_ADMIN_FAILURE,
  POST_ADD_SUBSCRIPTION_ADMIN_REQUEST,
  POST_ADD_SUBSCRIPTION_ADMIN_SUCCESS,
  POST_ADD_SUBSCRIPTION_ADMIN_FAILURE,
} from '../actions/types/types';
import {IAdminSubscriptionState, ISubscriptions} from '../interfaces';

export const initialState = {
  Subscriptions: null,
  SubscriptionsLoading: false,
  SubscriptionsError: '',
  AddSubscription: null,
  AddSubscriptionLoading: false,
  AddSubscriptionError: '',
};

const handlerMap: any = {
  [GET_SUBSCRIPTIONS_ADMIN_REQUEST]: (state: IAdminSubscriptionState) => ({
    ...state,
    SubscriptionsLoading: true,
  }),

  [GET_SUBSCRIPTIONS_ADMIN_SUCCESS]: (
    state: IAdminSubscriptionState,
    action: {payload: ISubscriptions},
  ) => ({
    ...state,
    Subscriptions: action.payload,
    SubscriptionsError: '',
    SubscriptionsLoading: false,
  }),

  [GET_SUBSCRIPTIONS_ADMIN_FAILURE]: (
    state: IAdminSubscriptionState,
    action: {payload: string},
  ) => ({
    ...state,
    Subscriptions: null,
    SubscriptionsError: action.payload,
    SubscriptionsLoading: false,
  }),

  [POST_ADD_SUBSCRIPTION_ADMIN_REQUEST]: (state: IAdminSubscriptionState) => ({
    ...state,
    AddSubscriptionLoading: true,
  }),

  [POST_ADD_SUBSCRIPTION_ADMIN_SUCCESS]: (
    state: IAdminSubscriptionState,
    action: {payload: boolean},
  ) => ({
    ...state,
    AddSubscription: action.payload,
    AddSubscriptionError: '',
    AddSubscriptionLoading: false,
  }),

  [POST_ADD_SUBSCRIPTION_ADMIN_FAILURE]: (
    state: IAdminSubscriptionState,
    action: {payload: string},
  ) => ({
    ...state,
    AddSubscription: null,
    AddSubscriptionError: action.payload,
    AddSubscriptionLoading: false,
  }),
};

export default handleActions(handlerMap, initialState);
