import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './style.scss';
import {useActiveUser} from '../../../helpers/customHooks';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  getTextModelDetailsRequest,
  getTextModelEntryRequest,
  patchTextModelAutosaveRequest,
  postShareTextModelRequest,
  postUnshareTextModelRequest,
  setModelTemplateDetailsClear,
} from '../../../actions/textModel';
import {IState, ITextGenerationResultItem, IUserProfile} from '../../../interfaces';
import UiAlert from '../../UI/UiAlert';
import CloseIcon from '@material-ui/icons/Close';
import EditorComponent from './EditorComponent';
import {ITemplateBlock} from '../../../helpers/textModel';
import TemplateFooter from '../TemplateFooter';
import DateIcon from '../../../images/model-details-date-icon.svg';
import WordsIcon from '../../../images/model-details-words-icon.svg';
import moment from 'moment';
import {IconButton} from '@material-ui/core';
import PreviewEditor from './PreviewEditor';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import useWindowDimensions from '../../../helpers/windowResize';
import CreditHistory from '../CreditHistory';
import {getCommandsListRequest} from '../../../actions/commandsManagement';
import {DeltaStatic} from 'quill';
import debounce from 'lodash/debounce';
import {ReactComponent as HintIcon} from '../../../images/hint-icon.svg';
import Onboarding from '../../UI/Onboarding';
import cn from 'classnames';

interface IProps {
  modelId: string | undefined;
}

export interface IShortenEntry {
  name: string;
  userId: string;
  contentType: string;
  categoryId: string;
  data: ITemplateBlock[];
  createdUtc: string;
  id?: string;
  tags?: string;
  help?: string;
  description?: string;
  isPublished?: boolean;
  isPrivate?: boolean;
  updatedUtc?: string;
  entryImg?: string;
  status?: string;
  qualityType?: string;
  creativityIndicator?: number;
}

const TextModelEditComponent = (props: IProps): JSX.Element => {
  const userStatus = useActiveUser();
  const dispatch = useDispatch();
  const {modelId} = props;
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const [open, setOpen] = useState<boolean>(false);
  const [entry, setEntry] = useState<IShortenEntry | null>(null);
  const [content, setContent] = useState<string>('');
  const [generationStatus, setGenerationStatus] = useState<string>('');
  const [contentAsHtml, setContentAsHtml] = useState<string>('');
  const [contentWords, setContentWords] = useState<number>(0);
  const [isEntryPreviewOpen, setEntryPreviewOpen] = useState<boolean>(true);
  const [hintButtonEnabled, setHintButtonEnabled] = useState<boolean>(true);
  const [creditCount, setCreditCount] = useState<number>(0);
  const [aiEditingLoading, setAiEditingLoading] = useState<boolean>(false);
  const [isOnboardingOpen, setIsOnboardingOpen] = React.useState<boolean>(false);

  const user: IUserProfile = useSelector((state: IState) => state.userProfile.userProfile);
  const modelDetails = useSelector((state: IState) => state.textModel.textModelDetails);
  const entryItem = useSelector((state: IState) => state.textModel.singleTextModelEntry);
  const textModelDetailsHistorySelector = (state: IState) =>
    (state.textModel.textModelDetailsHistory as ITextGenerationResultItem[]) || [];
  const modelDetailsHistory = useSelector(textModelDetailsHistorySelector);
  const signalRStatus = useSelector((state: IState) => state.signalRStepTemplate.status);
  const textModel = useSelector((state: IState) => state.textModel);
  const generateModelResult = useSelector(
    (state: IState) => state.textModel.generateTextModelResult,
  );

  const pdfRef = useRef<HTMLDivElement>(null);
  const screenWidth = useWindowDimensions().width;

  const steps = [
    {
      // element: screenWidth > 767 ? '.text-model-edit' : '.text-model',
      element: '.editor',
      position: screenWidth > 768 ? 'left' : 'floating',
      intro: 'Use the text editor to customize generated content to your liking.',
      tooltipClass: 'onboarding-tooltip__text onboarding-tooltip__height',
      highlightClass: 'onboarding-highlight__text',
      disableInteraction: true,
    },
    {
      element: screenWidth > 1025 ? '.text-model__button-wr' : '.open-entry-preview',
      position: screenWidth > 1025 ? 'right' : 'top',
      intro:
        screenWidth > 1025
          ? "Click Regenerate if you don't like the text created by AI. Feel free to start afresh if needed!"
          : "Open the Editor and click Regenerate if you don't like the text created by AI. Feel free to start afresh if needed! ",
      tooltipClass: 'onboarding-tooltip__button onboarding-tooltip__height',
      highlightClass: 'onboarding-highlight__button',
      disableInteraction: true,
    },
    {
      element: '.ql-ai',
      position: 'bottom',
      intro:
        'Highlight any section of the text and click AI Assistant to refine and personalize the AI-generated content. Your creativity - your rules!',
      tooltipClass: 'onboarding-tooltip__ai onboarding-tooltip__height',
      highlightClass: 'onboarding-highlight__ai',
      disableInteraction: true,
    },
    {
      element: '.text-model__action-button',
      position: 'top-left-aligned',
      intro: 'Preview the final version of your content by clicking the Publish button.',
      tooltipClass: 'onboarding-tooltip__action onboarding-tooltip__height',
      highlightClass: 'onboarding-highlight__action',
      disableInteraction: true,
    },
  ];

  useEffect(() => {
    const storedProperties = localStorage.getItem('properties');
    if (storedProperties) {
      const parsedProperties = JSON.parse(storedProperties) as {selectedEntryId: string};
      dispatch(getTextModelEntryRequest({id: parsedProperties.selectedEntryId}));
    }
    if (screenWidth < 1180) {
      setEntryPreviewOpen(false);
    }
    const commandsPayload = {
      commandStatus: 'published',
    };
    dispatch(getCommandsListRequest(commandsPayload));
    return () => {
      dispatch(setModelTemplateDetailsClear());
      setEntry(null);
    };
  }, []);
  useEffect(() => {
    if (screenWidth < 1180) {
      setEntryPreviewOpen(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    if (modelDetailsHistory) {
      setCreditCount(
        modelDetailsHistory.map((item) => item.chargeAmount).reduce((prev, curr) => prev + curr, 0),
      );
    }
  }, [modelDetailsHistory]);

  useEffect(() => {
    if (modelId) {
      dispatch(getTextModelDetailsRequest({id: modelId}));
    }
  }, [modelId]);

  useEffect(() => {
    if (generateModelResult && !modelId) {
      navigate(`${pathname}-details/${generateModelResult.id}`);
    }
  }, [generateModelResult]);

  useEffect(() => {
    if (modelDetails) {
      const entryData: IShortenEntry = {
        data: modelDetails.textualTemplate,
        contentType: modelDetails.contentType,
        name: modelDetails.name,
        entryImg: modelDetails.entryImg,
        userId: modelDetails.userId,
        createdUtc: modelDetails.createdDate,
        status: modelDetails.status.name,
        categoryId: modelDetails.categoryId,
        qualityType: modelDetails.qualityType,
        creativityIndicator: modelDetails.creativityIndicator,
      };
      setEntry(entryData);
    } else if (entryItem) {
      const entryData: IShortenEntry = {
        data: entryItem.data,
        contentType: entryItem.contentType,
        categoryId: entryItem.categoryId,
        name: entryItem.name,
        entryImg: entryItem.entryImg ? entryItem.entryImg : entryItem.image,
        userId: entryItem.userId,
        createdUtc: entryItem.createdUtc,
        description: entryItem.description,
        qualityType: entryItem.qualityType,
        creativityIndicator: entryItem.creativityIndicator,
      };
      setEntry(entryData);
    }
  }, [modelDetails, entryItem]);

  useEffect(() => {
    if (signalRStatus && signalRStatus.modelId === modelId) {
      if (signalRStatus.chunk && !signalRStatus.isResult) {
        const newString = content + signalRStatus.chunk;
        setContent(newString);
      } else if (signalRStatus.isResult) {
        setGenerationStatus('Pending');
        setContent(signalRStatus.result ?? '');
        !textModel.loading && dispatch(getTextModelDetailsRequest({id: modelId}));
      }
    }
  }, [signalRStatus]);

  useEffect(() => {
    if (modelDetails && modelDetails.finalResult) {
      setGenerationStatus(modelDetails.status.name);
      setContent(modelDetails.finalResult);
    }
  }, [modelDetails]);

  useEffect(() => {
    setGenerationStatus(modelDetails?.status.name ?? 'Pending');
  }, [modelDetails?.status.name]);

  const onPublish = useCallback(() => {
    if (modelDetails?.status.name === 'Done') {
      dispatch(postUnshareTextModelRequest({textModelId: modelId, isDetails: true}));
    } else {
      dispatch(postShareTextModelRequest({textModelId: modelId, isDetails: true}));
    }
  }, [modelDetails?.status.name]);

  const autoSave = useCallback(
    debounce((value: string, words: number) => {
      if (modelDetails?.id && generationStatus === 'Pending') {
        const payload = {
          textModelId: modelDetails.id,
          finalResult: value,
          wordCount: words,
        };
        dispatch(patchTextModelAutosaveRequest(payload));
      }
    }, 2000),
    [modelDetails, generationStatus],
  );

  const setContentDataAndWords = useCallback(
    (content: DeltaStatic, words: number) => {
      autoSave(JSON.stringify(content), words);
      setContentWords(words);
    },
    [modelDetails?.id, generationStatus],
  );

  const handleOnboardingExit = () => {
    setIsOnboardingOpen(false);
  };

  const hintClassNames = cn({
    'button-hint': true,
    'button-hint-disabled': !hintButtonEnabled,
  });

  return (
    <>
      <div className={`text-model-full-wr`}>
        <div
          className={`text-model editing ${modelId ? 'with-editor' : 'no-editor'} ${
            modelDetails?.status.name === 'Done' ? 'done' : ''
          }`}
        >
          <div
            className={`text-model__container ${modelId ? 'edit' : 'create'} ${
              modelDetails?.status.name === 'Done' ? 'done' : ''
            }`}
          >
            {entry && (
              <>
                {modelDetails?.status.name !== 'Done' && (
                  <>
                    {screenWidth > 1024 ? (
                      <div
                        className={`component-white-wr text-model-edit ${modelId ? 'preview' : ''}`}
                      >
                        <PreviewEditor
                          entryItem={entry}
                          urlModeId={modelId}
                          generationStatus={generationStatus}
                          setGenerationStatus={setGenerationStatus}
                          title={modelDetails ? modelDetails.alias : entry.name}
                          content={content}
                          setContent={setContent}
                          contentAsHtml={contentAsHtml}
                          isEditorMode={Boolean(modelId)}
                          setEntryPreviewOpen={setEntryPreviewOpen}
                          hintButtonEnabled={hintButtonEnabled}
                          setHintButtonEnabled={setHintButtonEnabled}
                          status={modelDetails?.status.name === 'Done'}
                          aiEditingLoading={aiEditingLoading}
                          onTriggerHint={() => setIsOnboardingOpen(true)}
                        />
                      </div>
                    ) : (
                      <div
                        className={`component-white-wr text-model-edit ${
                          modelId ? 'preview' : ''
                        } ${!isEntryPreviewOpen ? 'closed' : 'opened'}`}
                      >
                        <PreviewEditor
                          entryItem={entry}
                          urlModeId={modelId}
                          generationStatus={generationStatus}
                          setGenerationStatus={setGenerationStatus}
                          title={modelDetails ? modelDetails.alias : entry.name}
                          contentAsHtml={contentAsHtml}
                          content={content}
                          setContent={setContent}
                          isEditorMode={Boolean(modelId)}
                          setEntryPreviewOpen={setEntryPreviewOpen}
                          hintButtonEnabled={hintButtonEnabled}
                          setHintButtonEnabled={setHintButtonEnabled}
                          status={modelDetails?.status.name === 'Done'}
                          aiEditingLoading={aiEditingLoading}
                        />
                      </div>
                    )}
                  </>
                )}
                {modelId && (
                  <>
                    <div
                      className={`component-white-wr text-model-edit editor ${
                        aiEditingLoading ? 'blocked' : ''
                      }`}
                    >
                      {user.creditCount === 0 && !userStatus.isExpired && (
                        <UiAlert
                          type={'warning'}
                          title={'You have used all your credits!'}
                          details={'To continue asking questions buy additional credits.'}
                          withButton={true}
                          buttonText={'Buy credits'}
                          buttonAction={() => navigate('/subscription-plan')}
                        />
                      )}
                      <EditorComponent
                        entryItem={entry}
                        urlModeId={modelId}
                        historyOpen={open}
                        setHistoryOpen={setOpen}
                        content={content}
                        setContentAsHtml={setContentAsHtml}
                        setContentWords={setContentDataAndWords}
                        pdfRef={pdfRef}
                        contentWords={contentWords}
                        setAiEditingLoading={setAiEditingLoading}
                      />
                    </div>
                    {screenWidth <= 1024 && modelDetails?.status.name !== 'Done' && (
                      <div className="open-entry">
                        <div
                          className="open-entry-preview"
                          onClick={() => setEntryPreviewOpen(true)}
                        >
                          <TuneRoundedIcon />
                        </div>
                        <IconButton
                          onClick={() => setIsOnboardingOpen(true)}
                          className={hintClassNames}
                        >
                          <HintIcon />
                        </IconButton>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {creditCount > 0 && open && (
            <div className={`credit-history__container ${open ? 'opened' : 'hide'}`}>
              <IconButton onClick={() => setOpen(false)} className="close-history-icon">
                <CloseIcon />
              </IconButton>
              <CreditHistory historyTemplate={modelDetailsHistory} />
            </div>
          )}
        </div>
        {modelId && (
          <div className="text-model__footer-wr">
            <div className="text-model__footer">
              <div>
                {modelDetails && (
                  <span className="content-title__info-item">
                    <img src={DateIcon} alt="Date Icon" />
                    {moment(modelDetails.createdDate).format(
                      screenWidth > 767 ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY',
                    )}
                  </span>
                )}
                {!!contentWords && contentWords > 0 && (
                  <span className="content-title__info-item">
                    <img src={WordsIcon} alt="Words Icon" />
                    {contentWords} words
                  </span>
                )}
              </div>
              <TemplateFooter
                onAction={onPublish}
                isButtonDisabled={user.creditCount === 0}
                text={'Publish'}
                retryStep={''}
                historyTemplate={modelDetailsHistory}
                open={open}
                setOpen={setOpen}
                isContentDone={!!content}
                status={modelDetails?.status.name}
                refContent={pdfRef.current}
                contentAsHtml={contentAsHtml}
                publicHash={modelDetails?.publicHash}
                contentId={modelDetails?.id}
                title={modelDetails?.name}
                isFavorite={modelDetails?.isFavorite}
              />
            </div>
          </div>
        )}
      </div>
      <Onboarding steps={steps} onExit={handleOnboardingExit} isOpen={isOnboardingOpen} />
    </>
  );
};

export default TextModelEditComponent;
