import React, {useEffect, useRef, useState} from 'react';
import Search from '../Search';
import UsersTable, {ITableData} from './UsersTable';
import './style.scss';
import {useDispatch, useSelector} from 'react-redux';
import * as userManagement from '../../actions/userManagement';
import * as companies from '../../actions/companies';
import {
  ICompaniesPayload,
  ICompanyArrayItem,
  ICompanyItem,
  IState,
  IUserManagementPayload,
} from '../../interfaces';
import LoadingSpinner from '../UI/LoadingSpinner';
import SingleSelectTextfield from '../UI/SingleSelectTextfield';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {IconButton} from '@material-ui/core';
import CustomAutocomplete from '../UI/CustomAutocomplete';
import {getAdminSubscriptionsStatusRequest} from '../../actions/adminSubscription';
import {ExportToExcel} from '../UI/ExportExel';

type Order = 'asc' | 'desc';

const UserManagement = (): JSX.Element => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [companiesArray, setCompaniesArray] = useState<ICompanyArrayItem[] | null>(null);
  const [selectedCompanies, setSelectedCompanies] = useState<ICompanyArrayItem[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>('All');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ITableData>('userCompany');
  const [filtersUpdated, setFiltersUpdated] = useState<Date | null>(null);
  const mockUserStatus = [
    {id: 'Active', name: 'Active'},
    {id: 'Inactive', name: 'Inactive'},
  ];
  const users = useSelector((state: IState) => state.userManagement);
  const companiesState = useSelector((state: IState) => state.companies);
  const tableRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const companiesPayload: ICompaniesPayload = {
      pageSize: 500,
    };
    dispatch(companies.getCompaniesStatusRequest(companiesPayload));
  }, []);
  useEffect(() => {
    dispatch(getAdminSubscriptionsStatusRequest());
  }, []);

  function loadUserManagementData(pageNumber: number) {
    const companiesArray = selectedCompanies.map((item: ICompanyArrayItem) => item.id.toString());
    const sortOrder = order === 'asc' ? 'Ascending' : 'Descending';
    const usersPayload: IUserManagementPayload = {
      pageNumber: pageNumber,
      pageSize: rowsPerPage,
      subscriptionStatus: selectedStatus,
      companies: companiesArray,
      sortCategory: orderBy,
      sortOrder: sortOrder,
    };
    const allUsersPayload: IUserManagementPayload = {
      subscriptionStatus: selectedStatus,
      companies: companiesArray,
      sortCategory: orderBy,
      sortOrder: sortOrder,
      pageSize: 1000,
    };
    dispatch(userManagement.getUserManagementRequest(usersPayload));
    dispatch(userManagement.getAllUserManagementRequest(allUsersPayload));
  }
  useEffect(() => {
    loadUserManagementData(page + 1);
  }, [page, filtersUpdated]);

  useEffect(() => {
    setPage(0);
    setFiltersUpdated(new Date());
  }, [selectedCompanies, selectedStatus, rowsPerPage, order, orderBy]);

  useEffect(() => {
    if (companiesState.companies && companiesState.companies.items.length) {
      const updatedArray = companiesState.companies.items.map((item: ICompanyItem) => ({
        id: item.companyName,
        name: item.companyName === '' ? 'No name company' : item.companyName,
      }));
      setCompaniesArray(updatedArray);
    }
  }, [companiesState]);

  function handleFiltering(userStatus: string | number) {
    if (userStatus === 0) {
      setSelectedStatus('All');
    } else {
      setSelectedStatus(userStatus.toString());
    }
  }

  function handleClearFilters() {
    setSelectedCompanies([]);
    setSelectedStatus('All');
  }

  return (
    <div className="user-management">
      <div className="user-management__title-wr">
        <h2 className="user-management__title">
          User management
          {users.userManagement && (
            <span className="user-management__title-total">
              {users.userManagement.totalCount} total
            </span>
          )}
        </h2>
        {users.allUserManagement && (
          <ExportToExcel tableData={users.allUserManagement.items} fileName={'Users'} />
        )}
      </div>
      <div className="user-management__filters-wr">
        <div className="user-management__dropdowns-wr">
          {companiesState.companies && companiesArray && (
            <CustomAutocomplete
              placeholder="Company"
              className={''}
              items={companiesArray}
              selectedItems={selectedCompanies}
              setSelectedItems={setSelectedCompanies}
              showNumberOfSelected={true}
              defaultItemText="All"
              isAllByDefault={true}
            />
          )}
          <SingleSelectTextfield
            inputLabel={'User status'}
            itemsList={mockUserStatus}
            updateFunction={(val: string) => handleFiltering(val)}
            isDefaultValue={true}
            defaultValueName={'All'}
            className="user-management__filter-item"
            selectedValue={selectedStatus}
          />
          <IconButton
            className="refresh-btn"
            aria-label="refresh filters"
            component="span"
            disabled={selectedCompanies.length === 0 && selectedStatus === 'All'}
            onClick={handleClearFilters}
          >
            <AutorenewIcon />
          </IconButton>
        </div>
        <Search disabled />
      </div>
      {users.userManagement ? (
        <UsersTable
          users={users.userManagement.items}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={users.userManagement.totalCount}
          isLoading={users.loading}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          tableRef={tableRef}
        />
      ) : (
        <span className="user-management__spinner-wr">
          <LoadingSpinner />
        </span>
      )}
    </div>
  );
};

export default UserManagement;
