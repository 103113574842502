import React, {ReactNode} from 'react';
import './style.scss';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

interface IProps {
  isOpen: boolean;
  title: string;
  buttons?: ReactNode;
  subtitle?: string;
  specificClass?: string;
  minHeight?: string;
  children?: ReactNode;
  onClose?: () => void;
  footer?: ReactNode;
  warning?: string;
}

const StandardModal = (props: IProps): JSX.Element => {
  const {
    isOpen,
    title,
    subtitle,
    specificClass,
    minHeight = 'auto',
    children,
    buttons,
    onClose,
    footer,
    warning,
  } = props;
  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="dialog-before-close"
        className={`standard-modal ${specificClass ? specificClass : ''}`}
        onClose={onClose}
      >
        <div className="standard-modal__modal-wr" style={{minHeight: minHeight}}>
          <div className="modal__window standard-modal__modal-window">
            <h5 className="standard-modal__title">{title}</h5>
            {subtitle && <p className="standard-modal__subtitle">{subtitle}</p>}
            {warning && <p className="standard-modal__warning">{warning}</p>}
            {children && children}
            {buttons && (
              <DialogActions className="standard-modal__modal-btn">{buttons}</DialogActions>
            )}
            {footer && footer}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default StandardModal;
