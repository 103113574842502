import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as companies from '../api/companies';

// REDUX
import * as companiesAction from '../actions/companies';
import * as companiesActionType from '../actions/types/types';

import {IGetUserManagementAction} from '../interfaces';
import {Action} from 'redux-actions';

export function* getCompanies(
  action: IGetUserManagementAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(companies.getCompanies, action.payload);
    yield put(companiesAction.getCompaniesStatusSuccess(payload));
  } catch (error) {
    yield put(companiesAction.getCompaniesStatusFailure(error));
  }
}

// WATCHERS
export function* watchCompanies(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(companiesActionType.GET_COMPANIES_REQUEST, getCompanies);
}
