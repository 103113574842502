import {Checkbox, withStyles} from '@material-ui/core';

const checkBoxStyles = () => ({
  root: {
    color: 'var(--main-orange)',
    '&$checked': {
      color: 'var(--main-orange)',
    },
  },
  checked: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
export default CustomCheckbox;
