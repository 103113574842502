import React, {useCallback, useEffect, useState} from 'react';
import {Button, ButtonGroup, Chip, MenuItem, Select, TextField} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useDispatch, useSelector} from 'react-redux';
import {IState} from '../../../interfaces';
import CustomCheckbox from '../../UI/CustomCheckbox';
import {hasSelectedOption, ITemplateBlock} from '../../../helpers/textModel';
import {getTextModelTemplateRequest} from '../../../actions/textModel';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {useLocation} from 'react-router-dom';

interface IProps {
  options: ITemplateBlock[];
  setDataOutside?: (arr: ITemplateBlock[]) => void;
  status?: boolean;
  generationStatus?: string;
}

function formatMenuItem(item: string) {
  return item === '' || item === ' ' ? 'none' : item;
}

const OptionsPreview = (props: IProps): JSX.Element => {
  const {options, setDataOutside, status = false, generationStatus} = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const [dataToDisplay, setDataToDisplay] = useState<ITemplateBlock[]>([]);
  const textModelTemplates = useSelector((state: IState) => state.textModel.textModelTemplates);

  useEffect(() => {
    if (!textModelTemplates) {
      dispatch(getTextModelTemplateRequest());
    }
  }, [textModelTemplates]);

  useEffect(() => {
    const modifiedData = options;
    setDataToDisplay(modifiedData);
    setDataOutside && setDataOutside(modifiedData);
  }, [options]);

  const handleSelect = useCallback(
    (block: ITemplateBlock, value: string, index: number) => {
      const updatedArray: ITemplateBlock[] = [...dataToDisplay];

      const optionIndex = parseInt(value);
      updatedArray[index] = {
        ...block,
        value: isNaN(optionIndex) ? '' : optionIndex.toString(),
      };

      setDataOutside && setDataOutside(updatedArray);
      setDataToDisplay(updatedArray);
    },
    [dataToDisplay],
  );

  const handleMultiSelect = useCallback(
    (block: ITemplateBlock, values: string[], index: number) => {
      const updatedArray: ITemplateBlock[] = [...dataToDisplay];

      updatedArray[index] = {
        ...block,
        values: block.options ? values.map((v) => parseInt(v)) : [],
      };

      setDataOutside && setDataOutside(updatedArray);
      setDataToDisplay(updatedArray);
    },
    [dataToDisplay],
  );

  const handleChangeInp = useCallback(
    (block: ITemplateBlock, value: string, index: number) => {
      const updatedArray: ITemplateBlock[] = [...dataToDisplay];

      updatedArray[index] = {
        ...block,
        value,
      };

      setDataOutside && setDataOutside(updatedArray);
      setDataToDisplay(updatedArray);
    },
    [dataToDisplay],
  );

  const controlToShow = useCallback(
    (data: ITemplateBlock, elIndex: number) => {
      switch (data.type) {
        case 'text':
          return (
            <span className="entry-preview__text-field" key={`${elIndex}-${data.type}`}>
              {data.value}
            </span>
          );
        case 'tag':
          return <br key={`${elIndex}-${data.type}`} />;
        case 'number-input':
          return (
            <TextField
              id="standard-number"
              value={data.value}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChangeInp(data, e.target.value, elIndex)}
              variant="outlined"
              key={`${elIndex}-${data.type}`}
              className={`entry-preview__text-input`}
              disabled={status || generationStatus === 'Generating'}
            />
          );
        case 'wide-text-input':
          return (
            <TextField
              id="standard-text"
              value={data.value}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChangeInp(data, e.target.value, elIndex)}
              variant="outlined"
              key={`${elIndex}-${data.type}`}
              multiline
              disabled={status || generationStatus === 'Generating'}
              className={`entry-preview__wide-text-input`}
              inputProps={{style: {width: '100%'}}}
            />
          );
        case 'text-input':
          return (
            <TextField
              id="standard-text"
              value={data.value}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChangeInp(data, e.target.value, elIndex)}
              variant="outlined"
              key={`${elIndex}-${data.type}`}
              multiline
              disabled={status || generationStatus === 'Generating'}
              className={`entry-preview__text-input`}
            />
          );
        case 'single-select':
          return (
            data.options && (
              <>
                {data.options.length < 3 ? (
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group"
                    key={`${elIndex}-${data.type}`}
                    className={`entry-preview__form-select`}
                    disabled={status || generationStatus === 'Generating'}
                  >
                    {data.options.map((item, idx) => (
                      <Button
                        variant={data.value === String(idx) ? 'contained' : 'outlined'}
                        key={idx}
                        onClick={() => handleSelect(data, String(idx), elIndex)}
                        className="entry-preview__form-option"
                      >
                        {formatMenuItem(item)}
                      </Button>
                    ))}
                  </ButtonGroup>
                ) : (
                  <Select
                    MenuProps={{
                      variant: 'menu',
                      getContentAnchorEl: null,
                    }}
                    variant="outlined"
                    IconComponent={ExpandMoreIcon}
                    className={`entry-preview__form-select`}
                    labelId="industries-select"
                    value={data.value ? data.value : ''}
                    onChange={(e) => handleSelect(data, e.target.value as string, elIndex)}
                    key={`${elIndex}-${data.type}`}
                    disabled={status || generationStatus === 'Generating'}
                  >
                    {data.options.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={String(idx)}
                        className="entry-preview__form-option"
                      >
                        {formatMenuItem(item)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </>
            )
          );
        case 'multi-select':
          return (
            data.options && (
              <>
                {data.options.length < 3 ? (
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group"
                    key={`${elIndex}-${data.type}`}
                    className={`entry-preview__form-select`}
                    disabled={status || generationStatus === 'Generating'}
                  >
                    {data.options.map((item, idx) => (
                      <Button
                        variant={data.values?.includes(idx) ? 'contained' : 'outlined'}
                        key={idx}
                        onClick={() => {
                          if (!data.values) return;
                          const optionsArr = [...data.values];
                          if (data.values?.includes(idx)) {
                            const ind = optionsArr.indexOf(idx);
                            ind !== -1 && optionsArr.splice(ind, 1);
                          } else {
                            optionsArr.push(idx);
                          }
                          handleMultiSelect(
                            data,
                            optionsArr.map((i) => i.toString()),
                            elIndex,
                          );
                        }}
                        className="entry-preview__form-option"
                        startIcon={
                          data.values && data.values.includes(idx) ? <DoneIcon /> : <AddIcon />
                        }
                      >
                        {formatMenuItem(item)}
                      </Button>
                    ))}
                  </ButtonGroup>
                ) : (
                  <Select
                    MenuProps={{
                      variant: 'menu',
                      getContentAnchorEl: null,
                    }}
                    variant="outlined"
                    multiple
                    IconComponent={ExpandMoreIcon}
                    className={`entry-preview__form-select multi-select ${
                      location.pathname.includes('text-model-details') ? 'narrow' : ''
                    }`}
                    value={data.values ? data.values.map((v) => v.toString()) : []}
                    displayEmpty
                    onChange={(e) => handleMultiSelect(data, e.target.value as string[], elIndex)}
                    renderValue={() => (
                      <>
                        {data.values &&
                          data.values.map(
                            (value) =>
                              data.options && (
                                <Chip
                                  key={value}
                                  label={data.options[value]}
                                  onDelete={() => {
                                    if (!data.values) return;
                                    const optionsArr = [...data.values];
                                    if (data.values?.includes(value)) {
                                      const ind = optionsArr.indexOf(value);
                                      ind !== -1 && optionsArr.splice(ind, 1);
                                    }
                                    handleMultiSelect(
                                      data,
                                      optionsArr.map((i) => i.toString()),
                                      elIndex,
                                    );
                                  }}
                                  deleteIcon={
                                    <CloseIcon onMouseDown={(event) => event.stopPropagation()} />
                                  }
                                />
                              ),
                          )}
                      </>
                    )}
                    key={`${elIndex}-${data.type}`}
                    disabled={status || generationStatus === 'Generating'}
                  >
                    {data.options.map((item, idx) => (
                      <MenuItem
                        key={`${item + idx}`}
                        value={String(idx)}
                        className="modal-form__option"
                        disabled={status || generationStatus === 'Generating'}
                      >
                        <CustomCheckbox checked={hasSelectedOption(data, item)} />
                        {formatMenuItem(item)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </>
            )
          );
      }
    },
    [dataToDisplay],
  );

  return (
    <div className="entry-preview">
      {dataToDisplay.length ? dataToDisplay.map((item, idx) => controlToShow(item, idx)) : <></>}
    </div>
  );
};

export default OptionsPreview;
