import React, {ReactNode, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  MenuItem,
  Menu,
  IconButton,
  Avatar,
  Hidden,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import * as userSettingsAction from '../../actions/userSettings';
import {postUserLoginLastDateRequest} from '../../actions/userProfile';
import Logo from '../../images/Logo-dark.svg';
import {IState} from '../../interfaces';
import ImgAvatar from '../../images/empty-avatar.png';
import SideBar from '../../components/SideBar';
import './style.scss';
import {getIconSrc} from '../../helpers/helper';
import {useActiveUser} from '../../helpers/customHooks';
import {clearCreatedModel, clearModelDetails} from '../../actions/textModel';
import {ReactComponent as HintIcon} from '../../images/hint-icon.svg';
import Onboarding from '../UI/Onboarding';
import useWindowDimensions from '../../helpers/windowResize';

interface IProps {
  showLogo: boolean;
  adminSide?: boolean;
  specialClass?: string;
  backButton?: ReactNode;
}
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#fff',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    big: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    large: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  }),
);

export const Header = ({showLogo, adminSide, specialClass, backButton}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const {isInActiveUser, isExpired} = useActiveUser();
  const navigate = useNavigate();
  const screenWidth = useWindowDimensions().width;

  const user = useSelector((state: IState) => state.userProfile.userProfile);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);
  const [openSideMenu, setOpenSideMenu] = React.useState<boolean>(false);
  const [anchorElSide, setAnchorElSide] = React.useState<null | HTMLElement>(null);
  const [isOnboardingOpen, setIsOnboardingOpen] = React.useState<boolean>(false);

  useEffect(() => {
    if (user.userRole === 'Consultant' || user.userRole === 'Admin') {
      dispatch(userSettingsAction.getUserSettingsRequest());
    }
    if (user && !window.sessionStorage?.getItem('userIsLoggedIn')) {
      window.sessionStorage.setItem('userIsLoggedIn', 'true');
      dispatch(postUserLoginLastDateRequest({}));
    }
  }, []);

  const isMenuOpen = Boolean(anchorEl);
  const isSideMenuOpen = Boolean(anchorElSide);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };

  const handleSideMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSide(event.currentTarget);
    setOpenSideMenu(!openSideMenu);
  };

  const iconSrc = getIconSrc(user.currentUserSubscription);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(!openMenu);
  };

  const handleSideMenuClose = () => {
    setAnchorElSide(null);
    setOpenSideMenu(!openSideMenu);
  };

  const handleSignOut = () => {
    navigate('/logout');
    window.sessionStorage.removeItem('userIsLoggedIn');
  };

  const routeToTextModel = () => {
    dispatch(clearCreatedModel());
    dispatch(clearModelDetails());
    navigate('/text-model-create');
  };
  const menuId = 'primary-search-account-menu';
  const steps = [
    {
      title: 'Welcome to Dashboard!',
      element: '.dashboard__grid',
      position: 'top-right-aligned',
      intro: 'Track your content generation statistics on the Dashboard.',
      tooltipClass: 'onboarding-tooltip__dashboard ',
      highlightClass: 'onboarding-highlight__dashboard',
      disableInteraction: true,
    },
    {
      element: screenWidth <= 599 ? '.onboarding-burger' : '.header__btn',
      intro:
        screenWidth <= 599
          ? 'Press profile and find New Content - unlock your creativity and start writing.'
          : 'New Content - unlock your creativity and start writing.',
      position: 'bottom-right-aligned',
      tooltipClass: 'onboarding-tooltip__content onboarding-tooltip__height',
      highlightClass: 'onboarding-highlight__content',
      disableInteraction: true,
    },
  ];

  const handleOnboardingExit = () => {
    setIsOnboardingOpen(false);
  };

  const renderSideMenu = (
    <>
      <Menu
        anchorEl={anchorElSide}
        open={isSideMenuOpen}
        onClose={handleSideMenuClose}
        elevation={0}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            height: '100vh',
            maxHeight: 'calc(100vh - 17px )',
            width: '200px',
          },
        }}
        className="side-menu"
      >
        <IconButton onClick={handleSideMenuClose}>
          <CloseIcon fontSize="large" className="section-profile__mobile-menu" />
        </IconButton>

        <SideBar url="/main" location={location} handleMenu={handleSideMenuClose} />
      </Menu>
    </>
  );
  const renderMenu = (
    <>
      <Menu
        anchorEl={anchorEl}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        className={`headerDropDown ${!adminSide || specialClass ? 'headerDropDown--client' : ''}`}
        onClose={handleMenuClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {(!adminSide || specialClass) && (
          <div>
            {!isInActiveUser && !isExpired && (
              <Hidden only={['sm', 'md', 'xl', 'lg']}>
                <Button
                  onClick={routeToTextModel}
                  className="header__btn orange-btn orange-btn-hover"
                >
                  New Content
                </Button>
              </Hidden>
            )}
            {/*<div className="headerDropDown__sidebar">
              <Hidden only={['md', 'lg']}>
                <SideBar url="/main" location={location} handleMenu={handleMenuClose} />
              </Hidden>
            </div>*/}
          </div>
        )}

        {user.userRole === 'Consultant' || user.userRole === 'Admin' ? (
          <div>
            <NavLink to="/admin/user-management">
              <MenuItem onClick={handleMenuClose}>User management</MenuItem>
            </NavLink>
          </div>
        ) : (
          <div>
            <div>
              <div className={classes.root}>
                {user.avatar ? (
                  <Avatar alt="user" src={user.avatar} className={classes.big} />
                ) : (
                  <img src={ImgAvatar} alt="user-pic" />
                )}
                <div className="personeInfo">
                  <p className="personName personName--bold">{`${user.fullName.slice(0, 30)}${
                    user.fullName.length > 30 ? '...' : ''
                  }`}</p>
                  <p className="personEmail">{user.userEmail}</p>
                </div>
              </div>
            </div>
            <span className="divider"></span>
            <NavLink to="/account/profile">
              <MenuItem className="headerDropDown__link" onClick={handleMenuClose}>
                Account
              </MenuItem>
            </NavLink>
            <MenuItem className="headerDropDown__link" onClick={handleSignOut}>
              Sign out
            </MenuItem>
            <span className="divider"></span>
          </div>
        )}
        {(user.userRole === 'Consultant' || user.userRole === 'Admin') && (
          <div>
            <MenuItem onClick={handleMenuClose}>
              <NavLink
                className="list-item__link"
                to={
                  user.userRole === 'Consultant' || user.userRole === 'Admin'
                    ? '/admin/admin-settings'
                    : '/account/settings'
                }
              >
                Settings
              </NavLink>
            </MenuItem>
            <MenuItem className="headerDropDown__link" onClick={handleSignOut}>
              Sign out
            </MenuItem>
          </div>
        )}
        {user.userRole === 'Reader' && (
          <div>
            <NavLink to="/help-center">
              <MenuItem className="headerDropDown__link" onClick={handleMenuClose}>
                Help Center
              </MenuItem>
            </NavLink>
            <NavLink to="/contact-us">
              <MenuItem className="headerDropDown__link" onClick={handleMenuClose}>
                Contact Us
              </MenuItem>
            </NavLink>
          </div>
        )}
      </Menu>
    </>
  );

  return (
    <>
      <AppBar position="relative" className={`${classes.headerContainer} header`}>
        <div className="gridContainer">
          <Toolbar className="headerWr" disableGutters={true}>
            {!location.pathname.includes('/text-model') ||
              (!adminSide && (
                <IconButton disableRipple={true} onClick={handleSideMenuOpen}>
                  <MenuIcon
                    fontSize="large"
                    className={`section-profile__mobile-menu ${openSideMenu ? 'opacity' : ''}`}
                  />
                </IconButton>
              ))}
            {location.pathname.includes('/text-model') ||
              (!adminSide && (
                <Hidden only={['md', 'lg', 'xl']}>
                  <div className="header__menu-btns">
                    <IconButton disableRipple={true} onClick={handleSideMenuOpen}>
                      <MenuIcon
                        fontSize="large"
                        className={`section-profile__mobile-menu ${openSideMenu ? 'opacity' : ''}`}
                      />
                    </IconButton>
                    {location.pathname.includes('dashboard') && (
                      <IconButton
                        onClick={() => setIsOnboardingOpen(true)}
                        className="button-hint mobile"
                      >
                        <HintIcon />
                      </IconButton>
                    )}
                  </div>
                </Hidden>
              ))}
            {showLogo && (
              <NavLink className="header__logo-link" to={`/main/dashboard`}>
                <img src={Logo} alt="Logo Devtorium" />
              </NavLink>
            )}
            {backButton && backButton}
            <div className={classes.grow} />
            {!adminSide && (
              <>
                {!isInActiveUser && !isExpired && (
                  <Hidden only={['xs']}>
                    <div className="header__btns">
                      <Button
                        onClick={routeToTextModel}
                        className="header__btn orange-btn orange-btn-hover"
                      >
                        New Content
                      </Button>
                      {location.pathname.includes('dashboard') && (
                        <IconButton
                          onClick={() => setIsOnboardingOpen(true)}
                          className="button-hint"
                        >
                          <HintIcon />
                        </IconButton>
                      )}
                    </div>
                  </Hidden>
                )}
                {/*{user.creditCount >= 1 && (
                  <Hidden only={['sm', 'md', 'xl', 'lg']}>
                    <span className="mobile-credits">
                      <img src={iconSrc} alt="credits icon" />
                      {user.creditCount}
                    </span>
                  </Hidden>
                )}*/}
              </>
            )}
            <div className="section-profile">
              {/*{!adminSide && user.creditCount >= 1 && (
                <Hidden only={['xs', 'md', 'xl', 'lg']}>
                  <span className="mobile-credits">
                    <img src={iconSrc} alt="credits icon" />
                    {user.creditCount}
                  </span>
                </Hidden>
              )}*/}
              {adminSide === true && !specialClass ? (
                <>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <div className={classes.root}>
                      {user.avatar ? (
                        <Avatar alt="user" src={user.avatar} className={classes.large} />
                      ) : (
                        <img src={ImgAvatar} alt="user-pic" />
                      )}
                    </div>
                  </IconButton>
                  <IconButton
                    disableRipple={true}
                    style={{backgroundColor: 'transparent'}}
                    onClick={handleProfileMenuOpen}
                  >
                    <p className="personName">{`${user.fullName.slice(0, 30)}${
                      user.fullName.length > 30 ? '...' : ''
                    }`}</p>
                    <ExpandMoreIcon fontSize="small" />
                  </IconButton>
                </>
              ) : (
                <>
                  <Hidden only={['xs']}>
                    <IconButton
                      disableRipple={true}
                      style={{backgroundColor: 'transparent', padding: '6px'}}
                      onClick={handleProfileMenuOpen}
                    >
                      <div className="credits-block">
                        {user.creditCount >= 1 && user.userRole == 'Reader' && (
                          <span className="credits">
                            <img src={iconSrc} alt="credits icon" />
                            {user.creditCount}
                          </span>
                        )}
                      </div>
                      <div className={classes.root}>
                        {user.avatar ? (
                          <Avatar alt="user" src={user.avatar} className={classes.large} />
                        ) : (
                          <img src={ImgAvatar} alt="user-pic" />
                        )}
                      </div>
                    </IconButton>
                  </Hidden>
                  <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <div className="credits-block">
                      {user.creditCount >= 1 && user.userRole == 'Reader' && (
                        <span className="credits">
                          <img src={iconSrc} alt="credits icon" />
                          {user.creditCount}
                        </span>
                      )}
                    </div>
                    <IconButton
                      disableRipple={true}
                      onClick={handleProfileMenuOpen}
                      className="onboarding-burger"
                    >
                      {!openMenu && (
                        <>
                          {user.avatar ? (
                            <Avatar
                              alt="user"
                              src={user.avatar}
                              className="section-profile__mobile-menu"
                            />
                          ) : (
                            <img
                              src={ImgAvatar}
                              alt="user-pic"
                              className="section-profile__mobile-menu"
                            />
                          )}
                        </>
                      )}
                      {openMenu && (
                        <CloseIcon fontSize="large" className="section-profile__mobile-menu" />
                      )}
                    </IconButton>
                  </Hidden>
                </>
              )}
            </div>
          </Toolbar>
        </div>
      </AppBar>
      <Onboarding steps={steps} onExit={handleOnboardingExit} isOpen={isOnboardingOpen} />
      {renderMenu}
      {renderSideMenu}
    </>
  );
};
