import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import './style.scss';

interface ISwitcherProps {
  onSwitch: (s: boolean) => void;
  className: string;
}

const Switcher: React.FC<ISwitcherProps> = ({onSwitch, className}) => {
  const [toggleMonthly, setToggleMonthly] = useState<boolean>(true);

  const switcherClick = () => {
    setToggleMonthly(!toggleMonthly);
    onSwitch(toggleMonthly);
  };

  return (
    <div className={className}>
      <div className="switcher-wr">
        <Button
          variant="contained"
          className={'active-' + Boolean(toggleMonthly)}
          onClick={switcherClick}
        >
          Monthly
        </Button>
        <Button
          variant="contained"
          className={'active-' + Boolean(!toggleMonthly)}
          onClick={switcherClick}
        >
          Yearly
        </Button>
      </div>
    </div>
  );
};

export default Switcher;
