import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  POST_COMMAND_CREATE_REQUEST,
  POST_COMMAND_CREATE_SUCCESS,
  POST_COMMAND_CREATE_FAILURE,
  GET_COMMAND_ITEM_SUCCESS,
  GET_COMMAND_ITEM_REQUEST,
  GET_COMMAND_ITEM_FAILURE,
  GET_COMMANDS_LIST_REQUEST,
  GET_COMMANDS_LIST_SUCCESS,
  GET_COMMANDS_LIST_FAILURE,
  PATCH_PUBLISH_COMMAND_REQUEST,
  PATCH_PUBLISH_COMMAND_SUCCESS,
  PATCH_PUBLISH_COMMAND_FAILURE,
  PUT_COMMAND_UPDATE_REQUEST,
  PUT_COMMAND_UPDATE_SUCCESS,
  PUT_COMMAND_UPDATE_FAILURE,
  DELETE_COMMAND_REQUEST,
  DELETE_COMMAND_SUCCESS,
  DELETE_COMMAND_FAILURE,
  CLEAR_COMMAND_ITEM,
  SET_CREATE_COMMAND_NAVIGATE,
} from '../actions/types/types';
import {
  ICommandErrorAction,
  ICommandsState,
  ICreateCommandResponseAction,
  IDeleteCommandResponseAction,
  IGetCommandResponseAction,
  IGetCommandsListResponseAction,
  IPublishCommandsResponseAction,
  IUpdateCommandsResponseAction,
} from '../interfaces';

export const initialState: ICommandsState = {
  commandCreateResponse: null,
  getCommandItemResponse: null,
  commandsListResponse: null,
  publishCommandResponse: null,
  updateCommandResponse: null,
  deleteCommandResponse: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_COMMAND_ITEM_REQUEST]: (state: ICommandsState) => ({
    ...state,
    loading: true,
  }),
  [GET_COMMAND_ITEM_SUCCESS]: (state: ICommandsState, action: IGetCommandResponseAction) => ({
    ...state,
    getCommandItemResponse: action.payload,
    loading: false,
    error: '',
  }),
  [GET_COMMAND_ITEM_FAILURE]: (state: ICommandsState, action: ICommandErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [POST_COMMAND_CREATE_REQUEST]: (state: ICommandsState) => ({
    ...state,
    loading: true,
  }),
  [POST_COMMAND_CREATE_SUCCESS]: (state: ICommandsState, action: ICreateCommandResponseAction) => ({
    ...state,
    commandCreateResponse: action.payload,
    loading: false,
    error: '',
  }),
  [POST_COMMAND_CREATE_FAILURE]: (state: ICommandsState, action: ICommandErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
  [SET_CREATE_COMMAND_NAVIGATE]: (state: ICommandsState) => ({
    ...state,
    commandCreateResponse: null,
  }),

  [GET_COMMANDS_LIST_REQUEST]: (state: ICommandsState) => ({
    ...state,
    loading: true,
  }),
  [GET_COMMANDS_LIST_SUCCESS]: (state: ICommandsState, action: IGetCommandsListResponseAction) => ({
    ...state,
    commandsListResponse: action.payload,
    loading: false,
    error: '',
  }),
  [GET_COMMANDS_LIST_FAILURE]: (state: ICommandsState, action: ICommandErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [PATCH_PUBLISH_COMMAND_REQUEST]: (state: ICommandsState) => ({
    ...state,
    loading: true,
  }),
  [PATCH_PUBLISH_COMMAND_SUCCESS]: (
    state: ICommandsState,
    action: IPublishCommandsResponseAction,
  ) => ({
    ...state,
    publishCommandResponse: action.payload,
    loading: false,
    error: '',
  }),
  [PATCH_PUBLISH_COMMAND_FAILURE]: (state: ICommandsState, action: ICommandErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [PUT_COMMAND_UPDATE_REQUEST]: (state: ICommandsState) => ({
    ...state,
    loading: true,
  }),
  [PUT_COMMAND_UPDATE_SUCCESS]: (state: ICommandsState, action: IUpdateCommandsResponseAction) => ({
    ...state,
    updateCommandResponse: action.payload,
    loading: false,
    error: '',
  }),
  [PUT_COMMAND_UPDATE_FAILURE]: (state: ICommandsState, action: ICommandErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [DELETE_COMMAND_REQUEST]: (state: ICommandsState) => ({
    ...state,
    loading: true,
  }),
  [DELETE_COMMAND_SUCCESS]: (state: ICommandsState, action: IDeleteCommandResponseAction) => ({
    ...state,
    deleteCommandResponse: action.payload,
    loading: false,
    error: '',
  }),
  [DELETE_COMMAND_FAILURE]: (state: ICommandsState, action: ICommandErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [CLEAR_COMMAND_ITEM]: (state: ICommandsState) => ({
    ...state,
    getCommandItemResponse: null,
    deleteCommandResponse: null,
    error: '',
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
