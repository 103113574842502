import React from 'react';
import ContentImg from '../../../images/generated-content-img.svg';
import {List} from '@material-ui/core';
import ContentItem from './ContentItem';
import {ITextModelsItem} from '../../../interfaces';

interface IProps {
  data?: ITextModelsItem[];
}

const GeneratedContent = (props: IProps): JSX.Element => {
  const {data} = props;

  return (
    <>
      <div className="dashboard__subtitle">Recently Generated Content</div>
      <div className="dashboard__chart">
        {data ? (
          <List className="dashboard__list ">
            {data.slice(0, 4).map((item: ITextModelsItem, index: number) => (
              <ContentItem key={index + 'item'} content={item} index={index} />
            ))}
          </List>
        ) : (
          <>
            <img className="image" src={ContentImg} alt="content" />
            <div className="dashboard__count-descr">
              Start to create the best content with <b>Marquètte</b>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GeneratedContent;
