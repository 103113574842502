import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {useSelector} from 'react-redux';
import {IState, IUserManagementItem} from '../../../../interfaces';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';

interface IProps {
  item: IUserManagementItem;
  index: number;
}

const TableRowItem = (props: IProps): JSX.Element => {
  const {item, index} = props;
  const navigate = useNavigate();

  const singleUser = useSelector((state: IState) => state.userManagementProfile);

  const isEndlessSubscription =
    singleUser &&
    singleUser.userManagementProfile &&
    singleUser.userManagementProfile.currentUserSubscription &&
    singleUser.userManagementProfile.currentUserSubscription.subscriptionId === 'endless';
  const userStatus =
    item.userId === singleUser.userManagementProfile?.userId && isEndlessSubscription;

  function onCollapseOpen() {
    navigate(`/admin/user-management/${item.userId}`);
  }

  return (
    <>
      <TableRow hover key={index} className="table-row" onClick={onCollapseOpen}>
        <TableCell component="th" scope="row" className="user-name">
          {item.fullName}
        </TableCell>
        <TableCell>{item.userEmail}</TableCell>
        <TableCell>{item.userCompany}</TableCell>
        <TableCell
          className={`user-status ${
            userStatus ? 'active' : item.subscriptionStatus.toLocaleLowerCase()
          }`}
        >
          <p>{`${userStatus ? 'active' : item.subscriptionStatus} user`}</p>
        </TableCell>
        <TableCell>
          {item.createdDateTime ? moment(item.createdDateTime).format('MMM DD, YYYY') : '-'}
        </TableCell>
        <TableCell>
          {item.lastQuestionCreatedDateTime
            ? moment(item.lastQuestionCreatedDateTime).format('MMM DD, YYYY')
            : '-'}
        </TableCell>
        <TableCell>
          {item.lastLoginDateTime ? moment(item.lastLoginDateTime).format('MMM DD, YYYY') : '-'}
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableRowItem;
