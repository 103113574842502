import React, {useEffect, useState} from 'react';
import {
  Button,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import './style.scss';
import OptionsEdit from '../../TemplateManagement/EntryDetails/OptionsEdit';
import {ITemplateBlock} from '../../../helpers/textModel';
import {useDispatch, useSelector} from 'react-redux';
import {
  deleteCommandRequest,
  getCommandItemRequest,
  postCommandCreateRequest,
  putCommandUpdateRequest,
  setCreateCommandNavigate,
} from '../../../actions/commandsManagement';
import {IState} from '../../../interfaces';
import OptionsPreview from '../../TemplateManagement/EntryDetails/OptionsPreview';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useNavigate, useParams} from 'react-router-dom';
import ModalWithImage from '../../UI/ModalWithImage';
import {ReactComponent as ModalIcon} from '../../../images/askQuestion-large.svg';
import LoadingSpinner from '../../UI/LoadingSpinner';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShowPreviewButton from '../../UI/ShowPreviewButton';
import {containsSpecificStrings} from '../../../helpers/helper';

const CreateCommand = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const commandId = params.commandId;

  const commandItem = useSelector(
    (state: IState) => state.commandsManagement.getCommandItemResponse,
  );
  const commandState = useSelector((state: IState) => state.commandsManagement);

  const [commandName, setCommandName] = useState<string>('');
  const [options, setOptions] = useState<ITemplateBlock[]>([]);
  const [insertion, setInsertion] = useState<string>('');
  const [errorField, setErrorField] = useState<{
    name: boolean;
    options: boolean;
    resultInsertion: boolean;
  }>({
    name: false,
    options: false,
    resultInsertion: false,
  });
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showClonePopup, setShowClonePopup] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false);

  const isLoading = commandState.loading;

  const handleConfirmLeave = () => {
    setShowPopup(false);
    setCommandName(commandItem ? commandItem.name : '');
    setOptions(commandItem ? commandItem.data : []);
    navigate('/admin/commands-management');
  };

  useEffect(() => {
    if (commandId) {
      dispatch(getCommandItemRequest({id: commandId}));
    }
    if (commandState.commandCreateResponse) {
      dispatch(setCreateCommandNavigate());
    }
  }, []);

  useEffect(() => {
    if (!commandId) {
      if (commandItem) {
        setCommandName(commandItem.name);
        setOptions(commandItem.data);
        setInsertion(commandItem.resultInsertion);
        //navigate(`/admin/commands-management/details/${commandItem.id}`);
      }
    } else if (commandItem && commandItem.id === commandId) {
      setCommandName(commandItem.name);
      setOptions(commandItem.data);
      setInsertion(commandItem.resultInsertion);
    }
  }, [commandItem]);

  //useEffect(() => {
  //  if (showPopup) {
  //    window.addEventListener('beforeunload', handleBeforeUnload);
  //  } else {
  //    window.removeEventListener('beforeunload', handleBeforeUnload);
  //  }

  //  return () => {
  //    window.removeEventListener('beforeunload', handleBeforeUnload);
  //  };
  //}, [isPreviewMode, showPopup]);

  useEffect(() => {
    if (
      commandId &&
      commandState.deleteCommandResponse &&
      commandState.deleteCommandResponse.id === commandId
    ) {
      navigate('/admin/commands-management');
    }
  }, [commandState.deleteCommandResponse]);

  function onCommandCreate(isPublished: boolean) {
    const data = {
      name: commandName,
      data: options,
      isSilent: !containsSpecificStrings(options),
      resultInsertion: insertion,
      isPublished: isPublished,
    };
    if (commandName.length && options.length && insertion.length) {
      const errors = {
        name: false,
        options: false,
        resultInsertion: false,
      };
      setErrorField(errors);
      setSaved(true);
      !commandId
        ? dispatch(postCommandCreateRequest(data))
        : dispatch(putCommandUpdateRequest({id: commandId, ...data}));
      navigate('/admin/commands-management');
    } else {
      const errors = {
        name: !commandName.length,
        options: !options.length,
        resultInsertion: !insertion.length,
      };
      setErrorField(errors);
    }
  }

  const handleLeavePage = () => {
    if (!saved) {
      setShowPopup(true);
      return;
    }
    navigate('/admin/commands-management');
  };

  function onCommandDelete() {
    commandId && dispatch(deleteCommandRequest({id: commandId}));
    setShowDeletePopup(false);
  }

  function onCommandClone() {
    const data = {
      name: `Clone of ${commandName}`,
      data: options,
      isSilent: !containsSpecificStrings(options),
    };
    commandId && dispatch(postCommandCreateRequest(data));
    setShowClonePopup(false);
  }
  function handleInsertionSelect(event: React.ChangeEvent<HTMLInputElement>) {
    setInsertion((event.target as HTMLInputElement).value);
  }

  return (
    <div className="create-command">
      <Button className={`back-btn`} onClick={handleLeavePage} startIcon={<ArrowBackIcon />}>
        Back
      </Button>
      <div className="create-command__title-wr">
        <h2 className="create-command__title">{commandId ? 'Preview' : 'Create Command'}</h2>
        <div className="create-command__btns-wr">
          {commandId && (
            <Button
              className="white-btn white-btn-hover"
              onClick={() => setShowDeletePopup(true)}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          )}
          {commandItem?.isPublished && (
            <Button
              className="white-btn white-btn-hover"
              onClick={() => setShowClonePopup(true)}
              startIcon={<ContentCopyIcon />}
            >
              Copy command
            </Button>
          )}

          <Button className="orange-btn orange-btn-hover" onClick={() => setSaveDialogOpen(true)}>
            Save Command
          </Button>
        </div>
      </div>
      <div className="create-command__form-wr">
        <p className="create-command__field-name">Command name</p>
        <TextField
          className="create-command__input-field"
          value={commandName}
          variant="outlined"
          placeholder="Enter command name"
          onChange={(e) => setCommandName(e.target.value)}
          error={errorField.name}
          helperText={errorField.name && 'This field is required'}
        />
        <p className="create-command__field-name">
          Command parameters
          <ShowPreviewButton setShowPreview={setShowPreview} showPreview={showPreview} />
        </p>
        <>
          {options.length > 0 && commandId && (
            <OptionsEdit
              options={options}
              setOptions={setOptions}
              variant="outlined"
              nameDisplayed={false}
              error={errorField.options}
            />
          )}
          {!commandId && (
            <OptionsEdit
              options={options}
              setOptions={setOptions}
              variant="outlined"
              nameDisplayed={false}
              error={errorField.options}
            />
          )}
          {showPreview && <OptionsPreview options={options} />}
        </>
        <p className="create-command__field-name">Command result insertion</p>
        {errorField.resultInsertion && (
          <FormHelperText error={true}>Please select type of insertion</FormHelperText>
        )}
        <RadioGroup
          aria-labelledby="radio-buttons-group"
          name="radio-buttons-group"
          onChange={handleInsertionSelect}
        >
          <FormControlLabel
            value="InsertBefore"
            control={<Radio checked={insertion === 'InsertBefore'} />}
            label="Insert above"
          />
          <FormControlLabel
            value="InsertAfter"
            control={<Radio checked={insertion === 'InsertAfter'} />}
            label="Insert below"
          />
          <FormControlLabel
            value="Replace"
            control={<Radio checked={insertion === 'Replace'} />}
            label="Replace"
          />
        </RadioGroup>
      </div>
      {isLoading && (
        <div className="overlay-loading">
          <LoadingSpinner />
        </div>
      )}
      <ModalWithImage
        isOpen={showPopup}
        topImage={<ModalIcon />}
        title={'Your data won’t be saved!'}
        subtitle={'Are you sure you want to quit the process?'}
        buttons={
          <>
            <Button onClick={handleConfirmLeave} className="orange-btn orange-btn-hover">
              Yes, confirm
            </Button>
            <Button onClick={() => setShowPopup(false)} className="white-btn white-btn-hover">
              Cancel
            </Button>
          </>
        }
      />
      <ModalWithImage
        isOpen={showDeletePopup}
        topImage={<ModalIcon />}
        title={'Command will be deleted'}
        subtitle={'Are you sure you want to delete the command?'}
        buttons={
          <>
            <Button onClick={onCommandDelete} className="orange-btn orange-btn-hover">
              Yes, Delete
            </Button>
            <Button onClick={() => setShowDeletePopup(false)} className="white-btn white-btn-hover">
              Cancel
            </Button>
          </>
        }
      />
      <ModalWithImage
        isOpen={showClonePopup}
        topImage={<ModalIcon />}
        title={'Command will be cloned'}
        subtitle={'Are you sure you want to clone this command?'}
        buttons={
          <>
            <Button onClick={onCommandClone} className="orange-btn orange-btn-hover">
              Yes, Clone
            </Button>
            <Button onClick={() => setShowClonePopup(false)} className="white-btn white-btn-hover">
              Cancel
            </Button>
          </>
        }
      />
      <ModalWithImage
        isOpen={saveDialogOpen}
        topImage={<ModalIcon />}
        title={'How do you want to save the process?'}
        buttons={
          <>
            <Button className="orange-btn orange-btn-hover" onClick={() => onCommandCreate(true)}>
              Save & Publish
            </Button>
            <Button
              className="violet-btn orange-btn-hover dark-blue-btn"
              onClick={() => onCommandCreate(false)}
            >
              Save & Unpublish
            </Button>
            <Button
              className="white-btn white-btn-hover"
              onClick={() => setSaveDialogOpen(!saveDialogOpen)}
            >
              Cancel
            </Button>
          </>
        }
      />
    </div>
  );
};

export default CreateCommand;
