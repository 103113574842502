import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

//API
import * as coupon from '../api/coupon';

//REDUX
import * as couponAction from '../actions/coupon';
import * as couponActionType from '../actions/types/types';
import {Action} from 'redux-actions';

import {
  IApplyCouponRequestAction,
  ICouponRequestAction,
  IGetCouponsManagementAction,
  IGetCouponByIdRequestAction,
  ICheckCouponRequestAction,
  IUpdateCouponRequestAction,
} from '../interfaces';
import * as userProfile from '../api/userProfile';
import * as userProfileAction from '../actions/userProfile';

export function* createCoupon(
  action: ICouponRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(coupon.createCoupon, action.payload);
    yield put(couponAction.postCouponCreateSuccess(payload));
    const data = yield call(coupon.getCouponsManagement);
    yield put(couponAction.getCouponsManagementSuccess(data));
  } catch (error) {
    yield put(couponAction.postCouponCreateFailure(error));
  }
}

export function* getCoupons(
  action: IGetCouponsManagementAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(coupon.getCouponsManagement, action.payload);
    yield put(couponAction.getCouponsManagementSuccess(payload));
  } catch (error) {
    yield put(couponAction.getCouponsManagementFailure(error));
  }
}

export function* applyCoupon(
  action: IApplyCouponRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(coupon.applyCoupon, action.payload);
    yield put(couponAction.postStatusApplySuccess(payload));
  } catch (error) {
    yield put(couponAction.postStatusApplyFailure(error));
  }
}

export function* getCouponsHistory(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  unknown
> {
  try {
    const payload = yield call(coupon.getCouponsHistory);
    yield put(couponAction.getCouponsHistorySuccess(payload));
  } catch (error) {
    yield put(couponAction.getCouponsHistoryFailure(error));
  }
}

export function* getCouponById(
  action: IGetCouponByIdRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(coupon.getCouponById, action.payload);
    yield put(couponAction.getCouponByIdSuccess(payload));
  } catch (error) {
    yield put(couponAction.getCouponByIdFailure(error));
  }
}

export function* checkCoupon(
  action: ICheckCouponRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  const promocode = action.payload.promocode;
  try {
    const payload = yield call(coupon.checkCoupon, action.payload);
    yield put(couponAction.postCheckCouponSuccess(payload));
    const applyPayload = yield call(coupon.applyCoupon, promocode);
    yield put(couponAction.postStatusApplySuccess(applyPayload));
    const userPayload = yield call(userProfile.getUserProfile);
    const historyPayload = yield call(coupon.getCouponsHistory);
    yield put(couponAction.getCouponsHistorySuccess(historyPayload));
    yield put(userProfileAction.getUserProfileSuccess(userPayload));
  } catch (error) {
    if (error instanceof Error) {
      yield put(couponAction.postCheckCouponFailure(error.message));
    }
  }
}

export function* checkCouponWithoutApply(
  action: ICheckCouponRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(coupon.checkCoupon, action.payload);
    yield put(couponAction.postCheckCouponWithoutApplySuccess(payload));
  } catch (error) {
    if (error instanceof Error) {
      yield put(couponAction.postCheckCouponWithoutApplyFailure(error.message));
    }
  }
}

export function* updateCoupon(
  action: IUpdateCouponRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(coupon.updateCoupon, action.payload);
    yield put(couponAction.putUpdateCouponSuccess(payload));
  } catch (error) {
    yield put(couponAction.putUpdateCouponFailure(error));
  }
}

//WATCHERS
export function* watchCreateCoupon(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(couponActionType.POST_CREATE_COUPON_REQUEST, createCoupon);
}

export function* watchGetCouponsManagement(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(couponActionType.GET_COUPONS_MANAGEMENT_REQUEST, getCoupons);
}

export function* watchApplyCoupon(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(couponActionType.POST_APPLY_COUPON_REQUEST, applyCoupon);
}

export function* watchGetCouponsHistory(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(couponActionType.GET_COUPONS_HISTORY_REQUEST, getCouponsHistory);
}

export function* watchGetCouponById(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(couponActionType.GET_COUPON_BY_ID_REQUEST, getCouponById);
}

export function* watchUpdateCoupon(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(couponActionType.PUT_UPDATE_COUPON_REQUEST, updateCoupon);
}

export function* watchCheckCoupon(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(couponActionType.POST_CHECK_COUPON_REQUEST, checkCoupon);
}

export function* watchCheckCouponWithoutApply(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    couponActionType.POST_CHECK_COUPON_WITHOUT_APPLY_REQUEST,
    checkCouponWithoutApply,
  );
}
