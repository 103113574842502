// PAYMENTS - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as paymentsActionType from './types/types';

export const getPaymentMethodsRequest = createAction(
  paymentsActionType.GET_PAYMENT_METHODS_REQUEST,
);
export const getPaymentMethodsSuccess = createAction(
  paymentsActionType.GET_PAYMENT_METHODS_SUCCESS,
);
export const getPaymentMethodsFailure = createAction(
  paymentsActionType.GET_PAYMENT_METHODS_FAILURE,
);

export const postInitializePaymentCardRequest = createAction(
  paymentsActionType.POST_INITIALIZE_PAYMENT_CARD_REQUEST,
);
export const postInitializePaymentCardSuccess = createAction(
  paymentsActionType.POST_INITIALIZE_PAYMENT_CARD_SUCCESS,
);
export const postInitializePaymentCardFailure = createAction(
  paymentsActionType.POST_INITIALIZE_PAYMENT_CARD_FAILURE,
);

export const postInitializeDefaultPaymentCardRequest = createAction(
  paymentsActionType.POST_INITIALIZE_DEFAULT_PAYMENT_CARD_REQUEST,
);
export const postInitializeDefaultPaymentCardSuccess = createAction(
  paymentsActionType.POST_INITIALIZE_DEFAULT_PAYMENT_CARD_SUCCESS,
);
export const postInitializeDefaultPaymentCardFailure = createAction(
  paymentsActionType.POST_INITIALIZE_DEFAULT_PAYMENT_CARD_FAILURE,
);

export const removePaymentCardRequest = createAction(
  paymentsActionType.DELETE_PAYMENT_CARD_REQUEST,
);
export const removePaymentCardSuccess = createAction(
  paymentsActionType.DELETE_PAYMENT_CARD_SUCCESS,
);
export const removePaymentCardFailure = createAction(
  paymentsActionType.DELETE_PAYMENT_CARD_FAILURE,
);
