// CATEGORIES - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as notificationsActionType from './types/types';

export const postStatusRequest = createAction(
  notificationsActionType.POST_PASSWORD_CHANGED_NOTIFICATION_REQUEST,
);
export const postStatusSuccess = createAction(
  notificationsActionType.POST_PASSWORD_CHANGED_NOTIFICATION_SUCCESS,
);
export const postStatusFailure = createAction(
  notificationsActionType.POST_PASSWORD_CHANGED_NOTIFICATION_FAILURE,
);
