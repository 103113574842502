import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as userManagement from '../api/userManagement';

// REDUX
import * as userManagementAction from '../actions/userManagement';
import * as userManagementActionType from '../actions/types/types';

import {IGetUserManagementAction} from '../interfaces';
import {Action} from 'redux-actions';

export function* getUserManagement(
  action: IGetUserManagementAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(userManagement.getUserManagement, action.payload);
    yield put(userManagementAction.getUserManagementSuccess(payload));
  } catch (error) {
    yield put(userManagementAction.getUserManagementFailure(error));
  }
}

export function* getAllUserManagement(
  action: IGetUserManagementAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(userManagement.getUserManagement, action.payload);
    yield put(userManagementAction.getAllUserManagementSuccess(payload));
  } catch (error) {
    yield put(userManagementAction.getAllUserManagementFailure(error));
  }
}

// WATCHERS
export function* watchUserManagement(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(userManagementActionType.GET_USER_MANAGEMENT_REQUEST, getUserManagement);
}

export function* watchAllUserManagement(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(userManagementActionType.GET_ALL_USER_MANAGEMENT_REQUEST, getAllUserManagement);
}
