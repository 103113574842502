import {Link} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import BackButton from './../../UI/BackButton';
import BillingAndPaymentsImg from '../../../images/billing-payments.png';
import {useEffect} from 'react';

export const BillingAndPayments = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container className="info-page">
        <Grid item xs={11} md={11} lg={10}>
          <div className="info-page__wr">
            <BackButton prevPage />

            <Grid container>
              <Grid item xs={12} md={9} lg={8}>
                <h1 className="info-page__title">Billing and Payments</h1>

                <div className="info-page__content">
                  <img src={BillingAndPaymentsImg} alt="help-center_credits-use-history" />
                  <div className="content-item">
                    <p>
                      After subscribing to Marquètte, you have a set number of credits added to your
                      account. Credits are in-app Marquètte currency.
                    </p>
                    <p>
                      The cost of AI-generated content varies based on the GPT engine option you
                      choose:
                    </p>
                    <ul>
                      <li>
                        Premium (GPT 3.5)
                        <b> 1 credit/word</b>
                      </li>
                      <li>
                        Superior (GPT 4)
                        <b> 5 credits/word</b>
                      </li>
                    </ul>
                    <p>
                      Please note that <b>AI charges per generated word</b>, not for the final
                      version of your chosen content. Therefore, every content option offered by
                      Marquètte will deduct some credits from your account.
                    </p>
                    <p>
                      <Link className="use-qa__link" to={`/account/billing-details`}>
                        Click here
                      </Link>{' '}
                      to see your payment details and history.
                    </p>
                    <h2 className="info-page__subtitle info-page__subtitle--left">
                      7-Day Free Trial
                    </h2>

                    <p>
                      You can enjoy exploring all Marquètte features at no cost during the 7-Day
                      Free Trial.
                    </p>
                    <p>
                      At this time, there will be no charges to your credit/debit card. However,{' '}
                      <b>it is mandatory to enter card details</b> to register your account.
                    </p>
                    <p>
                      If you only have one card added for payment details, it cannot be deleted.
                    </p>
                    <p>
                      In order to change the card, add details of a new one and then choose which
                      option to delete.
                    </p>
                    <h2 className="info-page__subtitle info-page__subtitle--left">Auto-Renewal</h2>
                    <p>
                      For your convenience, the auto-renewal feature is active by default.
                      Therefore, you only need to subscribe to Marquètte once, and the system will
                      automatically renew your subscription monthly or yearly.
                    </p>
                    <p>You can disable the auto-renewal feature at any time.</p>
                    <p>
                      If the auto-renewal is disabled, once your account expires, it will become{' '}
                      {''}
                      <b>inactive</b>. It means that you will only be able to access your old posts
                      without the ability to generate new content.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default BillingAndPayments;
