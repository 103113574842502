import {Grid} from '@material-ui/core';
import BackButton from './../../UI/BackButton';
import GoodQuestionImg from '../../../images/goodQuestion.svg';
import {useEffect} from 'react';

export const TemplateWork = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container className="info-page">
        <Grid item xs={11} md={11} lg={10}>
          <div className="info-page__wr">
            {/*{history.length > 1 && <BackButton match={history} prevPage />}*/}
            <BackButton prevPage />
            <Grid container>
              <Grid item xs={12} md={9} lg={8}>
                <h1 className="info-page__title">What is a template, and how to work with it?</h1>

                <div className="info-page__content">
                  <img src={GoodQuestionImg} alt="template illustration" />
                  <div className="content-item">
                    <p>
                      Think of a Marquètte template as a content mold enabling you to create
                      specific types of texts.
                    </p>
                    <p>At the moment, you can use the following templates:</p>
                    <ul>
                      <li>
                        <b>X Post (ex-Tweet)</b>
                      </li>
                      <li>
                        <b>X Post (Series)</b>
                      </li>
                      <li>
                        <b>YouTube Video Description</b>
                      </li>
                      <li>
                        <b>YouTube Title Generator</b>
                      </li>
                      <li>
                        <b>YouTube Content Ideas-YouTube Outline Generator</b>
                      </li>
                      <li>
                        <b>YouTube Video Intro</b>
                      </li>
                      <li>
                        <b>TikTok Hooks- TikTok Video Script</b>
                      </li>
                      <li>
                        <b>LinkedIn Post</b>
                      </li>
                      <li>
                        <b>Instagram Captions</b>
                      </li>
                      <li>
                        <b>Hashtags for Instagram</b>
                      </li>
                      <li>
                        <b>Pinterest: Title & Description</b>
                      </li>
                      <li>
                        <b>Quora Question</b>
                      </li>
                      <li>
                        <b>Social Media Content Plan</b>
                      </li>
                      <li>
                        <b>Problem-Agitation-Solution sales email framework</b>
                      </li>
                      <li>
                        <b>Before-After-Bridge sales email framework</b>
                      </li>
                      <li>
                        <b>Stop-Look-Act-Purchase sales email framework</b>
                      </li>
                      <li>
                        <b>Problem-Promise-Proof-Proposal sales email framework</b>
                      </li>
                      <li>
                        <b>LinkedIn Ads Headlines</b>
                      </li>
                      <li>
                        <b>LinkedIn Ad Description</b>
                      </li>
                      <li>
                        <b>Titles for Google Ads</b>
                      </li>
                      <li>
                        <b>Descriptions for Google Ads</b>
                      </li>
                      <li>
                        <b>Facebook Ads</b>
                      </li>
                      <li>
                        <b>X (Twitter) Ads</b>
                      </li>
                      <li>
                        <b>Pinterest Ads</b>
                      </li>
                    </ul>
                    <p>
                      Note that Marquètte templates can be used not only to generate content. The
                      information provided by AI can help in your research of the topic. Use it to
                      see the matter from various angles and better understand it.
                    </p>
                    <p>
                      Business owners can use it to study their competitors, their target audience,
                      and market trends.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default TemplateWork;
