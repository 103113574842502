import React from 'react';
import './style.scss';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';

interface Props {
  status: 'processed' | 'generated' | 'empty';
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

enum Text {
  'processed' = 'The summary is being processed by admin',
  'generated' = 'The summary is generated and approved by admin',
  'empty' = 'You don`t have questions on processing',
}

enum ToolTipText {
  'processed' = 'You will receive a refined summary after information processing by the admin',
  'generated' = 'You will receive a refined summary after information processing by the admin',
  'empty' = 'You will receive a refined summary after information processing by the admin',
}

export const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.1);',
    fontSize: 12,
  },
}))(Tooltip);

const StatusLabel = ({status, placement}: Props): JSX.Element => {
  const text = Text[status];
  return (
    <div className="StatusLabel">
      <LightTooltip
        title={ToolTipText[status]}
        placement={placement ? placement : 'bottom-end'}
        open={true}
        onOpen={() => {
          return true;
        }}
        onClose={() => {
          return true;
        }}
      >
        <span className={'StatusLabel__item StatusLabel__' + status}>{text}</span>
      </LightTooltip>
    </div>
  );
};

export default StatusLabel;
