import React, {ReactNode} from 'react';
import '../style.scss';

interface IDesktopNotificationProps {
  children?: ReactNode;
}
const DesktopNotification = (props: IDesktopNotificationProps): JSX.Element => {
  const {children} = props;
  return (
    <div>
      <div className="SettingContent">
        <h2 className="SettingContent__title SettingSideBar__common-tit">
          Marquètte Notifications
        </h2>
        <div className="SettingContent__actionable-wr">
          <h3 className="SettingContent__actionable-tit">Email Notifications</h3>
        </div>
        {children}
      </div>
    </div>
  );
};

export default DesktopNotification;
