import React from 'react';
import {Header} from '../../components/Header';
import './style.scss';
import AdminSideBar from '../../components/AdminSideBar';
import UserManagement from '../../components/UserManagement';
import {Route, useLocation, Routes} from 'react-router-dom';
import {SettingsPage} from '../../components/Settings';
import {CouponsManagement} from '../../components/CouponsManagement';
import {CreateCoupons} from '../../components/CouponsManagement/CreateCoupons';
import EditCoupon from '../../components/CouponsManagement/EditCoupone';
import CloneCoupon from '../../components/CouponsManagement/CloneCoupon';
import UserDetails from '../../components/UserManagement/UserDetails';
import EntryDetails from '../../components/TemplateManagement/EntryDetails';
import TemplateManagement from '../../components/TemplateManagement';
import CommandsManagement from '../../components/CommandsManagement';
import CreateCommand from '../../components/CommandsManagement/CreateCommand';

export const Admin = (): JSX.Element => {
  const {pathname} = useLocation();
  const adminPages = pathname.includes('/admin');

  return (
    <div className={`admin ${adminPages ? 'section' : ''}`}>
      <AdminSideBar currentLocation={pathname} />

      <div className="admin__wrap">
        <Header showLogo={false} adminSide={true} />
        <Routes>
          <Route path={`user-management`} element={<UserManagement />} />
          <Route path={`user-management/:userId`} element={<UserDetails />} />
          <Route path={`admin-settings`} element={<SettingsPage />} />
          <Route path={`coupons-management`} element={<CouponsManagement />} />
          <Route path={`create-coupons`} element={<CreateCoupons />} />
          <Route path={`clone-coupon/:couponId`} element={<CloneCoupon />} />
          <Route path={`edit-coupon/:couponId`} element={<EditCoupon />} />
          <Route path={`template-management/*`} element={<TemplateManagement />} />
          <Route path={`template-management/details`} element={<EntryDetails />} />
          <Route path={`template-management/details/:entryId`} element={<EntryDetails />} />
          <Route path={`commands-management`} element={<CommandsManagement />} />
          <Route path={`commands-management/details`} element={<CreateCommand />} />
          <Route path={`commands-management/details/:commandId`} element={<CreateCommand />} />
        </Routes>
      </div>
    </div>
  );
};
export default Admin;
