import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {IState} from '../../../interfaces';
import {getCouponByIdRequest} from '../../../actions/coupon';
import {Grid} from '@material-ui/core';
import CreateCouponsSummary from '../CreateCoupons/CreateCouponsSummary';
import CloneCouponForm from './CloneCouponForm';
import LoadingSpinner from '../../UI/LoadingSpinner';

const CloneCoupon = (): JSX.Element => {
  const dispatch = useDispatch();
  const params = useParams();
  const couponId = params.couponId;

  const [isDataLoading, setDataLoading] = useState<boolean>(true);
  const [couponName, setCouponName] = useState<string>('');
  const [campaignName, setCampaignName] = useState<string>('');
  const [internalNote, setInternalNote] = useState<string>('');
  const [discountValue, setDiscountValue] = useState<string>('');
  const [discountDetailsIcon, setDiscountDetailsIcon] = useState<boolean>(false);
  const [discountPercetage] = useState<string>('');
  const [discountFixed] = useState<string>('');
  const [couponUsage, setCouponUsage] = useState<string>('');
  const [productPlan, setProductPlan] = useState<string>();
  const [startDate, setStartDate] = useState<Date | null | string>();
  const [endDate, setEndDate] = useState<Date | null | string>();

  const couponState = useSelector((state: IState) => state.coupon);

  useEffect(() => {
    if (couponId) {
      dispatch(getCouponByIdRequest({id: couponId}));
    }
  }, []);

  useEffect(() => {
    if (!couponState.loading && couponState.couponById && couponId === couponState.couponById.id) {
      const couponItem = couponState.couponById;
      setCouponName(couponItem.code);
      setCampaignName(couponItem.name);
      setInternalNote(couponItem.description);
      couponItem.maxUsage ? setCouponUsage(couponItem.maxUsage.toString()) : setCouponUsage('');
      setDiscountValue(couponItem.discount.toString());
      couponItem.isFixedAmount ? setDiscountDetailsIcon(true) : setDiscountDetailsIcon(false);
      setDataLoading(false);
    }
  }, [couponState.loading]);

  return !isDataLoading ? (
    <div className="coupon-create">
      <Grid container spacing={1}>
        <Grid
          className=""
          item
          style={{
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          xs={9}
        >
          <CloneCouponForm
            couponName={couponName}
            setCouponName={setCouponName}
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            internalNote={internalNote}
            setInternalNote={setInternalNote}
            discountValue={discountValue}
            setDiscountValue={setDiscountValue}
            discountDetailsIcon={discountDetailsIcon}
            setDiscountDetailsIcon={setDiscountDetailsIcon}
            couponUsage={couponUsage}
            setCouponUsage={setCouponUsage}
            productPlan={productPlan}
            setProductPlan={setProductPlan}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Grid>
        <Grid
          className=""
          item
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          xs={3}
        >
          <CreateCouponsSummary
            couponName={couponName}
            campaignName={campaignName}
            internalNote={internalNote}
            discountValue={discountValue}
            discountDetailsIcon={discountDetailsIcon}
            discountPercetage={discountPercetage}
            discountFixed={discountFixed}
            couponUsage={couponUsage}
            productPlan={productPlan}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
      </Grid>
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default CloneCoupon;
