import React from 'react';
import './style.scss';
import ContentItem from './ContentItem';
import {List} from '@material-ui/core';
import {ITextModelsItem} from '../../../interfaces';
import Pagination from '@material-ui/lab/Pagination';

interface IProps {
  content: ITextModelsItem[];
  page: number;
  setPage: (page: number) => void;
  isLoading: boolean;
  totalPages: number;
}

const ContentTable = (props: IProps): JSX.Element => {
  const {content, page, setPage, totalPages} = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  return (
    <div className="content-table">
      {content && (
        <List className="content-list">
          {content.map((item: ITextModelsItem, index: number) => (
            <ContentItem key={index} content={item} />
          ))}
        </List>
      )}
      {totalPages > 1 && content && (
        <Pagination
          count={totalPages}
          defaultPage={1}
          page={page}
          onChange={handleChangePage}
          siblingCount={0}
        />
      )}
    </div>
  );
};

export default ContentTable;
