import React, {useEffect, useState} from 'react';
import {Button} from '@material-ui/core';
import {clearCommandItemState, getCommandsListRequest} from '../../actions/commandsManagement';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {IState} from '../../interfaces';
import LoadingSpinner from '../UI/LoadingSpinner';
import CommandsTable, {ITableData} from './CommandsTable';
import Search from '../Search';

type Order = 'asc' | 'desc';

const CommandsManagement = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const commandsList = useSelector(
    (state: IState) => state.commandsManagement.commandsListResponse,
  );
  const commandsState = useSelector((state: IState) => state.commandsManagement);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ITableData>('name');
  const [filtersUpdated, setFiltersUpdated] = useState<Date | null>(null);
  const [searchText, setSearchText] = useState<string | null>('');

  const sortOrder = order === 'asc' ? 'Ascending' : 'Descending';

  function loadComandEntriesData(pageNumber: number) {
    const commandsPayload = {
      name: searchText,
      pageNumber: pageNumber,
      pageSize: rowsPerPage,
      sortOrder: sortOrder,
      sortingProperty: orderBy,
    };
    !commandsState.loading && dispatch(getCommandsListRequest(commandsPayload));
    dispatch(clearCommandItemState());
  }

  useEffect(() => {
    if (!commandsState.loading) {
      loadComandEntriesData(page + 1);
    }
  }, [
    page,
    filtersUpdated,
    commandsState.deleteCommandResponse,
    commandsState.publishCommandResponse,
    commandsState.updateCommandResponse,
  ]);

  useEffect(() => {
    setPage(0);
    setFiltersUpdated(new Date());
  }, [rowsPerPage, order, orderBy]);

  function onNewCommandCreate() {
    navigate('/admin/commands-management/details');
  }

  function handleClearFilters() {
    setSearchText('');
    setFiltersUpdated(new Date());
  }

  const handleSearch = () => {
    if (!commandsState.loading) {
      loadComandEntriesData(page + 1);
    }
  };

  return (
    <>
      <div className="user-management">
        <div className="user-management__title-wr">
          <h2 className="user-management__title">Commands management</h2>
          <Button className="orange-btn orange-btn-hover" onClick={onNewCommandCreate}>
            New Command
          </Button>
        </div>
        <div className="user-management__filters-wr ">
          <div className="user-management__dropdowns-wr ">
            <Search
              disabled={false}
              searchFunction={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
              clearFilter={handleClearFilters}
            />
          </div>
        </div>
        {commandsList ? (
          <CommandsTable
            commands={commandsList.items}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            totalCount={commandsList.totalCount}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            isLoading={commandsState.loading}
          />
        ) : (
          <span className="user-management__spinner-wr">
            <LoadingSpinner />
          </span>
        )}
      </div>
    </>
  );
};

export default CommandsManagement;
