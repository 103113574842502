import {handleActions} from 'redux-actions';
// ACTION TYPES
import {GET_AREAS_REQUEST, GET_AREAS_SUCCESS, GET_AREAS_FAILURE} from '../actions/types/types';
export interface IAction {
  payload: IPayloadAction;
}
interface IPayloadAction {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: IArea[];
  pageIndex: number;
  totalCount: number;
  totalPages: number;
}
export interface IArea {
  id: number;
  name: string;
  desc: string;
}
export interface IAreasState {
  areas: IPayloadAction | null;
  loading: boolean;
  error: string;
}

export const initialState = {
  areas: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_AREAS_REQUEST]: (state: IAreasState) => ({
    ...state,
    loading: true,
  }),

  [GET_AREAS_SUCCESS]: (state: IAreasState, action: IAction) => ({
    ...state,
    areas: action.payload,
    loading: false,
    error: '',
  }),

  [GET_AREAS_FAILURE]: (state: IAreasState) => ({
    ...state,
    error: '',
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
