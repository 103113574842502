import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as userProfile from '../api/userProfile';

// REDUX
import {Action} from 'redux-actions';
import * as userProfileAction from '../actions/userProfile';
import * as userProfileActionType from '../actions/types/types';
import {IUserUpdateNameRequestAction} from '../interfaces';

export function* postUserUpdateName(
  action: IUserUpdateNameRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(userProfile.postUpdateProfileName, action.payload);
    yield put(userProfileAction.postUserProfileNameSuccess(payload));
    const profilePayload = yield call(userProfile.getUserProfile);
    yield put(userProfileAction.getUserProfileSuccess(profilePayload));
  } catch (error) {
    yield put(userProfileAction.postUserProfileNameFailure(error));
    yield put(userProfileAction.getUserProfileFailure(error));
  }
}

// WATCHERS
export function* watchUserUpdateName(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(userProfileActionType.POST_UPDATEPROFILE_NAME_REQUEST, postUserUpdateName);
}
