// COUPON - ACTIONS
// =============================================================================
import {createAction} from 'redux-actions';

// ACTION TYPES
import * as couponActionType from './types/types';

export const postCouponCreateRequest = createAction(couponActionType.POST_CREATE_COUPON_REQUEST);
export const postCouponCreateSuccess = createAction(couponActionType.POST_CREATE_COUPON_SUCCESS);
export const postCouponCreateFailure = createAction(couponActionType.POST_CREATE_COUPON_FAILURE);

export const getCouponsManagementRequest = createAction(
  couponActionType.GET_COUPONS_MANAGEMENT_REQUEST,
);
export const getCouponsManagementSuccess = createAction(
  couponActionType.GET_COUPONS_MANAGEMENT_SUCCESS,
);
export const getCouponsManagementFailure = createAction(
  couponActionType.GET_COUPONS_MANAGEMENT_FAILURE,
);

export const postStatusRequest = createAction(couponActionType.POST_CREATE_COUPON_REQUEST);
export const postStatusSuccess = createAction(couponActionType.POST_CREATE_COUPON_SUCCESS);
export const postStatusFailure = createAction(couponActionType.POST_CREATE_COUPON_FAILURE);

export const postStatusApplyRequest = createAction(couponActionType.POST_APPLY_COUPON_REQUEST);
export const postStatusApplySuccess = createAction(couponActionType.POST_APPLY_COUPON_SUCCESS);
export const postStatusApplyFailure = createAction(couponActionType.POST_APPLY_COUPON_FAILURE);

export const getCouponsHistoryRequest = createAction(couponActionType.GET_COUPONS_HISTORY_REQUEST);
export const getCouponsHistorySuccess = createAction(couponActionType.GET_COUPONS_HISTORY_SUCCESS);
export const getCouponsHistoryFailure = createAction(couponActionType.GET_COUPONS_HISTORY_FAILURE);

export const getCouponByIdRequest = createAction(couponActionType.GET_COUPON_BY_ID_REQUEST);
export const getCouponByIdSuccess = createAction(couponActionType.GET_COUPON_BY_ID_SUCCESS);
export const getCouponByIdFailure = createAction(couponActionType.GET_COUPON_BY_ID_FAILURE);

export const postCheckCouponRequest = createAction(couponActionType.POST_CHECK_COUPON_REQUEST);
export const postCheckCouponSuccess = createAction(couponActionType.POST_CHECK_COUPON_SUCCESS);
export const postCheckCouponFailure = createAction(couponActionType.POST_CHECK_COUPON_FAILURE);

export const postCheckCouponWithoutApplyRequest = createAction(
  couponActionType.POST_CHECK_COUPON_WITHOUT_APPLY_REQUEST,
);
export const postCheckCouponWithoutApplySuccess = createAction(
  couponActionType.POST_CHECK_COUPON_WITHOUT_APPLY_SUCCESS,
);
export const postCheckCouponWithoutApplyFailure = createAction(
  couponActionType.POST_CHECK_COUPON_WITHOUT_APPLY_FAILURE,
);

export const putUpdateCouponRequest = createAction(couponActionType.PUT_UPDATE_COUPON_REQUEST);
export const putUpdateCouponSuccess = createAction(couponActionType.PUT_UPDATE_COUPON_SUCCESS);
export const putUpdateCouponFailure = createAction(couponActionType.PUT_UPDATE_COUPON_FAILURE);
