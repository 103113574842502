import {handleActions} from 'redux-actions';

// ACTION TYPES
import {
  GET_INDUSTRIES_REQUEST,
  GET_INDUSTRIES_SUCCESS,
  GET_INDUSTRIES_FAILURE,
} from '../actions/types/types';
import {IIndustry} from '../interfaces';

interface IAction {
  payload: IPayloadAction;
}
interface IPayloadAction {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: IIndustry[];
  pageIndex: number;
  totalCount: number;
  totalPages: number;
}

export interface IIndustriesState {
  industries: IPayloadAction;
  loading: boolean;
  error: string;
}

export const initialState = {
  industries: {},
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_INDUSTRIES_REQUEST]: (state: IIndustriesState) => ({
    ...state,
    loading: true,
  }),

  [GET_INDUSTRIES_SUCCESS]: (state: IIndustriesState, action: IAction) => ({
    ...state,
    industries: action.payload,
    loading: false,
    error: '',
  }),

  [GET_INDUSTRIES_FAILURE]: (state: IIndustriesState) => ({
    ...state,
    error: '',
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
