import {styled} from '@mui/material';
import {TextField} from '@material-ui/core';

export const StyledTextField = styled(TextField)(({theme}) => ({
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    // padding: '12px',
    '&.Mui-focused': {
      '& .MuiSvgIcon-root': {
        fill: '#2B2B2B',
      },
      '& .MuiFormLabel-root': {
        color: '#515151',
      },
    },
    '&.Mui-error': {
      '& .MuiFormLabel-root': {
        color: '#D80D00',
      },
    },
    '&.Mui-disabled': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'inherit',
        },
      },
    },
  },
  '& .MuiFormLabel-root': {
    color: '#828282',
    opacity: '1',
    fontSize: '16px',
    '&.Mui-error': {
      color: '#D80D00',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#BBD5FF',
  },
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2B2B2B',
    },
  },
}));
