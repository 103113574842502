import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as userProfile from '../api/userProfile';

// REDUX
import * as userProfileAction from '../actions/userProfile';
import * as userProfileActionType from '../actions/types/types';
import {Action} from 'redux-actions';
import {IGetUserPhotoRequestAction, IUserProfile} from '../interfaces';

export interface IInitUserAction {
  payload: {} | {id: string};
  type: string;
}

export function* getUserProfile(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<Error>>,
  void,
  IUserProfile
> {
  try {
    const payload: IUserProfile = yield call(userProfile.getUserProfile);
    yield put(userProfileAction.getUserProfileSuccess(payload));
    const payloadPhoto = yield call(userProfile.getUserPhoto, payload.userId);
    yield put(userProfileAction.getUserPhotoSuccess(payloadPhoto));
  } catch (error) {
    yield put(userProfileAction.getUserPhotoFailure(error));
    if (error instanceof Error) {
      if (error.message === 'Error! Status code: 404') {
        const payload: IUserProfile = yield call(userProfile.postInitUser, {});
        yield put(userProfileAction.postInitUserSuccess(payload));
        const userPayload = yield call(userProfile.getUserProfile);
        yield put(userProfileAction.getUserProfileSuccess(userPayload));
        getUserPhoto({type: '', payload: payload.userId});
      }
    }
    yield put(userProfileAction.getUserProfileFailure(error));
  }
}

export function* getUserPhoto(
  action: IGetUserPhotoRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(userProfile.getUserPhoto, action.payload);
    yield put(userProfileAction.getUserPhotoSuccess(payload));
  } catch (error) {
    yield put(userProfileAction.getUserPhotoFailure(error));
  }
}

export function* postInitUser(
  action: IInitUserAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(userProfile.postInitUser, action.payload);
    yield put(userProfileAction.postInitUserSuccess(payload));
  } catch (error) {
    yield put(userProfileAction.postInitUserFailure(error));
  }
}

export function* deleteUserAccount(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  unknown
> {
  try {
    const payload = yield call(userProfile.deleteUserAccount);
    yield put(userProfileAction.deleteUserAccountSuccess(payload));
  } catch (error) {
    yield put(userProfileAction.deleteUserAccountFailure(error));
  }
}

export function* deleteUserPhoto(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  unknown
> {
  try {
    const payload = yield call(userProfile.deleteUserPhoto);
    yield put(userProfileAction.deleteUserPhotoSuccess(payload));
  } catch (error) {
    yield put(userProfileAction.deleteUserPhotoFailure(error));
  }
}

// WATCHERS
export function* watchUserProfile(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(userProfileActionType.POST_INIT_USER_REQUEST, postInitUser);
}

export function* watchGetUserProfile(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(userProfileActionType.GET_USERPROFILE_REQUEST, getUserProfile);
}

export function* watchGetUserPhoto(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(userProfileActionType.GET_USER_PHOTO_REQUEST, getUserPhoto);
}

export function* watchDeleteUserAccount(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(userProfileActionType.DELETE_USER_ACCOUNT_REQUEST, deleteUserAccount);
}

export function* watchDeleteUserPhoto(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(userProfileActionType.DELETE_USER_PHOTO_REQUEST, deleteUserPhoto);
}
