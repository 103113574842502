// AREAS - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as areasActionType from './types/types';

export const getStatusRequest = createAction(areasActionType.GET_AREAS_REQUEST);
export const getStatusSuccess = createAction(areasActionType.GET_AREAS_SUCCESS);
export const getStatusFailure = createAction(areasActionType.GET_AREAS_FAILURE);
