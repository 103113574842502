import React from 'react';
import './style.scss';
import {CURRENT_YEAR} from '../../constants';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookF, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import {LightTooltip} from '../UI/StatusLabel';
import {Tooltip, Button, ClickAwayListener} from '@material-ui/core';
import useWindowDimensions from '../../helpers/windowResize';

interface IProps {
  helpLinks?: boolean;
}

export const Footer = (props: IProps): JSX.Element => {
  const {helpLinks = true} = props;
  const [open, setOpen] = React.useState(false);
  const screenWidth = useWindowDimensions().width;
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <footer className="footer">
      {helpLinks !== false && (
        <div className="footer__links">
          <Link className="footer-link footer-link--bold" to={`/help-center`}>
            Help Center
          </Link>
          {screenWidth < 768 ? (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Coming Soon"
                placement="bottom"
              >
                <Button onClick={handleTooltipOpen} className="footer-tooltip-button">
                  Schedule a consultation
                </Button>
              </Tooltip>
            </ClickAwayListener>
          ) : (
            <LightTooltip title="Coming Soon" placement="bottom">
              <span className="footer-link footer-link--bold">Schedule a consultation</span>
            </LightTooltip>
          )}
          <Link className="footer-link footer-link--bold" to={`/contact-us`}>
            Contact Us
          </Link>
        </div>
      )}
      <div className={`footer__links start ${!helpLinks ? 'without-links' : ''}`}>
        <div className="footer__social">
          <a
            href="https://www.facebook.com/devtoriumsoftwarecompany"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://www.instagram.com/marquette.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://www.linkedin.com/company/devtorium/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>
      <div className="footer__links grow">
        <Link to={`/terms`} className="footer-link">
          Terms of Use
        </Link>
        <Link to={`/policy-privacy`} className="footer-link">
          Privacy Policy
        </Link>
        <Link to={`/user-agreement`} className="footer-link">
          User Agreement
        </Link>
      </div>
      <div className="footer__links">
        <div className="footer__logo">
          <p>
            © {`${CURRENT_YEAR} `}
            <a href="https://devtorium.com/">Devtorium</a>. All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
