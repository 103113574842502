import {Action} from 'redux-actions';
import {put, takeLatest, ForkEffect, CallEffect, PutEffect} from 'redux-saga/effects';

//REDUX
import * as signalRCellEditAction from '../actions/signalRCellEdit';
import * as signalRActionType from '../actions/types/types';
import * as userAction from '../actions/userProfile';
import {ISignalRAction} from '../interfaces';

export function* setSignalRCellEdit(
  action: ISignalRAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    yield put(signalRCellEditAction.setSignalRCellEditSuccess(action.payload));
    yield put(userAction.updateUserCreditsRequest(action.payload));
  } catch (error) {
    yield put(signalRCellEditAction.setSignalRCellEditFailure(error));
  }
}

//WATCHERS
export function* watchSignalRCellEdit(): Generator<ForkEffect<never>, void> {
  yield takeLatest(signalRActionType.SET_SIGNALR_CELL_EDIT_REQUEST, setSignalRCellEdit);
}
