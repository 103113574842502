import React from 'react';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import TermsOfService from '../../pages/TermsOfService';
import UserAgreement from '../../pages/UserAgreement';
import {Navigate, Route, Routes} from 'react-router-dom';
import {useMsal} from '@azure/msal-react';
import './style.scss';
import {MSAL_SCOPE} from '../../constants';
import LandingPage from '../LandingPage';
import SubscriptionPlan from '../SubscriptionPlan';
import ContactUs from '../../components/ContactUs';
import PublicPageComponent from '../../components/TextModelComponent/SharedContent';
import {Helmet} from 'react-helmet';
import Footer from '../../components/Footer';
import {logInHandle} from '../../helpers/helper';
import LandingHeader from '../../components/Landing/Header';

interface ISeoMeta {
  [key: string]: {description: string};
}

const SEO_META: ISeoMeta = {
  home: {
    description:
      'Marquètte is an AI-powered platform for content generation and market research automation. Marquètte generates unique content by answering questions and providing you with opinions, trends, and market overviews.',
  },
  contactUs: {
    description:
      'Contact the Marquètte team to resolve any technical issues, share feedback, or get updates about our future roadmap.',
  },
  subscriptionPlan: {
    description:
      'Marquètte.ai has a 7-day free trial, silver, gold, diamond, and students subscription plans to meet every customer’s needs.',
  },
  terms: {
    description:
      'This Website is created and operated by Marquètte and is subject to the following Terms of Use.',
  },
  privacyPolicy: {
    description:
      'Marquètte’s Privacy Policy statement provides details of the policies and practices we follow to manage our business. By accessing this policy, you give your implicit consent to the procedures we have adopted and follow.',
  },
  userAgreement: {
    description:
      'We request that all our website visitors read this User Agreement before accessing and using our website’s features and content.',
  },
};

export const getMetaTags = (key: string): JSX.Element => {
  if (!SEO_META[key]) {
    return <></>;
  }

  return (
    <Helmet>
      <meta name="description" content={SEO_META[key].description} />
    </Helmet>
  );
};

function LoginWaiting() {
  const {instance} = useMsal();
  setTimeout(() => {
    void instance.loginRedirect({
      scopes: ['openid', MSAL_SCOPE],
      prompt: 'select_account',
    });
  }, 1);
  return <></>;
}

const Unauthenticated = (): JSX.Element => {
  const {instance} = useMsal();

  const btnClickHandle = (): Promise<void> => {
    return logInHandle(instance);
  };

  return (
    <>
      <div className="main-page">
        <Routes>
          <Route path="/login" element={<LoginWaiting />} />
          <Route
            path="/terms"
            element={
              <>
                <LandingHeader redirectHandle={btnClickHandle} />
                {getMetaTags('terms')}
                <div className="main-page unauthenticated__page">
                  <TermsOfService />
                </div>
                <Footer />
              </>
            }
          />
          <Route
            path="/policy-privacy"
            element={
              <>
                <LandingHeader redirectHandle={btnClickHandle} />
                {getMetaTags('privacyPolicy')}
                <div className="main-page unauthenticated__page">
                  <PrivacyPolicy />
                </div>
                <Footer />
              </>
            }
          />
          <Route
            path="/user-agreement"
            element={
              <>
                <LandingHeader redirectHandle={btnClickHandle} />
                {getMetaTags('userAgreement')}
                <div className="main-page unauthenticated__page">
                  <UserAgreement />
                </div>
                <Footer />
              </>
            }
          />
          <Route
            path="/subscription-plan"
            element={
              <>
                {getMetaTags('subscriptionPlan')}
                <SubscriptionPlan />
              </>
            }
          />
          <Route
            path="/contact-us"
            element={
              <div className="contact-us-page">
                <LandingHeader redirectHandle={btnClickHandle} />
                {getMetaTags('contactUs')}
                <ContactUs />
                <Footer />
              </div>
            }
          />
          <Route
            path={`/public/:publicHashId`}
            element={
              <>
                <div className="public">
                  <LandingHeader redirectHandle={btnClickHandle} navBar={false} />
                  <PublicPageComponent />
                  <Footer helpLinks={false} />
                </div>
              </>
            }
          />
          <Route
            path="/home"
            element={
              <>
                {getMetaTags('home')}
                <LandingPage />
              </>
            }
          />
          {window.location.pathname !== '/login' && (
            <Route path="/*" element={<Navigate replace to="/home" />} />
          )}
        </Routes>
      </div>
    </>
  );
};

export default Unauthenticated;
