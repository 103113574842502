// SUBSCRIPTION FOR UNREGISTERED USERS - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as userSubscriptionActionTypes from './types/types';

export const getUserSubscriptionRequest = createAction(
  userSubscriptionActionTypes.GET_USER_SUBSCRIPTION_REQUEST,
);
export const getUserSubscriptionSuccess = createAction(
  userSubscriptionActionTypes.GET_USER_SUBSCRIPTION_SUCCESS,
);
export const getUserSubscriptionFailure = createAction(
  userSubscriptionActionTypes.GET_USER_SUBSCRIPTION_FAILURE,
);

// SUBSCRIPTION FOR REGISTERED USERS - ACTIONS
// =============================================================================

export const getRegisteredUserSubscriptionRequest = createAction(
  userSubscriptionActionTypes.GET_REGISTERED_USER_SUBSCRIPTION_REQUEST,
);
export const getRegisteredUserSubscriptionSuccess = createAction(
  userSubscriptionActionTypes.GET_REGISTERED_USER_SUBSCRIPTION_SUCCESS,
);
export const getRegisteredUserSubscriptionFailure = createAction(
  userSubscriptionActionTypes.GET_REGISTERED_USER_SUBSCRIPTION_FAILURE,
);
