import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import {IState} from '../../../interfaces';
import {getBillingHistoryRequest} from '../../../actions/billing';
import CreditCountIcon from '../../../images/card-icon.svg';
import LoadingSpinner from '../../UI/LoadingSpinner';
import moment from 'moment';
import {DeclineCodes} from '../../../enums/UI';
import './style.scss';
import BackButton from '../../UI/BackButton';
// import {useHistory} from 'react-router-dom';

const BillingDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const billingData = useSelector((state: IState) => state?.billingHistory);
  // const match = useHistory();

  useEffect(() => {
    dispatch(getBillingHistoryRequest());
  }, []);

  return (
    <div className="billing">
      <Grid container style={{justifyContent: 'center'}}>
        <Grid item xs={12} sm={11} lg={10} className="billing__container component-white-wr">
          {!billingData.loading ? (
            <>
              <div className="billing__title-wr">
                <BackButton prevPage isTextBack={false} />
                <h1 className="billing__title">Billing details</h1>
              </div>
              <Hidden xsDown>
                <div className="billing__table-header">
                  <div className="billing__table-row">
                    <p className="billing__table-date">Date</p>
                    <p className="billing__table-description">Description</p>
                    <p className="billing__table-method">Payment method</p>
                    <p className="billing__table-total">Total</p>
                    <p className="billing__table-status">Status</p>
                  </div>
                </div>
              </Hidden>
              {billingData.billingHistory?.items && (
                <div className="billing__table-details">
                  {billingData.billingHistory.items.map((item, i) => (
                    <div key={i} className="billing__table-row">
                      <p className="billing__table-date">
                        <Hidden smUp>
                          <span className="billing__table-title">Date</span>
                        </Hidden>
                        {moment(item.effectiveUtc).format('MM/DD/YYYY')}
                      </p>
                      <p className="billing__table-description">
                        <Hidden smUp>
                          <span className="billing__table-title">Description</span>
                        </Hidden>
                        {item.name}
                      </p>
                      <p className="billing__table-method">
                        <Hidden smUp>
                          <span className="billing__table-title">Payment method</span>
                        </Hidden>
                        <span className="billing__table-card">
                          <img className="billing__table-card-img" src={CreditCountIcon} alt="" />
                          •••• •••• •••• {item.last4}
                        </span>
                      </p>
                      <p className="billing__table-total">
                        <Hidden smUp>
                          <span className="billing__table-title">Total</span>
                        </Hidden>
                        {`$${item.price}`}
                      </p>
                      <p className="billing__table-status">
                        <Hidden smUp>
                          <span className="billing__table-title">Status</span>
                        </Hidden>
                        {item.paymentStatus}
                        {item.paymentErrorReason && (
                          <span className="billing__table-status-error">
                            {DeclineCodes[item.paymentErrorReason as keyof typeof DeclineCodes] ||
                              item.paymentErrorReason}
                          </span>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <LoadingSpinner />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default BillingDetails;
