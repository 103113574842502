import React from 'react';
import './style.scss';
import IndustryIcon from '../../../images/expired-indusrty-icon.svg';
import PopularIcon from '../../../images/expired-popular-icon.svg';
import HelpIcon from '../../../images/expired-help-icon.svg';
import ContentIcon from '../../../images/expired-content-icon.svg';
import CreditsIcon from '../../../images/expired-coins-icon.svg';
import {Button} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';

interface IFeatureItem {
  id: number;
  name: string;
  icon: string;
}

const SubscriptionExpired = (): JSX.Element => {
  const navigate = useNavigate();
  const featuresList: IFeatureItem[] = [
    {id: 1, name: 'Industry news and stats', icon: IndustryIcon},
    {id: 2, name: 'Popular questions', icon: PopularIcon},
    {id: 3, name: 'Create content', icon: ContentIcon},
    {id: 4, name: 'Access the Help desk', icon: HelpIcon},
    {id: 5, name: 'Purchase credits', icon: CreditsIcon},
  ];
  function handleClick() {
    navigate('/subscription-plan');
  }
  return (
    <div className="subscription-expired">
      <h2 className="subscription-expired__title">
        We're very sorry but your subscription has expired.
      </h2>
      <p className="subscription-expired__description">
        It means you can no longer access all the cool Marquètte features. But you can still ask
        questions if you have credits remaining on your account.
      </p>
      <h3 className="subscription-expired__list-title">
        Below features are not available for expired subscriptions:
      </h3>
      <ul className="subscription-expired__list">
        {featuresList.map((item: IFeatureItem) => (
          <li key={item.id} className="list-item">
            <img className="list-item__icon" src={item.icon} alt="feature icon" />
            <p className="list-item__name">{item.name}</p>
          </li>
        ))}
      </ul>
      <h2 className="subscription-expired__title second-title">
        Get back your full access to Marquètte by renewing your subscription!
      </h2>
      <Button className="orange-btn orange-btn-hover" onClick={handleClick}>
        Subscribe now
      </Button>
    </div>
  );
};

export default SubscriptionExpired;
