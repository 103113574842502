import React, {useState} from 'react';
import {Button, Menu, MenuItem} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface IProps {
  buttonName: string;
  itemsList: {id: string; name: string}[];
  updateFunction: (selected: string) => void;
  disabled?: boolean;
}

const SingleSelectDropdown = (props: IProps): JSX.Element => {
  const {buttonName, itemsList, updateFunction, disabled = false} = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelect = (id: string) => {
    updateFunction(id);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="single-dropdown-btn"
        endIcon={<KeyboardArrowDownIcon />}
        disabled={disabled}
      >
        {buttonName}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="single-dropdown-menu"
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {itemsList.map((item) => (
          <MenuItem key={item.id} onClick={() => handleSelect(item.id)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SingleSelectDropdown;
