import React, {useState} from 'react';
import './style.scss';
import logo from '../../images/Logo-light.svg';
import logoSmall from '../../images/favicon-light.svg';
import people from '../../images/people.svg';
import coupon from '../../images/coupon.svg';
import template from '../../images/template.svg';
import commands from '../../images/commands-management.svg';
import LockIcon from '../../images/admin-sidebar-lock.svg';
import clsx from 'clsx';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Link} from 'react-router-dom';
import {LightTooltip} from '../UI/StatusLabel';
import {useActiveUser} from '../../helpers/customHooks';
import {ReactComponent as SidebarOpenIcon} from '../../images/admin-sidebar-open-icon.svg';
import {ReactComponent as SidebarCloseIcon} from '../../images/admin-sidebar-close-icon.svg';

interface IMenuItem {
  text: string;
  img: string;
  path: string;
  isBlocked: boolean;
}
interface IAdminSideBar {
  currentLocation?: string;
}

const drawerWidth = 300;
const adminMenu: IMenuItem[] = [
  {
    text: 'User Management',
    img: people,
    path: '/admin/user-management',
    isBlocked: false,
  },
  {
    text: 'Coupons Management',
    img: coupon,
    path: '/admin/coupons-management',
    isBlocked: false,
  },
  {
    text: 'Template Management',
    img: template,
    path: '/admin/template-management',
    isBlocked: false,
  },
  {
    text: 'Commands Management',
    img: commands,
    path: '/admin/commands-management',
    isBlocked: false,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
    },
    drawerClose: {
      width: theme.spacing(9) + 1,
    },
  }),
);

export default function AdminSideBar(props: IAdminSideBar): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [lockedItemIcon, setLockedItemIcon] = useState<string>('');
  const [hoveredItem, setHoveredItem] = useState<string>('');
  const menuArray = adminMenu;
  const {isActiveUser, isExpired} = useActiveUser();

  function onItemHover(item: string) {
    setLockedItemIcon(LockIcon);
    setHoveredItem(item);
  }
  function onItemUnHover() {
    setLockedItemIcon('');
    setHoveredItem('');
  }

  function obSidebarAction() {
    setOpen(!open);
  }

  return (
    <div className="admin-sidebar">
      <span className="sidebar-button" onClick={obSidebarAction}>
        {open ? <SidebarCloseIcon /> : <SidebarOpenIcon />}
      </span>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className="sidebar__head">
          <div className="logo__wrap">
            {isExpired || isActiveUser ? (
              <Link to="/">
                <img
                  className={`sidebar__logo ${open ? 'open' : ''}`}
                  src={open ? logo : logoSmall}
                  alt="logo Devtorium"
                />
              </Link>
            ) : (
              <img
                className={`sidebar__logo ${open ? 'open' : ''}`}
                src={open ? logo : logoSmall}
                alt="logo Devtorium"
              />
            )}
          </div>
        </div>
        <div className="sidebar__list">
          <List>
            {menuArray.map((menuItem: IMenuItem) => (
              <ListItem
                button
                key={menuItem.text}
                onMouseEnter={() => onItemHover(menuItem.text)}
                onMouseLeave={onItemUnHover}
              >
                {menuItem.isBlocked ? (
                  <LightTooltip title="coming soon">
                    <div
                      className={
                        props.currentLocation === menuItem.path
                          ? 'sidebar__link sidebar__link--active'
                          : 'sidebar__link'
                      }
                    >
                      <img
                        className="sidebar__icon"
                        src={
                          lockedItemIcon && hoveredItem === menuItem.text ? LockIcon : menuItem.img
                        }
                        alt=""
                      />
                      <ListItemText
                        className={
                          !open ? 'sidebar__item sidebar__item--sidebar-closed' : 'sidebar__item'
                        }
                        primary={menuItem.text}
                      />
                    </div>
                  </LightTooltip>
                ) : (
                  <Link
                    className={
                      props.currentLocation === menuItem.path
                        ? 'sidebar__link sidebar__link--active'
                        : 'sidebar__link'
                    }
                    to={menuItem.path}
                  >
                    <img className="sidebar__icon" src={menuItem.img} alt="" />
                    <ListItemText
                      className={
                        !open ? 'sidebar__item sidebar__item--sidebar-closed' : 'sidebar__item'
                      }
                      primary={menuItem.text}
                    />
                  </Link>
                )}
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </div>
  );
}
