import React, {useState} from 'react';
import {IconButton, ListItem} from '@material-ui/core';
import {ReactComponent as ArchiveIcon} from '../../../../images/archive.svg';
import {ReactComponent as UnarchiveIcon} from '../../../../images/unarchive.svg';
import {useDispatch} from 'react-redux';
import {ITextModelsItem} from '../../../../interfaces';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import * as textModels from '../../../../actions/textModel';
import SnackbarMessage from '../../../UI/Snackbar';
import {LightTooltip} from '../../../UI/StatusLabel';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShareContentBtn from '../../../UI/ShareContentBtn';
import DefaultModelIcon from '../../../../images/Text-model/default-text-model-icon.svg';
import {BLOB_LINK} from '../../../../constants';

interface IProps {
  content: ITextModelsItem;
}

const ContentList = (props: IProps): JSX.Element => {
  const {content} = props;
  const dispatch = useDispatch();

  const [isOpenSnack, setOpenSnack] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>('');
  const [severity, setSeverity] = useState<'success' | 'info' | 'warning' | 'error' | undefined>(
    undefined,
  );
  const imageLink = `${BLOB_LINK}/assets-for-templates/`;

  function snackBarAction(
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error' | undefined,
    open: boolean,
  ) {
    setSnackMessage(message);
    setSeverity(severity);
    setOpenSnack(open);
  }

  function addToFavourite(id: string, isFavorite: boolean) {
    const contentName = content.name.length > 0 ? content.name : content.alias;
    const msg = `Your content "${contentName}" has been ${
      !isFavorite ? 'moved to' : 'removed from'
    } the Favorites`;
    dispatch(
      textModels.putLikeModelRequest({
        textModelId: id,
        isFavorite: !isFavorite,
        successMsg: msg,
        inBackground: true,
      }),
    );
  }

  const setArchiveStatus = (id: string, isArchived: boolean) => {
    const contentName = content.name.length > 0 ? content.name : content.alias;
    const msg = `Your content "${contentName}" has been ${
      isArchived ? 'moved to' : 'removed from'
    } the archive`;

    dispatch(
      textModels.setArchiveStatusModelRequest({
        textModelId: id,
        isArchived,
        successMsg: msg,
        inBackground: true,
      }),
    );
  };

  return (
    <>
      <ListItem
        divider
        className={`content-list__item ${content.isArchived ? 'content-list__item--archived' : ''}`}
      >
        <div className="content-list__info">
          <div className="content-list__info-icon">
            <img
              src={
                content.iconTemplateLink
                  ? `${imageLink}${content.iconTemplateLink}`
                  : DefaultModelIcon
              }
              alt="template icon"
            />
          </div>
          <div className="content-list__info-items">
            <div className="content-list__info-alias">{content.alias}</div>
            <div className="content-list__info-item content-list__info-item--opacity">
              {content.industryName}
            </div>
            <div className="content-list__info-item content-list__info-item--opacity">
              {moment(content.createdDate).format('MM/DD/YYYY')}
            </div>
          </div>
        </div>
        <div className="content-list__info-title-status">
          <div className="content-list__title">
            <NavLink to={`/text-model-details/${content.id}`}>
              {content.name.length > 0 ? content.name : content.alias}
            </NavLink>
          </div>
        </div>
        <div className="content-list__buttons">
          {!content.isArchived && (
            <>
              <IconButton
                className={`content-list__favourite ${content.isFavorite ? 'liked' : ''}`}
                onClick={() => addToFavourite(content.id, content.isFavorite)}
              >
                <LightTooltip
                  title={
                    content.isFavorite
                      ? 'Remove content from favorites'
                      : 'Add content to favorites'
                  }
                  placement={'top'}
                >
                  {content.isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </LightTooltip>
              </IconButton>
              {content.status.name === 'Done' && (
                <ShareContentBtn
                  publicHash={content.publicHash}
                  contentId={content.id}
                  snackBarAction={snackBarAction}
                  title={content.name ? content.name : content.alias}
                />
              )}
              <IconButton
                className={`content-list__archive`}
                onClick={() => setArchiveStatus(content.id, true)}
              >
                <LightTooltip title={'Archive content'} placement={'top'}>
                  <ArchiveIcon />
                </LightTooltip>
              </IconButton>
            </>
          )}
          {content.isArchived && (
            <div className={`content-list__archive`}>
              <IconButton onClick={() => setArchiveStatus(content.id, false)}>
                <LightTooltip title={'Unarchive content'} placement={'top'}>
                  <UnarchiveIcon />
                </LightTooltip>
              </IconButton>
            </div>
          )}
        </div>
      </ListItem>
      <SnackbarMessage
        open={isOpenSnack}
        message={snackMessage}
        severity={severity}
        setOpen={setOpenSnack}
      />
    </>
  );
};

export default ContentList;
