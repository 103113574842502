import {Link} from 'react-router-dom';
import Logo from '../../../images/Logo-light.svg';
import {Grid} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, {MouseEventHandler} from 'react';
import {CURRENT_YEAR} from '../../../constants';
import './style.scss';
import Button from '@material-ui/core/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookF, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons';

interface IProps {
  redirectHandle: MouseEventHandler<HTMLButtonElement>;
}

const LandingFooter = ({redirectHandle}: IProps): JSX.Element => {
  return (
    <div className="footer-land footer-land-wr">
      <Grid
        className="footer-land__cta"
        container
        item
        style={{
          margin: 'auto',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
        }}
        xs={11}
        md={11}
        lg={12}
      >
        <p>
          Get Started with Marqu<span className="orange-text">è</span>tte
          <br />
          Sign up today!
        </p>
        <button onClick={redirectHandle} className="orange-btn orange-btn-hover">
          Get a 7-DAY FREE trial now
          <ArrowForwardIcon />
        </button>
      </Grid>
      <Grid
        className="footer-land__bottom"
        container
        item
        style={{margin: 'auto', alignItems: 'center'}}
        xs={10}
        lg={11}
      >
        <div className="footer-land__logo-link">
          <img src={Logo} alt="Logo Devtorium" />
        </div>
        <div className="footer-land__social">
          <a
            href="https://www.facebook.com/devtoriumsoftwarecompany"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://www.instagram.com/marquette.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://www.linkedin.com/company/devtorium/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
        <div className="footer-land__legal-info">
          <Link to={`/terms`}>Terms & Conditions</Link>
          <Link to={`/policy-privacy`}>Privacy Policy</Link>
          <Link to={`/user-agreement`}>User Agreement</Link>
          <Button className="orange-btn orange-btn-hover footer-land__link-btn">
            <Link to={`/contact-us`}>Contact Us</Link>
          </Button>
          <p>©{CURRENT_YEAR} Marquètte. All Right Reserved</p>
        </div>
      </Grid>
    </div>
  );
};

export default LandingFooter;
