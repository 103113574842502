import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  GET_CREDITS_DETAILS_REQUEST,
  GET_CREDITS_DETAILS_SUCCESS,
  GET_CREDITS_DETAILS_FAILURE,
  POST_BUY_CREDITS_REQUEST,
  POST_BUY_CREDITS_SUCCESS,
  POST_BUY_CREDITS_FAILURE,
} from '../actions/types/types';
import {ICreditsState, ICreditsAction} from '../interfaces';

export const initialState: ICreditsState = {
  CreditsAwailable: null,
  CreditsBought: null,
  CreditsBoughtError: '',
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_CREDITS_DETAILS_REQUEST]: (state: ICreditsState) => ({
    ...state,
    CreditsBought: null,
    CreditsBoughtError: '',
    loading: true,
  }),

  [GET_CREDITS_DETAILS_SUCCESS]: (state: ICreditsState, action: ICreditsAction) => ({
    ...state,
    CreditsAwailable: action.payload,
    loading: false,
    error: '',
  }),

  [GET_CREDITS_DETAILS_FAILURE]: (state: ICreditsState, action: ICreditsAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [POST_BUY_CREDITS_REQUEST]: (state: ICreditsState) => ({
    ...state,
    CreditsBought: null,
    CreditsBoughtError: '',
    loading: true,
  }),

  [POST_BUY_CREDITS_SUCCESS]: (state: ICreditsState, action: ICreditsAction) => ({
    ...state,
    CreditsBought: action.payload,
    CreditsBoughtError: '',
    loading: false,
  }),

  [POST_BUY_CREDITS_FAILURE]: (state: ICreditsState, action: ICreditsAction) => ({
    ...state,
    CreditsBought: null,
    CreditsBoughtError: action.payload,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
