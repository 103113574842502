import {handleActions} from 'redux-actions';
// ACTION TYPES
import {
  GET_SINGLE_USER_REQUEST,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAILURE,
} from '../actions/types/types';
import {IGetUserManagementProfileAction, IUserManagementProfileState} from '../interfaces';

export const initialState = {
  userManagementProfile: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_SINGLE_USER_REQUEST]: (state: IUserManagementProfileState) => ({
    ...state,
    loading: true,
  }),

  [GET_SINGLE_USER_SUCCESS]: (
    state: IUserManagementProfileState,
    action: IGetUserManagementProfileAction,
  ) => ({
    ...state,
    userManagementProfile: action.payload,
    loading: false,
    error: '',
  }),

  [GET_SINGLE_USER_FAILURE]: (
    state: IUserManagementProfileState,
    action: IGetUserManagementProfileAction,
  ) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
