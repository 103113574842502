import React, {useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CachedIcon from '@material-ui/icons/Cached';
import DoneIcon from '@material-ui/icons/Done';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import {IconButton, TextField} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {GrammarlyEditorPlugin} from '@grammarly/editor-sdk-react';

import './style.scss';

import {ReactComponent as WarningIcon} from '../../../images/warning.svg';

import {
  IInputControl,
  INNFormattingResponse,
  ISignalRResponse,
  IState,
  ITextModelDetails,
  IUserProfile,
} from '../../../interfaces';
import {NNFormattingCommands} from '../../../enums/TextModel';
import {nnFormattingModel} from '../../../api/textModel';
import LoadingLinear from '../../UI/LoadingLinear';
import {setSignalRCellEditRequest} from '../../../actions/signalRCellEdit';
import {getTextGenerationResultRequest} from '../../../actions/textModel';

interface IProps {
  controlSelector: JSX.Element;
  controlEditBtns: JSX.Element;
  isActive: boolean;
  isPointed: boolean;
  inputItem: IInputControl;
  idx: number;
  onSelection: (index: number, inputName: string, type: string) => void;
  onChange: (value: string, inputName: string, idx: number) => void;
}

export enum NNFormattingError {
  EmptyText,
  UnknownError,
}

export interface INNFValueError {
  code: NNFormattingError;
  type: 'error' | 'warning';
  text: string;
}

const BTN_ACTIONS = [
  {label: 'Rephrase', icon: <CachedIcon />, command: NNFormattingCommands.Rephrase},
  {label: 'Shorten', icon: <CloseFullscreenIcon />, command: NNFormattingCommands.Shorten},
  {
    label: 'Continue Writing ',
    icon: <PlaylistAddIcon />,
    command: NNFormattingCommands.ContinueWriting,
  },
];

const detailsSelector = (state: IState) => state.textModel.textModelDetails as ITextModelDetails;
const signalRCellEdit = (state: IState) => state.signalRCellEdit.status as ISignalRResponse;

const NNFormatControl = (props: IProps): JSX.Element => {
  const {
    inputItem,
    isActive,
    controlSelector,
    idx,
    onChange,
    isPointed,
    onSelection,
    controlEditBtns,
  } = props;
  const dispatch = useDispatch();
  const modelDetails = useSelector(detailsSelector);
  const cellEditData = useSelector(signalRCellEdit);
  const user: IUserProfile = useSelector((state: IState) => state.userProfile.userProfile);
  const [currentValue, setCurrentValue] = useState<string>(inputItem.values[idx]);
  const [nnFormattingData, setNnFormattingData] = useState<INNFormattingResponse | null>(null);
  const [nnFormattingResponsePending, setNNFormattingResponsePending] = useState<boolean>(false);
  const [selectedCommand, setSelectedCommand] = useState<NNFormattingCommands | null>(null);
  const [previousSelectedCommand, setPreviousSelectedCommand] =
    useState<NNFormattingCommands | null>(null);
  const [valueErrors, setValueErrors] = useState<INNFValueError[]>([]);
  const [loadFormatting, setLoadFormatting] = useState<boolean>(false);
  const [cancelLoadFormatting, setCancelLoadFormatting] = useState<boolean>(false);
  const [nnFormattingResponse, setNnFormattingResponse] = useState<{
    data?: INNFormattingResponse;
    error?: INNFValueError;
  }>({});
  const operationId = useMemo(() => uuidv4(), []);

  useEffect(() => {
    if (cancelLoadFormatting) {
      setCancelLoadFormatting(false);

      return;
    }

    if (nnFormattingResponse.error) {
      setValueErrors([nnFormattingResponse.error]);
      setNnFormattingData(null);

      return;
    }

    if (nnFormattingResponse.data) {
      setValueErrors([]);
      setNnFormattingData(nnFormattingResponse.data);

      return;
    }
  }, [nnFormattingResponse]);

  useEffect(() => {
    if (cellEditData?.operationId !== operationId || cellEditData?.operationId === '') {
      return;
    }

    setNNFormattingResponsePending(false);

    if (cellEditData) {
      if (cellEditData.error) {
        setNnFormattingResponse({
          error: {
            code: NNFormattingError.EmptyText,
            text: cellEditData.error,
            type: 'warning',
          },
        });
      } else {
        setNnFormattingResponse({
          data: {
            wordCount: cellEditData.wordCount,
            userCredits: cellEditData.userCredits,
            text: cellEditData.text,
          },
        });

        dispatch(
          getTextGenerationResultRequest({
            textModelId: modelDetails.id,
            pageSize: 999,
          }),
        );
      }
    } else {
      setNnFormattingResponse({
        error: {
          code: NNFormattingError.EmptyText,
          text: `We're sorry, AI couldn't complete this task. Please try again.`,
          type: 'warning',
        },
      });
    }

    dispatch(
      setSignalRCellEditRequest({
        operationId: '',
        text: '',
        wordCount: 0,
      }),
    );
  }, [cellEditData?.operationId]);

  useEffect(() => {
    if (selectedCommand === null || !loadFormatting) {
      return;
    }

    setNNFormattingResponsePending(true);
    setLoadFormatting(false);

    nnFormattingModel({
      text: currentValue,
      command: selectedCommand,
      context: null,
      textModelId: modelDetails.id,
      modelName: modelDetails.name,
      templateName: modelDetails.textModelTemplateName,
      inputControlAlias: inputItem.alias,
      operationId: operationId,
    })
      .then((res) => {
        return;
      })
      .catch((err) => {
        console.error('nnFormattingRequest failed', err);
        setNNFormattingResponsePending(false);
        setNnFormattingResponse({
          error: {
            code: NNFormattingError.UnknownError,
            text: `We're sorry, AI couldn't complete this task. Please try again.`,
            type: 'warning',
          },
        });
      });
  }, [loadFormatting]);

  const selectCommand = (command: NNFormattingCommands) => {
    setPreviousSelectedCommand(selectedCommand);
    setSelectedCommand(command);
    setLoadFormatting(true);
  };

  const save = () => {
    if (nnFormattingData === null) {
      return;
    }

    onInputChanged(nnFormattingData.text);
    setValueErrors([]);
    setSelectedCommand(null);
    setNnFormattingData(null);
  };

  const refresh = () => {
    if (selectedCommand !== null) {
      setPreviousSelectedCommand(selectedCommand);
      setLoadFormatting(true);
    }
  };

  const cancel = () => {
    setValueErrors([]);
    setSelectedCommand(null);
    setNnFormattingData(null);
  };

  const onInputChanged = (inputValue: string) => {
    onChange(inputValue, inputItem.name, idx);
    setCurrentValue(inputValue);
  };

  const cancelRequest = () => {
    setCancelLoadFormatting(true);
    setNNFormattingResponsePending(false);
    setSelectedCommand(previousSelectedCommand);
  };

  const loadFormattingLabel = (command: NNFormattingCommands | null): string => {
    const labels = {
      [NNFormattingCommands.Expand]: 'Expand ...',
      [NNFormattingCommands.Rephrase]: 'Rephrase ...',
      [NNFormattingCommands.ContinueWriting]: 'Continue Writing ...',
      [NNFormattingCommands.Shorten]: 'Shorten ...',
    };
    return command ? labels[command] : 'Loading ...';
  };

  return nnFormattingResponsePending ? (
    <LoadingLinear label={loadFormattingLabel(selectedCommand)} onCancel={() => cancelRequest()} />
  ) : (
    <FormControl
      className={`form__control ${isPointed ? 'pointed' : ''} ${isActive ? 'selected' : ''}
      nn-format ${inputItem.type.name.includes('Selector') ? 'with-margin' : ''}`}
      onClick={() => onSelection(idx, inputItem.name, inputItem.type.name)}
    >
      {controlSelector}
      <div className="nn-format-control" onClick={(ev) => isActive && ev.stopPropagation()}>
        <div className="nn-format-control__textfield-wr">
          <div className="nn-format-control__header-actions">
            {BTN_ACTIONS.map((btn, i) => (
              <Button
                key={i}
                disabled={user.creditCount === 0}
                className={`square-btn ${
                  btn.command === selectedCommand ? 'square-btn--selected' : ''
                }`}
                startIcon={btn.icon}
                onClick={(e) => {
                  selectCommand(btn.command);
                }}
              >
                <span className="nn-format-control__label">{btn.label}</span>
              </Button>
            ))}
          </div>
          <div
            className={`nn-format-control__control ${
              isActive && valueErrors.length > 0 ? 'warning' : ''
            }`}
          >
            <GrammarlyEditorPlugin>
              <TextField
                id="standard-basic"
                multiline={true}
                variant="outlined"
                value={currentValue}
                onChange={(ev) => {
                  onInputChanged(ev.target.value);
                }}
              />
            </GrammarlyEditorPlugin>
            {isActive && nnFormattingData && (
              <>
                <p className="nn-format-control__formatting-title">New Generated</p>
                <div className="nn-format-control__formatting-text">{nnFormattingData.text}</div>
                <div className="nn-format-control__footer-actions">
                  <IconButton
                    className="orange-icon-btn orange-icon-btn--reverse"
                    aria-label="checkbox"
                    onClick={(e) => {
                      save();
                    }}
                  >
                    <DoneIcon />
                  </IconButton>

                  <IconButton
                    className="orange-icon-btn"
                    aria-label="checkbox"
                    onClick={(e) => {
                      refresh();
                    }}
                  >
                    <CachedIcon />
                  </IconButton>

                  <IconButton
                    className="orange-icon-btn"
                    aria-label="checkbox"
                    onClick={(e) => {
                      cancel();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </>
            )}
            {isActive && valueErrors.length > 0 && (
              <>
                <div className="nn-format-control__error-wr">
                  {valueErrors.map((error, idx) => (
                    <div key={idx} className="nn-format-control__error">
                      <WarningIcon className="nn-format-control__error-icon" />
                      {error.text}
                    </div>
                  ))}
                </div>
                <div className="nn-format-control__footer-actions">
                  <Button
                    endIcon={<CachedIcon />}
                    onClick={() => refresh()}
                    className="orange-btn orange-btn-hover nn-format-control__retry-btn"
                  >
                    Retry
                  </Button>

                  <IconButton
                    className="orange-icon-btn"
                    aria-label="checkbox"
                    onClick={(e) => {
                      cancel();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </>
            )}
          </div>
        </div>
        {controlEditBtns}
      </div>
    </FormControl>
  );
};

export default NNFormatControl;
