import React, {useEffect, useState} from 'react';
import {IState} from '../../../interfaces';
import {Editor} from './Editor/Editor';
import {useSelector} from 'react-redux';
import {IShortenEntry} from './index';
import {DeltaStatic} from 'quill';

interface IProps {
  entryItem: IShortenEntry;
  urlModeId: string | undefined;
  historyOpen: boolean;
  setHistoryOpen: (isOpen: boolean) => void;
  content: string;
  setContentWords: (data: DeltaStatic, n: number) => void;
  setContentAsHtml: (s: string) => void;
  pdfRef: React.RefObject<HTMLDivElement>;
  contentWords: number;
  setAiEditingLoading: (b: boolean) => void;
}

const EditorComponent = (props: IProps): JSX.Element => {
  const {content, setContentAsHtml, setContentWords, pdfRef, setAiEditingLoading} = props;
  const [generationStatus, setGenerationStatus] = useState<string>('');
  const textModelDetails = useSelector((state: IState) => state.textModel.textModelDetails);

  // useEffect(() => {
  //   setLiked(textModelDetails?.isFavorite as boolean);
  // }, [textModelDetails?.isFavorite]);
  //
  // const likeModel = useCallback((id: string, like: boolean) => {
  //   setLiked(like);
  //   dispatch(putLikeModelRequest({textModelId: id, isFavorite: like, isDetails: true}));
  // }, []);

  useEffect(() => {
    setGenerationStatus(textModelDetails?.status.name ?? 'Pending');
  }, [textModelDetails?.status.name]);

  return (
    <>
      {textModelDetails ? (
        <div className="rich-editor-control" data-color-mode="light" ref={pdfRef}>
          <div className="wmde-markdown-var"></div>
          <Editor
            modelId={textModelDetails.id}
            disabled={generationStatus === 'Generating' || textModelDetails?.status.name === 'Done'}
            hideToolbar={textModelDetails.status.name === 'Done'}
            value={content}
            onUpdateContentWords={setContentWords}
            onUpdateContentAsHtml={setContentAsHtml}
            setAiEditingLoading={setAiEditingLoading}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditorComponent;
