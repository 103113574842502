import {Action} from 'redux-actions';
import {put, takeLatest, ForkEffect, CallEffect, PutEffect} from 'redux-saga/effects';

//REDUX
import * as signalRAction from '../actions/signalRStep';
import * as userAction from '../actions/userProfile';
import * as signalRActionType from '../actions/types/types';
import {ISignalRAction} from '../interfaces';

export function* setSignalRStepTemplate(
  action: ISignalRAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void> {
  try {
    yield put(signalRAction.setSignalRStepTemplateSuccess(action.payload));
    yield put(userAction.updateUserCreditsRequest(action.payload));
  } catch (error) {
    yield put(signalRAction.setSignalRStepTemplateFailure(error));
  }
}

//WATCHERS
export function* watchSignalRStepTemplate(): Generator<ForkEffect<never>, void> {
  yield takeLatest(signalRActionType.SET_SIGNALR_STEP_REQUEST, setSignalRStepTemplate);
}
