import React, {useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';

import '../../style.scss';

interface IProps {
  italic?: boolean;
}

const LiveNotification = ({italic}: IProps): JSX.Element => {
  const [state, setState] = useState({
    one: true,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({...state, [event.target.name]: event.target.checked});
  };

  const {one} = state;
  return (
    <div className="NewsLettersNotification">
      <h3 className="SettingSideBar__common-tit">
        Devtorium Live
        {italic && (
          <span className="SettingContent__actionable-tit--italic">I would like to receive:</span>
        )}
      </h3>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            disabled
            className="SettingSideBar__common-checkbox NewsLettersNotification__checkbox disabled-checkbox"
            control={<Checkbox checked={one} onChange={handleChange} name="one" />}
            label="Notifications about upcoming live events"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default LiveNotification;
