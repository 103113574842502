import {GET, handleBody, handleExceptions} from './request';

export const getUserManagementProfile = (payload: {userId: string}): Promise<Object | null> => {
  const endpoint: string = `/Admin/UserProfile?UserId=${payload.userId}`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
