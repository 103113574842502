import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {Switch} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {IState} from '../../../../../interfaces';
import {standardDateFormat} from '../../../../../helpers/helper';
import UiAlert from '../../../../UI/UiAlert';
import * as userProfileAction from '../../../../../actions/userProfile';
import {ReactComponent as AskQuestionIcon} from '../../../../../images/askQuestion-large.svg';
import ModalWithImage from '../../../../UI/ModalWithImage';
import {LightTooltip} from '../../../../UI/StatusLabel';
import {useActiveUser} from '../../../../../helpers/customHooks';
import LoadingSpinner from '../../../../UI/LoadingSpinner';

export type SubscriptionRenewalData = {
  currency: string;
  autoRenew: boolean;
  renewalDate: string;
  renewalPrice: number;
};

interface SubscriptionRenewalSwitchProps {
  setAutoRenewalActivatedEnabled?: (enable: boolean) => void;
}

const SubscriptionRenewalSwitch = (props: SubscriptionRenewalSwitchProps): JSX.Element => {
  const {currentStatus} = useActiveUser();
  const userData = useSelector((state: IState) => state.userProfile);
  const paymentMethods = useSelector(
    (state: IState) => state.paymentMethods && state.paymentMethods.paymentMethods,
  );
  const autoRenewState = useSelector((state: IState) => state.subscriptionRenewal);
  const autoRenewalStatus = useSelector((state: IState) => state.subscriptionRenewal.isAutorenewal);
  const subscriptionRenewalData = userData.userProfile?.subscriptionRenewal;
  const subscriptionRenewal: SubscriptionRenewalData = {
    renewalDate: standardDateFormat(subscriptionRenewalData?.renewalDate || ''),
    autoRenew: subscriptionRenewalData?.autoRenew || false,
    currency: subscriptionRenewalData?.currency || 'usd',
    renewalPrice: subscriptionRenewalData?.renewalPrice || 0,
  };
  const usersCards = paymentMethods ? paymentMethods.items : [];
  const isDisabled =
    currentStatus.includes('Expired') || currentStatus === 'None' || !usersCards.length;
  const [openDisablePopup, setOpenDisablePopup] = useState<boolean>(false);
  const [openEnablePopup, setOpenEnablePopup] = useState<boolean>(false);
  const [isAutorenewDisabled, setAutorenewDisabled] = useState<boolean>(false);
  const [isAutorenewEnabled, setAutorenewEnabled] = useState<boolean>(false);

  const renewalUpdating = autoRenewState.loading || userData.loading;
  const dispatch = useDispatch();
  let renewalOnAlertText;
  let renewalOffAlertText;

  if (subscriptionRenewal && subscriptionRenewal.renewalDate) {
    renewalOnAlertText = `Yay! You’ve just made your life a bit easier with payment
      automation. The next subscription fee will be charged on ${subscriptionRenewal.renewalDate}.`;
    renewalOffAlertText = `Your Subscription will continue until
         ${subscriptionRenewal.renewalDate}. You can get back to easy 
         automated payments anytime.`;
  } else {
    renewalOnAlertText = `Yay! You’ve just made your life a bit easier with payment
          automation.`;
    renewalOffAlertText = `Your Subscription will continue. You can get back to easy 
         automated payments anytime.`;
  }

  const changeAutoRenewSwitcher = (element: React.ChangeEvent<HTMLInputElement>) => {
    element.preventDefault();
    if (element.target?.checked) {
      setOpenEnablePopup(true);
    } else {
      setOpenDisablePopup(true);
    }
  };

  function onAutorenewEnable() {
    dispatch(userProfileAction.postUserSubscriptionRenewalRequest({autoRenew: true}));
  }

  function onAutorenewDisable() {
    dispatch(userProfileAction.postUserSubscriptionRenewalRequest({autoRenew: false}));
  }
  useEffect(() => {
    if (!autoRenewState.loading && (openEnablePopup || openDisablePopup)) {
      autoRenewalStatus ? setAutorenewEnabled(true) : setAutorenewEnabled(false);
      !autoRenewalStatus && autoRenewalStatus !== null
        ? setAutorenewDisabled(true)
        : setAutorenewDisabled(false);
    }
  }, [autoRenewState.loading]);
  useEffect(() => {
    if (!userData.loading) {
      openDisablePopup && setOpenDisablePopup(false);
      openEnablePopup && setOpenEnablePopup(false);
    }
  }, [userData.loading]);
  return (
    <>
      <span className={`user-info__all-info--descr user-info__all-info--blue`}>
        <LightTooltip
          title="Automatically charged to your default card every billing cycle."
          placement="right"
        >
          <Switch
            className={'auto-renew-switcher'}
            checked={subscriptionRenewal?.autoRenew}
            onChange={(element) => changeAutoRenewSwitcher(element)}
            disabled={isDisabled}
          />
        </LightTooltip>
      </span>
      <ModalWithImage
        topImage={<AskQuestionIcon />}
        isOpen={openDisablePopup}
        specificClass={'auto-renew-modal'}
        title={'Disable Auto-Renewal?'}
        onClose={() => setOpenDisablePopup(false)}
        buttons={
          <>
            <Button className="orange-btn orange-btn-hover" onClick={onAutorenewDisable}>
              Disable
            </Button>
            <Button
              className="white-btn white-btn-hover"
              onClick={() => setOpenDisablePopup(false)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <p>
          After disabling the auto-renewal, you will lose the benefit of using Marquètte
          effortlessly. Without payment automation, you’ll have to monitor your subscription and
          make new payments manually.
        </p>
        {subscriptionRenewal?.renewalDate && (
          <p>
            Your current subscription will expire on
            <span className="date">{` ${subscriptionRenewal.renewalDate}`}</span>.
          </p>
        )}
        <p>
          No fees will be charged after the subscription expires, but you won’t be able to use all
          the cool Marquètte platform features.
        </p>
        <p>
          At that point, you can only access your History and ask questions until your unused
          credits run out
        </p>
        {renewalUpdating && (
          <div className="modal__window--loading">
            <LoadingSpinner />
          </div>
        )}
      </ModalWithImage>

      <ModalWithImage
        topImage={<AskQuestionIcon />}
        specificClass={'auto-renew-modal'}
        isOpen={openEnablePopup}
        title={'Enable Auto-Renewal?'}
        onClose={() => setOpenEnablePopup(false)}
        buttons={
          <>
            <Button className="orange-btn orange-btn-hover" onClick={onAutorenewEnable}>
              Enable
            </Button>
            <Button className="white-btn white-btn-hover" onClick={() => setOpenEnablePopup(false)}>
              Cancel
            </Button>
          </>
        }
      >
        <p>Enjoy the full benefits of automation by turning on the auto-renewal feature. </p>
        <p>Do it once and you’ll be able to use Marquètte anytime!</p>
        <p>
          We will automatically renew your subscription at the beginning of every billing cycle. The
          payment will be charged directly to your card. You’ll see it as “WWW.DEVTORIUM.COM” on
          your credit card statements.
        </p>
        {subscriptionRenewal?.renewalDate && (
          <p>
            The next subscription fee will be charged on
            <span className="date">{` ${subscriptionRenewal.renewalDate}`}</span>.
          </p>
        )}
        {renewalUpdating && (
          <div className="modal__window--loading">
            <LoadingSpinner />
          </div>
        )}
      </ModalWithImage>
      {isAutorenewDisabled && <UiAlert type={'success'} title={renewalOffAlertText} />}
      {isAutorenewEnabled && <UiAlert type={'success'} title={renewalOnAlertText} />}
    </>
  );
};

export default SubscriptionRenewalSwitch;
