import {handleBody, handleExceptions, GET, PUT, PATCH} from './request';
//GET ========================================================================

export const getUserSettings = (): // params: Params
Promise<Object | null> => {
  const endpoint: string = `/UserSettings`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
//PUT ======================================================================

export const saveUserSettings = (data: {
  settingId: number;
  appNotification: boolean;
  sendEmail: boolean;
}): Promise<void | Response | Object | null> => {
  const endpoint: string = `/UserSettings`;
  const options = {
    body: data,
  };

  return PUT(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
//PATCH ================================================================================
export const patchUserSettings = (data: {
  settingId: number;
  parameterName: string;
  value: boolean;
}): Promise<Object | Response | null> => {
  const endpoint: string = `/UserSettings`;
  const options = {
    body: data,
  };

  return PATCH(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
