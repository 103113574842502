// AUTH-MSAL - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as authActionTypes from './types/types';

export const statusLogout = createAction(authActionTypes.AAD_LOGOUT_SUCCESS);
export const statusLogin = createAction(authActionTypes.AAD_LOGIN_SUCCESS);
