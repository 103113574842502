import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as creditItems from '../api/billing';

// REDUX
import * as creditItemsAction from '../actions/billing';
import * as creditItemsActionType from '../actions/types/types';
import {Action} from 'redux-actions';

export function* getCreditItems(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  unknown
> {
  try {
    const payload = yield call(creditItems.getCreditItems, {});
    yield put(creditItemsAction.getCreditItemsSuccess(payload));
  } catch (error) {
    yield put(creditItemsAction.getCreditItemsFailure(error));
  }
}

// WATCHERS
export function* watchGetCreditItems(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(creditItemsActionType.GET_CREDIT_ITEMS_REQUEST, getCreditItems);
}
