import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as contactUs from '../api/contactUs';

// REDUX
import * as contactUsAction from '../actions/contactUs';
import * as contactUsActionType from '../actions/types/types';
import {Action} from 'redux-actions';
import {IContactUsAction} from '../interfaces';

export function* sendContactUs(
  action: IContactUsAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(contactUs.sendContactUsForm, action.payload);
    yield put(contactUsAction.postStatusSuccess(payload));
  } catch (error) {
    yield put(contactUsAction.postStatusFailure(error));
  }
}

// WATCHERS
export function* watchSendContactUs(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(contactUsActionType.POST_SEND_CONTACT_US_FORM_REQUEST, sendContactUs);
}
