// ALERTS - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';
import {REMOVE_ALERT, SHOW_ALERT, ADD_ALERT} from './types/types';
import {IAlert, IRemoveAlertPayload} from '../interfaces/alerts';

export const showAlert = createAction<IAlert>(SHOW_ALERT);
export const addAlert = createAction<IAlert>(ADD_ALERT);
export const removeAlert = createAction<IRemoveAlertPayload>(REMOVE_ALERT);
