import {handleBody, handleExceptions, GET, POST, DELETE, PUT} from './request';

export const getUserProfile = (): Promise<Object | null> => {
  const endpoint: string = `/Profile`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getUserPhoto = (payload: string): Promise<Object | null> => {
  const endpoint: string = `/Profile/ProfilePhoto?userId=${payload}`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const postUpdateProfileName = (payload: {fullName: string}): Promise<Object | null> => {
  const endpoint: string = `/Profile/UpdateProfile`;
  const options = {
    body: payload,
  };

  return PUT(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const postUpdateProfilePhoto = (payload: {photo: string}): Promise<Object | null> => {
  const endpoint: string = `/Profile/UpdateProfilePhoto`;
  const options = {
    body: payload,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const postInitUser = (payload: object): Promise<Object | null> => {
  const endpoint: string = `/Profile/InitialUserCreation`;
  const options = {
    body: payload,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const deleteUserAccount = (): Promise<Object | null> => {
  const endpoint: string = `/Profile`;

  return DELETE(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const postUserLastLogin = (payload: object): Promise<Object | null> => {
  const endpoint: string = `/Me/UpdateUserLastLoginDate`;
  const options = {
    body: payload,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const setSubscriptionAutoRenewal = (payload: object): Promise<Object | null> => {
  const endpoint: string = `/Profile/UpdateSubscriptionAutoRenew`;
  const options = {
    body: payload,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const deleteUserPhoto = (): Promise<Object | null> => {
  const endpoint: string = `/Profile/DeleteProfilePhoto`;

  const options = {
    body: {photo: ''},
  };

  return DELETE(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
