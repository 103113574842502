import React, {useEffect, useState} from 'react';
import './style.scss';
import {FormControl, FormControlLabel, FormHelperText} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import CustomCheckbox from '../../UI/CustomCheckbox';
import {Link} from 'react-router-dom';
import * as contactUsStatus from '../../../actions/contactUs';
import {useDispatch, useSelector} from 'react-redux';
import {IState} from '../../../interfaces';
import UiAlert from '../../UI/UiAlert';
import {load} from 'recaptcha-v3';
import {CAPTCHA_SITE_KEY} from '../../../constants';
import {StyledTextField} from '../../UI/TestTextField/StyledTextField';

const isSnap = navigator.userAgent === 'ReactSnap';

const ContactForm = (): JSX.Element => {
  const dispatch = useDispatch();
  const [name, setName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [isCheckboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [nameError, setNameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<boolean>(false);
  const [checkboxError, setCheckboxError] = useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const [notificationAlert, setNotificationAlert] = useState<
    ('error' | 'info' | 'success' | 'warning') | null
  >(null);

  const sendFormResponse = useSelector((state: IState) => state.contactUs.sendFormResponse);
  function onNameChange(value: string) {
    const isLatin = /^[a-zA-Z]+$/.test(value);
    const isNumber = /\d/.test(value);
    const hasTrailingSpace = /\s$/.test(value);

    if (value.length) {
      if (isNumber) {
        setNameError('Please enter a real name');
      } else if (!isLatin || hasTrailingSpace) {
        setNameError('Please enter Latin letters without trailing spaces');
      } else {
        setName(value);
        setNameError(null);
      }
    } else {
      setNameError(null);
    }
  }

  function onEmailChange(value: string) {
    const validation =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmailValid = validation.test(value);
    if (!isEmailValid && value.length) {
      setEmailError('Please enter a valid email');
    } else {
      setEmailError(null);
      setEmail(value);
    }
  }

  function sendMail() {
    setNotificationAlert(null);
    if (!name || !email || !message || !isCheckboxChecked) {
      !isCheckboxChecked && setCheckboxError(Boolean(!isCheckboxChecked));
      !message && setMessageError(Boolean(!message));
      !name && setNameError('This field is required');
      !email && setEmailError('This field is required');
    }
    if (name && email && message && isCheckboxChecked) {
      const messageToSend = {
        name: name,
        email: email,
        message: message,
        recaptchaToken: token,
      };
      dispatch(contactUsStatus.postStatusRequest(messageToSend));
    }
  }
  async function asyncFunction() {
    const recaptcha = await load(CAPTCHA_SITE_KEY);
    const token = await recaptcha.execute();
    return token;
  }

  if (!isSnap) {
    asyncFunction()
      .then((token) => setToken(token))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (sendFormResponse) {
      sendFormResponse.success ? setNotificationAlert('success') : setNotificationAlert('error');
    }
  }, [sendFormResponse]);
  return (
    <div className="contact-us__form-wr">
      <h2 className="form__title">Contact Us</h2>
      <p className="form__desc">
        If you have any questions, just reach out to us and we'll respond as fast as possible.
        Please provide as much information as possible.
      </p>
      <div>
        <StyledTextField
          label="Name"
          className="form__input"
          id="name-form-input"
          variant="outlined"
          fullWidth
          error={Boolean(nameError)}
          helperText={nameError ? nameError : ''}
          required
          onChange={(event) => onNameChange(event.target.value)}
          inputProps={{minLength: 3}}
          margin="dense"
        />
        <StyledTextField
          label="Email"
          className="form__input"
          id="name-form-input"
          variant="outlined"
          fullWidth
          type="email"
          error={Boolean(emailError)}
          helperText={emailError ? emailError : ''}
          required
          onBlur={(event) => onEmailChange(event.target.value)}
          margin="dense"
        />
        <StyledTextField
          label="Message"
          className="form__input"
          id="name-form-input"
          variant="outlined"
          fullWidth
          error={messageError}
          multiline={true}
          onChange={(event) => setMessage(event.target.value)}
          helperText={messageError ? 'This field is required' : ''}
          required
          onBlur={(event) => event.target.value && setMessageError(false)}
          margin="dense"
        />
        <FormControl required className="form__checkbox-wr" error={checkboxError}>
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={isCheckboxChecked}
                onChange={() => setCheckboxChecked(!isCheckboxChecked)}
              />
            }
            label={
              <p className="form__checkbox-label">
                I have read and accepted the{` `}
                <Link to={`/terms`}>Terms & Conditions</Link> and{` `}
                <Link to={`/policy-privacy`}>Privacy Policy.</Link>
              </p>
            }
          />
          <FormHelperText>{checkboxError && 'This field is required'}</FormHelperText>
        </FormControl>
        {notificationAlert && (
          <UiAlert
            type={notificationAlert}
            title={
              notificationAlert === 'success'
                ? 'Your request was successfully sent'
                : 'Something went wrong'
            }
          />
        )}
        <Button
          className="form__btn orange-btn orange-btn-hover"
          endIcon={<SendOutlinedIcon />}
          onClick={sendMail}
        >
          Send a message
        </Button>
      </div>
    </div>
  );
};

export default ContactForm;
