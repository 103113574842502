import React from 'react';
import classNames from 'classnames';
import {ReactComponent as InformationIcon} from './../../../images/information-icon.svg';
import './DialogMaxSizeImg.scss';

declare type ErrorsType = {
  maxFileSize?: boolean;
  maxNumber?: boolean;
  acceptType?: boolean;
  resolution?: boolean;
} | null;

interface IDialogMaxSizeImgProps {
  className?: string;
  error?: ErrorsType;
}

const DialogMaxSizeImg: React.FC<IDialogMaxSizeImgProps> = ({className, error}) => {
  let text: string = '';

  if (error?.resolution) text = 'Max size upload image 300 x 300 px ';
  else if (error?.maxFileSize) text = 'File could not be uploaded. File not must exceed 3MB';
  else if (error?.maxNumber) text = 'You can choose one image';
  else if (error?.acceptType) text = 'Selected file type is not allowed';
  else text = 'Opps , try again';

  return (
    <>
      <div className={classNames('dialog-max-size-img__container', className)}>
        <div className="dialog-max-size-img__icon">
          <InformationIcon fill="#FF9070" />
        </div>
        <p className="dialog-max-size-img__title">{text}</p>
      </div>
      <div className="dialog-max-size-img__wrapper"></div>
    </>
  );
};

export default DialogMaxSizeImg;
