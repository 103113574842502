import React, {useEffect} from 'react';
import * as notificationRequest from '../../actions/notifications';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

const AfterChangePass = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location && location.hash.toString().includes('#id_token')) {
      dispatch(notificationRequest.postStatusRequest());
      navigate('/account/profile#changePass=success');
    } else {
      navigate('/account/profile#changePass=fail');
    }
  }, [location]);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <LoadingSpinner />
    </div>
  );
};

export default AfterChangePass;
