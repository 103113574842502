import React, {RefObject} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import './style.scss';
import TableRowItem from './TableItem';
import EnhancedTableHead from './EnhancedTableHead';
import {IUserManagementItem} from '../../../interfaces';
import LoadingSpinner from '../../UI/LoadingSpinner';

export interface ITableData {
  id: string;
  userCompany: string;
  fullName: string;
  userId: string;
  subscriptionStatus: string;
  credits: number;
  subscriptionType: string;
  userEmail: string;
  createdDateTime: string;
  lastLoginDateTime: string;
  lastQuestionCreatedDateTime: string;
}

interface IProps {
  users: IUserManagementItem[];
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rows: number) => void;
  totalCount: number;
  isLoading: boolean;
  order: Order;
  setOrder: (order: Order) => void;
  orderBy: keyof ITableData | string;
  setOrderBy: (orderBy: keyof ITableData) => void;
  tableRef: RefObject<HTMLElement>;
}

type Order = 'asc' | 'desc';

const UsersTable = (props: IProps): JSX.Element => {
  const {
    users,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    isLoading,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    tableRef,
  } = props;
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ITableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
  };
  return (
    <div className="users-table">
      <TableContainer className="users-table__container">
        <Table innerRef={tableRef}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={users.length}
          />
          {!isLoading ? (
            <TableBody>
              {users.map((row, index) => (
                <TableRowItem key={index} item={row} index={index} />
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <tr>
                <td>
                  <LoadingSpinner />
                </td>
              </tr>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default UsersTable;
