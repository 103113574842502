import React, {useEffect} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
  IState,
  ITextModelCategoriesItem,
  ITextModelEntriesItem,
  ITextModelEntry,
} from '../../../../interfaces';
import moment from 'moment';
import {NavLink, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  setModelEntryClear,
  setNavigateToEntry,
  postCreateModelEntryRequest,
  deleteTextModelEntryRequest,
  publishTextModelEntryRequest,
} from '../../../../actions/textModel';
import {Button, IconButton, Menu, MenuItem} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import {ReactComponent as CloneIcon} from '../../../../images/clone-icon.svg';
import {ReactComponent as Publishcon} from '../../../../images/publish-button-icon.svg';
import {ReactComponent as Unpublishcon} from '../../../../images/unpublish-button-icon.svg';
import {ReactComponent as OpenMenuIcon} from '../../../../images/openMenu.svg';
import {ReactComponent as AskQuestionIcon} from '../../../../images/askQuestion-large.svg';
import ModalWithImage from '../../../UI/ModalWithImage';

interface IProps {
  item: ITextModelEntriesItem;
  index: number;
  categories: ITextModelCategoriesItem[] | undefined;
}

const TableRowItem = (props: IProps): JSX.Element => {
  const {item, index, categories} = props;
  const entryCreateState = useSelector((state: IState) => state.textModel.entryCreateResult);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const isMenuOpen = Boolean(anchorEl);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(!openMenu);
  };

  function showCategoryName(id: string) {
    const categoryName = categories?.find((i) => i.id === id);
    if (categoryName) {
      return categoryName.name;
    }
  }
  function navigateToEntry() {
    dispatch(setModelEntryClear());
    dispatch(setNavigateToEntry());
    navigate(`/admin/template-management/details/${item.id}`);
  }

  async function cloneEntry(entryItem: ITextModelEntry) {
    const data = {
      name: 'Clone of [' + entryItem?.name + ']',
      description: entryItem?.description,
      categoryId: entryItem?.categoryId,
      contentType: entryItem?.contentType,
      data: entryItem?.data,
      tags: entryItem?.tags,
      help: entryItem?.help,
      image: entryItem?.image,
      isPublished: false,
      qualityType: entryItem?.qualityType,
      creativityIndicator: entryItem?.creativityIndicator,
    };

    dispatch(postCreateModelEntryRequest(data));
  }

  useEffect(() => {
    if (entryCreateState?.id) {
      navigate(`/admin/template-management/details/${entryCreateState.id}`);
    }
  }, [entryCreateState]);

  function handleDeleteDialog() {
    setDeleteDialogOpen(true);
  }

  function handleDeleteTemplate() {
    dispatch(deleteTextModelEntryRequest({id: item.id}));
  }

  function onPublish() {
    const publishData = {
      id: item.id,
      isPublished: !item.isPublished,
    };

    dispatch(publishTextModelEntryRequest(publishData));
  }

  const menuId = 'product-menu';
  const renderMenu = (
    <>
      <Menu
        anchorEl={anchorEl}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem>
          <NavLink to={`/admin/template-management/details/${item.id}`}>
            <div className="coupon__menu-items">
              <EditOutlinedIcon />
              Edit
            </div>
          </NavLink>
        </MenuItem>

        <MenuItem onClick={() => cloneEntry(item)}>
          <div className="coupon__menu-items">
            <CloneIcon />
            Clone
          </div>
        </MenuItem>

        <MenuItem onClick={handleDeleteDialog}>
          <div className="coupon__menu-items">
            <DeleteIcon />
            <span>Delete</span>
          </div>
        </MenuItem>
        <MenuItem onClick={onPublish}>
          <div className="coupon__menu-items">
            {!item.isPublished ? (
              <>
                <Publishcon />
                <span>Publish</span>
              </>
            ) : (
              <>
                <Unpublishcon />
                <span>Unpublish</span>
              </>
            )}
          </div>
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <TableRow hover key={index} className="table-row">
        <TableCell
          component="th"
          scope="row"
          className="user-name"
          onClick={() => navigateToEntry()}
        >
          {item.name}
        </TableCell>
        <TableCell onClick={() => navigateToEntry()}>{item.description}</TableCell>
        <TableCell>{showCategoryName(item.categoryId)}</TableCell>
        <TableCell className="status" onClick={() => navigateToEntry()}>
          <span className={`${item.isPublished ? 'published' : 'draft'}`}>
            {item.isPublished ? 'Published' : 'Draft'}
          </span>
        </TableCell>
        <TableCell onClick={() => navigateToEntry()}>
          {item.updatedUtc ? moment(item.updatedUtc).format('MMM DD, YYYY') : '-'}
        </TableCell>
        <TableCell onClick={() => navigateToEntry()}>
          {item.createdUtc ? moment(item.createdUtc).format('MMM DD, YYYY') : '-'}
        </TableCell>
        <TableCell>
          <IconButton onClick={(e) => handleMenuOpen(e)}>
            <OpenMenuIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {renderMenu}
      <ModalWithImage
        isOpen={isDeleteDialogOpen}
        topImage={<AskQuestionIcon />}
        title={'Are you sure want to delete the template?'}
        subtitle={`Are you sure you want to delete ${item.name} Template?`}
        buttons={
          <>
            <Button className="orange-btn orange-btn-hover" onClick={() => handleDeleteTemplate()}>
              Yes, delete
            </Button>

            <Button
              className="white-btn white-btn-hover"
              onClick={() => setDeleteDialogOpen(!isDeleteDialogOpen)}
            >
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};

export default TableRowItem;
