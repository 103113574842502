import {useSelector} from 'react-redux';
import {IState} from '../../../../../interfaces';
import {LightTooltip} from '../../../../UI/StatusLabel';
import '../style.scss';

const DeleteAccount = (): JSX.Element => {
  const authMsal = useSelector((state: IState) => state.authMsal.aadResponse.account);
  const userEmail = authMsal && authMsal.username && authMsal?.username;

  const handleDeleteAccount = () => {
    ////////coming soon...
    // console.log(userEmail);
  };

  return (
    <>
      <div className="DeleteAccount">
        <div className="DeleteAccount__title">
          <h2 className="DeleteAccount__title--title">Delete Account</h2>
        </div>
        <p className="DeleteAccount__description">
          <span className="DeleteAccount__description--bold">
            Would you like to delete your Marquètte account:
          </span>
          <span className="DeleteAccount__description--orange">
            &nbsp;{userEmail}
            <span>?</span>
          </span>
          Deleting your account will remove all content and data associated with it.
        </p>
        <LightTooltip title="coming soon" placement="right">
          <button className="DeleteAccount__btn" onClick={handleDeleteAccount}>
            I want to delete my account
          </button>
        </LightTooltip>
      </div>
    </>
  );
};

export default DeleteAccount;
