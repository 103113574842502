import React from 'react';
import {IData} from '..';
import {ListItem} from '@material-ui/core';
import {BLOB_LINK} from '../../../../constants';

interface IProps {
  templates: IData;
  index: number;
}

const TemplateItem = (props: IProps): JSX.Element => {
  const {templates, index} = props;
  const imageLink = `${BLOB_LINK}/assets-for-templates/`;

  return (
    <ListItem className="dashboard__list-item">
      <span className="dashboard__list-index">{index + 1}.</span>
      {templates.img && (
        <img
          className="dashboard__list-icon"
          src={`${imageLink}${templates.img}`}
          alt="Words Icon"
        />
      )}
      <span className="dashboard__list-alias">{templates.templateName}</span>
    </ListItem>
  );
};

export default TemplateItem;
