import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  POST_CREATE_COUPON_REQUEST,
  POST_CREATE_COUPON_SUCCESS,
  POST_CREATE_COUPON_FAILURE,
  POST_APPLY_COUPON_REQUEST,
  POST_APPLY_COUPON_SUCCESS,
  POST_APPLY_COUPON_FAILURE,
  GET_COUPONS_HISTORY_REQUEST,
  GET_COUPONS_HISTORY_SUCCESS,
  GET_COUPONS_HISTORY_FAILURE,
  GET_COUPON_BY_ID_REQUEST,
  GET_COUPON_BY_ID_SUCCESS,
  GET_COUPON_BY_ID_FAILURE,
  GET_COUPONS_MANAGEMENT_REQUEST,
  GET_COUPONS_MANAGEMENT_FAILURE,
  GET_COUPONS_MANAGEMENT_SUCCESS,
  POST_CHECK_COUPON_REQUEST,
  POST_CHECK_COUPON_SUCCESS,
  POST_CHECK_COUPON_FAILURE,
  PUT_UPDATE_COUPON_REQUEST,
  PUT_UPDATE_COUPON_SUCCESS,
  PUT_UPDATE_COUPON_FAILURE,
  POST_CHECK_COUPON_WITHOUT_APPLY_REQUEST,
  POST_CHECK_COUPON_WITHOUT_APPLY_SUCCESS,
  POST_CHECK_COUPON_WITHOUT_APPLY_FAILURE,
} from '../actions/types/types';

import {
  IApplyCouponAction,
  ICouponCreateAction,
  ICouponErrorAction,
  ICouponState,
  ICouponsManagementAction,
  IGetCouponsHistoryAction,
  IGetCouponByIdAction,
  ICheckCouponResponseAction,
  IUpdateCouponAction,
} from '../interfaces';

export const initialState = {
  couponsManagement: null,
  createCouponResponse: null,
  applyCouponResponse: null,
  couponsHistory: null,
  couponById: null,
  updateCouponResult: null,
  checkCouponResponse: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [POST_CREATE_COUPON_REQUEST]: (state: ICouponState) => ({
    ...state,
    loading: true,
  }),
  [POST_CREATE_COUPON_SUCCESS]: (state: ICouponState, action: ICouponCreateAction) => ({
    ...state,
    createCouponResponse: action.payload,
    loading: false,
    error: '',
  }),
  [POST_CREATE_COUPON_FAILURE]: (state: ICouponState, action: ICouponErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [GET_COUPONS_MANAGEMENT_REQUEST]: (state: ICouponState) => ({
    ...state,
    loading: true,
  }),

  [GET_COUPONS_MANAGEMENT_SUCCESS]: (state: ICouponState, action: ICouponsManagementAction) => ({
    ...state,
    couponsManagement: action.payload,
    loading: false,
    error: '',
  }),

  [GET_COUPONS_MANAGEMENT_FAILURE]: (state: ICouponState) => ({
    ...state,
    loading: false,
    error: '',
  }),

  [POST_APPLY_COUPON_REQUEST]: (state: ICouponState) => ({
    ...state,
    loading: true,
  }),
  [POST_APPLY_COUPON_SUCCESS]: (state: ICouponState, action: IApplyCouponAction) => ({
    ...state,
    applyCouponResponse: action.payload,
    loading: false,
    error: '',
  }),
  [POST_APPLY_COUPON_FAILURE]: (state: ICouponState, action: ICouponErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [GET_COUPONS_HISTORY_REQUEST]: (state: ICouponState) => ({
    ...state,
    loading: true,
  }),
  [GET_COUPONS_HISTORY_SUCCESS]: (state: ICouponState, action: IGetCouponsHistoryAction) => ({
    ...state,
    couponsHistory: action.payload,
    loading: false,
    error: '',
  }),
  [GET_COUPONS_HISTORY_FAILURE]: (state: ICouponState, action: ICouponErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [GET_COUPON_BY_ID_REQUEST]: (state: ICouponState) => ({
    ...state,
    loading: true,
  }),
  [GET_COUPON_BY_ID_SUCCESS]: (state: ICouponState, action: IGetCouponByIdAction) => ({
    ...state,
    couponById: action.payload,
    loading: false,
    error: '',
  }),
  [GET_COUPON_BY_ID_FAILURE]: (state: ICouponState, action: ICouponErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [PUT_UPDATE_COUPON_REQUEST]: (state: ICouponState) => ({
    ...state,
    loading: true,
  }),
  [PUT_UPDATE_COUPON_SUCCESS]: (state: ICouponState, action: IUpdateCouponAction) => ({
    ...state,
    updateCouponResult: action.payload,
    loading: false,
    error: '',
  }),
  [PUT_UPDATE_COUPON_FAILURE]: (state: ICouponState, action: ICouponErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [POST_CHECK_COUPON_REQUEST]: (state: ICouponState) => ({
    ...state,
    loading: true,
  }),
  [POST_CHECK_COUPON_SUCCESS]: (state: ICouponState, action: ICheckCouponResponseAction) => ({
    ...state,
    checkCouponResponse: action.payload,
    loading: false,
    error: '',
  }),
  [POST_CHECK_COUPON_FAILURE]: (state: ICouponState, action: ICouponErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [POST_CHECK_COUPON_WITHOUT_APPLY_REQUEST]: (state: ICouponState) => ({
    ...state,
    loading: true,
  }),
  [POST_CHECK_COUPON_WITHOUT_APPLY_SUCCESS]: (
    state: ICouponState,
    action: ICheckCouponResponseAction,
  ) => ({
    ...state,
    checkCouponResponse: action.payload,
    loading: false,
    error: '',
  }),
  [POST_CHECK_COUPON_WITHOUT_APPLY_FAILURE]: (state: ICouponState, action: ICouponErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
