import {Grid, Hidden} from '@material-ui/core';
import BackButton from './../../UI/BackButton';
import GoodQuestionImg from '../../../images/goodQuestion.svg';
import {useEffect} from 'react';

interface IQuestionExample {
  title: string;
  wrongExample: string;
  rightExample: string;
}

export const GoodQuestion = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const questionsArr: Array<IQuestionExample> = [
    {
      title: 'Example 1',
      wrongExample: `What do you recommend how an insurance company or fintech company or
      beauty company should focus on their marketing efforts, web development,
      adding new features?`,
      rightExample: `What is recommended for US midsized fintech companies to focus on in
      digital marketing in 2022?`,
    },
    {
      title: 'Example 2',
      wrongExample: `What are new PAM Platforms available for telecommunication operators?`,
      rightExample: `What are new Privileged Access Management Platforms available for
      telecommunication operators?`,
    },
  ];

  const renderQuestionExamples = () => {
    return questionsArr.map((item: IQuestionExample) => (
      <div key={item.title}>
        <h4>{item.title}</h4>
        <div className="info-page__example-row">
          <div className="info-page__example-call info-page__example--warning">
            <p className="info-page__example-label">Wrong</p>
            <p>{item.wrongExample}</p>
          </div>
          <div className="info-page__example-call info-page__example--success">
            <p className="info-page__example-label">Right</p>
            <p>{item.rightExample}</p>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <Grid container className="info-page">
        <Grid item xs={11} md={11} lg={10}>
          <div className="info-page__wr">
            {/*{history.length > 1 && <BackButton match={history} prevPage />}*/}
            <BackButton prevPage />
            <Grid container>
              <Grid item xs={12} md={9} lg={8}>
                <h1 className="info-page__title">How to ask a good question</h1>

                <div className="info-page__content">
                  <img src={GoodQuestionImg} alt="help-center_good-question" />
                  <ul>
                    <li>Make your question short and specific.</li>
                    <li>
                      It’s best to start your question with a question word. For example, ‘What?’
                      ‘Where?’ ‘When?’ etc.
                    </li>
                    <Hidden xsUp>
                      <li>
                        Ask questions about things that are or were discussed in the news media.
                      </li>
                    </Hidden>
                    <li>Your question should be only one sentence long.</li>
                    <li>
                      Double-check to make sure you’ve included all details you are interested in,
                      for example, time period, location, niche, company size, etc.
                    </li>
                    <li>
                      Do not use any acronyms, abbreviations, or contractions in your questions.
                    </li>
                    <li>
                      Any industry-specific acronyms should be written as complete words, for
                      instance, not ‘NASA’ but ‘National Aeronautics and Space Administration’.
                    </li>
                    <li>Please use formal language when asking questions.</li>
                    <li>
                      Slang and industry-specific jargon might be interpreted and processed
                      incorrectly, which affects the results.
                    </li>
                    <li>
                      Please limit your questions to a single industry or niche in order to get the
                      most accurate answers.
                    </li>
                  </ul>

                  <div className="info-page__examples">
                    <h2 className="info-page__subtitle">Check out some examples</h2>
                    {renderQuestionExamples()}
                  </div>
                  <Hidden xsUp>
                    <div>
                      <p>The list of media sources we use for research is provided.</p>
                      <p>You can select any or all of these sources.</p>
                      <p>
                        You also can add some sources of your choice to the database so we can
                        generate a more detailed answer for you. We would greatly appreciate it if
                        you share your trusted sources of industry news with us.
                      </p>
                    </div>
                  </Hidden>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default GoodQuestion;
