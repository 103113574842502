import {DeltaStatic} from 'quill';

export function MDtoQuill(mdText: string): DeltaStatic {
  const data = [];

  const strings = mdText.split('\n');

  for (let i = 0; i < strings.length; i++) {
    const s = strings[i].trim();

    if (strings[strings.length - 1].trim() === '') strings.pop();

    if (s.length == 0) {
      // empty string
      data.push({insert: '\n'});
    } else if (s[0] === '#') {
      if (s.length > 1 && s[1] != '#' && s[1] != ' ') {
        // hashtag or hashtags
        let hashtag = s;
        if (hashtag.indexOf(' ') !== -1) {
          // many words/hashtags
          const subStrings = hashtag.split(' ');
          for (let j = 0; j < subStrings.length; j++) {
            hashtag = subStrings[j];
            data.push({
              insert: hashtag + ' ',
              attributes: {
                italic: hashtag[0] === '#',
              },
            });
          }
        } else {
          // one hashtags
          data.push({
            insert: hashtag,
            attributes: {
              italic: true,
            },
          });
        }
      } else {
        // headers
        const parts = s.split(' ');
        const headerData = parts.shift();
        const headerLevel = Math.min(headerData?.length || 4, 4);
        const headerText = parts.join(' ');
        data.push({
          insert: headerText,
          attributes: {
            bold: headerLevel === 4,
          },
        });
        data.push({
          insert: '\n',
          attributes: {header: headerLevel},
        });
      }
    } else if (s[0] === '[') {
      // links
      const linkParts = s.split('](');
      const linkName = linkParts[0].substring(1, linkParts[0].length - 1);
      const link = linkParts[1] ? linkParts[1].substring(1, linkParts[1].length - 1) : '';
      data.push({
        insert: ' ' + linkName + '\n',
        attributes: {link: link},
      });
    } else if (s.indexOf('https://') === 0 || s.indexOf('http://') === 0) {
      // links
      data.push({
        insert: s,
        attributes: {link: s},
      });
    } else if (s.indexOf('!KaTeX') === 0) {
      // katex
      const formula = s.substring(6, s.length - 5);
      data.push({
        insert: {formula: formula},
      });
    } else if (s !== '```') {
      // simple text
      data.push({insert: s + '\n'});
    }
  }

  return {ops: data} as DeltaStatic;
}
