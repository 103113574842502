import React from 'react';
import classNames from 'classnames';
import {ReactComponent as Arrow} from '../../../images/arrow-up.svg';

import './style.scss';

interface IArrowToTopProps {
  className?: string;
}

const ArrowToTop: React.FC<IArrowToTopProps> = ({className}) => {
  const handleGoToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={classNames('arrowToTop', className)}>
      <div className="arrowToTop__box" onClick={handleGoToTop}>
        <Arrow className="arrowToTop__arrow" />
      </div>
      <p className="arrowToTop__content">Back to Top</p>
    </div>
  );
};

export default ArrowToTop;
