import React from 'react';
import {Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
interface IProps {
  open: boolean;
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error' | undefined;
  setOpen?: (open: boolean) => void;
}
const SnackbarMessage = (props: IProps): JSX.Element => {
  const {open, severity, message, setOpen} = props;
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen && setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
