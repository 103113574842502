import {List} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import TemplateItem from './TemplateItem';
import TemplatesImg from '../../../images/used-templates-icon.svg';
import {ITextModelEntry, ITextModelStatistic} from '../../../interfaces';

export interface IData {
  img: string;
  templateName: string;
  count: number;
}
interface IProps {
  data?: ITextModelStatistic[] | null;
  entries?: ITextModelEntry[];
}

const UsedTemplates = (props: IProps): JSX.Element => {
  const {data, entries} = props;
  const [topTemplates, setTopTemplates] = useState<IData[]>([]);

  useEffect(() => {
    if (data) {
      const templates = groupingData(data);
      setTopTemplates(templates);
    }
  }, [data, entries]);

  function groupingData(data: ITextModelStatistic[]) {
    const groupedData: Record<string, number> = {};
    data?.forEach((item) => {
      if (item.categoryName !== null) {
        const {templateName} = item;
        if (!groupedData[templateName]) {
          groupedData[templateName] = 0;
        }
        groupedData[templateName] += 1;
      }
    });

    const sortedTemplates: IData[] = Object.keys(groupedData)
      .map((templateName) => {
        if (entries) {
          const imgArray = entries.map((item: ITextModelEntry) => ({
            templateName: item.name,
            img: item.image,
          }));

          const imgObject = imgArray?.find((item) => item.templateName === templateName);
          const img = imgObject?.img;

          if (img) {
            return {
              templateName,
              count: groupedData[templateName],
              img,
            };
          }
          return null;
        }
      })
      .filter((template) => template !== null)
      .map((template) => template as IData)
      .sort((a, b) => b.count - a.count)
      .slice(0, 5);

    return sortedTemplates;
  }

  return (
    <>
      <div className="dashboard__subtitle">Top Used Templates</div>
      <div className={topTemplates.length === 0 ? 'dashboard__empty' : ''}>
        {topTemplates.length > 0 ? (
          <List className="dashboard__list ">
            {topTemplates.map((item: IData, index: number) => (
              <TemplateItem key={index} templates={item} index={index} />
            ))}
          </List>
        ) : (
          <img className="dashboard__templates-img" src={TemplatesImg} alt="templates" />
        )}
      </div>
    </>
  );
};

export default UsedTemplates;
