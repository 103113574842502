import React, {useEffect, useState} from 'react';
import {Button} from '@material-ui/core';
import './style.scss';
import {useDispatch, useSelector} from 'react-redux';
import {deleteUserAccountRequest} from '../../../../actions/userProfile';
import {ReactComponent as DeleteAccountIcon} from '../../../../images/delete-account-pop-up.svg';
import {useNavigate} from 'react-router-dom';
import {IState} from '../../../../interfaces';
import ModalWithImage from '../../../UI/ModalWithImage';
import {StyledTextField} from '../../../UI/TestTextField/StyledTextField';

const DeleteAccount = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [confirmPopupOpen, setConfirmPopupOpen] = useState<boolean>(false);
  const [confirmationWord, setConfirmationWord] = useState<string>('');
  const deleteUserState = useSelector((state: IState) => state.userProfile.isUserAccountDeleted);

  function handleDeleteAccount() {
    dispatch(deleteUserAccountRequest());
  }
  function handleDeletePopUp() {
    setConfirmationWord('');
    setDeletePopupOpen(false);
    setConfirmPopupOpen(true);
  }
  function handleConfirmTextfield(value: string) {
    setConfirmationWord(value);
  }
  useEffect(() => {
    if (deleteUserState) {
      setConfirmPopupOpen(false);
      navigate('/delete-success');
    }
  }, [deleteUserState]);
  return (
    <>
      <div className="user-info__delete" id="delete">
        <h2 className="profile__subtitle">Delete Account</h2>
        <div className="profile__section">
          <div className="user-info__delete-content">
            <p>Deleting your account will remove all your content and data associated with it.</p>
            <p className="warning">This action is PERMANENT and IRREVERSIBLE</p>
          </div>
          <Button
            className="white-btn white-btn-hover user-info__delete-button"
            onClick={() => setDeletePopupOpen(true)}
          >
            Delete my account
          </Button>
        </div>
      </div>
      <ModalWithImage
        isOpen={deletePopupOpen}
        topImage={<DeleteAccountIcon />}
        title={'Are you sure you want to delete your account permanently?'}
        warning={'This action cannot be reversed.'}
        specificClass={'delete-account-modal'}
        buttons={
          <>
            <Button className="orange-btn orange-btn-hover" onClick={handleDeletePopUp}>
              Delete account
            </Button>
            <Button className="white-btn white-btn-hover" onClick={() => setDeletePopupOpen(false)}>
              Cancel
            </Button>
          </>
        }
      >
        <p className="delete-account-modal__info">
          This account will no longer be available. All data in the account will be deleted,
          including:
        </p>
        <ul className="delete-account-modal__list">
          <li className="delete-account-modal__list-item">
            Profile and all data associated with your account
          </li>
          <li className="delete-account-modal__list-item">
            All questions history and related answers
          </li>
        </ul>
        <p className="delete-account-modal__info">
          Your subscription will be terminated. All purchased credits will be lost.
        </p>
      </ModalWithImage>

      <ModalWithImage
        isOpen={confirmPopupOpen}
        topImage={<DeleteAccountIcon />}
        title={'Are you sure you want to delete your account permanently?'}
        warning={'This action cannot be reversed.'}
        specificClass={'delete-account-modal'}
        buttons={
          <>
            <Button
              className="orange-btn orange-btn-hover"
              onClick={handleDeleteAccount}
              disabled={confirmationWord !== 'DELETE'}
            >
              Delete account
            </Button>
            <Button
              className="white-btn white-btn-hover"
              onClick={() => setConfirmPopupOpen(false)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <p className="delete-account-modal__info">
          If you still want to delete your account, please type DELETE in the field below:
        </p>
        <StyledTextField
          id="standard-basic"
          variant="outlined"
          placeholder="Type DELETE to proceed"
          className="delete-account-modal__confirm-input"
          onChange={(event) => handleConfirmTextfield(event.target.value)}
        />
      </ModalWithImage>
    </>
  );
};

export default DeleteAccount;
