import {Grid, Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style.scss';
import React from 'react';

const FAQ = (): JSX.Element => {
  return (
    <div id="faq" className="faq-section-wr">
      <Grid
        className="len-section faq-section"
        container
        item
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
        xs={11}
        lg={8}
      >
        <h2 className="len-section-title">FAQ</h2>
        <Grid
          className="len-faq"
          container
          item
          style={{
            margin: 'auto',
            alignItems: 'center',
          }}
        >
          <Accordion className="panel-question">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="panel-header">
              <p>What does the price of Marquètte include?</p>
            </AccordionSummary>
            <AccordionDetails className="panel-content">
              <p>
                When you subscribe to the Silver Plan, you get access to the Marquètte AI-powered
                platform and all its features for the duration of <strong>30 days</strong>. You also
                get <strong>20,000 coins</strong> used to ‘pay’ for generated content. In addition,
                with this plan, you receive free market insights for your industry on the user Home
                page. Also, you get access to top-quality customer support via email.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="panel-question">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="panel-header">
              <p>How is my payment processed?</p>
            </AccordionSummary>
            <AccordionDetails className="panel-content">
              <p>
                We use <a href="https://stripe.com/">Stripe</a> for payment processing. Stripe is
                one of the leaders among secure and efficient payment processing services available
                online today. We do not get access to your credit card information, and it’s not
                stored within our databases. Your payment is 100% safe and secure!
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="panel-question">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="panel-header">
              <p>How much do I pay for content?</p>
            </AccordionSummary>
            <AccordionDetails className="panel-content">
              <p>
                The Silver Plan subscription gives you <strong>20,000 coins</strong>. The in-app
                ‘payment system’ counts <strong>1 coin per word</strong>. Therefore, you can
                generate up to 20,000 words of content with a monthly subscription.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="panel-question">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="panel-header">
              <p>Do you offer a free trial?</p>
            </AccordionSummary>
            <AccordionDetails className="panel-content">
              <div>
                <p>
                  Yes! You can enjoy using the Marquètte AI content writing software FREE for 7
                  days. Explore the features of the product to see how well it can complement your
                  content generation process. You will need to provide your credit card information
                  when signing up for the free trial. However, you won’t be billed for 7 days.
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion className="panel-question">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="panel-header">
              <p>What are Templates?</p>
            </AccordionSummary>
            <AccordionDetails className="panel-content">
              <p>
                To help content writers save more time and make using Marquètte more convenient, we
                created content Templates. These are different post presets you can use to generate
                specific types of content, for example, blog or SMM posts. In addition, you can use
                Templates to generate complex mathematical formulas to insert into the text. We also
                offer a Template that gives a quick business analysis of any company, which you can
                use for research to enrich your own articles.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="panel-question">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="panel-header">
              <p>How does Marquètte create content?</p>
            </AccordionSummary>
            <AccordionDetails className="panel-content">
              <p>
                Marquètte is an AI-powered platform that uses GPT-3 based language models from
                OpenAI. This AI learns by browsing texts on the Internet and processing textual
                content to predict sequences of words based on a variety of scenarios. It means that
                the answers provided by the AI content generator come from the vast global library
                of existing knowledge.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="panel-question">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="panel-header">
              <p>How do you store my data?</p>
            </AccordionSummary>
            <AccordionDetails className="panel-content">
              <p>
                When using the Marquètte AI-powered platform, your data is completely secure. We do
                not use it to provide any additional training to AI or store it within our servers.
                All data processing is transient. Ensuring your privacy is our top priority.
                Marquètte runs on <a href="https://aws.amazon.com/what-is-aws/?nc1=h_ls">AWS</a> and
                we use all possible precautions to keep your data protected. Our high security
                standards entail frequent updates to ensure your protection is always top-notch.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="panel-question">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="panel-header">
              <p>Can I get in touch with you?</p>
            </AccordionSummary>
            <AccordionDetails className="panel-content">
              <p>
                We are always ready to answer any questions you have or offer assistance with any
                issues.Please
                <a href={'/contact-us'}> get in touch with us anytime!</a>
              </p>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default FAQ;
