import {Grid} from '@material-ui/core';
import imgStudent from '../../../images/01-student.png';
import imgCreator from '../../../images/02_content_creator.png';
import imgSMM from '../../../images/03-smm.png';
import imgBusinesses from '../../../images/04-sm-businesses.png';
import imgEntrepreneurs from '../../../images/05-private-entrepreneurs.png';
import imgEnterprise from '../../../images/06-Enterprise.png';
import './style.scss';

const WhoCanUse = (): JSX.Element => {
  const loadHandler = () => {
    const target = window.location.hash.substr(1);
    const element = (target && document.getElementById(target)) || null;
    setTimeout(() => target && window.scrollTo(0, element?.offsetTop || 0), 500);
  };
  return (
    <div id="whoCanUse" className="whoCanUse-section-wr">
      <Grid
        className="len-section whoCanUse-section"
        container
        item
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
        xs={11}
        lg={8}
      >
        <div className="whoCanUse-section"></div>
        <h2 className="len-section-title">
          Who Can Use Marqu<span className="orange-text">è</span>tte
        </h2>
        <p className="len-section-subtitle len-section-subtitle--grey">
          Marquètte is a versatile AI solution that can benefit anyone. It’s all about how you
          choose to use it. The applications of this AI-powered platform are innumerable.
        </p>
        <Grid
          className="len-canUse"
          container
          item
          style={{alignItems: 'stretch', justifyContent: 'stretch'}}
        >
          <Grid className="len-canUse__item" container item sm={6} lg={4}>
            <img className="len-canUse__img" src={imgCreator} alt="" />
            <div className="len-canUse__description">
              <h3 className="len-subtitle">Content Writers</h3>
              <p>
                Marquètte is a fantastic AI content generator that gives you ideas and helps create
                any content. Templates make it even easier to generate the type of content you need!
              </p>
            </div>
          </Grid>
          <Grid className="len-canUse__item" container item sm={6} lg={4}>
            <img className="len-canUse__img" src={imgSMM} alt="" />
            <div className="len-canUse__description">
              <h3 className="len-subtitle">Marketing Agencies</h3>
              <p>
                Marquètte helps marketing agencies work with maximum efficiency. Boost the speed of
                your content creation by providing your team with an AI content generation tool.
              </p>
            </div>
          </Grid>
          <Grid className="len-canUse__item" container item sm={6} lg={4}>
            <img className="len-canUse__img" src={imgStudent} alt="" />
            <div className="len-canUse__description">
              <h3 className="len-subtitle">College Students</h3>
              <p>
                You can use the AI-powered platform when working on essays and projects. We even
                have a template for mathematical formulas.
              </p>
            </div>
          </Grid>
          <Grid className="len-canUse__item" container item sm={6} lg={4}>
            <img className="len-canUse__img" src={imgEntrepreneurs} alt="" />
            <div className="len-canUse__description">
              <h3 className="len-subtitle">Start-Ups and Entrepreneurs</h3>
              <p>
                Coming up with new marketing ideas or strategies can be hard for startups and small
                entrepreneurs. But Marquètte makes it easier by giving you valuable opinions from
                different sources. You can use them to kickstart many interesting campaigns.
              </p>
            </div>
          </Grid>
          <Grid className="len-canUse__item" container item sm={6} lg={4}>
            <img className="len-canUse__img" src={imgBusinesses} alt="" />
            <div className="len-canUse__description">
              <h3 className="len-subtitle">Small & Medium Business Owners</h3>
              <p>
                SMBs can use Marquètte to help plan their business strategies. Study competitors'
                activities, industry trends and act on the ideas you get to keep your business
                ahead.
              </p>
            </div>
          </Grid>
          <Grid className="len-canUse__item" container item sm={6} lg={4}>
            <img onLoad={loadHandler} className="len-canUse__img" src={imgEnterprise} alt="" />
            <div className="len-canUse__description">
              <h3 className="len-subtitle">Enterprise-Level Companies</h3>
              <p>
                Enterprises can use Marquètte to stay informed about their target audience’s
                preferences by studying opinions from the WEB. This can help optimize various
                processes within the enterprise.
              </p>
            </div>
          </Grid>
        </Grid>

        <p className="len-canUse__cta">
          Start using the full power of Marqu<span className="orange-text">è</span>tte right away!
        </p>
        <a href="/subscription-plan" className="orange-btn orange-btn-hover">
          See all Plans
        </a>
      </Grid>
    </div>
  );
};

export default WhoCanUse;
