// ADD CREDITS BY ADMIN - ACTION
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as addCreditsActionType from './types/types';

export const postAddCreditsStatusRequest = createAction(
  addCreditsActionType.POST_ADD_CREDITS_ADMIN_REQUEST,
);
export const postAddCreditsStatusSuccess = createAction(
  addCreditsActionType.POST_ADD_CREDITS_ADMIN_SUCCESS,
);
export const postAddCreditsStatusFailure = createAction(
  addCreditsActionType.POST_ADD_CREDITS_ADMIN_FAILURE,
);
