import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import './style.scss';
import ImageUploading, {ImageListType, ErrorsType} from 'react-images-uploading';
import {ReactComponent as PhotoIcon} from '../../../../../images/photo-icon.svg';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DialogMaxSizeImg from '../../../../UI/DialogMaxSizeImg/DialogMaxSizeImg';
import Tooltip from '@material-ui/core/Tooltip';
import {getUserPhotoRequest, deleteUserPhotoRequest} from '../../../../../actions/userProfile';
import ModalWithImage from '../../../../UI/ModalWithImage';
import {ReactComponent as AskQuestionIcon} from '../../../../../images/askQuestion-large.svg';
import {Button} from '@material-ui/core';

interface IIMGtoUpload {
  dataURL: string;
  file: File;
}

interface IImageUploadProps {
  avatar: string;
  userId: string;
  updPhotoState: {loading: boolean};
  editMode: boolean;
  setProfilePhoto: (img: string) => void;
}

const ImageUpload = (props: IImageUploadProps): JSX.Element => {
  const {avatar, userId, updPhotoState, editMode, setProfilePhoto} = props;
  const [images, setImages] = React.useState<IIMGtoUpload[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const maxNumber = 1;
  const onChange = (imageList: ImageListType) => {
    setImages(() => {
      return imageList as IIMGtoUpload[];
    });
  };

  useEffect(() => {
    if (images.length) {
      const imgString: string = images[0].dataURL;
      setProfilePhoto(imgString);
    }
  }, [images]);

  useEffect(() => {
    if (!updPhotoState.loading && images.length && avatar !== images[0]['dataURL']) {
      dispatch(getUserPhotoRequest(userId));
    }
  }, [updPhotoState.loading]);

  const handleDeleteImg = () => {
    dispatch(deleteUserPhotoRequest());
    setTimeout(() => dispatch(getUserPhotoRequest(userId)), 400);
    setImages([]);
    setOpen(false);
  };

  function handleCancel() {
    setOpen(false);
  }

  const errorFunction = (errors: ErrorsType) => {
    if (errors) {
      setIsError(true);
      setTimeout(() => setIsError(false), 2800);
    }
  };

  return (
    <div className="App">
      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        maxFileSize={3000000}
        acceptType={['jpg', 'png', 'jpeg']}
        onError={errorFunction}
        resolutionWidth={300}
        resolutionHeight={300}
      >
        {({
          imageList,
          onImageUpload,
          onImageUpdate,
          onImageRemove,
          // isDragging,
          dragProps,
          errors,
        }) => (
          <div className="upload__image-wrapper">
            {isError && <DialogMaxSizeImg error={errors} />}
            {!editMode ? (
              <>
                {avatar ? (
                  <img alt="user" src={avatar} width="100" className="profile-avatar" />
                ) : (
                  <PhotoIcon fill="currentColor" />
                )}
              </>
            ) : (
              <>
                {!imageList.length && editMode ? (
                  <Tooltip title="Update image" placement="left-start">
                    <button onClick={onImageUpload} {...dragProps} className="add-img-btn">
                      {avatar ? (
                        <img alt="user" src={avatar} width="100" className="profile-avatar" />
                      ) : (
                        <PhotoIcon fill="currentColor" />
                      )}
                    </button>
                  </Tooltip>
                ) : null}
              </>
            )}
            {editMode &&
              imageList.map((image, index) => (
                <div key={index}>
                  <div className="image-item">
                    <img src={image.dataURL} alt="" width="100" />
                    <div
                      className="image-item--hover"
                      onClick={() => {
                        onImageUpdate(index);
                      }}
                    >
                      <EditOutlinedIcon htmlColor="#ffffff" fontSize="large" />
                    </div>
                  </div>
                </div>
              ))}
            {avatar && editMode && (
              <div className="image-item__delete">
                <p className="image-item__btn" onClick={() => setOpen(true)}>
                  Delete picture
                </p>
                <ModalWithImage
                  isOpen={open}
                  topImage={<AskQuestionIcon />}
                  title={'Are you sure you want to delete picture?!'}
                  specificClass={'expired-plan__popup'}
                  buttons={
                    <>
                      <Button className="orange-btn orange-btn-hover" onClick={handleDeleteImg}>
                        Yes, delete
                      </Button>
                      <Button className="white-btn white-btn-hover" onClick={handleCancel}>
                        Cancel
                      </Button>
                    </>
                  }
                />
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default ImageUpload;
