import React, {useEffect} from 'react';
import ReactGA from 'react-ga4';
import {TRACKING_ID} from '../../constants';

interface IProps {
  children: JSX.Element;
}

const GoogleAnalyticsProvider = (props: IProps): JSX.Element => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);
  return <>{props.children}</>;
};

export default GoogleAnalyticsProvider;
