import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  GET_BILLING_HISTORY_REQUEST,
  GET_BILLING_HISTORY_SUCCESS,
  GET_BILLING_HISTORY_FAILURE,
} from '../actions/types/types';
import {IBillingHistoryAction, IBillingHistoryState} from '../interfaces';

export const initialState: IBillingHistoryState = {
  billingHistory: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_BILLING_HISTORY_REQUEST]: (state: IBillingHistoryState) => ({
    ...state,
    loading: true,
  }),

  [GET_BILLING_HISTORY_SUCCESS]: (state: IBillingHistoryState, action: IBillingHistoryAction) => ({
    ...state,
    billingHistory: action.payload,
    loading: false,
    error: '',
  }),

  [GET_BILLING_HISTORY_FAILURE]: (state: IBillingHistoryState, action: IBillingHistoryAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
