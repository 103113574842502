import BackButton from './../../components/UI/BackButton';
import Grid from '@material-ui/core/Grid';
import './style.scss';
import {useEffect} from 'react';

const UserAgreement = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="policy-privacy__title">
        <BackButton prevPage isTextBack={false} />
        <span className="policy-privacy__back"> User Agreement</span>
      </div>
      <div className="policy-privacy__content">
        <Grid container item>
          <p className="policy-privacy__paragraph">
            All visitors to our website are requested to read this User Agreement before accessing
            and using our website features and content. By accessing our website and its services
            even after reading the user agreement document, you signify acceptance of the terms and
            conditions stated in this Agreement.
          </p>
          <p className="policy-privacy__paragraph">
            If you have subscribed to our services, you will receive a nonexclusive, non-assignable,
            worldwide right to access and use the services you have signed for, but only for your
            business operations and within the terms of this Agreement.
          </p>
          <p className="policy-privacy__paragraph">
            You shall not permit anyone else to copy or republish the services other than to any
            persons authorized in the Agreement.
          </p>
          <p className="policy-privacy__paragraph">
            This Agreement includes all modifications, improvements, and upgrades related to
            intellectual property rights. You agree to assign all rights, title, and interest you
            may have in the foregoing to Marquètte.
          </p>
          <p className="policy-privacy__paragraph">
            You shall agree to comply with all the local, state, national and foreign laws as
            applicable in connection with the use of our services, including those laws related to
            the privacy of data, communications, and the transmission of data.
          </p>
          <p className="policy-privacy__paragraph">
            You shall agree not to post, upload, post, distribute, reproduce, share, or distribute
            any information or other material protected by copyright, privacy rights, or any other
            intellectual property right without obtaining the owner's explicit permission of such
            rights.
          </p>
          <p className="policy-privacy__paragraph">
            You shall inform/notify us immediately of any unauthorized use of any password or user
            identity or any other suspected or known breach of security. You must put in reasonable
            efforts to stop any unauthorized use of our services.
          </p>
          <p className="policy-privacy__paragraph">
            You shall be solely responsible for the acts and omissions of your users. We shall not
            be held liable for any loss of functionality or data caused by your direct or indirect
            actions.
          </p>
          <p className="policy-privacy__paragraph">
            As our customer, you will be solely responsible for putting together and reviewing all
            the content. You will also be responsible for making sure that the content does not
            include anything that essentially or potentially affects the copyright, trademark, or IP
            rights or infringes or contain anything obscene, defamatory, harassing, offensive or
            malicious.
          </p>
          <p className="policy-privacy__paragraph">
            You shall immediately notify us if you have information or suspect any unauthorized use
            of any password or user identity.
          </p>
          <p className="policy-privacy__paragraph">
            As a customer, you retain the ownership and intellectual property rights to all your
            content. Your right to use such third-party technology is governed by the terms of the
            third-party technology license agreement specified by us.
          </p>
          <p className="policy-privacy__paragraph">
            We shall enjoy a royalty-free, irrevocable license to use and incorporate suggestions,
            requests, and recommendations into our services.
          </p>
          <p className="policy-privacy__paragraph">
            All services acquired by you shall be governed exclusively by this Agreement.
          </p>
          <p className="policy-privacy__paragraph">
            The term of this Agreement shall begin on the agreed date and shall continue till its
            term or is mutually terminated by either party as stated in the agreement. If there is a
            breach of contract by you, this Agreement shall stand terminated within 30 days of
            receiving a notice about such a breach.
          </p>
          <p className="policy-privacy__paragraph">
            Upon termination of this Agreement or expiration of the Subscription Term, we shall stop
            providing our services immediately as well as all usage rights granted via this
            Agreement.
          </p>
          <p className="policy-privacy__paragraph">
            If we terminate this Agreement due to a breach by you on any of the terms, you must make
            good all losses caused by such an abrupt termination.
          </p>
        </Grid>
      </div>
    </>
  );
};

export default UserAgreement;
