import {Link} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import BackButton from './../../UI/BackButton';
import MoneyBackImg from '../../../images/money-back.svg';
import {useEffect} from 'react';

export const MoneyBack = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container className="info-page money-back">
        <Grid item xs={11} md={11} lg={10}>
          <div className="info-page__wr">
            {/*{history.length > 1 && <BackButton match={history} prevPage />}*/}
            <BackButton prevPage />
            <Grid container>
              <Grid item xs={12} md={9} lg={8}>
                <h1 className="info-page__title">What to do if I want to get my money back?</h1>

                <div className="info-page__content">
                  <img src={MoneyBackImg} alt="help-center_money-back" />
                  <div className="content-item">
                    <p>
                      Marquètte offers a money-back guarantee, which you can use if you aren’t happy
                      with the product.
                    </p>
                    <p>
                      If you want to get your money back, you only need to send us an email request
                      using the <Link to={`/contact-us`}>Feedback form</Link>.
                    </p>
                    <p>
                      Your request will be considered, and if all conditions are fulfilled, you will
                      get a <b>full refund</b> to your card <b>within the next 30 days</b>. You will
                      receive a refund notification via email.
                    </p>
                    <p>Conditions for refund are as follows:</p>
                    <ul>
                      <li>
                        The refund is only possible for a <b>Monthly subscription package</b>.
                        Yearly subscription does not have a refund option.
                      </li>
                      <li>
                        You would get a refund if you used the service for <b>less than 14 days</b>{' '}
                        since the subscription activation.
                      </li>
                      <li>
                        The refund is only possible if you{' '}
                        <b>did not purchase any additional credits</b>.
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default MoneyBack;
