import React, {useEffect, useState} from 'react';
import './style.scss';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import * as textModels from '../../../actions/textModel';
import {useDispatch, useSelector} from 'react-redux';
import {IState, ITextModelCategoriesItem} from '../../../interfaces';
import {IFilterArrayItems} from '../../MyContent';
import LoadingSpinner from '../../UI/LoadingSpinner';
import OptionsEdit from './OptionsEdit';
import OptionsPreview from './OptionsPreview';
import {useNavigate, useParams} from 'react-router-dom';
import AlertDialog from '../../UI/AlertDialog';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {getTextModelCategoriesRequest} from '../../../actions/textModel';
import {ITemplateBlock} from '../../../helpers/textModel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ShowPreviewButton from '../../UI/ShowPreviewButton';
import {BLOB_LINK, CREATIVITY_LEVEL_DATA, QUALITY_TYPE_DATA} from '../../../constants';
import ModalWithImage from '../../UI/ModalWithImage';
import {ReactComponent as AskQuestionIcon} from '../../../images/askQuestion-large.svg';

const useStyles = makeStyles(() => {
  return {
    paper: {
      minWidth: '286px',
      maxWidth: '400px',
      borderRadius: '8px',
    },
  };
});

const EntryDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const entryId = params.entryId;
  const classes = useStyles();

  const textModelState = useSelector((state: IState) => state.textModel);
  const entryItem = useSelector((state: IState) => state.textModel.singleTextModelEntry);
  const entriesImages = useSelector((state: IState) => state.textModel.entriesImages?.imageNames);

  const [categoryArray, setCategoryArray] = useState<IFilterArrayItems[] | null>([]);
  const [templateName, setTemplateName] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [contentType, setContentType] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [creativityLevel, setCreativityLevel] = useState<number>(0);
  const [qualityType, setQualityType] = useState<string>('');
  const [options, setOptions] = useState<ITemplateBlock[]>([]);
  const [help, setHelp] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [tags, setTags] = useState<string>('');
  const [warningAlertOpen, setWarningAlertOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false);
  const [cloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false);
  const [isSaved, setSaved] = useState<boolean>(true);
  const [imagesList, setImagesList] = useState<string[]>(['']);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const isSaveDisabled =
    !templateName ||
    !selectedCategory ||
    !contentType ||
    !description ||
    !options ||
    !help ||
    !tags ||
    !selectedImage ||
    !creativityLevel ||
    !qualityType;
  const isLoading = textModelState.loading;
  const imageLink = `${BLOB_LINK}/assets-for-templates/`;

  useEffect(() => {
    if (!textModelState.textModelCategories) {
      dispatch(getTextModelCategoriesRequest());
    }
    if (!textModelState.entriesImages) {
      dispatch(textModels.getTextModelEntriesImagesRequest());
    }
    if (entryId) {
      dispatch(textModels.getTextModelEntryRequest({id: entryId}));
    }
    if (textModelState.entryCreateResult) {
      dispatch(textModels.setCreateModelEntryNavigate());
    }
  }, []);

  useEffect(() => {
    setSaved(false);
  }, [
    templateName,
    selectedCategory,
    contentType,
    description,
    options,
    help,
    tags,
    creativityLevel,
    qualityType,
  ]);

  useEffect(() => {
    if (textModelState.textModelCategories && textModelState.textModelCategories.items.length) {
      const updatedArray = textModelState.textModelCategories.items.map(
        (item: ITextModelCategoriesItem) => ({
          id: item.id,
          name: item.name,
          imageUrl: item.imageUrl,
        }),
      );
      setCategoryArray(updatedArray);
    }
  }, [textModelState.textModelCategories]);

  useEffect(() => {
    if (entryItem) {
      const {
        name,
        description,
        categoryId,
        contentType,
        data,
        tags,
        help,
        image,
        creativityIndicator,
        qualityType,
      } = entryItem;
      setTemplateName(name);
      setDescription(description);
      setSelectedCategory(categoryId);
      setContentType(contentType);
      setOptions(data);
      setHelp(help);
      setTags(tags);
      setCreativityLevel(creativityIndicator);
      setQualityType(qualityType);
      image && setSelectedImage(image);
      //!entryId && navigate(`/admin/template-management/details/${entryItem.id}`, {replace: true});
    }
  }, [entryItem]);
  useEffect(() => {
    if (entriesImages) {
      const imagesList = entriesImages;
      setImagesList(imagesList);
    }
  }, [entriesImages]);

  function onSave(isPublished: boolean) {
    const data = {
      name: templateName,
      description: description,
      categoryId: selectedCategory,
      contentType: contentType,
      data: options,
      tags: tags,
      help: help,
      image: selectedImage,
      qualityType: qualityType,
      creativityIndicator: creativityLevel,
      isPublished: isPublished,
    };
    if (entryId) {
      dispatch(textModels.updateTextModelEntryRequest({...data, id: entryId}));
    } else {
      dispatch(textModels.postCreateModelEntryRequest(data));
    }
    navigate('/admin/template-management');
  }

  function onClose() {
    navigate('/admin/template-management');
  }

  const handleLeavePage = () => {
    if (!isSaved) {
      setWarningAlertOpen(!warningAlertOpen);
    } else {
      navigate('/admin/template-management');
    }
  };

  function deleteEntry() {
    dispatch(textModels.deleteTextModelEntryRequest({id: entryId}));
    setTimeout(() => {
      navigate('/admin/template-management');
    }, 300);
  }

  async function cloneEntry() {
    const data = {
      name: 'Clone of [' + entryItem?.name + ']',
      description: entryItem?.description,
      categoryId: entryItem?.categoryId,
      contentType: entryItem?.contentType,
      data: entryItem?.data,
      tags: entryItem?.tags,
      help: entryItem?.help,
      image: entryItem?.image,
      isPublished: false,
      qualityType: entryItem?.qualityType,
      creativityIndicator: entryItem?.creativityIndicator,
    };
    dispatch(textModels.postCreateModelEntryRequest(data));
    setCloneDialogOpen(!cloneDialogOpen);
  }
  useEffect(() => {
    if (entryItem?.id) {
      if (entryItem?.id !== entryId) {
        window.history.replaceState({}, '', `${entryItem?.id}`);
      }
    }
  }, [entryItem]);

  const maxChars: number = 121;

  return (
    <div className="entry-create">
      <Button className={`back-btn`} onClick={handleLeavePage} startIcon={<ArrowBackIcon />}>
        Back
      </Button>
      <div className="entry-create__title-wr">
        <h2 className="entry-create__title">
          {entryId ? 'Template editing' : 'Template Creating'}
        </h2>
        <div className="entry-create__btns-wr">
          {entryId && (
            <Button
              className="white-btn white-btn-hover"
              onClick={() => setCloneDialogOpen(!cloneDialogOpen)}
            >
              <span className="entry-create__btns-icon">
                <ContentCopyIcon />
              </span>
              Copy Template
            </Button>
          )}
          {entryId && (
            <Button className="white-btn white-btn-hover" onClick={() => setDeleteDialogOpen(true)}>
              <span className="entry-create__btns-icon">
                <DeleteIcon />
              </span>
              Delete
            </Button>
          )}
          <Button
            className="orange-btn orange-btn-hover"
            onClick={() => setSaveDialogOpen(true)}
            disabled={isSaveDisabled}
          >
            Save
          </Button>
        </div>
      </div>
      <Grid container className="entry-create__container">
        <Grid
          item
          style={{
            margin: '0 24px',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
          xs={11}
        >
          {categoryArray && categoryArray.length ? (
            <div className="entry-create__grid-container">
              <div className="entry-create__name">
                <p className="entry-create__input-title">Template name</p>
                <TextField
                  variant="outlined"
                  InputLabelProps={{shrink: false}}
                  className="entry-create__input"
                  value={templateName}
                  label="Enter template name"
                  id="template-name"
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </div>

              <div className="entry-create__category">
                <p className="entry-create__input-title">Choose category</p>
                <FormControl className="entry-create__input" variant="outlined">
                  <InputLabel id="category-select">Category</InputLabel>
                  <Select
                    id="category-select"
                    IconComponent={ExpandMoreIcon}
                    className="entry-create__category-select"
                    value={selectedCategory}
                    onChange={(e: React.ChangeEvent<{value: unknown}>) =>
                      setSelectedCategory(e.target.value as string)
                    }
                    required
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                      style: {marginTop: 50},
                    }}
                  >
                    {categoryArray.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        className="entry-create__category-items"
                      >
                        <img src={`${imageLink}${item.imageUrl as string}`} alt="category icon" />
                        <span className="entry-create__category-item">{item.name}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="entry-create__image-selector">
                <p className="entry-create__input-title">Template icon</p>
                <FormControl className="entry-create__input" variant="outlined">
                  <InputLabel id="icon-select">Icon</InputLabel>
                  <Select
                    id="icon-select"
                    IconComponent={ExpandMoreIcon}
                    className="entry-create__image-select"
                    value={selectedImage}
                    onChange={(e: React.ChangeEvent<{value: unknown}>) =>
                      setSelectedImage(e.target.value as string)
                    }
                    required
                    autoWidth
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      getContentAnchorEl: null,
                      style: {
                        marginTop: 60,
                      },
                      classes: {
                        paper: classes.paper,
                      },
                    }}
                  >
                    {imagesList.map((image) => (
                      <MenuItem key={image} value={image} className="entry-create__image-items">
                        <img src={`${imageLink}${image}`} alt="template icon" />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="entry-create__content">
                <p className="entry-create__input-title">Content type</p>
                <TextField
                  variant="outlined"
                  InputLabelProps={{shrink: false}}
                  className="entry-create__input"
                  value={contentType}
                  label="Enter content type"
                  // disabled={}
                  multiline
                  minRows={2}
                  onChange={(e) => setContentType(e.target.value)}
                />
              </div>
              <div className="entry-create__description">
                <p className="entry-create__input-title">Description</p>
                <TextField
                  variant="outlined"
                  inputProps={{
                    maxLength: maxChars,
                  }}
                  InputLabelProps={{shrink: false}}
                  className="entry-create__input"
                  value={description}
                  multiline
                  minRows={2}
                  label="Enter description"
                  type="text"
                  id=""
                  // disabled={}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <p className="entry-create__description-chars">
                  {description.length}/{maxChars}
                </p>
              </div>
              <div className="entry-create__parameters-top">
                <p className="entry-create__input-title">Template parameters</p>
                <ShowPreviewButton setShowPreview={setShowPreview} showPreview={showPreview} />
              </div>
              {options.length > 0 && entryId && (
                <div className="entry-create__parameters">
                  <OptionsEdit options={options} setOptions={setOptions} variant="outlined" />
                </div>
              )}
              {!entryId && (
                <div className="entry-create__parameters">
                  <OptionsEdit options={options} setOptions={setOptions} variant="outlined" />
                </div>
              )}
              {showPreview && (
                <div className="entry-create__preview">
                  <OptionsPreview options={options} status={true} />
                </div>
              )}
              <div className="entry-create__quality">
                <p className="entry-create__input-title">Quality</p>
                <FormControl className="entry-create__input" variant="outlined">
                  <InputLabel id="quality-select">Choose Quality level</InputLabel>
                  <Select
                    id="quality-select"
                    IconComponent={ExpandMoreIcon}
                    className="entry-create__category-select"
                    value={qualityType}
                    onChange={(e: React.ChangeEvent<{value: unknown}>) =>
                      setQualityType(e.target.value as string)
                    }
                    required
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {QUALITY_TYPE_DATA.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        className="entry-create__category-items"
                      >
                        <span className="entry-create__category-item">{item.name}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="entry-create__creativity">
                <p className="entry-create__input-title">Creativity</p>
                <FormControl className="entry-create__input" variant="outlined">
                  <InputLabel id="category-select">Choose Creativity level</InputLabel>
                  <Select
                    id="category-select"
                    IconComponent={ExpandMoreIcon}
                    className="entry-create__category-select"
                    value={creativityLevel}
                    onChange={(e: React.ChangeEvent<{value: unknown}>) =>
                      setCreativityLevel(e.target.value as number)
                    }
                    required
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {CREATIVITY_LEVEL_DATA.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        className="entry-create__category-items"
                      >
                        <span className="entry-create__category-item">{item.name}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="entry-create__help">
                <p className="entry-create__input-title">Help</p>
                <TextField
                  variant="outlined"
                  InputLabelProps={{shrink: false}}
                  id=""
                  className="entry-create__input help"
                  label="Add additional tips for using template"
                  multiline
                  value={help}
                  onChange={(e) => setHelp(e.target.value)}
                />
              </div>
              <div className="entry-create__tags">
                <p className="entry-create__input-title">Tags</p>
                <TextField
                  variant="outlined"
                  InputLabelProps={{shrink: false}}
                  id=""
                  className="entry-create__input"
                  label="Enter all tags for template"
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {isLoading && (
        <div className="overlay-loading">
          <LoadingSpinner />
        </div>
      )}
      <ModalWithImage
        isOpen={warningAlertOpen}
        topImage={<AskQuestionIcon />}
        title={'Your data won’t be saved!'}
        subtitle={'Are you sure you want to quit the process?'}
        buttons={
          <>
            <Button className="orange-btn orange-btn-hover" onClick={onClose}>
              Yes, confirm
            </Button>
            <Button
              className="white-btn white-btn-hover"
              onClick={() => setWarningAlertOpen(!warningAlertOpen)}
            >
              Cancel
            </Button>
          </>
        }
      />
      <ModalWithImage
        isOpen={saveDialogOpen}
        topImage={<AskQuestionIcon />}
        title={'How do you want to save the process?'}
        buttons={
          <>
            <Button className="orange-btn orange-btn-hover" onClick={() => onSave(true)}>
              Save & Publish
            </Button>
            <Button
              className="violet-btn orange-btn-hover dark-blue-btn"
              onClick={() => onSave(false)}
            >
              Save & Unpublish
            </Button>
            <Button
              className="white-btn white-btn-hover"
              onClick={() => setSaveDialogOpen(!saveDialogOpen)}
            >
              Cancel
            </Button>
          </>
        }
      />
      <AlertDialog
        isDialogOpen={deleteDialogOpen}
        setDialogOpen={() => setDeleteDialogOpen(!deleteDialogOpen)}
        text={{
          question: 'Are you sure want to delete the template?',
          warning: 'Template will be lost.',
          action: 'Yes, delete',
        }}
        confirmAction={deleteEntry}
      />
      {entryItem?.isPublished && (
        <AlertDialog
          isDialogOpen={cloneDialogOpen}
          setDialogOpen={() => setCloneDialogOpen(!cloneDialogOpen)}
          text={{
            question: 'Are you sure you want to clone this template?',
            action: 'Yes, clone',
          }}
          confirmAction={cloneEntry}
        />
      )}
    </div>
  );
};

export default EntryDetails;
