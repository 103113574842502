import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  POST_UPDATEPROFILE_NAME_REQUEST,
  POST_UPDATEPROFILE_NAME_SUCCESS,
  POST_UPDATEPROFILE_NAME_FAILURE,
} from '../actions/types/types';
import {IUserUpdateNameResponseAction, IUserUpdateNameState} from '../interfaces';

export const initialState: IUserUpdateNameState = {
  userUpdateName: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [POST_UPDATEPROFILE_NAME_REQUEST]: (state: IUserUpdateNameState) => ({
    ...state,
    loading: true,
  }),

  [POST_UPDATEPROFILE_NAME_SUCCESS]: (
    state: IUserUpdateNameState,
    action: IUserUpdateNameResponseAction,
  ) => ({
    ...state,
    userUpdateName: action.payload,
    loading: false,
    error: '',
  }),

  [POST_UPDATEPROFILE_NAME_FAILURE]: (state: IUserUpdateNameState, action: object) => ({
    ...state,
    error: action,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
