import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import {
  IGetTextGenerationResultPayload,
  IState,
  ITextGenerationResultItem,
  ITextModelEntriesItem,
} from '../../../interfaces';
import LoadingSpinner from '../../UI/LoadingSpinner';
import './style.scss';
import BackButton from '../../UI/BackButton';
import {
  getTextGenerationResultRequest,
  getTextModelEntriesRequest,
} from '../../../actions/textModel';
import {Pagination} from '@material-ui/lab';
import TableRowItem from './TableItem';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import SingleSelectDropdown from '../../UI/SingleSelectDropdown';
import NoContentImg from '../../../images/empty-content.png';

interface IFormatTemplates {
  image: string;
  name: string;
}

export interface IHistoryTable extends IFormatTemplates, ITextGenerationResultItem {}

const ChargeHistory = (): JSX.Element => {
  const dispatch = useDispatch();
  const textModels = useSelector((state: IState) => state.textModel);
  const history = useSelector((state: IState) => state.textModel.textGenerationResult);
  const userPlan = useSelector(
    (state: IState) => state.userProfile.userProfile.currentUserSubscription,
  );
  const mockSortStatus = [
    {id: 'Latest', name: 'Latest'},
    {id: 'Oldest', name: 'Oldest'},
  ];
  const [historyItems, setHistoryItems] = useState<IHistoryTable[] | null>([]);
  const [page, setPage] = useState<number>(1);
  const [filtersUpdated, setFiltersUpdated] = useState<Date | null>(null);
  const [currentOpenItem, setCurrentOpenItem] = useState<number | null>(null);
  const [selectedSort, setSelectedSort] = useState<string>('Descending');
  const historyData: IGetTextGenerationResultPayload = {
    sortOrder: selectedSort,
  };

  function loadHistory(page: number) {
    dispatch(getTextGenerationResultRequest({...historyData, pageNumber: page}));
  }

  useEffect(() => {
    dispatch(getTextModelEntriesRequest({pageSize: 1000}));
  }, []);

  useEffect(() => {
    !textModels.loading && loadHistory(page);
  }, [page, filtersUpdated]);

  useEffect(() => {
    if (history && history.items && textModels.textModelEntries?.items) {
      const historyItems = history.items.reduce<IHistoryTable[]>((acc, item) => {
        const hh = textModels.textModelEntries?.items.find(
          (i: ITextModelEntriesItem) => i.name === item.templateName,
        );
        if (hh) {
          acc.push({
            ...item,
            ...hh,
          });
        }
        return acc;
      }, []);
      setHistoryItems(historyItems);
    }
  }, [history, textModels.textModelEntries]);

  useEffect(() => {
    setPage(1);
    setFiltersUpdated(new Date());
  }, [selectedSort]);

  const handleRequestSort = () => {
    const isAsc = selectedSort === 'Ascending';
    setSelectedSort(isAsc ? 'Descending' : 'Ascending');
  };

  function handleFiltering(selectedSort: string) {
    selectedSort === 'Latest' ? setSelectedSort('Descending') : setSelectedSort('Ascending');
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <div className="charge-history">
      <Grid container style={{justifyContent: 'center'}}>
        {history?.totalCount !== 0 ? (
          <>
            <Grid
              item
              xs={12}
              sm={11}
              lg={10}
              className="charge-history__container component-white-wr"
            >
              <div className="billing__title-wr">
                <BackButton prevPage isTextBack={false} />
                <h1 className="billing__title">Credit History</h1>
              </div>
              <Hidden mdUp>
                <div className="content__sortBy-wr">
                  <p>Sort by</p>
                  <SingleSelectDropdown
                    buttonName={selectedSort === 'Descending' ? 'latest' : 'oldest'}
                    itemsList={mockSortStatus}
                    updateFunction={handleFiltering}
                  />
                </div>
              </Hidden>
              <div style={{overflow: 'auto'}}>
                <Hidden xsDown>
                  <div className="charge-history__table-header">
                    <p
                      className="charge-history__row charge-history__table-date"
                      onClick={() => handleRequestSort()}
                    >
                      Date
                      <span className="charge-history__sort">
                        <ArrowDropUpRoundedIcon />
                        <ArrowDropDownRoundedIcon />
                      </span>
                    </p>
                    <p className="charge-history__row charge-history__table-template">Template</p>
                    <p className="charge-history__row charge-history__table-content">
                      Content title
                    </p>
                    <p className="charge-history__row charge-history__table-field">Field Name</p>
                    <p className="charge-history__row charge-history__table-credits">Credits</p>
                  </div>
                </Hidden>
                <div className="charge-history__container">
                  {!textModels.loading ? (
                    <>
                      {historyItems?.map((row, index) => (
                        <TableRowItem
                          key={index}
                          item={row}
                          index={index}
                          currentItem={currentOpenItem}
                          setCurrentItem={setCurrentOpenItem}
                          userPlan={userPlan}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <LoadingSpinner />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <Pagination
                style={{display: 'flex', justifyContent: 'center', marginTop: '40px'}}
                count={history?.totalPages}
                defaultPage={1}
                page={page}
                onChange={handleChangePage}
                siblingCount={0}
              />
            </Grid>
          </>
        ) : (
          <>
            <div className="charge-history">
              <div className="charge-history__img">
                <img src={NoContentImg} alt="Empty History" />
              </div>
              <div className="charge-history__text">Your credits use history is empty</div>
            </div>
          </>
        )}
      </Grid>
    </div>
  );
};

export default ChargeHistory;
