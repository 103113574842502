import {ReactComponent as AskQuestionIcon} from '../../images/askQuestion-large.svg';
import Button from '@material-ui/core/Button';
import ModalWithImage from '../UI/ModalWithImage';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';

export const OutOfCredits = (): JSX.Element => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState<boolean>(true);
  function handleBuy() {
    navigate(`/subscription-plan#add-credits`);
  }
  function handleCancel() {
    setOpen(false);
  }
  return (
    <div className="user-warning-wr">
      <ModalWithImage
        isOpen={isOpen}
        topImage={<AskQuestionIcon />}
        title={'Oops! You used up all your credits!'}
        subtitle={
          'Get your power back and use Marquètte to the fullest by purchasing additional credits.'
        }
        buttons={
          <>
            <Button className="orange-btn orange-btn-hover" onClick={handleBuy}>
              Buy credits
            </Button>
            <Button className="white-btn white-btn-hover" onClick={handleCancel}>
              Cancel
            </Button>
          </>
        }
      />
    </div>
  );
};
export default OutOfCredits;
