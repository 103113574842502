import {useState} from 'react';
import {useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import StripeFooter from '../../../SubscriptionComponent/StripeFooter';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import cardGreyImg from '../../../../images/card-icon-grey.svg';
import cardGreenImg from '../../../../images/card-icon-green.svg';
import {ReactComponent as ModalIcon} from '../../../../images/askQuestion-large.svg';
import {IPaymentMethods} from '../../../../interfaces/Payments';
import {
  getPaymentMethodsRequest,
  postInitializeDefaultPaymentCardRequest,
  removePaymentCardRequest,
} from '../../../../actions/payments';
import ModalWithImage from '../../../UI/ModalWithImage';
import {IconButton} from '@material-ui/core';
import {SubscriptionStatus} from '../../../../enums/SubscriptionStatuses';

interface IProps {
  item: IPaymentMethods;
  subscriptionStatus: SubscriptionStatus;
  cardLength: number;
}

const PaymentItem = (props: IProps): JSX.Element => {
  const {item, subscriptionStatus, cardLength} = props;
  const dispatch = useDispatch();
  const [defaultModalOpen, setDefaultModalOpen] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const isCheckboxDisabled = cardLength === 1;

  const hideRemoveCardButton =
    subscriptionStatus === SubscriptionStatus.Endless || cardLength === 1;

  const handleOpen = () => {
    !(cardLength === 1) && setDefaultModalOpen(true);
  };
  const handleClick = () => {
    dispatch(postInitializeDefaultPaymentCardRequest({paymentMethodId: item.id}));
    setTimeout(() => dispatch(getPaymentMethodsRequest()), 400);
    setDefaultModalOpen(false);
  };
  const handleCancel = () => {
    setDefaultModalOpen(false);
  };
  const handleRemove = () => {
    dispatch(removePaymentCardRequest({id: item.id}));
    setTimeout(() => dispatch(getPaymentMethodsRequest()), 400);
    setRemoveModalOpen(false);
  };

  return (
    <div className={`card-item ${item.isDefaultPaymentCard ? 'default' : ''}`}>
      <div
        className={`card-item__info ${isCheckboxDisabled ? 'disabled' : ''}`}
        onClick={handleOpen}
      >
        {item.isDefaultPaymentCard ? (
          <IconButton aria-label="checkbox" disabled={isCheckboxDisabled}>
            <CheckBoxIcon />
          </IconButton>
        ) : (
          <IconButton aria-label="checkbox">
            <CheckBoxOutlineBlankIcon />
          </IconButton>
        )}
        <img src={item.isDefaultPaymentCard ? cardGreenImg : cardGreyImg} alt="cart icon" />
        <label htmlFor={item.id}>
          &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {item.last4}
        </label>
      </div>
      {!hideRemoveCardButton && (
        <p className="remove-card" onClick={() => setRemoveModalOpen(true)}>
          <DeleteIcon />
          <span>Remove card</span>
        </p>
      )}
      <ModalWithImage
        isOpen={defaultModalOpen}
        topImage={<ModalIcon />}
        title={'Change Default card?'}
        subtitle={'Set the following card as Default?'}
        onClose={() => setDefaultModalOpen(false)}
        specificClass={'card-modal'}
        buttons={
          <>
            <Button className="orange-btn orange-btn-hover" onClick={handleClick}>
              Yes, change
            </Button>
            <Button className="white-btn white-btn-hover" onClick={handleCancel}>
              Cancel
            </Button>
          </>
        }
        footer={<StripeFooter />}
      >
        <div className="modal__card-item">
          <img src={item.isDefaultPaymentCard ? cardGreenImg : cardGreyImg} alt="cart icon" />
          <p>
            &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;
            {item.last4}
          </p>
        </div>
        <p>Next payment will be charged to this card.</p>
      </ModalWithImage>

      <ModalWithImage
        isOpen={removeModalOpen}
        topImage={<ModalIcon />}
        title={'Remove card?'}
        onClose={() => setRemoveModalOpen(false)}
        specificClass={'card-modal'}
        buttons={
          <>
            <Button className="orange-btn orange-btn-hover" onClick={handleRemove}>
              Yes, remove
            </Button>
            <Button className="white-btn white-btn-hover" onClick={() => setRemoveModalOpen(false)}>
              Cancel
            </Button>
          </>
        }
      >
        <div className="modal__card-item">
          <img src={item.isDefaultPaymentCard ? cardGreenImg : cardGreyImg} alt="cart icon" />
          <p>
            &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;{item.last4}
          </p>
        </div>
        <p className="add-card-text">
          You can add a new card on your{' '}
          <a href="/account/profile">
            <span className="orange-text">Account Page</span>
          </a>
          .
        </p>
      </ModalWithImage>
    </div>
  );
};
export default PaymentItem;
