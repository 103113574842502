import React, {useEffect} from 'react';
import {Header} from '../../components/Header';
import TextModelCreateComponent from '../../components/TextModelComponent/TextModelCreate';
import Footer from '../../components/Footer';

const TextModelCreatePage = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className="client-page client-page--light text-model-page">
      <Header showLogo={true} adminSide={false} backButton={<div>Back</div>} />
      <TextModelCreateComponent />
      <Footer />
    </div>
  );
};

export default TextModelCreatePage;
