import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment';
import PaymentInfo from './Payment';
import SubscriptionInfo from './Subscription';
import UserInfo from './UserInfo';
import Security from './Security';
import Coupon from './Coupon';
import {IState} from '../../../interfaces';
import {getBillingHistoryRequest} from '../../../actions/billing';
import SideBar from '../../SideBar';
import {
  getPaymentMethodsRequest,
  postInitializePaymentCardRequest,
} from '../../../actions/payments';
import LoadingSpinner from '../../UI/LoadingSpinner';
import cardGreyImg from '../../../images/card-icon-grey.svg';
import './style.scss';
import DeleteAccount from './Delete';
import UiAlert from '../../UI/UiAlert';
import {useActiveUser} from '../../../helpers/customHooks';
import {getCouponsHistoryRequest} from '../../../actions/coupon';
import ChargeHistory from './ChargeHistory';
import {getUserPhotoRequest} from '../../../actions/userProfile';
import {useLocation} from 'react-router-dom';

const Profile = (): JSX.Element => {
  const url = '/main';
  const location = useLocation();
  const sidebarSettings = {openOnPage: ['areas']};
  const dispatch = useDispatch();
  const userData = useSelector((state: IState) => state.userProfile);

  const paymentMethods = useSelector((state: IState) => state.paymentMethods.paymentMethods);
  const initializeCardState = useSelector((state: IState) => state.initializeCard);
  const removedCard = initializeCardState?.removedCard;
  const defaultCardResponse = initializeCardState?.defaultCardResponse;
  const autoRenewalActivatedDate = userData.userProfile?.subscriptionRenewal?.renewalDate;
  const updPhoto = useSelector((state: IState) => state.userUpdatePhoto);
  const updateUserName = useSelector((state: IState) => state.userUpdateName);
  const updateUserPhoto = useSelector((state: IState) => state.userUpdatePhoto);
  const couponsHistory = useSelector((state: IState) => state.coupon.couponsHistory);

  const defaultCard = paymentMethods?.items?.find((item) => item.isDefaultPaymentCard)?.last4;
  const [isCardSavingSucceeded, setCardSavingSucceeded] = useState<boolean>(false);
  const [isCardAdded, setCardAdded] = useState<boolean>(false);
  const paymentCards = paymentMethods && paymentMethods.items && paymentMethods.items.length;
  const [isPasswordChangeSuccess, setPasswordChangeSuccess] = useState<boolean>(false);
  const [isPasswordChangeFailed, setPasswordChangeFailed] = useState<boolean>(false);
  const [isUserDetailsChanged, setUserDetailsChanged] = useState<boolean>(false);
  const [isAutoRenewalActivated, setAutoRenewalActivated] = useState<boolean>(false);

  const exDate =
    userData.userProfile?.currentUserSubscription?.expirationUtc === '0001-01-01T00:00:00'
      ? ''
      : moment(userData.userProfile?.currentUserSubscription?.expirationUtc).format('MMM D, YYYY');
  const user = {
    fullName: userData.userProfile.fullName,
    userId: userData.userProfile.userId,
    avatar: userData.userProfile.avatar,
    updPhotoState: updPhoto,
    industry: userData.userProfile.industries,
    company: userData.userProfile.companyName,
    role: userData.userProfile.userRole,
    accountType: userData.userProfile.accountType,
  };
  const subscriptionInfo = {
    plan: userData.userProfile?.currentUserSubscription?.name || '',
    planId: userData.userProfile?.currentUserSubscription?.id || '',
    exDate: exDate,
    credits: userData.userProfile.creditCount,
  };
  const details = {
    email: userData.userProfile.userEmail,
    accountType: userData.userProfile.accountType,
  };
  const paymentData = {
    cards: paymentMethods && paymentMethods.items,
    billingDate: exDate,
    plan: userData.userProfile?.currentUserSubscription?.name,
    renewalPrice: userData.userProfile?.subscriptionRenewal?.renewalPrice,
    autoRenew: userData.userProfile.subscriptionRenewal?.autoRenew,
    renewalDate:
      userData.userProfile &&
      userData.userProfile.subscriptionRenewal &&
      moment(userData.userProfile?.subscriptionRenewal?.renewalDate).format('MMM D, YYYY'),
  };

  const paymentBlock = document.getElementById('payment');
  const deleteBlock = document.getElementById('delete');
  const userStatus = useActiveUser();
  useEffect(() => {
    dispatch(postInitializePaymentCardRequest());
    dispatch(getPaymentMethodsRequest());
    dispatch(getBillingHistoryRequest());
    dispatch(getCouponsHistoryRequest());
    dispatch(getUserPhotoRequest(user.userId));
  }, []);

  useEffect(() => {
    if (isCardSavingSucceeded && !initializeCardState.loading) {
      dispatch(getPaymentMethodsRequest());
      dispatch(postInitializePaymentCardRequest());
      setCardSavingSucceeded(false);
      setCardAdded(true);
    }
  }, [isCardSavingSucceeded, initializeCardState.loading]);

  useEffect(() => {
    dispatch(getPaymentMethodsRequest());
  }, [paymentCards]);

  useEffect(() => {
    if (location?.hash === '#changePass=success') {
      setPasswordChangeSuccess(true);
    } else if (location?.hash === '#changePass=fail') {
      setPasswordChangeFailed(true);
    }
    window.history.replaceState(null, '', ' ');
  }, [location]);

  useEffect(() => {
    if (!updateUserName.loading && !updateUserPhoto.loading) {
      updateUserName.userUpdateName?.id && setUserDetailsChanged(true);
      updateUserPhoto.userUpdatePhoto?.id && setUserDetailsChanged(true);
    }
  }, [updateUserName.loading, updateUserPhoto.loading]);

  if (location && location.hash === '#payment') {
    paymentBlock &&
      window.pageYOffset < 38 &&
      window.scrollTo({
        top: paymentBlock.getBoundingClientRect().top + window.pageYOffset - 90,
        behavior: 'smooth',
      });
  }

  if (location && location.hash === '#delete') {
    deleteBlock &&
      window.pageYOffset < 38 &&
      window.scrollTo({
        top: deleteBlock.getBoundingClientRect().top + window.pageYOffset - 90,
        behavior: 'smooth',
      });
  }

  return (
    <div className="profile">
      <Grid container className="gridContainer">
        <Grid item xs={12} md={11} lg={10}>
          <Grid container direction="row" className="mainContainer">
            <Hidden only={['xs', 'sm']}>
              <Grid item md={3} lg={2}>
                {location && (
                  <SideBar url={url} location={location} sidebarSettings={sidebarSettings} />
                )}
              </Grid>
            </Hidden>
            {userData.userProfile.userId || !userData.loading ? (
              <Grid item xs={12} sm={11} md={9} lg={10} className="component-white-wr">
                <h1 className="profile__title">Account</h1>
                {userStatus.isExpired && !userStatus.isSubscribed && (
                  <UiAlert
                    type={'warning'}
                    title={'Oops! Your subscription plan expired!!'}
                    specificClass={'expired-warning'}
                    details={
                      'You can still use your remaining credits. Or get back your full use of Marquètte by updating your subscription!'
                    }
                  />
                )}

                {isUserDetailsChanged && (
                  <UiAlert type={'success'} title={'Your account details were changed.'} />
                )}
                {isPasswordChangeSuccess && (
                  <UiAlert
                    type={'success'}
                    title={'Your password was changed.'}
                    withButton={true}
                    buttonText={'Got it!'}
                  />
                )}
                {isPasswordChangeFailed && (
                  <UiAlert
                    type={'warning'}
                    title={'Your password has not been changed'}
                    withButton={true}
                    buttonText={'Got it'}
                    specificClass={'password-change__fails'}
                  />
                )}
                {defaultCardResponse && (
                  <UiAlert
                    type={'info'}
                    specificClass={'profile-notification__default-card'}
                    title={
                      <p>
                        The card
                        <span className="profile-notification--card">
                          <img src={cardGreyImg} alt="" />
                          &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;{' '}
                          {defaultCard}
                        </span>
                        is set up as a Default card.
                      </p>
                    }
                    color={'success'}
                  />
                )}
                {removedCard && (
                  <UiAlert type={'success'} title={'Your payment details were updated.'} />
                )}
                {isCardAdded && <UiAlert type={'success'} title={'New card was added.'} />}
                {isAutoRenewalActivated && (
                  <UiAlert
                    type={'success'}
                    title={'Auto-Renewal Activated'}
                    details={`Your Next Subscription fee will be charged on ${moment(
                      autoRenewalActivatedDate,
                    ).format('MMM DD, YYYY')}.`}
                  />
                )}
                <UserInfo user={user} setAlert={setUserDetailsChanged} />
                <Security details={details} />
                <SubscriptionInfo
                  credits={subscriptionInfo}
                  setAutoRenewalActivated={setAutoRenewalActivated}
                />

                <PaymentInfo data={paymentData} setCardSavingSucceeded={setCardSavingSucceeded} />
                <ChargeHistory />
                <Coupon
                  isAutorenewal={paymentData.autoRenew}
                  haveHistory={couponsHistory && Boolean(couponsHistory.items.length)}
                />
                <DeleteAccount />
              </Grid>
            ) : (
              <Grid item xs={12} sm={11} md={9} lg={10} className="component-white-wr">
                <div className="client-page-loader">
                  <LoadingSpinner />
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Profile;
