import React, {ReactNode} from 'react';
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js';
import {STRIPE_PUBLISHABLE_KEY} from '../../../constants';
import {Elements} from '@stripe/react-stripe-js';
import {IInitializeCardResponse} from '../../../interfaces';

interface IProps {
  initializeCardResponse: IInitializeCardResponse;
  children: ReactNode;
}

const isSnap = navigator.userAgent === 'ReactSnap';
const stripePromise = isSnap ? Promise.resolve(null) : loadStripe(STRIPE_PUBLISHABLE_KEY);

const Payment = (props: IProps): JSX.Element => {
  const {children, initializeCardResponse} = props;
  const options: StripeElementsOptions = {
    clientSecret: initializeCardResponse.clientSecret,
    appearance: {
      theme: 'night',
      rules: {
        '.CardField-expiry': {
          border: '1px solid #E0E6EB',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
        },
      },
    },
  };
  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        {children}
      </Elements>
    </>
  );
};

export default Payment;
