import {createArrayEndpoint} from '../helpers/helper';
import {GET, handleBody, handleExceptions} from './request';

export const getUserManagement = (payload: string): Promise<Object | null> => {
  const endpoint: string = createArrayEndpoint('Admin/Users', payload);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
