import React from 'react';
import PublicContentComponent from '../../components/TextModelComponent/SharedContent';
import {Header} from '../../components/Header';
import {Footer} from '../../components/Footer';
import {Grid} from '@material-ui/core';

const PublicPage = (): JSX.Element => {
  return (
    <div className="main-page client-page text-model-page">
      <Header showLogo={true} adminSide={false} />
      <Grid container style={{justifyContent: 'center'}} className="main-page__content">
        <Grid item xs={12} sm={11} md={10} lg={8}>
          <PublicContentComponent />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default PublicPage;
