import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as subscription from '../api/subscription';

// REDUX
import * as subscriptionAction from '../actions/subscription';
import * as subscriptionActionType from '../actions/types/types';
import {Action} from 'redux-actions';

export function* getUserSubscription(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  unknown
> {
  try {
    const payload = yield call(subscription.getUserSubscription, {});
    yield put(subscriptionAction.getUserSubscriptionSuccess(payload));
  } catch (error) {
    yield put(subscriptionAction.getUserSubscriptionFailure(error));
  }
}

// WATCHERS
export function* watchUserSubscription(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(subscriptionActionType.GET_USER_SUBSCRIPTION_REQUEST, getUserSubscription);
}

// SUBSCRIPTIONS FOR REGISTERED USERS
export function* getRegisteredUserSubscription(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  unknown
> {
  try {
    const payload = yield call(subscription.getRegisteredUserSubscription, {});
    yield put(subscriptionAction.getRegisteredUserSubscriptionSuccess(payload));
  } catch (error) {
    yield put(subscriptionAction.getRegisteredUserSubscriptionFailure(error));
  }
}

// WATCHERS
export function* watchRegisteredUserSubscription(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    subscriptionActionType.GET_REGISTERED_USER_SUBSCRIPTION_REQUEST,
    getRegisteredUserSubscription,
  );
}
