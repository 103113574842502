import {handleActions} from 'redux-actions';

// ACTION TYPES
import {
  GET_FAVORITES_REQUEST,
  GET_FAVORITES_SUCCESS,
  GET_FAVORITES_FAILURE,
  PUT_FAVORITES_REQUEST,
  PUT_FAVORITES_SUCCESS,
  PUT_FAVORITES_FAILURE,
  DELETE_FAVORITES_REQUEST,
  DELETE_FAVORITES_SUCCESS,
  DELETE_FAVORITES_FAILURE,
} from '../actions/types/types';
import {
  IFavoriteState,
  IGetFavoriteAction,
  IFavoriteItemAction,
  IDeleteItemAction,
} from '../interfaces';

export const initialState = {
  favoritesResponse: {
    items: [],
  },
  favoriteDeleteResponse: null,
  favoriteAddResponse: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_FAVORITES_REQUEST]: (state: IFavoriteState) => ({
    ...state,
    loading: true,
  }),

  [GET_FAVORITES_SUCCESS]: (state: IFavoriteState, action: IGetFavoriteAction) => ({
    ...state,
    favoritesResponse: action.payload,
    loading: false,
    error: '',
  }),

  [GET_FAVORITES_FAILURE]: (state: IFavoriteState) => ({
    ...state,
    error: '',
    loading: false,
  }),

  [PUT_FAVORITES_REQUEST]: (state: IFavoriteState) => ({
    ...state,
    loading: true,
  }),

  [PUT_FAVORITES_SUCCESS]: (state: IFavoriteState, action: IFavoriteItemAction) => ({
    ...state,
    favoriteAddResponse: action.payload,
    loading: false,
    error: '',
  }),

  [PUT_FAVORITES_FAILURE]: (state: IFavoriteState) => ({
    ...state,
    error: '',
    loading: false,
  }),

  [DELETE_FAVORITES_REQUEST]: (state: IFavoriteState) => ({
    ...state,
    loading: true,
  }),

  [DELETE_FAVORITES_SUCCESS]: (state: IFavoriteState, action: IDeleteItemAction) => ({
    ...state,
    favoriteDeleteResponse: action.payload,
    loading: false,
    error: '',
  }),

  [DELETE_FAVORITES_FAILURE]: (state: IFavoriteState) => ({
    ...state,
    error: '',
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
