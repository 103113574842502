// CONTACT US - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as contactUsActionType from './types/types';

export const postStatusRequest = createAction(
  contactUsActionType.POST_SEND_CONTACT_US_FORM_REQUEST,
);
export const postStatusSuccess = createAction(
  contactUsActionType.POST_SEND_CONTACT_US_FORM_SUCCESS,
);
export const postStatusFailure = createAction(
  contactUsActionType.POST_SEND_CONTACT_US_FORM_FAILURE,
);
