import React from 'react';
import {Divider, Hidden, ListItem} from '@material-ui/core';
import {BLOB_LINK} from '../../../../constants';
import moment from 'moment';
import {ITextModelsItem} from '../../../../interfaces';
import {NavLink} from 'react-router-dom';

interface IProps {
  content: ITextModelsItem;
  index: number;
}

const ContentItem = (props: IProps): JSX.Element => {
  const {content, index} = props;
  const imageLink = `${BLOB_LINK}/assets-for-templates/`;

  return (
    <>
      <ListItem className="dashboard__list-item" key={index}>
        <div className="dashboard__list-section">
          <img
            className="dashboard__list-icon"
            src={`${imageLink}${content.iconTemplateLink}`}
            alt="Words Icon"
          />
          <div className="dashboard__list-info">
            <span className="dashboard__list-alias size">{content.alias}</span>
            <span className="dashboard__list-index">
              {moment(content.createdDate).format('MM-DD-YYYY')}
            </span>
          </div>
        </div>
        <div className="dashboard__list-name">
          <span className="dashboard__list-content">
            <NavLink to={`/text-model-details/${content.id}`} key={content.id}>
              {content.name.length > 0 ? content.name : content.alias}
            </NavLink>
          </span>
          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <span className="dashboard__list-index">
              {moment(content.createdDate).format('MM-DD-YYYY')}
            </span>
          </Hidden>
        </div>
      </ListItem>
      <Divider
        style={{
          background: 'undefined',
          height: '0px',
          borderTop: 'thin solid #E9E9E9',
        }}
      />
    </>
  );
};

export default ContentItem;
