import Quill, {DeltaStatic} from 'quill';
import katex from 'katex';
import markdownToDelta from 'markdown-to-quill-delta';
import {MDtoQuill} from './MDtoQuill';

interface Toolbar {
  addHandler: (name: string, callback: () => void) => void;
}

function traverseDom(element: HTMLElement) {
  function getTextLoop(element: HTMLElement) {
    const texts: string[] = [];
    Array.from(element.childNodes).forEach((node) => {
      if (node.nodeType === 3 && node.textContent) {
        texts.push(node.textContent.trim());
      } else {
        texts.push(...getTextLoop(node as HTMLElement));
      }
    });
    return texts;
  }
  return getTextLoop(element).join(' ');
}

export const updateContentWords = (
  editor: Quill,
  data: DeltaStatic,
  onUpdateContentWords: (data: DeltaStatic, words: number) => void,
): void => {
  const node = editor.root.cloneNode(true) as HTMLElement;

  // exclude all formulas
  const formulaElements = node.querySelectorAll('span.ql-formula');
  const formulaCount = formulaElements.length;
  for (let i = 0; i < formulaCount; i++) {
    formulaElements[i].remove();
  }

  // traverse the DOM
  const text = traverseDom(node);

  // calculate words
  const words = text.split(' ').filter((text) => text.trim() !== '').length;

  // update
  onUpdateContentWords(data, words + formulaCount);
};

export function getDelta(value: string): DeltaStatic {
  let data: DeltaStatic;
  try {
    data = JSON.parse(value) as DeltaStatic;
  } catch (error) {
    try {
      // modern parser
      data = {ops: markdownToDelta(value)} as DeltaStatic;
    } catch (error) {
      // legacy parser
      data = MDtoQuill(value);
    }
  }

  fixSize(data);
  parseFormula(data);
  return data;
}

export function fixSize(data: DeltaStatic): void {
  data?.ops?.forEach((op) => {
    if (op.attributes && op.attributes.header) {
      delete op.attributes.header;
    }
  });
}

export function parseFormula(data: DeltaStatic): void {
  try {
    if (!data.ops) return;

    for (let i = 0; i < data.ops.length; i++) {
      const op1 = data.ops[i];
      const op2 = data.ops[i + 1];
      if (
        op1.insert &&
        typeof op1.insert === 'string' &&
        op2.insert === '\n' &&
        op2.attributes &&
        op2.attributes['code-block']
      ) {
        katex.renderToString(op1.insert, {
          fleqn: true,
          throwOnError: true,
        });

        data.ops.splice(
          i,
          0,
          {
            insert: {
              formula: op1.insert,
            },
          },
          {
            insert: '\n',
          },
        );
        i = i + 2;
      }
    }
  } catch (error) {
    return;
  }
}

export const prepareContainerForQuill = (container: HTMLDivElement): HTMLDivElement => {
  while (container.children.length > 0) {
    container.removeChild(container.children[0]);
  }

  const subContainer = document.createElement('div');
  container.appendChild(subContainer);

  return subContainer;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const Inline = Quill.import('blots/inline');

class AIBlot extends Inline {
  static blotName: string;
  static tagName: string;
}

AIBlot.blotName = 'ai';
AIBlot.tagName = 'span';

Quill.register({
  'formats/ai': AIBlot,
});

export class AIModule {
  quill: Quill;
  toolbar: Toolbar;
  handlerOnShowDropdown?: () => void;

  constructor(quill: Quill) {
    this.quill = quill;
    this.toolbar = quill.getModule('toolbar') as Toolbar;
    this.toolbar.addHandler('ai', this.aiHandler);
  }

  onShowDropdown = (callback: () => void): void => {
    this.handlerOnShowDropdown = callback;
  };

  aiHandler = (): void => {
    const container = document.querySelector('.text-model-full-wr');
    const dropdown = document.getElementById('aiDropdown');
    const aiButton = container && container.querySelector('.ql-ai');

    if (container && aiButton && dropdown) {
      const containerRect = container.getBoundingClientRect();
      const buttonRect = aiButton.getBoundingClientRect();
      //position dropdown relative to the AiButton
      const buttonPosition = {
        top: buttonRect.top - containerRect.top,
        right: containerRect.right - buttonRect.right,
      };
      dropdown.style.top = `${buttonPosition.top + 38}px`;
      dropdown.style.right = `${buttonPosition.right - 25}px`;
      dropdown.style.right =
        window.innerWidth >= 820
          ? `${buttonPosition.right - 20}px`
          : `${buttonPosition.right - 95}px`;
    }

    this.handlerOnShowDropdown && this.handlerOnShowDropdown();
  };
}

Quill.register('modules/aiModule', AIModule);
