import React, {useState} from 'react';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {IInitNotification} from '../../../../../../interfaces';
import '../../style.scss';
import {LightTooltip} from '../../../../../UI/StatusLabel';

interface INotificationCheckbosexProps {
  checkboxNotification?: IInitNotification[];
  handleChange: (change: string) => void;
  dropdown?: boolean;
  className?: string;
}

const NotificationCheckboxes = (props: INotificationCheckbosexProps): JSX.Element => {
  const {checkboxNotification, handleChange, dropdown, className} = props;
  const [open, setOpen] = useState<{[key: string]: boolean}>({
    1: true,
    2: true,
  });

  const handleOpen = (i: number) => {
    setOpen((prev) => ({...prev, [i]: !prev[i]}));
  };

  return (
    <>
      {dropdown && checkboxNotification ? (
        checkboxNotification.map((item, idx) => (
          <div key={item.title + idx} className={classNames(className)}>
            <div className="SettingContent__dropdown" onClick={() => handleOpen(idx + 1)}>
              <p className={`SettingContent__dropdown--title ${open[idx + 1] ? 'active' : ''}`}>
                {item.title}
              </p>
              {open[idx + 1] ? <ExpandLess /> : <ExpandMore />}
            </div>
            <Collapse in={open[idx + 1]} timeout="auto" unmountOnExit>
              <div className="SettingContent__notification-wr SettingSideBar__common-bottom-line">
                <FormControl component="fieldset">
                  <FormGroup>
                    {item.checkboxes.map((item) => (
                      <LightTooltip
                        key={item.text + 'key'}
                        title={
                          item.text === 'Your answer has been processed.'
                            ? 'New version of answer has been processed'
                            : ''
                        }
                      >
                        <FormControlLabel
                          key={item.text}
                          disabled
                          className="SettingSideBar__common-checkbox disabled-checkbox"
                          control={
                            <Checkbox
                              disabled
                              checked={item.checked}
                              onChange={() => handleChange(item.text)}
                            />
                          }
                          label={item.text}
                        />
                      </LightTooltip>
                    ))}
                  </FormGroup>
                </FormControl>
              </div>
            </Collapse>
          </div>
        ))
      ) : (
        <div
          className={classNames(
            'SettingContent__notification-wr SettingSideBar__common-bottom-line',
            className,
          )}
        >
          <FormControl component="fieldset">
            <FormGroup>
              {checkboxNotification &&
                checkboxNotification.map((el) =>
                  el.checkboxes.map((item, idx) => (
                    <FormControlLabel
                      disabled
                      key={'element' + idx}
                      className="SettingSideBar__common-checkbox disabled-checkbox"
                      control={
                        <Checkbox
                          disabled
                          checked={item.checked}
                          onChange={() => handleChange(item.text)}
                        />
                      }
                      label={item.text}
                    />
                  )),
                )}
            </FormGroup>
          </FormControl>
        </div>
      )}
    </>
  );
};

export default NotificationCheckboxes;
