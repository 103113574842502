import {combineReducers} from 'redux';
import areas from './areas';
import industries from './industries';
import favorites from './favorites';
import authMsal from './auth-msal';
import userProfile from './userProfile';
import userSettings from './userSettings';
import userUpdateName from './userUpdateName';
import userUpdatePhoto from './userUpdatePhoto';
import initNewUser from './initNewUser';
import initializeCard from './initializeCard';
import paymentMethods from './payments';
import subscription, {subscriptionRegistered} from './subscription';
import boughtSubscription from './billing';
import credits from './credits';
import billingHistory from './billingHistory';
import creditItems from './creditItems';
import addAdminCredits from './adminCredits';
import adminSubscription from './adminSubscription';
import userManagement from './userManagement';
import userManagementProfile from './userManagementProfile';
import companies from './companies';
import contactUs from './contactUs';
import coupon from './coupon';
import changePassword from './changePassword';
import signalRStepTemplate from './signalRStepTemplate';
import signalRCellEdit from './signalRCellEdit';
import textModel from './textModel';
import alerts from './alerts';
import refundSubscription from './refundSubscription';
import subscriptionRenewal from './userUpdateSubscriptionRenewal';
import commandsManagement from './commandsManagement';

export default combineReducers({
  // admin,
  alerts,
  areas,
  industries,
  favorites,
  authMsal,
  userProfile,
  userSettings,
  userUpdateName,
  userUpdatePhoto,
  initNewUser,
  initializeCard,
  credits,
  paymentMethods,
  subscription,
  subscriptionRegistered,
  boughtSubscription,
  billingHistory,
  creditItems,
  addAdminCredits,
  adminSubscription,
  userManagement,
  companies,
  userManagementProfile,
  contactUs,
  coupon,
  changePassword,
  signalRStepTemplate,
  signalRCellEdit,
  textModel,
  refundSubscription,
  subscriptionRenewal,
  commandsManagement,
});
