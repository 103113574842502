// USERPROFILE - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as userProfileActionTypes from './types/types';

export const getUserProfileRequest = createAction(userProfileActionTypes.GET_USERPROFILE_REQUEST);
export const getUserProfileSuccess = createAction(userProfileActionTypes.GET_USERPROFILE_SUCCESS);
export const getUserProfileFailure = createAction(userProfileActionTypes.GET_USERPROFILE_FAILURE);

// UPDATE NAME
export const postUserProfileNameRequest = createAction(
  userProfileActionTypes.POST_UPDATEPROFILE_NAME_REQUEST,
);
export const postUserProfileNameSuccess = createAction(
  userProfileActionTypes.POST_UPDATEPROFILE_NAME_SUCCESS,
);
export const postUserProfileNameFailure = createAction(
  userProfileActionTypes.POST_UPDATEPROFILE_NAME_FAILURE,
);

// GET PHOTO
export const getUserPhotoRequest = createAction(userProfileActionTypes.GET_USER_PHOTO_REQUEST);
export const getUserPhotoSuccess = createAction(userProfileActionTypes.GET_USER_PHOTO_SUCCESS);
export const getUserPhotoFailure = createAction(userProfileActionTypes.GET_USER_PHOTO_FAILURE);

// UPDATE PHOTO
export const postUserProfilePhotoRequest = createAction(
  userProfileActionTypes.POST_UPDATEPROFILE_PHOTO_REQUEST,
);
export const postUserProfilePhotoSuccess = createAction(
  userProfileActionTypes.POST_UPDATEPROFILE_PHOTO_SUCCESS,
);
export const postUserProfilePhotoFailure = createAction(
  userProfileActionTypes.POST_UPDATEPROFILE_PHOTO_FAILURE,
);

//INIT USER NEW

export const postInitUserRequest = createAction(userProfileActionTypes.POST_INIT_USER_REQUEST);
export const postInitUserSuccess = createAction(userProfileActionTypes.POST_INIT_USER_SUCCESS);
export const postInitUserFailure = createAction(userProfileActionTypes.POST_INIT_USER_FAILURE);

//DELETE USER

export const deleteUserAccountRequest = createAction(
  userProfileActionTypes.DELETE_USER_ACCOUNT_REQUEST,
);
export const deleteUserAccountSuccess = createAction(
  userProfileActionTypes.DELETE_USER_ACCOUNT_SUCCESS,
);
export const deleteUserAccountFailure = createAction(
  userProfileActionTypes.DELETE_USER_ACCOUNT_FAILURE,
);

// UPDATE USER LAST LOGIN DATE

export const postUserLoginLastDateRequest = createAction(
  userProfileActionTypes.POST_USER_LASTLOGINDATE_REQUEST,
);
export const postUserLoginLastDateSuccess = createAction(
  userProfileActionTypes.POST_USER_LASTLOGINDATE_SUCCESS,
);
export const postUserLoginLastDateFailure = createAction(
  userProfileActionTypes.POST_USER_LASTLOGINDATE_FAILURE,
);

// UPDATE USER SUBSCRIPTION RENEWAL

export const postUserSubscriptionRenewalRequest = createAction(
  userProfileActionTypes.POST_USER_SUBSCRIPTION_RENEWAL_REQUEST,
);
export const postUserSubscriptionRenewalSuccess = createAction(
  userProfileActionTypes.POST_USER_SUBSCRIPTION_RENEWAL_SUCCESS,
);
export const postUserSubscriptionRenewalFailure = createAction(
  userProfileActionTypes.POST_USER_SUBSCRIPTION_RENEWAL_FAILURE,
);

//DELETE PHOTO

export const deleteUserPhotoRequest = createAction(
  userProfileActionTypes.DELETE_USER_PHOTO_REQUEST,
);
export const deleteUserPhotoSuccess = createAction(
  userProfileActionTypes.DELETE_USER_PHOTO_SUCCESS,
);
export const deleteUserPhotoFailure = createAction(
  userProfileActionTypes.DELETE_USER_PHOTO_FAILURE,
);

export const updateUserCreditsRequest = createAction(
  userProfileActionTypes.UPDATE_USER_CREDITS_REQUEST,
);
