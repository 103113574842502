import {useEffect} from 'react';
import {Grid} from '@material-ui/core';
import BackButton from '../../UI/BackButton';
import CouponCodesImg from '../../../images/help-center-coupon-codes.svg';
import '../infoPageStyle.scss';

const CouponCodesInfo = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container className="info-page">
        <Grid item xs={11} md={11} lg={10}>
          <div className="info-page__wr">
            <BackButton prevPage />

            <Grid container>
              <Grid item xs={12} md={9} lg={8}>
                <h1 className="info-page__title">How to Use Coupon Codes</h1>

                <div className="info-page__content">
                  <img src={CouponCodesImg} alt="Coupon code illustration" />
                  <div className="content-item">
                    <h2>What are promo codes?</h2>
                    <p>
                      These are unique codes. If you enter them at the time of ordering on the site
                      in a special field, you can get a discount on goods or make a premium on the
                      goodness.
                    </p>
                  </div>
                  <div className="content-item">
                    <h2>How do I use the promo code?</h2>
                    <ol className="content-item__list numbered">
                      <li>Check the product on the site and go to the checkout.</li>
                      <li>Copy one code and enter it in the space provided.</li>
                      <li>Click "Add" button.</li>
                      <li>
                        If a promo code has already been applied to you, or such a code does not
                        exist - "Please enter a valid coupon code" will be displayed.
                      </li>
                      <li>
                        If you entered the promo code correctly, you will see the message "Success!"
                        and the new price of the service.
                      </li>
                    </ol>
                  </div>
                  <div className="content-item">
                    <h2>Where can I find the promo code?</h2>
                    <ul className="content-item__list">
                      <li>On the product promotion sites</li>
                      <li>On the Internet during promotions</li>
                      <li>Can be given by leaflets to active users</li>
                    </ul>
                  </div>
                  <div className="content-item">
                    <h2>What is the term of action of the promo code?</h2>
                    <p>
                      The term of action of the promo code is fixed for each individual promo code
                      and the term of its action will be specified in the conditions of its use.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CouponCodesInfo;
