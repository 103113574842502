import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as industries from '../api/industries';

// REDUX
import * as industriesAction from '../actions/industries';
import * as industriesActionType from '../actions/types/types';
import {Action} from 'redux-actions';

export function* getIndustries(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  unknown
> {
  try {
    const payload = yield call(industries.getIndustries, {});
    yield put(industriesAction.getStatusSuccess(payload));
  } catch (error) {
    yield put(industriesAction.getStatusFailure(error));
  }
}

// WATCHERS
export function* watchIndustries(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(industriesActionType.GET_INDUSTRIES_REQUEST, getIndustries);
}
