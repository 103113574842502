import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  GET_TEXT_MODEL_TEMPLATE_REQUEST,
  GET_TEXT_MODEL_TEMPLATE_SUCCESS,
  GET_TEXT_MODEL_TEMPLATE_FAILURE,
  POST_TEXT_MODEL_CREATE_REQUEST,
  POST_TEXT_MODEL_CREATE_SUCCESS,
  POST_TEXT_MODEL_CREATE_FAILURE,
  GET_TEXT_MODEL_DETAILS_REQUEST,
  GET_TEXT_MODEL_DETAILS_SUCCESS,
  GET_TEXT_MODEL_DETAILS_FAILURE,
  GET_MODEL_TEMPLATE_DETAILS_REQUEST,
  GET_MODEL_TEMPLATE_DETAILS_SUCCESS,
  GET_MODEL_TEMPLATE_DETAILS_FAILURE,
  SET_TEXT_MODEL_DETAILS_CLEAR,
  POST_MODEL_NEXT_STEP_REQUEST,
  POST_MODEL_NEXT_STEP_SUCCESS,
  POST_MODEL_NEXT_STEP_FAILURE,
  CLEAR_CREATED_MODEL_REQUEST,
  POST_CANCEL_MODEL_REQUEST,
  POST_CANCEL_MODEL_SUCCESS,
  GET_TEXT_MODELS_REQUEST,
  GET_TEXT_MODELS_SUCCESS,
  GET_TEXT_MODELS_FAILURE,
  CLEAR_MODEL_DETAILS_REQUEST,
  PUT_LIKE_MODEL_REQUEST,
  POST_CANCEL_MODEL_FAILURE,
  PUT_LIKE_MODEL_FAILURE,
  PUT_LIKE_MODEL_SUCCESS,
  PATCH_MODEL_FEEDBACK_REQUEST,
  PATCH_MODEL_FEEDBACK_SUCCESS,
  PATCH_MODEL_FEEDBACK_FAILURE,
  GET_PUBLIC_TEXT_MODEL_FAILURE,
  GET_PUBLIC_TEXT_MODEL_SUCCESS,
  GET_PUBLIC_TEXT_MODEL_REQUEST,
  POST_SHARE_TEXT_MODEL_REQUEST,
  POST_SHARE_TEXT_MODEL_SUCCESS,
  POST_SHARE_TEXT_MODEL_FAILURE,
  POST_UNSHARE_TEXT_MODEL_FAILURE,
  POST_UNSHARE_TEXT_MODEL_REQUEST,
  POST_UNSHARE_TEXT_MODEL_SUCCESS,
  POST_UPLOAD_FILE_REQUEST,
  POST_UPLOAD_FILE_SUCCESS,
  POST_UPLOAD_FILE_FAILURE,
  SET_ARCHIVE_STATUS_MODEL_REQUEST,
  SET_ARCHIVE_STATUS_MODEL_SUCCESS,
  SET_ARCHIVE_STATUS_MODEL_FAILURE,
  REMOVE_TEXT_MODEL_MANUALLY,
  GET_TEXT_GENERATION_RESULT_FAILURE,
  GET_TEXT_GENERATION_RESULT_REQUEST,
  GET_TEXT_GENERATION_RESULT_SUCCESS,
  GET_TEXT_MODELS_FOR_LANDING_SUCCESS,
  GET_TEXT_MODELS_FOR_LANDING_FAILURE,
  UPDATE_TEXT_MODEL_DETAILS_HISTORY,
  POST_CREATE_MODEL_ENTRY_REQUEST,
  POST_CREATE_MODEL_ENTRY_SUCCESS,
  POST_CREATE_MODEL_ENTRY_FAILURE,
  GET_TEXT_MODEL_ENTRY_REQUEST,
  GET_TEXT_MODEL_ENTRY_SUCCESS,
  GET_TEXT_MODEL_ENTRY_FAILURE,
  UPDATE_MODEL_ENTRY_REQUEST,
  UPDATE_MODEL_ENTRY_SUCCESS,
  UPDATE_MODEL_ENTRY_FAILURE,
  PUBLISH_MODEL_ENTRY_REQUEST,
  PUBLISH_MODEL_ENTRY_SUCCESS,
  PUBLISH_MODEL_ENTRY_FAILURE,
  GET_TEXT_MODEL_ENTRIES_FAILURE,
  GET_TEXT_MODEL_ENTRIES_REQUEST,
  GET_TEXT_MODEL_ENTRIES_SUCCESS,
  GET_TEXT_MODEL_CATEGORIES_FAILURE,
  GET_TEXT_MODEL_CATEGORIES_REQUEST,
  GET_TEXT_MODEL_CATEGORIES_SUCCESS,
  GENERATE_TEXT_MODEL_REQUEST,
  GENERATE_TEXT_MODEL_SUCCESS,
  GENERATE_TEXT_MODEL_FAILURE,
  SET_MODEL_ENTRY_CLEAR,
  DELETE_TEXT_MODEL_ENTRY_FAILURE,
  DELETE_TEXT_MODEL_ENTRY_REQUEST,
  DELETE_TEXT_MODEL_ENTRY_SUCCESS,
  GET_TEXT_MODEL_ENTRIES_IMAGES_REQUEST,
  GET_TEXT_MODEL_ENTRIES_IMAGES_SUCCESS,
  GET_TEXT_MODEL_ENTRIES_IMAGES_FAILURE,
  PATCH_TEXT_MODEL_AUTOSAVE_FAILURE,
  PATCH_TEXT_MODEL_AUTOSAVE_REQUEST,
  PATCH_TEXT_MODEL_AUTOSAVE_SUCCESS,
  SET_NAVIGATE_TO_ENTRY,
  SET_CLEAR_NAVIGATE_TO_ENTRY,
  GET_TEXT_MODEL_STATISTIC_FAILURE,
  GET_TEXT_MODEL_STATISTIC_REQUEST,
  GET_TEXT_MODEL_STATISTIC_SUCCESS,
  SET_CREATE_MODEL_ENTRY_NAVIGATE,
} from '../actions/types/types';
import {
  ICancelModelAction,
  ICreateTextModelResponseAction,
  IEntryCreateAction,
  IGetModelTemplateDetailsAction,
  IGetTextGenerationResultAction,
  IGetTextModelCategoriesAction,
  IGetTextModelDetailsAction,
  IGetTextModelEntryAction,
  IGetTextModelEntriesAction,
  IGetTextModelsAction,
  IGetTextModelTemplateAction,
  ILikeModelAction,
  IModelFeedbackAction,
  IModelNextStepAction,
  IPublishEntryAction,
  ISetArchiveModelRequestAction,
  ISetFavoriteModelRequestAction,
  IShareTextModelAction,
  ITextModelDetails,
  ITextModelEntryActionError,
  ITextModelErrorAction,
  ITextModelState,
  IUpdateEntryAction,
  IUploadFileAction,
  IGenerateTextModelAction,
  IDeleteEntryAction,
  IEntriesImagesAction,
  ITextModelAutosaveAction,
  ITextModelStatisticAction,
} from '../interfaces';

export const initialState = {
  lastFilterParams: null,
  textModels: null,
  textModelsForLanding: [],
  textModelTemplates: null,
  textModelEntries: null,
  entriesImages: null,
  textModelCategories: null,
  textModelCreate: null,
  textModelDetails: null,
  textModelDetailsHistory: null,
  textPublicModel: null,
  modelTemplateDetails: null,
  nextPageResponse: null,
  cancelResponse: null,
  shareResponse: null,
  unShareResponse: null,
  uploadFileResponse: null,
  likeModelResponse: null,
  modelFeedbackResponse: null,
  setArchiveModelResponse: null,
  textModelAutosaveRespone: null,
  textGenerationResult: null,
  entryCreateResult: null,
  textModelEntry: null,
  entryUpdateResult: null,
  publishEntryResult: null,
  generateTextModelResult: null,
  textModelStatistic: null,
  loading: false,
  copyLink: '',
  actionLoading: false,
  navigateToEntry: false,
  isEntryDeleted: null,
  error: '',
};

const handlerMap: any = {
  [GET_TEXT_MODEL_TEMPLATE_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [GET_TEXT_MODEL_TEMPLATE_SUCCESS]: (
    state: ITextModelState,
    action: IGetTextModelTemplateAction,
  ) => ({
    ...state,
    textModelTemplates: action.payload,
    error: '',
    loading: false,
  }),
  [GET_TEXT_MODEL_TEMPLATE_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [POST_TEXT_MODEL_CREATE_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [POST_TEXT_MODEL_CREATE_SUCCESS]: (
    state: ITextModelState,
    action: ICreateTextModelResponseAction,
  ) => ({
    ...state,
    textModelCreate: action.payload,
    error: '',
    loading: false,
  }),
  [POST_TEXT_MODEL_CREATE_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [GET_TEXT_MODEL_DETAILS_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [GET_TEXT_MODEL_DETAILS_SUCCESS]: (
    state: ITextModelState,
    action: IGetTextModelDetailsAction,
  ) => ({
    ...state,
    textModelDetails: action.payload,
    textModelDetailsHistory: [...action.payload.history] || [],
    uploadFileResponse: null,
    error: '',
    loading: false,
  }),
  [SET_TEXT_MODEL_DETAILS_CLEAR]: (state: ITextModelState) => ({
    ...state,
    textModelCreate: null,
    textModelDetails: null,
    textModelDetailsHistory: null,
    uploadFileResponse: null,
    generateTextModelResult: null,
    singleTextModelEntry: null,
    error: '',
    loading: false,
  }),
  [GET_TEXT_MODEL_DETAILS_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [GET_MODEL_TEMPLATE_DETAILS_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
    modelTemplateDetails: null,
  }),
  [GET_MODEL_TEMPLATE_DETAILS_SUCCESS]: (
    state: ITextModelState,
    action: IGetModelTemplateDetailsAction,
  ) => ({
    ...state,
    modelTemplateDetails: action.payload,
    error: '',
    loading: false,
  }),
  [GET_MODEL_TEMPLATE_DETAILS_FAILURE]: (
    state: ITextModelState,
    action: ITextModelErrorAction,
  ) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [POST_MODEL_NEXT_STEP_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [POST_MODEL_NEXT_STEP_SUCCESS]: (state: ITextModelState, action: IModelNextStepAction) => ({
    ...state,
    nextPageResponse: action.payload,
    error: '',
    loading: false,
  }),
  [POST_MODEL_NEXT_STEP_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [CLEAR_CREATED_MODEL_REQUEST]: (state: ITextModelState) => ({
    ...state,
    textModelCreate: null,
    error: '',
    loading: false,
  }),

  [POST_CANCEL_MODEL_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [POST_CANCEL_MODEL_SUCCESS]: (state: ITextModelState, action: ICancelModelAction) => ({
    ...state,
    cancelResponse: action.payload,
    error: '',
    loading: false,
  }),
  [POST_CANCEL_MODEL_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [CLEAR_MODEL_DETAILS_REQUEST]: (state: ITextModelState) => ({
    ...state,
    modelTemplateDetails: null,
    textModelDetails: null,
    textModelDetailsHistory: null,
    error: '',
    loading: false,
  }),

  [PUT_LIKE_MODEL_REQUEST]: (state: ITextModelState, action: ISetFavoriteModelRequestAction) => ({
    ...state,
    loading: true,
  }),
  [PUT_LIKE_MODEL_SUCCESS]: (state: ITextModelState, action: ILikeModelAction) => ({
    ...state,
    likeModelResponse: action.payload,
    error: '',
    loading: false,
  }),
  [PUT_LIKE_MODEL_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [PATCH_MODEL_FEEDBACK_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [PATCH_MODEL_FEEDBACK_SUCCESS]: (state: ITextModelState, action: IModelFeedbackAction) => ({
    ...state,
    modelFeedbackResponse: action.payload,
    error: '',
    loading: false,
  }),
  [PATCH_MODEL_FEEDBACK_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [GET_TEXT_MODELS_REQUEST]: (state: ITextModelState, action: IGetTextModelsAction) => ({
    ...state,
    lastFilterParams: (({inBackground, ...payload}) => payload)(action.payload),
    ...(action.payload.inBackground ? {} : {loading: true}),
  }),
  [GET_TEXT_MODELS_SUCCESS]: (state: ITextModelState, action: IGetTextModelsAction) => ({
    ...state,
    textModels: action.payload,
    error: '',
    loading: false,
  }),
  [GET_TEXT_MODELS_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [GET_PUBLIC_TEXT_MODEL_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [GET_PUBLIC_TEXT_MODEL_SUCCESS]: (
    state: ITextModelState,
    action: IGetModelTemplateDetailsAction,
  ) => ({
    ...state,
    textPublicModel: action.payload,
    error: '',
    loading: false,
  }),
  [GET_PUBLIC_TEXT_MODEL_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [POST_SHARE_TEXT_MODEL_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
    shareResponse: null,
    unShareResponse: null,
  }),
  [POST_SHARE_TEXT_MODEL_SUCCESS]: (state: ITextModelState, action: IShareTextModelAction) => ({
    ...state,
    shareResponse: action.payload,
    error: '',
    loading: false,
  }),
  [POST_SHARE_TEXT_MODEL_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [POST_UNSHARE_TEXT_MODEL_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
    unShareResponse: null,
    shareResponse: null,
  }),
  [POST_UNSHARE_TEXT_MODEL_SUCCESS]: (state: ITextModelState, action: IShareTextModelAction) => ({
    ...state,
    unShareResponse: action.payload,
    error: '',
    loading: false,
  }),
  [POST_UNSHARE_TEXT_MODEL_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [POST_UPLOAD_FILE_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [POST_UPLOAD_FILE_SUCCESS]: (state: ITextModelState, action: IUploadFileAction) => ({
    ...state,
    uploadFileResponse: action.payload,
    error: '',
    loading: false,
  }),
  [POST_UPLOAD_FILE_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [SET_ARCHIVE_STATUS_MODEL_REQUEST]: (
    state: ITextModelState,
    action: ISetArchiveModelRequestAction,
  ) => ({
    ...state,
    ...(action.payload.inBackground ? {} : {loading: true}),
  }),
  [SET_ARCHIVE_STATUS_MODEL_SUCCESS]: (state: ITextModelState, action: ILikeModelAction) => ({
    ...state,
    setArchiveModelResponse: action.payload,
    error: '',
    loading: false,
  }),
  [SET_ARCHIVE_STATUS_MODEL_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [REMOVE_TEXT_MODEL_MANUALLY]: (state: ITextModelState, action: {payload: {id: string}}) => ({
    ...state,
    textModels: {
      ...state.textModels,
      items: state.textModels?.items.filter((item) => item.id !== action.payload.id),
    },
  }),
  [GET_TEXT_GENERATION_RESULT_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [GET_TEXT_GENERATION_RESULT_SUCCESS]: (
    state: ITextModelState,
    action: IGetTextGenerationResultAction,
  ) => ({
    ...state,
    textGenerationResult: action.payload,
    error: '',
    loading: false,
  }),
  [GET_TEXT_GENERATION_RESULT_FAILURE]: (
    state: ITextModelState,
    action: ITextModelErrorAction,
  ) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
  [GET_TEXT_MODELS_FOR_LANDING_SUCCESS]: (
    state: ITextModelState,
    action: {payload: ITextModelDetails[]},
  ) => ({
    ...state,
    textModelsForLanding: [...action.payload],
  }),
  [GET_TEXT_MODELS_FOR_LANDING_FAILURE]: (
    state: ITextModelState,
    action: ITextModelErrorAction,
  ) => ({
    ...state,
    textModelsForLanding: [],
    error: action.payload,
  }),
  [UPDATE_TEXT_MODEL_DETAILS_HISTORY]: (
    state: ITextModelState,
    action: IGetTextGenerationResultAction,
  ) => ({
    ...state,
    textModelDetailsHistory: action.payload.items,
    error: '',
    loading: false,
  }),
  [GET_TEXT_MODEL_ENTRIES_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [GET_TEXT_MODEL_ENTRIES_SUCCESS]: (
    state: ITextModelState,
    action: IGetTextModelEntriesAction,
  ) => ({
    ...state,
    textModelEntries: action.payload,
    error: '',
    loading: false,
  }),
  [GET_TEXT_MODEL_ENTRIES_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
  [GET_TEXT_MODEL_CATEGORIES_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [GET_TEXT_MODEL_CATEGORIES_SUCCESS]: (
    state: ITextModelState,
    action: IGetTextModelCategoriesAction,
  ) => ({
    ...state,
    textModelCategories: action.payload,
    error: '',
    loading: false,
  }),
  [GET_TEXT_MODEL_CATEGORIES_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [POST_CREATE_MODEL_ENTRY_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: false,
  }),
  [POST_CREATE_MODEL_ENTRY_SUCCESS]: (state: ITextModelState, action: IEntryCreateAction) => ({
    ...state,
    entryCreateResult: action.payload,
    error: '',
    loading: false,
  }),
  [POST_CREATE_MODEL_ENTRY_FAILURE]: (
    state: ITextModelState,
    action: ITextModelEntryActionError,
  ) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
  [SET_CREATE_MODEL_ENTRY_NAVIGATE]: (state: ITextModelState) => ({
    ...state,
    entryCreateResult: null,
  }),

  [GET_TEXT_MODEL_ENTRY_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [GET_TEXT_MODEL_ENTRY_SUCCESS]: (state: ITextModelState, action: IGetTextModelEntryAction) => ({
    ...state,
    singleTextModelEntry: action.payload,
    error: '',
    loading: false,
  }),
  [GET_TEXT_MODEL_ENTRY_FAILURE]: (state: ITextModelState, action: ITextModelEntryActionError) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [UPDATE_MODEL_ENTRY_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [UPDATE_MODEL_ENTRY_SUCCESS]: (state: ITextModelState, action: IUpdateEntryAction) => ({
    ...state,
    entryUpdateResult: action.payload,
    error: '',
    loading: false,
  }),
  [UPDATE_MODEL_ENTRY_FAILURE]: (state: ITextModelState, action: ITextModelEntryActionError) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [PUBLISH_MODEL_ENTRY_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [PUBLISH_MODEL_ENTRY_SUCCESS]: (state: ITextModelState, action: IPublishEntryAction) => ({
    ...state,
    publishEntryResult: action.payload,
    singleTextModelEntry: null,
    error: '',
    loading: false,
  }),
  [PUBLISH_MODEL_ENTRY_FAILURE]: (state: ITextModelState, action: ITextModelEntryActionError) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
  [SET_MODEL_ENTRY_CLEAR]: (state: ITextModelState) => ({
    ...state,
    singleTextModelEntry: null,
    error: '',
    loading: false,
  }),
  [SET_NAVIGATE_TO_ENTRY]: (state: ITextModelState) => ({
    ...state,
    error: '',
    loading: false,
    navigateToEntry: true,
  }),
  [SET_CLEAR_NAVIGATE_TO_ENTRY]: (state: ITextModelState) => ({
    ...state,
    error: '',
    loading: false,
    navigateToEntry: false,
  }),
  [DELETE_TEXT_MODEL_ENTRY_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [DELETE_TEXT_MODEL_ENTRY_SUCCESS]: (state: ITextModelState, action: IDeleteEntryAction) => ({
    ...state,
    isEntryDeleted: action.payload,
    error: '',
    loading: false,
  }),
  [DELETE_TEXT_MODEL_ENTRY_FAILURE]: (
    state: ITextModelState,
    action: ITextModelEntryActionError,
  ) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
  [GET_TEXT_MODEL_ENTRIES_IMAGES_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [GET_TEXT_MODEL_ENTRIES_IMAGES_SUCCESS]: (
    state: ITextModelState,
    action: IEntriesImagesAction,
  ) => ({
    ...state,
    entriesImages: action.payload,
    error: '',
    loading: false,
  }),
  [GET_TEXT_MODEL_ENTRIES_IMAGES_FAILURE]: (
    state: ITextModelState,
    action: ITextModelErrorAction,
  ) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [GENERATE_TEXT_MODEL_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [GENERATE_TEXT_MODEL_SUCCESS]: (state: ITextModelState, action: IGenerateTextModelAction) => ({
    ...state,
    generateTextModelResult: action.payload,
    error: '',
    loading: false,
  }),
  [GENERATE_TEXT_MODEL_FAILURE]: (state: ITextModelState, action: ITextModelEntryActionError) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [PATCH_TEXT_MODEL_AUTOSAVE_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [PATCH_TEXT_MODEL_AUTOSAVE_SUCCESS]: (
    state: ITextModelState,
    action: ITextModelAutosaveAction,
  ) => ({
    ...state,
    textModelAutosaveRespone: action.payload,
    error: '',
    loading: false,
  }),
  [PATCH_TEXT_MODEL_AUTOSAVE_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [GET_TEXT_MODEL_STATISTIC_REQUEST]: (state: ITextModelState) => ({
    ...state,
    loading: true,
  }),
  [GET_TEXT_MODEL_STATISTIC_SUCCESS]: (
    state: ITextModelState,
    action: ITextModelStatisticAction,
  ) => ({
    ...state,
    textModelStatistic: action.payload,
    error: '',
    loading: false,
  }),
  [GET_TEXT_MODEL_STATISTIC_FAILURE]: (state: ITextModelState, action: ITextModelErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
