import React, {useState} from 'react';
import './style.scss';
import {MenuItem, Select, FormControl, Box, ListItemText, Input, Button} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CustomCheckbox from '../CustomCheckbox';

export interface IItem {
  name: string;
  id: string;
}

interface IProps {
  itemsList: IItem[];
  setSelectedItems: (items: IItem[] | ((i: IItem[]) => IItem[])) => void;
  selectedItems: IItem[];
  itemName: string;
}

const MultipleSelectorAll = (props: IProps): JSX.Element => {
  const {itemsList, setSelectedItems, selectedItems, itemName} = props;
  const [isOpen, setOpen] = useState<boolean>(false);
  const selectedIdsArr = selectedItems.map((item) => item.id);

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleChange(event: string[]) {
    if (event.includes('0')) {
      if (selectedItems.length) {
        setSelectedItems([]);
      } else {
        return;
      }
    } else if (event.length === itemsList.length) {
      setSelectedItems([]);
    } else {
      const selectedArr: IItem[] = event.map((item) => {
        const elById = itemsList.find((el) => el.id === item);
        return {
          id: item,
          name: elById ? elById.name : '',
        } as IItem;
      });
      setSelectedItems(selectedArr);
      localStorage.setItem('category', JSON.stringify(selectedArr));
    }
  }

  return (
    <Box sx={{minWidth: 72}} className="select-all">
      <FormControl fullWidth>
        <Button
          id={`openMenu-${itemName}`}
          onClick={handleOpen}
          endIcon={!isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          className="white-btn white-btn-hover select-all__btn"
        >
          {selectedItems.length === 0 ? 'All' : selectedItems.length + ' selected'}
        </Button>
        <Select
          labelId="multiple-selector-label"
          id="multiple-selector"
          multiple={true}
          value={selectedIdsArr}
          onChange={(e) => handleChange(e.target.value as string[])}
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            anchorEl: document.getElementById(`openMenu-${itemName}`),
            style: {marginTop: 10},
          }}
          input={<Input id="select-multiple-checkbox" />}
          style={{display: 'none'}}
          open={isOpen}
          onClose={handleClose}
        >
          <MenuItem value={'0'}>
            <CustomCheckbox checked={!selectedItems.length} />
            <ListItemText primary={`All`} />
          </MenuItem>
          {itemsList.map((item: IItem, index) => {
            const idsArr = selectedItems.map((item) => item.id);
            return (
              <MenuItem key={index} value={item.id}>
                <CustomCheckbox checked={idsArr.indexOf(item.id) > -1} />
                <ListItemText primary={item.name} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MultipleSelectorAll;
