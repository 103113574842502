// SIGNALR Status - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as signalRStatusActionType from './types/types';

export const setSignalRCellEditRequest = createAction(
  signalRStatusActionType.SET_SIGNALR_CELL_EDIT_REQUEST,
);
export const setSignalRCellEditSuccess = createAction(
  signalRStatusActionType.SET_SIGNALR_CELL_EDIT_SUCCESS,
);
export const setSignalRCellEditFailure = createAction(
  signalRStatusActionType.SET_SIGNALR_CELL_EDIT_FAILURE,
);
