/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {useCallback, useEffect, useState} from 'react';
import ChartOfWords from '../../../images/charts-of-words-icon.svg';
import SingleSelectDropdown from '../../UI/SingleSelectDropdown';
import {Area, AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';
import {ITextModelStatistic} from '../../../interfaces';
import moment from 'moment';

interface IProps {
  totalWords?: number;
  dataS?: ITextModelStatistic[] | null;
}

const mockFilterData = [
  {id: 'Last 7 Days', name: 'Last 7 Days'},
  {id: 'Last 14 Days', name: 'Last 14 Days'},
  {id: 'Last month', name: 'Last month'},
];

const dateFormatter = (date: Date) => moment(date).format('DD MMM');

const CustomTooltip = ({active, payload, label}: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="custom-tooltip__label">{label} </p>
        <p className="custom-tooltip__descr">{payload[0].value} words</p>
      </div>
    );
  }

  return null;
};

const TotalWords = (props: IProps): JSX.Element => {
  const {totalWords, dataS} = props;
  const [groupedData, setGroupedData] = useState([] as any[]);
  const [selectedSort, setSelectedSort] = useState<string>('Last 7 Days');
  const [filtersUpdated, setFiltersUpdated] = useState<Date | null>(null);

  const updateFilters = useCallback(() => {
    setFiltersUpdated(new Date());
  }, [selectedSort]);

  useEffect(() => {
    if (dataS) {
      const filteredData = filterData(dataS);
      const grouped = groupData(filteredData);
      const filledData = fillMissingDays(grouped);
      setGroupedData(filledData);
    }
  }, [dataS, filtersUpdated]);

  function formater(i: string) {
    if (i === 'Last 14 Days') {
      return 14;
    } else if (i === 'Last month') {
      return 30;
    } else {
      return 7;
    }
  }

  const filterData = (data: ITextModelStatistic[]) => {
    const daysAgo = new Date();
    const daysFilter = formater(selectedSort);
    daysAgo.setDate(daysAgo.getDate() - daysFilter);

    return data.filter((entry) => {
      const entryDate = new Date(entry.createdDate);
      return (
        entry.categoryName !== null &&
        entry.words > 0 &&
        entryDate >= daysAgo &&
        entryDate <= new Date()
      );
    });
  };

  const fillMissingDays = (groupedData: {date: string; words: number}[]) => {
    const daysAgo = new Date();
    const daysFilter = formater(selectedSort);

    daysAgo.setDate(daysAgo.getDate() - daysFilter);

    const formattedDates = [];
    for (let i = 0; i < daysFilter; i++) {
      const date = new Date(daysAgo);
      date.setDate(date.getDate() + i);
      formattedDates.push(date.toISOString().substr(0, 10));
    }

    const filledData = formattedDates.map((date) => ({
      date,
      words: groupedData.find((entry) => entry.date === date)?.words || 0,
    }));

    const todayDate = new Date();
    const todayFormattedDate = todayDate.toISOString().substr(0, 10);
    filledData.push({
      date: todayFormattedDate,
      words: groupedData.find((entry) => entry.date === todayFormattedDate)?.words || 0,
    });

    return filledData;
  };

  const groupData = (filteredData: ITextModelStatistic[]) => {
    const groupedData: {[date: string]: number} = {};

    filteredData.forEach((entry) => {
      const date = new Date(entry.createdDate);
      const formattedDate = date.toISOString().substr(0, 10);

      if (groupedData[formattedDate]) {
        groupedData[formattedDate] += entry.words;
      } else {
        groupedData[formattedDate] = entry.words;
      }
    });

    return Object.keys(groupedData).map((date) => ({
      date,
      words: groupedData[date],
    }));
  };

  function handleFiltering(selected: string) {
    setSelectedSort(selected);
    updateFilters();
  }

  const setInterval = () => {
    switch (groupedData.length) {
      case 8:
        return 0;
      case 15:
        return 1;
      case 31:
        return 2;
    }
  };

  return (
    <>
      <div className="dashboard__words-top">
        <div className="dashboard__count">
          <div className="dashboard__count-title">{`${totalWords ? totalWords : '0'} words`}</div>
          <div className="dashboard__count-descr">Total Words Generated</div>
        </div>
        <div className="dashboard__filter">
          <SingleSelectDropdown
            buttonName={selectedSort}
            itemsList={mockFilterData}
            updateFunction={(val: string) => handleFiltering(val)}
            disabled={groupedData.length === 0}
          />
        </div>
      </div>
      <div className="dashboard__chart">
        {groupedData.length > 0 ? (
          <div className="dashboard__chart-line">
            <ResponsiveContainer className="dashboard__chart-container" width="100%" height={190}>
              <AreaChart data={groupedData}>
                <defs>
                  <linearGradient id="colorWords" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#BBD5FF" stopOpacity={0.8} />
                    <stop offset="100%" stopColor="#BBD5FF" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="date"
                  tickMargin={15}
                  tickFormatter={dateFormatter}
                  interval={setInterval()}
                  padding={{left: 15, right: 15}}
                />
                <YAxis
                  tickFormatter={(value: number) =>
                    new Intl.NumberFormat('en-US', {
                      notation: 'compact',
                      compactDisplay: 'short',
                    }).format(value)
                  }
                />
                <CartesianGrid stroke="#E9E9E9" />
                <Tooltip
                  content={<CustomTooltip />}
                  cursor={{stroke: '#5C96F5', strokeDasharray: 3}}
                />
                <Area
                  type="monotone"
                  dataKey="words"
                  stroke="#5C96F5"
                  fillOpacity={1}
                  fill="url(#colorWords)"
                  strokeWidth="3"
                  activeDot={{r: 6, clipDot: false}}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="dashboard__chart-image">
            <img className="image" src={ChartOfWords} alt="chart" />
          </div>
        )}
      </div>
    </>
  );
};

export default TotalWords;
