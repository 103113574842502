import React, {useEffect, useState} from 'react';
import {FormControl, InputLabel, ListItemText, MenuItem, Select} from '@material-ui/core';
import './style.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
interface IItem {
  id: string | number;
  name: string;
}
interface IProps {
  inputLabel: string;
  itemsList: IItem[];
  updateFunction: CallableFunction;
  isDisabled?: boolean;
  isDefaultValue?: boolean;
  defaultValueName?: string;
  className?: string;
  selectedValue?: string;
  shrink?: boolean;
  showLabel?: string;
}
const SingleSelectTextfield = (props: IProps): JSX.Element => {
  const {
    inputLabel,
    itemsList,
    isDisabled,
    updateFunction,
    isDefaultValue = false,
    defaultValueName,
    className,
    selectedValue,
    shrink = true,
    showLabel,
  } = props;
  const [selectedItem, setSelectedItem] = useState<string | number>('');

  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    // if (setClearFilter) {
    //   setClearFilter(false);
    // }
    const targetValue = event.target.value as number;
    targetValue !== 0 ? setSelectedItem(targetValue) : setSelectedItem('');
    updateFunction(targetValue);
  };
  useEffect(() => {
    if (selectedValue === 'All') {
      setSelectedItem('');
    }
    if (selectedValue === 'Latest') {
      setSelectedItem('Latest');
    }
    if (selectedValue) {
      setSelectedItem(selectedValue);
    }
  }, [selectedValue]);
  return (
    <>
      <FormControl variant="standard" className={`simple-select ${className ? className : ''}`}>
        <InputLabel shrink={shrink} variant="standard" id="demo-simple-select-label">
          {!selectedItem ? inputLabel : showLabel}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select-standard"
          value={selectedItem}
          onChange={handleChange}
          IconComponent={ExpandMoreIcon}
          disabled={isDisabled}
          renderValue={() => (selectedItem ? selectedItem : inputLabel)}
        >
          {isDefaultValue && (
            <MenuItem value={0} className={`simple-select__item`}>
              <ListItemText primary={defaultValueName} />
            </MenuItem>
          )}
          {itemsList.map((item: IItem, index: number) => (
            <MenuItem key={index} value={item.id} className={`simple-select__item`}>
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SingleSelectTextfield;
