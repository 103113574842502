import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as notifications from '../api/notifications';

// REDUX
import * as notificationsAction from '../actions/notifications';
import * as notificationsActionType from '../actions/types/types';
import {Action} from 'redux-actions';

export function* postChangePasswordNotification(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  unknown
> {
  try {
    const payload = yield call(notifications.postPasswordChangedNotifications, {});
    yield put(notificationsAction.postStatusSuccess(payload));
  } catch (error) {
    yield put(notificationsAction.postStatusFailure(error));
  }
}

// WATCHERS
export function* watchChangePassword(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    notificationsActionType.POST_PASSWORD_CHANGED_NOTIFICATION_REQUEST,
    postChangePasswordNotification,
  );
}
