import React from 'react';
import {Header} from '../../components/Header';
import Footer from '../../components/Footer';
import {Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IState} from '../../interfaces';
import AccountSettings from '../../components/AccountComponents/Settings';
import Profile from '../../components/AccountComponents/Profile';
import BillingDetails from '../../components/AccountComponents/BillingDetails';
import CouponHistory from '../../components/AccountComponents/CouponHistory';
import ChargeHistory from '../../components/AccountComponents/ChargeHistory';

const AccountPage = (): JSX.Element => {
  const user = useSelector((state: IState) => state.userProfile.userProfile);

  return (
    <div className="main-page client-page">
      <Header showLogo={true} adminSide={false} />
      <div className="main-page__content">
        <Routes>
          <Route path={`profile`} element={<Profile />} />
          <Route path={`profile#payment`} element={<Profile />} />
          <Route path={`profile#delete`} element={<Profile />} />
          {(user.userRole === 'Consultant' || user.userRole === 'Admin') && (
            <Route path={`settings`} element={<AccountSettings />} />
          )}
          <Route path={`billing-details`} element={<BillingDetails />} />
          <Route path={`credit-history`} element={<ChargeHistory />} />
          <Route path={`coupon-history`} element={<CouponHistory />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default AccountPage;
