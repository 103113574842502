import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as payments from '../api/payments';
import * as billing from '../api/billing';

// REDUX
import * as paymentsAction from '../actions/payments';
import * as paymentsActionType from '../actions/types/types';
import {Action} from 'redux-actions';
import {
  IInitializeCardAction,
  IInitializeDefaultCardAction,
  IRemoveCardPayloadAction,
} from '../interfaces';
import * as billingAction from '../actions/billing';
import * as userProfile from '../api/userProfile';
import * as userProfileAction from '../actions/userProfile';

export function* initializePaymentCard(
  action: IInitializeCardAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(payments.initializePaymentCard, action.payload);
    yield put(paymentsAction.postInitializePaymentCardSuccess(payload));
  } catch (error) {
    yield put(paymentsAction.postInitializePaymentCardFailure(error));
  }
}

export function* initializeDefaultPaymentCard(
  action: IInitializeDefaultCardAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(payments.initializeDefaultPaymentCard, action.payload);
    yield put(paymentsAction.postInitializeDefaultPaymentCardSuccess(payload));
    const payloadMethods = yield call(payments.getPaymentMethods, {});
    yield put(paymentsAction.getPaymentMethodsSuccess(payloadMethods));
    if (payload && action.payload.subscriptionId) {
      const buySubscriptionPayload = {
        subscriptionId: action.payload.subscriptionId,
        promocode: ' ',
      };
      const payload = yield call(billing.postBuySubscription, buySubscriptionPayload);
      yield put(billingAction.postSubscriptionSuccess(payload));
      const userPayload = yield call(userProfile.getUserProfile);
      yield put(userProfileAction.getUserProfileSuccess(userPayload));
    }
  } catch (error) {
    yield put(billingAction.postSubscriptionFailure(error));
    yield put(paymentsAction.postInitializeDefaultPaymentCardFailure(error));
  }
}

export function* removePaymentCard(
  action: IRemoveCardPayloadAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(payments.removePaymentCard, action.payload);
    yield put(paymentsAction.removePaymentCardSuccess(payload));
  } catch (error) {
    yield put(paymentsAction.removePaymentCardFailure(error));
  }
}

// WATCHERS
export function* watchInitializePaymentCard(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(paymentsActionType.POST_INITIALIZE_PAYMENT_CARD_REQUEST, initializePaymentCard);
}
export function* watchInitializeDefaultPaymentCard(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    paymentsActionType.POST_INITIALIZE_DEFAULT_PAYMENT_CARD_REQUEST,
    initializeDefaultPaymentCard,
  );
}

export function* watchRemovePaymentCard(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(paymentsActionType.DELETE_PAYMENT_CARD_REQUEST, removePaymentCard);
}
