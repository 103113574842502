import React, {useEffect, useState} from 'react';
import './style.scss';
import BackButton from '../../UI/BackButton';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getUserManagementProfileRequest} from '../../../actions/userManagementProfile';
import {IPaymentHistory, IState} from '../../../interfaces';
import {
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import moment from 'moment';
import LoadingSpinner from '../../UI/LoadingSpinner';
import CreditCountIconSilver from '../../../images/credit-icon-silver.svg';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import * as addAdminCredits from '../../../actions/adminCredits';
import {
  postAddAdminSubscriptionStatusRequest,
  postSubscriptionRefundRequest,
} from '../../../actions/adminSubscription';
import {standardDateFormat} from '../../../helpers/helper';
import CreditCountIcon from '../../../images/card-icon.svg';
import {SubscriptionStatus} from '../../../enums/SubscriptionStatuses';

const UserDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const params = useParams();
  const userId = params.userId;
  const [isAddFieldOpen, setAddFieldOpen] = useState<boolean>(false);
  const [creditsAmount, setCreditsAmount] = useState<number | string>('');
  const [refundError, setRefundError] = useState<boolean>(false);

  const singleUser = useSelector((state: IState) => state.userManagementProfile);
  const refundSate = useSelector((state: IState) => state.refundSubscription);
  const userProfile = singleUser.userManagementProfile;
  const userSubscriptionStatus = userProfile ? userProfile.userSubscriptionStatus : '';

  useEffect(() => {
    dispatch(getUserManagementProfileRequest({userId: userId}));
  }, []);

  useEffect(() => {
    refundSate.error && setRefundError(true);
  }, [refundSate.error]);

  const exDate =
    userSubscriptionStatus === 'Endless'
      ? ''
      : standardDateFormat(userProfile?.currentUserSubscription?.expirationUtc);
  const activeUserStatus =
    !userSubscriptionStatus.includes('Expired') &&
    userSubscriptionStatus !== SubscriptionStatus.None;
  const currentDate = new Date();
  const startDate =
    userProfile && moment(userProfile?.currentUserSubscription?.startedUtc).toDate();
  const diff = startDate && currentDate.getTime() - startDate.getTime();
  const diffDays = diff && Math.floor(diff / (1000 * 60 * 60 * 24));

  const submitAddCredits = () => {
    const data = {creditsValue: creditsAmount, userId: userId};
    dispatch(addAdminCredits.postAddCreditsStatusRequest(data));
    setCreditsAmount('');
    setAddFieldOpen(false);
    // setUserDataLoading(true);
  };
  const handleAssignPlan = () => {
    // setUserDataLoading(true);
    dispatch(
      postAddAdminSubscriptionStatusRequest({
        userId: userId,
      }),
    );
  };

  const refundSubscription = () => {
    dispatch(postSubscriptionRefundRequest({userId: userId}));
  };

  return !singleUser.loading && userProfile ? (
    <div className="user-details">
      <BackButton prevPage isTextBack={true} />
      <p className="user-details__item-title">Profile Details</p>
      <div className="user-details__item profile">
        <div>
          <h2>{userProfile.fullName}</h2>
          <p className="item-row">
            <span className="title">Company:</span>
            <span className="value">{userProfile.companyName}</span>
          </p>
          <p className="item-row">
            <span className="title">Email:</span>
            <span className="value email">{userProfile.userEmail}</span>
          </p>
          <p className={`item-row`}>
            <span className="title">Status:</span>
            <span className={`value ${activeUserStatus ? 'active' : 'inactive'} status`}>
              {`${activeUserStatus ? 'active' : 'inactive'} user`}
            </span>
          </p>
        </div>
        {userProfile.avatar ? <img src={userProfile.avatar} alt="Profile avatar" /> : <></>}
      </div>
      <p className="user-details__item-title">Subscription plan</p>
      <div className="user-details__item subscription">
        <div className="item-row credits">
          <span className="title">Credits:</span>
          <span className="value">
            <img src={CreditCountIconSilver} alt="Coin icon" />
            {userProfile.creditCount}
          </span>
          {!isAddFieldOpen ? (
            <Button
              className="user-details__text-btn"
              onClick={() => setAddFieldOpen(true)}
              startIcon={<AddIcon />}
            >
              Add credits
            </Button>
          ) : (
            <span className="user-info__item">
              {/*<p className="user-info__item-title">Add credits</p>*/}
              <TextField
                value={creditsAmount}
                className="user-management__credits-input"
                autoFocus
                placeholder="0"
                type="text"
                onChange={(e) => setCreditsAmount(Number(e.target.value))}
              />
              <Button
                className="user-details__text-btn"
                onClick={submitAddCredits}
                disabled={Boolean(!creditsAmount)}
                startIcon={<DoneIcon />}
              >
                Add credits
              </Button>
              <Button className="credits-btn cancel" onClick={() => setAddFieldOpen(false)}>
                Cancel
              </Button>
            </span>
          )}
        </div>
        <div className="item-row refund">
          <span className="title">Subscription plan:</span>
          <span className="value">{userProfile.currentUserSubscription?.name}</span>
          {!refundError ? (
            <Button className="orange-btn orange-btn-hover" onClick={refundSubscription}>
              Refund
            </Button>
          ) : (
            <p className="error">User cannot be refunded</p>
          )}
        </div>
        <div className="item-row">
          <span className="title">Expiration date: </span>
          <span className="value">{exDate}</span>
        </div>
        <div className="item-row subscription-plan">
          <span className="title">Subscription days:</span>
          <span className="value">{activeUserStatus ? diffDays : 0}</span>
          <Button
            className="user-details__text-btn"
            startIcon={<AddIcon />}
            onClick={handleAssignPlan}
            disabled={userSubscriptionStatus === 'Endless'}
          >
            Apply Endless Silver Plan
          </Button>
        </div>
      </div>
      <p className="user-details__item-title">Credit History</p>
      <TableContainer className="user-details__item payments-table">
        <Table>
          <TableHead className="payments-table__head">
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Payment method</TableCell>
              <TableCell align="left">Total</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="payments-table__body">
            {userProfile.paymentHistory.map((item: IPaymentHistory) => (
              <TableRow key={item.name}>
                <TableCell component="th" scope="row">
                  {standardDateFormat(item.effectiveUtc)}
                </TableCell>
                <TableCell align="left" className="description">
                  {item.name}
                </TableCell>
                <TableCell align="left">
                  <img className="billing__table-card-img" src={CreditCountIcon} alt="" />
                  •••• •••• •••• {item.last4}
                </TableCell>
                <TableCell align="left">
                  {`${item.currency === 'usd' ? '$' : ''}${item.price}`}
                </TableCell>
                <TableCell align="right">
                  {item.paymentStatus === 'Decline' ? (
                    <p className="decline-payment">
                      {item.paymentStatus}
                      <span>{item.paymentErrorReason}</span>
                    </p>
                  ) : (
                    item.paymentStatus
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default UserDetails;
