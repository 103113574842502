import {SubscriptionStatus} from '../enums/SubscriptionStatuses';

export function isExpiredSubscription(userSubscriptionStatus: SubscriptionStatus): boolean {
  return (
    userSubscriptionStatus === SubscriptionStatus.PaidExpired ||
    userSubscriptionStatus === SubscriptionStatus.FreeExpired
  );
}

export function isEndlessSubscription(subscriptionStatus: SubscriptionStatus): boolean {
  return subscriptionStatus === SubscriptionStatus.Endless;
}

export function isNoSubscription(subscriptionStatus: SubscriptionStatus): boolean {
  return subscriptionStatus === SubscriptionStatus.None;
}

export function isTrialSubscription(subscriptionStatus: SubscriptionStatus): boolean {
  return subscriptionStatus === SubscriptionStatus.TrialActive;
}

export function getSubscriptionTitleLabel(
  subscriptionStatus: SubscriptionStatus,
  subscriptionName: string,
): string {
  if (isEndlessSubscription(subscriptionStatus)) {
    return 'Endless';
  } else if (isExpiredSubscription(subscriptionStatus)) {
    return `${subscriptionName} (Expired)`;
  } else if (isNoSubscription(subscriptionStatus)) {
    return `No subscription yet`;
  } else if (isTrialSubscription(subscriptionStatus)) {
    return `7-Day Free Trial period of Silver Plan`;
  }

  return subscriptionName;
}

// subscription helpers

/* export function isActivePaidSubscription(userSubscriptionStatus: SubscriptionStatus):boolean {
  return userSubscriptionStatus === SubscriptionStatus.PaidActive ||
    userSubscriptionStatus === SubscriptionStatus.Endless;
}

export function isActiveSubscription(userSubscriptionStatus: SubscriptionStatus):boolean {
  return userSubscriptionStatus === SubscriptionStatus.PaidActive ||
    userSubscriptionStatus === SubscriptionStatus.FreeActive ||
    userSubscriptionStatus === SubscriptionStatus.Endless;
}
*/
