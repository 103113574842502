import React, {ReactNode, useState} from 'react';
import {Alert, AlertTitle, Color} from '@material-ui/lab';
import './style.scss';
import {Button, Collapse, IconButton} from '@material-ui/core';

interface IProps {
  type: Color;
  title: string | ReactNode;
  withButton?: boolean;
  details?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  color?: Color;
  specificClass?: string;
  buttonText?: string;
  buttonIcon?: ReactNode;
  buttonAction?: () => void;
  detailsList?: string[];
}

const UiAlert = (props: IProps): JSX.Element => {
  const {
    type,
    title,
    details,
    variant = 'standard',
    color,
    specificClass,
    withButton = false,
    buttonText,
    buttonIcon,
    buttonAction,
    detailsList,
  } = props;
  const [isOpen, setOpen] = useState<boolean>(true);
  return (
    <>
      {!withButton ? (
        <Alert
          className={`ui-alert ${specificClass ? specificClass : ''}`}
          severity={type}
          variant={variant}
          color={color ? color : type}
        >
          <AlertTitle>{title}</AlertTitle>
          {details && <p>{details}</p>}
          {detailsList && detailsList.length && (
            <ul className="ui-alert__list">
              {detailsList.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          )}
        </Alert>
      ) : (
        <Collapse in={isOpen}>
          <Alert
            className={`ui-alert ${specificClass ? specificClass : ''}`}
            severity={type}
            variant={variant}
            color={color ? color : type}
            action={
              buttonText ? (
                <Button
                  color="inherit"
                  size="small"
                  onClick={buttonAction ? buttonAction : () => setOpen(false)}
                  className={`ui-alert__btn`}
                >
                  {buttonText}
                </Button>
              ) : (
                <IconButton onClick={() => setOpen(false)}>{buttonIcon}</IconButton>
              )
            }
          >
            <AlertTitle>{title}</AlertTitle>
            {details && <p>{details}</p>}
            {detailsList && detailsList.length && (
              <ul className="ui-alert__list">
                {detailsList.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            )}
          </Alert>
        </Collapse>
      )}
    </>
  );
};

export default UiAlert;
