import {handleActions} from 'redux-actions';
// ACTION TYPES
import {
  POST_PASSWORD_CHANGED_NOTIFICATION_REQUEST,
  POST_PASSWORD_CHANGED_NOTIFICATION_SUCCESS,
  POST_PASSWORD_CHANGED_NOTIFICATION_FAILURE,
} from '../actions/types/types';
import {IChangePasswordAction, IChangePasswordState} from '../interfaces';

export const initialState: IChangePasswordState = {
  changePasswordResponse: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [POST_PASSWORD_CHANGED_NOTIFICATION_REQUEST]: (state: IChangePasswordState) => ({
    ...state,
    loading: true,
  }),

  [POST_PASSWORD_CHANGED_NOTIFICATION_SUCCESS]: (
    state: IChangePasswordState,
    action: IChangePasswordAction,
  ) => ({
    ...state,
    changePasswordResponse: action.payload,
    loading: false,
    error: '',
  }),

  [POST_PASSWORD_CHANGED_NOTIFICATION_FAILURE]: (
    state: IChangePasswordState,
    action: IChangePasswordAction,
  ) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
