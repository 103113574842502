import {handleActions} from 'redux-actions';

//ACTION TYPES
import {
  POST_SUBSCRIPTIONS_REQUEST,
  POST_SUBSCRIPTIONS_SUCCESS,
  POST_SUBSCRIPTIONS_FAILURE,
} from '../actions/types/types';
import {IBuySubscriptionAction, IBuySubscriptionState} from '../interfaces';

export const initialState: IBuySubscriptionState = {
  boughtSubscription: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [POST_SUBSCRIPTIONS_REQUEST]: (state: IBuySubscriptionState) => ({
    ...state,
    loading: true,
  }),

  [POST_SUBSCRIPTIONS_SUCCESS]: (state: IBuySubscriptionState, action: IBuySubscriptionAction) => ({
    ...state,
    boughtSubscription: action.payload,
    loading: false,
    error: '',
  }),

  [POST_SUBSCRIPTIONS_FAILURE]: (state: IBuySubscriptionState, action: IBuySubscriptionAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
