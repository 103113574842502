import React, {useEffect, useState} from 'react';
import './style.scss';
import Modal from '@material-ui/core/Modal';
import {IArea} from '../../../interfaces';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Collapse, styled, Button, FormGroup, FormControlLabel} from '@material-ui/core';
import IconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import {Theme} from '@material-ui/core/styles';
import CustomCheckbox from '../../UI/CustomCheckbox';
import {IItem} from '../../UI/MultipleSelectorAll';

interface IFilterArray {
  areas: IArea[];
  industry: IItem[];
  stencil: IItem[];
  status: IItem[];
}
interface IProps {
  industryArray: IItem[] | null;
  stencilArray: IItem[] | null;
  areaArray: IArea[] | null;
  statusArray: IItem[];
  setModalOpen: (isOpen: boolean) => void;
  selectedIndustry: IItem[];
  setSelectedIndustry: (items: IItem[]) => void;
  selectedStencil: IItem[];
  setSelectedStencil: (items: IItem[]) => void;
  selectedArea: IArea[];
  setSelectedArea: (items: IItem[]) => void;
  selectedStatus: IItem[];
  setSelectedStatus: (items: IItem[]) => void;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const {expand, ...other} = props;
  return <IconButton {...other} />;
})<Theme, ExpandMoreProps>(({theme, expand}) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ModalFilter = (props: IProps): JSX.Element => {
  const {
    stencilArray,
    setModalOpen,
    selectedIndustry,
    setSelectedIndustry,
    selectedStencil,
    setSelectedStencil,
    selectedArea,
    setSelectedArea,
    selectedStatus,
    setSelectedStatus,
  } = props;
  const [localIndustry, setLocalIndustry] = useState<IItem[]>(selectedIndustry);
  const [localStencil, setLocalStencil] = useState<IItem[]>(selectedStencil);
  const [localArea, setLocalArea] = useState<IArea[]>(selectedArea);
  const [localStatus, setLocalStatus] = useState<IItem[]>(selectedStatus);
  const [expandedCollapse, setExpandedCollapse] = useState<string[]>([]);
  const [localFilterArray, setLocalFilterArray] = useState<IFilterArray>({
    areas: localArea,
    industry: localIndustry,
    stencil: localStencil,
    status: localStatus,
  });

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleExpandCollapse = (name: string) => {
    if (expandedCollapse.some((e) => e === name)) {
      setExpandedCollapse((prev: string[]) => prev.filter((e: string) => e !== name));
      return;
    }
    setExpandedCollapse([...expandedCollapse, name]);
  };
  function handleDeleteFilter(index: number, objectIndex: number) {
    const newIndustryArray: IItem[] = localFilterArray.industry;
    const newStencilArray: IItem[] = localFilterArray.stencil;
    const newAreaArray: IArea[] = localFilterArray.areas;
    const newStatusArray: IItem[] = localFilterArray.status;

    if (index !== -1) {
      if (objectIndex === 0) {
        const newList = newAreaArray.filter((e, idx) => idx !== index);
        setLocalFilterArray((prev: IFilterArray) => ({
          ...prev,
          areas: newList,
        }));
        setLocalArea(newList);
      }
      if (objectIndex === 1) {
        const newList = newIndustryArray.filter((e, idx) => idx !== index);
        setLocalFilterArray((prev: IFilterArray) => ({
          ...prev,
          industry: newList,
        }));
        setLocalIndustry(newList);
      }
      if (objectIndex === 2) {
        const newList = newStencilArray.filter((e, idx) => idx !== index);
        setLocalFilterArray((prev: IFilterArray) => ({
          ...prev,
          stencil: newList,
        }));
        setLocalStencil(newList);
      }
      if (objectIndex === 3) {
        const newList = newStatusArray.filter((e, idx) => idx !== index);
        setLocalFilterArray((prev: IFilterArray) => ({
          ...prev,
          status: newList,
        }));
        setLocalStatus(newList);
      }
    }
  }
  function filterItems(
    id: string,
    itemsArr: IItem[],
    setSelectedItems: (items: IItem[] | ((i: IItem[]) => IItem[])) => void,
    selectedArr: IItem[],
  ) {
    if (id === 'All') {
      setSelectedItems([]);
      return;
    }
    const elById = itemsArr && itemsArr.find((el) => el.id === id);
    const selectedItem = {
      id: id,
      name: elById ? elById.name : '',
    };
    if (selectedArr.some((e) => e.id === selectedItem.id)) {
      setSelectedItems((prev: IItem[]) => prev.filter((e: IItem) => e.id !== selectedItem.id));
      return;
    }
    setSelectedItems([...selectedArr, selectedItem]);
  }
  useEffect(() => {
    setLocalFilterArray({
      areas: localArea,
      industry: localIndustry,
      stencil: localStencil,
      status: localStatus,
    });
  }, [localArea, localIndustry, localStencil, localStatus]);

  const clearAll = () => {
    setSelectedIndustry([]);
    setSelectedStencil([]);
    setSelectedArea([]);
    setSelectedStatus([]);
    setLocalIndustry([]);
    setLocalStencil([]);
    setLocalArea([]);
    setLocalStatus([]);
  };

  const handleApply = () => {
    setSelectedIndustry(localIndustry);
    setSelectedStatus(localStatus);
    setSelectedStencil(localStencil);
    setSelectedArea(localArea);
    setModalOpen(false);
  };

  return (
    <Modal open={true} onClose={handleClose}>
      <div className="filter-modal">
        <div className="filter-modal__header">
          <h2 className="filter-modal__header-title">Filters</h2>
          <IconButton aria-label="close" className="close-icon" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="content__filters">
          {Object.values(localFilterArray).map((value: IItem[], objectIndex: number) =>
            value.map((i: IItem, index: number, _: any) => (
              <div className="content__filters-item" key={index}>
                <span className="content__filters-filter">{i.name}</span>
                <IconButton onClick={() => handleDeleteFilter(index, objectIndex)}>
                  <CloseIcon className="content__filters-close-icon" />
                </IconButton>
              </div>
            )),
          )}
        </div>
        <div className="filter-modal__content">
          {stencilArray && (
            <div className="filter-category">
              <div className="filter-category__item">
                <h3 className="filter-category__item-title">Stencil</h3>
                <ExpandMore
                  expand={expandedCollapse.includes('stencil')}
                  onClick={() => handleExpandCollapse('stencil')}
                  aria-expanded={expandedCollapse.includes('stencil')}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </div>
              <Collapse in={expandedCollapse.includes('stencil')} timeout="auto" unmountOnExit>
                <FormGroup>
                  <FormControlLabel
                    control={<CustomCheckbox checked={!localStencil.length} />}
                    label={'All'}
                    onChange={() => filterItems('All', stencilArray, setLocalStencil, localStencil)}
                  />
                  {stencilArray.map((item: IItem) => (
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          checked={localStencil.map((i) => i.id).indexOf(item.id) > -1}
                        />
                      }
                      label={item.name}
                      onChange={() =>
                        filterItems(item.id, stencilArray, setLocalStencil, localStencil)
                      }
                      key={item.id}
                    />
                  ))}
                </FormGroup>
              </Collapse>
            </div>
          )}
        </div>
        <div className="filter-modal__footer">
          <Button
            className="white-btn white-btn-hover"
            onClick={clearAll}
            disabled={
              !localIndustry.length &&
              !localArea.length &&
              !localStatus.length &&
              !localStencil.length
            }
          >
            Clear All
          </Button>
          <Button className="orange-btn orange-btn-hover" onClick={handleApply}>
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFilter;
