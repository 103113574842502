import {ICheckCouponRequest, ICoupon, IGetCouponByIdRequest, IUpdateCoupon} from '../interfaces';
import {GET, handleBody, handleExceptions, POST, PUT} from './request';
import {createArrayEndpoint} from '../helpers/helper';

export const createCoupon = (data: ICoupon): Promise<void | Response | Object | null> => {
  const endpoint: string = `/Admin/Coupon`;
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getCouponsManagement = (payload?: string): Promise<Object | null> => {
  const endpoint: string = createArrayEndpoint('Admin/Coupons', payload);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const applyCoupon = (data: string): Promise<void | Response | Object | null> => {
  const endpoint: string = `/Billing/Coupon/ApplyPromocode?Promocode=${data}`;
  const options = {};

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getCouponsHistory = (): Promise<void | Response | Object | null> => {
  const endpoint: string = `/Billing/Coupon/History`;
  const options = {};

  return GET(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getCouponById = (
  data: IGetCouponByIdRequest,
): Promise<void | Response | Object | null> => {
  const endpoint: string = `/Admin/Coupon?Id=${data.id}`;

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const checkCoupon = (
  data: ICheckCouponRequest,
): Promise<void | Response | Object | null> => {
  const endpoint: string = `/Billing/Coupon/CheckPromocodeDiscount`;
  const options = {
    body: data,
  };

  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const updateCoupon = (data: IUpdateCoupon): Promise<void | Response | Object | null> => {
  const endpoint: string = `/Admin/Coupon`;
  const options = {
    body: data,
  };

  return PUT(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
