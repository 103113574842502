import {handleActions} from 'redux-actions';

// ACTION TYPES
import {
  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
} from '../actions/types/types';
import {IPaymentMethodsState, IPaymentMethods} from '../interfaces';

interface IAction {
  payload: IPaymentMethods;
}

export const initialState = {
  paymentMethods: undefined,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_PAYMENT_METHODS_REQUEST]: (state: IPaymentMethodsState) => ({
    ...state,
    loading: true,
  }),

  [GET_PAYMENT_METHODS_SUCCESS]: (state: IPaymentMethodsState, action: IAction) => ({
    ...state,
    paymentMethods: action.payload,
    loading: false,
    error: '',
  }),

  [GET_PAYMENT_METHODS_FAILURE]: (state: IPaymentMethodsState) => ({
    ...state,
    error: '',
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
