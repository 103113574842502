import React from 'react';
import './style.scss';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShareIcon from '@material-ui/icons/Share';
import DownloadIcon from '../../../images/download-icon.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import FilterListIcon from '@material-ui/icons/FilterList';
import ReplayIcon from '@material-ui/icons/Replay';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import {IconType} from '../../../enums';

interface Props {
  type: IconType;
  color?: string;
  wrapper?: boolean;
}

const Icons = ({type, color = '#EC7644', wrapper = true}: Props): JSX.Element => {
  const iconHandler = () => {
    switch (type) {
      case IconType.MINUS_FILLED:
        return <RemoveIcon style={{color: color}} />;
      case IconType.PLUS_FILLED:
        return <AddIcon style={{color: color}} />;
      case IconType.CALENDAR:
        return <CalendarTodayIcon style={{color: color}} />;
      case IconType.HEART_BORDER:
        return <FavoriteBorderIcon style={{color: color}} />;
      case IconType.HEART_FILLED:
        return <FavoriteIcon style={{color: color}} />;
      case IconType.MORE_DOTS:
        return <MoreVertIcon style={{color: color}} />;
      case IconType.SHARE_ICON:
        return <ShareIcon style={{color: color}} />;
      case IconType.EXPAND_MORE:
        return <ExpandMoreIcon style={{color: color}} />;
      case IconType.EXPAND_LESS:
        return <ExpandLessIcon style={{color: color}} />;
      case IconType.FILTER_LIST:
        return <FilterListIcon style={{color: color}} />;
      case IconType.REPLAY:
        return <ReplayIcon style={{color: color}} />;
      case IconType.DOWNLOAD_ICON_SVG:
        return <SystemUpdateAltIcon style={{color: color}} />;
      case IconType.DOWNLOAD_ICON:
        return <img src={DownloadIcon} style={{color: color}} alt="" />;
      default:
        return null;
    }
  };

  return <div className={wrapper ? 'IconWrapper' : undefined}>{iconHandler()}</div>;
};

export default Icons;
