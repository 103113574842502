import React, {useEffect, useState} from 'react';
import './style.scss';
import {Grid, Hidden, Table, TableBody, TableContainer} from '@material-ui/core';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import {useSelector} from 'react-redux';
import TableRowItem from './TableItem';
import {IState, ITextGenerationResultItem} from '../../../interfaces';
import SingleSelectDropdown from '../../UI/SingleSelectDropdown';

export interface ICreditHistoryTable {
  createdDate: string;
  inputControlAlias: string;
  wordCount: number;
  value: string[];
  modelName: string;
  wordCounts: number[];
  chargeAmount: number;
  model: string;
  qualityType: string;
}
interface IProps {
  historyTemplate: ITextGenerationResultItem[];
}

const CreditHistory = (props: IProps): JSX.Element => {
  const {historyTemplate} = props;
  const [currentOpenItem, setCurrentOpenItem] = useState<number | null>(null);
  const mockSortStatus = [
    {id: 'Latest', name: 'Latest'},
    {id: 'Oldest', name: 'Oldest'},
  ];
  const [selectedSort, setSelectedSort] = useState<string>('Descending');
  const [filtersUpdated, setFiltersUpdated] = useState<Date | null>(null);
  const userPlan = useSelector(
    (state: IState) => state.userProfile.userProfile.currentUserSubscription?.name,
  );

  const handleRequestSort = () => {
    const isAsc = selectedSort === 'Ascending';
    setSelectedSort(isAsc ? 'Descending' : 'Ascending');
  };

  useEffect(() => {
    setFiltersUpdated(new Date());
  }, [selectedSort]);

  useEffect(() => {
    if (selectedSort === 'Descending') {
      historyTemplate.sort(function (a: ITextGenerationResultItem, b: ITextGenerationResultItem) {
        const dateA = new Date(a.createdDate);
        const dateB = new Date(b.createdDate);
        return dateA.getTime() - dateB.getTime();
      });
    } else {
      historyTemplate.sort(function (a: ITextGenerationResultItem, b: ITextGenerationResultItem) {
        const dateA = new Date(a.createdDate);
        const dateB = new Date(b.createdDate);
        return dateB.getTime() - dateA.getTime();
      });
    }
  }, [filtersUpdated]);

  function handleFiltering(selected: string) {
    selected === 'Latest' ? setSelectedSort('Descending') : setSelectedSort('Ascending');
  }

  return (
    <>
      <div className="credit-history">
        <Grid container>
          <div className="credit-history__top">
            <h2 className="credit-history__title">Credit History</h2>
            <Hidden smUp>
              <div className="content__sortBy-wr">
                <p>Sort by</p>
                <SingleSelectDropdown
                  buttonName={selectedSort === 'Descending' ? 'Latest' : 'Oldest'}
                  itemsList={mockSortStatus}
                  updateFunction={handleFiltering}
                />
              </div>
            </Hidden>
          </div>
          <Hidden smUp>
            <span className="divider"></span>
          </Hidden>

          <Hidden xsDown>
            <div className="charge-history__table-header ">
              <div
                className="charge-history__row charge-history__table-date"
                onClick={() => handleRequestSort()}
              >
                Date
                <div className="charge-history__sort">
                  <ArrowDropUpRoundedIcon />
                  <ArrowDropDownRoundedIcon />
                </div>
              </div>
              <p className="charge-history__row charge-history__table-field">Field Name</p>
              <p className="charge-history__row charge-history__table-model">Model</p>
              <p className="charge-history__row charge-history__table-credits">Credits</p>
            </div>
          </Hidden>
          <TableContainer className="charge-history__container">
            <Table>
              <TableBody>
                {historyTemplate?.map((row, index) => (
                  <TableRowItem
                    key={index}
                    item={row}
                    index={index}
                    currentItem={currentOpenItem}
                    setCurrentItem={setCurrentOpenItem}
                    userPlan={userPlan}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </div>
    </>
  );
};

export default CreditHistory;
