import {NNFormattingCommands} from '../enums/TextModel';

import {IInBackground} from './UI';
import {ITemplateBlock} from '../helpers/textModel';

export interface ITextModelState {
  lastFilterParams: ITextModelsPayload | null;
  textModelTemplates: ITextModelTemplates | null;
  textModelCreate: IModelIdResponse | null;
  textModelDetails: ITextModelDetails | null;
  textModelDetailsHistory: ITextGenerationResultItem[] | null;
  textPublicModel: ITextModelDetails | null;
  modelTemplateDetails: IModelTemplateDetails | null;
  textModels: ITextModelsResponse | null;
  textModelEntries: ITextModelEntriesResponse | null;
  textModelCategories: ITextModelCategoriesResponse | null;
  textModelsForLanding: ITextModelDetails[];
  entriesImages: IEntriesImages | null;
  textModelStatistic: ITextModelStatistic[] | null;
  nextPageResponse: IModelIdResponse | null;
  cancelResponse: IModelIdResponse | null;
  textGenerationResult: ITextGenerationResultResponse | null;
  shareResponse: IShareResponse | null;
  unShareResponse: boolean | null;
  uploadFileResponse: IUploadFileResponse;
  likeModelResponse: IModelIdResponse | null;
  modelFeedbackResponse: boolean | null;
  setArchiveModelResponse: boolean | null;
  entryCreateResult: IEntryRequest | null;
  singleTextModelEntry: ITextModelEntry | null;
  entryUpdateResult: IEntryRequest | null;
  publishEntryResult: IEntryRequest | null;
  generateTextModelResult: IEntryRequest | null;
  actionLoading: boolean;
  copyLink: string;
  loading: boolean;
  navigateToEntry: boolean;
  isEntryDeleted: IEntryRequest | null;
  error: string;
}

export interface ITextModelTemplates {
  items: ITextModelCategoryTemplateItem[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ITextModelCategoryTemplateItem {
  id: string;
  externalId: string;
  name: string;
  description: string;
  imageUrl: string;
  templates: ITextModelTemplateItem[];
  backgroundColor: string;
}

export interface ITextModelTemplateItem {
  id: string;
  externalId: string;
  textModelCategoryId: string;
  name: string;
  alias: string;
  section: string;
  description: string;
  imageUrl: string;
  version: number;
  help: string;
}

export interface ITextModelEntries {
  items: ITextModelEntriesItem[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ITextModelEntriesItem {
  id: string;
  name: string;
  description: string;
  isPublished: true;
  isPrivate: true;
  userId: string;
  contentType: string;
  categoryId: string;
  categoryName?: string;
  data: ITemplateBlock[];
  tags: string;
  help: string;
  image: string;
  createdUtc: string;
  updatedUtc: string;
  creativityIndicator: number;
  qualityType: string;
}
export interface ITextModelCategories {
  items: ITextModelCategoriesItem[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ITextModelCategoriesItem {
  id: string;
  externalId: string;
  name: string;
  description: string;
  imageUrl: string;
  backgroundColor: string;
}

export interface ITextModelDetails {
  id: string;
  userId: string;
  name: string;
  textModelTemplateName: string;
  currentStepName: string;
  alias: string;
  version: number;
  status: IStatus;
  finalResult: string;
  lastError: string;
  variables: IVariable[];
  areaNames: string[];
  industryName: string;
  industryId: number;
  isFavorite: boolean;
  isArchived: boolean;
  feedback: ETextModelFeedback;
  completedDate: string;
  createdDate: string;
  retryStep: string;
  textModelTemplate?: IModelTemplateDetails[];
  publicHash: string;
  history: ITextGenerationResultItem[];
  entryImg: string;
  textualTemplate: ITemplateBlock[];
  contentType: string;
  categoryId: string;
  qualityType: string;
  creativityIndicator: number;
}

export interface IModelTemplateDetails {
  id: string;
  externalId: string;
  name: string;
  alias: string;
  description: string;
  imageUrl: string;
  version: number;
  forms: IDetailsForm[];
  help: string | null;
}

export interface IDetailsForm {
  id: string;
  externalId: string;
  textModelTemplateId: string;
  name: string;
  alias: string;
  description: string;
  nameForGenerationStep: string;
  order: number;
  metaData: string;
  inputControls: IInputControl[];
}

export interface IInputControl {
  id: string;
  externalId: string;
  defaultValue: string[];
  name: string;
  alias: string;
  textModelTemplateFormId: string;
  description: string;
  order: number;
  source: IValue;
  listAccess: IValue;
  itemAccess: IValue;
  type: IValue;
  values: string[];
  selections: number[];
  optional: boolean;
  upload?: boolean;
  errorText?: string;
  customTitle: string;
  valuesWordCounts?: number[];
}

export interface IValue {
  name: string;
  value: number;
}

export interface IStatus {
  id: string;
  textModelId: string;
  name: string;
}

export interface IVariable {
  id: string;
  textModelId: string;
  name: string;
  values: string[];
  selections: number[];
  customTitle: string;
  wordCounts: number[];
}

export interface IEntriesImages {
  imageNames: string[];
}

export interface ITextModelStatistic {
  templateName: string;
  categoryName: string;
  words: number;
  createdDate: Date;
}

export interface ITextModelsResponse {
  items: ITextModelsItem[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ITextModelEntriesResponse {
  items: ITextModelEntriesItem[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}
export interface ITextModelCategoriesResponse {
  items: ITextModelCategoriesItem[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ITextModelsItem {
  id: string;
  textModelTemplateName: string; // TODO: replace string into ETextModelTemplateName
  currentStepName: string;
  alias: string;
  name: string;
  version: number;
  status: ITextModelsStatus;
  lastError: string;
  createdDate: Date;
  completedDate: Date;
  areaNames: string[];
  industryName: string;
  isFavorite: boolean;
  feedback: ETextModelFeedback;
  iconTemplateLink: string;
  publicHash: string;
  isArchived: boolean;
}

export interface ITextGenerationResultResponse {
  items: ITextGenerationResultItem[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ITextGenerationResultItem {
  id: string;
  modelId: string;
  textModelId: string;
  userId: string;
  value: string[];
  createdDate: string;
  wordCount: number;
  chargeAmount: number;
  templateName: string;
  modelName: string;
  inputControlAlias: string;
  wordCounts: number[];
  model: string;
  qualityType: string;
}

export interface ITextModelsStatus {
  name: string;
  value: number;
}

export interface ITextModelsPayload {
  versions?: IFilterArray[] | null;
  industryIds?: string[] | null;
  areaIds?: string[] | null;
  statusIds?: string[] | null;
  templateNames?: string[] | null;
  onlyFavorite: boolean;
  search?: string | null;
  sortOrder?: string;
  pageNumber?: number;
  pageSize?: number;
  onlyArchived?: boolean;
}
export interface ITextModelEntriesPayload {
  categoryIds?: string[];
  state?: string;
  sortOrder?: string;
  sortingProperty?: string;
  pageNumber?: number;
  pageSize?: number;
  templateName?: string | null;
}

export interface IShareResponse {
  publicHash: string;
}

export interface ITextModelsAction {
  payload: ITextModelsResponse;
  type: string;
}

export interface IGetTextModelsAction {
  payload: ITextModelsPayload & IInBackground;
  type: string;
}
export interface IPutPublicTextModelsAction {
  payload: IPublicContentPayload;
  type: string;
}

export interface IModelNextStepRequestData {
  id: string;
  name: string;
  currentStepName: string;
  isRetry: boolean;
  variables: (
    | {
        name: string;
        values: string[];
        selections: number[];
      }
    | undefined
  )[];
}

export interface ICreateTextModelRequestPayload {
  textModelTemplateId: string;
  industryId: number;
  areaIds: number[];
}

export interface IModelIdResponse {
  id: string;
}
export interface IPublicHashResponse {
  publicHash: string;
}
export interface IUploadFileResponse {
  text: string;
  error: string;
}

export interface IEntriesImagesResponse {
  imageNames: string[];
}

export interface IModelIdFeedbackPayload {
  textModelId: string;
  feedback: ETextModelFeedback;
}
export interface IPublicContentPayload {
  publicHashId: string;
}

export interface ILikeModelPayload {
  textModelId: string;
  isFavorite: boolean;
  isDetails?: boolean;
}

export interface IShareModelPayload {
  textModelId: string;
  isDetails?: boolean;
}

export interface ITextModelAutosavePayload {
  textModelId: string;
  finalResult: string;
}
export interface IGetTextModelTemplateRequestAction {
  payload: {pageNumber: number; pageSize: number};
  type: string;
}
export interface IGetTextModelEntriesRequestAction {
  payload: string;
  type: string;
}

export interface ICreateTextModelRequestAction {
  payload: ICreateTextModelRequestPayload;
  type: string;
}

export interface IGetModelDetailsRequestAction {
  payload: {id: string};
  type: string;
}

export interface IGetModelTemplateDetailsRequestAction {
  payload: {textModelTemplateName: string};
  type: string;
}

export interface IModelNextStepRequestAction {
  payload: IModelNextStepRequestData;
  type: string;
}

export interface ICancelModelRequestAction {
  payload: {
    textModelId: string;
    isDetails: boolean;
  };
  type: string;
}

export interface IShareModelRequestAction {
  payload: IShareModelPayload;
  type: string;
}

export interface IUploadFileRequestAction {
  payload: {file: string};
  type: string;
}

export interface ILikeModelRequestAction {
  payload: ILikeModelPayload;
  type: string;
}

export interface IAddModelFeedbackRequestAction {
  payload: IModelIdFeedbackPayload;
  type: string;
}

export interface IGetTextGenerationResultRequestAction {
  payload: IGetTextGenerationResultPayload;
  type: string;
}

export interface IGetTextGenerationResultPayload {
  textModelId?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  pageNumber?: number;
  pageSize?: number | null;
  sortOrder?: string | null;
}

export interface IModelFeedbackAction {
  payload: boolean;
  type: string;
}

export interface IGetTextModelTemplateAction {
  payload: ITextModelTemplates;
  type: string;
}
export interface IGetTextModelEntriesAction {
  payload: ITextModelEntries;
  type: string;
}
export interface IGetTextModelCategoriesAction {
  payload: ITextModelCategories;
  type: string;
}

export interface ICreateTextModelResponseAction {
  payload: IModelIdResponse;
  type: string;
}

export interface IGetTextModelDetailsAction {
  payload: ITextModelDetails;
  type: string;
}

export interface IGetModelTemplateDetailsAction {
  payload: ITextModelTemplateItem;
  type: string;
}

export interface ILikeModelAction {
  payload: IModelIdResponse;
  type: string;
}

export interface IModelNextStepAction {
  payload: IModelIdResponse;
  type: string;
}

export interface ICancelModelAction {
  payload: IModelIdResponse;
  type: string;
}

export interface ITextModelErrorAction {
  payload: string;
  type: string;
}

export interface IGetTextGenerationResultAction {
  payload: ITextGenerationResultResponse;
  type: string;
}

export interface ITextModelAutosaveAction {
  payload: ITextModelAutosavePayload;
  type: string;
}

interface IFilterArray {
  id: string;
  name: string;
}

export interface IShareTextModelAction {
  payload: IPublicHashResponse;
  type: string;
}

export interface IUploadFileAction {
  payload: IUploadFileResponse;
  type: string;
}
export interface IEntriesImagesAction {
  payload: IEntriesImagesResponse;
  type: string;
}
export interface ITextModelStatisticAction {
  payload: ITextModelStatistic;
  type: string;
}

export interface ISetArchiveModelRequest {
  textModelId: string;
  isArchived: boolean;
}

export interface ISetArchiveModelRequestAction {
  payload: ISetArchiveModelRequestActionPayload & IInBackground;
  type: string;
}

export interface ISetFavoriteModelRequestAction {
  payload: ISetFavoriteModelRequestActionPayload & IInBackground;
  type: string;
}

export interface ISetArchiveModelRequestActionPayload extends ISetArchiveModelRequest {
  successMsg: string;
}

export interface ISetFavoriteModelRequestActionPayload extends ILikeModelPayload {
  successMsg: string;
}

export interface INNFormattingRequest {
  text: string;
  context: string | null;
  command: string | NNFormattingCommands;
  templateName: string;
  modelName: string;
  inputControlAlias: string;
  textModelId: string;
  operationId: string;
}

export interface INNFormattingResponse {
  text: string;
  wordCount: number;
  userCredits: number;
}

export interface IEntryCreate {
  name: string;
  description: string;
  categoryId: string;
  contentType: string;
  data: string[];
  tags: string;
  help: string;
}

export interface IEntryCategoryItems {
  id: string;
  externalId: string;
  name: string;
  description: string;
  imageUrl: string;
  entries: ITextModelEntry[];
  backgroundColor: string;
}

export interface IEntryUpdate extends IEntryCreate {
  id: string;
}

export interface IEntryPublishBody {
  id: string;
  isPublished: boolean;
  navigate: (url: string) => void;
}

export interface IEntryCreateAction {
  payload: IEntryRequest;
  type: string;
}

export interface IGetEntryAction {
  payload: IEntryRequest;
  type: string;
}

export interface IDeleteEntryAction {
  payload: IEntryRequest;
  type: string;
}

export interface IEntryCreateRequestAction {
  payload: IEntryCreate;
  type: string;
}

export interface IPublishEntryRequestAction {
  payload: IEntryPublishBody;
  type: string;
}

export interface IEntryRequest {
  id: string;
}

export interface ITextModelEntry {
  id: string;
  name: string;
  description: string;
  isPublished: boolean;
  isPrivate: boolean;
  userId: string;
  contentType: string;
  categoryId: string;
  data: ITemplateBlock[];
  tags: string;
  help: string;
  createdUtc: string;
  updatedUtc: string;
  image: string;
  entryImg?: string;
  qualityType: string;
  creativityIndicator: number;
}

export interface IGenerateTextModel {
  id?: string;
  name: string;
  entryName: string;
  entryImg: string;
  textualTemplate: ITemplateBlock[];
  textualRequest: string;
  contentType: string;
  categoryId: string;
  qualityType: string;
  creativityIndicator: number;
}

export interface IGenerateTextModelRequestAction {
  payload: IGenerateTextModel;
  type: string;
}

export interface IGetTextModelEntryAction {
  payload: ITextModelEntry;
  type: string;
}

export interface ITextModelEntryActionError {
  payload: string;
  type: string;
}

export interface IUpdateEntryAction {
  payload: IEntryRequest;
  type: string;
}

export interface IPublishEntryAction {
  payload: IEntryRequest;
  type: string;
}
export interface IDeleteEntryAction {
  payload: IEntryRequest;
  type: string;
}

export interface IUpdateEntryActionRequest {
  payload: IEntryUpdate;
  type: string;
}

export interface IGenerateTextModelAction {
  payload: IEntryRequest;
  type: string;
}

export interface IEngineOptions {
  name: string;
  value: string | number;
}

export enum ETextModelTemplateName {
  QuoraQuestion = 'quora-question',
  QuestionAndAnswer = 'question-and-answer',
  SwotOutlinesReaders = 'swot-outlines-readers',
  SmmShortPost = 'smm-short-post',
  PopularQuestion = 'popular-question',
  SpecialQuestion = 'special-question',
  SwotOutlines = 'swot-outlines',
  SimpleArticle = 'simple-article',
  Product = 'product',
  Essay = 'essay',
  MathLatexFormula = 'math-latex-formula',
}

export enum ETextModelFeedback {
  None = 'None',
  Good = 'Good',
  Medium = 'Medium',
  Bad = 'Bad',
}

export enum ETextModelCategoryStatus {
  All = 'All',
  Draft = 'Draft',
  Published = 'Published',
}
