import {Routes, Route} from 'react-router-dom';
import './style.scss';
import {Header} from '../Header';
import Footer from '../Footer';
import ResultContent from './ResultContent';
import GoodQuestion from './GoodQuestion';
import TemplateWork from './TemplateWork';
import NotUpdateQuestion from './NotUpdateQuestion';
import MainPage from './MainPage';
import {useSelector} from 'react-redux';
import {IState} from '../../interfaces';
import CouponCodesInfo from './CouponCodes';
import MoneyBack from './Moneyback';
import UseQATemplate from './UseQATemplate';
import UseShortEssayTemplate from './UseShortEssayTemplate';
import HowItWorks from './HowItWorks';
import CreditsUseHistory from './CreditsUseHistory';
import BillingAndPayments from './BillingAndPayments';
import PlansAndPricing from './PlansAndPricing';

export const HelpCenter = (): JSX.Element => {
  const userRole = useSelector((state: IState) => state.userProfile.userProfile.userRole);

  return (
    <div className="main-page client-page">
      <Header
        showLogo={true}
        adminSide={userRole === 'Admin' || userRole === 'Consultant'}
        specialClass={'headerDropDown--client'}
      />
      <div className="main-page__content">
        <Routes>
          <Route path={`/`} element={<MainPage />} />
          <Route path={`Rules`} element={<ResultContent />} />
          <Route path={`GoodQuestion`} element={<GoodQuestion />} />
          <Route path={`TemplateWork`} element={<TemplateWork />} />
          <Route path={`Not-update`} element={<NotUpdateQuestion />} />
          <Route path={`coupon-codes`} element={<CouponCodesInfo />} />
          <Route path={`money-back`} element={<MoneyBack />} />
          <Route path={`use-q-and-a`} element={<UseQATemplate />} />
          <Route path={`use-short-essay`} element={<UseShortEssayTemplate />} />
          <Route path={`how-it-works`} element={<HowItWorks />} />
          <Route path={`credits-use-history`} element={<CreditsUseHistory />} />
          <Route path={`billing-and-payments`} element={<BillingAndPayments />} />
          <Route path={`plans-and-pricing`} element={<PlansAndPricing />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};
export default HelpCenter;
