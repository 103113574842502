import {Grid} from '@material-ui/core';
import BackButton from './../../UI/BackButton';
import '../infoPageStyle.scss';
import {useEffect} from 'react';

export const HowItWorks = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container className="info-page how-it-works">
        <Grid item xs={11} md={11} lg={10}>
          <div className="info-page__wr">
            {/*{history.length > 1 && <BackButton match={history} prevPage />}*/}
            <BackButton prevPage />
            <Grid container>
              <Grid item xs={12} md={10} lg={9}>
                <h1 className="info-page__title">How to create content with templates</h1>

                <div className="info-page__content">
                  <div className="info-page__video">
                    {/*<video*/}
                    {/*  id="heroVideo"*/}
                    {/*  ref={v.setVidRef}*/}
                    {/*  autoPlay*/}
                    {/*  playsInline*/}
                    {/*  controls*/}
                    {/*  preload="auto"*/}
                    {/*  onClick={v.handlePlayVideo}*/}
                    {/*  muted={true}*/}
                    {/*>*/}
                    {/*  <source src={videoLink} type="video/mp4" />*/}
                    {/*</video>*/}
                  </div>
                  <div className="info-page__content">
                    <div className="content-item">
                      <h2 className="info-page__subtitle">Welcome to Marquètte</h2>
                      <p>
                        Marquètte can help you save time by quickly generating different types of
                        content using Templates. Each template is formatted to ensure the text will
                        require only minimal editing and personalization before you can post it.
                      </p>
                    </div>
                    <div className="content-item">
                      <p>To generate content using templates, take the following steps:</p>
                      <ul>
                        <li>Click the New Content button in the top right corner of the screen.</li>
                        <li>
                          Choose the template from one of the categories. Please note that each
                          category contains several template options. Check them out to see what
                          suits your needs best.
                        </li>
                        <li>
                          Follow the step-by-step instructions provided by Marquètte. Each template
                          has its own set of instructions so that the engine can generate more
                          accurate texts based on your requirements. You can also choose between the
                          GPT 3.5 and GPT 4 versions and adjust the Creativity Level to control the
                          quality of the output.
                        </li>
                        <li>Create and share top-quality content!</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default HowItWorks;
