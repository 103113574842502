import {useState, useEffect} from 'react';
import {Grid} from '@material-ui/core';
import Quotes from '../../../images/quotes.svg';
import './style.scss';
import Slider from 'react-slick';

const slidesArr = [
  {
    id: 1,
    name: 'Retail',
    question: 'What are the most used mobile apps for blind and visually impaired people?',
  },
  {
    id: 2,
    name: 'Legal',
    question: 'Can personal assets be used to run a business?',
  },
  {
    id: 3,
    name: 'Beauty',
    question: 'What technology is enhancing the beauty industry?',
  },
  {
    id: 4,
    name: 'Education',
    question: 'How is social networking impacting learning?',
  },
  {
    id: 5,
    name: 'Health and Wellness',
    question: 'What is recommended for health & wellness companies to focus on in web development?',
  },
  {
    id: 6,
    name: 'Media',
    question: 'What will be the potential uses of artificial intelligence in the future?',
  },
  {
    id: 7,
    name: 'Finance',
    question: 'What technologies will transform the financial markets in 2022?',
  },
];

const PopularQuestions = (): JSX.Element => {
  const [nav, setNav] = useState<Slider | null>(null);
  const [question, setQuestion] = useState<Slider | null>(null);

  let sliderQuestions: Slider | null = null;
  let sliderNav: Slider | null = null;

  useEffect(() => {
    setQuestion(sliderQuestions);
    setNav(sliderNav);
  }, [sliderQuestions, sliderNav]);

  return (
    <div className="lan-questions">
      <Grid
        container
        item
        style={{
          margin: 'auto',
        }}
        xs={10}
        lg={8}
        className="lan-questions-grid"
      >
        <h2 className="len-section-title len-section-title--white">The Future is Now </h2>
        <p className="len-section-subtitle len-section-subtitle--white">
          Marquètte can give you multiple opinions about business, industry trends, design, and many
          other topics. Check out the most popular questions our clients ask. Subscribe and get
          valuable info for your business!
        </p>
        <div className="questions__slider-wr">
          <Slider
            asNavFor={nav ? nav : undefined}
            ref={(slider) => setQuestion((sliderQuestions = slider))}
            slidesToShow={slidesArr.length > 3 ? slidesArr.length - 2 : slidesArr.length - 1}
            variableWidth={true}
            speed={800}
            arrows={false}
            focusOnSelect={true}
            className="questions__slider-nav"
            responsive={[
              {
                breakpoint: 1140,
                settings: {
                  slidesToShow: 4,
                  arrows: false,
                },
              },
              {
                breakpoint: 300,
                settings: {
                  slidesToShow: 2,
                  arrows: true,
                },
              },
            ]}
          >
            {slidesArr.map((item) => (
              <div key={item.id} className="questions__slide-nav">
                <p className="questions__slide-lable">{item.name}</p>
              </div>
            ))}
          </Slider>
          <Slider
            asNavFor={question ? question : undefined}
            slidesToShow={1}
            arrows={true}
            dots={true}
            fade={true}
            speed={800}
            infinite={true}
            centerMode={true}
            centerPadding={'110px'}
            responsive={[
              {
                breakpoint: 750,
                settings: {
                  arrows: false,
                  centerMode: true,
                  slidesToShow: 1,
                  centerPadding: '0px',
                },
              },
              {
                breakpoint: 900,
                settings: {
                  arrows: false,
                  centerMode: true,
                  slidesToShow: 1,
                  centerPadding: '50px',
                },
              },
              {
                breakpoint: 1140,
                settings: {
                  arrows: false,
                  centerMode: true,
                  slidesToShow: 1,
                  centerPadding: '50px',
                },
              },
              {
                breakpoint: 1590,
                settings: {
                  arrows: true,
                  centerMode: true,
                  slidesToShow: 1,
                  centerPadding: '110px',
                },
              },
            ]}
            ref={(slider) => setNav((sliderNav = slider))}
            className="questions__slider"
          >
            {slidesArr.map((item) => (
              <div key={`name-${item.id}`} className="questions__slide-item">
                <p className="questions__slide-content">
                  <img src={Quotes} alt="Quotation mark" />
                  {item.question}
                </p>
              </div>
            ))}
          </Slider>
        </div>
      </Grid>
    </div>
  );
};

export default PopularQuestions;
