import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as userProfile from '../api/userProfile';

// REDUX
import * as userProfileAction from '../actions/userProfile';
import * as userProfileActionType from '../actions/types/types';
import {Action} from 'redux-actions';
import {IUserLastLoginResponseAction} from '../interfaces';

export function* postUserLastLogin(
  action: IUserLastLoginResponseAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(userProfile.postUserLastLogin, action.payload);
    yield put(userProfileAction.postUserLoginLastDateSuccess(payload));
  } catch (error) {
    yield put(userProfileAction.postUserLoginLastDateFailure(error));
  }
}

// WATCHERS
export function* watchUserLastLogin(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(userProfileActionType.POST_USER_LASTLOGINDATE_REQUEST, postUserLastLogin);
}
