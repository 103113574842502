import {MouseEventHandler} from 'react';
import {Grid} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HeroImg from '../../../images/hero-image_screenshots.png';
import HeroMobileImg from '../../../images/iphone-mockup.png';
// @ts-ignore
import HeroBg from '../../../images/hero-bg.mp4';
import './style.scss';

interface IProps {
  redirectHandle: MouseEventHandler<HTMLButtonElement>;
}

const HeroBanner = ({redirectHandle}: IProps): JSX.Element => {
  const videoBgLink = HeroBg as string;

  return (
    <div className="lan-hero">
      <video autoPlay muted loop id="bgVideo" className="lan-hero__bg" playsInline>
        <source src={videoBgLink} type="video/mp4" />
      </video>
      <Grid
        container
        item
        style={{
          justifyContent: 'space-between',
          margin: '0 0 0 auto',
        }}
        xs={11}
        lg={10}
      >
        <Grid container item xs={12} lg={5} className="lan-hero__text" style={{flexFlow: 'column'}}>
          <h1 className="lan-hero__title">
            Marqu<span className="orange-text">è</span>tte
          </h1>
          <h2 className="lan-hero__subtitle">Content writing on steroids</h2>
          <p>
            Marquètte AI-powered platform is your ultimate content generation tool. Save your time
            and money by making your writing easy and super-fast!
          </p>
          <p className="lan-hero__cta">
            <button onClick={redirectHandle} className="orange-btn orange-btn-hover">
              Try for Free
              <ArrowForwardIcon />
            </button>
          </p>
        </Grid>
        <Grid container className="lan-hero__img-wr" item xs={12} lg={7}>
          <Hidden smUp>
            <img
              className="lan-hero__img"
              src={HeroMobileImg}
              alt="Marquètte ask a question page"
            />
          </Hidden>
          <Hidden only={['xs']}>
            <img className="lan-hero__img" src={HeroImg} alt="Marquètte ask a question page" />
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
};

export default HeroBanner;
