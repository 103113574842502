import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as subscription from '../api/adminSubscription';

// REDUX
import {Action} from 'redux-actions';
import * as subscriptionAction from '../actions/adminSubscription';
import * as subscriptionType from '../actions/types/types';
import {IAdminSubscriptionAction} from '../interfaces';
import {getUserManagementProfileRequest} from '../actions/userManagementProfile';
// postAddSubscriptionAdmin getSubscriptionsAdmin

export function* getAdminSubscriptions(
  action: IAdminSubscriptionAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(subscription.getSubscriptionsAdmin, action.payload);
    yield put(subscriptionAction.getAdminSubscriptionsStatusSuccess(payload));
  } catch (error) {
    yield put(subscriptionAction.getAdminSubscriptionsStatusFailure(error));
  }
}

export function* postAddAdminSubscriptions(
  action: IAdminSubscriptionAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(subscription.postAddSubscriptionAdmin, action.payload);
    yield put(subscriptionAction.postAddAdminSubscriptionStatusSuccess(payload));
    yield put(getUserManagementProfileRequest({userId: action.payload.userId}));
  } catch (error) {
    yield put(subscriptionAction.postAddAdminSubscriptionStatusFailure(error));
  }
}

// WATCHERS
export function* watchAdminSubscription(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(subscriptionType.GET_SUBSCRIPTIONS_ADMIN_REQUEST, getAdminSubscriptions);
  yield takeLatest(subscriptionType.POST_ADD_SUBSCRIPTION_ADMIN_REQUEST, postAddAdminSubscriptions);
}
