import React, {useCallback, useEffect, useState} from 'react';
import {TextField} from '@material-ui/core';
import {
  templateBlockToString,
  stringToTemplateBlocks,
  ITemplateBlock,
} from '../../../helpers/textModel';

interface IProps {
  options: ITemplateBlock[];
  setOptions: (data: ITemplateBlock[]) => void;
  variant?: 'standard' | 'filled' | 'outlined';
  nameDisplayed?: boolean;
  error?: boolean;
}

const OptionsEdit = (props: IProps): JSX.Element => {
  const {options, setOptions, variant, nameDisplayed = true, error} = props;
  const [optionString, setOptionString] = useState('');

  useEffect(() => {
    const data = templateBlockToString(options);
    setOptionString(data);
  }, []);

  const updateOptions = useCallback(
    (data: string) => {
      setOptionString(data);
      const dataBlocks = stringToTemplateBlocks(data);
      setOptions(dataBlocks);
    },
    [setOptions],
  );

  return (
    <div>
      {nameDisplayed ? <p>Options</p> : <></>}
      <TextField
        id=""
        className="entry-create__input options"
        // label="Options"
        InputLabelProps={{shrink: false}}
        placeholder="Enter options"
        multiline
        minRows={4}
        value={optionString}
        onChange={(e) => updateOptions(e.target.value)}
        variant={variant ? variant : 'standard'}
        error={error}
        helperText={error && 'This field is required'}
      />
    </div>
  );
};

export default OptionsEdit;
