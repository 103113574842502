import React from 'react';
import SettingSideBar from './../AccountComponents/Settings/SettingSideBar';

import './settings.scss';

export const initNotification = [
  {
    title: 'Notification Summary',
    checkboxes: [
      {text: 'An Answer (from the system) is ready', checked: true},
      {text: 'New version of the Answer (from the system) is ready', checked: true},
      {text: 'My Question was rejected', checked: true},
      {text: 'My Question was blocked', checked: true},
      {text: 'Answer to my question is ready ', checked: true},
      {text: 'New version for my Answer is ready ', checked: true},
      {text: 'New version for my Answer is not ready', checked: true},
    ],
  },
  // {
  // 	title: 'Notification Questions',
  // 	checkboxes: [
  // 		{ text: 'The question has been created by Admin', checked: false },
  // 		{ text: 'The question has been assigned to our Consultant', checked: false },
  // 		{ text: 'The question has been reassigned to an Consultant', checked: false },
  // 	],
  // },
];

export const SettingsPage = (): JSX.Element => {
  const handleAdminManagement = () => {
    //comming soon will be function
  };
  return (
    <div className="settings-page">
      <h2 className="settings-page__title">Settings</h2>
      <SettingSideBar
        className="settings-page__content"
        handleAdminManagement={handleAdminManagement}
        adminManagment={true}
        dataPrivacy={false}
      />
    </div>
  );
};
