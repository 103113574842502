import React, {useEffect, useState} from 'react';
import './style.scss';
import {useDispatch, useSelector} from 'react-redux';
import {IBillingHistoryArray, IState} from '../../../../interfaces';
import {getBillingHistoryRequest} from '../../../../actions/billing';
import moment from 'moment';
import {NavLink, useNavigate} from 'react-router-dom';
import {LightTooltip} from '../../../UI/StatusLabel';
import {ReactComponent as QuestionIcon} from '../../../../images/redeem-coupon-question.svg';

interface ICharge {
  amount: string;
  date: string;
}

const ChargeHistory = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lastAddedCredits, setLastAddedCredits] = useState<ICharge | null>();
  const billingData = useSelector((state: IState) => state?.billingHistory?.billingHistory?.items);

  useEffect(() => {
    dispatch(getBillingHistoryRequest());
  }, []);

  useEffect(() => {
    if (billingData) {
      const credits = billingData?.find((item: IBillingHistoryArray) =>
        item.name.includes('CREDITS'),
      );
      if (billingData && credits) {
        const chargeArray: ICharge = {
          amount: `${credits.name.slice(0, 2)}k`,
          date: moment(credits.effectiveUtc).format('MMM DD, YYYY'),
        };
        setLastAddedCredits(chargeArray);
      }
    }
  }, [billingData]);

  return (
    <>
      <div className="user-info__charge" id="charge">
        <h2 className="profile__subtitle profile__subtitle--flex">
          <span>Credit History</span>
          <LightTooltip
            title="Read more"
            placement="bottom"
            onClick={() => navigate('/help-center/credits-use-history')}
          >
            <QuestionIcon />
          </LightTooltip>
        </h2>
        <div className="profile__section">
          <div className="user-info__payment--title">
            {lastAddedCredits && (
              <div>
                The last time {lastAddedCredits?.amount} words were added:{' '}
                <span className="user-info__payment--descr user-info__all-info--blue">
                  {lastAddedCredits?.date}
                </span>
              </div>
            )}
            <span className="user-info__payment--history">
              <NavLink to="/account/credit-history">View history</NavLink>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChargeHistory;
