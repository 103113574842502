import {handleActions} from 'redux-actions';
import {AAD_LOGIN_SUCCESS} from '../actions/types/types';
import {EventMessage, EventType, EventPayload} from '@azure/msal-browser';

interface IAuthState {
  aadResponse: EventPayload | null;
}

const initialState = {
  aadResponse: null,
};

const handlerMap: any = {
  [AAD_LOGIN_SUCCESS]: (state: IAuthState, action: EventMessage) => ({
    ...state,
    aadResponse: action.payload,
  }),

  [EventType.LOGOUT_SUCCESS]: (state: IAuthState) => ({
    ...state,
    aadResponse: null,
  }),
};

export default handleActions(handlerMap, initialState);
