import {handleActions} from 'redux-actions';

// ACTION TYPES
import {
  GET_USER_SUBSCRIPTION_REQUEST,
  GET_USER_SUBSCRIPTION_SUCCESS,
  GET_USER_SUBSCRIPTION_FAILURE,
  GET_REGISTERED_USER_SUBSCRIPTION_REQUEST,
  GET_REGISTERED_USER_SUBSCRIPTION_SUCCESS,
  GET_REGISTERED_USER_SUBSCRIPTION_FAILURE,
} from '../actions/types/types';
import {ISubscriptionState, ISubscription} from '../interfaces';

export interface IAction {
  payload: ISubscription;
}

export const initialState = {
  subscription: undefined,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_USER_SUBSCRIPTION_REQUEST]: (state: ISubscriptionState) => ({
    ...state,
    loading: true,
  }),

  [GET_USER_SUBSCRIPTION_SUCCESS]: (state: ISubscriptionState, action: IAction) => ({
    ...state,
    subscription: action.payload,
    loading: false,
    error: '',
  }),

  [GET_USER_SUBSCRIPTION_FAILURE]: (state: ISubscriptionState) => ({
    ...state,
    error: '',
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);

const handlerMapRegistered: any = {
  [GET_REGISTERED_USER_SUBSCRIPTION_REQUEST]: (state: ISubscriptionState) => ({
    ...state,
    loading: true,
  }),

  [GET_REGISTERED_USER_SUBSCRIPTION_SUCCESS]: (state: ISubscriptionState, action: IAction) => ({
    ...state,
    subscription: action.payload,
    loading: false,
    error: '',
  }),

  [GET_REGISTERED_USER_SUBSCRIPTION_FAILURE]: (state: ISubscriptionState) => ({
    ...state,
    error: '',
    loading: false,
  }),
};

export const subscriptionRegistered = handleActions(handlerMapRegistered, initialState);
