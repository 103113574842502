import {handleBody, handleExceptions, GET, PUT, DELETE} from './request';
import {createArrayEndpoint} from '../helpers/helper';

export const getFavorites = (payload?: {
  entityType: string;
  pageNumber: string;
}): Promise<Object | null> => {
  const endpoint: string = createArrayEndpoint('Favorites', payload);

  return GET(endpoint).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const saveItem = (data: {
  reference: string;
  notes: string;
  title: string;
}): Promise<void | Response | Object | null> => {
  const endpoint: string = `/Favorites`;
  const options = {
    body: data,
  };

  return PUT(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const deleteItem = (data: {reference: string}): Promise<Object | null> => {
  const endpoint: string = `/Favorites`;

  const options = {
    body: data,
  };

  return DELETE(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
