import {handleActions} from 'redux-actions';
// ACTION TYPES
import {
  GET_USER_MANAGEMENT_REQUEST,
  GET_USER_MANAGEMENT_SUCCESS,
  GET_USER_MANAGEMENT_FAILURE,
  GET_ALL_USER_MANAGEMENT_REQUEST,
  GET_ALL_USER_MANAGEMENT_SUCCESS,
  GET_ALL_USER_MANAGEMENT_FAILURE,
} from '../actions/types/types';
import {IUserManagementAction, IUserManagementState} from '../interfaces';

export const initialState = {
  userManagement: null,
  allUserManagement: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_USER_MANAGEMENT_REQUEST]: (state: IUserManagementState) => ({
    ...state,
    loading: true,
  }),

  [GET_USER_MANAGEMENT_SUCCESS]: (state: IUserManagementState, action: IUserManagementAction) => ({
    ...state,
    userManagement: action.payload,
    loading: false,
    error: '',
  }),

  [GET_USER_MANAGEMENT_FAILURE]: (state: IUserManagementState) => ({
    ...state,
    error: '',
    loading: false,
  }),

  [GET_ALL_USER_MANAGEMENT_REQUEST]: (state: IUserManagementState) => ({
    ...state,
    loading: true,
  }),

  [GET_ALL_USER_MANAGEMENT_SUCCESS]: (
    state: IUserManagementState,
    action: IUserManagementAction,
  ) => ({
    ...state,
    allUserManagement: action.payload,
    loading: false,
    error: '',
  }),

  [GET_ALL_USER_MANAGEMENT_FAILURE]: (state: IUserManagementState) => ({
    ...state,
    error: '',
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
