import {handleActions} from 'redux-actions';
// ACTION TYPES
import {
  DELETE_USER_ACCOUNT_FAILURE,
  DELETE_USER_ACCOUNT_REQUEST,
  DELETE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_PHOTO_FAILURE,
  DELETE_USER_PHOTO_REQUEST,
  DELETE_USER_PHOTO_SUCCESS,
  GET_USER_PHOTO_FAILURE,
  GET_USER_PHOTO_REQUEST,
  GET_USER_PHOTO_SUCCESS,
  GET_USERPROFILE_FAILURE,
  GET_USERPROFILE_REQUEST,
  GET_USERPROFILE_SUCCESS,
  POST_USER_SUBSCRIPTION_RENEWAL_FAILURE,
  POST_USER_SUBSCRIPTION_RENEWAL_REQUEST,
  POST_USER_SUBSCRIPTION_RENEWAL_SUCCESS,
  UPDATE_USER_CREDITS_REQUEST,
} from '../actions/types/types';
import {
  IDeleteUserAccountAction,
  ISignalRResponse,
  IUserProfile,
  IUserProfileGetErrorAction,
  IUserProfileState,
} from '../interfaces';
import {SubscriptionStatus} from '../enums/SubscriptionStatuses';

export interface IAction {
  payload: IUserProfile;
  type: string;
}

export interface IAutoRenewalAction {
  payload: {
    autoRenew: boolean;
  };
  type: string;
}

export const initialState: IUserProfileState = {
  userProfile: {
    userId: '',
    accountType: '',
    userEmail: '',
    fullName: '',
    avatar: '',
    userRole: '',
    industries: [],
    companyName: '',
    expirationSubscriptionDate: '',
    creditCount: 0,
    currentUserSubscription: null,
    hadSubscription: false,
    subscriptionRenewal: null,
    userSubscriptionStatus: SubscriptionStatus.None,
  },
  isUserAccountDeleted: false,
  isUserPhotoDeleted: null,
  loading: false,
  error: '',
};

const handlerMap: any = {
  [GET_USERPROFILE_REQUEST]: (state: IUserProfileState) => ({
    ...state,
    loading: true,
  }),

  [GET_USERPROFILE_SUCCESS]: (state: IUserProfileState, action: IAction) => ({
    ...state,
    userProfile: {...action.payload, avatar: state.userProfile.avatar},
    loading: false,
    error: '',
  }),

  [GET_USERPROFILE_FAILURE]: (state: IUserProfileState, action: IUserProfileGetErrorAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [GET_USER_PHOTO_REQUEST]: (state: IUserProfileState) => ({
    ...state,
    loading: true,
  }),

  [GET_USER_PHOTO_SUCCESS]: (
    state: IUserProfileState,
    action: {
      payload: {
        userId: string;
        photo: string;
      };
    },
  ) => ({
    ...state,
    userProfile: {...state.userProfile, avatar: action.payload.photo},
    loading: false,
    error: '',
  }),

  [GET_USER_PHOTO_FAILURE]: (state: IUserProfileState, action: IAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [DELETE_USER_ACCOUNT_REQUEST]: (state: IUserProfileState) => ({
    ...state,
    loading: true,
  }),

  [DELETE_USER_ACCOUNT_SUCCESS]: (state: IUserProfileState, action: IDeleteUserAccountAction) => ({
    ...state,
    isUserAccountDeleted: action.payload,
    loading: false,
    error: '',
  }),

  [DELETE_USER_ACCOUNT_FAILURE]: (state: IUserProfileState, action: IAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [POST_USER_SUBSCRIPTION_RENEWAL_REQUEST]: (state: IUserProfileState) => ({
    ...state,
    loading: true,
  }),

  [POST_USER_SUBSCRIPTION_RENEWAL_SUCCESS]: (
    state: IUserProfileState,
    action: IAutoRenewalAction,
  ) => {
    return {
      ...state,
      userProfile: {
        ...state.userProfile,
        subscriptionRenewal: {
          ...state.userProfile.subscriptionRenewal,
          autoRenew: action.payload.autoRenew,
        },
      },
      loading: false,
      error: '',
    };
  },

  [POST_USER_SUBSCRIPTION_RENEWAL_FAILURE]: (state: IUserProfileState, action: IAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [DELETE_USER_PHOTO_REQUEST]: (state: IUserProfileState) => ({
    ...state,
    loading: true,
  }),

  [DELETE_USER_PHOTO_SUCCESS]: (state: IUserProfileState, action: IAction) => ({
    ...state,
    isUserPhotoDeleted: action.payload,
    loading: false,
    error: '',
  }),

  [DELETE_USER_PHOTO_FAILURE]: (state: IUserProfileState, action: IAction) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [UPDATE_USER_CREDITS_REQUEST]: (
    state: IUserProfileState,
    action: {type: string; payload: ISignalRResponse},
  ) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      creditCount: action.payload.result
        ? action.payload.userCredits
        : state.userProfile.creditCount,
    },
    error: action.payload,
    loading: false,
  }),
};

export default handleActions(handlerMap, initialState);
