import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {IconButton} from '@material-ui/core';
import './style.scss';
import {CHANGE_PASSWORD_LINK} from '../../../../constants';
import useWindowDimensions from '../../../../helpers/windowResize';

interface ISubscriptionInfoProps {
  details: {
    email: string;
    accountType: string;
  };
}

const change_password_link: string = CHANGE_PASSWORD_LINK || '';

const Security = (props: ISubscriptionInfoProps): JSX.Element => {
  const {details} = props;
  const screenWidth = useWindowDimensions().width;

  return (
    <div className="profile__section-wr security-section">
      <h2 className="profile__subtitle">Security details</h2>
      <div className="profile__section">
        <div className="security-section__item">
          <div>
            <div className="user-info__all-info--title">E-mail:</div>
          </div>
          <span className="user-info__all-info--descr user-info__all-info--blue">
            {details.email}
          </span>
        </div>
        <div className="security-section__item">
          <div>
            <div className="user-info__all-info--title">Password:</div>
          </div>
          <div>
            <span className="user-info__all-info--descr user-info__all-info--blue">********</span>
          </div>
          {details.accountType !== 'Google' && (
            <div className="user-info__grid-right">
              <a href={change_password_link} className="profile__section--edit">
                <IconButton aria-label="edit">
                  <EditOutlinedIcon />
                </IconButton>
                <span>{screenWidth > 768 ? 'Change password' : 'Change'}</span>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Security;
