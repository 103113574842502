import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as subscription from '../api/adminSubscription';

// REDUX
import * as subscriptionAction from '../actions/adminSubscription';
import * as subscriptionActionType from '../actions/types/types';
import {IRefundRequestAction} from '../interfaces';
import {Action} from 'redux-actions';
import * as userManagementProfile from '../api/userManagementProfile';
import * as userManagementProfileAction from '../actions/userManagementProfile';

export function* refundSubscription(
  action: IRefundRequestAction,
): Generator<CallEffect<void | Object | Response | null> | PutEffect<Action<any>>, void, unknown> {
  try {
    const payload = yield call(subscription.refundSubscription, action.payload);
    yield put(subscriptionAction.postSubscriptionRefundSuccess(payload));
    const userPayload = yield call(userManagementProfile.getUserManagementProfile, action.payload);
    yield put(userManagementProfileAction.getUserManagementProfileSuccess(userPayload));
  } catch (error) {
    yield put(subscriptionAction.postSubscriptionRefundFailure(error));
  }
}

// WATCHERS
export function* watchRefund(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    subscriptionActionType.POST_USER_SUBSCRIPTION_REFUND_REQUEST,
    refundSubscription,
  );
}
