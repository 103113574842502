import {call, put, takeLatest, CallEffect, PutEffect, ForkEffect} from 'redux-saga/effects';

// API
import * as areas from '../api/areas';

// REDUX
import * as areasAction from '../actions/areas';
import * as areasActionType from '../actions/types/types';
import {Action} from 'redux-actions';

export function* getAreas(): Generator<
  CallEffect<void | Object | Response | null> | PutEffect<Action<any>>,
  void,
  unknown
> {
  try {
    const payload = yield call(areas.getAreas, {});
    yield put(areasAction.getStatusSuccess(payload));
  } catch (error) {
    yield put(areasAction.getStatusFailure(error));
  }
}

// WATCHERS
export function* watchAreas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(areasActionType.GET_AREAS_REQUEST, getAreas);
}
