// SIGNALR Status - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as signalRStatusActionType from './types/types';

export const setSignalRStepTemplateRequest = createAction(
  signalRStatusActionType.SET_SIGNALR_STEP_REQUEST,
);
export const setSignalRStepTemplateSuccess = createAction(
  signalRStatusActionType.SET_SIGNALR_STEP_SUCCESS,
);
export const setSignalRStepTemplateFailure = createAction(
  signalRStatusActionType.SET_SIGNALR_STEP_FAILURE,
);
