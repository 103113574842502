import {useSelector} from 'react-redux';
import {IState} from '../../interfaces';
import {RouteProps} from 'react-router-dom';
import whitekLockIcon from '../../images/white-lock-icon.svg';
import logo from '../../images/Logo-white.svg';
import darkLogo from '../../images/Logo-dark.svg';
import trialExpired from '../../images/trialExpired.svg';
import useWindowDimensions from '../../helpers/windowResize';
import './style.scss';

const TrialExpired = (props: RouteProps): JSX.Element => {
  const fullName = useSelector((state: IState) => state.userProfile.userProfile.fullName);
  const screenWidth = useWindowDimensions().width;
  return (
    <div className="trialExpired">
      <div className="trialExpired__img-wr">
        <img src={screenWidth > 960 ? logo : darkLogo} alt="logo" />
        <img src={trialExpired} alt="trial expired" />
      </div>
      <div className="trialExpired__text-wr">
        <p>
          <span>Dear</span>, {fullName}
        </p>
        <p>
          Your free trial is over. Please, select a subscription plan to use{' '}
          <span>the application</span>
        </p>
        <button className="orange-btn ">
          <img src={whitekLockIcon} alt="lock" /> Select plan
        </button>
      </div>
    </div>
  );
};

export default TrialExpired;
