import * as React from 'react';
import reportWebVitals from './reportWebVitals';
import 'isomorphic-fetch';
import {hydrate, render, Renderer} from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider, createTheme} from '@material-ui/core/styles';
import RootRouters from './routes';
import {pca} from './authProvider/auth-provider';
import GoogleAnalyticsProvider from './components/GoogleAnalyticsProvider';

import Unauthenticated from './pages/Unauthenticated';
import {MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';

import './styles/main.scss';
import Alerts from './components/Alerts';
import {AuthenticationResult, EventMessage, EventType} from '@azure/msal-browser';
import {statusLogin} from './actions/auth-msal';
import createSagaMiddleware from 'redux-saga';
import {composeWithDevTools} from '@redux-devtools/extension';
import {applyMiddleware, legacy_createStore} from 'redux';
import promise from 'redux-promise-middleware';
import reducer from './reducers';
import rootSaga from './sagas';
import {Grammarly} from '@grammarly/editor-sdk-react';
import {GRAMMARLY_CLIENT_ID} from './constants';

const grammarlyClientId: string = GRAMMARLY_CLIENT_ID || '';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#1565c0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ec7644',
    },
  },
});

const sagaMiddleware = createSagaMiddleware();
const middleware = composeWithDevTools(applyMiddleware(promise, sagaMiddleware));
const preloadedState = {};
const store = legacy_createStore(reducer, preloadedState, middleware);
// Configure redux-saga
sagaMiddleware.run(rootSaga);

const performRender = (renderMethod: Renderer) => {
  pca.addEventCallback((event: EventMessage) => {
    console.log('PCA event: ', event);
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
      event.payload
    ) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      pca.setActiveAccount(account);
      store.dispatch(statusLogin(account));
    }
  });

  renderMethod(
    <Grammarly config={{autocomplete: 'on'}} clientId={grammarlyClientId}>
      <Provider store={store}>
        <MsalProvider instance={pca}>
          <GoogleAnalyticsProvider>
            <>
              <BrowserRouter>
                <ThemeProvider theme={muiTheme}>
                  <AuthenticatedTemplate>
                    <RootRouters />
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <Unauthenticated />
                  </UnauthenticatedTemplate>
                </ThemeProvider>
              </BrowserRouter>
              <Alerts />
            </>
          </GoogleAnalyticsProvider>
        </MsalProvider>
      </Provider>
    </Grammarly>,
    document.getElementById('root'),
  );
};

const rootElement = document.getElementById('root');

performRender(rootElement?.hasChildNodes() ? hydrate : render);

reportWebVitals();
