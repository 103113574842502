import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Grid} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Button from '@material-ui/core/Button';
import {
  postUserProfileNameRequest,
  postUserProfilePhotoRequest,
} from '../../../../actions/userProfile';
import ImageUpload from './Image';
import './style.scss';
import {StyledTextField} from '../../../UI/TestTextField/StyledTextField';

interface IUserInfo {
  fullName: string;
  avatar: string;
  updPhotoState: {loading: boolean};
  userId: string;
  industry: string[];
  company: string;
  role: string;
  accountType: string;
}

interface IProps {
  user: IUserInfo;
  setAlert: (item: boolean) => void;
}

const UserInfo = (props: IProps): JSX.Element => {
  const {user} = props;

  const [editfullName, setEditFullName] = useState<string>(user.fullName || '');
  const [userCompany, setUserCompany] = useState<string>(user.company || '');
  const [userIndustry, setUserIndustry] = useState<string[]>(user.industry || ['']);
  // const [isEditFullName, setIsEditFullName] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [profilePhoto, setProfilePhoto] = useState<string>('');

  const dispatch = useDispatch();
  // const changeFullName = () => {
  //   setIsEditFullName(!isEditFullName);
  //   setAlert(false);
  // };

  const handleInputName = (value: string) => {
    if (value.length < 2 || value.length > 65) {
      setError(true);
    } else {
      setError(false);
    }
    setEditFullName(value);
  };

  const handleCompanyName = (value: string) => {
    setUserCompany(value);
  };

  const handleSubmit = (e: React.MouseEvent): void => {
    const submitData = {
      fullName: editfullName,
      company: userCompany,
      industries: userIndustry,
    };

    dispatch(postUserProfileNameRequest(submitData));
    if (profilePhoto) {
      dispatch(postUserProfilePhotoRequest({photo: profilePhoto}));
    }
    setEditMode(false);
  };

  const handleCloseButton = () => {
    setEditFullName(user.fullName || '');
    setUserCompany(user.company || '');
    setUserIndustry(user.industry || []);
    setEditMode(false);
  };

  return (
    <div className="user-info">
      <h2 className="profile__subtitle">Profile Details</h2>
      <Grid container>
        <Grid
          className="profile__section"
          item
          xs={12}
          container
          direction="column"
          alignItems="center"
        >
          <Grid container justifyContent="space-between" className="user-info__top-bar">
            <Grid item sm={9} className="user-info__name">
              {isEditMode && user.accountType !== 'Google' ? (
                <StyledTextField
                  value={editfullName}
                  error={error || editfullName.length > 65 || editfullName.length < 2}
                  required
                  // className="modal-form__input-text"
                  variant="outlined"
                  margin="dense"
                  id="name"
                  label="Full name"
                  inputProps={{
                    type: 'text',
                  }}
                  fullWidth
                  onChange={(e) => handleInputName(e.target.value)}
                  helperText={
                    (editfullName.length > 65 && 'You have exceeded the limit in 64 characters') ||
                    (editfullName.length < 2 && 'This field is required')
                  }
                />
              ) : (
                <div className="user-info__name--dflex">
                  <p className="user-info__name--dflex-p">{`${editfullName.slice(0, 20)}${
                    editfullName.length > 20 ? '...' : ''
                  }`}</p>
                </div>
              )}
              <div className="user-info__all-info">
                <div>
                  <div className="user-info__all-info--title">Company:</div>
                  {!isEditMode ? (
                    <span>{userCompany ? userCompany : 'None'}</span>
                  ) : (
                    <StyledTextField
                      value={userCompany}
                      error={userCompany.length > 401}
                      className="modal-form__input-text"
                      margin="dense"
                      id="company"
                      variant="outlined"
                      label="Company Name"
                      fullWidth
                      inputProps={{
                        type: 'text',
                      }}
                      onChange={(e) => handleCompanyName(e.target.value)}
                      helperText={
                        userCompany.length > 401 && 'You have exceeded the limit in 400 characters'
                      }
                    />
                  )}
                </div>
                <div>
                  <div className="user-info__all-info--title">Role:</div>
                  <span>{user.role}</span>
                </div>
              </div>
            </Grid>
            <Grid item sm={3}>
              <ImageUpload
                avatar={user.avatar}
                userId={user.userId}
                updPhotoState={user.updPhotoState}
                editMode={isEditMode}
                setProfilePhoto={setProfilePhoto}
              />
            </Grid>
          </Grid>
          {!isEditMode ? (
            <Button
              className="edit-action-btn"
              onClick={() => setEditMode(true)}
              startIcon={<EditOutlinedIcon />}
            >
              Edit profile details
            </Button>
          ) : (
            <div className="action-buttons-wr">
              <Button className="white-btn white-btn-hover" onClick={handleCloseButton}>
                Cancel
              </Button>
              <Button
                className={`orange-btn orange-btn-hover ${error ? 'orange-btn--disabled' : ''}`}
                onClick={(e) => handleSubmit(e)}
                disabled={error}
              >
                Save
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default UserInfo;
