// COMMANDS - ACTIONS
// =============================================================================

import {createAction} from 'redux-actions';

// ACTION TYPES
import * as commandsActionType from './types/types';

export const getCommandsListRequest = createAction(commandsActionType.GET_COMMANDS_LIST_REQUEST);
export const getCommandsListSuccess = createAction(commandsActionType.GET_COMMANDS_LIST_SUCCESS);
export const getCommandsListFailure = createAction(commandsActionType.GET_COMMANDS_LIST_FAILURE);

export const getCommandItemRequest = createAction(commandsActionType.GET_COMMAND_ITEM_REQUEST);
export const getCommandItemSuccess = createAction(commandsActionType.GET_COMMAND_ITEM_SUCCESS);
export const getCommandItemFailure = createAction(commandsActionType.GET_COMMAND_ITEM_FAILURE);

export const postCommandCreateRequest = createAction(
  commandsActionType.POST_COMMAND_CREATE_REQUEST,
);
export const postCommandCreateSuccess = createAction(
  commandsActionType.POST_COMMAND_CREATE_SUCCESS,
);
export const postCommandCreateFailure = createAction(
  commandsActionType.POST_COMMAND_CREATE_FAILURE,
);

export const putCommandUpdateRequest = createAction(commandsActionType.PUT_COMMAND_UPDATE_REQUEST);
export const putCommandUpdateSuccess = createAction(commandsActionType.PUT_COMMAND_UPDATE_SUCCESS);
export const putCommandUpdateFailure = createAction(commandsActionType.PUT_COMMAND_UPDATE_FAILURE);

export const patchPublishCommandRequest = createAction(
  commandsActionType.PATCH_PUBLISH_COMMAND_REQUEST,
);
export const patchPublishCommandSuccess = createAction(
  commandsActionType.PATCH_PUBLISH_COMMAND_SUCCESS,
);
export const patchPublishCommandFailure = createAction(
  commandsActionType.PATCH_PUBLISH_COMMAND_FAILURE,
);

export const deleteCommandRequest = createAction(commandsActionType.DELETE_COMMAND_REQUEST);
export const deleteCommandSuccess = createAction(commandsActionType.DELETE_COMMAND_SUCCESS);
export const deleteCommandFailure = createAction(commandsActionType.DELETE_COMMAND_FAILURE);

export const setCreateCommandNavigate = createAction(
  commandsActionType.SET_CREATE_COMMAND_NAVIGATE,
);

export const clearCommandItemState = createAction(commandsActionType.CLEAR_COMMAND_ITEM);
