import {useEffect, useState} from 'react';
import {Button} from '@material-ui/core';
import {ReactComponent as QuestionIcon} from '../../../../images/redeem-coupon-question.svg';
import './style.scss';
import {useActiveUser} from '../../../../helpers/customHooks';
import * as coupons from '../../../../actions/coupon';
import {useDispatch, useSelector} from 'react-redux';
import {IState} from '../../../../interfaces';
import {ReactComponent as ModalIcon} from '../../../../images/askQuestion-large.svg';
import {ReactComponent as ApprovedQuestionIcon} from '../../../../images/ask-question-approved.svg';
import {Link, useNavigate} from 'react-router-dom';
import {LightTooltip} from '../../../UI/StatusLabel';
import LoadingSpinner from '../../../UI/LoadingSpinner';
import {StyledTextField} from '../../../UI/TestTextField/StyledTextField';

interface IProps {
  isAutorenewal: boolean | undefined;
  haveHistory: boolean | null;
}
const Coupon = (props: IProps): JSX.Element => {
  const {isAutorenewal, haveHistory} = props;
  const dispatch = useDispatch();
  const userStatus = useActiveUser();
  const navigate = useNavigate();

  const [couponCode, setCouponCode] = useState<string>('');
  const [isApplySuccess, setApplySuccess] = useState<boolean | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const couponState = useSelector((state: IState) => state.coupon);
  const userState = useSelector((state: IState) => state.userProfile);

  const responseId = couponState.applyCouponResponse && couponState.applyCouponResponse.id;
  const isDisabled = userStatus.isExpired || !isAutorenewal;

  function handleChange(value: string): void {
    setCouponCode(value);
  }
  function applyCoupon() {
    const dataForCheck = {
      subscriptionId: userState.userProfile.currentUserSubscription?.subscriptionId,
      promocode: couponCode,
    };
    setLoading(true);
    dispatch(coupons.postCheckCouponRequest(dataForCheck));
  }

  function onRedeem() {
    setCouponCode('');
    setApplySuccess(null);
  }
  useEffect(() => {
    if (!couponState.loading) {
      if (responseId && couponState.checkCouponResponse) {
        setApplySuccess(true);
        setLoading(false);
      } else if (couponState.error.length) {
        setLoading(false);
        setApplySuccess(false);
      }
    }
  }, [responseId, couponState.loading]);
  return (
    <div className={`user-info__coupon ${isDisabled ? 'disabled' : ''}`}>
      <h2 className="profile__subtitle profile__subtitle--flex">
        <span>Redeem Coupon</span>
        <LightTooltip
          title="Read more"
          placement="bottom"
          onClick={() => navigate('/help-center/coupon-codes')}
        >
          <QuestionIcon />
        </LightTooltip>
      </h2>
      {!isLoading ? (
        <div className="profile__section">
          {isApplySuccess === null ? (
            <div className="user-info__coupon--form">
              <StyledTextField
                className="user-info__coupon--input"
                value={couponCode}
                margin="dense"
                label="Enter your code"
                inputProps={{
                  type: 'text',
                }}
                id="coupon-code"
                disabled={isDisabled}
                onChange={(e) => handleChange(e.target.value)}
                variant="outlined"
              />
              <Button
                className="orange-btn orange-btn-hover"
                onClick={applyCoupon}
                disabled={isDisabled || !couponCode.length}
              >
                Add
              </Button>
            </div>
          ) : (
            <div className="user-info__coupon-message">
              {!isApplySuccess ? <ModalIcon /> : <ApprovedQuestionIcon />}
              <div className="text-wr">
                <h3>{isApplySuccess ? 'Success!' : 'Error!'}</h3>
                <p>
                  {isApplySuccess && couponState.checkCouponResponse
                    ? `Coupon code ${couponState.checkCouponResponse.promocodeName} was successfully redeemed. The reward will be applied to your account momentarily.`
                    : `This code doesn't seem to work.`}
                </p>
                <Button className="redeem-btn" onClick={onRedeem}>
                  Redeem Another Coupon
                </Button>
              </div>
            </div>
          )}
          <Link
            to={'/account/coupon-history'}
            className={`user-info__coupon--history ${!haveHistory ? 'disabled' : ''}`}
          >
            Coupon history
          </Link>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default Coupon;
