import React from 'react';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {IUserManagementItem} from '../../../interfaces';
import {Button} from '@material-ui/core';
import moment from 'moment';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

interface IProps {
  tableData: IUserManagementItem[];
  fileName: string;
}

interface IExelData {
  fullName: string;
  email: string;
  company: string;
  subscription: string;
  createdDate: string;
  lastQuestionCreatedDate: string;
  lastLoginDate: string;
}

export const ExportToExcel = (props: IProps): JSX.Element => {
  const {tableData, fileName} = props;
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const updatedData = tableData.map((item) => {
    return {
      fullName: item.fullName,
      email: item.userEmail,
      company: item.userCompany,
      subscription: item.subscriptionStatus,
      createdDate: item.createdDateTime ? moment(item.createdDateTime).format('MMM DD, YYYY') : '-',
      lastQuestionCreatedDate: item.lastQuestionCreatedDateTime
        ? moment(item.lastQuestionCreatedDateTime).format('MMM DD, YYYY')
        : '-',
      lastLoginDate: item.lastLoginDateTime
        ? moment(item.lastLoginDateTime).format('MMM DD, YYYY')
        : '-',
    };
  });

  const exportToCSV = (tableData: IExelData[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(tableData);
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          'Name',
          'Email',
          'Company name',
          'Subscription status',
          'Created',
          'Last question date',
          'Last login',
        ],
      ],
      {origin: 'A1'},
    );
    const wb = {Sheets: {data: ws}, SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'}) as BlobPart;
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      className="violet-btn violet-btn-hover"
      onClick={(e) => exportToCSV(updatedData, fileName)}
      startIcon={<FileDownloadOutlinedIcon />}
    >
      Export XLS File
    </Button>
  );
};
