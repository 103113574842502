import {handleBody, handleExceptions, GET, Params, POST} from './request';
import {IBuySubscriptionPayload} from '../interfaces';

export const getCreditItems = (params: Params): Promise<Object | null> => {
  const endpoint: string = `/Billing/CreditItems`;
  const options = {
    ...params,
  };

  return GET(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const postBuySubscription = (payload: IBuySubscriptionPayload): Promise<Object | null> => {
  const endpoint: string = `/Billing/Subscriptions?SubscriptionId=${payload.subscriptionId}&Promocode=${payload.promocode}`;
  const options = {
    body: {},
  };
  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getCreditDetails = (params: Params): Promise<Object | null> => {
  const endpoint: string = `/Billing/Credits`;
  const options = {
    ...params,
  };

  return GET(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const postBuyCredits = (payload: {creditsProductId: string}): Promise<Object | null> => {
  const endpoint: string = `/Billing/Credits/`;
  const options = {
    body: {...payload},
  };
  return POST(endpoint, options).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};

export const getBillingHistory = (): Promise<Object | null> => {
  const endpoint: string = `/Billing/History`;

  return GET(endpoint, undefined).then((response) => {
    if (response.status >= 400) {
      return handleExceptions(response);
    }

    return handleBody(response);
  });
};
